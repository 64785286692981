<template>
   <div
      class="ma-0"
      align="center"
   >
      <v-col
         class="pa-0"
         align="center"
         cols="12"
      >
         <v-row
            v-model="newmodel"
            class="ma-0 d-flex justify-space-between type_btn_03"
            justify="center"
         >
            <label
               v-for="(item, n) in items"
               :key="n"
               style="flex: 1"
               :class="newmodel === n ? 'active' : ''"
               class="radiotab custom-button"
            >
               <v-col
                  align="start"
                  class="pa-0"
               >
                  <v-radio
                     :class="{ 'custom-toggle': newmodel === item.value }"
                     :value="newmodel"
                     :color="newmodel === n ? 'primary' : 'white'"
                     :outlined="newmodel === n ? false : true"
                     elevation="0"
                     cols="4"
                     @change="handleRadioChange(n)"
                  >
                  </v-radio>
                  <v-icon
                     size="22"
                     :color="newmodel === n ? 'white' : 'grey'"
                     class="mb-1 hidden-sm-and-down"
                  >
                     check_circle_outline
                  </v-icon>
                  <span
                     class="type_btn_03_text"
                     :class="newmodel === n ? 'type_btn_03_text_select' : ''"
                  >
                     {{ item.title }}
                  </span>
               </v-col>
            </label>
         </v-row>
      </v-col>
   </div>
</template>

<script>
import model from '@/mixins/model'
import model02 from '@/mixins/model02'

export default {
   name: 'Radio03',
   components: {},
   mixins: [model, model02],
   props: ['items', 'readonly', 'disabled'],
   data: () => ({
      newmodel: null,
   }),
   computed: {},
   watch: {},
   created() {
      this.newmodel = null
   },
   mounted() {},
   methods: {
      handleRadioChange(value) {
         // Handle the change event as needed
         console.log('Selected Item:', value)
         this.newmodel = value
      },
   },
}
</script>
<style scoped lang="scss">
.v-input--selection-controls {
   margin-top: 0;
}

// .type_btn_03 {
//    width: fit-content;
//    border: 1px solid #666;
//    border-radius: 4px;
//    overflow: hidden;
//    display: flex;
//    flex-direction: row;
//    flex-wrap: no-wrap;
//    input {
//       display: none;
//    }
//    label {
//       font-family: sans-serif;
//       padding: 10px 16px;
//       border-right: 1px solid #ccc;
//       cursor: pointer;
//       transition: all 0.3s;

//       label:last-of-type {
//          border-right: 0;
//       }
//    }
//    label:hover {
//       background: #eee;
//    }
//    input:checked + label {
//       background: #becbff;
//    }
// }

.type_btn_03 {
   .radiotab {
      border-radius: 0 !important;
      width: 100%;
      height: 50px !important;
      background-color: #fff !important;
      cursor: pointer;
      border: 1px solid rgba(0, 0, 0, 0.12);
      display: flex;
      align-items: center;
      padding-left: 20px;
   }
   .radiotab.active {
      background-color: #42883d !important;
   }
   .type_btn_03_text {
      color: $color_green_6 !important;
      font-family: 'NotoSansKR-Regular' !important;
      font-size: 16px !important;
      color: #262626 !important;
      &_select {
         font-family: 'NotoSansKR-Bold' !important;
         color: #fff !important;
      }
   }
}

.active {
   background: #42883d;
}

.custom-radio-label {
   display: flex;
   align-items: center;
   margin-bottom: 10px;
   /* 레이블과 라디오 버튼을 가로로 정렬하고 간격을 조절합니다 */
}
.custom-toggle {
   background-color: #42883d; /* 선택된 경우의 배경색을 원하는 색상으로 설정 */
   color: #fff; /* 선택된 경우의 텍스트 색상을 원하는 색상으로 설정 */
}
.custom-toggle .v-input--selection-controls__input {
   display: none; /* 동그라미 버튼을 감춥니다 */
}

:deep(.v-input--selection-controls__input) {
   display: none;
}

.custom-toggle .v-input--selection-controls__ripple {
   display: none; /* 동그라미 버튼 클릭 시 나타나는 효과를 감춥니다 */
}
.custom-button {
   /* 원하는 스타일을 추가하세요 */
   transition: background-color 0.3s ease; /* 배경색 변경 시 부드러운 전환을 위한 CSS 예시 */
}
.custom-button::before {
   content: '';
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   width: 0;
   height: 0;
   background-color: rgba(0, 123, 255, 0.3);
   border-radius: 50%;
   transition: width 0.3s ease, height 0.3s ease;
   pointer-events: none;
}
</style>