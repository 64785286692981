<template>
    <v-container>
        <v-col>
            이용약관 컴포넌트
        </v-col>
    </v-container>
</template>

<script>
import model from '@/mixins/model'

export default {
    name: 'SiteTerms',
    mixins:[
        model
    ],
	data: ()=>({
	}),
    components: {
    },
	computed: {
	},
	watch: {
	},
    created(){
    },
    mounted(){
    },
    methods:{
    }
}
</script>
<style scoped lang="scss">
</style>

