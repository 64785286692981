<template>
	<div
		v-if="floatMenuShow"
		class="float"
	>
		<div class="">
			<!-- 최근본상품 -->
			<v-col
				class="pa-0 recent"
				cols="12"
				style="display: none"
			>
				<v-col
					cols="12"
					class="pa-0 mb-2"
				>
					<template v-if="APP_GET_RECENT_PRODUCT.length">
						<p class="tit mb-2">최근 본 제품</p>
						<div>
							<v-img
								width="100%"
								height="90"
								:src="APP_GET_RECENT_PRODUCT[checkIndex].image"
								class="mb-2 clickCursor"
								@click="common_link_pusher(APP_GET_RECENT_PRODUCT[checkIndex])"
							/>
							<div class="control d-flex">
								<v-btn
									class="mr-2"
									@click="prevItem"
								>
									<v-icon>chevron_left</v-icon>
								</v-btn>
								<p>
									<span class="done">{{ checkIndex + 1 }}</span>
									<span> / </span>
									<span class="all">{{ APP_GET_RECENT_PRODUCT.length }}</span>
								</p>
								<v-btn
									class="ml-2"
									@click="nextItem"
								>
									<v-icon>chevron_right</v-icon>
								</v-btn>
							</div>
						</div>
					</template>
					<!-- 최근 본 상품이 없는경우 -->
					<!-- <template v-else>
						<div class="card_line">
							<v-card
								width="100%"
								height="90"
								elevation="0"
								outlined
							/>
						</div>
					</template> -->
				</v-col>

				<!-- 전체보기 -->
				<v-col
					v-if="APP_GET_RECENT_PRODUCT.length"
					class="pa-0"
				>
					<div>
						<!-- 전체보기 노출버튼 (상태:전체보기 팝업(기본) 노출) -->
						<div class="text-center">
							<v-btn
								class="all_view my-2"
								rounded
								@click="alert = !alert"
							>
								전체보기
							</v-btn>
						</div>

						<!-- 상태 1: 전체보기 팝업 (기본) -->
						<v-alert
							v-model="alert"
							close-text="Close Alert"
							class="all_view_list pa-6"
							elevation="7"
						>
							<div class="header d-flex justify-space-between mb-3">
								<p>
									최근 본 제품 <strong class="color_green_7"> {{ APP_GET_RECENT_PRODUCT.length }} </strong>개
								</p>
								<v-btn @click="alert = false">
									<v-icon>close</v-icon>
								</v-btn>
							</div>
							<div
								v-if="APP_GET_RECENT_PRODUCT.length"
								class="box"
							>
								<v-col
									v-for="(item, i) in APP_GET_RECENT_PRODUCT"
									:key="i"
									class="pa-5 borderTop"
								>
									<LayoutFloatMenuMenu
										:menu="item"
										class="clickCursor"
										@submit="selectItem"
									/>
								</v-col>
							</div>
							<v-col
								v-else
								align="center"
								class="mt-10"
							>
								<p>최근 선택한 제품이 없습니다.</p>
							</v-col>
						</v-alert>
					</div>
				</v-col>
			</v-col>

			<!-- 토글 -->
			<v-col
				class="pa-0 mb-0"
				cols="12"
			>
				<div class="toggle">
					<div
						class="btn pa-0 py-0"
						align="center"
						@click="alert_recent = !alert_recent"
						@mouseover="handleIconMouseOver('recentIconColor')"
						@mouseout="handleIconMouseOut('recentIconColor')"
					>
						<!-- <v-img
							width="40"
							height="40"
							:src="require('@/assets/img/icon/icon_estimate.svg')"
						/> -->
						<v-badge
							color="#FA2A6F"
							:content="APP_GET_RECENT_PRODUCT?.length ? `${APP_GET_RECENT_PRODUCT.length}` : '0'"
							offset-x="11"
							offset-y="16"
							bottom
						>
							<CommonIconsRecentIcon :color="recentIconColor" />
						</v-badge>
						<p class="mt-1 font_sm">최근 본 제품</p>
					</div>
					<v-alert
						v-model="alert_recent"
						elevation="7"
						class="fast_estimate_recent pa-1 ma-0"
						:style="APP_GET_RECENT_PRODUCT.length > 0 ? '' : 'top:15px; overflow: hidden;'"
					>
						<HomeMainNewRecent
							:items="APP_GET_RECENT_PRODUCT"
							floating
						/>
						<!-- <v-col class="pa-0 tar">
							<span
								class="tar mb-4 font_normal"
								@click="alert_recent = !alert_recent"
							>
								<span>닫기</span>
								<v-icon>close</v-icon>
							</span>
						</v-col> -->
					</v-alert>
				</div>

				<div class="toggle">
					<div
						class="btn pa-0 py-0"
						align="center"
						@click="alert_estimate = !alert_estimate"
						@mouseover="handleIconMouseOver('estimateIconColor')"
						@mouseout="handleIconMouseOut('estimateIconColor')"
					>
						<!-- <v-img
							width="40"
							height="40"
							:src="require('@/assets/img/icon/icon_estimate.svg')"
						/> -->
						<CommonIconsEstimateIcon :color="estimateIconColor" />
						<p class="mt-1 font_sm">빠른견적내기</p>
					</div>
					<v-alert
						v-model="alert_estimate"
						elevation="7"
						class="fast_estimate pa-4"
					>
						<HomeMainNewEstimate floating />
						<v-col class="pa-0 tar">
							<span
								class="tar mb-2 font_normal"
								@click="alert_estimate = !alert_estimate"
							>
								<span>닫기</span>
								<v-icon>close</v-icon>
							</span>
						</v-col>
					</v-alert>
				</div>
				<!-- <div class="line my-1" /> -->
				<div class="toggle">
					<div
						class="btn pa-0"
						align="center"
						@click="naver_chatbot_on_click"
						@mouseover="handleIconMouseNewOver('talkIconColor', 'alert_hover1')"
						@mouseout="handleIconMouseNewOut('talkIconColor', 'alert_hover1')"
					>
						<!-- <v-img
							class="icon_talk"
							width="40"
							height="40"
							:src="require('@/assets/img/icon/icon_talk.svg')"
						/> -->
						<CommonIconsTalkIcon :color="talkIconColor" />
						<p class="mt-1 font_sm">동화 톡톡</p>
						<!-- <div class="text">
							<p class="py-5">
								<span>동화 톡톡</span>을 통해 <br />
								무엇이든 물어보세요!
							</p>
						</div> -->
					</div>
					<v-alert
						v-model="alert_hover1"
						elevation="7"
						class="short_bubble bubble01 px-3 py-2"
					>
						<!-- <p class="mt-1">동화 톡톡</p> -->
						<div class="text">
							<p class="py-1">
								<span>동화 톡톡</span>을 통해 <br />
								무엇이든 물어보세요!
							</p>
						</div>
					</v-alert>
				</div>
				<!-- <div class="line my-1" /> -->
				<div class="toggle">
					<div
						class="btn pa-0"
						align="center"
						@click="common_phoneCall('18992837')"
						@mouseover="handleIconMouseNewOver('callIconColor', 'alert_hover2')"
						@mouseout="handleIconMouseNewOut('callIconColor', 'alert_hover2')"
					>
						<!-- <v-img
							class="icon_call"
							width="40"
							height="40"
							:src="require('@/assets/img/icon/icon_call.svg')"
						/> -->
						<CommonIconsCallIcon :color="callIconColor" />
						<p class="mt-1 font_sm">동화콜센터</p>
						<!-- <div class="text">
							<p class="py-5">
								콜센터로 전화 걸어<br />
								궁금증을 해결하세요!<br />
								<span>1899-2837</span>
							</p>
						</div> -->
					</div>
					<v-alert
						v-model="alert_hover2"
						elevation="7"
						class="short_bubble bubble02 px-3 py-2"
					>
						<p class="mt-1 pb-1 mb-1 num">1899-2837</p>
						<div class="text">
							<p class="pb-1">
								<span>콜센터로 전화 걸어</span><br />
								궁금증을 해결하세요!
							</p>
						</div>
					</v-alert>
				</div>
				<div class="toggle">
					<div
						class="btn pa-0"
						align="center"
						@click="goCatalog"
						@mouseover="handleIconMouseNewOver('catalogIconColor')"
						@mouseout="handleIconMouseNewOut('catalogIconColor')"
					>
						<!-- <v-img
							class="icon_call"
							width="40"
							height="40"
							:src="require('@/assets/img/icon/icon_call.svg')"
						/> -->
						<CommonIconsCatalog :color="catalogIconColor" />
						<p class="mt-1 font_sm">카탈로그</p>
						<!-- <div class="text">
							<p class="py-5">
								콜센터로 전화 걸어<br />
								궁금증을 해결하세요!<br />
								<span>1899-2837</span>
							</p>
						</div> -->
					</div>
				</div>
			</v-col>
			<div
				id="naverChatBot"
				class="talk_banner_div"
				data-id="22278"
				style="display: none"
			></div>
		</div>

		<div class="line my-1" />

		<!-- 견적상담 버튼 -->
		<!-- <v-col
			v-if="AUTH_GET_USER_AUTH !== 'AGENCY'"
			align="center"
			class="pa-0"
		>
			<v-btn
				class="btn_estimate"
				to="/interior/price/inquire"
				block
			>
				맞춤견적
			</v-btn>
		</v-col> -->

		<!-- 상단으로 버튼 -->
		<v-col
			align="center"
			class="pa-0 bottombt"
		>
			<v-btn
				class="btn_top"
				block
				@click="common_scrollToTop"
			>
				<v-img :src="require('@/assets/img/home/main/float_arrow.svg')" />
			</v-btn>
		</v-col>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import common from '@/mixins/common'
import naver_chatbot from '@/mixins/naver_chatbot'

export default {
	name: 'HomeMainFloating',
	mixins: [common, naver_chatbot],
	props: ['items'],
	data: () => ({
		alert: false,
		alert_estimate: false,
		alert_recent: false,
		checkIndex: 0,
		callIconColor: '#000',
		talkIconColor: '#000',
		estimateIconColor: '#000',
		catalogIconColor: '#000',
		recentIconColor: '#000',
		alert_hover1: false,
		alert_hover2: false,
	}),
	computed: {
		...mapGetters(['AUTH_GET_USER_AUTH', 'APP_GET_APP_WIDTH', 'APP_GET_RECENT_PRODUCT']),
		floatMenuShow() {
			return this.APP_GET_APP_WIDTH > 1500 ? true : false
		},
		cssCall() {
			return {
				'-webkit-mask-image': require('../../../assets/img/icon/icon_call.svg') + ' no-repeat center !important',
				'mask-image': require('../../../assets/img/icon/icon_call.svg') + ' no-repeat center !important',
			}
		},
	},
	created() {},
	mounted() {},
	methods: {
		selectItem(item) {
			this.alert = false
			this.common_link_pusher(item)
		},
		prevItem() {
			if (this.checkIndex === 0) return
			this.checkIndex = this.checkIndex - 1
		},
		nextItem() {
			if (this.checkIndex + 1 === this.APP_GET_RECENT_PRODUCT.length) return
			this.checkIndex = this.checkIndex + 1
		},
		handleIconMouseOut(icon) {
			this[icon] = '#262626'
		},
		handleIconMouseOver(icon) {
			this[icon] = '#00CE7D'
		},
		handleIconMouseNewOut(icon, txt) {
			this[icon] = '#262626'
			if (txt) {
				this.alert_hover1 = false
				this.alert_hover2 = false
			}
		},
		handleIconMouseNewOver(icon, txt) {
			this[icon] = '#00CE7D'
			if (txt) {
				if (txt === 'alert_hover1') {
					this.alert_hover1 = true
				} else {
					this.alert_hover2 = true
				}
			}
		},
		goCatalog() {
			this.$router.push('/site/catalog')
		},
	},
}
</script>
<style scoped lang="scss">
.borderTop {
	border-bottom: 1px solid $color_gray_4;
}
.card_line {
	border-bottom: 1px solid $color_gray_4;
	padding-bottom: 1em;
}
.float {
	max-width: 90px;
	z-index: 10;
	position: fixed;
	top: 150px;
	right: 2%;
	border-radius: 8px;
	background-color: #fff !important;
	box-shadow: 0px 0px 10px 0px #00000026;

	// 최근 본 상품
	.recent {
		position: relative;
		margin: 0 auto;
		// 최근본상품 슬라이드
		.tit {
			font-family: 'NotoSansKR-Regular' !important;
			font-size: 12px !important;
			color: #262626;
		}
		.control {
			p {
				font-family: 'Roboto' !important;
				font-size: 12px !important;
			}
			.v-btn {
				border-radius: 0 !important;
				background-color: #fff !important;
				border: 1px solid $color_gray_4 !important;
				box-shadow: none !important;
			}
			.v-btn:not(.v-btn--round).v-size--default {
				min-width: 20px;
				width: 20px;
				height: 20px;
				padding: 0;
			}
		}

		// 최근본상품 전체보기
		.all_view {
			width: 100%;
			height: 25px !important;
			font-family: 'NotoSansKR-Regular' !important;
			font-size: 12px !important;
			background-color: #fff !important;
			border: 1px solid $color_gray_4 !important;
			box-shadow: none !important;
		}
		.all_view_list {
			width: 500px !important;
			height: 420px !important;
			position: absolute;
			left: -530px;
			top: 20px;
			border: 1px solid $color_gray_4;
			background-color: white !important;
			.header {
				p {
					font-family: 'NotoSansKR-Regular' !important;
					font-size: 16px !important;
				}
				.v-btn {
					box-shadow: none;
					background: #fff;
				}
				.v-btn:not(.v-btn--round).v-size--default {
					min-width: 20px !important;
					width: 20px;
					height: 20px;
				}
			}
			.box {
				height: 290px;
				overflow-y: scroll;
				border: 1px solid $color_gray_4;
				.v-card {
					box-shadow: none !important;
					border-radius: 0 !important;
					border-bottom: 1px solid $color_gray_4;
					.type {
						font-family: 'NotoSansKR-Regular' !important;
						font-size: 14px !important;
					}
					.theme {
						font-family: 'NotoSansKR-Regular' !important;
						font-size: 18px !important;
					}
					.path {
						font-family: 'NotoSansKR-Regular' !important;
						font-size: 14px !important;
						color: $color_gray_6;
					}
				}
				.v-card:last-child {
					border-bottom: none !important;
				}
				.mark.v-btn:not(.v-btn--round).v-size--x-small {
					min-width: 20px !important;
					width: 23px !important;
					height: 26px !important;
					box-shadow: none;
				}
			}
			.btn {
				width: 100px;
				font-family: 'NotoSansKR-Medium' !important;
				font-size: 14px !important;
				box-shadow: none;
				&.btn_edit {
					//편집
					color: $color_gray_6;
					background-color: #fff !important;
					border: 1px solid $color_gray_6;
				}
				&.btn_del {
					//삭제
					color: #fff;
					background-color: $color_green_6 !important;
				}
				&.btn_cancel {
					//취소
					color: #262626;
					background-color: #fff !important;
					border: 1px solid #262626;
				}
			}
		}
	}

	// 빠른견적내기 , 1:1상담 , 동화콜센터 (토글효과)
	.toggle {
		//height: 56px !important;
		margin: 20px 0 !important;
		&:first-child {
			margin: 13px 0 20px !important;
		}
		.btn {
			position: relative;
			width: 90px !important;
			height: 56px !important;
			border-radius: 8px;
			color: #777;
			cursor: pointer;
			.text {
				display: none;
				width: 150px;
				height: 90px;
				position: absolute;
				top: 0;
				right: 0;
				font-size: 12px;
				color: #fff;
				background-color: #229145;
				line-height: 1.5;
				border-radius: 8px;
				span {
					color: #a6eb76;
				}
			}
		}
		.btn:hover {
			color: $color_state_posi !important;
			// .v-image.icon_call {
			// 	border: 1px solid yellow;
			// 	// mask-image: url(../../../assets/img/icon/icon_call_w.svg);
			// 	// -webkit-mask-image: url(../../../assets/img/icon/icon_call_w.svg);
			// 	mask-size: cover;
			// 	-webkit-mask-size: cover;
			// 	background: #fff !important;
			// }
			.text {
				display: block;
				left: -140px !important;
				transition: all 5s;
			}
		}
	}

	.bottombt {
		border-radius: 0 0 8px 8px;
		overflow: hidden;
	}

	.line {
		margin: 0 auto;
		border-radius: 0 !important;
		width: 80% !important;
		height: 1px !important;
		background-color: $color_gray_4 !important;
	}

	// 최근본제품 레이어
	.fast_estimate_recent {
		cursor: inherit;
		&::after {
			//background-color: #fff;
			background: url('../../../assets/img/home/main/bubble_arrow.png') no-repeat;
			//box-shadow: -3px 2px 2px 0 rgba(178, 178, 178, 0.4);
			background-size: cover;
			content: ' ';
			display: block;
			height: 16px;
			right: -20px;
			position: absolute;
			top: 50px;
			// transform: rotate(225deg);
			// -moz-transform: rotate(45deg);
			// -ms-transform: rotate(45deg);
			// -o-transform: rotate(45deg);
			// -webkit-transform: rotate(225deg);
			width: 20px;
		}
		width: 300px !important;
		//height: 318px !important;
		position: absolute;
		left: -320px;
		top: -1px;
		border: 1px solid $color_gray_5;
		border-radius: 8px;
		background-color: white !important;
		.tar {
			display: inline-block;
			position: relative;
			bottom: 0px;
			cursor: pointer;
		}
	}

	// 빠른견적내기 레이어
	.fast_estimate {
		cursor: inherit;
		&::after {
			//background-color: #fff;
			background: url('../../../assets/img/home/main/bubble_arrow.png') no-repeat;
			//box-shadow: -3px 2px 2px 0 rgba(178, 178, 178, 0.4);
			background-size: cover;
			content: ' ';
			display: block;
			height: 16px;
			right: -20px;
			position: absolute;
			top: 50px;
			// transform: rotate(225deg);
			// -moz-transform: rotate(45deg);
			// -ms-transform: rotate(45deg);
			// -o-transform: rotate(45deg);
			// -webkit-transform: rotate(225deg);
			width: 20px;
		}
		width: 500px !important;
		//height: 500px !important;
		position: absolute;
		left: -520px;
		top: 55px;
		border: 1px solid $color_gray_5;
		border-radius: 8px;
		background-color: white !important;
		.tar {
			display: inline-block;
			position: relative;
			bottom: 0px;
			cursor: pointer;
		}
	}

	.short_bubble {
		cursor: inherit;
		border-radius: 8px;
		// &::after {
		// 	content: "";
		// 	position: absolute;
		// 	top: 21px;
		// 	right: -30px;
		// 	border-left: 30px solid #000;
		// 	border-top: 10px solid transparent;
		// 	border-bottom: 10px solid transparent;
		// }
		&::after {
			background: url('../../../assets/img/home/main/bubble_arrow.png') no-repeat;
			//box-shadow: -3px 2px 2px 0 rgba(178, 178, 178, 0.4);
			background-size: cover;
			content: ' ';
			display: block;
			height: 16px;
			right: -20px;
			position: absolute;
			top: 37%;
			// transform: rotate(225deg);
			// -moz-transform: rotate(45deg);
			// -ms-transform: rotate(45deg);
			// -o-transform: rotate(45deg);
			// -webkit-transform: rotate(225deg);
			width: 20px;
		}
		.text {
			p {
				line-height: 18px;
				font-weight: 400;
			}
		}
		width: auto !important;
		height: auto !important;
		position: absolute;
		left: -200%;
		top: 0px;
		border: 1px solid $color_gray_5;
		border-radius: 8px;
		background-color: white !important;
		.tar {
			display: inline-block;
			position: relative;
			bottom: 0px;
			cursor: pointer;
		}
	}

	.bubble01 {
		top: 37% !important;
	}

	.bubble02 {
		top: 52% !important;
		.num {
			background-color: $color_state_posi;
			color: #fff;
			padding: 2px 10px;
			line-height: 22px;
			border-radius: 99px;
			display: inline-block;
			font-size: $font_normal;
			p {
				line-height: 16px;
				font-size: $font_normal;
			}
		}
	}

	//  버튼(견적상담의뢰, 맨위로가기)
	.btn_estimate,
	.btn_top {
		width: 15px;
		height: 43px;
		padding: 14px;
		border-radius: 0;
		font-family: 'NotoSansKR-Medium';
		font-size: 14px;
		color: #262626;
		box-shadow: none;
		background-color: #fff;
		:deep(.v-image__image) {
			background-size: inherit;
		}
		// &:hover {
		// 	color: #fff !important;
		// 	background-color: #42883d !important;
		// }
	}
}
</style>
