import { mapGetters } from 'vuex'

export default {
	data() {
		return {
			keyword: null,
			keywords: [],
		}
	},
	computed: {
		...mapGetters(['APP_GET_APP_STATE']),
		get_keywords() {
			//return this.$route.query.keyword || null

			if (this.$route.query.keyword) {
				let toRemove = '나투스'
				if (this.$route.query.keyword.includes(toRemove)) {
					return this.$route.query.keyword.replace(toRemove, '')
				} else {
					return this.$route.query.keyword
				}
			} else {
				return null
			}
		},
	},
	watch: {
		get_keywords(val) {
			// let toRemove = '나투스'
			// if (val.includes(toRemove)) {
			// 	this.keyword = val.replace(toRemove, '')
			// } else {
			// 	this.keyword = val
			// }
			this.keyword = val
		},
	},
	methods: {
		on_search_keyword(val) {
			// 검색 키워드 없으면 리턴
			if (val?.length > 0) {
				let toRemove = '나투스'
				if (val.includes(toRemove)) {
					this.keyword = val.replace(toRemove, '')
				} else {
					this.keyword = val
				}
			} else return

			console.log('asdf', this.keyword)

			this.$router.push(`/search/result?keyword=${this.keyword.trim()}`).catch(() => {})
			this.save_recent_keyword()

			// 검색 후 검색창 닫기
			setTimeout(() => {
				if (this.APP_GET_APP_STATE === 'web' && window.innerWidth > 959) this.closeDialogWeb()
				else this.closeDialog()
			}, 20)
		},
		save_recent_keyword() {
			const cookieValue = this.keyword || this.get_keywords

			// 검색 키워드가 비어있을 시 리턴
			if (cookieValue === null || cookieValue === undefined || cookieValue.trim() === '') return

			if (this.$cookies.get('searchKeyword'))
				this.keywords = Array.isArray(this.$cookies.get('searchKeyword'))
					? this.$cookies.get('searchKeyword')
					: JSON.parse(this.$cookies.get('searchKeyword'))
			else this.keywords = []

			// 검색한적이 있는 키워드를 다시 검색할 경우 기존 키워드를 쿠키에서 삭제 후 맨 앞에 추가하기
			const index = this.keywords.indexOf(cookieValue)
			if (index !== -1) this.keywords.splice(index, 1)
			this.keywords.unshift(cookieValue)
			this.keywords = this.keywords.slice(0, 5)

			this.$cookies.set('searchKeyword', JSON.stringify(this.keywords), '1d')
		},
	},
	destroyed() {},
}
