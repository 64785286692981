<template>
	<div class="mb-5 mx-0 item">
		<v-col class="pa-0 bg_speciallist">
			<div class="category">
				<div class="tit">
					<router-link
						tag="div"
						to="/housewarming/specialist"
						class="mb-3 d-flex align-center clickCursor"
					>
						<p class="mr-1">전문가집들이</p>
						<v-img
							:src="require('@/assets/img/community/arrow_white.svg')"
							max-width="22"
							height="100%"
						/>
					</router-link>
					<span> 전문 인테리어점의 멋진 시공 사례를 확인해보세요! <br class="hidden-sm-and-down" /> </span>
				</div>
			</div>
		</v-col>

		<v-col class="px-4 py-5 px-lg-5 pt-lg-7 pb-lg-4 cont">
			<HomeMainNewCarousels
				:menu-type="'housewarming'"
				v-if="housewarmingItems"
				:carousel-items="housewarmingItems"
          		:interval="5000"
				height="440px"		
				@submit="onClickProduct"		
			/>
		</v-col>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import common from '@/mixins/common'
export default {
	name: 'CommunityHomeNewProfesional',
	components: {},
	mixins: [common],
	data: () => ({}),
	computed: {
		...mapGetters(['HOME_GET_MAIN_HOUSEWARMING_SPECIAL']),
		housewarmingItems() {
			const items = this.HOME_GET_MAIN_HOUSEWARMING_SPECIAL
			return items.sort(() => Math.random() - 0.5).slice(0, 3)
		},
	},
	watch: {},
	created() {},
	methods: {
		onClickProduct(val) {
			this.common_link_pusher(val)
		},
		pushLink() {
			this.$router.push('/housewarming/3d')
		},
	},
}
</script>

<style scoped lang="scss">
.item {
	background-color: $color_white;
	border-radius: 16px;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
	overflow: hidden;

	.category {
		color: $color_white;
		p {
			font-weight: $fw_bold;
			line-height: 1 !important;
			font-family: 'NotoSansKR-Bold' !important;
			font-size: $font_tit !important;
		}
		span {
			word-break: keep-all;
			font-size: $font_normal;
			font-weight: $fw_normal;
		}
	}

	.cont {
		position: relative;
		margin-top: -20px;
		border-radius: 16px !important;
		background-color: $color_white;
		h3 {
			color: $color_font;
			font-size: $font_xl;
			font-weight: $fw_bold;
		}
	}

	.bg_speciallist {
		padding: 30px 20px 40px !important;
		background-color: $color_primary_1;
	}
}

@media all and (max-width: 1264px) {
	.item {
		.cont {
			margin-top: 0 !important;
			border-radius: 0 !important;
		}
		.bg_speciallist {
			padding: 20px 16px !important;
		}
	}
}
@media all and (max-width: 600px) {
	.item{
		border-radius: 0 !important;	
	}
}
@media all and (max-width: 380px) {
	.item {
		.category {
			p {
				font-size: $font_xl !important;
				line-height: 22px !important;
			}
			span {
				font-size: $font_sm !important;
			}
		}
		.cont {
			h3 {
				font-size: $font_lg !important;
			}
		}

		.main_youtube {
			.desc {
				font-size: $font_normal !important;
			}
		}
	}
}
</style>
