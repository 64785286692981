<template>
	<div class="ma-0 mb-5 tiplist">
		<div
			class="pa-0 tipitem"
			cols="4"
			@click="goDetail(item.id)"
		>
			<div class="thumbnail clickCursor">
				<!-- <v-img
					:src="thumbnail"
					height="100%"
					width="100%"
					aspect-ratio="1.8"
				/> -->
				<v-img
					:src="item.image_url"
					height="100%"
					width="100%"
					aspect-ratio="1.8"
				/>
				<!-- <div class="dim">
					<v-img
						:src="require('@/assets/img/community/youtube_white.svg')"
						width="53"
						height="auto"
						class="icon_play"
					/>
				</div> -->
			</div>
			<div class="ml-5 my-2 thumbtxt">
				{{ item.title }}
			</div>
		</div>
		<!-- <v-col
			class="pa-0 desc"
			cols="8"
			align-self="center"
		>
			<div class="ml-5 my-2">
				{{ item.title }}
			</div>
		</v-col> -->
	</div>
</template>

<script>
export default {
	name: 'HomeCommunityHomeNewTipitem',
	props: ['item'],

	computed: {
		// thumbnail() {
		// 	const videoId = this.item.video_url.split('/').pop().split('=').pop()
		// 	return `https://i3.ytimg.com/vi/${videoId}/hqdefault.jpg`
		// },
		// thumbnail() {
		// 	console.log(item)
		// },
	},
	created() {
	},
	methods: {
		goDetail(id) {
			this.$router.push({
				name: 'Community_KnowHow_Detail',
				params: {
					id,
				},
			})
		},
	},
}
</script>

<style lang="scss" scoped>
@mixin ellipsis_3 {
	display: -webkit-box !important;
	height: 75px !important;
	word-wrap: break-word !important;
	-webkit-line-clamp: 3 !important;
	-webkit-box-orient: vertical !important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
}

.tiplist {
	display: flex;
	align-items: center;
	.tipitem {
		display: flex;
    	align-items: center;
		.thumbnail {
			width: 126px;
    		height: 126px;
		}
		.thumbtxt {
			flex: 1;
		}
	}
}

.sub_youtube {
	.desc {
		@include ellipsis_3;
		color: $color_font;
		font-size: $font_normal;
		font-weight: $fw_normal;
		display: flex;
		align-items: center;
	}
	.thumbnail {
		position: relative;
		border-radius: 4px;
		overflow: hidden;
		width:126px;
		height:126px;
		.dim {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: rgba(0, 0, 0, 0.3);
			.icon_play {
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
	}
}

@media all and (max-width: 600px) { 
	.sub_youtube {	
		.thumbnail {
			width:98px;
			height:98px;		
		}
	}	
}
</style>
