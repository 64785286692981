<template>
	<div class="qa">
		<v-row class="ma-0">
			<v-col
				class="pa-0 left"
				cols="12"
				md="3"
			>
				<v-row class="ma-0 mb-5 mb-md-0">
					<v-col
						class="pa-0 pb-md-3 mr-3 mr-md-0 mb-2 mb-md-0"
						cols="auto"
						md="auto"
					>
						<div>
							<v-img
								max-width="76"
								eager
								:src="require('@/assets/img/careflo/icon_Q&A.svg')"
							/>
						</div>
					</v-col>
					<v-col
						class="pa-0 pt-md-3"
						cols="10"
						md="12"
					>
						풀케어 서비스가
						<br class="hidden-sm-and-down" />
						궁금하신가요?
					</v-col>
				</v-row>
			</v-col>
			<v-col
				class="pa-0 right"
				cols="12"
				md="9"
			>
				<v-expansion-panels
					v-model="panel"
					accordion
					elevation="0"
					class="panel"
					multiple
				>
					<!-- 1번질문 -->
					<v-expansion-panel class="mb-2">
						<v-expansion-panel-header>
							<div
								class="d-flex"
								align="start"
							>
								<div class="mr-4 icon_question">
									<v-img
										max-width="24"
										eager
										:src="require('@/assets/img/careflo/icon_green_q.svg')"
									/>
								</div>
								<div class="list_question">
									실수로 마루에 문제가 생긴 경우에도 풀케어 서비스를 받을 수 있나요?
								</div>
							</div>
						</v-expansion-panel-header>
						<v-expansion-panel-content class="mt-2">
							<div
								class="d-flex"
								align="start"
							>
								<div class="mr-4 icon_answer">
									<v-img
										max-width="24"
										eager
										:src="require('@/assets/img/careflo/icon_white_a.svg')"
									/>
								</div>
								<div class="list_answer">
									소비자 과실을 포함하여 무상 A/S를 제공하고 있습니다. 상세한 서비스 범위는 ‘무상 A/S 기준'을
									참고해 주세요.

									<v-row class="ma-0 mt-6 justify-center free_list">
										<v-col
											class="py-2 px-3"
											cols="12"
											md="10"
										>
											<p class="mb-2 tac tit">무상 A/S 기준</p>
											<v-row class="ma-0 py-2 item">
												<v-col
													class="pa-0 mb-2 mb-md-0 list"
													cols="6"
													md="3"
													align="center"
												>
													찍힘/긁힘 메꿈처리
												</v-col>
												<v-col
													class="pa-0 mb-2 mb-md-0 list"
													cols="6"
													md="3"
													align="center"
												>
													표면 이물질/오염제거
												</v-col>
												<v-col
													class="pa-0 list"
													cols="6"
													md="3"
													align="center"
												>
													걸레받이 재결합
												</v-col>
												<v-col
													class="pa-0 list"
													cols="6"
													md="3"
													align="center"
												>
													실리콘 탈락 시 코킹처리
												</v-col>
											</v-row>
										</v-col>
									</v-row>
								</div>
							</div>
						</v-expansion-panel-content>
					</v-expansion-panel>

					<!-- 2번질문 -->
					<v-expansion-panel class="mb-2">
						<v-expansion-panel-header>
							<div
								class="d-flex"
								align="start"
							>
								<div class="mr-4 icon_question">
									<v-img
										max-width="24"
										eager
										:src="require('@/assets/img/careflo/icon_green_q.svg')"
									/>
								</div>
								<div class="list_question">풀케어 서비스는 어떻게 신청하나요?</div>
							</div>
						</v-expansion-panel-header>
						<v-expansion-panel-content class="mt-2">
							<div
								class="d-flex"
								align="start"
							>
								<div class="mr-4 icon_answer">
									<v-img
										max-width="24"
										eager
										:src="require('@/assets/img/careflo/icon_white_a.svg')"
									/>
								</div>
								<div class="list_answer">
									풀케어 서비스는 진/듀오 제품군 구매 시 인테리어점에서 풀케어 서비스를 별도로 구매하신 고객에게
									제공됩니다. 케어플로 서비스 신청은 인증 스티커를 확인하신 후 신청해주세요.
									<div class="mt-2 call">
										<p>*2023년 7월 1일 이후 시공 현장에 한합니다.</p>
										<p
											class="mt-2 link"
											@click="goDetail"
										>
											자세한 신청 방법 보러가기
										</p>
									</div>
								</div>
							</div>
						</v-expansion-panel-content>
					</v-expansion-panel>

					<!-- 3번질문 -->
					<v-expansion-panel class="mb-2">
						<v-expansion-panel-header>
							<div
								class="d-flex"
								align="start"
							>
								<div class="mr-4 icon_question">
									<v-img
										max-width="24"
										eager
										:src="require('@/assets/img/careflo/icon_green_q.svg')"
									/>
								</div>
								<div class="list_question">케어플로 인증 스티커는 무엇인가요?</div>
							</div>
						</v-expansion-panel-header>
						<v-expansion-panel-content class="mt-2">
							<div
								class="d-flex"
								align="start"
							>
								<div class="mr-4 icon_answer">
									<v-img
										max-width="24"
										eager
										:src="require('@/assets/img/careflo/icon_white_a.svg')"
									/>
								</div>
								<div class="list_answer">
									동화가 인증하는 전문점에서 시공된 현장에 부착되며, 스티커를 통해 케어플로 서비스를 받으실 수
									있습니다. <br />
									스티커 부착 위치 및 사용법은
									<span
										class="link"
										@click="goDetail"
									>
										자세한 신청 방법 보러가기
									</span>
									에서 확인해 주세요.<br />
									케어플로 인증 스티커에는 NFC(Near Field Communication) 기술이 적용되어 있어, 스마트폰을 통해
									간단하게 신청할 수 있습니다.
								</div>
							</div>
						</v-expansion-panel-content>
					</v-expansion-panel>

					<!-- 4번질문 -->
					<v-expansion-panel>
						<v-expansion-panel-header>
							<div
								class="d-flex"
								align="start"
							>
								<div class="mr-4 icon_question">
									<v-img
										max-width="24"
										eager
										:src="require('@/assets/img/careflo/icon_green_q.svg')"
									/>
								</div>
								<div class="list_question">본사에서 시공자를 보내주나요?</div>
							</div>
						</v-expansion-panel-header>
						<v-expansion-panel-content class="mt-2">
							<div
								class="d-flex"
								align="start"
							>
								<div class="mr-4 icon_answer">
									<v-img
										max-width="24"
										eager
										:src="require('@/assets/img/careflo/icon_white_a.svg')"
									/>
								</div>
								<div class="list_answer">
									동화자연마루는 마루업계 최대 본사소속 직배송, 직시공 전문기사가 있습니다. 70년 역사의 목질
									전문기업의 노하우로 숙련된 전문가를 육성하고 있으며, 풀케어를 구매하시면 본사에서 믿을 수 있는
									전문기사를 보내드립니다.
								</div>
							</div>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</v-col>
		</v-row>
	</div>
</template>

<script>
export default {
	name: 'HomeCustomCareFloFullAccodian',
	components: {},
	props: [],
	data: () => ({
		panel: [0],
	}),
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		goDetail() {
			window.open('https://www.greendongwha.com/community/knowHow/detail/128')
		},
	},
}
</script>

<style scoped lang="scss">
.qa {
	margin-top: 70px;
	.left {
		position: relative;
		color: $color_font;
		font-size: 2rem;
		font-weight: $fw_bold;
		&::after {
			display: block;
			width: 84px;
			height: 2px;
			position: absolute;
			top: 48px;
			left: 0;
			background-color: $color_font;
			content: '';
			z-index: 1;
		}
	}
}
.panel {
	:deep(.v-expansion-panel::before) {
		box-shadow: none !important;
	}
	:deep(.v-expansion-panel-header) {
		padding: 20px 30px !important;
		background: $color_white;
		border-radius: 8px;
		box-shadow: none !important;
	}
	.list_question {
		color: $color_gray_8;
		font-size: $font_sub_tit !important;
		font-weight: $fw_regular !important;
		line-height: 1.3;
	}
	:deep(.v-expansion-panel-content) {
		padding: 20px 50px;
		background-color: #1f8e63;
		border-radius: 8px;
		.v-expansion-panel-content__wrap {
			padding: 0;
		}
	}
	.list_answer {
		color: $color_white;
		font-size: $font_xl;
		line-height: 1.3;
	}
	:deep(.v-expansion-panel-header__icon) {
		max-height: 20px !important;
		i {
			font-size: 48px;
			color: #00ce7d !important;
		}
	}
}
:deep(.theme--light.v-expansion-panels .v-expansion-panel) {
	background-color: #eef8f3 !important;
}
:deep(.v-expansion-panels--accordion > .v-expansion-panel::after) {
	display: none !important;
}
.call {
	font-size: $font_mid;
	color: $color_white;
}
.link {
	display: inline-block;
	font-size: $font_xl;
	color: $color_white;
	border-bottom: 1px solid $color_white;
	cursor: pointer;
}
.free_list {
	font-size: $font_normal;
	background: $color_gray_1;
	border-radius: 8px;
	.tit {
		color: $color_gray_7;
		font-weight: $fw_bold;
	}
	.item {
		background: $color_white;
		border-radius: 4px;
		color: $color_gray_8;
		.list {
			position: relative;
			&:last-child {
				&::after {
					display: none;
				}
			}
			&::after {
				display: block;
				width: 1px;
				height: 12px;
				background-color: $color_gray_3;
				content: '';
				position: absolute;
				top: 5px;
				right: 0;
			}
		}
	}
}

@media all and (max-width: 959px) {
	.qa {
		margin-top: 34px !important;
		.left {
			font-size: $font_lg !important;
			&::after {
				display: none !important;
			}
			.v-image {
				max-width: 56px !important;
			}
		}
		.right {
		}
	}

	.panel {
		background-color: #eef8f3;
		.icon_question,
		.icon_answer {
			display: none !important;
		}
		:deep(.v-expansion-panel-header) {
			padding: 12px 16px !important;
		}
		.list_question {
			font-size: $font_lg !important;
			padding-right: 20px !important;
		}
		:deep(.v-expansion-panel-content) {
			// display: block !important;
			padding: 16px !important;
		}
		.list_answer {
			width: 100% !important;
			font-size: $font_normal !important;
		}
		:deep(.v-expansion-panel-header__icon) {
			i {
				font-size: 24px !important;
			}
		}
	}
	.link {
		font-size: $font_normal !important;
	}

	.free_list {
		font-size: $font_sm !important;
		.item {
			.list {
				&:nth-child(2) {
					&::after {
						display: none;
					}
				}
			}
		}
	}
}
</style>
