<template>
	<div>
		<div
			v-if="!frommain"
			class="px-4"
		>
			<h2 class="mt-6 mb-4">마루추천</h2>
			<p class="mb-5 mb-md-7 slogan pop_only">사진만 올리면 어울리는 마루를 추천해드려요!</p>
			<p class="mb-5 mb-md-7 slogan page_only">사진을 업로드하고 마루추천을 받아보세요!</p>

			<v-col
				class="mb-7 layout_align btn_upload"
				cols="12"
				sm="6"
				md="5"
				lg="4"
				align="center"
				@click="triggerFileInput"
			>
				<v-icon color="#fff">mdi-camera-outline</v-icon>
				<input
					ref="fileInput"
					type="file"
					accept=".jpeg, .jpg, .png"
					hidden
					@change="handleFileChange"
				/>
				사진업로드
			</v-col>
		</div>

		<div
			v-else
			class="px-0"
		>
			<div class="photo_tip">
				<div class="inner">
					<p class="mb-5 mb-md-4 slogan page_only">
						우리집 거실 사진을 올려볼까요?<br />
						AI봇이 업로드한 사진을 분석하여<br />
						<em>어울리는 마루를 추천</em>드려요
					</p>
					<v-col
						class="layout_align btn_upload02"
						cols="12"
						sm="12"
						md="12"
						lg="12"
						align="center"
						@click="triggerFileInput"
					>
						<v-icon color="#fff">mdi-camera-outline</v-icon>
						<input
							ref="fileInput"
							type="file"
							accept=".jpeg, .jpg, .png"
							hidden
							@change="handleFileChange"
						/>
						사진업로드
					</v-col>
				</div>
			</div>
		</div>

		<!-- <v-bottom-sheet v-model="sheet">
			<v-row class="ma-0 justify-end">
				<v-btn
					icon
					@click="sheet = !sheet"
				>
					<v-icon color="#fff">mdi-close</v-icon>
				</v-btn>
			</v-row>
			<v-card class="text-center">
				<v-col class="py-4 tit">사진업로드</v-col>
				<v-col class="py-4 list">
					<v-icon color="#777">mdi-camera-outline</v-icon>
					사진 찍기
				</v-col>
				<v-col class="py-4 list">
					<v-icon color="#777">mdi-image-multiple-outline</v-icon>
					사진 보관함
				</v-col>
			</v-card>
		</v-bottom-sheet> -->

		<div
			v-if="!frommain"
			class="photo_tip"
		>
			<div class="inner">
				<div class="mb-5 tac">
					<span class="tit">사진 촬영 Tip</span>
				</div>
				<v-row class="ma-0">
					<v-col
						class="pa-0 mb-5 mb-md-0 align-center align-md-start"
						cols="12"
						md="7"
						align="center"
						align-md="start"
					>
						<v-img
							width="100%"
							max-width="320"
							alt="마루추천 서비스 사진 촬영 팁"
							:src="require('@/assets/img/search/img_search_guide.png')"
						/>
					</v-col>

					<v-col
						class="pa-0 tal right"
						cols="12"
						md="5"
					>
						<div class="mb-5 item">
							<p class="mb-1 tip">Tip 1</p>
							<p class="txt">
								<strong>[바닥, 가구, 벽]</strong>
								세개의 피사체가 <br class="hidden-md-and-down" />
								모두 보이도록 촬영해주세요!
							</p>
						</div>
						<div class="mb-5 item">
							<p class="mb-1 tip">Tip 2</p>
							<p class="txt">
								적당한 거리를 유지하여<br class="hidden-md-and-down" />
								<strong>전체적인 인테리어</strong>가 보이도록 촬영해주세요!
							</p>
						</div>
						<div class="item">
							<p class="mb-1 tip">Tip 3</p>
							<p class="txt">
								사진 권장 비율은 <strong>4:3 가로형</strong>입니다.<br class="hidden-md-and-down" />
								사진을 촬영할 때 가로로 촬영해주세요!
							</p>
						</div>
					</v-col>
				</v-row>
			</div>
		</div>
		<div
			v-else
			class="photo_tip"
		>
			<!-- <div class="inner">
				<div class="mb-5 tac">
					<span class="tit">사진 촬영 Tip</span>
				</div>
				<v-row class="ma-0">
					<v-col
						class="pa-0 mb-5 mb-md-0 align-center align-md-start"
						cols="12"
						md="7"
						align="center"
						align-md="start"
					>
						<v-img
							width="100%"
							max-width="320"
							alt="마루추천 서비스 사진 촬영 팁"
							:src="require('@/assets/img/search/img_search_guide.png')"
						/>
					</v-col>

					<v-col
						class="pa-0 tal right"
						cols="12"
						md="5"
					>
						<div class="mb-5 item">
							<p class="mb-1 tip">Tip 1</p>
							<p class="txt">
								<strong>[바닥, 가구, 벽]</strong>
								세개의 피사체가 <br class="hidden-md-and-down" />
								모두 보이도록 촬영해주세요!
							</p>
						</div>
						<div class="mb-5 item">
							<p class="mb-1 tip">Tip 2</p>
							<p class="txt">
								적당한 거리를 유지하여<br class="hidden-md-and-down" />
								<strong>전체적인 인테리어</strong>가 보이도록 촬영해주세요!
							</p>
						</div>
						<div class="item">
							<p class="mb-1 tip">Tip 3</p>
							<p class="txt">
								사진 권장 비율은 <strong>4:3 가로형</strong>입니다.<br class="hidden-md-and-down" />
								사진을 촬영할 때 가로로 촬영해주세요!
							</p>
						</div>
					</v-col>
				</v-row>
			</div> -->
		</div>
	</div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import common from '@/mixins/common'
export default {
	name: 'PhotoReco',
	mixins: [common],
	props: {
		frommain: {
			type: Boolean,
		},
	},
	data: () => ({
		fileInput: null,
		sheet: false,
	}),
	computed: {
		...mapGetters(['HOME_GET_SEARCH_PICTURE_UPLOAD_FILE']),
	},
	watch: {},
	mounted() {},
	methods: {
		...mapMutations(['APP_MU_DIALOG', 'HOME_MU_SEARCH_PICTURE_UPLOAD_FILE']),
		triggerFileInput() {
			this.$refs.fileInput.click()
		},
		async handleFileChange(e) {
			const selectedFile = e.target.files[0]
			if (selectedFile) {
				// 확장자 validation
				const extension = selectedFile.name.split('.').pop().toLowerCase()
				const extensionCheck = ['jpg', 'jpeg', 'png'].includes(extension)
				console.log(extension)
				if (!extensionCheck) {
					this.$refs.fileInput.value = null
					this.APP_MU_DIALOG({
						dialog: true,
						title: '',
						text: '파일형식은 jpg, jpeg, png만 가능합니다.',
					})
					return
				} else {
					let data = {}
					const reader = new FileReader()
					reader.onload = e => {
						data.file = selectedFile
						data.image = e.target.result
						this.HOME_MU_SEARCH_PICTURE_UPLOAD_FILE(data)
					}
					reader.readAsDataURL(selectedFile)
					if (this.$route.path !== '/search/maruRecommend') this.$router.push(`/search/maruRecommend`)
					this.$emit('close')
				}
			}
		},
	},
}
</script>

<style scoped lang="scss">
.slogan {
	text-align: center;
	font-size: $font_normal;
	color: $color_gray_6;
	font-weight:$fw_normal;
	line-height: 18px;
	em {
		font-weight:$fw_bold;
		font-style:normal;
	}
}
.photo_tip {
	.inner {
		max-width: 678px;
		margin: 0 auto;
		padding: 20px 40px;
		background-color: $color_gray_1;
		border-radius: 16px;
	}
	.tit {
		display: inline-block;
		padding: 8px 36px;
		font-size: $font_lg;
		font-weight: $fw_bold;
		color: $color_font;
		background-color: $color_white;
		border-radius: 99px;
	}
	.item {
		.tip {
			font-size: $font_normal;
			font-weight: $fw_bold;
			color: $color_font;
		}
		.txt {
			font-size: $font_normal;
			color: $color_gray_8;
			word-break: keep-all;
		}
	}
}
.btn_upload {
	cursor: pointer;
	font-size: $font_lg;
	font-weight: $fw_bold;
	color: $color_white;
	background-color: $color_state_posi;
	border-radius: 99px;
}

.btn_upload02 {
	cursor: pointer;
	font-size: $font_lg;
	font-weight: $fw_bold;
	color: $color_white;
	background-color: $color_state_posi;
	border-radius: 99px;
}

:deep(.v-dialog) {
	box-shadow: none !important;
}
.v-sheet.v-card {
	border-radius: 0 !important;
	border-top-left-radius: 16px !important;
	border-top-right-radius: 16px !important;
	.tit {
		font-size: $font_normal;
		font-weight: $fw_bold;
		color: $color_font;
		background-color: $color_gray_1;
	}
	.list {
		cursor: pointer;
		border-top: 1px solid $color_gray_2 !important;
		font-size: $font_normal;
		color: $color_font;
	}
}

h2 {
	display: none;
}
.pop_only {
	display: none;
}
.pos {
	.wrapper {
		width: 100%;
		min-width: 680px;
	}
	h2 {
		display: block !important;
	}
	.pop_only {
		display: block !important;
	}
	.page_only {
		display: none !important;
	}
	.photo_tip {
		.inner {
			border-radius: 0 !important;
			.right {
				padding-left: 20px !important;
			}
		}
	}
}
.v-dialog {
	.slogan {
		margin-top: 20px;
	}
}

@media all and (max-width: 959px) {
	.slogan {
		font-size: $font_normal !important;
	}
	.photo_tip {
		.inner {
			padding: 16px 14px !important;
		}
		.tit {
			padding: 6px 36px !important;
		}
		.v-image {
			max-width: 100% !important;
		}
	}
	.btn_upload {
		height: 46px !important;
	}
	.btn_upload02 {
		height: 46px !important;
	}
}
</style>
