<template>
	<div>
		<!-- <div v-if="!appWidthState">
			<v-row
				class="ma-0"
				align="center"
			>
				<v-col
					cols="7"
					align="start"
				>
					<span class="select_count">{{ items.length }} 지점 </span> 선택
					<span class="select_explain">(최대 3개까지 선택 가능)</span>
				</v-col>
				<v-col
					cols="5"
					align="end"
				>
					<v-btn
						dark
						color="olive"
						@click="listState = !listState"
					>
						{{ listState ? '상세보기' : '상세보기' }}
						<v-icon class="pl-2">
							{{ listState ? 'mdi-chevron-down' : 'mdi-chevron-up' }}
						</v-icon>
					</v-btn>
				</v-col>
			</v-row>
		</div> -->
		<!-- <v-row
			class="ma-0"
			align="center"
		>
			<template v-if="listState">
				<v-col
					v-for="(item, i) in items"
					:key="i"
					class="pa-0 selectedStore"
					cols="12"
					md="4"
					align="center"
				>
					<CommonCardMenuCardInteriorSelect02
						:item="item"
						@close="deleteInterior"
					/>
				</v-col>
			</template>
			<template v-if="listState">
				<v-col
					v-for="i in emptyCount"
					:key="`empty_${i}`"
					class="py-0"
					cols="12"
					md="4"
					align="center"
				>
					<v-img
						width="100%"
						max-width="320px"
						contain
						eager
						:src="require('@/assets/img/home/interior/card_default.png')"
					/>
				</v-col>
			</template>
		</v-row> -->
		<v-row class="ma-0">
			<v-col
				class="px-0"
				cols="12"
				md="12"
			>
				<v-btn
					v-if="appWidthState"
					:disabled="allowCheck"
					color="#00CE7D"
					block
					height="40"
					x-large
					elevation="0"
					@click="openInquireDrawer"
				>
					<h1
						class="font_lg"
						style="color: #fff"
					>
						간편상담신청하기
					</h1>
				</v-btn>
				<v-btn
					v-else
					:disabled="allowCheck"
					color="#00CE7D"
					block
					height="40"
					large
					elevation="0"
					@click="openInquireDrawer"
				>
					<h3
						class="font_lg"
						style="color: #fff"
					>
						간편상담신청하기
					</h3>
				</v-btn>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import common from '@/mixins/common'
import { mapGetters, mapMutations } from 'vuex'

export default {
	name: 'HomeInteriorPriceItemsInteriorList03',
	mixins: [common],
	props: {
		items: {
			type: Array,
			default: () => [],
		},
		tabSelect: {
			type: Number,
			default: 0,
		},
	},
	data: () => ({
		listState: true,
	}),
	computed: {
		...mapGetters(['APP_GET_APP_WIDTH', 'HOME_GET_INTERIOR_SELECT_INTERIOR']),
		allowCheck() {
			return !this.items.length
		},
		emptyCount() {
			return 3 - Number(this.items.length)
		},
		appWidthState() {
			return this.APP_GET_APP_WIDTH > 960
		},
		chooseStoreItems() {
			return this.items
		},
	},
	watch: {
		APP_GET_APP_WIDTH() {
			this.checkAppState()
			this.changeHeight()
		},
		listState() {
			this.changeHeight()
		},
		tabSelect(val) {
			if (val === 1) this.checkAppState()
		},
	},
	mounted() {
		this.checkAppState()
		this.changeHeight()
	},
	destroyed() {},
	methods: {
		...mapMutations([
			'HOME_MU_INTERIOR_SELECT_INTERIOR',
			'APPBAR_MU_INTERIORSTORE_DRAWER',
			'HOME_MU_INTERIOR_GET_INTERIOR_ITEMS',
		]),
		deleteInterior(item) {
			this.HOME_MU_INTERIOR_SELECT_INTERIOR(item)
		},
		checkAppState() {
			// 화면이 960보다 큰 경우
			this.appWidthState ? (this.listState = true) : (this.listState = false)
		},
		changeHeight() {
			// 화면이 960보다 큰 경우
			if (this.appWidthState) return this.$emit('changeHeight', '90px')
			if (this.listState) this.$emit('changeHeight', '487px')
			else this.$emit('changeHeight', '90px')
		},
		next() {
			this.listState = false
			this.$emit('next')
		},
		openInquireDrawer() {
			if (this.chooseStoreItems?.length !== 0) {
				this.APPBAR_MU_INTERIORSTORE_DRAWER(true)
				// this.HOME_MU_CURRENT_ESTIMATE_CONTENT_INTERIORSELECT(null)
				this.HOME_MU_CURRENT_ESTIMATE_CONTENT_INTERIORSELECT(this.chooseStoreItems)
				///this.HOME_MU_INTERIOR_GET_INTERIOR_ITEMS(this.chooseStoreItems) || null
			}
		},
	},
}
</script>

<style scoped lang="scss">
.select {
	&_count {
		font-size: 1.2rem;
		color: $color_green_6;
		font-weight: bold;
	}
	&_explain {
		font-size: 0.85rem;
		color: $color_gray_9;
	}
}
.selectedStore {
	padding-right: 8px !important;
	&:last-child {
		padding-right: 0 !important;
	}
}
</style>
