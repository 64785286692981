<template>
	<div class="mx-0 my-2">
		<v-col
			class="pa-0"
			cols="12"
			align-self="center"
		>
			<HomeInteriorPriceDetailinquire99Finishform />
		</v-col>
		<div></div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'GreenDongwhaClientRepo05Form',
	// computed: {
	// 	...mapGetters(['AUTH_GET_USER_AUTH']),
	// },
	props: {},
}
</script>

<style lang="scss" scoped></style>
