<template>
	<div class="pt-3 pb-6 pt-md-6 pb-md-8 px-12 green_banner">
		<div class="wrapper tac">
			케어플로서비스는 <br class="hidden-md-and-up" />
			<span class="highlight">원케어</span>와 <span class="highlight">풀케어 <span class="ready">(출시예정)</span></span> 로
			구성됩니다.
		</div>
	</div>
</template>

<script>
export default {
	name: 'HomeCustomCareFloGreenBanner',
	components: {},
	props: [],
	data: () => ({}),
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
}
</script>

<style scoped lang="scss">
.green_banner {
	color: #d2ffe7;
	background-color: #00ce7d;
	font-size: 1.8rem;
	line-height: 1.3;
	.highlight {
		display: inline-block;
		position: relative;
		padding: 0 2px;
		color: #00ce7d;
		background-color: $color_white;
		font-weight: $fw_bold;
		border-radius: 1px;
		line-height: 1.2;
		.ready {
			display: block;
			width: 100%;
			position: absolute;
			bottom: -20px;
			left: 50%;
			transform: translateX(-50%);
			color: $color_white;
			font-size: $font_normal;
			font-weight: $fw_normal;
		}
	}
}
@media all and (max-width: 959px) {
	.green_banner {
		font-size: $font_xl !important;
		line-height: 1.5;
		.highlight {
			line-height: 1.3 !important;
			.ready {
				bottom: -17px !important;
				font-size: $font_sm !important;
			}
		}
	}
}

@media all and (max-width: 360px) {
	.green_banner {
		font-size: $font_normal !important;
		line-height: 1.5;
		.highlight {
			line-height: 1.3 !important;
			.ready {
				bottom: -15px !important;
				font-size: $font_xs !important;
			}
		}
	}
}
</style>
