<template>
	<svg
		width="36"
		height="36"
		viewBox="0 0 36 36"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		class="icn"
	>
		<path
			d="M18 3C9.72 3 3 9.72 3 18C3 26.28 9.72 33 18 33C26.28 33 33 26.28 33 18C33 9.72 26.28 3 18 3ZM10.605 27.42C11.25 26.07 15.18 24.75 18 24.75C20.82 24.75 24.765 26.07 25.395 27.42C23.355 29.04 20.79 30 18 30C15.21 30 12.645 29.04 10.605 27.42ZM27.54 25.245C25.395 22.635 20.19 21.75 18 21.75C15.81 21.75 10.605 22.635 8.46 25.245C6.93 23.235 6 20.73 6 18C6 11.385 11.385 6 18 6C24.615 6 30 11.385 30 18C30 20.73 29.07 23.235 27.54 25.245ZM18 9C15.09 9 12.75 11.34 12.75 14.25C12.75 17.16 15.09 19.5 18 19.5C20.91 19.5 23.25 17.16 23.25 14.25C23.25 11.34 20.91 9 18 9ZM18 16.5C16.755 16.5 15.75 15.495 15.75 14.25C15.75 13.005 16.755 12 18 12C19.245 12 20.25 13.005 20.25 14.25C20.25 15.495 19.245 16.5 18 16.5Z"
			:fill="color"
		/>
	</svg>
</template>

<script>
export default {
	name: 'CommonIconsJoinUserIcon',
	props: ['color'],
}
</script>

<style lang="scss" scoped>
.icn {
	margin-top: 4px;
}
@media all and (max-width: 600px) {
	.icn {
		margin-top: 4px;
		width: 30px !important;
		height: 30px !important;
	}
}
</style>
