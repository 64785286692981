<template>
	<div class="association searchbar-touch-area">
		<!-- pc 상태일때 검색 인풋 -->
		<v-text-field
			v-model="keyword"
			class="search_bar hidden-sm-and-down"
			outlined
			prepend-inner-icon="mdi-magnify"
			append-icon="mdi-camera-outline"
			clearable
			placeholder="깔끔한 인테리어"
			@click="openSearchDialog"
			@click:append="onSearchPicture"
			@keyup.enter="keyUpEnter"
			@keydown="onSearchKeyup($event)"
		>
			<template v-slot:append>
				<v-icon
					class="camera-button"
					color="#78C046"
					@click="onSearchPicture"
					>mdi-camera-outline</v-icon
				>
			</template>
		</v-text-field>

		<!-- 연관검색어 & 최근검색어 -->
		<div class="pos">
			<!-- 최근검색어 -->
			<LayoutAppbarAssociation
				v-if="dialogWebAssociation"
				v-click-outside="onClickOutside"
				@onSearch="on_search_keyword"
				@close="closeDialogWeb"
			/>

			<!-- 연관검색어 -->
			<keepAlive>
				<LayoutAppbarKeyword
					v-if="dialogWebKeyword"
					v-click-outside="onClickOutside"
					:keyword="keyword"
					:arrow-idx="keyupArrowIdx"
					@onSearch="on_search_keyword"
					@updateAutoComList="updateAutoComList"
					@close="closeDialogWeb"
				/>
			</keepAlive>

			<HomeSearchPhotoReco
				v-if="dialogWebPicture"
				v-click-outside="onClickOutside"
				@close="closeDialogWeb"
			/>
		</div>

		<!-- mo상태일때 버튼처리 (팝업이 열림) -->
		<div class="hidden-md-and-up">
			<v-row
				class="ma-0 search_bar_mo clickCursor"
				@click="dialogMobilePop = true"
			>
				<v-col
					class="pa-0 pr-1 icn"
					cols="auto"
					align="start"
					align-self="center"
				>
					<v-icon color="#78C046">mdi-magnify</v-icon>
				</v-col>
				<v-col
					class="pa-0 input"
					align="start"
					align-self="center"
				>
					{{ `${get_keywords || '깔끔한 인테리어'}` }}
				</v-col>
				<v-col
					class="pa-0 pl-1 icn"
					cols="auto"
					align="end"
					align-self="center"
				>
					<v-icon
						color="#78C046"
						@click="event => onSearchPictureDialog(event)"
						>mdi-camera-outline</v-icon
					>
				</v-col>
			</v-row>
		</div>

		<!-- 검색다이얼로그 -->
		<CommonDialogDialogDynamicSearch
			v-if="dialogMobilePop"
			:dialog="dialogMobilePop"
			components-path="layout/appbar/association"
			:width="800"
			@onSearchPictureDialog="onSearchPictureDialog"
			@close="dialogMobilePop = false"
		/>
		<!-- 마루추천다이얼로그 -->
		<CommonDialogDialogDynamicBasic
			v-if="dialogMobilePicturePop"
			:dialog="dialogMobilePicturePop"
			title="마루추천"
			components-path="home/search/photoReco"
			:width="800"
			@close="dialogMobilePicturePop = false"
		/>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import vClickOutside from 'vue-click-outside'
import search from '@/mixins/search'

export default {
	name: 'SearchBar',
	directives: { 'click-outside': vClickOutside },
	mixins: [search],
	props: {},
	data: () => ({
		dialogWebAssociation: false,
		dialogWebKeyword: false,
		dialogWebPicture: false,
		dialogMobilePop: false,
		dialogMobilePicturePop: false,
		keyupArrowIdx: -1,
		autoComList: [],
	}),
	computed: {
		...mapGetters(['APP_GET_APP_STATE', 'HOME_GET_SEARCH_STOP_DIALOGS']),
	},
	watch: {
		keyword(val) {
			if (this.HOME_GET_SEARCH_STOP_DIALOGS) return

			if (val?.length > 0) {
				this.dialogWebAssociation = false
				this.dialogWebKeyword = true
			} else {
				this.dialogWebAssociation = true
				this.dialogWebKeyword = false
			}
		},
	},
	created() {},
	mounted() {},
	methods: {
		openSearchDialog() {
			this.closeDialogWeb()
			if (this.APP_GET_APP_STATE === 'web' && this.keyword?.length > 0) this.dialogWebKeyword = true
			else this.dialogWebAssociation = true
		},
		// 마루추천 PC
		onSearchPicture() {
			this.closeDialogWeb()
			if (this.APP_GET_APP_STATE === 'web' && window.innerWidth > 959) this.dialogWebPicture = !this.dialogWebPicture
			else this.dialogMobilePicturePop = !this.dialogMobilePicturePop
		},
		// 마루추천 Mobile
		onSearchPictureDialog(e) {
			e.stopPropagation()
			this.dialogMobilePicturePop = true
			setTimeout(() => (this.dialogMobilePop = false), 200) // 마루추천 뜨고 난 뒤에 사라지게하기
		},
		onClickOutside(e) {
			if (e.target.classList.contains('searchbar-touch-area') || e.target.closest('.searchbar-touch-area')) return
			this.closeDialogWeb()
		},
		closeDialogWeb() {
			this.keyupArrowIdx = -1
			this.dialogWebAssociation = false
			this.dialogWebKeyword = false
			this.dialogWebPicture = false
		},
		onSearchKeyup(e) {
			if (e.key === 'Process') return // IME변환 무시
			if (this.keyword) {
				if (e.code === 'ArrowUp') {
					if (this.dialogWebKeyword && this.keyupArrowIdx === 0) this.dialogWebKeyword = false
					this.keyupArrowIdx = Math.max(this.keyupArrowIdx - 1, -1)
				}
				// TODO max length
				else if (e.code === 'ArrowDown') {
					if (!this.dialogWebKeyword && this.keyupArrowIdx === -1) this.dialogWebKeyword = true
					if (this.keyupArrowIdx >= this.autoComList.length - 1) this.keyupArrowIdx = 0
					else this.keyupArrowIdx = Math.max(this.keyupArrowIdx + 1, -1)
				}
			} else {
				this.keyupArrowIdx = -1
				this.autoComList = []
			}
		},
		updateAutoComList(val) {
			this.keyupArrowIdx = -1
			this.autoComList = val
		},
		keyUpEnter() {
			if (0 <= this.keyupArrowIdx) this.keyword = this.autoComList[this.keyupArrowIdx].keyword
			this.on_search_keyword(this.keyword)
		},
	},
}
</script>

<style scoped lang="scss">
// 한줄이상 (...)처리
@mixin ellipsis_1 {
	display: -webkit-box !important;
	word-wrap: break-word !important;
	-webkit-line-clamp: 1 !important;
	-webkit-box-orient: vertical !important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
}

// 두줄이상 (...)처리
@mixin ellipsis_2 {
	display: -webkit-box !important;
	word-wrap: break-word !important;
	-webkit-line-clamp: 2 !important;
	-webkit-box-orient: vertical !important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
}

.association {
	.pos {
		// display: none;
		position: absolute;
		top: 70px;
		left: 50%;
		transform: translateX(-50%);
		border: 1px solid $color_gray_4;
		border-radius: 16px;
		box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
		overflow: hidden;
		z-index: 99;
		background-color: $color_white;
	}
}

.search_bar.v-text-field {
	width: 100%;
	background-color: #f8f8f8;
	outline: transparent;
	border-radius: 30px !important;
	box-sizing: border-box !important;
	:deep(.v-input__slot) {
		margin-bottom: 0;
		min-height: 40px;
		padding: 0 14px !important;
	}

	:deep(.v-text-field__details) {
		display: none;
	}
	:deep(fieldset) {
		border-color: transparent;
	}
	:deep(.v-input__prepend-inner) {
		margin-top: 6px !important;
		.v-input__icon {
			height: 30px;
		}
		.v-icon {
			&.mdi-magnify {
				font-size: 30px;
				color: #78c046;
			}
		}
	}
	:deep(.v-input__append-inner) {
		margin-top: 6px !important;
		.v-input__icon {
			height: 30px;
		}
		.v-icon {
			&.mdi-close::before {
				font-size: 20px;
				color: #d8d8d8;
				content: '\F0159';
			}
			&.mdi-camera-outline {
				font-size: 30px;
				color: #78c046;
			}
		}
	}
}
:deep(.v-input--is-focused) {
	background-color: #fff !important;
	border: 1px solid #78c046 !important;
	margin: -1px !important;
	.v-icon {
		&.mdi-magnify,
		&.mdi-camera-outline {
			color: #78c046 !important;
		}
	}
	+ .pos {
		display: block !important;
	}
}

.search_bar_mo {
	width: 100%;
	padding: 8px 12px;
	background-color: #f8f8f8;
	border-radius: 30px !important;
	color: #9e9e9e;
	font-size: $font_normal;
	.icn {
		width: 24px;
	}
	.input {
		@include ellipsis_1;
		width: calc(100% - 56px);
	}
}

@media all and (max-width: 959px) {
	.association {
		.pos {
			display: none !important;
			top: 46px !important;
		}
	}
}
</style>
