<template>
	<!-- 연관검색어 & 최근검색어 -->
	<div
		v-if="autoCompleteList.length > 0"
		class="px-5 keyword_view"
	>
		<v-col
			class="pa-0 py-4 tit"
			align="start"
			>이런 검색어를 찾으시나요?</v-col
		>

		<template>
			<div class="inner">
				<v-row
					v-for="item in autoCompleteList"
					:key="item.keyword"
					class="ma-0 justify-space-between recommend"
					:class="[arrowIdx === item.idx ? 'keyword_hover' : '']"
				>
					<v-col
						class="pa-0"
						align-self="center"
						align="start"
						@click="$emit('onSearch', item.keyword)"
						v-html="item.autoComplete"
					>
					</v-col>
					<!-- <v-col
					class="pa-0"
					align-self="center"
					align="end"
				>
					<v-btn
						class="pa-0 btn_clear"
						text
					>
						<v-icon
							color="#CCC"
							small
						>
							mdi-window-close
						</v-icon>
					</v-btn>
				</v-col> -->
				</v-row>
			</div>
		</template>

		<v-row class="ma-0 mt-2 bottm_close hidden-sm-and-down">
			<v-col
				class="pa-0"
				align="end"
			>
				<v-btn
					class="pa-0 pb-1 btn_close"
					text
					@click="$emit('close')"
				>
					닫기
					<v-icon
						color="#CCC"
						small
					>
						mdi-window-close
					</v-icon>
				</v-btn>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import common from '@/mixins/common'
import Inko from 'inko'

export default {
	name: 'Keyword',
	mixins: [common],
	props: ['keyword', 'arrowIdx'],
	data: () => ({
		inko: null,
		autoCompleteList: [],
	}),
	computed: {
		...mapGetters(['HOME_GET_SEARCH_AUTO_COMPLETE_KEYWORDS']),
	},
	watch: {
		keyword(val) {
			this.autoCompleteList = this.HOME_GET_SEARCH_AUTO_COMPLETE_KEYWORDS.filter(
				x => this.inko.ko2en(x.keyword).toLocaleLowerCase().indexOf(this.inko.ko2en(val).toLocaleLowerCase()) > -1,
			)
			let idx = 0

			if (this.autoCompleteList.length > 0)
				// 일치하는 키워드 스타일 적용
				this.autoCompleteList.forEach(x => {
					x.autoComplete = x.keyword.replace(val, `<span style="color: #00ce7d">${val}</span>`)
					x.idx = idx++
				})
			this.$emit('updateAutoComList', this.autoCompleteList)
		},
		arrowIdx(newVal, oldVal) {
			const keywordView = document.querySelector('.keyword_view')
			const selectedKeyword = document.querySelectorAll('.recommend')[newVal]

			const baseHeight = 124 // 키워드 리스트 첫번째 시작 높이값
			const offset = 36 // 키워드 높이 픽셀값
			const keywordViewPosition = keywordView?.scrollTop
			const selectedKeywordPosition = selectedKeyword?.getBoundingClientRect().top

			if (newVal - oldVal > 0) {
				// 아래로 이동
				const positionY = offset * (newVal - 6)
				if (keywordViewPosition < positionY) {
					keywordView.scrollTo({
						top: positionY,
					})
				}
				if (selectedKeywordPosition < baseHeight) {
					keywordView.scrollTo({
						top: positionY,
					})
				}
			} else {
				// 위로 이동
				let positionY = offset * newVal
				if (selectedKeywordPosition < baseHeight) {
					keywordView.scrollTo({
						top: positionY,
					})
				}
				if (keywordViewPosition < offset * (newVal - 6)) {
					keywordView.scrollTo({
						top: positionY,
					})
				}
			}
		},
	},
	async created() {
		if (this.HOME_GET_SEARCH_AUTO_COMPLETE_KEYWORDS.length <= 0) await this.HOME_ACT_SEARCH_KEYWORD_LIST()

		this.inko = new Inko()
		this.inko.config({
			allowDoubleConsonant: false,
		})
	},
	mounted() {},
	methods: {
		...mapActions(['HOME_ACT_SEARCH_KEYWORD_LIST']),
	},
}
</script>

<style scoped lang="scss">
@mixin ellipsis_1 {
	display: -webkit-box !important;
	word-wrap: break-word !important;
	-webkit-line-clamp: 1 !important;
	-webkit-box-orient: vertical !important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
}

.keyword_view {
	width: 100%;
	max-width: 420px;
	min-width: 420px;
	max-height: 340px !important;
	padding: 0 0 10px;
	padding-bottom: 40px !important;
	background-color: $color_white;
	overflow-y: auto;
	&::-webkit-scrollbar {
		width: 12px;
		background-color: $color_gray_2 !important;
	}
	&::-webkit-scrollbar-thumb {
		background-color: $color_gray_4 !important;
		border-radius: 99px;
	}

	.tit {
		position: sticky;
		top: 0;
		font-size: $font_normal;
		font-weight: $fw_bold;
		background-color: $color_white;
	}
	.inner {
		// max-height: 250px;
		// overflow-y: auto;
		// &::-webkit-scrollbar {
		// 	width: 12px;
		// 	background-color: $color_gray_2 !important;
		// }
		// &::-webkit-scrollbar-thumb {
		// 	background-color: $color_gray_4 !important;
		// 	border-radius: 99px;
		// }
	}
	.bottm_close {
		width: calc(100% - 40px);
		margin: 0 auto;
		position: fixed;
		bottom: 0;
		background-color: $color_white;
	}

	.btn_clear {
		min-width: 0;
	}

	.btn_all_del {
		min-width: 0;
		:deep(.v-btn__content) {
			color: $color_gray_7;
			border-bottom: 1px solid $color_gray_7;
			font-size: $font_normal;
			font-weight: $fw_normal;
		}
	}
	.btn_close {
		min-width: 0;
		:deep(.v-btn__content) {
			color: $color_gray_7;
			font-size: $font_normal;
			font-weight: $fw_normal;
		}
	}
}

.recommend {
	height: 36px;
	font-size: $font_normal;
	color: $color_gray_7;
	cursor: pointer;
	.match {
		color: $color_state_posi;
	}
	&:hover {
		background-color: $color_gray_1 !important;
	}
}
.keyword_hover {
	background-color: $color_gray_1 !important;
}
@media all and (max-width: 959px) {
	.keyword_view {
		min-width: unset !important;
		max-width: unset !important;
		.inner {
		}
	}
}
</style>
