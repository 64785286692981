<template>
	<v-row class="ma-0">
		<v-col
			class="py-5 px-4 py-md-10 sticker_txt"
			cols="12"
			md="7"
		>
			<p class="tit mb-4 mb-md-5">케어플로 인증스티커를 확인하세요!</p>
			<p class="sub mb-4 mb-md-3">
				동화가 인증하는 전문점에서 <strong>시공된 현장에 부착</strong>되며,<br class="hidden-sm-and-down" />
				스티커를 통해 <strong>케어플로 서비스</strong>를 받으실 수 있습니다.
			</p>
			<p class="notice">
				* 각각의 현장마다 고유한 일련번호를 가진 케어플로 인증 스티커가 부착되며, 개별 현장의 유통경로, 시공제품,
				고객정보가 통합관리됩니다.
			</p>
		</v-col>
		<v-col
			class="pa-0"
			cols="12"
			md="5"
		>
			<v-img
				width="100%"
				height="100%"
				eager
				:src="require('@/assets/img/careflo/img_nfc_sticker.svg')"
			/>
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: 'HomeCustomCareFloSticker',
	components: {},
	props: [],
	data: () => ({}),
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
}
</script>

<style scoped lang="scss">
.sticker_txt {
	word-break: keep-all;
	.tit {
		font-weight: $fw_bold;
		font-size: $font_tit;
		color: #d2ffe7;
	}
	.sub {
		font-size: $font_lg;
		color: $color_white;
	}
	.notice {
		font-size: $font_sm;
		color: $color_white;
	}
}
@media all and (max-width: 959px) {
	.sticker_txt {
		.tit {
			font-size: $font_xl !important;
		}
		.sub {
			font-size: $font_normal !important;
		}
	}
}
</style>
