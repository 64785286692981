<template>
	<div class="mb-5 mx-0 item">
		<v-col class="pa-0 bg_knowhow">
			<v-col
				class="pa-0 d-flex"
				align="start"
				align-self="center"
				cols="6"
			>
				<span class="flag best">Best</span>
			</v-col>
			<div class="category">
				<a
					href="javascript:;"
					class="mb-3 d-flex align-center clickCursor"
				>
					<p class="mr-1">베스트 제품</p>
				</a>

				<span> 동화자연마루의 베스트셀러를 만나보세요! </span>
			</div>
		</v-col>

		<v-col class="px-4 py-5 px-lg-5 pb-lg-4 cont">
			<v-container class="pa-0">
				<v-carousel
					v-if="items.length"
					ref="carousel"
					v-model="currentIndex"
					hide-delimiters
					hide-delimiter-background
					:show-arrows="false"
					class="slide_type03"
					:interval="interval"
					height="auto"
					cycle
				>
					<v-carousel-item
						v-for="(slide, i) in filterItems"
						:key="i"
					>
						<div
							cols="12"
							class="pb-4"
						>
							<v-fade-transition mode="out-in">
								<v-row>
									<v-col
										v-for="(item, j) in slide"
										:key="j"
										cols="6"
										class="ma-0 pb-2 content"
										align="center"
										@click="pushLink(item)"
									>
										<!-- @click="pushLink(item)" -->
										<!-- <CommonCardMenuCardDefaultBest new-tab-link :item="item" /> -->
										<div class="bestThumb clickCursor">
											<div>
												<v-img
													:src="item.image"
													eager
													cover
													height="auto"
													class="bg-grey-lighten-2"
												>
													<!-- <v-chip class="ma-0 flag" color="primary" label>
                            BEST
                          </v-chip> -->
												</v-img>
											</div>
											<div
												v-if="item.brand_name"
												class="theme mt-2 px-2"
											>
												<span class="brand_info">
													{{ item.brand_name }} {{ item.goods_name }}

													{{ item.pattern_name }}
												</span>
											</div>
											<div
												v-if="item.kind_name"
												class="item_category mb-2 title px-2"
											>
												{{ item.kind_name }}
											</div>
										</div>
									</v-col>
								</v-row>
							</v-fade-transition>

							<!-- <v-row
              v-for="(item, j) in slide"
              :key="j"
              class="ma-0 mb-2 content clickCursor"
              fill-height
              align="center"
            >
              <CommonCardMenuCard01 />
            </v-row> -->
						</div>
					</v-carousel-item>
				</v-carousel>
				<div class="carousel-nav">
					<button @click="prev(currentIndex)">
						<v-img
							:src="require('@/assets/img/home/main/img_slide_left_arrow_gray.svg')"
							eager
						/>
					</button>
					<span class="page_count">
						<em>{{ currentIndex + 1 }}</em> / {{ Math.ceil(items.length / 4) }}
					</span>
					<button @click="next(currentIndex)">
						<v-img
							:src="require('@/assets/img/home/main/img_slide_right_arrow_gray.svg')"
							eager
						/>
					</button>
				</div>
			</v-container>
		</v-col>
	</div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
	name: 'HomeMainNewBest',
	props: ['items'],
	data: () => ({
		currentIndex: 0,
		interval: 5000,
	}),
	computed: {
		filterItems() {
			let items = []
			let item = []
			this.items.forEach((element, i) => {
				if (i % 4 === 0) {
					item = []
					items.push(item)
				}
				item.push(element)
			})
			return items
		},
		currentTitle() {
			return this.items[this.currentIndex]
		},
	},
	created() {},
	mounted() {},
	methods: {
		...mapMutations(['APP_MU_RECENT_PRODUCT']),
		pushLink(item) {
			this.APP_MU_RECENT_PRODUCT(item)
			this.$router.push(`/product/detail/${item.product_id}`)
		},
		next() {
			this.currentIndex += 1
		},
		prev() {
			this.currentIndex -= 1
		},
	},
}
</script>

<style lang="scss" scoped>
// 두줄이상 (...)처리
@mixin ellipsis_2 {
	display: -webkit-box !important;
	word-wrap: break-word !important;
	-webkit-line-clamp: 2 !important;
	-webkit-box-orient: vertical !important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
}

@mixin ellipsis {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	word-break: keep-all;
	overflow: hidden;
	text-overflow: ellipsis;
}

.flag {
	padding: 6px 10px;
	border-radius: 99px;
	color: $color_white;
	line-height: 1 !important;
	&.best {
		padding: 4px 6px 4px 6px;
		font-size: $font_normal;
		font-weight: $fw_normal;
		background-color: $color_acc_1;
		margin: 15px 0 0 15px;
	}
}

.v-window.slide_type03 {
	overflow: inherit;
	border-radius: 10px;
	height: auto !important;
	.bestThumb {
		border-radius: 16px !important;
		overflow: hidden;
		border: 1px solid #e6e6e6;
		:deep(.v-image) {
			max-height: 170px;
			object-fit: cover;
			aspect-ratio: 1/1;
		}
		.theme {
			display: flex;
			align-items: center;
			height: 42px;
			span {
				@include ellipsis_2;
			}
		}
		.item_category {
			font-size: $font_normal !important;
			font-weight: $fw_normal;
			color: $color_gray_5;
			text-align: left;
		}
		.brand_info {
			font-weight: $fw_semibold;
		}
	}
	:deep(.v-carousel__controls) {
		bottom: -33px;
	}
	:deep(.v-image__image--contain) {
		background-size: cover !important;
	}
	:deep(.v-item-group) {
		button::before {
			display: none;
		}
		.v-btn--icon {
			width: 10px;
			height: 10px;
		}
		.v-item--active.v-btn--active .v-icon.notranslate {
			background-color: #42883d;
		}
		.v-icon.notranslate {
			width: 10px;
			height: 10px;
			justify-content: space-between;
			background-color: #ccc;
			opacity: 1;
			border-radius: 4px;
		}
		.v-icon.notranslate::before {
			display: none !important;
		}
		.theme {
			text-align: left;
			padding: 0 15px;
			min-height: 42px;
			display: flex;
			align-items: center;
		}
	}

	:deep(.theme) {
		text-align: left;
	}

	.text_area {
		max-width: 360px;
		position: absolute;
		bottom: 50%;
		transform: translate(0, 50%);
		right: 20px;
		background-color: rgba(1, 1, 1, 0.5);

		:deep(.v-image) {
			height: 36px !important;
			margin-bottom: 5px;
		}
		:deep(.v-image__image--contain) {
			background-size: inherit !important;
		}
	}
}

.item {
	background-color: $color_white;
	border-radius: 16px;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
	overflow: hidden;

	.category {
		color: $color_white;
		a {
			color: $color_white;
			text-decoration: none;
			p {
				font-size: $font_tit;
				font-weight: $fw_bold;
				line-height: 1 !important;
			}
			span {
				word-break: keep-all;
				font-size: $font_normal;
				font-weight: $fw_normal;
			}
		}
	}

	.cont {
		position: relative;
		margin-top: -20px;
		border-radius: 16px !important;
		background-color: $color_white;
		h3 {
			color: $color_font;
			font-size: $font_xl;
			font-weight: $fw_bold;
		}
	}

	.bg_knowhow {
		position: relative;
		width: 100%;
		height: 205px;
		background: url('../../../../assets/img/home/main/bg_best.png') no-repeat;
		background-size: cover;
		background-position: top;
		.category {
			position: absolute;
			bottom: 15%;
			left: 20px;
		}
		&::after {
			content: '';
			background: linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
			width: 100%;
			height: 100%;
			display: block;
		}
	}
}

.carousel-nav {
	width: 78px;
	height: 26px;
	left: 15px;
	padding: 10px;
	border: 1px solid #e6e6e6;
	border-radius: 220px;
	gap: 10px;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;
	.page_count {
		color: #777777;
		font-weight: $fw_normal;
		font-size: $font_sm;
		em {
			font-style: normal;
			font-weight: $fw_semibold;
		}
	}
}

@media all and (max-width: 1264px) {
	.item {
		.cont {
			margin-top: 0 !important;
			border-radius: 0 !important;
		}
		.bg_maru_tv {
			padding: 20px 16px !important;
		}
		.bg_knowhow {
		}
	}
	.v-window.slide_type03 {
		.bestThumb {
			:deep(.v-image) {
				max-height: 144px;
				width: 100%;
			}
		}
	}
}

@media all and (max-width: 600px) {
	.item {
		border-radius: 0 !important;
		.bg_knowhow {
			height: 205px;
			background-size: cover;
			background-position: 0px 0;
			.category {
				line-height: 32px;
				bottom: 10%;
			}
		}
	}
	.v-window.slide_type03 {
		.bestThumb {
			:deep(.v-image) {
				max-height: 144px;
			}
		}
	}
}

@media all and (max-width: 380px) {
	.item {
		.bg_knowhow {
			height: 130px;
			max-height: 130px;
			.category {
				p {
					font-size: $font_xl !important;
					line-height: 22px !important;
				}
				span {
					font-size: $font_sm !important;
				}
			}
		}
		.cont {
			h3 {
				font-size: $font_lg !important;
			}
		}

		.main_youtube {
			.desc {
				font-size: $font_normal !important;
			}
		}
	}
}
</style>
