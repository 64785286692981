<template>
	<div class="wrapper">
		<div class="custom_quote">
			<v-col
				cols="12"
				class="pa-0 register_history inner"
				align="center"
			>
				<div class="">
					<v-col
						class="pa-0"
						cols="12"
						align="center"
					>
						<v-col
							cols="12"
							class="pa-0 table"
						>
							<div class="mb-5">
								<p
									class="tit"
									align="start"
								>
									간편상담 신청내역 확인
								</p>
								<v-row class="finalCont mb-4">
									<p
										class="tit pb-1"
										align="start"
									>
										신청자 정보
									</p>
									<v-col
										v-if="estimateItems"
										cols="12"
										class="innerBox02 pa-0"
									>
										<div class="d-flex align-items-center my-1">
											<div
												v-if="
													estimateItems.login_id &&
													!estimateItems.login_id.includes('@') &&
													!estimateItems.login_id.includes('010')
												"
												class="listItem"
											>
												<!-- <p class="font_sm color_gray_6">{{ selectedItem.item[0].kind_name }}</p> -->
												<p class="font_sm color_gray_6">신청번호</p>
												<p>{{ estimateItems?.consult_id }}</p>
											</div>
											<div
												v-else
												class="listItem"
											>
												<!-- <p class="font_sm color_gray_6">{{ selectedItem.item[0].kind_name }}</p> -->
												<p class="font_sm color_gray_6">신청번호</p>
												<p>{{ estimateItems?.consult_id }}</p>
											</div>
										</div>

										<div class="d-flex align-items-center my-1">
											<div
												v-if="
													estimateItems.login_id &&
													!estimateItems.login_id.includes('@') &&
													!estimateItems.login_id.includes('010')
												"
												class="listItem"
											>
												<!-- <p class="font_sm color_gray_6">{{ selectedItem.item[0].kind_name }}</p> -->
												<p class="font_sm color_gray_6">이름</p>
												<p>{{ estimateItems?.login_id }}</p>
											</div>
											<div
												v-else
												class="listItem"
											>
												<!-- <p class="font_sm color_gray_6">{{ selectedItem.item[0].kind_name }}</p> -->
												<p class="font_sm color_gray_6">이름</p>
												<p>{{ estimateItems?.name }}</p>
											</div>
										</div>
										<div class="d-flex align-items-center my-1">
											<div
												v-if="estimateItems.login_id && !estimateItems.login_id.includes('@')"
												class="listItem"
											>
												<!-- <p class="font_sm color_gray_6">{{ selectedItem.item[0].kind_name }}</p> -->
												<p class="font_sm color_gray_6">휴대폰 번호</p>
												<p>{{ estimateItems?.login_id }}</p>
											</div>
											<div
												v-else
												class="listItem"
											>
												<!-- <p class="font_sm color_gray_6">{{ selectedItem.item[0].kind_name }}</p> -->
												<p class="font_sm color_gray_6">휴대폰 번호</p>
												<p>{{ estimateItems?.hand }}</p>
											</div>
										</div>
										<div
											v-if="estimateItems.content"
											class="d-flex align-items-center mt-1"
										>
											<div class="listItem">
												<p class="font_sm color_gray_6">문의사항</p>
												<p>{{ estimateItems?.content }}</p>
											</div>
										</div>
									</v-col>
									<v-col
										v-if="
											estimateItems.product_kind === '견적내기' || estimateItems.product_kind === '빠른견적'
										"
										class="pa-0 td nearArea"
										cols="12"
										sm="12"
										lg="12"
										align="start"
										align-self="start"
									>
										<v-expansion-panels
											v-model="panel"
											flat
											multiple
											class="panel"
										>
											<v-expansion-panel v-model="panel">
												<v-expansion-panel-header>
													<span class="innerTitle"> 맞춤 견적 사항 </span>
												</v-expansion-panel-header>
												<v-expansion-panel-content class=""
													><div>
														<div
															v-if="
																estimateItems !== null ||
																estimateItems.exclusive_area !== null ||
																estimateItems.goods_name
															"
															class="detailWrap"
														>
															<div
																v-if="estimateItems?.housetype"
																class="d-flex"
															>
																<span class="detailTit">주거형태</span>
																<span>
																	{{ estimateItems.housetype }}
																</span>
															</div>
															<div
																v-if="estimateItems.exclusive_area"
																class="d-flex"
															>
																<span class="detailTit">전용면적</span>
																<span>
																	{{ exclusive_area[0].text }}
																</span>
															</div>
															<div
																v-if="estimateItems?.roomcount"
																class="d-flex"
															>
																<span class="detailTit">방개수</span>
																<span> {{ estimateItems.roomcount }}개 </span>
															</div>
															<!-- <div
														v-if="selectedItem?.roomcount"
														class="d-flex"
													>
														<span class="detailTit">방개수</span>
														<span> {{ selectedItem.roomcount }}개 </span>
													</div> -->
															<div
																v-if="estimateItems?.veranda_yn"
																class="d-flex"
															>
																<span class="detailTit">베란다 확장 여부</span>
																<span>
																	{{ estimateItems.veranda_yn }}
																</span>
															</div>
															<div
																v-if="estimateItems?.buildscope"
																class="d-flex"
															>
																<span class="detailTit">시공 범위</span>
																<span>
																	{{ estimateItems.buildscope }}
																</span>
															</div>
															<div
																v-if="estimateItems?.goods_name"
																class="d-flex"
															>
																<span class="detailTit">시공 제품</span>
																<span>
																	{{
																		estimateItems.goods_name === '그란데'
																			? good_name_list.gc_01
																			: estimateItems.goods_name === ' '
																			? good_name_list.gc_01
																			: estimateItems.goods_name === '베이직'
																			? good_name_list.gc_02
																			: estimateItems.goods_name === '125'
																			? good_name_list.gc_03
																			: estimateItems.goods_name
																	}}
																</span>
															</div>
															<div
																v-if="estimateItems?.removal_yn"
																class="d-flex"
															>
																<span class="detailTit">철거유무</span>
																<span>
																	{{ estimateItems.removal_yn }}
																</span>
															</div>
															<div
																v-if="estimateItems?.est_cost"
																class="d-flex"
															>
																<span class="detailTit">예상견적</span>
																<span> {{ estimateItems.est_cost }}만원 </span>
															</div>
														</div>
														<!-- <div
													v-if="nexttointerior.length !== 0"
													class="exist"
												>
													<span
														v-for="(item, idx) in nearinterior"
														:key="idx"
														class=""
														style="width: 100%"
													>
														<span
															v-if="askThatInterior !== true"
															class="nearItem"
														>
															<span class="">
																{{ item.business_name }}
																<span class="nearAddr"> {{ `${item.addr_cut}` }}</span>
															</span>
															<v-img
																class="ml-1 cancelButton"
																max-width="16"
																height="16"
																eager
																:src="
																	require('@/assets/img/home/interior/ico_interiorstor_close.svg')
																"
																@click="selectCancel(item, idx)"
															/>
														</span>
													</span>
												</div> -->
													</div></v-expansion-panel-content
												>
											</v-expansion-panel>
										</v-expansion-panels>
									</v-col>
									<!-- <div class="pt-3">
								<div v-if="selectedItem.interiorSelect !== null">
									<p class="titType01">상담 인테리어점</p>
									<ul class="innerBox py-1">
										<li
											v-for="(ele, idx) in selectedItem.interiorSelect"
											:key="idx"
											class="my-2"
										>
											{{ ele.business_name }}
										</li>
									</ul>
								</div>
								<div v-else>
									<p class="titType01">상담 인테리어점</p>
									<ul class="innerBox py-1">
										<li
											v-for="(ele, idx) in selectedItem.interiorSelectauto"
											:key="idx"
											class="my-2"
										>
											{{ ele.business_name }}
										</li>
									</ul>
								</div>
							</div> -->
									<div v-if="estimateItems.card_list">
										<div v-if="estimateItems.product_kind">
											<p class="titType02 font_sm pt-1">문의콘텐츠</p>
											<div class="innerBox02 contentArea">
												<div class="d-flex align-items-center">
													<!-- <v-img
											class="hidden-md-and-down mr-2"
											width="56"
											height="56"
											style="flex: none"
											:src="require(`${selectedItem.item[0].image_url}`)"
										/> -->
													<!-- <img
											:src="`${selectedItem.item.image_url} ? ${selectedItem.item.image_url} : ${selectedItem.item.image}`"
											class="hidden-md-and-down mr-2 askcontImg"
										/> -->
													<router-link
														:to="
															estimateItems.product_kind === '전문가집들이관리'
																? `/housewarming/specialist/detail/${estimateItems.card_list.item_id}`
																: estimateItems.product_kind === '3D집들이관리'
																? `/housewarming/3d/detail/${estimateItems.card_list.item_id}`
																: estimateItems.product_kind === '상품마스터'
																? `/product/detail/${estimateItems.card_list.item_id}`
																: ''
														"
														><v-img
															eager
															class="mr-2 askcontImg"
															:src="
																estimateItems.card_list?.image_url
																	? estimateItems.card_list.image_url
																	: estimateItems.image
															"
													/></router-link>
													<div class="d-flex flex-column justify-center">
														<!-- <p class="font_sm color_gray_6">{{ selectedItem.item[0].kind_name }}</p> -->
														<p class="font_sm color_gray_6">
															{{ estimateItems.card_list.top_title }}
														</p>
														<p class="contSub">{{ estimateItems.card_list?.title }}</p>
													</div>
												</div>
											</div>
										</div>
										<div v-else>
											<p class="titType02 font_sm pt-1">제품상세</p>
											<div class="innerBox02">
												<div class="">
													<p class="font_sm color_gray_6">{{ estimateItems.card_list.product_kind }}</p>
													<p>{{ estimateItems.card_list.sub_line }}</p>
												</div>
											</div>
										</div>
									</div>
								</v-row>

								<!-- 상담 인테리어점 -->
								<v-row class="ma-0 finalCont02 pt-2">
									<v-col
										v-if="interiorItems.length"
										class="pa-0"
									>
										<v-row
											class="ma-0"
											align="center"
										>
											<v-col
												class="pa-0 tit"
												cols="12"
												align="start"
											>
												상담 인테리어점
											</v-col>
											<v-col
												class="pa-0 td"
												cols="12"
												align="start"
											>
												<v-col
													v-for="(item, i) in interiorItems"
													:key="i"
													cols="12"
													align="start"
													class="pa-0"
												>
													<UserItemEstimateInteriorCard02 :item="item" />
												</v-col>
											</v-col>
										</v-row>
									</v-col>
								</v-row>

								<!-- <p class="titType03">신청자 정보</p>
						<v-row class="ma-0 my-2">
							<v-col
								class="pa-0 mb-lg-0 th"
								cols="12"
								lg="3"
								align="start"
							>
								<p class="color_gray_6">이름</p>
							</v-col>
							<v-col
								class="pa-0 mb-lg-0 td"
								cols="12"
								lg="9"
								align="start"
							>
								<v-col class="pa-0"> {{ selectedUserinfo?.name }} </v-col>
							</v-col>
						</v-row>
						<v-row class="ma-0 my-2">
							<v-col
								class="pa-0 mb-lg-0 th"
								cols="12"
								lg="3"
								align="start"
							>
								<p class="color_gray_6">휴대폰 번호</p>
							</v-col>
							<v-col
								class="pa-0 mb-lg-0 td"
								cols="12"
								lg="9"
								align="start"
							>
								<v-col class="pa-0"> {{ selectedUserinfo?.hand }} </v-col>
							</v-col>
						</v-row>
						<v-row class="ma-0 my-2">
							<v-col
								class="pa-0 mb-lg-0 mb-lg-0 th"
								cols="12"
								lg="3"
								align="start"
							>
								<p class="color_gray_6">문의사항</p>
							</v-col>
							<v-col
								class="pa-0 mb-lg-0 td"
								cols="12"
								lg="9"
								align="start"
							>
								<v-col class="pa-0"> {{ selectedItem?.askContents }} </v-col>
							</v-col>
						</v-row> -->
							</div>
						</v-col>

						<!-- <v-row class="ma-0 step">
					<v-col
						class="pa-0 item"
						cols="12"
						md="12"
						align="left"
					>
						<p class="font_normal color_gray_8">
							영업일 기준 3~4일 내에 상담 인테리어점를 통해<br />고객님의 문의 내용을 기반하여 맞춤 전화 상담이 진행
							예정이에요!
						</p>
						<v-col
							cols="12"
							sm="12"
							lg="12"
							class="pb-2 px-0"
						>
							<CommonButtonsButton01
								name="신청내역 보기"
								color="#666666"
								outlined
								class-name="edge_8 fw_normal font_16 btn_upload02"
								height="42"
								radius="4"
								@click="confirmMySubmit"
							/>
						</v-col>
						<p class="py-3 font_sm color_gray_5">
							입력하신 정보는 인테리어점 담당자에게만 전달됩니다. <br />동화자연마루는 인테리어점과 소비자 직거래
							중계 플랫폼으로 <br />판매에 대한 책임이 없습니다.
						</p>
						<v-col
							cols="12"
							sm="12"
							lg="12"
							class="pb-2 px-0"
						>
							<CommonButtonsButton01
								name="확인"
								color="#00592D"
								class-name="edge_8 fw_bold font_16 btn_upload02"
								height="42"
								radius="4"
								@click="backToPage"
							/>
						</v-col>
					</v-col>
				</v-row> -->
					</v-col>
				</div>
			</v-col>
		</div>

		<v-col cols="12">
			<div
				class="mt-10 mb-10 d-flex justify-center"
				align="center"
			>
				<CommonButtonsButton02
					v-if="listType === 'user'"
					name="삭제"
					class-name="btn_large mr-1"
					color="#a0a1a5"
					@click="confirmRemove = true"
				/>
				<CommonButtonsButton02
					v-if="listType === 'user'"
					name="목록"
					class-name="btn_large ml-1"
					color="#262626"
					outlined
					@click="goFirstTab"
				/>
				<CommonButtonsButton02
					v-if="listType === 'guest'"
					name="닫기"
					class-name="btn_large ml-1"
					color="#262626"
					outlined
					@click="$emit('back')"
				/>
			</div>
		</v-col>

		<CommonConfirm
			:dialog="confirmRemove"
			:title="`견적상담 내용 삭제`"
			:text="`선택하신 견적상담 내용이 삭제 됩니다. <br/> 그래도 삭제하시겠습니까?`"
			@close="confirmRemove = false"
			@submit="removeData"
		/>
	</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import common from '@/mixins/common'
import sms from '@/mixins/sms'

export default {
	name: 'HomeInteriorPriceNewFinalinquireCommonFormMember',
	mixins: [common, sms],
	props: {
		listType: {
			type: String,
			default: 'user',
		},
		userData: {
			type: Object,
			default: () => {},
		},
	},
	data: () => ({
		newInteriorItems: [],
		panel: [0],
		confirmRemove: false,
		addQuestionModel: [],
		addQuestionItems: [],
		interiorItems: [],
		estimateItems: {},
		exclusive_area: null,
		homeTypeItems1: [
			{ title: '아파트', value: '아파트' },
			{ title: '오피스텔', value: '오피스텔' },
			{ title: '빌라/주택', value: '빌라/주택' },
		],
		homeTypeItems2: [
			{ title: '강마루', value: 'KC_00001' },
			{ title: '강화마루', value: 'KC_00002' },
			{ title: '원목마루', value: 'KC_00003' },
		],
		good_name_list: {
			gc_01: '강마루',
			gc_02: '강화마루',
			gc_03: '원목마루',
		},
	}),
	computed: {
		...mapGetters([
			'HOME_GET_INTERIOR_SELECT_INTERIOR',
			'HOME_GET_INTERIOR_GET_INTERIOR_ITEMS',
			'HOME_GET_INTERIOR_SELECT_PRICE',
			'AUTH_GET_USER',
			'USER_GET_ESTIMATE_SELECT',
			'HOME_GET_INTERIOR_ESTIMATE_EXCLUSIVE_AREA',
			'HOME_GET_INTERIOR_ESTIMATE_PRODUCT',
		]),
		// checkInteriorItem() {
		// 	return this.selectedItem
		// },
	},
	watch: {
		// checkInteriorItem(newval) {
		// 	if (newval) {
		// 		this.checkInteriorItems(newval)
		// 	}
		// },
	},
	async created() {
		if (this.listType === 'user') {
			if (Object.keys(this.USER_GET_ESTIMATE_SELECT).length === 0) this.$router.go(-1)
			await this.getData()
		} else {
			if (Object.keys(this.userData).length !== 0) {
				this.parseInteriorItems(this.userData)
				this.estimateItems = this.userData
			}
		}
		// 시공 추가문의 항목
		await this.ADMIN_ACT_PRODUCT_GET_PUBLIC_CODE_ITEMS({ group_code: '601' }).then(res => {
			// 기타 마지막으로 보내기
			let etc = res[0]
			res.shift()
			res.push(etc)
			this.addQuestionItems = res
		})
	},
	mounted() {},
	destroyed() {},
	methods: {
		...mapMutations(['APP_MU_DIALOG']),
		...mapActions([
			'USER_ACT_ESTIMATE',
			'ADMIN_ACT_PRODUCT_GET_PUBLIC_CODE_ITEMS',
			'HOME_ACT_INTERIOR_ESTIMATE_EXCLUSIVE_AREA',
			'HOME_ACT_INTERIOR_ESTIMATE_PRODUCT',
		]),

		backToPage() {
			this.$router.go(-1)
		},
		confirmMySubmit() {
			if (this.AUTH_GET_USER_AUTH === 'USER') return this.$router.push('/user?tab=2')
			else if (this.AUTH_GET_USER_AUTH === 'GUEST') return this.$router.push('/interior/mypage/guest')
		},
		checkInteriorItems(newval) {
			for (let i = 1; i <= 3; i++) {
				if (newval[`corporate_no${i}`] !== null) {
					this.newInteriorItems.push(newval[`corporate_no${i}`])
				}
			}
		},

		async getData() {
			await this.USER_ACT_ESTIMATE({
				method: 'detail',
				query: this.USER_GET_ESTIMATE_SELECT.consult_id,
			}).then(res => {
				this.estimateItems = res[0]
				this.parseInteriorItems(this.estimateItems)
			})

			// 전용면적, 방 데이터 가져오기
			if (this.exclusive_area === null) {
				await this.HOME_ACT_INTERIOR_ESTIMATE_EXCLUSIVE_AREA() // 평수
				await this.HOME_ACT_INTERIOR_ESTIMATE_PRODUCT() //제품목록
			}
			if (this.HOME_GET_INTERIOR_ESTIMATE_EXCLUSIVE_AREA.length !== 0) {
				this.exclusive_area = this.HOME_GET_INTERIOR_ESTIMATE_EXCLUSIVE_AREA.filter(
					v => v.exclusive_area === this.estimateItems.exclusive_area,
				)
			}
		},
		parseInteriorItems(data) {
			for (let i = 0; i < 3; i++) {
				const num = i + 1
				if (data[`corp_card_list${num}`]) this.interiorItems.push(data[`corp_card_list${num}`])
			}
		},
		async removeData() {
			await this.USER_ACT_ESTIMATE({
				method: 'del',
				params: [{ consult_id: this.USER_GET_ESTIMATE_SELECT.consult_id }],
			}).then(async () => {
				await this.APP_MU_DIALOG({
					dialog: true,
					title: '견적상담 내용 삭제',
					text: '해당 내용을 삭제하였습니다.',
				})
				//this.$router.go(-1)
				this.$router.push('/user')
			})
		},
		goFirstTab() {
			this.$router.push('/user')
		},
	},
}
</script>

<style scoped lang="scss">
// 두줄이상 (...)처리
@mixin ellipsis_2 {
	display: -webkit-box !important;
	word-wrap: break-word !important;
	-webkit-line-clamp: 2 !important;
	-webkit-box-orient: vertical !important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
}

.contSub {
	@include ellipsis_2;
}

.tit {
	font-weight: $fw_bold;
	color: #262626;
	font-size: $font_xl;
	padding-bottom: 20px;
	letter-spacing: -0.7px;
}
.finalCont {
	border: 1px solid #cccccc;
	border-radius: 8px;
	margin: 0;
	padding: 16px;
	flex-direction: column;
	flex-wrap: initial;

	.tit {
		font-weight: $fw_bold;
		color: #262626;
		font-size: $font_lg;
		letter-spacing: -0.7px;
		padding-bottom: 0;
	}

	.innerBox {
		background-color: $color_gray_1;
		padding: 0 10px;
		margin: 10px 0;
		border-radius: 4px;
		li {
			list-style: none;
			text-align: left;
		}
	}

	.innerBox02 {
		//background-color: $color_gray_1;
		text-align: left;
		img {
		}

		.listItem {
			width: 100%;
			display: flex;
			align-items: center;
			p {
				&:nth-of-type(1) {
					width: 30%;
					min-width: 80px;
				}
				&:nth-of-type(2) {
				}
			}
		}
	}

	.contentArea {
		background-color: $color_gray_1;
		padding: 8px 14px;
		margin-top: 6px;
		border-radius: 4px;
	}
}

.finalCont02 {
	.tit {
		font-weight: $fw_bold;
		color: #262626;
		font-size: $font_lg;
		letter-spacing: -0.7px;
		padding-bottom: 0;
	}
}

.titType01 {
	color: $color_font;
	font-size: $font_normal;
	text-align: left;
	font-weight: $fw_bold;
}
.titType02 {
	color: $color_gray_6;
	font-size: $font_normal;
	text-align: left;
}
.titType03 {
	color: $color_font;
	font-size: $font_lg;
	text-align: left;
	font-weight: $fw_regular;
}

.askcontImg {
	flex: none;
	max-width: 56px;
	max-height: 56px;
	height: 56px;
	border-radius: 4px;
}

.custom_quote {
	.title {
		max-width: 622px;
		margin: 0 auto;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.inner {
		max-width: 622px;
		padding: 30px 30px 30px !important;
		//margin: 30px auto;
		background-color: $color_white;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
		border-radius: 16px;
		position: relative;
		z-index: 3;
		top: 30px;
		h3 {
			font-size: $font_xl;
		}
		.confirmMember {
			p {
				font-size: $font_lg;
				color: #262626;
				font-weight: $fw_regular;
			}
			a {
				font-size: $font_normal;
				color: #666666;
				text-decoration: underline;
				font-weight: $fw_normal;
			}
		}
		.nonmemberConfirm {
			max-width: 840px;
		}
	}
}

.panel {
	border: 1px solid #cccccc;
	border-radius: 8px;
	background-color: #f8f8f8;
	margin-top: 16px;
	.innerTitle {
		font-size: $font_normal;
		color: #555555;
	}
	.detailWrap {
		> div {
			padding: 2px 0;
		}
	}
	.detailTit {
		color: #777777;
		font-size: $font_normal;
		min-width: 150px;
	}
	:deep(.v-expansion-panel-header) {
		padding: 16px;
		background-color: #f8f8f8;
		min-height: 42px;
		border-radius: 8px;
	}
	:deep(.v-expansion-panel-content) {
		background-color: #f8f8f8;
		padding: 0 16px 16px !important;
	}
	:deep(.v-expansion-panel-header--active) {
		background-color: #f8f8f8;
	}
	:deep(.v-expansion-panel-content) {
		padding: 0;
	}
	:deep(.v-expansion-panel-content__wrap) {
		padding: 0;
	}
	:deep(.v-expansion-panel) {
		background-color: transparent;
	}
}

.nearArea {
	.panel {
		overflow: hidden;
	}
}

@media all and (max-width: 959px) {
	.custom_quote {
		.inner {
			border-radius: 0;
			box-shadow: none;
			padding: 15px !important;
		}
	}
}
</style>
