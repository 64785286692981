import apiHome from '@/api/home'

export default {
	state: {
		careflo_auth: {
			customer: false,
			store: false,
			nfc_code: '',
			hand: '',
			name: '',
			event: 'N',
		},
		careflo_barcode_item: {
			barcode: null,
			care_type: null,
			image: null,
			reg_date: null,
		},
	},
	mutations: {
		CAREFLO_MU_AUTH_USER(state, item) {
			state.careflo_auth.customer = item.customer ? item.customer : state.careflo_auth.customer
			state.careflo_auth.store = item.store ? item.store : state.careflo_auth.store
			state.careflo_auth.nfc_code = item.nfc_code ? item.nfc_code : state.careflo_auth.nfc_code
			state.careflo_auth.hand = item.hand ? item.hand : state.careflo_auth.hand
			state.careflo_auth.name = item.name ? item.name : state.careflo_auth.name
			state.careflo_auth.event = item.event ? item.event : state.careflo_auth.event
		},
		CAREFLO_MU_BARCODE_ITEM(state, item) {
			state.careflo_barcode_item.barcode = item.barcode
			state.careflo_barcode_item.care_type = item.care_type
			state.careflo_barcode_item.image = item.barcode_file_base64
			state.careflo_barcode_item.reg_date = item.reg_date
		},
	},
	actions: {
		// 안심케어서비스
		async CAREFLO_ACT_CAREFLO_NFC_CODE({ commit }, items) {
			try {
				let result
				switch (items.method) {
					case 'get':
						await apiHome.careflo.NfcBasicInfoGet(items.query).then(res => {
							console.log('CAREFLO_ACT_CAREFLO_NFC_CODE_get', res)
							result = res == undefined || res == null ? {} : res
						})
						break
					case 'detail':
						await apiHome.careflo.NfcDetailInfoGet(items.params).then(res => {
							console.log('CAREFLO_ACT_CAREFLO_NFC_CODE_get', res)
							result = res == undefined || res == null ? {} : res
						})
						break
				}

				return result
			} catch (e) {
				console.log('home.careflo.Nfc', e)
			}
		},
		async CAREFLO_ACT_CUSTOM_INFO_SAVE({ commit }, params) {
			let result
			try {
				console.log('CAREFLO_ACT_CUSTOM_INFO_SAVE', params)
				result = await apiHome.careflo.CustomInfoPost(params)
			} catch (e) {
				console.log('home.careflo.CustomInfoPost', e)
			}
			return result
		},
		async CAREFLO_ACT_APPLY_AS({ commit }, items) {
			let result
			switch (items.method) {
				case 'create':
					try {
						console.log('CAREFLO_ACT_APPLY_AS', items.params)
						result = await apiHome.careflo.CareFloAsPost(items.params)
					} catch (e) {
						console.log('home.careflo.CareFloAsPost', e)
					}
					break
				case 'modify':
					try {
						console.log('CAREFLO_ACT_APPLY_AS', items.params)
						result = await apiHome.careflo.CareFloAsPut(items.params)
					} catch (e) {
						console.log('home.careflo.CareFloAsPut', e)
					}
					break
				case 'delete':
					try {
						console.log('CAREFLO_ACT_APPLY_AS', items.params)
						result = await apiHome.careflo.CareFloAsDelete(items.params)
					} catch (e) {
						console.log('home.careflo.CareFloAsDelete', e)
					}
					break
			}
			return result
		},
		async CAREFLO_ACT_STORE_BARCODE_SAVE({ commit }, params) {
			let result
			try {
				console.log('HOME_ACT_CAREFLO_STORE_BARCODE', params)
				result = await apiHome.careflo.StoreBarcodePut(params)
				commit('CAREFLO_MU_BARCODE_ITEM', params)
			} catch (e) {
				console.log('home.careflo.StoreBarcodePut', e)
			}
			return result
		},
		async CAREFLO_ACT_STORE_EVENT_CHECK_CNT({ commit }, params) {
			let result
			try {
				console.log('CAREFLO_ACT_STORE_EVENT_CHECK_CNT', params)
				result = await apiHome.careflo.StoreEventCheckCnt(params)
			} catch (e) {
				console.log('home.careflo.StoreEventCheckCnt', e)
			}
			return result
		},
	},
	getters: {
		CAREFLO_GET_AUTH_USER: state => state.careflo_auth,
		CAREFLO_GET_BARCODE_ITEM: state => state.careflo_barcode_item,
	},
	modules: {},
}
