<template>
	<div class="mx-0 mb-2 item">
		<div
			v-if="loading"
			class="loadingWrap"
		>
			<CommonLoaderCircle02
				v-if="loading"
				color="primary"
				class="mt-5"
			/>
		</div>
		<v-col
			class="pa-0 pb-2 contAsk"
			cols="12"
			align="left"
		>
			전문 인테리어점에게 상담을 요청해보세요!
		</v-col>

		<!-- 등록영역 -->
		<div class="input_area">
			<v-col
				cols="12"
				class="pa-0 table"
			>
				<div class="mb-5">
					<!-- <v-row
						v-if="selectInteriorList"
						class="ma-0 mb-6"
					>
						<v-col
							class="pa-0 th"
							cols="12"
							sm="3"
							lg="2"
							align="start"
							align-self="start"
						>
							<p>상담인테리어점</p>
						</v-col>
						<v-col
							class="pa-0 td"
							cols="12"
							sm="9"
							lg="10"
							align="start"
							align-self="start"
						>
							{{ selectInteriorList }}
						</v-col>
					</v-row> -->

					<!-- <v-row
						v-if="selectContents"
						class="ma-0 mb-6"
					>
						<v-col
							class="pa-0 th"
							cols="12"
							sm="3"
							lg="2"
							align="start"
							align-self="start"
						>
							<p>상담콘텐츠</p>
						</v-col>
						<v-col
							class="pa-0 td"
							cols="12"
							sm="9"
							lg="10"
							align="start"
							align-self="start"
						>
							{{ selectContents }}
						</v-col>
					</v-row> -->

					<!-- <v-row
						v-if="selectProductName"
						class="ma-0 mb-6"
					>
						<v-col
							class="pa-0 th"
							cols="12"
							sm="3"
							lg="2"
							align="start"
							align-self="start"
						>
							<p>상담제품</p>
						</v-col>
						<v-col
							class="pa-0 td"
							cols="12"
							sm="9"
							lg="10"
							align="start"
							align-self="start"
						>
							{{ selectProductName }}
						</v-col>
					</v-row> -->

					<v-row class="ma-0 mb-2">
						<!-- <v-col
							class="pa-0 mb-3 mb-lg-0 th"
							cols="12"
							lg="2"
							align="start"
						>
							<p>시공범위</p>
							<span>(*중복가능)</span>
						</v-col> -->
						<v-col
							class="pa-0 td"
							cols="12"
							lg="10"
							align="start"
						>
							<CommonButtonsToggleButton03
								v-if="addQuestionItems.length"
								v-model="addQuestionModel"
								:items="addQuestionItems"
							/>
						</v-col>
					</v-row>
					<v-row class="ma-0 mb-6 interior_area pa-4 consultInterior">
						<v-col
							class="pa-0 th"
							cols="12"
							sm="12"
							lg="12"
							align="start"
							align-self="start"
						>
							<p
								class="tit d-flex align-items-center"
								style="align-items: center; position: relative"
							>
								상담 인테리어점

								<!-- <v-tooltip bottom>
									<template v-slot:activator="{ on, attrs }">
										<v-icon
											color="#006FF1"
											dark
											v-bind="attrs"
											class="ml-1 info_tootip"
											v-on="on"
										>
											mdi-information-outline
										</v-icon>
									</template>
									<span>Tooltip</span>
								</v-tooltip> -->
								<!-- <v-img
									class="ml-1 tooltipOrigin"
									max-width="16"
									height="16"
									eager
									:src="require('@/assets/img/estimate/ico_estimate_tootip.svg')"
									@click="tooltipPop"
								/> 
								<span
									v-if="tooltip"
									class="ballon"
								>
									<span>
										고객님의 접속 위치를 기반하여 가장 가까운 인테리어점 3곳을 추천드립니다.
										<em>
											<v-img
												class="ml-1 tooltipOrigin"
												max-width="10"
												height="10"
												eager
												:src="require('@/assets/img/estimate/ico_tooltip_close.svg')"
												@click="closetooltipPop" /></em
									></span>
								</span>-->
							</p>
						</v-col>
						<v-col
							class="pa-0 td nearArea"
							cols="12"
							sm="12"
							lg="12"
							align="start"
							align-self="start"
						>
							<v-expansion-panels
								v-model="panel"
								flat
								multiple
								class="panel"
							>
								<v-expansion-panel v-model="panel">
									<v-expansion-panel-header>
										<span class="innerTitle"> 추천 인테리어점 </span>
									</v-expansion-panel-header>
									<v-expansion-panel-content class=""
										><div
											v-if="contInterior.length !== 0"
											class="exist"
										>
											<span
												v-for="(item, idx) in contInterior"
												:key="idx"
												class=""
												style="width: 100%"
											>
												<span class="nearItem">
													<span class="">
														{{ item.business_name }}
														<span class="nearAddr"> {{ `${item.addr_cut}` }}</span>
													</span>
													<!-- <em
															class="cancelButton"
															@click="selectCancel(item, idx)"
															>x</em> -->
													<v-img
														class="ml-1 cancelButton"
														max-width="16"
														height="16"
														eager
														:src="require('@/assets/img/home/interior/ico_interiorstor_close.svg')"
														@click="selectCancel(item, idx)"
													/>
												</span>
												<!-- <span v-if="idx + 1 < nearinterior.length">, </span> -->
											</span>
										</div></v-expansion-panel-content
									>
								</v-expansion-panel>
							</v-expansion-panels>
						</v-col>
						<!-- <v-col
							v-if="getMetaTitle === '전문가 집들이'"
							class="pa-0 td nearArea"
							cols="12"
							sm="12"
							lg="12"
							align="start"
							align-self="start"
						>							
							<CommonCheckboxsCheckbox03
								v-model="askThatInterior"
								:active-event="true"
								:text="`${contItem.business_name}점`"
								:company_addr1="`${contInterior[0].addr_cut}`"
								class="pa-0 ma-0 pr-5"
							/>
						</v-col> -->
					</v-row>

					<v-row class="ma-0 mb-6">
						<v-col
							class="pa-0 mb-3 mb-lg-0 th"
							cols="12"
							lg="12"
							align="start"
						>
							<p class="color_gray_8">문의 사항</p>
						</v-col>
						<v-col
							class="pa-0 td"
							cols="12"
							lg="12"
							align="start"
						>
							<v-col class="pa-0 questCont">
								<v-textarea
									v-model="askContents"
									outlined
									height="60"
									hide-details
									:placeholder="askContents ? askContents : '문의사항을 입력해주세요.'"
									:maxlength="maxLength"
									@input="checkLength"
								/>
							</v-col>
						</v-col>
					</v-row>
				</div>
			</v-col>
		</div>
		<!-- 로그인 되어 있지 않은경우 본인인증 -->
		<!-- <v-row
			v-if="AUTH_GET_USER_AUTH === 'GUEST'"
			class="ma-0 mt-2"
		> -->
		<v-col
			class="pa-0 mb-3 mb-lg-0 th"
			cols="12"
			lg="12"
			align="start"
		>
			<p class="color_gray_8 required">{{ AUTH_GET_USER_AUTH === 'GUEST' ? '본인 인증' : '신청자 정보' }}</p>
		</v-col>
		<div v-if="AUTH_GET_USER_AUTH === 'GUEST'">
			<button
				v-if="!selfAuthToggle"
				class="selfAuthBt"
				@click="selfAuthTogglebt"
			>
				본인인증하기
			</button>
			<v-row
				v-if="selfAuthToggle"
				class="ma-0 mt-2"
			>
				<v-col
					class="pa-0 td"
					cols="12"
					md="12"
					align="start"
				>
					<v-col
						cols="12"
						sm="12"
						md="12"
						class="pa-0 selfAuth"
						><span class="color_gray_8">이름</span>
						<v-col class="pa-0 mb-2">
							<CommonInputsInput04
								ref="inputName"
								v-model="name"
								rule-name="name"
								placeholder="이름"
								:readonly="smsNumberCheck"
								:clearable="!selfAuthToggle"
								@checkInvalid="checkName"
							/>
						</v-col>
						<span class="color_gray_8">휴대폰 번호</span>
						<v-row class="ma-0 mb-2">
							<v-col
								class="pa-0"
								cols="7"
								sm="8"
								md="7"
							>
								<div class="pr-1">
									<CommonInputsInput04
										ref="inputHand"
										v-model="hand"
										rule-name="tel"
										placeholder="휴대폰 번호(- 없이 입력)"
										:readonly="smsNumberCheck"
										:clearable="!selfAuthToggle"
										@checkInvalid="checkNum"
									/>
								</div>
							</v-col>
							<v-col
								class="pa-0"
								cols="5"
								sm="4"
								md="5"
							>
								<div class="pl-1">
									<CommonButtonsButton01
										:disabled="nameInvalid === false || numberInvalid === false"
										name="인증번호 발송"
										:color="smsNumberCheck ? 'primary' : '#262626'"
										class-name="regular font_12 edge5"
										height="40"
										@click="checkSms"
									/>
								</div>
							</v-col>
							<v-row class="ma-0">
								<v-col
									cols="8"
									class="pa-0 pt-2 pr-1"
									align-self="center"
									align="start"
								>
									<p
										v-if="smsNumberCheck"
										style="color: #00ce7d"
									>
										인증이 완료되었습니다.
									</p>
								</v-col>
								<v-col
									class="pa-0 pt-1"
									cols="4"
									align-self="center"
									align="end"
								>
									<v-btn
										v-if="smsNumberCheck"
										class="pa-0 reset_btn"
										text
										@click="resetSms"
									>
										<v-icon
											color="#9e9e9e"
											small
											>mdi-refresh</v-icon
										>
										초기화
									</v-btn>
								</v-col>
							</v-row>
							<CommonLoaderLine01 v-if="telLoading" />
						</v-row>

						<div v-if="smsNumberModel">
							<v-row class="ma-0 pt-3">
								<v-col
									class="pa-0"
									cols="7"
									sm="8"
									md="7"
								>
									<div class="pr-1">
										<CommonInputsInput02
											ref="inputSmsNum"
											v-model="smsNum"
											type="number"
											placeholder="인증번호를 입력해주세요."
										/>
									</div>
								</v-col>
								<v-col
									class="pa-0"
									cols="5"
									sm="4"
									md="5"
								>
									<div class="pl-1">
										<CommonButtonsButton01
											name="인증 확인"
											color="#262626"
											class-name="regular font_12 edge5"
											height="40"
											@click="confirmSmsNumber"
										/>
									</div>
									<v-col
										cols="12"
										class="pa-0 pt-2 pr-1 pb-3"
										align="end"
										style="color: #fb4f4f"
									>
										{{ sms_check_timer }}
									</v-col>
								</v-col>
							</v-row>
						</div>
					</v-col>
				</v-col>
				<v-row class="ma-0">
					<v-col
						class="pa-0 td"
						cols="12"
						lg="12"
						align="start"
						align-self="center"
					>
						<div class="btn_privacy">
							<div class="pa-0 pb-2">
								<CommonCheckboxsCheckbox02
									v-model="privateTermsCheck"
									sub-text="상담 예약 및"
									allowlink="개인정보 수집"
									href="/site/userInfo"
									text="이용 동의"
									class="pa-0 ma-0 pr-5"
								/>
							</div>
							<!-- <div>
						<v-btn
							text
							@click="common_openPrivate"
						>
							개인정보처리방침확인
						</v-btn>
					</div> -->
						</div>
					</v-col>
					<v-col
						class="pa-0"
						cols="12"
					>
						<CommonButtonsButton06
							:disabled="!registerCheck"
							name="간편상담 신청하기"
							color="#00582c"
							class-name="btn_new_basic"
							@click="confirmSubmitPop = true"
						/>
					</v-col>
				</v-row>
			</v-row>
			<CommonConfirm
				:dialog="confirmSubmitPop"
				:title="`간편상담 신청`"
				:text="`간편상담 신청을 진행하시겠습니까?`"
				@close="confirmSubmitPop = false"
				@submit="apiCallRegister"
			/>
		</div>
		<div v-else>
			<v-row class="ma-0 mt-0">
				<!-- 로그인 되어 있는 경우 본인인증 -->
				<v-col
					class="pa-0 td"
					cols="12"
					md="12"
					align="start"
				>
					<HomeCustomItemRegisterBasicInfo
						v-if="AUTH_GET_USER.name"
						title="이름"
						:submit="2"
						:text="AUTH_GET_USER.name"
					/>
					<HomeCustomItemRegisterBasicInfo
						v-if="AUTH_GET_USER.hand"
						title="휴대폰"
						:submit="1"
						:text="common_number_filter(AUTH_GET_USER.hand)"
					/>
					<HomeCustomItemRegisterBasicInfo
						v-if="AUTH_GET_USER.name"
						title="이메일"
						:submit="2"
						:text="AUTH_GET_USER.emailaddr"
					/>
				</v-col>
				<v-row class="ma-0 mt-2">
					<v-col
						class="pa-0 td"
						cols="12"
						lg="12"
						align="start"
						align-self="center"
					>
						<div class="btn_privacy">
							<div class="pa-0 pb-2">
								<CommonCheckboxsCheckbox02
									v-model="privateTermsCheck"
									sub-text="상담 예약 및"
									allowlink="개인정보 수집"
									href="/site/userInfo"
									text="이용 동의"
									class="pa-0 ma-0 pr-5"
								/>
							</div>
							<!-- <div>
						<v-btn
							text
							@click="common_openPrivate"
						>
							개인정보처리방침확인
						</v-btn>
					</div> -->
						</div>
					</v-col>
					<v-col
						class="pa-0"
						cols="12"
					>
						<CommonButtonsButton06
							:disabled="!registerCheck"
							name="간편상담 신청하기"
							color="#00582c"
							height="42"
							class-name="btn_new_basic"
							@click="confirmSubmitPop = true"
						/>
					</v-col>
				</v-row>
			</v-row>
			<CommonConfirm
				:dialog="confirmSubmitPop"
				:title="`간편상담 신청`"
				:text="`간편상담 신청을 진행하시겠습니까?`"
				@close="confirmSubmitPop = false"
				@submit="apiCallRegister"
			/>
		</div>
		<!-- <v-row class="my-0 pb-2">
			<v-col class="searchWarning">
				입력하신 정보는 인테리어점 담당자에게만 전달됩니다. 동화자연마루는 인테리어점과 소비자 직 거래 중계 플랫폼으로
				판매에 대한 책임이 없습니다.
			</v-col>
		</v-row> -->

		<!-- 로그인 되어 있지 않은경우 본인인증 -->

		<!-- <input type="radio" v-model="model.radioValues" value="아파트">
        <input type="radio" v-model="model.radioValues" value="빌라">
        <input type="radio" v-model="model.radioValues" value="주택"> -->
		<div style="top: 100px"></div>
	</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import kakao_post from '@/mixins/kakao_post'
import ad_kakao from '@/mixins/ad/kakao'
import ad_naver from '@/mixins/ad/naver'
import ad_mobion from '@/mixins/ad/mobion'
import localStorage from '@/utils/localStorage'
import common from '@/mixins/common'
import sms from '@/mixins/sms'
import geolocation from '@/mixins/geolocation'
import geolocation_current from '@/mixins/geolocation_current'

export default {
	name: 'HomeInteriorPriceDetailinquireCommonFormInterior',
	mixins: [kakao_post, ad_kakao, ad_naver, ad_mobion, common, sms, geolocation, geolocation_current],

	props: {
		currentpage: {
			Type: Number,
			require: true,
		},
		finaldata: {
			Type: Object,
			default: '',
		},
		homeTypeItems1: {
			Type: Array,
			default: '',
		},
		homeTypeItems2: {
			Type: Array,
			default: '',
		},
		homeTypeItems3: {
			Type: Array,
			default: '',
		},
		homeTypeItems4: {
			Type: Array,
			default: '',
		},
		reset: {
			Type: Boolean,
			default: '',
		},
		items: {
			Type: Object,
			default: '',
		},
		cont: {
			Type: Object,
			default: '',
		},
		detailTitle: {
			Type: String,
			default: '',
		},
		contItem: {
			Type: Object,
			default: '',
		},
		contInterior: {
			Type: Array,
			default: '',
		},
	},

	data() {
		return {
			//신청
			confirmSubmitPop: false,
			newmodel: [],
			askContents: '',
			privateTermsCheck: false,
			confirm: false,
			//finalconfirm: false,
			addQuestionModel: [],
			addQuestionItems: [],
			// sms check
			name: null,
			hand: null,
			smsNum: null,
			// sms
			smsNumberCheck: null,
			smsNumberModel: false,
			smsCheckId: null,
			telLoading: false,
			// temp
			nearinterior: [],
			nexttointerior: [],
			limit: 3,
			nexttointeriorCheck: 0,
			searchNextto: false,
			panel: [0],
			askThatInterior: false,
			//tooltip
			tooltip: false,
			// selfauth
			selfAuthToggle: false,
			// pickinterior
			pickThisInterior: [],
			loading: false,
			// 최대 글자 수
			maxLength: 200,
			// 이름 유효성
			nameInvalid: false,
			numberInvalid: false,
		}
	},

	created() {
		//this.askContents = `${this.homeTypeItems1[this.finaldata.type_1].title} / ${this.finaldata.widthModel.text} / ${this.finaldata.roomModel.text} / ${this.homeTypeItems2[this.finaldata.type_2].title} / ${this.homeTypeItems3[this.finaldata.type_3].title} / ${this.finaldata.productModel.text} / ${this.homeTypeItems4[this.finaldata.type_4].title}`
		this.pickThisInterior = this.contInterior
	},

	computed: {
		...mapGetters([
			'AUTH_GET_USER',
			'AUTH_GET_USER_AUTH',
			'APP_GET_APP_STATE',
			'ADMIN_GET_PRODUCT_CATEGORY_TYPE_ITEMS',
			'HOME_GET_INTERIOR_SELECT_INTERIOR',
			'HOME_GET_INTERIOR_AUTO_SELECT_INTERIOR',
			'HOME_GET_INTERIOR_SELECT_PRICE',
			'HOME_GET_INTERIOR_SELECT_PRODUCT',
			'HOME_GET_INTERIOR_GET_INTERIOR_ITEMS',
			'HOME_GET_INTERIOR_GET_PRODUCT_ITEMS',
			'HOME_GET_CURRENT_ESTIMATE_CONTENT',
			'APPBAR_GET_INTERIORSTORE_DRAWER',
		]),
		// activeClass() {
		// 	return this.filterInteriorItems.length > 0 ? alert('1') : alert('2')
		// },
		interiorItems() {
			return this.HOME_GET_INTERIOR_GET_INTERIOR_ITEMS || []
		},
		filterInteriorItems() {
			return this.interiorItems
		},
		nearinteriors() {
			return this.nexttointerior.slice(0, this.limit)
		},
		registerCheck() {
			// 인테리어 점 선택
			//if (this.HOME_GET_INTERIOR_SELECT_INTERIOR === null) return false

			const checkList = ['privateTermsCheck']
			// GUEST일 경우 본인인증 추가체크
			if (this.AUTH_GET_USER_AUTH === 'GUEST') checkList.push('smsNumberCheck')

			for (let i of checkList) {
				if (this[i] === '' || this[i] === null || this[i] === undefined || this[i] === false) return false
			}
			return true
		},
		// registerChecked() {
		// 	if (this.currentpage === 4) {
		// 		return (this.askContents = `${this.homeTypeItems1[this.finaldata.type_1].title} / ${
		// 			this.finaldata.widthModel.text
		// 		} / ${this.finaldata.roomModel.text} / ${this.homeTypeItems2[this.finaldata.type_2].title} / ${
		// 			this.homeTypeItems3[this.finaldata.type_3].title
		// 		} / ${this.finaldata.productModel.text} / ${this.homeTypeItems4[this.finaldata.type_4].title}`)
		// 	} else {
		// 		return (this.askContents = '')
		// 	}
		// },
		askContentsCheck() {
			return this.askContents
		},
		selectProductName() {
			let name = null
			if (this.HOME_GET_INTERIOR_SELECT_PRODUCT) {
				name = this.HOME_GET_INTERIOR_SELECT_PRODUCT.contents.name
			}
			return name
		},
		selectContents() {
			let contents = null
			if (this.HOME_GET_INTERIOR_SELECT_PRODUCT?.contents) {
				contents = `${this.HOME_GET_INTERIOR_SELECT_PRODUCT.contents.name}`
			}
			return contents
		},
		getMetaTitle() {
			return this.$router.currentRoute.meta.title
		},
		// interiorArea() {
		// 	if (this.contItem.company_addr1 !== null && this.common_title === '전문가 집들이') {
		// 		return this.contItem.company_addr1.split(' ').slice(0, 2).join(' ')
		// 	}
		// },
		appWidth() {
			return this.APP_GET_APP_WIDTH < 960
		},
		remainingChars() {
			return this.maxLength - this.askContents.length // 남은 글자 수를 계산
		},
		closeDrawerCheck() {
			return this.APPBAR_GET_INTERIORSTORE_DRAWER === false
		},
	},
	watch: {
		updatedata(newval, oldval) {
			if (newval) {
				this.$emit('formevent', newval, 'form4')
			}
		},
		// kakao_map_status(val) {
		// 	// kakao map start
		// 	if (val) this.startLocation()
		// },
		registerChecked(val) {
			if (val) {
				console.log('z')
			}
		},
		contInterior(val) {
			if (val) {
				this.HOME_MU_CURRENT_ESTIMATE_CONTENT_INTERIORSELECT(val)
			}
		},
		// registerCheck(val) {
		// 	alert(val)
		// 	if (val) {
		// 		this.$emit('customquote', val)
		// 	}
		// },
		// reset(newvalue, oldvalue) {
		// 	if (newvalue) {
		// 		this.privateTermsCheck = false
		// 		this.smsCheckId = null
		// 		this.smsNum = null
		// 		this.smsNumberCheck = null
		// 		this.smsNumberModel = false
		// 		;(this.name = null), (this.hand = null)
		// 		this.sms_id = null
		// 		this.telLoading = false
		// 		this.timer = 180
		// 		this.timer_model = false
		// 	} else {
		// 		this.privateTermsCheck = false
		// 		this.smsCheckId = null
		// 		this.smsNum = null
		// 		this.smsNumberCheck = null
		// 		this.smsNumberModel = false
		// 		;(this.name = null), (this.hand = null)
		// 		this.sms_id = null
		// 		this.telLoading = false
		// 		this.timer = 180
		// 		this.timer_model = false
		// 	}
		// },
		askContents(newval, oldval) {
			if (newval) {
				this.HOME_MU_CURRENT_ESTIMATE_CONTENT_ASKCONTENT(newval) || null
			}
		},
		nearinterior(newval, oldval) {
			this.HOME_MU_INTERIOR_GET_INTERIOR_ITEMS(this.nearinterior) || null
			this.HOME_MU_CURRENT_ESTIMATE_CONTENT_INTERIORSELECTAUTO(newval) || null
		},
		nearinteriors(newval, oldval) {
			return (this.nearinterior = newval)
		},
		askThatInterior(newval, oldval) {
			if (newval === true) {
				//this.nearinterior = []
				this.HOME_MU_INTERIOR_SELECT_INTERIOR(this.pickThisInterior[0])
				this.HOME_MU_CURRENT_ESTIMATE_CONTENT_INTERIORSELECTAUTO(this.pickThisInterior)
				// this.HOME_MU_INTERIOR_SELECT_INTERIOR(newval) || null
			}
		},
		pickThisInterior(newval, oldval) {
			if (newval) {
				this.HOME_MU_CURRENT_ESTIMATE_CONTENT_INTERIORSELECTAUTO(newval)
				this.HOME_MU_CURRENT_ESTIMATE_CONTENT_INTERIORSELECT(newval)
			}
		},
		closeDrawerCheck(val) {
			if (val) {
				this.tooltip = false
				this.askContents = ''
				this.selfAuthToggle = false
				this.privateTermsCheck = false
				this.hand = null
				this.smsNumberCheck = false
				this.smsNumberModel = false
			}
		},
	},

	mounted() {
		console.log('메타인포', this.$metaInfo)
		//this.startLocation()
		this.baseInfo()
		this.findInterior()

		this.HOME_MU_TAB_SELECT(0)
	},

	methods: {
		...mapMutations([
			'APP_MU_DIALOG',
			'HOME_MU_CURRENT_ESTIMATE_CONTENT_ITEM',
			'HOME_MU_CURRENT_ESTIMATE_CONTENT_PAGETYPE',
			'HOME_MU_CURRENT_ESTIMATE_CONTENT_INTERIORSELECT',
			'HOME_MU_CURRENT_ESTIMATE_CONTENT_INTERIORSELECTAUTO',
			'HOME_MU_CURRENT_ESTIMATE_CONTENT_ASKCONTENT',
			'HOME_MU_INTERIOR_SELECT_PRODUCT',
			'HOME_MU_INTERIOR_SELECT_INTERIOR',
			'HOME_MU_INTERIOR_AUTO_SELECT_INTERIOR',
			'HOME_MU_CURRENT_ESTIMATE_CONTENT_USERINFO',
			'HOME_MU_INTERIOR_GET_INTERIOR_ITEMS',
			'HOME_MU_TAB_SELECT',
			'HOME_MU_CURRENT_ESTIMATE_CONTENT_PRODUCT',
		]),
		...mapActions([
			'HOME_ACT_INTERIOR_GET_INTERIOR_ITEMS',
			'HOME_ACT_INTERIOR_GET_EXPERT_ITEMS',
			'HOME_ACT_INTERIOR_SELECT',
			'HOME_ACT_AUTO_INTERIOR_SELECT',
			'ADMIN_ACT_PRODUCT_GET_PUBLIC_CODE_ITEMS',
			'HOME_ACT_INTERIOR_ESTIMATE_SIMPLE_PRICE',
		]),
		baseInfo() {
			this.HOME_MU_CURRENT_ESTIMATE_CONTENT_ITEM(this.items)
			if (this.nearinterior.length > 0) {
				this.HOME_MU_INTERIOR_GET_INTERIOR_ITEMS(this.nearinterior)
			}
			this.HOME_MU_INTERIOR_SELECT_PRODUCT(this.contItem)
		},
		checkName(val) {
			if (val === true) {
				this.nameInvalid = true
			} else {
				this.nameInvalid = false
			}
		},
		checkNum(val) {
			if (val === true) {
				this.numberInvalid = true
			} else {
				this.numberInvalid = false
			}
		},
		async checkSms() {
			this.telLoading = true
			let params = {
				type: this.sms_check_type[7],
				hand: this.hand,
				name: this.name,
				login_id: this.email,
			}
			const check = await this.sms_call(params)
			if (check) {
				this.telLoading = false
				this.smsNumberModel = true
			}
		},
		async confirmSmsNumber() {
			const check = await this.sms_call_check(this.hand, this.smsNum)
			if (check) {
				this.smsNumberCheck = true
				this.smsNumberModel = false
			}
		},

		// 예제 사용
		// var inputString = "이것은 예제 문자열입니다. 두 번째 공백까지 자를 것입니다.";
		// var result = extractSubstring(inputString);
		// console.log(result);

		checkLength() {
			if (this.askContents.length > this.maxLength) {
				this.askContents = this.askContents.substring(0, this.maxLength) // 글자 수가 최대 길이를 초과하는 경우 자르기
				return this.$toastr.error('최대 글자수는 200자입니다', { timeOut: 1500 })
			}
		},

		confirmSubmit() {
			this.confirm = false
			this.$router.go(-1)
		},
		startLocation() {
			// this.kakao_map_pan_to(this.geolocation_position);
			// this.kakao_map_create_my_location_marker(
			// 	this.kakao_map_code_transform(this.geolocation_position),
			// 	{
			// 	place_name: "내위치",
			// 	lat: this.geolocation_position.lat,
			// 	lng: this.geolocation_position.lng,
			// 	}
			// );
			//this.getApiExpertItems(this.geolocation_position);
			//this.getCurrentLocation()
			this.HOME_MU_INTERIOR_AUTO_SELECT_INTERIOR(this.nearinteriors)
		},
		// getCurrentLocation() {
		// 	if (navigator.geolocation) {
		// 		navigator.geolocation.getCurrentPosition(
		// 			position => {
		// 				const latitude = position.coords.latitude
		// 				const longitude = position.coords.longitude
		// 				console.log(`현재 위치 - 위도: ${latitude}, 경도: ${longitude}`)
		// 				this.currentmap.lat = latitude
		// 				this.currentmap.lng = longitude
		// 				// 여기에서 위도와 경도 값을 활용할 수 있습니다.
		// 				this.getApiInteriorItems(this.currentmap)
		// 			},
		// 			error => {
		// 				console.error(`위치 정보를 가져오는 중 오류가 발생했습니다: ${error.message}`)
		// 			},
		// 		)
		// 	} else {
		// 		console.error('브라우저가 Geolocation API를 지원하지 않습니다.')
		// 	}
		// },
		findInterior() {
			this.askThatInterior = false
			this.searchNextto = true
			this.startLocation()
		},
		selectCancel(item, idx) {
			// if(this.nexttointerior.includes(item.corporate_no)){
			// 	this.nexttointerior.slice(0, item.corporate_no)
			// }
			// this.nexttointerior.slice(0, this.limit).filter((_, i) => i !== idx)
			console.log(item)
			//this.pickThisInterior = this.nearinteriors.filter(v => v.corporate_no !== item.corporate_no)

			this.HOME_MU_INTERIOR_SELECT_INTERIOR(item)
			if (this.HOME_GET_INTERIOR_SELECT_INTERIOR.length === 0) {
				this.$emit('closeDrawer')
			}
		},
		// async getApiInteriorItems(center) {
		// 	console.log(center)
		// 	const params = {
		// 		//latitude_x: 37.5980032,
		// 		//longitude_y: 126.6876416,
		// 		latitude_x: center.lat,
		// 		longitude_y: center.lng,
		// 		build_range: '',
		// 		meter_range: 6000,
		// 	}

		// 	console.log('params', params)

		// 	await this.HOME_ACT_INTERIOR_GET_INTERIOR_ITEMS(params).then(res => {
		// 		// this.interiorItems = res
		// 		// if (res.length)
		// 		// this.createMarker(this.filterInteriorItems);
		// 		console.log('이건가/', res)
		// 		if (res.length !== 0) {
		// 			this.nexttointeriorCheck = 1
		// 			this.nexttointerior = res
		// 		} else {
		// 			this.nexttointeriorCheck = 0
		// 		}
		// 	})
		// },
		// 객체를 문자열로
		// stringifyData(data) {
		// 	return JSON.stringify(data, null, 2);
		// }

		// 위에서 정의한 함수를 사용하여 문자열로 변환
		//const resultString = stringifyData(finalData);

		// 신청하기
		async apiCallRegister() {
			if (this.contInterior.length === 0) {
				alert('인테리어점을 선택 해주세요.')
				return
			}
			this.confirmSubmitPop = false
			this.loading = true
			this.HOME_MU_CURRENT_ESTIMATE_CONTENT_PRODUCT(null)
			if (this.askContents === '' || this.askContents === null) {
				this.HOME_MU_CURRENT_ESTIMATE_CONTENT_ASKCONTENT(null)
			}
			console.log('post', this.post)
			console.log('addressBasic', this.addressBasic)
			console.log('addressDetail', this.addressDetail)
			console.log('askContents', this.askContents)
			console.log('HOME_GET_INTERIOR_SELECT_INTERIOR', this.HOME_GET_INTERIOR_SELECT_INTERIOR)
			console.log('HOME_GET_INTERIOR_SELECT_PRICE', this.HOME_GET_INTERIOR_SELECT_PRICE)
			console.log('HOME_GET_INTERIOR_SELECT_PRODUCT', this.HOME_GET_INTERIOR_SELECT_PRODUCT)
			let items = {
				method: 'post',
				params: {
					memberYN: this.AUTH_GET_USER.login_id ? 'Y' : 'N',
					login_id: this.AUTH_GET_USER.login_id ? this.AUTH_GET_USER.login_id : this.hand,
					login_name: this.AUTH_GET_USER.name ? this.AUTH_GET_USER.name : this.name,
					regi_userid: this.AUTH_GET_USER.login_id ? this.AUTH_GET_USER.login_id : this.name,

					// 문의 사항 등록정보
					// zipcode: this.post,
					// address1: this.addressBasic,
					// address2: this.addressDetail,
					content: this.askContents,
				},
			}

			// urlHistory 등록
			items.params.trackingList = localStorage.urlHistory?.get().join(',')

			// interior 업체 등록
			if (this.HOME_GET_INTERIOR_SELECT_INTERIOR.length > 0 && this.contInterior.length > 0) {
				this.HOME_GET_INTERIOR_SELECT_INTERIOR.forEach((x, i) => {
					const num = i + 1
					items.params[`corporate_no${num}`] = x.corporate_no
					items.params[`hand${num}`] = x.manager_hand
					items.params[`sms_yn${num}`] = x.sms_yn
				})
			}
			// if (this.askThatInterior === false) {
			// 	if (this.HOME_GET_INTERIOR_SELECT_INTERIOR.length > 0 && this.contInterior.length > 0) {
			// 		this.HOME_GET_INTERIOR_SELECT_INTERIOR.forEach((x, i) => {
			// 			const num = i + 1
			// 			items.params[`corporate_no${num}`] = x.corporate_no
			// 			items.params[`hand${num}`] = x.manager_hand
			// 			items.params[`sms_yn${num}`] = x.sms_yn
			// 		})
			// 	}
			// } else {
			// 	items.params[`corporate_no1`] = this.contInterior[0].corporate_no
			// 	items.params[`hand1`] = this.contInterior[0].manager_hand
			// 	items.params[`sms_yn1`] = this.contInterior[0].sms_yn
			// }

			// if (this.addQuestionModel.length) {
			// 	// 추가 상담이 있는경우
			// 	const addItems = []
			// 	this.addQuestionModel.forEach(element => addItems.push(this.addQuestionItems[element]))
			// 	items.params.estconsultadd = addItems.map(x => x.code).join(',')
			// }

			if (this.HOME_GET_INTERIOR_SELECT_PRODUCT && this.contItem.type !== '전문가집들이관리') {
				// 제품을 선택한 경우 ( 상세제품 - '상품마스터' / 3D 집들이 - '3D집들이관리' / 전문가 집들이 - '전문가집들이관리' )
				items.params.product_kind = this.HOME_GET_CURRENT_ESTIMATE_CONTENT.type
				items.params.product_id = this.HOME_GET_INTERIOR_SELECT_PRODUCT.contents.id
				items.params.product_code = this.HOME_GET_INTERIOR_SELECT_PRODUCT.goods_code
			}

			// if (this.HOME_GET_INTERIOR_SELECT_PRICE) {
			// 	// 견적 단가가 있는 경우
			// 	items.params.goods_code = this.HOME_GET_INTERIOR_SELECT_PRICE.goods_code
			// 	items.params.exclusive_area = this.HOME_GET_INTERIOR_SELECT_PRICE.exclusive_area
			// 	items.params.housetype = this.HOME_GET_INTERIOR_SELECT_PRICE.housetype
			// 	items.params.roomcount = this.HOME_GET_INTERIOR_SELECT_PRICE.roomcount
			// 	items.params.veranda_yn = this.HOME_GET_INTERIOR_SELECT_PRICE.veranda_yn
			// 	items.params.buildscope = this.HOME_GET_INTERIOR_SELECT_PRICE.buildscope
			// 	items.params.removal_yn = this.HOME_GET_INTERIOR_SELECT_PRICE.removal_yn
			// 	items.params.est_cost = this.HOME_GET_INTERIOR_SELECT_PRICE.est_cost
			// 	items.params.product_kind = this.HOME_GET_INTERIOR_SELECT_PRICE.type
			// 		? this.HOME_GET_INTERIOR_SELECT_PRICE.type
			// 		: '간편견적관리'
			// }

			items.params.product_kind = '인테리어점 찾기'

			console.log('params?????', items)

			await this.HOME_ACT_INTERIOR_ESTIMATE_SIMPLE_PRICE(items)
				.then(async res => {
					if (res) {
						// await this.APP_MU_DIALOG({
						// 	dialog: true,
						// 	title: '견적내기',
						// 	text: '견적내기 접수가 완료되었습니다.',
						// })

						// google analytics
						window.gtag('event', 'click_inquire_button', { button_location: 'interior_price_inquire' })
						// ad_kakao addToCart
						this.ad_kakao_addToCart(this.HOME_GET_INTERIOR_SELECT_INTERIOR.corporate_no, 'click_inquire_button_ip')
						// ad_naver
						this.ad_naver_changeScript(1, 1)
						// ad_mobion
						this.ad_mobion_click_convertion('click_inquire_button_ipi')

						//if (this.AUTH_GET_USER_AUTH === 'USER') this.$router.push('/user?tab=2').catch(() => {})
						//else this.$router.go(0)]
						this.HOME_MU_CURRENT_ESTIMATE_CONTENT_ITEM(this.contInterior)
						//this.HOME_MU_CURRENT_ESTIMATE_CONTENT_INTERIORSELECT(this.contInterior)
						this.HOME_MU_CURRENT_ESTIMATE_CONTENT_PAGETYPE('인테리어점 찾기')
						const currentUserinfo = {
							name: this.name,
							hand: this.hand,
						}
						this.HOME_MU_CURRENT_ESTIMATE_CONTENT_USERINFO(currentUserinfo)

						//this.HOME_MU_INTERIOR_SELECT_INTERIOR(this.contInterior)
						this.loading = false
						this.$router.push({ path: '/interior/price/new/finalinquire' })
					} else {
						await this.APP_MU_DIALOG({
							dialog: true,
							title: '견적내기',
							text: '견적내기 접수를 실패하였습니다.',
						})
					}
				})
				.catch(e => {
					console.log('HOME_ACT_INTERIOR_ESTIMATE_SIMPLE_PRICE', e)
				})
		},

		// tooltipPop() {
		// 	this.tooltip ? (this.tooltip = false) : (this.tooltip = true)
		// },
		closetooltipPop() {
			this.tooltip = false
		},

		// 직접찾기
		selfSearchInterior(e) {
			console.log('직접찾기', e)
			this.HOME_MU_CURRENT_ESTIMATE_CONTENT_ITEM(this.contItem)
			this.HOME_MU_CURRENT_ESTIMATE_CONTENT_PAGETYPE(this.common_title)
			this.$router.push({ path: '/interior/price/inquire' })
		},

		// 본인인증영역 오픈
		selfAuthTogglebt() {
			this.selfAuthToggle = !this.selfAuthToggle
		},
		resetSms() {
			this.smsNumberCheck = false
			this.name = null
			this.hand = null
			this.smsNum = null
			this.selfAuthToggle = false
			if (this.$refs.inputName) this.$refs.inputName.inputModel = ''
			if (this.$refs.inputHand) this.$refs.inputHand.inputModel = ''
			if (this.$refs.inputSmsNum) this.$refs.inputSmsNum.inputModel = ''
		},
	},
}
</script>

<style lang="scss" scoped>
.item {
	background-color: $color_white;
	overflow: hidden;
	padding: 15px 20px;
	position: sticky;
	margin-bottom: 0 !important;
	max-height: calc(100vh - 93px);
	overflow-y: scroll;
	&::-webkit-scrollbar {
		display: none;
	}
	-ms-overflow-style: none;
	.contAsk {
		font-weight: $fw_bold;
		font-size: $font_lg;
		color: $color_font;
	}
	.contTitle {
		border: 1px solid #cccccc;
		border-radius: 4px;
		padding: 12px 18px;
	}
	.panel {
		.innerTitle {
			font-size: $font_normal;
			color: $color_font;
		}
		:deep(.v-expansion-panel-header) {
			padding: 0;
			background-color: transparent;
			min-height: 42px;
		}
		:deep(.v-expansion-panel-content) {
			padding: 0;
		}
		:deep(.v-expansion-panel-content__wrap) {
			padding: 0;
		}
		:deep(.v-expansion-panel) {
			background-color: transparent;
		}
	}
}
.input_area {
	.tit {
		font-size: $font_xl;
	}
	.interior_area {
		background: #f8f8f8;
		border-radius: 8px;
		display: flex;
		flex-direction: column;

		.consultInterior {
			font-size: $font_lg;
			font-weight: $fw_semibold;
		}
		.nearArea {
			.exist {
				display: flex;
				flex-wrap: wrap;
				gap: 12px;
				.nearItem {
					background-color: #fff;
					border-radius: 4px;
					font-size: $font_normal;
					padding: 6px 12px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					.nearAddr {
						font-size: $font_xs;
						display: block;
						align-self: end;
						color: #9e9e9e;
					}
					.cancelButton {
						background-color: #e6e6e6;
						border-radius: 50%;
						display: flex;
						width: 16px;
						height: 16px;
						justify-content: center;
						align-items: center;
						font-style: normal;
						margin-left: 5px;
						font-size: 10px;
						cursor: pointer;
					}
				}
			}
		}

		.tit {
			font-size: $font_xl;
			letter-spacing: -0.7px;
			color: $color_font;
			font-weight: $fw_regular;
		}
	}
}

.stepprogreess {
	display: flex;
	li {
		width: 25%;
		list-style: none;
		height: 10px;
		background-color: #c0c0c0;
	}
	.active {
		background-color: #00582c;
	}
}

.questCont {
	:deep(.v-input__slot) {
		padding: 0 !important;
	}

	:deep(.v-text-field__slot) {
		margin-right: 0 !important;
		border: 1px solid #cccccc;
		border-radius: 4px;
	}

	:deep(.v-text-field__slot) {
		overflow: hidden;
		textarea {
			padding: 5px 10px !important;
			margin-top: 0px !important;
			resize: none;
		}
		textarea::-webkit-scrollbar {
			width: 4px; /* 스크롤 바의 너비 */
		}

		textarea::-webkit-scrollbar-track {
			background-color: #f8f8f8; /* 스크롤 바 트랙의 배경색 */
		}

		textarea::-webkit-scrollbar-thumb {
			background-color: #d1d1d1; /* 스크롤 바의 색상 */
			border-radius: 4px; /* 스크롤 바의 모서리 둥글게 */
		}

		textarea::-webkit-scrollbar-thumb:hover {
			background-color: #555; /* 마우스를 가져다 댔을 때 스크롤 바의 색상 */
		}
	}
}
.selfAuth {
	:deep(.v-input__slot) {
		padding: 0 !important;
		input {
			padding: 0 10px !important;
		}
		button {
			padding: 0px !important;
		}
	}
}

.searchWarning {
	font-size: $font_sm;
	font-weight: $fw_normal;
	color: $color_gray_5;
}

.info_tootip {
	&:hover {
		cursor: pointer;
	}
}

.required {
	font-weight: $fw_regular;
	font-size: $font_lg;
}
.tooltipOrigin {
	&:hover {
		cursor: pointer;
	}
}
.selfAuthBt {
	background-color: #00592d;
	width: 100%;
	padding: 10px;
	border-radius: 4px;
	color: #fff;
	font-weight: 100;
	margin: 0px auto 0;
	display: block;
}
.ballon {
	position: absolute;
	width: auto;
	left: auto;
	top: 30px;
	z-index: 99;
	background: #262626;
	opacity: 0.8;
	color: white;
	border-radius: 5px;
	padding: 12px 12.8px;
	font-size: $font_normal;
	font-weight: $fw_normal;
	span {
		//padding-left: 10px;
		word-wrap: break-word;
		display: block;
		position: relative;
		em {
			position: absolute;
			right: 5px;
			bottom: 0px;
		}
		// &::before {
		// 	content: '-';
		// 	width: 2px;
		// 	height: 2px;
		// 	position: absolute;
		// 	left: 0;
		// }
	}
}

.ballon:after {
	border-bottom: 10px solid #262626;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-top: 0px solid transparent;
	content: '';
	position: absolute;
	top: -9px;
	left: 118px;
}

.loadingWrap {
	position: fixed;
	width: calc(100% - 30px);
	height: calc(100vh - 15%);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	z-index: 10;
}
</style>
