var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","width":"1000"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pa-0"},[_c('AdminItemsTitle',{attrs:{"title":`${_vm.title} 내용 확인`},on:{"close":function($event){return _vm.$emit('close')}}}),_c('v-col',{staticClass:"pa-0 pa-md-3"},[_c('v-container',{staticClass:"admin_table"},[_c('v-row',{staticClass:"ma-0 admin_table_area"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","md":"6"}},[_c('AdminItemsTableItem',{attrs:{"title":"상태","text":_vm.readItem.use_yn === 'Y' ? 'ON' : 'OFF'}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","md":"6"}},[_c('AdminItemsTableItem',{attrs:{"title":"조회수","text":String(_vm.readItem.hits)}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('AdminItemsTableItem',{attrs:{"title":"테마","text":_vm.readItem.theme_name,"fill":true}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('AdminItemsTableItem',{attrs:{"title":"대표이미지","text-button":{
								text: _vm.readItem.image_url,
								button: {
									text: '대표이미지',
									color: 'primary',
								},
							},"fill":true},on:{"click":function($event){return _vm.common_onLink(_vm.readItem.image_url)}}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('AdminItemsTableItem',{attrs:{"title":"제목","text":_vm.readItem.title,"fill":true}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[(_vm.loading)?_c('CommonLoaderLine01'):_c('AdminItemsTableItem',{attrs:{"title":"내용","text-button":{
								text: _vm.description,
								button: {
									text: '내용 확인',
									color: 'primary',
								},
							},"fill":true},on:{"click":function($event){_vm.onViewDialog = true}}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[(_vm.readItem.attach_url)?_c('AdminItemsTableItem',{attrs:{"title":"첨부파일","text-button":{
								text: _vm.readItem.attach_url,
								button: {
									text: '첨부파일 확인',
									color: 'primary',
								},
							},"fill":true},on:{"click":function($event){return _vm.common_onLink(_vm.readItem.attach_url)}}}):_c('AdminItemsTableItem',{attrs:{"title":"첨부파일","text":' ',"fill":true}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","md":"6"}},[_c('AdminItemsTableItem',{attrs:{"title":"등록자","text":_vm.readItem.regi_userid}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","md":"6"}},[_c('AdminItemsTableItem',{attrs:{"title":"등록일","text":_vm.readItem.regi_date}})],1)],1)],1),_c('v-row',{staticClass:"ma-0",attrs:{"align":"center","justify":"end"}},[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3","lg":"2"}},[_c('v-btn',{attrs:{"color":"error","block":"","tile":"","elevation":"0"},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("clear")]),_c('span',{staticClass:"ml-2"},[_vm._v("닫기")])],1)],1)],1)],1)],1),_c('CommonEditorQuillViewerDialog',{attrs:{"dialog":_vm.onViewDialog,"html":_vm.description},on:{"close":function($event){_vm.onViewDialog = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }