<template>
	<div class="inner">
		<section class="pa-4 form">
			<p class="mb-2 item ast">제목</p>
			<CommonInputsInput05
				v-model="asTitle"
				class="mb-4"
				:height="40"
				placeholder="제목"
			/>

			<p class="mb-2 item">A/S 접수 내용</p>
			<v-textarea
				v-model="asContents"
				class="pa-0 ma-0 mb-4"
				placeholder="내용을 입력해주세요."
			/>

			<p class="mb-2 item">첨부파일</p>
			<v-row class="ma-0 mb-4">
				<CommonInputsInputFile03
					v-model="uploadFile"
					placeholder="jpg/png/gif이미지"
				/>
			</v-row>

			<p class="mb-2 item ast">개인정보 처리약관</p>
			<div class="px-2 py-3 term">
				<v-row class="ma-0">
					<v-col
						class="pa-0"
						cols="6"
					>
						<CommonCheckboxsCheckbox02
							v-model="privateTermsCheck"
							text="개인정보 이용에 동의"
							class="pa-0 ma-0"
							:block="false"
						/>
					</v-col>
					<v-col
						class="pa-0"
						cols="6"
						align="end"
						align-self="center"
					>
						<v-btn
							class="btn_openPrivate"
							text
							@click="common_openPrivate"
						>
							처리방침확인
						</v-btn>
					</v-col>
				</v-row>
			</div>
		</section>

		<v-row class="ma-0 py-5 px-4 btn_bar">
			<v-col
				class="pa-0"
				cols="4"
			>
				<div class="mr-1">
					<v-btn
						class="pa-0 btn_cancel"
						block
						height="42"
						color="#ccc"
						outlined
						@click="$emit('close')"
					>
						취소
					</v-btn>
				</div>
			</v-col>
			<v-col
				class="pa-0"
				cols="8"
			>
				<div class="ml-1">
					<v-btn
						class="pa-0 btn_register"
						block
						height="42"
						color="#00592D"
						:disabled="registerCheck"
						@click="apiCallRegister"
					>
						{{ title + '하기' }}
					</v-btn>
				</div>
			</v-col>
		</v-row>
		<!-- AS신청완료 팝업 -->
		<CommonDialogDialogDynamicBasic
			v-if="dialogComplete"
			:dialog="dialogComplete"
			:title="type === 'A/S 신청 완료!'"
			:components-path="'home/careflo/clear'"
			:width="800"
			@close="
				dialogComplete = false
				$emit('close', 'create')
			"
		/>
		<CommonLoading v-if="loading" />
	</div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import common from '@/mixins/common'

export default {
	name: 'Register',
	mixins: [common],
	props: ['type', 'item'],
	data: () => ({
		asTitle: '',
		asContents: '',
		asCount: null,
		uploadFile: null,
		privateTermsCheck: false,
		dialogComplete: false,
		loading: false,
	}),
	computed: {
		...mapGetters(['CAREFLO_GET_AUTH_USER']),
		title() {
			return `AS ${this.type === 'modify' ? '수정' : '신청'}`
		},
		registerCheck() {
			let check = false
			const checkValue = ['privateTermsCheck']

			for (let i of checkValue) {
				if (this[i] === null || this[i] === false) return true
			}

			const checkTextValue = ['asTitle', 'asContents']

			for (let i of checkTextValue) {
				if (this[i] === null || !this[i].length) return true
			}

			return check
		},
	},
	created() {
		if (this.type === 'modify') {
			this.asTitle = this.item.title || ''
			this.asContents = this.item.contents || ''
			this.asCount = this.item.as_count || ''
			this.uploadFile = this.item.file_url || null
		}
	},
	methods: {
		...mapMutations(['APP_MU_DIALOG']),
		...mapActions(['CAREFLO_ACT_APPLY_AS']),
		async apiCallRegister() {
			this.loading = true
			let items = {
				method: this.type === 'create' ? 'create' : 'modify',
				params: {
					nfc_code: this.CAREFLO_GET_AUTH_USER.nfc_code,
					title: this.asTitle || '',
					contents: this.asContents || '',
					cust_name: this.CAREFLO_GET_AUTH_USER.name,
					cust_hand: this.CAREFLO_GET_AUTH_USER.hand,
				},
			}

			if (this.type === 'modify') {
				items.params.as_count = this.asCount

				// 첨부파일 추가
				if (this.item.file_url?.length === 0 && this.uploadFile !== null) {
					console.log('추가')
					items.params.fileurl = this.uploadFile
				}

				// 첨부파일 삭제
				if (this.item.file_url?.length !== 0 && this.uploadFile === null) {
					console.log('삭제')
					items.params.file_url = this.item.file_url
				}

				// 첨부파일 수정
				if (this.item.file_url?.length !== 0 && this.uploadFile !== null && this.item.file_url !== this.uploadFile) {
					console.log('수정')
					items.params.file_url = this.item.file_url
					items.params.fileurl = this.uploadFile
				}
			} else {
				if (this.uploadFile !== null || this.uploadFile?.length > 0) {
					console.log('새로등록')
					items.params.fileurl = this.uploadFile
				}
			}
			this.CAREFLO_ACT_APPLY_AS(items)
				.then(async res => {
					this.loading = false
					if (res && this.type === 'modify') {
						await this.APP_MU_DIALOG({
							dialog: true,
							title: this.title,
							text: `${this.title}이 완료되었습니다.`,
						})
						this.$emit('close')
						// this.$router.push(`/careflo/customer/clear`)
					} else if (res && this.type === 'create') {
						this.dialogComplete = true
					}
				})
				.catch(err => {
					console.error('CAREFLO_ACT_APPLY_AS', err)
					this.loading = false
				})
		},
	},
}
</script>

<style scoped lang="scss">
.form {
	padding-bottom: 150px !important;
	background-color: $color_white;
	.item {
		display: inline-block;
		font-size: $font_normal;
		color: $color_gray_8;
		&.ast {
			position: relative;
			&::after {
				display: block;
				position: absolute;
				top: -2px;
				right: -8px;
				content: '*';
				color: #fb4f4f;
			}
		}
	}

	.v-textarea {
		:deep(.v-input__slot) {
			margin: 0 !important;
			border: 1px solid $color_gray_4;
			border-radius: 4px;

			textarea {
				padding: 8px 12px;
				&::placeholder {
					font-size: $font_normal;
					color: $color_gray_11;
				}
			}
		}
		&.v-input--is-focused {
			:deep(.v-input__slot) {
				border: 1px solid $color_primary_1 !important;
			}
		}

		fieldset {
			border: none !important;
		}
		:deep(.v-text-field__details) {
			display: none;
		}
	}
	:deep(.v-input__slot:before),
	:deep(.v-input__slot:after) {
		display: none !important;
	}
}

.term {
	background-color: $color_gray_1;
	border-radius: 4px;
	.v-btn {
		min-height: 0 !important ;
		height: auto !important ;
		.checkbox_text {
			font-size: $font_normal !important;
		}
	}
}

.btn_bar {
	width: 100%;
	position: fixed;
	left: 0;
	bottom: 0;
}
.btn_cancel {
	box-shadow: none !important;
	background-color: #fff;
	:deep(.v-btn__content) {
		font-size: $font_normal;
		color: $color_gray_6;
	}
}
.btn_register {
	box-shadow: none !important;
	:deep(.v-btn__content) {
		font-size: $font_normal;
		font-weight: $fw_bold;
		color: #fff;
	}
}
.btn_openPrivate {
	:deep(.v-btn__content) {
		font-size: $font_sm;
		border-bottom: 1px solid $color_gray_6;
		color: $color_gray_6;
	}
}
</style>
