<template>
	<v-col class="pa-0">
		<!-- 컨텐츠 -->
		<v-card-title class="pa-0">
			<v-row class="ma-0 text_wrapper">
				<v-col
					class="pa-0"
					align="center"
					align-self="center"
				>
					<div class="mb-3 dialog_text">
						<p>비밀번호 오류가 5회를 초과하였습니다.</p>
						<p>
							‘비밀번호 찾기’를 통해 임시 비밀번호를<br class="hidden-sm-and-down" />
							발급 받아주세요!
						</p>
					</div>
				</v-col>
			</v-row>
		</v-card-title>
	</v-col>
</template>

<script>
import model from '@/mixins/model'
export default {
	name: 'LoginPasswordLock',
	mixins: [model],
	methods: {
		closeDialog(e) {
			alert(e)
			this.$emit('close', e)
			//this.$emit('close')
		},
	},
}
</script>

<style scoped lang="scss">
// 제목
.dialog_title {
	font-family: 'NotoSansKR-Bold';
	font-size: 1.2rem;
	word-break: keep-all;
	color: $color_green_6;
}
.title_bottom {
	border-bottom: 1px solid $color_gray_4;
}

// 컨텐츠
.text_wrapper {
	min-height: 30px;
}
.dialog_text {
	font-size: $font_normal !important;
	color: $color_font;
	word-break: keep-all;
}
</style>
