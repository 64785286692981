import { render, staticRenderFns } from "./card_06.vue?vue&type=template&id=5e77b52e&scoped=true&"
import script from "./card_06.vue?vue&type=script&lang=js&"
export * from "./card_06.vue?vue&type=script&lang=js&"
import style0 from "./card_06.vue?vue&type=style&index=0&id=5e77b52e&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e77b52e",
  null
  
)

export default component.exports