<template>
	<!-- 제목 -->
	<v-row class="ma-0">
		<v-col
			cols="12"
			class="header"
		>
			<v-row class="ma-0 mb-0 mb-md-3 content_info">
				<div class="tal">
					<!-- <p
						v-if="items.brand_name"
						class="type"
					>
						{{ items.brand_name }} {{ items.goods_name }}
					</p> -->
					<p
						class="type"
						style="font-family: NotoSansKR-Light !important"
					>
						{{ typeTitle }}
					</p>
				</div>
				<div class="pa-0 tar">
					<span class="scrap"
						>스크랩 <strong>{{ Number(scrap_detail_count).toLocaleString('ko-KR') }}</strong></span
					>
					<span class="count"
						>조회 <strong>{{ Number(items.hits).toLocaleString('ko-KR') }}</strong></span
					>
					<span
						v-if="items.cut_regdate"
						class="date"
					>
						{{ items.cut_regdate.substring(0, 4) }}년 {{ items.cut_regdate.substring(4, 6) }}월
						{{ items.cut_regdate.substring(6, 8) }}일
					</span>
				</div>
			</v-row>
			<v-row class="ma-0">
				<v-col
					cols="12"
					sm="9"
					md="10"
					class="pa-0 title product_name"
					align="start"
					align-self="center"
				>
					{{ items.title }}
				</v-col>
				<v-col
					cols="12"
					sm="3"
					md="2"
					class="pa-0 btn_area"
					align="end"
				>
					<v-btn
						v-if="AUTH_GET_USER_AUTH !== 'AGENCY'"
						class="pa-0 bookMark"
						@click="scrap_call"
					>
						<v-icon v-if="scrap_check === 'post'">mdi-bookmark-outline</v-icon>
						<v-icon v-else>mdi-bookmark</v-icon>
					</v-btn>
					<CommonMenuMenu02
						:icon="'mdi-share-variant-outline'"
						:items="share_shareItems"
						@submit="onShare"
					/>
				</v-col>
			</v-row>
		</v-col>
	</v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import common from '@/mixins/common'
import scrap from '@/mixins/scrap'
import share from '@/mixins/share'

export default {
	name: 'HousewarmingHeader',
	mixins: [common, scrap, share],
	props: ['items', 'typeTitle'],
	data: () => ({}),
	computed: {
		...mapGetters(['AUTH_GET_USER_AUTH']),
	},
	watch: {
		items() {
			// 스크랩 카운트
			this.scrap_detail_count = this.items.scrap_cnt
		},
	},
	mounted() {},
	destroyed() {},
	methods: {
		onShare(item) {
			console.log('onShare', this.items)
			console.log('onShare', this.items.hw_id !== undefined)
			console.log('onShare', this.items.hw_id !== undefined)
			// const link = this.items.hw_id !== undefined? `${process.env.VUE_APP_BASIC_SERVER}/housewarming/3d`: `${process.env.VUE_APP_BASIC_SERVER}/housewarming/specialist`
			const productItem = {
				title: this.items.title,
				description: '집들이',
				image: this.items.image,
				url: null,
			}
			this.share_operator(item, productItem)
		},
	},
}
</script>

<style scoped lang="scss">
@mixin ellipsis_2 {
	display: -webkit-box !important;
	word-wrap: break-word !important;
	-webkit-line-clamp: 2 !important;
	-webkit-box-orient: vertical !important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
}

.contents_table {
	.header {
		padding: 0 10px;
		// border-top:2px solid #262626;
		// border-bottom:1px solid $color_gray_4;
		.title {
			@include ellipsis_2;
			//margin-bottom: 12px;
			font-family: 'NotoSansKR-Bold' !important;
			font-size: 24px !important;
			color: #262626;
			word-break: keep-all;
		}
		.v-btn {
			box-shadow: none !important;
			min-width: 40px !important;
			height: 40px !important;
			padding: 0 !important;
			background-color: #fff !important;
			.v-icon {
				color: #414141 !important;
			}
			&:hover {
				//color: #fff !important;
				//background-color: #42883d !important;
				.v-icon {
					//color: #fff !important;
				}
			}
		}
		.content_info {
			font-family: 'NotoSansKR-Light' !important;
			font-size: $font_normal;
			color: #a0a1a5;
			font-weight: 400;
			.tal,
			.tar {
				width: 50%;
			}
			.scrap,
			.count {
				position: relative;
				padding: 0 10px;
				font-family: 'NotoSansKR-Light' !important;
				font-weight: 400;
				font-size: $font_normal;
				&:after {
					display: block;
					width: 1px;
					height: 14px;
					position: absolute;
					top: 4px;
					right: 0;
					background-color: #a0a1a5 !important;
					content: '';
				}
				strong {
					font-family: 'NotoSansKR-Light' !important;
					color: #a0a1a5;
					font-weight: 400;
					font-size: $font_normal;
				}
			}
			.date {
				font-family: 'NotoSansKR-Light' !important;
				color: #a0a1a5;
				font-weight: 400;
				padding-left: 10px;
				font-size: $font_normal;
			}
		}
	}
}
// .product_name {
// 	text-align: start;
// 	font-family: 'NotoSansKR-Regular' !important;
// 	font-size: 26px !important;
// }
.btn_area {
	position: relative;
	display: flex;
	justify-content: end;
	align-items: center;
	height: fit-content;
	margin-top: 16px !important;
	.bookMark {
		border: 1px solid #cccccc;
		min-width: auto;
		background: none;
		padding: 12px !important;
		box-shadow: none;
		width: 40px !important;
		height: 40px !important;
		margin-right: 5px;
	}
	.shareMark {
		border: 1px solid #cccccc !important;
		min-width: auto;
		background: none;
		padding: 12px !important;
		width: 40px !important;
		height: 40px !important;
	}
	// .share_box {
	// 	width: 92px;
	// 	position: absolute;
	// 	right: 0px;
	// 	bottom: -110px;
	// 	background-color: #fff;
	// 	border: 1px solid #42883d;
	// 	padding: 5px 0;
	// 	border-radius: 10px;
	// 	p {
	// 		font-family: 'NotoSansKR-Light' !important;
	// 		font-size: 13px;
	// 		padding: 0 10px;
	// 		&:hover {
	// 			color: #fff !important;
	// 			background-color: #42883d !important;
	// 		}
	// 	}
	// }
}

@media all and (min-width: 981px) and (max-width: 1280px) {
}
@media all and (min-width: 769px) and (max-width: 980px) {
	.contents_table {
		.body {
			.event_clear {
				height: 90px;
				margin-bottom: 30px;
			}
		}
		.board_list {
			.row {
				padding: 10px 0;
			}
		}
	}
}
@media all and (min-width: 380px) and (max-width: 768px) {
	.contents_table {
		.header {
			padding: 0 !important;
			.title {
				font-size: 20px !important;
			}
			.content_info {
				font-size: 14px !important;
				.tal,
				.tar {
					width: 100%;
				}
				.tar {
					margin-left: -10px !important;
					text-align: left !important;
				}
				.type {
					font-family: 'NotoSansKR-Light' !important;
				}
			}
		}
		.body {
			padding: 32px 0 !important;
			.event_clear {
				height: 75px;
				margin-bottom: 20px;
			}
			span {
				font-size: 15px;
			}
		}
		.notice {
			padding-left: 0 !important;
			.title {
				margin-bottom: 14px;
				font-size: 16px !important;
			}
			ul {
				padding: 0;
				li {
					font-size: 14px !important;
				}
			}
		}
		.board_list {
			.row {
				padding: 10px 0;
			}
		}
	}
}
@media all and (max-width: 380px) {
	.contents_table {
		.header {
			padding: 0 !important;
			.title {
				font-size: 20px !important;
			}
			.content_info {
				font-size: 14px !important;
				.tal,
				.tar {
					width: 100%;
				}
				.tar {
					margin-left: -10px !important;
					text-align: left !important;
				}
			}
		}
		.body {
			padding: 20px 0 !important;
			.event_clear {
				height: 75px;
				margin-bottom: 20px;
			}
			span {
				font-size: 15px;
			}
		}
		.notice {
			padding-left: 0 !important;
			.title {
				margin-bottom: 14px;
				font-size: 16px !important;
			}
			ul {
				padding: 0;
				li {
					font-size: 14px !important;
				}
			}
		}
		.board_list {
			.row {
				padding: 10px 0;
			}
		}
	}
}
</style>
