<template>
	<v-col
		cols="12"
		align="center"
	>
		<v-card class="feature_tit02 pt-5 pb-5">
			<p>특장점</p>
		</v-card>
		<v-row class="ma-0 feature_table02">
			<v-col
				v-for="(item, i) in items"
				:key="i"
				cols="12"
				md="6"
				class="pa-0 py-10 item"
				:class="items.length % 2 === 0 ? 'itemEven' : 'itemOdd'"
			>
				<v-row class="ma-0 fill-height">
					<v-col
						cols="3"
						class="pa-0"
					>
						<v-img
							width="80"
							height="80"
							class="icon"
							:src="item.mainImage"
							eager
						/>
					</v-col>
					<v-col
						cols="9"
						class="pa-0"
						align="start"
					>
						<p class="tit mb-3">{{ item.title }}</p>
						<span class="text">{{ item.text }}</span>
					</v-col>
					<v-row
						class="ma-0"
						justify="center"
						align="center"
					>
						<v-col
							v-if="item.subImage"
							cols="12"
							class="pa-0 fill-height"
							align="center"
						>
							<v-img
								class="mt-5"
								:width="getWidth > 475 ? 350 : 200"
								:src="item.subImage"
								eager
							/>
						</v-col>
					</v-row>
				</v-row>
			</v-col>
		</v-row>
	</v-col>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	name: 'HomeProductDetailFeature',
	components: {},
	props: ['items'],
	data: () => ({}),
	computed: {
		...mapGetters(['APP_GET_APP_WIDTH']),
		getWidth() {
			return this.APP_GET_APP_WIDTH
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
}
</script>
<style scoped lang="scss">
.item.itemEven {
	border-bottom: 1px solid #e6e6e6;
	// &:nth-of-type(7) {
	// 	border-bottom: 0;
	// }
	// &:last-child {
	// 	border-bottom: 0;
	// }
	&:nth-last-of-type(1) {
		border-bottom: 0;
	}
	&:nth-last-of-type(2) {
		border-bottom: 0;
	}
}
.item.itemOdd {
	border-bottom: 1px solid #e6e6e6;
	&:last-child {
		border-bottom: 0;
	}
}

@media all and (max-width: 960px) {
	.item.itemEven {
		// &:nth-of-type(3) {
		// 	border-bottom: 0;
		// }
		&:nth-of-type(7) {
			border-bottom: 1px solid #e6e6e6;
		}
		&:last-child {
			border-bottom: 0;
		}
	}
	.feature_table02 {
		.item {
			.tit {
				padding-left: 10px;
			}
			.text {
				padding-left: 10px;
				display: block;
			}
		}
	}
}
</style>
