<template>
	<div>
		<v-row
			class="ma-0"
			align="center"
		>
			<v-col
				cols="8"
				align="start"
				class="list_title"
			>
				{{ title }}
				<v-btn
					class="pa-0"
					icon
					:to="link"
				>
					<v-icon>mdi-dock-window</v-icon>
				</v-btn>
			</v-col>
			<v-col
				cols="4"
				align="end"
				class="list_count"
			>
				{{ countTitle }}: {{ count }}{{ countUnit }}
			</v-col>
		</v-row>
		<v-row
			v-if="tabItems"
			class="ma-0 mb-3"
			align="center"
		>
			<v-col
				cols="12"
				sm="4"
				align="start"
				class="pa-0"
			>
			</v-col>
			<v-col
				cols="12"
				sm="8"
				align="end"
				class="pa-0"
			>
				<CommonTabsTab01
					v-model="tab"
					:tab-items="tabItems"
					class-name="margin_0"
				/>
			</v-col>
		</v-row>
		<v-row
			v-if="headerTitleLeft || headerTitleCenter || headerTitleRight"
			class="ma-0 list_header"
		>
			<v-col
				v-show="headerTitleLeft"
				cols="12"
				sm="3"
				align="center"
				class="list_header_title"
			>
				{{ headerTitleLeft }}
			</v-col>
			<v-col
				v-show="headerTitleCenter"
				cols="12"
				sm="3"
				align="center"
				class="list_header_title"
			>
				{{ headerTitleCenter }}
			</v-col>
			<v-col
				v-show="headerTitleRight"
				cols="12"
				sm="6"
				align="center"
				class="list_header_title"
			>
				{{ headerTitleRight }}
			</v-col>
		</v-row>
		<v-card
			:height="listHeight || 310"
			class="list_bg"
			elevation="1"
		>
			<v-list :class="[items.length < 1 ? 'no-data' : '']">
				<v-list-item-group v-model="model">
					<template v-if="items.length < 1">
						<v-icon color="#CCC">mdi-alert-circle-outline</v-icon>
						<p class="no-data--alert">내역이 없습니다.</p>
					</template>
					<template
						v-for="(item, i) in items"
						v-else
					>
						<v-list-item
							:key="i"
							class="list_item"
						>
							<v-col
								class="pa-0"
								cols="12"
							>
								<v-row class="ma-0">
									<v-col
										cols="12"
										sm="3"
										align="start"
										class="list_value_text"
									>
										{{ item.first }}
									</v-col>
									<v-col
										cols="12"
										sm="3"
										align="start"
										class="list_value_text"
									>
										{{ item.second }}
									</v-col>
									<v-col
										cols="12"
										sm="6"
										align="center"
										class="list_value_text"
									>
										{{ item.third }}
									</v-col>
								</v-row>
							</v-col>
						</v-list-item>
					</template>
				</v-list-item-group>
			</v-list>
		</v-card>
	</div>
</template>

<script>
import model from '@/mixins/model'

export default {
	name: 'List01',
	components: {},
	mixins: [model],
	props: [
		'title',
		'countTitle',
		'countUnit',
		'link',
		'headerTitleLeft',
		'headerTitleCenter',
		'headerTitleRight',
		'left',
		'center',
		'right',
		'listHeight',
		'items',
		'tabItems',
	],
	data: () => ({
		tab: 0,
	}),
	computed: {
		count() {
			return this.items.length
		},
	},
	watch: {
		tab(value) {
			this.$emit('tabClicked', value)
		},
	},
	created() {},
	mounted() {},
	methods: {},
}
</script>
<style scoped lang="scss">
.list {
	&_bg {
		overflow: hidden;
		overflow-y: auto;
	}
	&_title {
		font-size: 1.2rem;
		font-family: 'NotoSansKR-Bold';
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		max-width: 100%;
	}
	&_text {
		font-size: 1rem;
		font-family: 'NotoSansKR-Regular';
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		max-width: 100%;
	}
	&_item {
		border-top: 1px solid $color_gray_4;
		&:first-child {
			border-top: none;
		}
	}
	&_value {
		&_title {
			font-family: 'NotoSansKR-Bold';
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			max-width: 100%;
		}

		&_text {
			font-family: 'NotoSansKR-Regular';
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			max-width: 100%;
		}
	}
}
.v-list-item--active::before {
	opacity: 0 !important;
}
.no-data {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	.v-item-group {
		text-align: center;
	}
	&--alert {
		padding: 6px;
		color: $color_gray_4;
	}
}
.list_header {
	background-color: #f1f2f3;
	border: 1px solid $color_gray_4;
}
::-webkit-scrollbar {
	display: none !important;
	z-index: 10000;
	-webkit-appearance: none;
}
::-webkit-scrollbar:vertical {
	width: 10px;
}
::-webkit-scrollbar:horizontal {
	height: 10px;
}
::-webkit-scrollbar-track {
	background: #ededed;
	border-radius: 0;
}
::-webkit-scrollbar-thumb {
	background: #92a99b;
	border-radius: 5px;
}
</style>
