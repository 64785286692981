<template>
	<div class="wrapper">
		<v-container
			class="pa-0 px-0 px-md-3"
			fluid
		>
			<!-- <div class="title">간편상담 신청내역 학인</div>
			<p>
				회원으로 간편 상담을 신청하신 고객님께서는 <br />
				로그인 후 마이페이지 > 간편상담 메뉴 메뉴를 통해서 신청 내역을 확인해주세요.
			</p>

			{{ userdata?.login_id }}
			{{ userdata?.login_name }} -->
			<!-- <div class="custom_quote pt-12">
				<v-col
					v-show="true"
					cols="12"
					align="left"
					class="pa-0 inner"
				>
					<v-row
						v-if="GUEST_GET_ESTIMATE.length"
						class="ma-0"
					>
						<v-col class="pa-0">
							<CommonCardMenuGroup02
								:menu-type="'estimate'"
								:card-menu="GUEST_GET_ESTIMATE"
							/>
						</v-col>
					</v-row>
					<v-row
						v-else
						justify="center"
						class="ma-0"
					>
						<UserItemEmpty
							:text="`집 근처 인테리어점을 찾아<br>첫 번째 <strong>견적상담 의뢰</strong>를 진행해보세요.`"
						/>
					</v-row>
				</v-col>
			</div> -->
			<div class="custom_quote">
				<v-col
					cols="12"
					class="pa-0 register_history inner"
					align="center"
				>
					<v-col
						cols="12"
						class="pa-0"
						align="center"
					>
						<div class="content_title">
							간편상담 신청내역 확인
							<!-- <p class="sub_title">동화자연마루를 이용해주셔서 감사합니다.</p> -->
						</div>
					</v-col>

					<UserItemUserInfoGuest :userdata="userdata" />
					<p class="txt">
						회원으로 간편 상담을 신청하신 고객님께서는 <br />
						로그인 후 마이페이지 > 간편상담 메뉴를 통해서 신청 내역을 확인해주세요.
						<span
							style="display: block"
							class="pt-2"
						>
							<v-btn
								text
								class="btn_login"
								@click="$router.push('/login')"
								>로그인하기 〉
							</v-btn>
						</span>
					</p>
					<div class="pt-12">
						<v-col
							class="pa-0"
							cols="12"
							align="center"
						>
							<v-row
								v-if="GUEST_GET_ESTIMATE.length"
								class="ma-0 justify-content-center nonmemberConfirm"
							>
								<v-col class="pa-0">
									<CommonCardMenuGroup02
										:menu-type="'guestestimate'"
										:card-menu="paginatedItems"
									/>
								</v-col>
							</v-row>
							<v-row
								v-else
								justify="center"
								class="ma-0 pb-8"
							>
								<!-- <UserItemEmpty
									:text="`집 근처 인테리어점을 찾아<br>첫 번째 <strong>견적상담 의뢰</strong>를 진행해보세요.`"
								/> -->
								<p>간편 상담 신청 내역이 존재하지 않습니다.</p>
							</v-row>
							<!-- user -->
							<!-- <UserUserEstimateDetail v-if="getPart === '0'" />
					<UserUserQualityDetail v-if="getPart === '1'" />
					<UserUserAsDetail v-if="getPart === '2'" />
					<UserUserQuestionDetail v-if="getPart === '3'" /> -->

							<!-- agency -->
							<!-- <UserAgencyHousewarmingDetail v-if="getPart === '4'" />
					<UserAgencyEstimateDetail v-if="getPart === '5'" />
					<UserAgencyAskDetail v-if="getPart === '6'" /> -->
						</v-col>
					</div>
					<div v-if="GUEST_GET_ESTIMATE.length !== 0">
						<CommonPaginationListPagination03
							:total-pages="totalPages"
							:items-per-page="itemsPerPage"
							:current-page="currentPage"
							@changePage="handlePageChange"
						/>
					</div>
				</v-col>
			</div>
		</v-container>
	</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import common from '@/mixins/common'
import sms from '@/mixins/sms'

export default {
	name: 'HomeInteriorMypageConfirm02',
	mixins: [common, sms],
	props: ['userdata'],

	data: () => ({
		currentPage: 1,
		itemsPerPage: 7,
		items: [], // 실제 데이터가 들어가는 곳으로 가정
		previousPage: null, // 이전 페이지를 저장하기 위한 변수
	}),
	computed: {
		...mapGetters(['GUEST_GET_ESTIMATE', 'GUEST_GET_MYPAGE_INFO_PREVIOUSPAGE']),
		changedItems() {
			return this.items
		},
		totalItems() {
			return this.paginatedItems.length
		},
		totalPages() {
			return Math.ceil(this.changedItems.length / this.itemsPerPage)
		},
		paginatedItems() {
			const startIndex = (this.currentPage - 1) * this.itemsPerPage
			const endIndex = startIndex + (this.itemsPerPage + 1)
			return this.changedItems.slice(startIndex, endIndex)
		},
	},
	watch: {
		previousPage(val) {
			if (val) {
				this.GUEST_MU_MYPAGE_INFO_PREVIOUSPAGE(val)
			}
		},
	},
	created() {
		this.items = this.GUEST_GET_ESTIMATE
		if (this.GUEST_GET_MYPAGE_INFO_PREVIOUSPAGE !== null) {
			this.previousPage = this.GUEST_GET_MYPAGE_INFO_PREVIOUSPAGE
			this.currentPage = this.previousPage
		}
	},
	methods: {
		...mapMutations(['GUEST_MU_ESTIMATE_SELECT', 'GUEST_MU_MYPAGE_INFO_PREVIOUSPAGE']),
		handlePageChange(pageNumber) {
			this.currentPage = pageNumber
			this.previousPage = this.currentPage // 이전 페이지를 저장
		},
	},
}
</script>

<style scoped lang="scss">
.custom_quote {
	.title {
		max-width: 1260px;
		margin: 0 auto;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.inner {
		max-width: 1260px;
		padding: 0 30px 30px !important;
		margin: 30px auto;
		background-color: $color_white;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
		border-radius: 16px;
		position: relative;
		z-index: 3;
		h3 {
			font-size: $font_xl;
		}
		.confirmMember {
			p {
				font-size: $font_lg;
				color: #262626;
				font-weight: $fw_regular;
			}
			a {
				font-size: $font_normal;
				color: #666666;
				text-decoration: underline;
				font-weight: $fw_normal;
			}
		}
		.nonmemberConfirm {
			max-width: 840px;
		}
	}
}
.txt {
	font-weight: $fw_bold;
	font-size: $font_mid;
}

.btn_login {
	:deep(.v-btn__content) {
		color: $color_gray_7;
		font-size: $font_normal;
		font-weight: $fw_regular;
		border-bottom: 1px solid $color_gray_7;
	}
}

@media all and (max-width: 960px) {
	.custom_quote {
		.inner {
			box-shadow: none;
			border-radius: 0;
			padding: 0 15px 15px !important;
		}
	}
}
</style>
