<template>
	<div>
		<v-col
			cols="12"
			class="pa-0 pb-2"
		>
			<v-row
				class="ma-0"
				align="center"
			>
				<v-col
					cols="6"
					class="pa-0"
				>
					전체: {{ desserts.length }}건
				</v-col>
				<v-spacer />
				<v-col
					cols="6"
					md="3"
					class="table-search pa-0"
				>
					<v-text-field
						v-model="search"
						append-icon="mdi-magnify"
						single-line
						hide-details
					></v-text-field>
				</v-col>
			</v-row>
		</v-col>
		<v-data-table
			id="virtual-scroll-table"
			v-model="model"
			:headers="bindingHeaders"
			:page.sync="page"
			:items-per-page="itemsPerPage"
			:items="desserts"
			:search="search"
			:class="[className, lineHeaders ? 'lineHeader' : '']"
			:loading="loading"
			item-key="id"
			hide-default-header
			hide-default-footer
			:mobile-breakpoint="0"
			elevation="0"
			:style="`max-height:${height};`"
			@page-count="pageCount = $event"
		>
			<template v-slot:header="{ props }">
				<thead>
					<tr
						v-for="(header, i) in headers"
						:key="i"
					>
						<th
							v-for="(th, j) in header.row"
							:key="j"
							:rowspan="th.rowspan"
							:colspan="th.colspan"
							:width="th.width"
							:style="{ textAlign: th.align }"
						>
							{{ th.text }}
						</th>
					</tr>
				</thead>
			</template>

			<!-- <template v-slot:item>

			</template> -->

			<template v-slot:item.state="{ item, header }">
				<td
					class="admin_grid_text"
					:style="`max-width:${header.width}`"
				>
					<p v-if="item.state">ON</p>
					<p v-else>OFF</p>
				</td>
			</template>
			<template v-slot:item.use_yn="{ item, header }">
				<td
					class="admin_grid_text"
					:style="`max-width:${header.width}`"
				>
					<p v-if="item.use_yn === 'Y'">ON</p>
					<p v-else>OFF</p>
				</td>
			</template>
			<template v-slot:item.title="{ item, header }">
				<td
					class="admin_grid_text"
					:style="`max-width:${header.width}`"
				>
					{{ item.title }}
				</td>
			</template>
			<template v-slot:item.contents="{ item, header }">
				<td>
					<p
						class="admin_grid_contents"
						:style="`max-width:${header.width};`"
						v-html="item.contents"
					/>
				</td>
			</template>
			<template v-slot:item.recomm_prod="{ item }">
				<a
					:href="`/product/detail/${item.recomm_prod}`"
					target="_blank"
					>{{ item.recomm_prod }}</a
				>
			</template>
			<template v-slot:item.recomm_prod1="{ item }">
				<a
					:href="`/product/detail/${item.recomm_prod1}`"
					target="_blank"
					>{{ item.recomm_prod1 }}</a
				>
			</template>
			<template v-slot:item.recomm_prod2="{ item }">
				<a
					:href="`/product/detail/${item.recomm_prod2}`"
					target="_blank"
					>{{ item.recomm_prod2 }}</a
				>
			</template>
			<template v-slot:item.recomm_prod3="{ item }">
				<a
					:href="`/product/detail/${item.recomm_prod3}`"
					target="_blank"
					>{{ item.recomm_prod3 }}</a
				>
			</template>
			<template v-slot:item.recomm_prod4="{ item }">
				<a
					:href="`/product/detail/${item.recomm_prod4}`"
					target="_blank"
					>{{ item.recomm_prod4 }}</a
				>
			</template>
			<template v-slot:item.recomm_prod5="{ item }">
				<a
					:href="`/product/detail/${item.recomm_prod5}`"
					target="_blank"
					>{{ item.recomm_prod5 }}</a
				>
			</template>
			<template v-slot:item.recomm_prod6="{ item }">
				<a
					:href="`/product/detail/${item.recomm_prod6}`"
					target="_blank"
					>{{ item.recomm_prod6 }}</a
				>
			</template>
			<template v-slot:item.recomm_prod7="{ item }">
				<a
					:href="`/product/detail/${item.recomm_prod7}`"
					target="_blank"
					>{{ item.recomm_prod7 }}</a
				>
			</template>
			<template v-slot:item.recomm_prod8="{ item }">
				<a
					:href="`/product/detail/${item.recomm_prod8}`"
					target="_blank"
					>{{ item.recomm_prod8 }}</a
				>
			</template>
			<template v-slot:item.picture_color1="{ item }">
				<span
					style="height: 36px; width: 36px; display: inline-block; margin-right: 4px; vertical-align: middle"
					:style="{ background: item.picture_color1 }"
				></span
				>{{ item.picture_color1 }}
			</template>
			<template v-slot:item.picture_color2="{ item }">
				<span
					style="height: 36px; width: 36px; display: inline-block; margin-right: 4px; vertical-align: middle"
					:style="{ background: item.picture_color2 }"
				></span
				>{{ item.picture_color2 }}
			</template>
			<template v-slot:item.picture_color3="{ item }">
				<span
					style="height: 36px; width: 36px; display: inline-block; margin-right: 4px; vertical-align: middle"
					:style="{ background: item.picture_color3 }"
				></span
				>{{ item.picture_color3 }}
			</template>
			<template v-slot:item.picture_color4="{ item }">
				<span
					style="height: 36px; width: 36px; display: inline-block; margin-right: 4px; vertical-align: middle"
					:style="{ background: item.picture_color4 }"
				></span
				>{{ item.picture_color4 }}
			</template>
			<template v-slot:item.wall_color="{ item }">
				<span
					style="height: 36px; width: 36px; display: inline-block; margin-right: 4px; vertical-align: middle"
					:style="{ background: item.wall_color }"
				></span
				>{{ item.wall_color }}
			</template>
			<template v-slot:item.floor_color="{ item }">
				<span
					style="height: 36px; width: 36px; display: inline-block; margin-right: 4px; vertical-align: middle"
					:style="{ background: item.floor_color }"
				></span
				>{{ item.floor_color }}
			</template>
			<template v-slot:item.object_color="{ item }">
				<span
					style="height: 36px; width: 36px; display: inline-block; margin-right: 4px; vertical-align: middle"
					:style="{ background: item.object_color }"
				></span
				>{{ item.object_color }}
			</template>
			<template v-slot:item.thumbnail="{ item }">
				<v-btn
					v-if="item.thumbnail"
					outlined
					@click.stop="common_onLink(item.thumbnail)"
				>
					<span class="admin_grid_text">썸네일 확인</span>
				</v-btn>
			</template>
			<template v-slot:item.image="{ item }">
				<v-btn
					v-if="item.image"
					outlined
					@click.stop="common_onLink(item.image)"
				>
					<span class="admin_grid_text">이미지 확인</span>
				</v-btn>
			</template>
			<template v-slot:item.link="{ item }">
				<v-btn
					v-if="item.link"
					outlined
					@click.stop="common_onLink(item.link)"
				>
					<span class="admin_grid_text">링크 확인</span>
				</v-btn>
			</template>
			<template v-slot:item.file="{ item }">
				<v-btn
					v-if="item.file"
					outlined
					@click.stop="common_onLink(item.file)"
				>
					<span class="admin_grid_text">파일 확인</span>
				</v-btn>
			</template>
			<template v-slot:item.data-read="{ item }">
				<v-btn
					icon
					@click.stop="$emit('open', item)"
				>
					<v-icon>mdi-eye-settings</v-icon>
				</v-btn>
			</template>
			<template v-slot:item.data-edit="{ item }">
				<v-btn
					icon
					@click.stop="$emit('edit', item)"
				>
					<v-icon>mdi-pencil</v-icon>
				</v-btn>
			</template>
			<template v-slot:item.data-etc="{ item }">
				<v-btn
					icon
					@click.stop="$emit('etc', item)"
				>
					<v-icon>mdi-wrench</v-icon>
				</v-btn>
			</template>
			<template v-slot:item.data-link="{ item }">
				<v-btn
					icon
					@click.stop="$emit('link', item)"
				>
					<v-icon>mdi-link</v-icon>
				</v-btn>
			</template>

			<template v-slot:no-data> NO DATA HERE! </template>
		</v-data-table>
		<div class="table-pagination text-center pt-2">
			<v-pagination
				v-model="page"
				:length="pageCount"
				:total-visible="totalVisible"
			></v-pagination>
			<v-select
				v-model="itemsPerPage"
				:items="[5, 10, 15, 20]"
				label="개수"
			></v-select>
			<!-- <v-text-field
				:value="itemsPerPage"
				label="Items per page"
				type="number"
				min="-1"
				max="100"
				@input="itemsPerPage = parseInt($event, 10)"
			></v-text-field> -->
		</div>
	</div>
</template>

<script>
import model from '@/mixins/model'
import common from '@/mixins/common'
import admin from '@/mixins/admin'

export default {
	// gridType_03에 테이블 헤더 커스텀 추가
	// 통계관리 - 사진,색상검색 커스텀 슬롯 추가
	name: 'GridType04',
	mixins: [model, common, admin],
	props: [
		'bindingHeaders',
		'headers',
		'lineHeaders',
		'desserts',
		'height',
		'readonly',
		'className',
		'loading',
		'numbersPerPage',
	],
	data: () => ({
		page: 1,
		pageCount: 0,
		totalVisible: 7,
		itemsPerPage: 11,
		search: '',
	}),
	computed: {
		showSelect() {
			return this.readonly === undefined
		},
	},
	watch: {
		loading(val) {
			if (!val) {
				// scroll Top push
				// this.common_scrollTop('#virtual-scroll-table')
				this.start = 0
			}
		},
	},
	created() {
		this.itemsPerPage = this.numbersPerPage || 11
	},
	mounted() {},
	methods: {
		changeStatus(item) {
			this.$emit('status', item)
		},
	},
}
</script>
<style scoped lang="scss">
:deep(.v-data-table-header) {
	background: #ededed !important;
}
:deep(.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th) {
	color: #000 !important;
	font-size: 1rem;
	background: #ededed !important;
}
#virtual-scroll-table {
	overflow: auto;
}
.v-data-table {
	border-radius: 0 !important;
	border: 1px solid #ccc;
}

.lineHeader {
	&.v-data-table {
		> :deep(.v-data-table__wrapper) > table {
			border-collapse: collapse;
		}
		&.theme--light > .v-data-table__wrapper > table > thead > tr {
			&:first-child > th {
				border-top: none;
			}
			& > th {
				height: 36px;
				border: thin solid rgba(0, 0, 0, 0.12);
				&:first-child {
					border-left: none;
				}
				&:last-child {
					border-right: none;
				}
			}
		}
	}
}
.table-pagination {
	position: relative;
	> .v-input {
		position: absolute;
		top: 0;
		width: 60px;
	}
}
</style>
