<template>
	<v-dialog
		v-model="dialog"
		persistent
		width="1000"
	>
		<v-card class="pa-0">
			<AdminItemsTitle
				:title="`${title} 내용 확인`"
				@close="$emit('close')"
			/>
			<v-col class="pa-0 pa-md-3">
				<v-container>
					<v-row class="ma-0">
						<v-col
							cols="12"
							class="pa-0"
						>
							<CommonDataTableGridType02
								v-model="models"
								:headers="headers"
								:desserts="desserts"
								height="400px"
							/>
						</v-col>
					</v-row>
				</v-container>
				<v-row
					class="ma-0"
					align="center"
					justify="center"
				>
					<v-col
						cols="6"
						sm="2"
						md="2"
						lg="2"
					>
						<v-btn
							color="error"
							block
							tile
							elevation="0"
							@click="$emit('close')"
						>
							<v-icon>clear</v-icon>
							<span class="ml-2">취소</span>
						</v-btn>
					</v-col>
					<v-col
						cols="6"
						sm="2"
						md="2"
						lg="2"
					>
						<v-btn
							color="primary"
							block
							tile
							elevation="0"
							:disabled="models.length === 0"
							@click="confirmRegister = true"
						>
							<v-icon>clear</v-icon>
							<span class="ml-2">등록</span>
						</v-btn>
					</v-col>
				</v-row>
			</v-col>
		</v-card>
		<CommonConfirm
			:dialog="confirmRegister"
			title="등록"
			text="등록하시겠습니까?"
			@close="confirmRegister = false"
			@submit="registerData()"
		/>
	</v-dialog>
</template>

<script>
import common from '@/mixins/common'
import { mapActions, mapMutations } from 'vuex'
export default {
	name: 'AdminProductTagRead',
	mixins: [common],
	props: ['title', 'headers', 'dialog', 'desserts', 'maxLength', 'type', 'checkedData'],
	data: () => ({
		imageItem: null,
		models: [],
		confirmRegister: false,
	}),
	computed: {},
	watch: {
		models(nowV, oldV) {
			if (this.maxLength < nowV.length)
				this.$nextTick(() => {
					this.models = oldV
					this.APP_MU_DIALOG({
						dialog: true,
						title: '',
						text: `최대 ${this.maxLength}개만 선택 가능합니다.`,
					})
					// this.$toastr.error(`최대 ${this.maxLength}개만 선택 가능합니다.`, { timeOut: 1000 })
				})
		},
	},
	created() {},
	mounted() {
		this.models = this.checkedData
		this.desserts.sort((a, b) => {
			if (a[`${this.type}_yn`] === 'N' && b[`${this.type}_yn`] === 'N') return -1
			if (a[`${this.type}_yn`] < b[`${this.type}_yn`]) return 1
			if (a[`${this.type}_yn`] > b[`${this.type}_yn`]) return -1
			if (parseInt(a[`${this.type}_priority`]) < parseInt(b[`${this.type}_priority`])) return -1
			if (parseInt(a[`${this.type}_priority`]) > parseInt(b[`${this.type}_priority`])) return 1
			if (parseInt(a.id) < parseInt(b.id)) return -1
			if (parseInt(a.id) > parseInt(b.id)) return 1
			return 0
		})
	},
	destroyed() {},
	methods: {
		...mapMutations(['APP_MU_DIALOG', 'HOME_MU_SEARCH_PICTURE_UPLOAD_FILE']),
		...mapActions(['ADMIN_ACT_PRODUCT_TAG_ITEMS']),
		async registerData() {
			const params = this.models.map(e => {
				return { tag_id: parseInt(e.id) }
			})
			const items = { method: `${this.type}Regi`, params: params }
			try {
				await this.ADMIN_ACT_PRODUCT_TAG_ITEMS(items)
				this.confirmRegister = false
				this.$emit('close')
			} catch (e) {
				console.log(e)
				this.confirmRegister = false
			}
		},
	},
}
</script>

<style scoped lang="scss"></style>
