<template>
	<div class="mb-5 item">	
		<v-col class="pa-0">
			<HomeMainNewEstimate />
		</v-col>
	</div>
</template>

<script>
export default {
	name: 'CommunityHomeNewRapidcalc',
	components: {},
	data: () => ({}),
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
}
</script>

<style scoped lang="scss">
.item {
	background-color: $color_white;
	border-radius: 16px;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
	overflow: hidden;

	.category {
		color: $color_white;
		p {
			font-size: $font_sub_tit;
			font-weight: $fw_bold;
			line-height: 1 !important;
		}
		span {
			word-break: keep-all;
			font-size: $font_normal;
			font-weight: $fw_normal;
		}
	}

	.cont {
		position: relative;
		margin-top: -20px;
		border-radius: 16px !important;
		background-color: $color_white;
		h3 {
			color: $color_font;
			font-size: $font_xl;
			font-weight: $fw_bold;
		}
	}

	.bg_event {
		padding: 20px !important;
		background-color: $color_primary_1;
	}

	.event_list {
		.v-image {
			border-radius: 4px;
		}
		.tit {
			color: $color_font;
			font-size: $font_lg;
			font-weight: $fw_normal;
		}
		.text {
			color: $color_gray_5;
			font-size: $font_normal;
			font-weight: $fw_normal;
		}
	}
}
:deep(.v-window__prev) {
	position: absolute;
	top: 40%;
	left: -30px;
	background: rgba(0, 0, 0, 0.3);
	z-index: 99;
}
:deep(.v-window__next) {
	position: absolute;
	top: 40%;
	right: -30px;
	background: rgba(0, 0, 0, 0.3);
}
.v-carousel {
	overflow: inherit;
}

@media all and (max-width: 1264px) {
	.item {
		.cont {
			margin-top: 0 !important;
			border-radius: 0 !important;
		}
		.bg_event {
			padding: 20px 16px !important;
		}
	}
}

@media all and (max-width: 600px) {
	.item {
		border-radius: 0 !important;	
	}
}

@media all and (max-width: 380px) {
	.item {
		.category {
			p {
				font-size: $font_xl !important;
				line-height: 22px !important;
			}
			span {
				font-size: $font_sm !important;
			}
		}
		.cont {
			margin-top: 0px !important;
			h3 {
				font-size: $font_lg !important;
			}
		}
		.event_list {
			.tit {
				font-size: $font_normal !important;
			}
		}
	}
}
</style>
