<template>
	<div class="">
		<v-carousel
			ref="carousel"
			v-model="currentIndex"
			:show-arrows="false"
			delimiter-icon="mdi-color-helper"
			hide-delimiters
			hide-delimiter-background
			class="slide_type01 clickCursor"
			height="auto"
			:interval="5000"
			cycle
		>
			<!-- :style="`height:${height} !important`" -->
			<v-carousel-item
				v-for="(item, i) in carouselItems"
				:key="i"
				eager
				width="100%"
			>
				<div
					class="event_list clickCursor"
					@click="onClick(item)"
				>
					<v-img
						:src="item.image_url"
						contain
						eager
						:class="item.link_url ? 'clickCursor' : ''"
						width="100%"
						aspect-ratio="1"
					/>
					<div
						v-if="item.title"
						class="thumb_text_area"
					>
						<p
							v-if="item.title"
							class="text_area_title"
						>
							{{ item.title }}
						</p>
						<p
							v-if="item.familytype_name"
							class="text_area_text01"
						>
							{{ item.familytype_name }}
						</p>
						<p
							v-if="item.cocat_subtitle"
							class="text_area_text02"
						>
							{{ item.cocat_subtitle }}
						</p>
						<p
							v-if="item.business_name"
							class="text_area_text03"
						>
							{{ item.business_name }}
						</p>
					</div>
				</div>
			</v-carousel-item>
		</v-carousel>
		<div class="carousel-nav">
			<button @click="prev(currentIndex)">
				<v-img
					:src="require('@/assets/img/home/main/img_slide_left_arrow_gray.svg')"
					eager
				/>
			</button>
			<span class="page_count">
				<em>{{ currentIndex + 1 }}</em> / {{ carouselItems.length }}
			</span>
			<button @click="next(currentIndex)">
				<v-img
					:src="require('@/assets/img/home/main/img_slide_right_arrow_gray.svg')"
					eager
				/>
			</button>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import model from '@/mixins/model'
import common from '@/mixins/common'
import google_analytic from '@/mixins/google_analytic'

export default {
	name: 'HomeMainNewCarousels',
	mixins: [model, common, google_analytic],
	props: ['carouselItems', 'height', 'menuType'],
	data: () => ({
		currentIndex: 0,
		house3dItem: [],
	}),
	computed: {
		...mapGetters([
			// 제품 item
			'HOME_GET_GET_PRODUCT_ITEMS',
		]),
		currentTitle() {
			return this.carouselItems[this.currentIndex]
		},
	},
	watch: {
		getQuery() {
			this.getData()
		},
	},
	created() {
		this.getData()
	},
	mounted() {
		console.log('뭐지', this.carouselItems)
	},
	methods: {
		...mapMutations(['HOME_MU_INTERIOR_SELECT_PRODUCT', 'APP_MU_RECENT_PRODUCT']),
		...mapActions(['HOME_ACT_HOUSEWARMING_HOUSE_3D', 'HOME_ACT_HOUSEWARMING_SPECIAL']),

		onClickPushLink(item) {
			// google analytic
			console.log('ggggggggggggg', item)
			this.google_analytic_push_event('click_main_banner', { button_location: item.title })
			this.common_onLink(item.link_url)
		},
		onClick(val) {
			if (this.menuType === 'housewarming') {
				val.kind_name = '전문가 집들이'
				this.APP_MU_RECENT_PRODUCT(val)
			} else {
				val.kind_name = '3D 집들이'
				this.APP_MU_RECENT_PRODUCT(val)
			}
			this.$emit('submit', val)
		},
		next() {
			this.currentIndex += 1
		},
		prev() {
			this.currentIndex -= 1
		},

		async getData() {
			// 3D집들이 상세 정보
			for (let i = 0; i < this.carouselItems.length; i++) {
				if (this.carouselItems[i].scrap_code.includes('housewarming')) {
					await this.HOME_ACT_HOUSEWARMING_HOUSE_3D({ method: 'detail', query: this.carouselItems[i].hw_id })
						.then(res => {
							this.parseData(res)
						})
						.catch(() => {})
				}
				// } else {
				// 	await this.HOME_ACT_HOUSEWARMING_SPECIAL({ method: 'detail', query: this.carouselItems[i].hw_id })
				// 	.then(res => {
				// 		this.parseData(res)
				// 	})
				// 	.catch(() => {
				// 	})
				// }
			}
		},
		parseData(data) {
			let parser = JSON.parse(JSON.stringify(data[0]))

			console.log('parser', parser)

			this.house3dItem.push(parser)
		},
	},
}
</script>

<style scoped lang="scss">
@mixin ellipsis_2 {
	display: -webkit-box !important;
	word-wrap: break-word !important;
	-webkit-line-clamp: 2 !important;
	-webkit-box-orient: vertical !important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
}

.v-window.slide_type01 {
	border-radius: 10px;
	:deep(.v-image) {
		width: 100% !important;
		height: 100% !important;
		//max-height: 200px !important;
	}
	:deep(.v-image__image--contain) {
		background-size: cover !important;
		border-radius: 4px;
	}
	:deep(.v-item-group) {
		button::before {
			display: none;
		}
		.v-btn--icon {
			width: 10px;
			height: 10px;
		}
		.v-item--active.v-btn--active .v-icon.notranslate {
			background-color: #42883d;
		}
		.v-icon.notranslate {
			width: 10px;
			height: 10px;
			justify-content: space-between;
			background-color: #fff;
			opacity: 1;
			border-radius: 4px;
		}
		.v-icon.notranslate::before {
			display: none !important;
		}
	}

	.text_area {
		max-width: 100%;
		bottom: 25%;
		transform: translate(0, 50%);
		background-color: rgba(1, 1, 1, 0.5);

		:deep(.v-image) {
			height: 36px !important;
			margin-bottom: 5px;
		}
		:deep(.v-image__image--contain) {
			background-size: inherit !important;
		}
	}
}

.event_list {
	:deep(.v-image) {
		max-height: 366px;
	}
	.thumb_text_area {
		padding: 16px 0;
		.text_area_title {
			@include ellipsis_2;
			font-size: $font_lg;
			font-weight: $fw_normal;
			min-height: 48px;
		}
		.text_area_text01 {
			font-size: $font_lg;
			font-weight: $fw_normal;
			color: #9e9e9e;
		}
		.text_area_text02 {
			font-size: $font_normal;
			font-weight: $fw_normal;
			color: $color_gray_5;
			margin: 12px 0 0;
		}
		.text_area_text03 {
			font-size: $font_normal;
			font-weight: $fw_normal;
			color: $color_primary_1;
			margin: 12px 0 0;
		}
	}
}

.carousel-nav {
	z-index: 999;
	width: 78px;
	height: 26px;
	left: 15px;
	border: 1px solid #e6e6e6;
	border-radius: 220px;
	gap: 10px;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;
	.page_count {
		color: #777777;
		font-weight: $fw_normal;
		font-size: $font_sm;
		em {
			font-style: normal;
			font-weight: $fw_semibold;
		}
	}
}

.detail {
	height: 620px !important;
	border-radius: 0 !important;
	:deep(.v-image) {
		width: 100% !important;
		height: 100% !important;
	}
}
@media all and (min-width: 960px) and (max-width: 1263px) {
}
@media all and (min-width: 600px) and (max-width: 959px) {
	.v-window.slide_type01 {
		border-radius: 0 !important;
	}
}
@media all and (max-width: 600px) {
	.v-window.slide_type01 {
		border-radius: 0 !important;
	}
	.text_area {
		padding-bottom: 50px !important;
		max-width: 100% !important;
		position: absolute;
		bottom: 0 !important;
		right: 0 !important;
		transform: translate(0, 0) !important;
		&_title {
			font-size: 22px;
		}
		&_text {
			font-size: 14px;
		}
	}
}
</style>
