<template>
	<div class="pa-4 pb-5 inner">
		<v-row class="ma-0 mb-5 count">
			<v-col
				class="pa-0 tit"
				cols="6"
			>
				시공 인증 완료
			</v-col>
			<v-col
				class="pa-0 num"
				cols="6"
				align="end"
			>
				{{ item.count }}회
			</v-col>
		</v-row>

		<!-- 당첨 문구 -->
		<div
			v-if="checkValues.includes(item.count)"
			class="mb-5 msg"
		>
			<v-img
				width="40"
				eager
				:src="require('@/assets/img/careflo/icon_pang.png')"
			/>
			<br />
			<strong>100% 당첨 이벤트</strong>에 <br />
			<strong>당첨</strong>되셨습니다!
		</div>

		<!-- 자동응모 문구 -->
		<div
			v-else
			class="mb-5 msg"
		>
			100% 당첨 이벤트에<br />
			<strong>자동응모</strong> 되셨습니다.
		</div>

		<!-- 리워드안내 -->
		<div class="reward">
			<span class="item">리워드1</span>
			<p class="mb-4 txt">
				시공 최초 1회 인증 시 <br />
				3,000원 편의점 상품권 100% 지급
			</p>

			<span class="item">리워드2</span>
			<p class="txt">
				누적 5회/10회 인증 완료 시 <br />
				5,000 / 10,000 / 50,000원 상품권 중<br />
				100% 랜덤 지급
			</p>
		</div>

		<!-- 안내사항 -->
		<ul class="pa-0 mb-15 notice">
			<li>인증 시 기입한 휴대폰번호로 모바일 상품권 전송</li>
			<li>리워드 1의 경우 인증 완료 기준 5일 이내 지급</li>
			<li>리워드 2의 경우 5/10회 누적 인증 시 최대 1개월 이내 지급</li>
		</ul>

		<CommonButtonsButton01
			class="btn_confirm"
			name="확인"
			color="#00592D"
			height="42"
			@click="$emit('close')"
		/>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'CarefloEvent',
	props: ['type', 'item'],
	data: () => ({
		checkValues: [1, 5, 10],
	}),
	computed: {
		...mapGetters(['CAREFLO_GET_AUTH_USER']),
	},
	created() {},
	methods: {},
}
</script>

<style scoped lang="scss">
.count {
	padding: 18px 10px;
	border-radius: 8px;
	border: 1px solid $color_gray_2;
	background-color: $color_white;
	.tit {
		font-size: $font_normal;
		color: $color_gray_8;
	}
	.num {
		font-size: $font_normal;
		font-weight: $fw_bold;
		color: $color_font;
	}
}

.msg {
	.v-image {
		margin: 0 auto;
	}
	font-size: $font_lg;
	color: $color_font;
	text-align: center;
}

.reward {
	padding: 16px 20px;
	margin-bottom: 10px;
	border-radius: 8px;
	background-color: $color_white;
	.item {
		display: inline-block;
		padding: 4px 10px;
		margin-bottom: 10px;
		font-size: $font_normal;
		font-weight: $fw_bold;
		border-radius: 99px;
		color: $color_white;
		background-color: $color_font;
	}
	.txt {
		font-size: $font_normal;
		color: $color_gray_8;
	}
}

.notice {
	li {
		position: relative;
		padding-left: 10px;
		font-size: $font_sm;
		color: $color_gray_6;
		list-style: none !important;
		word-break: keep-all;
		&::before {
			display: block;
			position: absolute;
			top: 0px;
			left: 3px;
			content: '-';
		}
	}
}

.btn_confirm {
	width: 100%;
	:deep(.v-btn__content) {
		font-weight: $fw_bold !important;
		font-size: $font_lg !important;
	}
}
</style>
