<template>
	<div
		v-masonry="containerId"
		class="masonry_wrapper"
		transition-duration="0.2s"
		item-selector=".grid_item"
		:horizontal-order="true"
	>
		<v-col
			v-for="(item, i) in filterShowItems"
			:key="item.type + item.id"
			v-masonry-tile
			class="grid_item"
			cols="6"
			sm="4"
			lg="3"
		>
			<CommonCardMenuCardDefault
				new-tab-link
				:item="item"
			/>
		</v-col>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'HomeSearchMasonry',
	props: ['items'],
	data: () => ({
		containerId: 'card-masonry',
		showItemsCount: 20,
		increaseItemsCount: 12,
		scrollThrottle: null,
	}),
	computed: {
		...mapGetters([
			'APP_GET_SCROLL_BOTTOM', // 스크롤 EVENT,
		]),
		filterShowItems() {
			console.log(this.items?.slice(0, this.showItemsCount))
			return this.items?.slice(0, this.showItemsCount)
		},
	},
	watch: {
		filterShowItems() {
			setTimeout(() => {
				this.$redrawVueMasonry('card-masonry')
			}, 100)
		},
		APP_GET_SCROLL_BOTTOM() {
			if (!this.scrollThrottle) {
				if (this.showItemsCount < this.items?.length) this.showItemsCount += this.increaseItemsCount
				this.scrollThrottle = setTimeout(() => {
					this.scrollThrottle = null
					console.log('this.showItemsCount', this.showItemsCount)
				}, 500)
			}
		},
	},
	async created() {},
	mounted() {},
	destroyed() {},
	methods: {},
}
</script>

<style scoped lang="scss">
.masonry_wrapper {
	margin: 0 -16px !important;
}
@media all and (max-width: 600px) {
	.masonry_wrapper {
		margin: 0 !important;
	}
}
</style>
