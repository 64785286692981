<template>
	<div class="youtube_container">
		<iframe
			id="youtube_player"
			:src="`http://www.youtube.com/embed/${videoId}?autoplay=1&mute=1&enablejsapi=1&loop=1&playlist=${videoId}`"
			frameborder="0"
			allowfullscreen
			width="100%"
			height="100%"
			allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
		></iframe>
	</div>
</template>

<script>
export default {
	name: 'HomeCommunityItemsYoutubeIframe',
	props: {
		videoId: {
			type: String,
			default: '',
		},
	},
	data: () => ({
		player: null,
	}),
	mounted() {
		// this.setYoutubeApi()
		// this.initPlayer()
	},
	methods: {
		// setYoutubeApi() {
		// 	const script = document.createElement('script')
		// 	script.src = `https://www.youtube.com/iframe_api`
		// 	script.onload = this.initPlayer
		// 	document.head.appendChild(script)
		// },
		// initPlayer() {
		// 	console.log('init')
		// 	YT.ready(() => {
		// 		this.player = new YT.Player('youtube_player', {
		// 			events: {
		// 				onReady: this.onPlayerReady,
		// 			},
		// 		})
		// 		console.log('yt ready')
		// 		console.log(this.player)
		// 	})
		// },
		// onPlayerReady(event) {
		// 	console.log('onplayerready')
		// 	event.target.mute()
		// 	event.target.playVideo()
		// },
	},
}
</script>

<style lang="scss" scoped>
.youtube_container {
	aspect-ratio: 1.784;
	height: 100%;
	position: relative;
	&::after {
		content: '';
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		border: 3px solid #fff;
		outline: 1px solid #fff;
		top: 0;
		left: 0;
		z-index: 9;
		pointer-events: none;
	}
	&:hover {
		cursor: pointer;
	}
}
</style>
