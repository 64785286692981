<template>
	<v-dialog
		:value="dialog"
		persistent
		:width="width"
		height="100%"
		scrollable
		fullscreen
	>
		<v-card class="pa-0">
			<!-- Dialog header -->
			<v-row
				class="ma-0 py-2 px-4 dialog_dynamic_header"
				align="center"
			>
				<v-col
					class="pa-0 icn"
					cols="auto"
					align="start"
				>
					<v-img
						width="18"
						height="25"
						:src="require('@/assets/logo_tree.svg')"
					/>
				</v-col>
				<v-col
					class="pa-0 input"
					cols="auto"
				>
					<div class="px-2">
						<v-text-field
							ref="keyword"
							v-model="keyword"
							class="search_bar"
							outlined
							clearable
							prepend-inner-icon="mdi-magnify"
							append-icon="mdi-camera-outline"
							placeholder="깔끔한 인테리어"
							@click:append="onSearchPictureDialog"
							@keyup.enter="on_search_keyword(keyword)"
						></v-text-field>
					</div>
				</v-col>
				<v-col
					class="pa-0 icn"
					cols="auto"
					align="end"
				>
					<v-btn
						icon
						class="icn_close pa-0"
						@click="closeDialog"
					>
						<v-icon
							color="#666"
							medium
						>
							mdi-window-close
						</v-icon>
					</v-btn>
				</v-col>
			</v-row>

			<!-- 컴포넌트 -->
			<Component
				:is="componentInstance"
				ref="child"
				:keyword="keyword"
				@onSearch="on_search_keyword"
				@close="closeDialog"
			/>
		</v-card>
	</v-dialog>
</template>

<script>
import search from '@/mixins/search'

export default {
	name: 'CommonDialogDialogDynamicSearch',
	components: {
		componentAssociation: () => import('@/components/layout/appbar/association'),
		componentKeyword: () => import('@/components/layout/appbar/keyword'),
	},
	mixins: [search],
	props: {
		width: {
			type: Number,
			default: 400,
		},
		componentsPath: {
			type: String,
			default: '',
		},
		dialog: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {}
	},
	computed: {
		componentInstance() {
			return this.keyword?.length > 0 ? 'componentKeyword' : 'componentAssociation'
		},
	},
	created() {},
	mounted() {
		this.$refs.keyword.focus()
	},
	methods: {
		onSearchPictureDialog(e) {
			this.$emit('onSearchPictureDialog', e)
		},
		closeDialog() {
			this.$emit('close')
		},
	},
}
</script>

<style scoped lang="scss">
.dialog_dynamic_header {
	width: 100%;
	min-height: 55px !important;
	height: 55px !important;
	flex: initial !important;
	border-bottom: 1px solid #d8d8d8;
	.input {
		width: calc(100% - 42px);
	}
	.icn_close {
		&.v-btn--icon.v-size--default {
			width: 24px;
		}
		:deep(.v-btn__content) {
			flex: inherit !important;
		}
	}
	.search_bar.v-text-field {
		width: 100%;
		background-color: #f8f8f8;
		outline: transparent;
		border-radius: 30px !important;
		box-sizing: border-box !important;
		&.v-text-field--placeholder {
			font-size: $font_sm;
		}

		:deep(.v-input__slot) {
			margin-bottom: 0;
			min-height: 40px;
			padding: 0 8px !important;
		}
		:deep(.v-text-field__details) {
			display: none;
		}
		:deep(fieldset) {
			border-color: transparent;
		}
		:deep(.v-input__prepend-inner) {
			margin-top: 6px !important;
			.v-input__icon {
				height: 30px;
			}
			.v-icon {
				text-align: start;
				&.mdi-magnify {
					font-size: 24px;
					color: #78c046;
				}
			}
		}
		:deep(.v-input__append-inner) {
			margin-top: 6px !important;
			.v-input__icon {
				height: 30px;
			}
			.v-icon {
				&.mdi-close::before {
					font-size: 20px;
					color: #d8d8d8;
					content: '\F0159';
				}
				&.mdi-camera-outline {
					font-size: 24px;
					color: #78c046;
				}
			}
		}
	}
	:deep(.v-input--is-focused) {
		background-color: #fff !important;
		border: 1px solid #78c046 !important;
		margin: -1px !important;
		.v-icon {
			text-align: end;
			&.mdi-camera-outline,
			&.mdi-magnify {
				color: #78c046 !important;
			}
		}
	}
}
</style>
