<template>
	<v-bottom-sheet
		v-model="cameraLayer"
		content-class="camera-layer"
	>
		<v-sheet dark>
			<!--헤더 -->
			<div class="layer_header">
				<v-row class="ma-0 pa-4">
					<v-col
						class="pa-0"
						cols="6"
						align-self="center"
						align="start"
					>
						사진촬영
					</v-col>
					<v-col
						class="pa-0"
						cols="6"
						align-self="center"
						align="end"
					>
						<v-btn
							class="pa-0"
							color="#fff"
							text
							@click="$emit('close')"
						>
							<v-icon>clear</v-icon>
						</v-btn>
					</v-col>
				</v-row>
			</div>

			<!-- 카메라 인식영역 -->
			<div class="readerArea">
				<div id="reader">
					<video
						id="video"
						ref="video"
						autoplay
					></video>
				</div>
			</div>

			<!-- 캡쳐된화면 -->
			<div class="capture"></div>

			<!-- 인식상태 -->
			<div class="pt-6 state_viewer">
				<!-- 상태: 바코드인식중 -->
				<v-col
					v-if="!isManual"
					class="pa-0"
					align="center"
				>
					<div
						v-if="isLoading"
						class="mb-4 loading"
					>
						<v-img
							width="78"
							eager
							:src="require('@/assets/img/careflo/care_flo_loading.gif')"
						/>
					</div>
					<p v-show="!imageData">바코드를 인식해주세요!</p>
				</v-col>

				<!-- 상태: 촬영버튼 생성 -->
				<div v-else-if="isManual && !imageData">
					<v-col
						class="pa-0 btn_capture"
						@click="capture"
					>
						<v-col class="inner">
							<v-img
								width="52"
								eager
								:src="require('@/assets/img/careflo/btn_camera.svg')"
							/>
						</v-col>
					</v-col>
				</div>

				<!-- 상태: 인식결과 출력 및 재촬영버튼 -->
				<v-col class="pa-0">
					<p
						v-show="imageData && barcode"
						class="mb-7 tac"
					>
						일련번호 : {{ barcode }}
					</p>

					<v-row class="ma-0 px-4">
						<v-col
							class="pa-0"
							cols="6"
						>
							<div class="mr-1">
								<v-btn
									v-if="imageData"
									height="40"
									block
									outlined
									color="#9E9E9E"
									class="btn_recapture"
									@click="retry"
								>
									<v-icon
										class="mr-1"
										width="20"
										color="#fff"
									>
										mdi-camera-outline
									</v-icon>
									재촬영
								</v-btn>
							</div>
						</v-col>
						<v-col
							class="pa-0"
							cols="6"
						>
							<div class="ml-1">
								<!-- <v-btn
												v-if="imageData"
												height="40"
												block
												color="#fff"
												class="btn_save"
												@click="confirmSave = true"
											>
												저장
											</v-btn> -->
								<v-btn
									v-if="imageData"
									height="40"
									block
									color="#fff"
									class="btn_save"
									@click="confirmScan = true"
								>
									완료
								</v-btn>
							</div>
						</v-col>
					</v-row>
				</v-col>

				<!-- 상태: 자동인식 안될때.. -->
				<v-bottom-sheet
					v-model="cameraAlertLayer"
					:hide-overlay="true"
					content-class="camera-layer--alert"
					persistent
				>
					<v-sheet>
						<div class="py-6 px-4 tac">
							<p class="mb-5">
								자동인식이 안되시나요? <br />
								직접 촬영모드로 진행해보세요!
							</p>
							<v-btn
								height="40"
								block
								color="#78C046"
								class="btn_self_cam"
								@click="manualCapture"
							>
								<v-icon
									class="mr-1"
									color="#fff"
								>
									mdi-camera-outline
								</v-icon>
								직접촬영
							</v-btn>
						</div>
					</v-sheet>
				</v-bottom-sheet>
			</div>
			<CommonConfirm
				:dialog="confirmScan"
				:title="``"
				text="완료하시겠습니까?"
				mobile
				@close="confirmScan = false"
				@submit="readyToSave"
			/>
		</v-sheet>
	</v-bottom-sheet>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import { BrowserMultiFormatReader, NotFoundException } from '@zxing/library'

export default {
	props: ['cameraLayer'],
	data: () => ({
		cameraAlertLayer: false,
		codeReader: null,
		rearCamera: [], // 후면 카메라 리스트
		camera: undefined, // 실제 사용 카메라
		barcode: null,
		imageData: null,
		isLoading: true,
		isCapturing: false,
		isManual: false,
		timer: 0,
		timerId: null,
		confirmScan: false,
	}),
	computed: {
		...mapGetters(['CAREFLO_GET_BARCODE_ITEM']),
	},
	created() {},
	mounted() {
		this.codeReader = new BrowserMultiFormatReader()
		this.initCamera()
	},
	beforeDestroy() {
		if (this.codeReader) {
			this.codeReader.reset()
			this.codeReader = null
		}
		this.closeCamera()
	},
	methods: {
		...mapMutations(['CAREFLO_MU_BARCODE_ITEM']),
		initCamera() {
			this.codeReader
				.listVideoInputDevices()
				.then(videoInputDevices => {
					const videoDevices = videoInputDevices.filter(device => device.kind === 'videoinput')
					this.rearCamera = videoDevices.filter(device => {
						const label = device.label.toLowerCase()
						return label.includes('back') || label.includes('rear')
					})
					this.camera = // 후면 카메라가 없으면 기본(undifined), 둘 이상이면 두번째 카메라 우선 선택
						this.rearCamera.length === 0
							? undefined
							: this.rearCamera.length > 1
							? this.rearCamera[1]
							: this.rearCamera[0]

					this.startScanning()
				})
				.catch(err => {
					console.error(err)
				})
		},
		startScanning() {
			this.isLoading = true

			this.codeReader.decodeFromVideoDevice(
				this.camera?.deviceId ? this.camera.deviceId : undefined,
				'video',
				(result, err) => {
					// 스캔 성공하면 1.5초동안 스캔 중지
					if (!this.isCapturing) {
						if (result) {
							this.$refs.video.classList.add('scanned')
							if (this.barcode !== result.text) this.barcode = result.text
							this.isCapturing = true
							this.cameraAlertLayer = false
							setTimeout(() => {
								this.capture()
								this.isLoading = false
							}, 1500) // 스캔 성공시 1.5초 후 촬영
						}
						if (err) {
							if (err && !(err instanceof NotFoundException)) {
								console.error(err)
							}

							// 5초동안 인식 못하면 알람창 띄우기
							if (this.timer >= 5 && !this.isManual) {
								this.cameraAlertLayer = true
								this.timer = 0
							}
							// console.log(this.timer)
						}
					}
				},
			)

			const getTime = () => {
				this.timerId = setInterval(() => {
					this.timer++
				}, 1000)
			}
			getTime()
		},
		manualCapture() {
			this.cameraAlertLayer = false
			this.isManual = true
			clearInterval(this.timerId)
		},
		switchCamera() {
			if (this.rearCamera.length < 2) return // 후면 카메라가 둘 이상일 경우에만 카메라 전환
			const currentIndex = this.rearCamera.findIndex(device => device.deviceId === this.camera.deviceId)
			const nextIndex = (currentIndex + 1) % this.rearCamera.length
			this.camera = this.rearCamera[nextIndex]
			if (this.codeReader) this.codeReader.reset()
			this.startScanning()
		},
		capture() {
			const capture = document.querySelector('.capture')
			const canvas = document.createElement('canvas')
			const video = this.$refs.video

			canvas.width = video.videoWidth
			canvas.height = video.videoHeight
			canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height)
			capture.append(canvas)
			capture.style.display = 'block'
			document.querySelector('.readerArea').style.display = 'none'

			// 이미지 저장.
			this.imageData = canvas.toDataURL('image/png')
			console.log(this.imageData)

			this.$refs.video.classList.remove('scanned')
			this.isCapturing = false
			this.codeReader.stopContinuousDecode()
		},
		closeCamera() {
			const canvas = document.querySelector('.capture canvas')
			if (canvas) canvas.remove()
			if (this.codeReader) this.codeReader.reset()

			this.barcode = null
			this.imageData = null
			this.timer = 0
			clearInterval(this.timerId)

			document.querySelector('.readerArea').style.display = 'block'
			document.querySelector('.capture').style.display = 'none'
		},
		retry() {
			this.closeCamera()
			this.startScanning()
		},
		readyToSave() {
			this.CAREFLO_MU_BARCODE_ITEM({
				barcode: this.barcode ? this.barcode : '',
				care_type: this.care_type ? 'full' : 'one',
				barcode_file_base64: this.imageData ? this.imageData : '',
			})
			this.confirmScan = false
			this.isScanned = true
			this.$emit('close')
		},
	},
}
</script>

<style scoped lang="scss">
// 사진촬영 레이어
:deep(.camera-layer) {
	max-height: 100%;
	height: 100%;
	.v-sheet {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: stretch;
		overflow-x: hidden;
		overflow-y: auto;
		.layer_header {
			color: $color_white;
			font-size: $font_lg;
			font-weight: $fw_bold;
			.v-btn {
				min-width: 0 !important;
			}
		}
	}
}

.state_viewer {
	height: 124px;
	color: $color_white;
	font-size: $font_normal;
	.loading {
		display: flex;
		flex-direction: row;
		width: 100px;
		height: 34px;
		justify-content: center;
		align-items: center;
		padding: 2px 12px;
		background: $color_white;
		border-radius: 99px;
	}
	.btn_capture {
		position: relative;
		width: 64px;
		height: 64px;
		border-radius: 50%;
		background-color: $color_gray_1;
		margin: 0 auto;
		// .inner {
		// 	position: absolute;
		// 	width: 52px;
		// 	height: 52px;
		// 	top: 50%;
		// 	left: 50%;
		// 	border-radius: 50%;
		// 	-webkit-border-radius: 50% !important;
		// 	-moz-border-radius: 50% !important;
		// 	outline: 1px solid $color_gray_4;
		// 	transform: translate(-50%, -50%);
		// }
		.inner {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
	.btn_recapture {
		:deep(.v-btn__content) {
			color: $color_white;
			font-size: $font_normal;
		}
	}
	.btn_save {
		:deep(.v-btn__content) {
			color: $color_font;
			font-size: $font_normal;
			font-weight: $fw_bold;
		}
	}
}

:deep(.v-bottom-sheet.v-dialog) {
	&.camera-layer--alert {
		.theme--light.v-sheet {
			font-size: $font_lg;
			color: $color_gray_8;
			background-color: $color_white;
			border-radius: 16px 16px 0px 0px !important;
		}
		.btn_self_cam {
			box-shadow: none !important;
			.v-btn__content {
				color: $color_white;
				font-weight: $fw_bold;
				font-size: $font_lg;
			}
		}
	}
}

// 카메라 인식영역
.readerArea {
	flex: 0;
}
#reader,
.capture {
	position: relative;
	width: 100%;
	padding-top: calc(12 / 10 * 100%);
	aspect-ratio: 10/12;
	overflow: hidden;
}
#reader #video,
.capture :deep(canvas) {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	&.scanned {
		border: 6px solid #00ce7d;
	}
}
.capture {
	display: none;
}

.sava-confirm--dialog {
	background: #fff;
	padding: 30px;
	text-align: center;
	border-radius: 20px;
	.v-image {
		display: inline-block;
		margin-bottom: 10px;
	}
	strong {
		display: block;
		font-size: $font_xl;
		font-weight: 700;
	}
	p {
		margin-top: 20px;
		font-size: $font_lg;
	}
	:deep(button) {
		width: 100%;
		margin-top: 30px;
		background: #000;
		.v-btn__content {
			color: #fff;
			font-size: $font_xl;
		}
	}
}
</style>
