<template>
	<div
		class="cardtype content"
		@click="onClick"
	>
		<div
			class="thumbnail"
			:class="menuType"
		>
			<v-img
				:src="menu.image"
				eager
			>
				<v-row class="ma-0 pa-2 pa-sm-3 pa-md-4 top_element">
					<v-col
						class="pa-0"
						align="end"
						align-self="center"
						cols="6"
					>
						<!-- <span
							v-if="AUTH_GET_USER_AUTH !== 'AGENCY'"
							class="bookmark"
							@click="event => onScrap(item, event)"
						>
							<v-icon
								v-if="scrapStatus(item)"
								color="#00CE7D"
								>mdi-bookmark-outline</v-icon
							>
							<v-icon
								v-else
								color="#00CE7D"
								>mdi-bookmark</v-icon
							>
						</span> -->
					</v-col>
				</v-row>
			</v-img>
			<v-btn
				v-if="AUTH_GET_USER_AUTH !== 'AGENCY'"
				class="mark"
				@click.stop="onScrap(menu)"
			>
				<v-icon v-if="scrapStatus(menu)">mdi-bookmark-outline</v-icon>
				<v-icon v-else>mdi-bookmark</v-icon>
			</v-btn>
			<div class="dim">
				<v-img
					:src="require('@/assets/img/search/icon_thum_play.svg')"
					width="53"
					height="53"
					class="icon_play"
				/>
			</div>
		</div>
		<div class="py-4 px-2 py-md-6 px-md-4 card_txt">
			<!-- <span
				v-if="menu.features"
				class="d-flex flag category"
			>
				<v-img
					class="icon_marutv"
					max-width="14"
					height="100%"
					:src="require('@/assets/img/search/icon_play.svg')"
				/>
				<span>{{ menu.features }}</span>
			</span> -->
			<!-- <p
						class="menu_type"
					>
						{{ menu.features }}
					</p> -->
			<p
				v-if="menu.title"
				class="name"
			>
				{{ menu.title }}
			</p>
			<!-- <p
						v-if="menu.title"
						class="tit pb-3"
					>
						{{ menu.title }}
					</p> -->
			<p
				v-if="menu.text"
				class="mt-2 sub_txt"
			>
				{{ menu.text }}
			</p>
			<!-- <p
						v-if="menu.text"
						class="path_text"
					>
						{{ menu.text }}
					</p> -->
			<p
				v-if="menu.path"
				class="path_text"
			>
				{{ menu.path }}
			</p>

			<p
				v-if="menu.name"
				class="mt-1 store_name"
			>
				{{ menu.name }}
			</p>
			<!-- <p
						v-if="menu.name"
						class="menu_name pt-1"
					>
						{{ menu.name }}
					</p> -->
		</div>
	</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import common from '@/mixins/common'
import scrap from '@/mixins/scrap'

export default {
	name: 'CardMenuCard07',
	mixins: [common, scrap],
	props: ['menu', 'menuType'],
	data: () => ({}),
	computed: {
		...mapGetters(['AUTH_GET_USER_AUTH', 'APP_GET_RECENT_PRODUCT', 'APP_GET_SNACKBAR', 'USER_GET_SCRAP', 'AUTH_GET_USER']),
		homePath() {
			return this.common_recentlyRouterPath === '/home'
		},
		productSearchPath() {
			return this.common_recentlyRouterPath === '/product/search'
		},
		scrap_get_meta_new() {
			return this.$route.meta.scrapCode || null
		},
	},
	created() {},
	mounted() {},
	methods: {
		...mapMutations(['APP_MU_RECENT_PRODUCT', 'APP_MU_SNACKBAR']),
		...mapActions(['USER_ACT_SCRAP']),
		scrapStatus(menu) {
			let scrapCheck
			if (this.homePath) scrapCheck = this.scrap_check_list(menu.id, menu.scrap_code)
			else if (this.productSearchPath) scrapCheck = this.scrap_check_list(menu.id, menu.con_gubun)
			else scrapCheck = this.scrap_check_list(menu.id)
			return scrapCheck === 'post'
		},

		onClick() {
			this.APP_MU_RECENT_PRODUCT(this.menu)
			this.$emit('submit', this.menu)
		},

		async onScrap(menu) {
			// console.log('homePath', menu)
			// console.log('homePath', this.homePath)
			// console.log('homePath', this.common_recentlyRouterPath)
			// console.log('homePath', this.$route.meta)
			// if (this.homePath) this.scrap_call_new(menu.id, 'list', '')
			// else if (this.productSearchPath) this.scrap_call_new(menu.id, 'list', '')
			// else this.scrap_call_new(menu.id, 'list')
			// id check

			// 로그인 상태 체크
			if (this.AUTH_GET_USER.login_id === undefined) {
				return this.APP_MU_SNACKBAR({
					title: '로그인이 필요합니다.',
					action: {
						name: '로그인',
						link: '/login',
					},
				})
			}

			const code = this.scrap_get_meta_new
			const items = {
				method: 'post',
				params: {
					login_id: this.AUTH_GET_USER.login_id,
					scrap_kind: code,
					scrap_code: menu.id,
				},
			}

			console.log('params', items)

			await this.USER_ACT_SCRAP(items).then(res => {
				let check = 'post'
				let title = check === 'post' ? '스크랩이 등록되었습니다.' : '스크랩이 삭제되었습니다.'
				this.APP_MU_SNACKBAR({
					title: title,
					action: {
						name: '스크랩보기',
						link: '/user',
					},
				})
				//if (this.scrap_get_query !== null) this.scrap_detail_count_call()
			})
		},
	},
}
</script>

<style scoped lang="scss">
// 한줄이상 (...)처리
@mixin ellipsis_1 {
	display: -webkit-box !important;
	word-wrap: break-word !important;
	-webkit-line-clamp: 1 !important;
	-webkit-box-orient: vertical !important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
}

// 두줄이상 (...)처리
@mixin ellipsis_2 {
	display: -webkit-box !important;
	word-wrap: break-word !important;
	-webkit-line-clamp: 2 !important;
	-webkit-box-orient: vertical !important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
}
.cardtype {
	width: 100%;
	height: 100%;
	cursor: pointer;
	.thumbnail {
		position: relative !important;
		border-top-left-radius: 16px;
		border-top-right-radius: 16px;
		overflow: hidden;
		.mark {
			&.v-btn {
				height: 30px;
				width: 30px;
				min-width: 20px;
				padding: 7px !important;
				font-size: 20px;
				box-shadow: none !important;
				border-radius: 55% !important;
				background-color: #fff;
				position: absolute;
				right: 12px;
				top: 12px;
				.v-icon.v-icon {
					font-size: 20px;
					color: #00ce7d;
				}
			}
		}
		:deep(.v-image) {
			border-top-left-radius: 16px;
			border-top-right-radius: 16px;
			aspect-ratio: 16 / 10;
		}
		.dim {
			display: none;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: rgba(0, 0, 0, 0.3);
			.icon_play {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%) !important;
			}
		}
	}
	.thumbnail.special {
		:deep(.v-image) {
			aspect-ratio: 1/1;
		}
	}
	.top_element {
		.bookmark {
			display: inline-block;
			width: 30px !important;
			height: 30px !important;
			position: relative;
			border-radius: 50%;
			background-color: $color_white;
			opacity: 0.8;
			box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
			text-align: center;
			cursor: pointer;
			:deep(.v-icon) {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				font-size: 22px;
			}
		}
	}
	.card_txt {
		position: relative;
		background-color: $color_white;
		border-bottom-left-radius: 16px;
		border-bottom-right-radius: 16px;
		box-shadow: (0px 2px 8px rgba(0, 0, 0, 0.15));
		min-height: 130px;
		text-align: left;
		.name {
			@include ellipsis_2;
			font-size: $font_lg !important;
			font-weight: $fw_regular !important;
			color: $color_font;
			line-height: 1.4;
			height: 42px;
		}
		.sub_txt {
			@include ellipsis_1;
			font-size: $font_sm;
			color: #9e9e9e;
		}
		.store_name {
			@include ellipsis_1;
			font-size: $font_sm;
			color: $color_primary_2;
		}
	}
	.flag {
		padding: 6px 10px;
		border-radius: 99px;
		color: $color_white;
		line-height: 1 !important;
		&.best {
			padding: 2px 10px;
			font-size: $font_normal;
			background-color: $color_acc_1;
		}
		&.category {
			position: absolute;
			top: -12px;
			background-color: $color_font;
			font-size: $font_sm;
			align-items: center;
			.v-image {
				display: none;
				aspect-ratio: auto !important;
				:deep(.v-image__image) {
					max-width: 14px;
					height: 10px;
				}
				margin-right: 4px;
				border-radius: 0;
			}
		}
	}
	// 카드(제품)

	&.maru_tv {
		.top_element {
			display: none;
		}
		.thumbnail {
			.dim {
				display: block !important;
			}
		}
		.flag {
			&.category {
				.v-image {
					display: block !important;
				}
			}
		}
	}
}

@media all and (max-width: 600px) {
	.cardtype {
		.thumbnail {
			.dim {
				.icon_play {
					width: 26px !important;
					height: 26px !important;
				}
			}
		}
		.top_element {
			.bookmark {
				width: 20px !important;
				height: 20px !important;
				:deep(.v-icon) {
					font-size: 18px !important;
				}
			}
		}
		.card_txt {
			.name {
				font-size: $font_normal !important;
				height: 36px !important;
			}
		}
		.flag {
			font-size: $font_sm !important;
			padding: 4px 10px !important;
			&.best {
				padding: 2px 10px;
			}
		}
	}
}
</style>
