<template>
	<ul class="flag d-flex pa-0">
		<li
			v-if="items.popitem_yn === 'Y'"
			class="best"
		>
			BEST
		</li>
		<li
			v-if="items.new_yn === 'Y'"
			class="new"
		>
			신제품
		</li>
	</ul>
</template>

<script>
export default {
	name: 'Flag',
	components: {},
	props: ['items'],
	data: () => ({}),
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
}
</script>

<style scoped lang="scss">
// 기존
// .flag {
// 	li {
// 		width: 60px;
// 		padding: 5px 0;
// 		margin-right: 5px;
// 		text-align: center;
// 		background-color: $color_gray_1;
// 		border-radius: 18px;
// 		list-style: none;
// 		font-family: 'NotoSansKR-Regular';
// 		font-size: 13px;
// 		&.best {
// 			color: #262626;
// 		}
// 		&.new {
// 			color: #fa2a6f;
// 		}
// 	}
// }

//신규
.flag {
	li {
		width: 60px;
		padding: 5px 0;
		margin-right: 5px;
		text-align: center;
		//background-color: $color_gray_1;
		border: 1px solid #e6e6e6;
		border-radius: 18px;
		list-style: none;
		font-family: 'NotoSansKR-Regular';
		font-size: 13px;
		&.best {
			color: #262626;
		}
		&.new {
			color: #fa2a6f;
		}
	}
}
</style>
