<template>
	<v-dialog
		v-model="dialog"
		persistent
		width="320"
	>
		<v-card>
			<!-- 내용 -->
			<v-card-text class="pa-0">
				<v-row class="ma-0 text_wrapper">
					<v-col
						class="pa-0"
						align="center"
						align-self="center"
					>
						<p
							class="confirm_text"
							v-html="text"
						/>
					</v-col>
				</v-row>
			</v-card-text>

			<!-- 버튼 -->
			<v-card-actions class="pa-0">
				<v-row class="ma-0 mt-3 mt-sm-5">
					<v-col
						class="pa-0"
						cols="6"
					>
						<div class="mr-1">
							<v-btn
								width="100%"
								height="46"
								class="confirm_btn left"
								outlined
								color="#ccc"
								@click.stop="closePopup"
							>
								취소
							</v-btn>
						</div>
					</v-col>
					<v-col
						class="pa-0"
						cols="6"
					>
						<div class="ml-1">
							<v-btn
								width="100%"
								height="46"
								class="confirm_btn right"
								color="#00592D"
								@click.stop="confirmPopup"
							>
								<b>확인</b>
							</v-btn>
						</div>
					</v-col>
				</v-row>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: 'Confirm',
	props: {
		title: { type: String, default: '' },
		text: { type: String, default: '' },
		dialog: { type: Boolean, default: false },
		mobile: { type: Boolean, default: false },
	},
	data: () => ({}),
	computed: {},
	created() {},
	mounted() {},
	methods: {
		closePopup() {
			this.$emit('close', false)
		},
		confirmPopup() {
			this.$emit('submit', true)
		},
	},
}
</script>

<style scoped lang="scss">
:deep(.v-dialog) {
	padding: 30px 16px 16px;
	background-color: #fff;
	border: 1px solid $color_gray_8 !important;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
	border-radius: 8px;
	.v-card {
		border-radius: 0;
		box-shadow: none;

		// 컨텐츠
		.text_wrapper {
			min-height: 70px;
		}
		.confirm_text {
			font-size: $font_normal;
			color: $color_gray_8;
			word-break: keep-all;
			text-align: center;
		}

		// 버튼
		.confirm_btn {
			font-size: $font_lg;
			font-weight: normal !important;
			border-radius: 4px;
			box-shadow: none !important;

			&.left {
				.v-btn__content {
					color: $color_gray_7;
				}
			}
			&.right {
				.v-btn__content {
					color: #fff;
				}
			}
		}
	}
}
@media all and (max-width: 600px) {
	:deep(.v-dialog) {
		padding: 16px 10px 10px;
		.v-card {
			.confirm_btn {
				font-size: $font_normal !important;
				max-height: 36px !important;
			}
		}
	}
}
</style>
