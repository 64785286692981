// 만단위로 숫자 삭제
function ten_thousand(item) {
	const filter = item.split('')
	filter.splice(-4)
	return Number(filter.join('')).toLocaleString('ko-KR')
}

// 집들이 공통태그 취합
function public_code_mix(item) {
	let text = ''
	if (item.familytype_name?.length) text = text + item.familytype_name
	if (item.spacious_name?.length) text = text + ' | ' + item.spacious_name
	if (item.style_name?.length) text = text + ' | ' + item.style_name
	if (item.space_name?.length) text = text + ' | ' + item.space_name
	if (item.form_name?.length) text = text + ' | ' + item.form_name
	return text
}

const Calculater = {
	main: {
		banner: {
			parser(res) {
				let data = []
				res.forEach((x, i) => {
					if (x.mainimage_url !== null) {
						x.src = x.mainimage_url
						data.push(x)
					}
				})
				return data
			},
		},
		simpleEstimate: {
			parser(res) {
				let data = { ...res }

				// 만단위로 숫자 삭제
				data.est_cost_min = ten_thousand(res.est_cost_min)
				data.est_cost = ten_thousand(res.est_cost)
				data.est_cost_max = ten_thousand(res.est_cost_max)
				data.removal_cost = ten_thousand(res.removal_cost)

				return data
			},
		},
		housewarming: {
			house3d: {
				parser(res) {
					let data = [...res]
					data.forEach((x, i) => {
						x.id = x.hw_id
						x.image = x.image_url ? x.image_url : require('@/assets/img/etc/no_image.jpg')
						x.scrap_code = 'housewarming'
						x.tags = x.tags.map(x => x.tag_value)
					})
					return data
				},
			},
			special: {
				parser(res) {
					let data = [...res]
					data.forEach((x, i) => {						
						x.id = x.exphw_id
						x.image = x.image_url ? x.image_url : require('@/assets/img/etc/no_image.jpg')
						x.scrap_code = 'exphousewarming'
						x.tags = x.tags.map(x => x.tag_value)
					})
					return data
				},
			},
		},
		bestProduct: {
			parser(res) {
				let data = [...res]
				data.forEach((x, i) => {
					x.id = i
					x.image = x.rep_image_url ? x.rep_image_url : require('@/assets/img/etc/no_image.jpg')
				})
				return data
			},
		},
		searchTags: {
			parser(res) {
				let data = [...res]
				data.forEach((x, i) => {
					x.id = i
				})
				return data
			},
		},
		popularProduct: {
			parser(res) {
				let result = []
				let data = [...res]
				data.forEach((x, i) => {
					x.id = x.product_id
					x.image =
						x.rep_image_url == null || x.rep_image_url == undefined || x.rep_image_url == ''
							? require('@/assets/img/etc/no_image.jpg')
							: x.rep_image_url
					// flag
					x.flagItems = []
					// if( x.new_YN === 'Y' ) x.flagItems.push('신제품')
					if (x.popItem_YN === 'Y') x.flagItems.push('BEST')
					// title
					x.title = `${x.brand_name} ${x.goods_name} ${x.pattern_name}`
					x.tags = x.tags.map(x => x.tag_value)
					x.scrap_code = 'master'
				})

				// 품목별로 filter item
				const subject = data.map(x => x.subject_code)
				const set = new Set(subject)
				const setArr = [...set]
				// 바닥재 "SC_00001"
				result.push([
					...res
						.filter(item => item.subject_code === setArr[0])
						.sort((a, b) => (Number(a.hits) > Number(b.hits) ? -1 : 1)),
				])
				// 벽재 "SC_00003"
				result.push([
					...res
						.filter(item => item.subject_code === setArr[1])
						.sort((a, b) => (Number(a.hits) > Number(b.hits) ? -1 : 1)),
				])

				return result
			},
		},
		recommendProduct: {
			parser(res) {
				let data = [...res]
				data.forEach((x, i) => {
					x.id = x.product_id
					x.image =
						x.rep_image_url == null || x.rep_image_url == undefined || x.rep_image_url == ''
							? require('@/assets/img/etc/no_image.jpg')
							: x.rep_image_url
					x.name = `${x.brand_name} ${x.goods_name}`
				})
				return data.sort((a, b) => (a.recommend_priority < b.recommend_priority ? 1 : -1))
			},
		},
	},
	housewarming: {
		house3d: {
			parser(res) {
				let data = [...res]

				data.forEach((x, i) => {
					x.id = x.hw_id
					x.image = x.image_url ? x.image_url : require('@/assets/img/etc/no_image.jpg')
					x.tags = x.tags.map(x => x.tag_value)
					x.kind_name = '3D 집들이'
					x.subTitle = public_code_mix(x)
				})
				return data
			},
			pop(res) {
				let data = [...res]
				data.forEach((x, i) => {
					x.id = x.hw_id
					x.link = '3d'
					x.image = x.image_url ? x.image_url : require('@/assets/img/etc/no_image.jpg')
					x.features = '3D 집들이'
					// x.path = `${x.spacious_name} ${x.style_name.length ? '| ' + x.style_name : ''} ${
					// 	x.form_name.length ? '| ' + x.form_name : ''
					// }`
					x.path = x.path = x.cocat_subtitle;
				})
				return data
			},
		},
		special: {
			parser(res) {
				let data = [...res]
				data.forEach((x, i) => {
					x.id = x.exphw_id
					x.image = x.image_url ? x.image_url : require('@/assets/img/etc/no_image.jpg')
					x.tags = x.tags.map(x => x.tag_value)
					x.kind_name = '전문가 집들이'
					x.subTitle = public_code_mix(x)
				})
				return data
			},
			pop(res) {
				let data = [...res]
				data.forEach((x, i) => {
					x.id = x.exphw_id
					x.link = 'special'
					x.image = x.image_url ? x.image_url : require('@/assets/img/etc/no_image.jpg')
					x.features = '전문가 집들이'
					//x.path = `${x.addr_cut} ${x.spacious_name.length ? '| ' + x.spacious_name : ''}`
					x.path = x.cocat_subtitle
					x.name = x.business_name
				})
				return data
			},
		},
	},
	interior: {
		parser(res) {
			let data = [...res]
			data.forEach((x, i) => {
				x.id = i
				x.kakao_map = {
					lat: x.latitude,
					lng: x.longitude,
				}
				if (x.buildtype) {
					x.buildItems = x.buildtype.map(x => x.code_name)
					x.filter_items = x.buildtype.map(x => x.code)
				}
				x.is_img = x.company_picurl?.length
				x.company_picurl = x.company_picurl?.length
					? x.company_picurl
					: require('@/assets/img/home/interior/img_store_empty.png')
			})
			return data
		},
		simple_price_parser(res) {
			let data = { ...res }

			// 만단위로 숫자 삭제
			data.est_cost_min = ten_thousand(res.est_cost_min)
			data.est_cost = ten_thousand(res.est_cost)
			data.est_cost_max = ten_thousand(res.est_cost_max)
			data.removal_cost = ten_thousand(res.removal_cost)

			return data
		},
		showRoom: {
			list(res) {
				let data = [...res]
				data.forEach((x, i) => {
					x.id = i
					x.address = `${x.showroom_addr1} ${x.showroom_addr2}`
				})
				return data
			},
		},
	},
	product: {
		total_parser(res) {
			let data = [...res]
			data.forEach((x, i) => {
				x.id = x.con_id
				x.image =
					x.img_url == null || x.img_url == undefined || x.img_url == ''
						? require('@/assets/img/etc/no_image.jpg')
						: x.img_url
				// flag
				x.flagItems = []
				// if( x.new_YN === 'Y' ) x.flagItems.push('신제품')
				if (x.popItem_YN === 'Y') x.flagItems.push('BEST')
				// title
				x.kind_name = x.con_line1
				x.title = x.con_line2
				x.subTitle = x.con_line3
				x.tags = x.tags.map(x => x.tag_value)
			})

			return data
		},
		parser(res) {
			let data = [...res]
			data.forEach((x, i) => {
				// x.id = i+1
				x.id = x.product_id
				x.image =
					x.rep_image_url == null || x.rep_image_url == undefined || x.rep_image_url == ''
						? require('@/assets/img/etc/no_image.jpg')
						: x.rep_image_url
				// flag
				x.flagItems = []
				// if( x.new_YN === 'Y' ) x.flagItems.push('신제품')
				if (x.popItem_YN === 'Y') x.flagItems.push('BEST')
				// title
				x.title = `${x.brand_name} ${x.goods_name} ${x.pattern_name}`
				x.tags = x.tags.map(x => x.tag_value)
				// x.text  = `${x.subject_name} > ${x.kind_name} > ${x.brand_name}`

				// x.code_kind_name = kindItems.find(item => item.value === x.code_kind).text
			})
			const sortData = data.sort((a, b) => (a.release_date < b.release_date ? 1 : -1))
			console.log('sortData', sortData)

			return data
		},
		detail_parser(res) {
			let data = JSON.parse(JSON.stringify(res))

			// flagItems
			data.flagItems = []
			if (data.popitem_yn === 'Y') data.flagItems.push('인기제품')
			if (data.new_yn === 'Y') data.flagItems.push('신제품')
			// data.tags = data.tags.map(x => x.tag_value)

			return data
		},
		popular_parser(res) {
			let data = [...res]
			data.forEach((x, i) => {
				x.id = x.con_id
				x.image =
					x.img_url == null || x.img_url == undefined || x.img_url == ''
						? require('@/assets/img/etc/no_image.jpg')
						: x.img_url
				// flag
				x.flagItems = []
				// if( x.new_YN === 'Y' ) x.flagItems.push('신제품')
				if (x.popItem_YN === 'Y') x.flagItems.push('BEST')
				// title
				x.kind_name = x.con_line1
				x.title = x.con_line2
				x.subTitle = x.con_line3
				x.tags = x.tags.map(x => x.tag_value)
			})
			const sortData = data.sort((a, b) => (Number(a.hits) < Number(b.hits) ? 1 : -1))

			// // 종류별로 filter item
			// let result = []
			//
			// const subject  = sortData.map(x => x.con_gubun)
			// const set = new Set(subject)
			// const setArr = [...set]
			//
			// setArr.forEach(element =>{
			//     result.push([...res.filter(item => item.con_gubun === element)])
			// })

			return sortData
		},
	},
	community: {
		knowhow: {
			parser(res) {
				let data = [...res]
				data.forEach((x, i) => {
					x.id = x.knowhow_id
					x.image =
						x.image_url == null || x.image_url == undefined || x.image_url === ''
							? require('@/assets/img/etc/no_image.jpg')
							: x.image_url
					x.subTitle = x.code_name
					x.date = `${x.regi_date.split('-')[0]}년 ${x.regi_date.split('-')[1]}월 ${x.regi_date
						.split('-')[2]
						.substring(0, 2)}일`
					x.kind_name = '노하우'
				})
				const sortData = data.sort((a, b) => (a.regi_date < b.regi_date ? 1 : -1))
				return sortData
			},
			pop(res) {
				let data = [...res]
				data.forEach((x, i) => {
					x.id = x.knowhow_id
					x.image = x.image_url ? x.image_url : require('@/assets/img/etc/no_image.jpg')
					x.subTitle = x.name
					x.path = x.name
				})
				const sortData = data.sort((a, b) => (a.regi_date < b.regi_date ? 1 : -1))
				return sortData
			},
		},
		event: {
			parser(res) {
				let data = [...res]
				data.forEach((x, i) => {
					x.id = x.event_id
					x.state = x.current_status_name
					x.title = x.event_title
					x.image = x.image_url ? x.image_url : require('@/assets/img/etc/no_image.jpg')
					x.date = `기간: ${x.start_date} ~ ${x.end_date}`
					x.result = `당첨자발표: ${x.result_date}`
				})
				const sortData = data.sort((a, b) => (a.result_date < b.result_date ? 1 : -1))
				console.log('sortData', sortData)

				return sortData
			},
		},
	},
	custom: {
		notice: {
			parser(res) {
				let data = [...res]
				data.forEach((x, i) => {
					x.id = x.notice_id
				})

				// 중요
				const importantItems = data
					.filter(item => item.important_yn === 'Y')
					.sort((a, b) => (a.regi_date > b.regi_date ? -1 : 1))
				// 일반
				const nomalItems = data
					.filter(item => item.important_yn !== 'Y')
					.sort((a, b) => (a.regi_date > b.regi_date ? -1 : 1))

				return [...importantItems, ...nomalItems]
			},
		},
		interiorNotice: {
			parser(res) {
				let data = [...res]
				data.forEach((x, i) => {
					x.id = x.notice_id
				})

				return data.sort((a, b) => (a.regi_date > b.regi_date ? -1 : 1))
			},
		},
	},
	search: {
		universal: {
			parser(res) {
				let data = { ...res }
				data.result?.forEach((x, i) => {
					x.class =
						x.type === 'master'
							? 'product'
							: x.type === '3dhw'
							? 'housewarming'
							: x.type === 'exphw'
							? 'expert'
							: x.type === 'knowhow'
							? 'knowhow'
							: 'maru_tv'
					x.typeKr =
						x.type === 'master'
							? '제품'
							: x.type === '3dhw'
							? '3D집들이'
							: x.type === 'exphw'
							? '전문가집들이'
							: x.type === 'knowhow'
							? '노하우'
							: '마루잘.알.TV'
					x.scrap_code =
						x.type === 'master'
							? 'master'
							: x.type === '3dhw'
							? 'housewarming'
							: x.type === 'exphw'
							? 'exphousewarming'
							: 'knowhow'
					x.image = x.image_url
					x.video = x.video_url
				})
				data.result?.sort((a, b) => (a.score > b.score ? -1 : 1))

				data.recommand?.forEach((x, i) => {
					x.class =
						x.type === 'master'
							? 'product'
							: x.type === '3dhw'
							? 'housewarming'
							: x.type === 'exphw'
							? 'expert'
							: x.type === 'knowhow'
							? 'knowhow'
							: 'maru_tv'
					x.typeKr =
						x.type === 'master'
							? '제품'
							: x.type === '3dhw'
							? '3D집들이'
							: x.type === 'exphw'
							? '전문가집들이'
							: x.type === 'knowhow'
							? '노하우'
							: '마루잘.알.TV'
					x.image = x.image_url
				})
				data.recommand?.sort((a, b) => (a.hit > b.hit ? -1 : 1))
				return data
			},
		},
		keywordList: {
			parser(res) {
				let data = [...res]
				data.forEach(x => {
					x.keyword = x.keyword.replace(/\s+/g, ' ')
				})
				return data
			},
		},
		recommendList: {
			parser(res) {
				let data = { ...res }
				data.result.forEach(x => {
					x.typeKr = '제품'
					x.scrap_code =
						x.type === 'master'
							? 'master'
							: x.type === '3dhw'
							? 'housewarming'
							: x.type === 'exphw'
							? 'exphousewarming'
							: 'knowhow'
					x.image = x.image_url
					x.stars = calcFillingStar(x.score)
				})
				// if (data.colorInfo.objectColor) {
				// 	data.colorInfo.objectColorHex = rgbToHex(...data.colorInfo.objectColor)
				// 	data.colorInfo.objectColorHsl = rgbToHsl(...data.colorInfo.objectColor)
				// }
				// if (data.colorInfo.floorColor) {
				// 	data.colorInfo.floorColorHex = rgbToHex(...data.colorInfo.floorColor)
				// 	data.colorInfo.floorColorHsl = rgbToHsl(...data.colorInfo.floorColor)
				// }
				// if (data.colorInfo.wallColor) {
				// 	data.colorInfo.wallColorHex = rgbToHex(...data.colorInfo.wallColor)
				// 	data.colorInfo.wallColorHsl = rgbToHsl(...data.colorInfo.wallColor)
				// }

				function calcFillingStar(percentage) {
					// 마지막 별 하나만 percentage만큼 칠하기
					let stars = []
					let starNum = 4
					for (let i = 0; i < starNum; i++) {
						if (i < 2) stars[i] = 100
						else {
							if (percentage >= 50) {
								stars[i] = 100
								percentage -= 50
							} else {
								stars[i] = percentage * 2
								percentage = 0
							}
						}
					}
					return stars

					// percentage만큼 별 칠하기
					// let stars = []
					// let starNum = 4
					// let fillRatio = 100 / starNum
					// percentage = percentage > 100 ? 100 : percentage

					// for (let i = 0; i < starNum; i++) {
					// 	if (percentage >= fillRatio) {
					// 		percentage -= fillRatio
					// 		stars[i] = 100
					// 	} else if (percentage > 0) {
					// 		stars[i] = (percentage / fillRatio) * 100
					// 		percentage = 0
					// 	} else stars[i] = 0
					// }
					// return stars
				}

				// function rgbToHex(r, g, b) {
				// 	const toHex = c => {
				// 		const hex = c.toString(16)
				// 		return hex.length === 1 ? '0' + hex : hex
				// 	}

				// 	return `#${toHex(r)}${toHex(g)}${toHex(b)}`
				// }
				// function rgbToHsl(r, g, b) {
				// 	r /= 255
				// 	g /= 255
				// 	b /= 255

				// 	const max = Math.max(r, g, b)
				// 	const min = Math.min(r, g, b)
				// 	let h,
				// 		s,
				// 		l = (max + min) / 2

				// 	if (max === min) {
				// 		h = s = 0
				// 	} else {
				// 		const d = max - min
				// 		s = l > 0.5 ? d / (2 - max - min) : d / (max + min)

				// 		switch (max) {
				// 			case r:
				// 				h = (g - b) / d + (g < b ? 6 : 0)
				// 				break
				// 			case g:
				// 				h = (b - r) / d + 2
				// 				break
				// 			case b:
				// 				h = (r - g) / d + 4
				// 				break
				// 		}

				// 		h /= 6
				// 	}

				// 	return [Math.round(h * 360), Math.round(s * 100), Math.round(l * 100)]
				// }

				return res
			},
		},
	},
}

export default Calculater
