<template>
	<div>
		<!-- 썸네일 리스트 -->
		<div class="thumbnailList">
			<img
				v-for="(item, index) in items"
				:key="index"
				:src="item.src"
				alt="Thumbnail"
				:class="{ active: activeSlide === index }"
				@click="changeSlide(index)"
				@mouseenter="changeSlide(index)"
			/>
		</div>
		<v-carousel
			v-model="activeSlide"
			:show-arrows="false"
			delimiter-icon="mdi-color-helper"
			hide-delimiter-background
			class="slide_type01"
			:style="`height:${height} !important`"
			:cycle="false"
		>
			<v-carousel-item
				v-for="(item, i) in items"
				:key="i"
				:src="item.src"
			>
			</v-carousel-item>
		</v-carousel>
	</div>
</template>

<script>
import model from '@/mixins/model'

export default {
	name: 'Carousels03',
	mixins: [model],
	props: ['items', 'height'],
	data: () => ({
		activeSlide: 0,
	}),
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		changeSlide(index) {
			// 클릭한 썸네일의 인덱스를 변경하여 슬라이드를 변경
			this.activeSlide = index
		},
	},
}
</script>

<style scoped lang="scss">
.v-window.slide_type01 {
	border-radius: 4px;
	:deep(.v-item-group) {
		button::before {
			display: none;
		}
		.v-btn--icon {
			width: 10px;
			height: 10px;
		}
		.v-item--active.v-btn--active .v-icon.notranslate {
			background-color: #42883d;
		}
		.v-icon.notranslate {
			width: 10px;
			height: 10px;
			justify-content: space-between;
			background-color: #fff;
			opacity: 1;
			border-radius: 4px;
		}
		.v-icon.notranslate::before {
			display: none !important;
		}
	}
	:deep(.v-window__container) {
		height: 100%;
	}
	:deep(.v-carousel__controls__item) {
		visibility: hidden;
	}
}
.detail {
	height: 620px !important;
	border-radius: 0 !important;
	display: flex;
	:deep(.v-image) {
		width: 100% !important;
		height: 100% !important;
	}
}
.thumbnailList {
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: start;
}

.thumbnailList img {
	width: 62px;
	height: 62px;
	margin: 0 5px;
	cursor: pointer;
	border: 2px solid #fff;
	margin-bottom: 8px;
	border-radius: 5px;
	object-fit: cover;
}

.thumbnailList img.active {
	border-color: #00ce7d; /* 선택된 슬라이드를 강조하는 스타일 */
}
@media all and (min-width: 960px) and (max-width: 1263px) {
}
@media all and (min-width: 600px) and (max-width: 959px) {
	.v-window.slide_type01 {
		border-radius: 0 !important;
	}
}
@media all and (max-width: 600px) {
	.v-window.slide_type01 {
		border-radius: 0 !important;
		:deep(.v-carousel__controls__item) {
			visibility: visible;
		}
	}
	.thumbnailList {
		display: none;
	}
	.detail {
		height: 300px !important;
	}
}
</style>
