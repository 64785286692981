<template>
	<v-tabs
		v-model="model"
		background-color="$color_gray_10"
		active-class="tab_active"
		grow
		show-arrows
		:class="className"
	>
		<v-tab
			v-for="(item, i) in tabItems"
			:key="i"
			class="pa-0"
		>
			<v-row
				class="ma-0 item_text"
				align="center"
				justify="center"
			>
				<span class="pl-2">{{ item.name }}</span>
			</v-row>
		</v-tab>
	</v-tabs>
</template>

<script>
import model from '@/mixins/model'
export default {
	name: 'TypeTab01',
	components: {},
	mixins: [model],
	props: ['tabItems', 'className'],
	data: () => ({}),
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
}
</script>

<style scoped lang="scss">
.v-tabs {
	.v-tab {
		max-width: 176px !important;
		border-bottom: 1px solid $color_gray_3;
		.item_text {
			font-weight: $fw_normal;
			font-size: $font_lg;
			color: $color_gray_7;
			word-break: keep-all;
		}
	}
	:deep(.v-slide-group.v-item-group > .v-slide-group__next),
	:deep(.v-slide-group.v-item-group > .v-slide-group__prev) {
		display: none;
	}

	.tab_active.v-tab {
		.item_text {
			font-weight: $fw_bold;
			color: $color_primary_1 !important;
		}
	}
}
:deep(.v-tabs-slider-wrapper) {
	border: 1px solid $color_primary_1 !important;
}

.margin_0 {
	margin: 0 !important;
}

@media all and (min-width: 981px) and (max-width: 1280px) {
}
@media all and (min-width: 769px) and (max-width: 980px) {
}
@media all and (min-width: 380px) and (max-width: 768px) {
	.v-tabs {
		.v-tab {
			p {
				font-size: 13px;
			}
		}
	}
}
@media all and (max-width: 380px) {
	.v-tabs {
		.v-tab {
			p {
				font-size: 13px;
			}
		}
	}
}
</style>
