<template>
	<v-dialog
		v-model="model"
		class="pop_wrapper"
		width="100%"
		max-width="500px"
		persistent
		content-class="elevation-0"
	>
		<v-col
			class="pa-0 pop_header"
			align="end"
		>
			<v-btn
				icon
				class="close_button justify-content-end"
				color="white"
				@click="closeDialog"
				><v-icon size="35">mdi-window-close</v-icon></v-btn
			>
		</v-col>

		<v-col class="pa-4 pa-sm-5 pop_cont">
			<div class="pa-3 pa-sm-7 pt-5 bg">
				<p class="mb-8 tac tit">휴면 계정 전환 안내</p>
				<v-row class="ma-0 mb-7">
					<v-col
						class="pa-0 point"
						cols="8"
					>
						잠자고 있는 <br />
						회원님의 <br />
						아이디를 깨워주세요!
					</v-col>
					<v-col
						class="pa-0"
						cols="4"
						align="center"
						align-self="center"
					>
						<v-img
							:src="require('@/assets/img/login/sleep.svg')"
							max-width="68"
						/>
					</v-col>
				</v-row>

				<p class="mb-5 notice">
					회원님은 로그인 한지 1년 이상이 지나 아이디가 <br />
					휴면 상태로 전환되었습니다.<br />
					본인확인 후 다시 동화자연마루 사이트를 이용하실 수 있습니다.
				</p>

				<div class="pa-3 pa-sm-5 mb-7 sleep_date">
					<v-row class="ma-0 mb-2">
						<v-col
							class="pa-0 mr-5 fw_bold"
							cols="auto"
						>
							마지막 접속일
						</v-col>
						<v-col class="pa-0">{{ visitdate }}</v-col>
					</v-row>
					<v-row class="ma-0">
						<v-col
							class="pa-0 mr-8 fw_bold"
							cols="auto"
						>
							휴면 전환일
						</v-col>
						<v-col class="pa-0">{{ inactivedate }}</v-col>
					</v-row>
				</div>

				<v-row class="ma-0">
					<v-col
						class="pa-0"
						cols="6"
					>
						<div class="pr-1">
							<CommonButtonsButton01
								name="취소"
								color="#b4b4b4"
								height="46"
								class="btn_cancel"
								outlined
								@click="closeDialog"
							/>
						</div>
					</v-col>
					<v-col
						class="pa-0"
						cols="6"
					>
						<div class="pl-1">
							<CommonButtonsButton01
								name="계정 복구하기"
								color="#262626"
								height="46"
								class="btn_recover"
								@click="linkTo(memberkind)"
							/>
						</div>
					</v-col>
				</v-row>
			</div>
		</v-col>
	</v-dialog>
</template>

<script>
import model from '@/mixins/model'
import { mapGetters, mapMutations } from 'vuex'
export default {
	name: 'LoginSleeperAccount',
	mixins: [model],
	props: ['title', 'text', 'dialog', 'componentname', 'memberkind', 'inactivedate', 'visitdate', 'loginId'],
	methods: {
		closeDialog() {
			this.$emit('close')
		},
		linkTo(e) {
			//this.$emit('close')
			//console.log(e)
			this.$emit('close', e)
			this.$router.push(`/login/active_info/${e}?loginId=${this.loginId}`)
		},
	},
}
</script>

<style lang="scss" scoped>
:deep(.v-dialog) {
	border-radius: 0;
}
.pop_cont {
	background-color: $color_gray_1 !important;
	.bg {
		background-color: $color_white;
		border-radius: 16px;
	}
}

.tit {
	font-size: $font_xl;
	font-weight: $fw_bold;
	color: $color_black;
}
.point {
	font-size: $font_tit;
	font-weight: $fw_bold;
	color: #00ce7d;
	line-height: 1.3;
}
.notice {
	font-size: $font_normal;
	color: $color_gray_5;
}
.sleep_date {
	background-color: $color_gray_1;
	font-size: $font_normal;
	color: $color_gray_7;
}

.btn_cancel {
	:deep(.v-btn__content) {
		font-size: $font_lg;
		span {
			color: $color_gray_7 !important;
		}
	}
}
.btn_recover {
	:deep(.v-btn__content) {
		font-size: $font_lg;
		font-weight: $fw_bold;
	}
}

@media all and (max-width: 600px) {
	.point {
		font-size: $font_sub_tit !important;
	}
	.notice {
		font-size: $font_sm !important;
	}
	.sleep_date {
		font-size: $font_mid !important;
		color: $color_gray_7;
	}

	.btn_cancel {
		:deep(.v-btn__content) {
			font-size: $font_normal !important;
		}
	}
	.btn_recover {
		:deep(.v-btn__content) {
			font-size: $font_normal !important;
		}
	}
}
</style>
