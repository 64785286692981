<template>
	<v-container
		class="user_bar py-5 mb-10"
		fluid
	>
		<div class="inner_wrapper">
			<v-col
				cols="12"
				class="pa-0"
			>
				<div>
					<!-- <v-row class="ma-0 pa-3">
						<v-col
							cols="2"
							sm="1"
							align-self="start"
						>
							비회원 신청 정보
						</v-col>
					</v-row> -->
					<v-row
						class="ma-0 pa-3 user"
						align="center"
					>
						<v-col
							cols="12"
							class="pa-0 pb-2"
							align="start"
						>
							비회원 신청 정보
						</v-col>
						<v-col
							cols="2"
							sm="1"
							class="pa-0 tal"
							align-self="start"
						>
							<v-avatar
								color="#44883d"
								to="/user"
								class="avatar"
							>
								<v-icon color="white">person</v-icon>
							</v-avatar>
						</v-col>

						<v-col
							cols="10"
							sm="11"
							class="pa-0"
						>
							<v-row
								class="ma-0"
								align="center"
							>
								<v-col
									cols="12"
									sm="7"
									align-self="center"
									class="pa-0 tal"
								>
									<div class="pl-4 pl-md-0">
										<!-- 비회원 -->
										<p
											v-if="userdata.login_name"
											class="name"
										>
											{{ userdata.login_name }}<span>님</span>
										</p>
										<!-- 비회원 id -->
										<p
											v-if="userdata.login_id"
											class="email"
										>
											{{ userdata.login_id }}
										</p>
									</div>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</div>
			</v-col>
		</div>
	</v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'UserInfo02',
	components: {},
	props: ['userdata'],
	data: () => ({}),
	computed: {
		...mapGetters(['AUTH_GET_USER', 'USER_GET_SCRAP']),
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
}
</script>
<style scoped lang="scss">
// 회원정보영역
.user_bar {
	background-color: $color_gray_1;
	.user {
		padding: 16px 20px !important;
		border-radius: 4px;
		background-color: #fff;
		.avatar {
			min-width: 44px !important;
			min-height: 44px !important;
			width: 44px !important;
			height: 44px !important;
		}
		.name {
			font-family: 'NotoSansKR-Bold' !important;
			font-size: 18px;
			color: #262626;
			span {
				font-family: 'NotoSansKR-Regular' !important;
			}
		}
		.email {
			font-family: 'NotoSansKR-Regular' !important;
			font-size: 14px;
			color: $color_gray_9;
		}
		.align {
			text-align: right;
		}
		.btn_setting {
			font-family: 'NotoSansKR-Regular' !important;
			font-size: 14px;
		}
		.v-btn:not(.v-btn--round).v-size--default {
			min-height: 14px;
			height: 14px;
		}
	}
}

@media all and (min-width: 981px) and (max-width: 1280px) {
}
@media all and (min-width: 769px) and (max-width: 980px) {
}
@media all and (min-width: 600px) and (max-width: 768px) {
}
@media all and (min-width: 380px) and (max-width: 599px) {
	.user_bar {
		.user {
			padding: 10px 14px !important;
			border-radius: 4px;
			.name {
				font-size: 15px;
			}
			.email {
				font-size: 12px;
			}
			.align {
				text-align: left;
			}
			.btn_setting {
				font-size: 12px;
			}
		}
	}
}
@media all and (max-width: 380px) {
	.user_bar {
		.user {
			padding: 10px 14px !important;
			border-radius: 4px;
			.name {
				font-size: 15px;
			}
			.email {
				font-size: 12px;
			}
			.align {
				text-align: left;
			}
			.btn_setting {
				font-size: 12px;
			}
		}
	}
}
</style>
