<template>
	<div>
		<v-col class="section pa-4">
			<div class="head mb-5">
				<v-col class="pa-0 title">{{ item.title }}</v-col>
				<v-row class="ma-0 mb-2">
					<v-col
						class="pa-0 date"
						cols="6"
						align-self="center"
						align="start"
					>
						{{ item.receipt_date }}
					</v-col>
					<v-col
						class="pa-0 state"
						align-self="center"
						align="end"
					>
						{{ item.progress_state_name }}
					</v-col>
				</v-row>
			</div>

			<div class="body">
				<div class="cont">
					{{ item.contents }}
				</div>

				<div class="py-3 px-2 file">
					<v-row class="ma-0">
						<v-col
							class="pa-0 th"
							cols="3"
							sm="auto"
						>
							첨부파일
						</v-col>
						<v-col
							class="pa-0 td"
							cols="9"
						>
							<div class="ml-2">
								<a
									v-if="item.file_url"
									:href="item.file_url"
									target="_black"
								>
									파일 확인하기</a
								>
							</div>
						</v-col>
					</v-row>
				</div>
			</div>
		</v-col>

		<v-row
			v-if="item.progress_state_name === '접수중'"
			class="ma-0 py-5 px-4 btn_bar"
		>
			<v-col
				class="pa-0"
				cols="6"
			>
				<div class="mr-1">
					<v-btn
						class="pa-0 btn_cancel"
						block
						height="42"
						color="#ccc"
						outlined
						@click="confirm = true"
					>
						삭제하기
					</v-btn>
				</div>
			</v-col>
			<v-col
				class="pa-0"
				cols="6"
			>
				<div class="ml-1">
					<v-btn
						class="pa-0 btn_register"
						block
						height="42"
						color="#00592D"
						@click="$emit('modify', item)"
					>
						수정하기
					</v-btn>
				</div>
			</v-col>
		</v-row>
		<CommonConfirm
			:dialog="confirm"
			:title="'정보 확인'"
			:text="`삭제하시겠습니까?`"
			mobile
			@close="confirm = false"
			@submit="confirmSubmit"
		/>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import common from '@/mixins/common'

export default {
	name: 'List',
	mixins: [common],
	props: ['item'],
	data: () => ({
		confirm: false,
	}),
	computed: {
		...mapGetters(['CAREFLO_GET_AUTH_USER']),
	},
	created() {
		console.log('itemitemitem', this.item)
	},
	methods: {
		...mapActions(['CAREFLO_ACT_APPLY_AS']),
		confirmSubmit() {
			this.confirm = false
			const items = {
				method: 'delete',
				params: {
					as_count: this.item.as_count,
					nfc_code: this.CAREFLO_GET_AUTH_USER.nfc_code,
				},
			}
			this.CAREFLO_ACT_APPLY_AS(items)
				.then(async res => {
					this.loading = false
					if (res) {
						this.$emit('close')
					}
				})
				.catch(err => {
					console.error('CAREFLO_ACT_APPLY_AS', err)
					this.loading = false
				})
		},
	},
}
</script>

<style scoped lang="scss">
// 두줄이상 (...)처리
@mixin ellipsis_2 {
	display: -webkit-box !important;
	word-wrap: break-word !important;
	-webkit-line-clamp: 2 !important;
	-webkit-box-orient: vertical !important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
}

.section {
	background-color: $color_white;
	.head {
		border-bottom: 1px solid $color_gray_3;
		.flag {
			font-size: $font_sm;
			padding: 2px 8px;
			color: $color_gray_8;
			border: 1px solid $color_gray_2;
			border-radius: 99px;
		}
		.date {
			font-size: $font_sm;
			color: $color_gray_5;
		}
		.title {
			@include ellipsis_2;
			color: $color_font;
			font-size: $font_lg;
			font-weight: $fw_bold !important;
		}
		.state {
			font-size: $font_sm;
			color: $color_gray_8;
		}
	}
	.body {
		.cont {
			padding-right: 20px;
			word-break: keep-all;
			font-size: $font_normal;
			color: $color_gray_8;
			min-height: 180px;
		}

		.file {
			background-color: $color_gray_1;
			border-radius: 4px;
			.th {
				font-size: $font_normal;
				color: $color_gray_8;
			}
			.td {
				span {
					font-size: $font_sm;
					border-bottom: 1px solid $color_primary_3;
					color: $color_primary_3;
				}
			}
		}
	}
}

.btn_bar {
	width: 100%;
	position: fixed;
	left: 0;
	bottom: 0;
}
.btn_cancel {
	box-shadow: none !important;
	background-color: #fff;
	:deep(.v-btn__content) {
		font-size: $font_normal;
		color: $color_gray_6;
	}
}
.btn_register {
	box-shadow: none !important;
	:deep(.v-btn__content) {
		font-size: $font_normal;
		font-weight: $fw_bold;
		color: #fff;
	}
}
</style>
