<template>
	<svg
		width="36"
		height="36"
		viewBox="0 0 36 36"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		class="icn"
	>
		<path
			d="M27.54 13.5L28.44 18H7.56L8.46 13.5H27.54ZM30 6H6V9H30V6ZM30 10.5H6L4.5 18V21H6V30H21V21H27V30H30V21H31.5V18L30 10.5ZM9 27V21H18V27H9Z"
			:fill="color"
		/>
	</svg>
</template>

<script>
export default {
	name: 'JoinInteriorIcon',
	props: ['color'],
}
</script>

<style lang="scss" scoped>
.icn {
	margin-top: 4px;
}
@media all and (max-width: 600px) {
	.icn {
		margin-top: 4px;
		width: 30px !important;
		height: 30px !important;
	}
}
</style>
