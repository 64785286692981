<template>
	<div class="photo_analysis">
		<v-row class="ma-0 mb-3 mb-md-5 head">
			<v-col
				class="pa-0 subject"
				cols="6"
				align-self="center"
			>
				<div class="d-flex">
					<span>사진 분석 결과</span>
					<span class="desc hidden-sm-and-down">
						<span class="eff"></span>
						<v-icon
							color="#006FF1"
							@click="showTooltip = !showTooltip"
							>mdi-information-outline</v-icon
						>

						<div
							v-show="showTooltip"
							class="txt"
						>
							<p>* 카메라 촬영 환경으로 분석 결과 값이 실제와 다를 수 있습니다.</p>
							<p>* 비율에 따라 사진이 일부 잘려보일 수 있습니다.</p>
							<div
								class="btn_close tar"
								@click="showTooltip = !showTooltip"
							>
								<v-icon
									small
									color="white"
									>mdi-window-close</v-icon
								>
							</div>
						</div>
					</span>
				</div>
			</v-col>

			<v-col
				class="pa-0 hidden-md-and-up"
				cols="6"
				align-self="center"
				align="end"
			>
				<div
					class="btn_pic_chage"
					@click="triggerFileInput"
				>
					<span class="d-flex justify-center">
						<v-img
							width="100%"
							max-width="16"
							height="17"
							:src="require('@/assets/img/search/icon_pic_change2.svg')"
						/>
						<span class="pl-1">사진변경</span>
					</span>

					<input
						ref="fileInput"
						type="file"
						accept="image/*"
						capture="camera"
						hidden
						@change="handleFileChange"
					/>
				</div>
			</v-col>
		</v-row>

		<v-row class="ma-0">
			<v-col
				class="pa-0"
				cols="12"
				md="7"
			>
				<div class="pa-4 mr-0 mr-md-2 left">
					<v-row
						class="ma-0"
						style="align-item: center"
					>
						<v-col
							class="pa-0 mb-4 mb-md-0"
							cols="12"
							md="5"
							align="center"
							align-self="center"
						>
							<div class="mb-2 pic_change">
								<v-img
									width="100%"
									height="100%"
									:src="uploadedImage"
								/>
							</div>

							<div
								class="d-flex justify-center btn_pic_chage"
								@click="triggerFileInput"
							>
								<v-img
									width="100%"
									max-width="18"
									height="19"
									:src="require('@/assets/img/search/icon_pic_change.svg')"
								/>
								<span class="pl-1">사진변경</span>
								<input
									ref="fileInput"
									type="file"
									accept=".jpeg, .jpg, .png"
									hidden
									@change="handleFileChange"
								/>
							</div>
						</v-col>

						<v-col
							class="pa-0"
							cols="12"
							md="7"
						>
							<div class="ml-0 ml-md-4">
								<div class="mb-4 tac">
									<span class="tone">메인 인테리어 톤</span>
								</div>
								<v-col
									class="pa-0 mb-3 layout_align"
									cols="12"
								>
									<v-row class="ma-0 justify-center">
										<v-col
											class="pa-0"
											cols="auto"
											md="4"
											align-self="center"
										>
											<div class="drop mr-2">
												<svg
													width="34"
													height="40"
													viewBox="0 0 34 40"
													fill="none"
												>
													<path
														d="M5.05143 34.5644C8.29796 37.7155 12.2303 39.291 16.8483 39.291C21.4664 39.291 25.3987 37.7155 28.6452 34.5644C31.8917 31.4133 33.515 27.6065 33.515 23.1439C33.515 20.8955 33.0809 18.8175 32.2129 16.9098C31.3448 15.0021 30.1469 13.2989 28.6191 11.8L16.8483 0.456055L5.07747 11.8C3.5497 13.2989 2.35178 15.0021 1.48372 16.9098C0.615669 18.8175 0.181641 20.8955 0.181641 23.1439C0.181641 27.6065 1.8049 31.4133 5.05143 34.5644Z"
														:fill="items?.colorInfo?.floorColorHex"
													/>
													<path
														d="M16.8483 38.791C12.3573 38.791 8.55076 37.2641 5.39967 34.2056C2.24864 31.1472 0.681641 27.4692 0.681641 23.1439C0.681641 20.9617 1.10247 18.9549 1.93882 17.1169C2.78056 15.2671 3.94196 13.6148 5.42602 12.1585C5.42656 12.1579 5.4271 12.1574 5.42764 12.1569L16.8483 1.15046L28.269 12.1569C28.2695 12.1574 28.2701 12.158 28.2706 12.1585C29.7547 13.6149 30.9161 15.2671 31.7578 17.1169C32.5941 18.9549 33.015 20.9617 33.015 23.1439C33.015 27.4692 31.448 31.1472 28.2969 34.2056C25.1459 37.2641 21.3393 38.791 16.8483 38.791Z"
														stroke="black"
														stroke-opacity="0.1"
													/>
												</svg>
												<div class="mt-1 tac">
													<span class="color_code">{{ items?.colorInfo?.floorColorCategory }}</span>
												</div>
											</div>
										</v-col>
										<v-col
											class="pa-0 colorBoxes"
											cols="8"
											md="8"
											align-self="center"
										>
											<!-- <div
												id="colorBox1"
												class="colorBox"
											/> -->
										</v-col>
									</v-row>
								</v-col>

								<v-col
									class="pa-0 mb-5 layout_align"
									cols="12"
								>
									<v-row class="ma-0 justify-center">
										<v-col
											class="pa-0"
											cols="auto"
											md="4"
											align-self="center"
										>
											<div class="drop mr-2">
												<svg
													width="34"
													height="40"
													viewBox="0 0 34 40"
													fill="none"
												>
													<path
														d="M5.05143 34.5644C8.29796 37.7155 12.2303 39.291 16.8483 39.291C21.4664 39.291 25.3987 37.7155 28.6452 34.5644C31.8917 31.4133 33.515 27.6065 33.515 23.1439C33.515 20.8955 33.0809 18.8175 32.2129 16.9098C31.3448 15.0021 30.1469 13.2989 28.6191 11.8L16.8483 0.456055L5.07747 11.8C3.5497 13.2989 2.35178 15.0021 1.48372 16.9098C0.615669 18.8175 0.181641 20.8955 0.181641 23.1439C0.181641 27.6065 1.8049 31.4133 5.05143 34.5644Z"
														:fill="items?.colorInfo?.wallColorHex"
													/>
													<path
														d="M16.8483 38.791C12.3573 38.791 8.55076 37.2641 5.39967 34.2056C2.24864 31.1472 0.681641 27.4692 0.681641 23.1439C0.681641 20.9617 1.10247 18.9549 1.93882 17.1169C2.78056 15.2671 3.94196 13.6148 5.42602 12.1585C5.42656 12.1579 5.4271 12.1574 5.42764 12.1569L16.8483 1.15046L28.269 12.1569C28.2695 12.1574 28.2701 12.158 28.2706 12.1585C29.7547 13.6149 30.9161 15.2671 31.7578 17.1169C32.5941 18.9549 33.015 20.9617 33.015 23.1439C33.015 27.4692 31.448 31.1472 28.2969 34.2056C25.1459 37.2641 21.3393 38.791 16.8483 38.791Z"
														stroke="black"
														stroke-opacity="0.1"
													/>
												</svg>
												<div class="mt-1 tac">
													<span class="color_code">{{ items?.colorInfo?.wallColorCategory }}</span>
												</div>
											</div>
										</v-col>
										<v-col
											class="pa-0 colorBoxes"
											cols="8"
											md="8"
											align-self="center"
										>
											<!-- <div
												id="colorBox2"
												class="colorBox"
											/> -->
										</v-col>
									</v-row>
								</v-col>

								<v-row class="ma-0 furniture">
									<v-col
										class="pa-0"
										cols="3"
										lg="4"
										align="center"
										align-self="center"
									>
										<span class="tit">가구</span>
									</v-col>
									<v-col
										class="pa-0"
										cols="9"
										lg="8"
									>
										<div class="item_group">
											<div
												v-for="(item, i) in furniture"
												:key="i"
												:class="[
													items.objectList?.some(x => x.objectName === item.titleEng) ? 'active' : '',
												]"
												class="px-4 item"
											>
												<svg
													width="34"
													height="34"
													viewBox="0 0 34 34"
													fill="#fff"
												>
													<rect
														x="0.5"
														y="0.5"
														width="33"
														height="33"
														:rx="item.title === '의자' ? 17 : 16.5"
														fill="#fff"
													/>
													<path
														v-if="item.title === '소파'"
														d="M10 26C9.71667 26 9.47917 25.9042 9.2875 25.7125C9.09583 25.5208 9 25.2833 9 25V24C8.16667 24 7.45833 23.7083 6.875 23.125C6.29167 22.5417 6 21.8333 6 21V16C6 15.1667 6.29167 14.4583 6.875 13.875C7.45833 13.2917 8.16667 13 9 13V11C9 10.1667 9.29167 9.45833 9.875 8.875C10.4583 8.29167 11.1667 8 12 8H22C22.8333 8 23.5417 8.29167 24.125 8.875C24.7083 9.45833 25 10.1667 25 11V13C25.8333 13 26.5417 13.2917 27.125 13.875C27.7083 14.4583 28 15.1667 28 16V21C28 21.8333 27.7083 22.5417 27.125 23.125C26.5417 23.7083 25.8333 24 25 24V25C25 25.2833 24.9042 25.5208 24.7125 25.7125C24.5208 25.9042 24.2833 26 24 26C23.7167 26 23.4792 25.9042 23.2875 25.7125C23.0958 25.5208 23 25.2833 23 25V24H11V25C11 25.2833 10.9042 25.5208 10.7125 25.7125C10.5208 25.9042 10.2833 26 10 26ZM9 22H25C25.2833 22 25.5208 21.9042 25.7125 21.7125C25.9042 21.5208 26 21.2833 26 21V16C26 15.7167 25.9042 15.4792 25.7125 15.2875C25.5208 15.0958 25.2833 15 25 15C24.7167 15 24.4792 15.0958 24.2875 15.2875C24.0958 15.4792 24 15.7167 24 16V20H10V16C10 15.7167 9.90417 15.4792 9.7125 15.2875C9.52083 15.0958 9.28333 15 9 15C8.71667 15 8.47917 15.0958 8.2875 15.2875C8.09583 15.4792 8 15.7167 8 16V21C8 21.2833 8.09583 21.5208 8.2875 21.7125C8.47917 21.9042 8.71667 22 9 22ZM12 18H22V16C22 15.55 22.0917 15.1417 22.275 14.775C22.4583 14.4083 22.7 14.0833 23 13.8V11C23 10.7167 22.9042 10.4792 22.7125 10.2875C22.5208 10.0958 22.2833 10 22 10H12C11.7167 10 11.4792 10.0958 11.2875 10.2875C11.0958 10.4792 11 10.7167 11 11V13.8C11.3 14.0833 11.5417 14.4083 11.725 14.775C11.9083 15.1417 12 15.55 12 16V18Z"
														fill="#D9D9D9"
													/>
													<path
														v-if="item.title === '의자'"
														d="M10 26V19C10 18.45 10.1958 17.9792 10.5875 17.5875C10.9792 17.1958 11.45 17 12 17H13V15H12C11.45 15 10.9792 14.8042 10.5875 14.4125C10.1958 14.0208 10 13.55 10 13V10C10 9.45 10.1958 8.97917 10.5875 8.5875C10.9792 8.19583 11.45 8 12 8H22C22.55 8 23.0208 8.19583 23.4125 8.5875C23.8042 8.97917 24 9.45 24 10V13C24 13.55 23.8042 14.0208 23.4125 14.4125C23.0208 14.8042 22.55 15 22 15H21V17H22C22.55 17 23.0208 17.1958 23.4125 17.5875C23.8042 17.9792 24 18.45 24 19V26H22V23H12V26H10ZM12 13H22V10H12V13ZM15 17H19V15H15V17ZM12 21H22V19H12V21Z"
														fill="#D9D9D9"
													/>
													<path
														v-if="item.title === '테이블'"
														d="M9.3246 14H24.6746L23.8246 11H10.1996L9.3246 14ZM21.7996 16H12.2246L11.9496 18H22.0496L21.7996 16ZM8.9996 25L10.2246 16H7.9996C7.66626 16 7.40376 15.8667 7.2121 15.6C7.02043 15.3333 6.96626 15.0417 7.0496 14.725L8.4746 9.725C8.54126 9.50833 8.65793 9.33333 8.8246 9.2C8.99126 9.06667 9.19126 9 9.4246 9H24.5746C24.8079 9 25.0079 9.06667 25.1746 9.2C25.3413 9.33333 25.4579 9.50833 25.5246 9.725L26.9496 14.725C27.0329 15.0417 26.9788 15.3333 26.7871 15.6C26.5954 15.8667 26.3329 16 25.9996 16H23.7996L24.9996 25H22.9996L22.3246 20H11.6746L10.9996 25H8.9996Z"
														fill="#D9D9D9"
													/>
												</svg>
												<p>{{ item.title }}</p>
											</div>
										</div>
									</v-col>
								</v-row>
							</div>
						</v-col>
					</v-row>
				</div>
			</v-col>

			<v-col
				class="pa-0 hidden-sm-and-down"
				md="5"
			>
				<div class="ml-0 ml-md-2 right">
					<div class="py-4 px-2 mb-4 analysis">
						<p class="mb-4 tac txt">올려주신 이미지는 이런 색상이 많이 사용되었어요!</p>
						<v-col
							class="pa-0 layout_align"
							cols="8"
						>
							<v-row class="ma-0">
								<v-col
									class="pa-0"
									cols="6"
									align="center"
								>
									<div class="drop">
										<svg
											width="34"
											height="40"
											viewBox="0 0 34 40"
											fill="none"
										>
											<path
												d="M5.05143 34.5644C8.29796 37.7155 12.2303 39.291 16.8483 39.291C21.4664 39.291 25.3987 37.7155 28.6452 34.5644C31.8917 31.4133 33.515 27.6065 33.515 23.1439C33.515 20.8955 33.0809 18.8175 32.2129 16.9098C31.3448 15.0021 30.1469 13.2989 28.6191 11.8L16.8483 0.456055L5.07747 11.8C3.5497 13.2989 2.35178 15.0021 1.48372 16.9098C0.615669 18.8175 0.181641 20.8955 0.181641 23.1439C0.181641 27.6065 1.8049 31.4133 5.05143 34.5644Z"
												:fill="items?.colorInfo?.floorColorHex"
											/>
											<path
												d="M16.8483 38.791C12.3573 38.791 8.55076 37.2641 5.39967 34.2056C2.24864 31.1472 0.681641 27.4692 0.681641 23.1439C0.681641 20.9617 1.10247 18.9549 1.93882 17.1169C2.78056 15.2671 3.94196 13.6148 5.42602 12.1585C5.42656 12.1579 5.4271 12.1574 5.42764 12.1569L16.8483 1.15046L28.269 12.1569C28.2695 12.1574 28.2701 12.158 28.2706 12.1585C29.7547 13.6149 30.9161 15.2671 31.7578 17.1169C32.5941 18.9549 33.015 20.9617 33.015 23.1439C33.015 27.4692 31.448 31.1472 28.2969 34.2056C25.1459 37.2641 21.3393 38.791 16.8483 38.791Z"
												stroke="black"
												stroke-opacity="0.1"
											/>
										</svg>
										<div class="mt-1 tac">
											<span class="color_code">{{ items?.colorInfo?.floorColorCategory }}</span>
										</div>
									</div>
								</v-col>
								<v-col
									class="pa-0"
									cols="6"
									align="center"
								>
									<div class="drop">
										<svg
											width="34"
											height="40"
											viewBox="0 0 34 40"
											fill="none"
										>
											<path
												d="M5.05143 34.5644C8.29796 37.7155 12.2303 39.291 16.8483 39.291C21.4664 39.291 25.3987 37.7155 28.6452 34.5644C31.8917 31.4133 33.515 27.6065 33.515 23.1439C33.515 20.8955 33.0809 18.8175 32.2129 16.9098C31.3448 15.0021 30.1469 13.2989 28.6191 11.8L16.8483 0.456055L5.07747 11.8C3.5497 13.2989 2.35178 15.0021 1.48372 16.9098C0.615669 18.8175 0.181641 20.8955 0.181641 23.1439C0.181641 27.6065 1.8049 31.4133 5.05143 34.5644Z"
												:fill="items?.colorInfo?.wallColorHex"
											/>
											<path
												d="M16.8483 38.791C12.3573 38.791 8.55076 37.2641 5.39967 34.2056C2.24864 31.1472 0.681641 27.4692 0.681641 23.1439C0.681641 20.9617 1.10247 18.9549 1.93882 17.1169C2.78056 15.2671 3.94196 13.6148 5.42602 12.1585C5.42656 12.1579 5.4271 12.1574 5.42764 12.1569L16.8483 1.15046L28.269 12.1569C28.2695 12.1574 28.2701 12.158 28.2706 12.1585C29.7547 13.6149 30.9161 15.2671 31.7578 17.1169C32.5941 18.9549 33.015 20.9617 33.015 23.1439C33.015 27.4692 31.448 31.1472 28.2969 34.2056C25.1459 37.2641 21.3393 38.791 16.8483 38.791Z"
												stroke="black"
												stroke-opacity="0.1"
											/>
										</svg>
										<div class="mt-1 tac">
											<span class="color_code">{{ items?.colorInfo?.wallColorCategory }}</span>
										</div>
									</div>
								</v-col>
							</v-row>
						</v-col>
					</div>

					<div class="py-4 px-0 mb-0 mb-md-4 tac analysis">
						<div
							v-if="items.objectList"
							class="result_txt"
						>
							<span
								v-for="(item, i) in items.objectList"
								:key="i"
								class="tac mx-3 color_circle"
							>
								<span class="circle">
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
									>
										<path
											d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z"
											:fill="item.objectColor"
										/>
										<path
											d="M23.5 12C23.5 18.3513 18.3513 23.5 12 23.5C5.64873 23.5 0.5 18.3513 0.5 12C0.5 5.64873 5.64873 0.5 12 0.5C18.3513 0.5 23.5 5.64873 23.5 12Z"
											stroke="black"
											stroke-opacity="0.1"
										/>
									</svg>
								</span>
								<!-- <p>회색</p> -->
							</span>
							<!-- <span class="tac mx-2 color_circle">
								<span class="circle">
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
									>
										<path
											d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z"
											fill="#FFFDEE"
										/>
										<path
											d="M23.5 12C23.5 18.3513 18.3513 23.5 12 23.5C5.64873 23.5 0.5 18.3513 0.5 12C0.5 5.64873 5.64873 0.5 12 0.5C18.3513 0.5 23.5 5.64873 23.5 12Z"
											stroke="black"
											stroke-opacity="0.1"
										/>
									</svg>
								</span>
								<p>아이보리</p>
							</span> -->
							<span class="color_circle_txt color_font"> 색상의 가구가 조화롭게 어울리는 멋진 인테리어네요! </span>
						</div>
						<div
							v-else
							class="result_txt"
						>
							<span class="fw_bold color_state_posi"> 사진 분석 AI가 가구 정보를 감지하지 않네요! </span>
						</div>
					</div>

					<p class="tac font_lg color_font">사진 분석 결과를 바탕으로 추천된 제품을 확인해보세요!</p>
				</div>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import common from '@/mixins/common'
export default {
	name: 'PhotoAnalysis',
	mixins: [common],
	props: ['items'],
	data: () => ({
		showTooltip: false,
		uploadedImage: null,
		furniture: [
			{
				title: '소파',
				titleEng: 'sofa',
			},
			{
				title: '의자',
				titleEng: 'chair',
			},
			{
				title: '테이블',
				titleEng: 'diningtable',
			},
		],
	}),
	computed: {
		...mapGetters(['HOME_GET_SEARCH_PICTURE_UPLOAD_FILE']),
	},
	watch: {
		HOME_GET_SEARCH_PICTURE_UPLOAD_FILE() {
			this.uploadedImage = this.HOME_GET_SEARCH_PICTURE_UPLOAD_FILE?.image
		},
		items() {
			this.updateColorSpectrum()
		},
	},
	created() {
		this.uploadedImage = this.HOME_GET_SEARCH_PICTURE_UPLOAD_FILE?.image
	},
	mounted() {
		this.updateColorSpectrum()
	},
	methods: {
		...mapMutations(['HOME_MU_SEARCH_PICTURE_UPLOAD_FILE']),
		triggerFileInput() {
			this.$refs.fileInput.click()
		},
		async handleFileChange(e) {
			const selectedFile = e.target.files[0]
			if (selectedFile) {
				let data = {}
				const reader = new FileReader()
				reader.onload = e => {
					data.file = selectedFile
					data.image = e.target.result
					this.HOME_MU_SEARCH_PICTURE_UPLOAD_FILE(data)
				}
				reader.readAsDataURL(selectedFile)
			}
		},
		updateColorSpectrum() {
			const colorBoxes = Array.from(document.getElementsByClassName('colorBoxes'))
			const colors = this.items.colorInfo

			colorBoxes.forEach((box, i) => {
				let colorBox = document.getElementById(`colorBox${i + 1}`)
				if (colorBox) box.removeChild(colorBox)

				const newColorBox = document.createElement('div')
				newColorBox.id = `colorBox${i + 1}`
				newColorBox.classList.add('colorBox')
				box.appendChild(newColorBox)

				for (let j = 0; j <= 100; j++) {
					const innerDiv = document.createElement('div')
					//바닥컬러
					if (i === 0) {
						innerDiv.style.background = `hsl(${colors.floorColorHsl[0]}, ${colors.floorColorHsl[1]}%, ${100 - j}%)`
						if (j === 100 - colors.floorColorHsl[2]) innerDiv.classList.add('here')
					}
					// 벽컬러
					if (i === 1) {
						innerDiv.style.background = `hsl(${colors.wallColorHsl[0]}, ${colors.wallColorHsl[1]}%, ${100 - j}%)`
						if (j === 100 - colors.wallColorHsl[2]) innerDiv.classList.add('here')
					}
					newColorBox.appendChild(innerDiv)
				}
			})
		},
	},
}
</script>

<style scoped lang="scss">
.photo_analysis {
	.head {
		.subject {
			font-size: $font_sub_tit;
			font-weight: 500;
			letter-spacing: -2px;
			color: $color_font;
			.d-flex {
				align-items: center !important;
			}
		}
		.btn_pic_chage {
			display: inline-block;
			width: 100px;
			padding: 4px 13px;
			border: 1px solid $color_gray_3;
			border-radius: 4px;
			box-sizing: border-box;
			font-size: $font_sm;
		}
		.desc {
			display: inline-block;
			position: relative;
			cursor: pointer;
			padding-left: 4px;
			line-height: 20px;
			> .v-icon.v-icon {
				&::after {
					display: none;
				}
			}
			.eff {
				display: none;
			}
			&:hover {
				.eff {
					display: inline-block;
					width: 30px;
					height: 30px;
					position: absolute;
					left: 1px;
					top: -2px;
					background-color: rgba(71, 170, 252, 0.3);

					border-radius: 100%;
				}
			}
			.txt {
				width: 400px;
				position: absolute;
				bottom: -90px;
				left: -30px;
				padding: 16px;
				background: rgba(38, 38, 38, 0.9);
				border-radius: 4px;
				color: $color_white;
				font-size: $font_normal;
				z-index: 2;
				.btn_close {
					height: 10px;
					.v-icon.v-icon {
						vertical-align: top !important;
					}
				}
				&:after {
					border-top: 0 solid transparent;
					border-left: 8px solid transparent;
					border-right: 8px solid transparent;
					border-bottom: 8px solid rgba(38, 38, 38, 0.9);
					content: '';
					position: absolute;
					top: -8px;
					left: 38px;
				}
			}
		}
	}
	.left {
		height: 100%;
		border: 1px solid $color_gray_2;
		border-radius: 8px;
		.pic_change {
			width: 100%;
			max-width: 320px;
			margin: 0 auto;
			border-radius: 10px;
			border: 1px solid rgba(0, 0, 0, 0.1);
			overflow: hidden;
			aspect-ratio: 16/12 !important;
		}
		.btn_pic_chage {
			width: 100%;
			padding: 10px 0;
			color: $color_white;
			background-color: rgba(0, 0, 0, 0.6) !important;
			font-size: $font_normal;
			border-radius: 8px;
			cursor: pointer;
		}
		.tone {
			padding: 5px 16px;
			color: $color_font;
			font-size: $font_lg;
			line-height: 1;
			border-radius: 99px;
			background-color: $color_gray_1;
		}
		::v-deep(.colorBox) {
			display: flex;
			width: 100%;
			height: 12px;
			> div {
				position: relative;
				flex: 1;
				border-top: 1px solid $color_gray_2;
				border-bottom: 1px solid $color_gray_2;
				&:first-child {
					border-start-start-radius: 160px;
					border-end-start-radius: 160px;
					border-left: 1px solid $color_gray_2;
				}
				&:last-child {
					border-start-end-radius: 160px;
					border-end-end-radius: 160px;
					border-right: 1px solid $color_gray_2;
				}
				&.here::before {
					display: block;
					width: 11px;
					height: 13px;
					background: url('../../../assets/img/search/color_drop.svg') no-repeat;
					background-size: contain;
					content: '';
					position: absolute;
					top: -18px;
					left: -5.5px;
				}
			}
		}
		.furniture {
			padding: 7px 18px;
			background-color: $color_gray_1;
			border-radius: 4px;
			.tit {
				padding: 5px 10px;
				font-size: $font_lg;
				color: $color_font;
				background-color: $color_white;
				border-radius: 99px;
			}
			.item_group {
				display: flex;
				justify-content: space-between;
				.item {
					text-align: center;
					font-size: $font_sm;
					color: $color_gray_4;
					line-height: 1.2 !important;
					&.active {
						color: $color_font !important;
						svg {
							rect {
								fill: $color_state_posi !important;
							}
							path {
								fill: $color_white !important;
							}
						}
					}
				}
			}
		}
	}
	.right {
		.analysis {
			background-color: $color_gray_1;
			border-radius: 8px;
			vertical-align: middle;
			.txt {
				font-size: $font_xl;
				font-weight: $fw_regular;
				color: $color_font;
				word-break: keep-all;
			}
		}
		.result_txt {
			.color_circle {
				display: inline-block;
				vertical-align: middle;
				.circle {
					width: 24px;
					height: 24px;
				}
			}
			.color_circle_txt {
				display: inline-block;
				font-size: $font_lg;
				word-break: keep-all;
			}
		}
	}
	.drop {
		min-width: 64px;
		text-align: center;
		.color_code {
			padding: 2px 14px !important;
			background-color: $color_white;
			border: 1px solid $color_gray_2;
			border-radius: 99px;
			font-size: $font_normal;
			color: $color_font;
		}
	}
}

@media all and (max-width: 959px) {
	.photo_analysis {
		.head {
			.subject {
				font-size: $font_lg !important;
			}
		}
		.left {
			.pic_change {
				max-width: 100% !important;
			}
			.btn_pic_chage {
				display: none !important;
			}
			.tone {
				font-size: $font_normal !important;
			}
			::v-deep(.colorBox) {
				height: 10px !important;
				> div {
					&.here::before {
						top: -15px !important;
					}
				}
			}
			.furniture {
				padding: 7px 18px;
				background-color: $color_gray_1;
				border-radius: 4px;
				.tit {
					padding: 5px 10px;
					font-size: $font_lg;
					color: $color_font;
					background-color: $color_white;
					border-radius: 99px;
				}
				.item {
					text-align: center;
					font-size: $font_normal;
					color: $color_font;
					line-height: 1.2 !important;
					&.active {
						svg {
							rect {
								fill: $color_state_posi !important;
							}
							path {
								fill: $color_white !important;
							}
						}
					}
				}
			}
		}
		.drop {
			svg {
				width: 24px !important;
				height: 28px !important;
			}
			.color_code {
				padding: 2px 10px !important;
				font-size: $font_sm !important;
			}
		}
	}
}
@media all and (max-width: 600px) {
}
</style>
