<template>
	<!-- 연관검색어 & 최근검색어 -->
	<div class="pt-5 pb-2 association_view">
		<div class="px-5">
			<v-row class="ma-0 mb-5">
				<v-col
					class="pa-0"
					cols="6"
					align="start"
					align-self="center"
				>
					<p class="tit">최근검색어</p>
				</v-col>
				<v-col
					class="pa-0"
					cols="6"
					align="end"
					align-self="center"
				>
					<v-btn
						class="pa-0 btn_all_del"
						text
						@click="deleteRecentKeywords"
						>전체삭제</v-btn
					>
				</v-col>
			</v-row>
			<v-row class="ma-0 mb-3">
				<template v-if="recentKeywords.length === 0">
					<v-col class="text-center">최근검색어가 없습니다.</v-col>
				</template>
				<template v-else>
					<template v-for="(item, i) in recentKeywords">
						<v-col
							v-if="i < 5"
							:key="item"
							class="py-1 px-3 mr-1 mb-1 recent_tag"
							cols="auto"
							align-self="center"
							@click="$emit('onSearch', item)"
						>
							<div class="inner">
								<span class="txt">{{ item }}</span>
								<v-btn
									class="pa-0 ml-2"
									icon
								>
									<v-icon
										color="#D8D8D8"
										@click="event => deleteRecentKeyword(item, event)"
										>mdi-close-circle</v-icon
									>
								</v-btn>
							</div>
						</v-col>
					</template>
				</template>
			</v-row>
		</div>

		<div class="py-4 px-5 mb-4 bg_gray">
			<v-row class="ma-0 mb-4">
				<v-col
					class="pa-0"
					cols="3"
					align="start"
				>
					<p class="tit">추천 검색</p>
				</v-col>
				<v-col
					class="pa-0"
					cols="9"
				>
					<ul class="pa-0">
						<li
							v-for="(item, i) in APPBAR_GET_SEARCH_KEYWORDS"
							:key="i"
							@click="onLink2(item.link_url)"
						>
							# {{ item.search_name }}
						</li>
					</ul>
				</v-col>
			</v-row>

			<v-col
				class="pa-0"
				align="start"
			>
				<p class="mb-3 tit">추천 태그</p>
				<v-row class="ma-0 tag_wrap">
					<template v-for="(item, i) in HOME_GET_MAIN_SEARCH_NEW_TAGS">
						<v-col
							v-if="i < 12"
							:key="item.tag_id"
							class="py-1 px-4 tag"
							cols="auto"
							@click="$emit('onSearch', item.tag_value)"
						>
							{{ item.tag_value }}
						</v-col>
					</template>
				</v-row>
			</v-col>
		</div>

		<div class="px-5">
			<v-col
				class="pa-0"
				align="start"
			>
				<p class="mb-4 tit">추천 제품</p>
				<v-carousel
					cycle
					:interval="3000"
					:show-arrows="APPBAR_GET_RECOMMEND_PRODUCT.length > 1"
					class="recommend_item"
				>
					<template v-for="(item, i) in APPBAR_GET_RECOMMEND_PRODUCT">
						<v-carousel-item
							v-if="i < 3"
							:key="item.product_id"
						>
							<v-row
								class="ma-0 px-6"
								@click="onLink(`/product/detail/${item.product_id}`)"
							>
								<v-col
									cols="4"
									sm="auto"
									class="pa-0 item_img"
								>
									<div class="pr-2">
										<v-img :src="item.image" />
									</div>
								</v-col>
								<v-col
									cols="6"
									class="pa-0"
									align="start"
									align-self="center"
								>
									<div class="pl-2">
										<p class="category">{{ item.kind_name }}</p>
										<p class="ex">{{ item.brand_name }} {{ item.goods_name }} {{ item.pattern_name }}</p>
									</div>
								</v-col>
							</v-row>
						</v-carousel-item>
					</template>
					<!-- <v-carousel-item>
						<v-row class="ma-0 px-6">
							<v-col
								cols="4"
								sm="auto"
								class="pa-0 item_img"
							>
								<div class="pr-2">
									<v-img :src="require('@/assets/img/home/main/img_best2.png')" />
								</div>
							</v-col>
							<v-col
								cols="6"
								class="pa-0"
								align="start"
								align-self="center"
							>
								<div class="pl-2">
									<p class="category">강마루</p>
									<p class="ex">나투스진 그란데 이모션 블랑 나투스진 그란데 이모션</p>
								</div>
							</v-col>
						</v-row>
					</v-carousel-item> -->
				</v-carousel>
			</v-col>
		</div>

		<v-col
			class="pa-0 px-5"
			align="end"
		>
			<v-btn
				class="pa-0 btn_close"
				text
				@click="onClose"
			>
				닫기
				<v-icon
					color="#CCC"
					small
				>
					mdi-window-close
				</v-icon>
			</v-btn>
		</v-col>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import common from '@/mixins/common'

export default {
	name: 'Association',
	mixins: [common],
	props: {},
	data: () => ({
		recentKeywords: [],
	}),
	computed: {
		...mapGetters(['APPBAR_GET_SEARCH_KEYWORDS', 'HOME_GET_MAIN_SEARCH_TAGS', 'HOME_GET_MAIN_SEARCH_NEW_TAGS', 'APPBAR_GET_RECOMMEND_PRODUCT']),
	},
	watch: {},
	async created() {
		this.recentKeywords = this.$cookies.get('searchKeyword')
			? Array.isArray(this.$cookies.get('searchKeyword'))
				? this.$cookies.get('searchKeyword')
				: JSON.parse(this.$cookies.get('searchKeyword'))
			: []
		if (this.APPBAR_GET_SEARCH_KEYWORDS?.length === 0) await this.getSearchList()
		if (this.HOME_GET_MAIN_SEARCH_TAGS?.length === 0) await this.getSearchProductList()
		if (this.HOME_GET_MAIN_SEARCH_NEW_TAGS?.length === 0) await this.getSearchProductListNew()
		if (this.APPBAR_GET_RECOMMEND_PRODUCT?.length === 0) await this.getRecommendProduct()
	},
	mounted() {},
	methods: {
		...mapActions(['APPBAR_ACT_SEARCH_KEYWORDS', 'HOME_ACT_MAIN_SEARCH_TAGS', 'HOME_ACT_MAIN_SEARCH_NEW_TAGS', 'APPBAR_ACT_RECOMMEND_PRODUCT']),
		async getSearchList() {
			// 추천검색어
			await this.APPBAR_ACT_SEARCH_KEYWORDS().then(res => {
				console.log('APPBAR_GET_SEARCH_KEYWORDS', res)
			})
		},
		async getSearchProductList() {
			// 추천태그
			await this.HOME_ACT_MAIN_SEARCH_TAGS().then(res => {
				console.log('HOME_ACT_MAIN_SEARCH_TAGS', res)
			})
		},
		async getSearchProductListNew() {
			// 추천뉴태그
			await this.HOME_ACT_MAIN_SEARCH_NEW_TAGS().then(res => {
				console.log('HOME_ACT_MAIN_SEARCH_NEW_TAGS', res)
			})
		},
		async getRecommendProduct() {
			// 추천제품
			await this.APPBAR_ACT_RECOMMEND_PRODUCT().then(res => {
				console.log('APPBAR_ACT_RECOMMEND_PRODUCT', res)
			})
		},
		deleteRecentKeywords() {
			this.recentKeywords = []
			this.$cookies.set('searchKeyword', '', { expires: 'Thu, 01 Jan 1970 00:00:00 GMT', path: '/' })
			console.log('deleteRecentKeywords', this.$cookies.get('searchKeyword'))
		},
		deleteRecentKeyword(val, e) {
			e.stopPropagation()
			if (this.recentKeywords.length === 0) return

			this.recentKeywords = this.recentKeywords.filter(item => item !== val)
			this.$cookies.set('searchKeyword', JSON.stringify(this.recentKeywords), '1d')
		},
		onLink(link) {
			console.log(link)
			this.common_onLink(link)
			// 검색 후 검색창 닫기
			setTimeout(() => {
				this.$emit('close')
			}, 100)
		},
		onLink2(link) {
			window.location.href = link
			// 검색 후 검색창 닫기
			setTimeout(() => {
				this.$emit
			}, 100)
		},
		onClose(e) {
			this.$emit('close', e)
		},
	},
}
</script>

<style scoped lang="scss">
@mixin ellipsis_1 {
	display: -webkit-box !important;
	word-wrap: break-word !important;
	-webkit-line-clamp: 1 !important;
	-webkit-box-orient: vertical !important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
}
@mixin ellipsis_2 {
	display: -webkit-box !important;
	word-wrap: break-word !important;
	-webkit-line-clamp: 2 !important;
	-webkit-box-orient: vertical !important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
}

.association_view {
	width: 100%;
	max-width: 420px;
	background-color: $color_white;

	.btn_all_del {
		min-width: 0;
		:deep(.v-btn__content) {
			color: $color_gray_7;
			border-bottom: 1px solid $color_gray_7;
			font-size: $font_normal;
			font-weight: $fw_normal;
		}
	}
	.btn_close {
		min-width: 0;
		:deep(.v-btn__content) {
			color: $color_gray_7;
			font-size: $font_normal;
			font-weight: $fw_normal;
		}
	}

	.tit {
		color: $color_font;
		font-size: $font_normal;
		font-weight: $fw_bold;
	}

	.recent_tag {
		border-radius: 99px;
		border: 1px solid $color_gray_3;
		max-width: 200px;
		cursor: pointer;
		.inner {
			display: flex;
			align-items: center;
			.txt {
				@include ellipsis_1;
			}
		}
		.v-btn {
			width: 16px !important;
			height: 16px !important;
			span {
				font-size: $font_normal;
			}
			:deep(.v-btn__content) {
				width: 16px;
				height: 16px;
				.v-icon {
					font-size: $font_xl;
				}
			}
		}
	}

	.bg_gray {
		background-color: $color_gray_1;
		ul {
			li {
				@include ellipsis_1;
				margin-bottom: 8px;
				color: $color_gray_7;
				font-weight: $fw_normal;
				text-align: left;
				list-style: none;
				cursor: pointer;
			}
		}
		.tag_wrap {
			.tag {
				color: #78c046;
				background-color: $color_white;
				border-radius: 20px;
				margin: 4px 8px 4px 0;
				border: 1px solid $color_primary_1;
				cursor: pointer;
			}
		}
	}

	.recommend_item {
		overflow: visible;
		height: auto !important;
		cursor: pointer;
		&.v-window {
			overflow: visible;
		}
		:deep(.v-carousel__item) {
			height: auto !important;
		}
		:deep(.v-carousel__controls) {
			width: auto;
			height: 8px;
			left: 60px !important;
			top: -30px;
			background-color: transparent;
			.v-carousel__controls__item {
				width: 8px;
				height: 8px;
				background-color: $color_gray_3;
				&.v-btn--active {
					background-color: $color_primary_1;
				}
				.v-icon {
					opacity: 0;
				}
			}
		}
		:deep(.v-window__prev) {
			left: -36px;
			background-color: transparent;
			.v-btn__content {
				color: $color_gray_3;
			}
		}
		:deep(.v-window__next) {
			right: -36px;
			background-color: transparent;
			.v-btn__content {
				color: $color_gray_3;
			}
		}
		.item_img {
			.v-image {
				max-width: 90px;
				aspect-ratio: 1/1 !important;
				border-radius: 8px;
			}
		}
		.category {
			color: $color_gray_7;
			font-size: $font_normal;
		}
		.ex {
			@include ellipsis_2;
			color: $color_font;
			font-size: $font_normal;
		}
	}
}

@media all and (max-width: 959px) {
	.association_view {
		max-width: unset !important;
	}
}
</style>
