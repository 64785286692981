<!-- eslint-disable vue/no-template-key -->
<template>
	<v-dialog
		v-model="dialog"
		persistent
		width="1000"
	>
		<v-card class="pa-0">
			<AdminItemsTitle
				:title="`맞춤 견적 상담 보기`"
				@close="$emit('close')"
			/>
			<v-col class="pa-0 pa-md-3">
				<v-container class="admin_table">
					<h2>신청정보</h2>
					<v-row class="ma-0 admin_table_area">
						<v-col
							cols="12"
							md="12"
							class="pa-0"
							:fill="true"
						>
							<AdminItemsTableItem
								v-if="readItem.consult_id"
								title="신청 번호"
								:text="readItem.consult_id"
								:fill="true"
							/>
						</v-col>
						<v-col
							cols="12"
							md="12"
							class="pa-0"
							:fill="true"
						>
							<AdminItemsTableItem
								v-if="readItem.hand"
								title="신청 번호"
								:text="readItem.hand"
								:fill="true"
							/>
						</v-col>
						<v-col
							cols="12"
							md="6"
							class="pa-0"
						>
							<AdminItemsTableItem
								v-if="readItem.product_kind"
								title="문의 구분"
								:text="readItem.product_kind"
							/>
						</v-col>
						<v-col
							cols="12"
							md="6"
							class="pa-0"
						>
							<AdminItemsTableItem
								title="컨텐츠URL"
								:text="
									readItem.product_kind === '3D집들이관리'
										? `/housewarming/3d/detail/${readItem.product_id}`
										: readItem.product_kind === '전문가집들이관리'
										? `/housewarming/specialist/detail/${readItem.product_id}`
										: readItem.product_kind === '상품마스터'
										? `/product/detail/${readItem.product_id}`
										: ''
								"
							/>
						</v-col>
						<!-- <v-col
							cols="12"
							md="6"
							class="pa-0"
						>
							<AdminItemsTableItem
								v-if="readItem.brand_name"
								title="브랜드"
								:text="readItem.brand_name"
							/>
						</v-col> -->

						<!-- <v-col
							cols="12"
							md="6"
							class="pa-0"
						>
							<AdminItemsTableItem
								v-if="readItem.login_id"
								title="고객 ID"
								:text="readItem.login_id"
							/>
						</v-col> -->
						<v-col
							cols="12"
							class="pa-0"
							:fill="true"
						>
							<AdminItemsTableItem
								title="신청자명"
								:text="readItem.login_name"
								:fill="true"
							/>
						</v-col>
						<v-col
							cols="12"
							class="pa-0"
							:fill="true"
						>
							<AdminItemsTableItem
								v-if="readItem.login_id"
								title="신청자 연락처"
								:text="readItem.login_id"
								:fill="true"
							/>
							<AdminItemsTableItem
								v-if="readItem.login_id"
								title="신청자 연락처"
								:text="readItem.hand"
								:fill="true"
							/>
						</v-col>
						<v-col
							cols="12"
							class="pa-0"
							:fill="true"
						>
							<AdminItemsTableItem
								v-if="readItem.content"
								title="문의사항"
								:text="readItem.content"
								:fill="true"
							/>
						</v-col>
						<v-col
							cols="12"
							md="6"
							class="pa-0"
						>
							<AdminItemsTableItem
								v-if="readItem.housetype"
								title="주거형태"
								:text="readItem.housetype"
							/>
						</v-col>
						<v-col
							cols="12"
							md="6"
							class="pa-0"
						>
							<AdminItemsTableItem
								v-if="widthCodeCheck.length !== 0"
								title="전용면적"
								:text="widthCodeCheck[0].text"
							/>
						</v-col>
						<v-col
							cols="12"
							md="6"
							class="pa-0"
						>
							<AdminItemsTableItem
								v-if="readItem.roomcount"
								title="방개수"
								:text="`${readItem.roomcount} 개`"
							/>
						</v-col>
						<v-col
							cols="12"
							md="6"
							class="pa-0"
						>
							<AdminItemsTableItem
								v-if="readItem.veranda_yn"
								title="베란다확장여부"
								:text="readItem.veranda_yn === 'y' ? '베란다 확장' : '베란다 미확장'"
							/>
						</v-col>
						<v-col
							v-if="readItem.product_kind === '빠른견적'"
							cols="12"
							md="6"
							class="pa-0"
						>
							<AdminItemsTableItem
								v-if="readItem.est_cost"
								title="예상견적"
								:text="`${readItem.est_cost} 만원`"
							/>
						</v-col>
						<v-col
							v-if="readItem.product_kind === '견적내기'"
							cols="12"
							md="6"
							class="pa-0"
						>
							<AdminItemsTableItem
								v-if="readItem.buildscope"
								title="시공범위"
								:text="readItem.buildscope"
							/>
						</v-col>
						<v-col
							v-if="
								(readItem.goods_name !== '' || readItem.goods_name !== null) &&
								readItem.product_kind === '빠른견적'
							"
							cols="12"
							md="6"
							class="pa-0"
						>
							<AdminItemsTableItem
								v-if="readItem.goods_name === '그란데' && readItem.product_kind === '빠른견적'"
								title="시공제품"
								:text="good_name_list.gc_01"
							/>
							<AdminItemsTableItem
								v-if="readItem.goods_name === '베이직' && readItem.product_kind === '빠른견적'"
								title="시공제품"
								:text="good_name_list.gc_02"
							/>
							<AdminItemsTableItem
								v-if="readItem.goods_name === '125' && readItem.product_kind === '빠른견적'"
								title="시공제품"
								:text="good_name_list.gc_03"
							/>
						</v-col>
						<v-col
							v-if="readItem.product_kind === '견적내기'"
							cols="12"
							md="6"
							class="pa-0"
						>
							<AdminItemsTableItem
								v-if="
									readItem.goods_name !== '125' ||
									readItem.goods_name !== '그란데' ||
									readItem.goods_name !== '베이직'
								"
								title="시공제품"
								:text="readItem.goods_name"
							/>
						</v-col>
						<v-col
							cols="12"
							md="6"
							class="pa-0"
						>
							<AdminItemsTableItem
								v-if="readItem.removal_yn"
								title="철거여부"
								:text="readItem.removal_yn === 'y' ? '철거진행' : '철거미진행'"
							/>
						</v-col>
						<v-col
							v-if="readItem.product_kind === '견적내기'"
							cols="12"
							md="6"
							class="pa-0"
						>
							<AdminItemsTableItem
								v-if="readItem.est_cost"
								title="예상견적"
								:text="`${readItem.est_cost} 만원`"
							/>
						</v-col>
						<v-col
							cols="12"
							class="pa-0"
							:fill="true"
						>
							<AdminItemsTableItem
								v-if="readItem.regi_date"
								title="상담의뢰일"
								:text="readItem.regi_date"
								:fill="true"
							/>
						</v-col>
						<!-- <v-col
							cols="12"
							class="pa-0"
						>
							 <AdminItemsTableItem
								title="고객 핸드폰"
								:text="common_number_filter(readItem.login_hand)"
								:fill="true"
							/> 
						</v-col> -->
						<!-- <v-col
							cols="12"
							class="pa-0"
						>
							<AdminItemsTableItem
								v-if="!readItem.address.includes('undefined')"
								title="고객 주소"
								:text="readItem.address"
								:fill="true"
							/>
						</v-col> -->
						<!-- <v-col
							cols="12"
							class="pa-0"
						>
							<AdminItemsTableItem
								v-if="filterEstconsultadd.length !== 0"
								v-model="filterEstconsultadd"
								title="추가상담 항목"
								tag-chips
								:fill="true"
							/>
						</v-col> -->
						<!-- <v-col
							cols="12"
							class="pa-0"
						>
							<AdminItemsTableItem
								v-if="readItem.content"
								title="문의사항"
								:text="readItem.content"
								:fill="true"
							/>
						</v-col> -->
						<!-- <v-col
							cols="12"
							md="6"
							class="pa-0"
						>
							<AdminItemsTableItem
								v-if="readItem.regi_userid"
								title="등록자"
								:text="readItem.regi_userid"
							/>
						</v-col> -->
						<!-- <v-col
							cols="12"
							md="6"
							class="pa-0"
						>
							<AdminItemsTableItem
								v-if="readItem.regi_date"
								title="등록일"
								:text="readItem.regi_date"
							/>
						</v-col> -->
					</v-row>
					<h2 class="mt-4">문의인테리어점 정보</h2>
					<template v-for="(item, i) in interiorItems">
						<p class="middleTit py-2">인테리어점 정보 {{ i + 1 }}</p>
						<v-row class="ma-0 admin_table_area mb-3">
							<v-col
								cols="12"
								md="6"
								class="pa-0"
							>
								<AdminItemsTableItem
									v-if="item.business_name"
									title="인테리어점"
									:text="item.business_name"
								/>
							</v-col>
							<v-col
								cols="12"
								md="6"
								class="pa-0"
							>
								<AdminItemsTableItem
									v-if="item.corporate_no"
									title="사업자번호"
									:text="item.corporate_no"
								/>
							</v-col>
							<v-col
								cols="12"
								md="6"
								class="pa-0"
							>
								<AdminItemsTableItem
									v-if="item.boss_name"
									title="대표자명"
									:text="item.boss_name"
								/>
							</v-col>
							<v-col
								cols="12"
								md="6"
								class="pa-0"
							>
								<AdminItemsTableItem
									v-if="item.company_tel"
									title="대표자 연락처"
									:text="item.company_tel"
								/>
							</v-col>
						</v-row>
						<p class="middleTit py-2">상담자정보 {{ i + 1 }}</p>
						<v-row class="ma-0 admin_table_area mb-3">
							<v-col
								cols="12"
								md="6"
								class="pa-0"
							>
								<AdminItemsTableItem
									title="상담상태"
									:text="item.consult_state.text"
								/>
							</v-col>
							<v-col
								cols="12"
								md="6"
								class="pa-0"
							>
								<AdminItemsTableItem
									title="처리 대기일"
									:text="item.consult_state.text"
								/>
							</v-col>
							<v-col
								cols="12"
								md="6"
								class="pa-0"
							>
								<AdminItemsTableItem
									title="상담자 이름"
									:text="item.manager_name"
								/>
							</v-col>
							<v-col
								cols="12"
								md="6"
								class="pa-0"
							>
								<AdminItemsTableItem
									title="상담자 ID"
									:text="item.manager_name"
								/>
							</v-col>
							<v-col
								cols="12"
								md="12"
								class="pa-0"
							>
								<!-- <AdminItemsTableItem
									title="상담자 연락처"
									:text="common_number_filter(item.manager_hand)"
								/> -->
								<AdminItemsTableItem
									v-if="item.manager_hand"
									title="상담자 연락처"
									:text="item.manager_hand"
									:fill="true"
								/>
							</v-col>
							<v-col
								cols="12"
								md="12"
								class="pa-0"
								:fill="true"
							>
								<AdminItemsTableItem
									title="상담 처리일"
									:text="item.response_date"
									:fill="true"
								/>
							</v-col>
							<!-- <v-col
								cols="12"
								md="6"
								class="pa-0"
							>
								<AdminItemsTableItem
									title="상담대기 등록일"
									:text="item.check_date"
								/>
							</v-col>
							<v-col
								cols="12"
								md="6"
								class="pa-0"
							>
								<AdminItemsTableItem
									title="상담결과 등록일"
									:text="item.response_date"
								/>
							</v-col> -->
						</v-row>
						<div
							style="
								height: 1px;
								width: 100%;
								background-color: rgb(227, 234, 230);
								padding: 10px 0;
								margin: 15px 0;
							"
						></div>
					</template>
				</v-container>
				<v-row
					class="ma-0"
					align="center"
					justify="end"
				>
					<v-col
						cols="12"
						sm="4"
						md="3"
						lg="2"
					>
						<v-btn
							color="error"
							block
							tile
							elevation="0"
							@click="$emit('close')"
						>
							<v-icon>clear</v-icon>
							<span class="ml-2">닫기</span>
						</v-btn>
					</v-col>
				</v-row>
			</v-col>
		</v-card>
	</v-dialog>
</template>

<script>
import admin from '@/mixins/admin'
import common from '@/mixins/common'

export default {
	name: 'AdminEstimateConsultRead',
	mixins: [common, admin],
	props: ['title', 'dialog', 'readItem', 'widthCodeItems'],
	data: () => ({
		interiorItems: [],
		good_name_list: {
			gc_01: '강마루',
			gc_02: '강화마루',
			gc_03: '원목마루',
		},
	}),
	computed: {
		filterEstconsultadd() {
			return this.readItem.estconsultadd?.map(x => x.code_name) || []
		},
		widthCodeCheck() {
			return this.widthCodeItems?.filter(v => v.exclusive_area === this.readItem.exclusive_area)
		},
	},
	watch: {},
	created() {
		this.parseInteriorItems()
	},
	mounted() {},
	destroyed() {
		this.interiorItems = []
	},
	methods: {
		parseInteriorItems() {
			for (let i = 0; i < 3; i++) {
				const num = i + 1
				const checkValue = this.readItem[`corporate_no${num}`]
				if (checkValue) {
					const item = {
						business_name: this.readItem[`business_name${num}`],
						corporate_no: this.readItem[`corporate_no${num}`],
						consult_state: this.admin_estimate_consult_statusItems.find(
							x => x.text === this.readItem[`consult_state${num}`],
						),
						res_content: this.readItem[`res_content${num}`],
						manager_name: this.readItem[`manager_name${num}`],
						manager_hand: this.readItem[`manager_hand${num}`],
						business_id: this.readItem[`business_id${num}`],
						check_date: this.readItem[`check_date${num}`],
						response_date: this.readItem[`response_date${num}`],
						boss_name: this.readItem[`boss_name${num}`],
						company_tel: this.readItem[`company_tel${num}`],
					}
					this.interiorItems.push(item)
				}
			}
		},
	},
}
</script>

<style scoped lang="scss">
.middleTit {
	font-size: $font_lg;
	font-weight: $fw_bold;
}
</style>
