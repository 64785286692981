<template>
	<div class="pl-0 ma-md-0 click_price_button_home click_price_button_fb">
		<v-col class="py-3 estimate">
			<p class="mt-2 tit tac">빠른견적 내기</p>
			<div
				class="d-flex align-content-space-between flex-wrap"
				style="height: 90%"
			>
				<v-col
					v-if="!checkResult"
					cols="12"
					class="py-0"
				>
					<p class="tac color_gray_6">단 세가지 항목으로 빠르게 견적을 확인해보세요.</p>
				</v-col>
				<v-col
					cols="12"
					class="py-0"
				>
					<p class="mb-1 color_gray_7">주거유형</p>
					<CommonCardMenuTypeBtn05
						v-model="homeTypeModel1"
						class="fast"
						:type-items="homeTypeItems1"
						:cols="4"
					/>
				</v-col>
				<v-col
					cols="12"
					class="py-0"
				>
					<p class="mb-1 color_gray_7">제품</p>
					<CommonCardMenuTypeBtn05
						v-model="homeTypeModel2"
						class="fast"
						:type-items="homeTypeItems2"
						:cols="4"
					/>
				</v-col>
				<v-col
					cols="12"
					class="py-0"
				>
					<p class="mb-1 color_gray_7">전용면적</p>
					<CommonSelectsSelect01
						v-if="HOME_GET_INTERIOR_ESTIMATE_EXCLUSIVE_AREA"
						v-model="widthModel"
						class="fast"
						label="선택해주세요"
						:items="HOME_GET_INTERIOR_ESTIMATE_EXCLUSIVE_AREA"
					/>
				</v-col>
				<v-col
					v-if="!checkResult"
					class="py-0 layout_align pt-2"
					cols="12"
					:class="!checkResult ? 'mb-4' : ''"
				>
					<CommonButtonsButton01
						name="예상 견적 보기"
						color="#00CE7D"
						class-name="edge_8 fw_bold font_16 btn_upload02"
						height="46"
						radius="8"
						@click="getSimpleEstimatePost"
					/>
				</v-col>
				<CommonLoaderLine01 v-if="loading" />
				<v-col
					v-if="checkResult && !loading"
					cols="12"
					class="py-0 pt-2"
				>
					<v-row class="ma-0 py-2 px-4 cal">
						<v-col
							class="pa-0"
							cols="6"
						>
							<p class="tit">예상견적</p>
						</v-col>
						<v-col
							class="pa-0"
							cols="6"
							align="end"
						>
							<strong
								>{{ result.est_cost_min }}~{{ result.est_cost_max }}<span class="constvalue">만원</span></strong
							>
							<p class="notice">*철거비용은 별도 입니다.</p>
						</v-col>
					</v-row>
					<v-col
						class="pa-0 pt-1 d-flex align-center justify-space-between"
						cols="12"
						align="center"
					>
						<!-- 초기화 -->
						<!-- <p
							class="d-flex align-center resetcalc"
							@click="calcFunc('reset')"
						> -->
						<p
							class="d-flex align-center resetcalc"
							@click="getSimpleEstimatePost"
						>
							<v-img
								width="16"
								height="16"
								class=""
								:src="require('@/assets/img/home/main/refresh_calc.svg')"
								eager
							/>
							<span class="pl-1 font_normal"> 견적 다시 내기 </span>
						</p>
						<!-- <CommonButtonsButton02
							name="더 상세한 견적이 필요하신가요 ?"
							height="20"
							class-name="btn_more"
							color="#262626"
							to="/interior/price"
							text
						/> -->
						<span
							class="all_view my-2"
							@click="calcFunc('move')"
						>
							더 상세한 견적이 필요하신가요 ?
						</span>
					</v-col>
				</v-col>
				<!--견적내기 임시 위치 버튼-->
				<v-col
					v-if="checkResult"
					class="py-0 layout_align"
					:class="checkResult ? 'mb-4' : ''"
					cols="12"
					align="center"
				>
					<CommonButtonsButton01
						name="간편상담 신청하기"
						color="#00582c"
						class-name="edge_8 fw_bold font_16 btn_upload02"
						height="46"
						radius="8"
						@click="openInquireDrawer"
					/>
				</v-col>
			</div>
		</v-col>
	</div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import ad_kakao from '@/mixins/ad/kakao'
import ad_mobion from '@/mixins/ad/mobion'

export default {
	name: 'HomeMainNewEstimate',
	mixins: [ad_kakao, ad_mobion],
	props: ['floating'],
	data: () => ({
		homeTypeModel1: 0,
		homeTypeModel2: 0,
		widthModel: {
			exclusive_area: '33',
			py_area: '10',
			text: '10평 ( 33㎡ )',
		},
		homeTypeItems1: [
			{ title: '아파트', value: '아파트' },
			{ title: '오피스텔', value: '오피스텔' },
			{ title: '빌라/주택', value: '빌라/주택' },
		],
		homeTypeItems2: [
			{ title: '강마루', value: 'KC_00001' },
			{ title: '강화마루', value: 'KC_00002' },
			{ title: '원목마루', value: 'KC_00003' },
		],
		result: {},
		loading: false,
	}),
	computed: {
		...mapGetters(['HOME_GET_INTERIOR_ESTIMATE_EXCLUSIVE_AREA', 'HOME_GET_INTERIOR_SELECT_PRICE']),
		checkResult() {
			return Object.keys(this.result).length > 0
		},
	},
	watch: {
		checkResult(newval, oldval) {
			if (newval !== true) {
				this.APPBAR_MU_INQUIRE_DRAWER(false)
			}
		},
	},
	created() {
		this.getData()
	},
	mounted() {
		if (this.floating) console.log('floating on')
		else console.log('floating off')
	},
	methods: {
		...mapMutations(['APPBAR_MU_INQUIRE_DRAWER', 'HOME_MU_INTERIOR_SELECT_PRICE']),
		...mapActions(['HOME_ACT_SIMPLE_ESTIMATE', 'HOME_ACT_INTERIOR_ESTIMATE_EXCLUSIVE_AREA']),
		async getData() {
			if (this.HOME_GET_INTERIOR_ESTIMATE_EXCLUSIVE_AREA === null) await this.HOME_ACT_INTERIOR_ESTIMATE_EXCLUSIVE_AREA() // 평수
		},
		async getSimpleEstimatePost() {
			let gaTag = `home`
			let kakaoTag = `click_price_button_home`
			let mobionTag = `click_price_button_home`

			if (this.floating !== undefined) {
				gaTag = `floating_bar`
				kakaoTag = `click_price_button_fb`
				mobionTag = `click_price_button_fb`
			}

			// google analytics
			window.gtag('event', 'click_price_button', { button_location: gaTag })
			// ad_kakao click
			this.ad_kakao_clickScript(null, kakaoTag)
			// ad_mobion click
			this.ad_mobion_click_convertion(mobionTag)

			this.loading = true
			const params = {
				housetype: this.homeTypeItems1[this.homeTypeModel1].value,
				exclusive_area: this.widthModel.exclusive_area,
				kind_code: this.homeTypeItems2[this.homeTypeModel2].value,

				buildscope: '집전체',
				veranda_yn: 'N',
				removal_yn: 'N',
			}

			console.log(params)
			try {
				// 빠른견적
				await this.HOME_ACT_SIMPLE_ESTIMATE(params).then(res => {
					// 너무 빠른 조회로 로딩시간 보이게 수정
					setTimeout(() => {
						this.loading = false
						this.result = res
						if (this.result !== null && this.widthModel !== null) {
							const estimateParams = {
								homeTypeItems2: this.homeTypeItems2[this.homeTypeModel2],
								widthModel: this.widthModel.text,
								goods_code: this.homeTypeItems2[this.homeTypeModel2].value,
								exclusive_area: this.result.exclusive_area,
								housetype: this.result.housetype,
								roomcount: this.result.roomcount,
								veranda_yn: this.result.veranda_yn,
								buildscope: this.result.buildscope,
								removal_yn: this.result.removal_yn,
								est_cost: this.result.est_cost,
								est_cost_min: this.result.est_cost_min,
								est_cost_max: this.result.est_cost_max,
								type: '빠른견적',
							}
							this.HOME_MU_INTERIOR_SELECT_PRICE(estimateParams)
						}
					}, 500)
					console.log('HOME_ACT_SIMPLE_ESTIMATE', res)
				})
			} catch (e) {
				this.loading = false
			}
		},
		calcFunc(val) {
			if (val === 'move') {
				this.$router.push('/interior/price')
			}
			if (val === 'reset') {
				this.result = {}
				this.widthModel = {
					exclusive_area: '33',
					py_area: '10',
					text: '10평 ( 33㎡ )',
				}
				this.homeTypeModel1 = 0
				this.homeTypeModel2 = 0
			}
		},
		openInquireDrawer() {
			if (this.result !== null && this.widthModel !== null) {
				const estimateParams = {
					homeTypeItems2: this.homeTypeItems2[this.homeTypeModel2],
					widthModel: this.widthModel.text,
					product_code: this.homeTypeItems2[this.homeTypeModel2].value,
					exclusive_area: this.result.exclusive_area,
					housetype: this.result.housetype,
					roomcount: this.result.roomcount,
					veranda_yn: this.result.veranda_yn,
					buildscope: this.result.buildscope,
					removal_yn: this.result.removal_yn,
					est_cost: this.result.est_cost,
					est_cost_min: this.result.est_cost_min,
					est_cost_max: this.result.est_cost_max,
					type: '빠른견적',
				}
				this.HOME_MU_INTERIOR_SELECT_PRICE(estimateParams)
			}
			this.APPBAR_MU_INQUIRE_DRAWER(true)
		},
	},
}
</script>

<style lang="scss" scoped>
.estimate {
	width: 100%;
	//height: 470px;
	height: 440px;
	background-color: #fff;
	border-radius: 10px;
	.tit {
		font-family: 'NotoSansKR-Bold' !important;
		font-size: $font_tit !important;
		color: #262626;
		padding: 0 12px;
		.tac {
			text-align: left;
		}
	}
	.tac {
		text-align: left;
	}
	.cal {
		background-color: #555555;
		border-radius: 10px;
		color: #262626;
		.tit {
			font-family: 'NotoSansKR-Light' !important;
			font-size: 14px !important;
			padding-left: 0;
			color: #fff;
		}
		strong {
			font-size: 18px !important;
			color: #fff;
			.constvalue {
				font-family: 'NotoSansKR-Light' !important;
				font-weight: 400;
				font-size: 14px !important;
				padding-left: 5px;
			}
		}
		.notice {
			font-family: 'NotoSansKR-Light' !important;
			font-size: 12px !important;
			color: #fff;
		}
		:deep(.v-image__image) {
			background-size: auto;
		}
	}
}

.btn_upload02 {
	font-size: $font_lg !important;
	font-weight: $fw_bold;
	color: $color_white;
}

.resetcalc {
	cursor: pointer;
}

.all_view {
	text-decoration: underline;
	color: #555555;
	&:hover {
		cursor: pointer;
	}
}

@media all and (max-width: 600px) {
	.click_price_button_home {
		margin: 0 !important;
	}
}
</style>
