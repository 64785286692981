<template>
	<div class="mx-0 my-0">
		<v-col
			class="pa-0 pb-2 tit04"
			cols="12"
			align="left"
		>
			예상 시공 견적가를 알려드립니다.
		</v-col>

		<v-col
			class="pa-0"
			cols="12"
			style="min-height: 225px"
		>
			<HomeInteriorPriceReceiptnew />
		</v-col>
		<!-- 등록영역 -->
		<div class="input_area">
			<div
				class="mt-4 part tit"
				align="left"
			>
				간편상담 신청서
			</div>
			<v-col
				cols="12"
				class="pa-0 table"
			>
				<div class="">
					<!-- <v-row
						v-if="selectInteriorList"
						class="ma-0 mb-6"
					>
						<v-col
							class="pa-0 th"
							cols="12"
							sm="3"
							lg="2"
							align="start"
							align-self="start"
						>
							<p>상담인테리어점</p>
						</v-col>
						<v-col
							class="pa-0 td"
							cols="12"
							sm="9"
							lg="10"
							align="start"
							align-self="start"
						>
							{{ selectInteriorList }}
						</v-col>
					</v-row> -->

					<!-- <v-row
						v-if="selectContents"
						class="ma-0 mb-6"
					>
						<v-col
							class="pa-0 th"
							cols="12"
							sm="3"
							lg="2"
							align="start"
							align-self="start"
						>
							<p>상담콘텐츠</p>
						</v-col>
						<v-col
							class="pa-0 td"
							cols="12"
							sm="9"
							lg="10"
							align="start"
							align-self="start"
						>
							{{ selectContents }}
						</v-col>
					</v-row> -->

					<!-- <v-row
						v-if="selectProductName"
						class="ma-0 mb-6"
					>
						<v-col
							class="pa-0 th"
							cols="12"
							sm="3"
							lg="2"
							align="start"
							align-self="start"
						>
							<p>상담제품</p>
						</v-col>
						<v-col
							class="pa-0 td"
							cols="12"
							sm="9"
							lg="10"
							align="start"
							align-self="start"
						>
							{{ selectProductName }}
						</v-col>
					</v-row> -->

					<v-row class="ma-0 mb-2">
						<!-- <v-col
							class="pa-0 mb-3 mb-lg-0 th"
							cols="12"
							lg="2"
							align="start"
						>
							<p>시공범위</p>
							<span>(*중복가능)</span>
						</v-col> -->
						<v-col
							class="pa-0 td"
							cols="12"
							lg="10"
							align="start"
						>
							<CommonButtonsToggleButton03
								v-if="addQuestionItems.length"
								v-model="addQuestionModel"
								:items="addQuestionItems"
							/>
						</v-col>
					</v-row>
					<!-- <v-row class="ma-0 mb-6 interior_area pa-4">
						<v-col
							class="pa-0 th mb-2"
							cols="12"
							sm="12"
							lg="12"
							align="start"
							align-self="start"
						>
							<p class="tit mb-2">상담인테리어점</p>
						</v-col>
						<v-col
							class="pa-0 td nearArea"
							cols="12"
							sm="12"
							lg="12"
							align="start"
							align-self="start"
						>
							<div
								v-if="nexttointerior.length !== 0"
								class="exist"
							>
								<span
									v-for="(item, idx) in nearinterior"
									:key="idx"
								>
									<span class="nearItem">{{ item.business_name }}</span>									
								</span>
							</div>
							<div
								v-else
								class="disexist"
							>
								<span>주변에 인테리어점이 없습니다.</span>
							</div>
						</v-col>
					</v-row> -->
					<v-row class="ma-0 mb-6 interior_area pa-4">
						<v-col
							class="pa-0 th"
							cols="12"
							sm="12"
							lg="12"
							align="start"
							align-self="start"
						>
							<p
								class="tit mb-2 d-flex align-items-center"
								style="align-items: center; position: relative"
							>
								상담 인테리어점
								<v-img
									class="ml-1 tooltipOrigin"
									max-width="16"
									height="16"
									eager
									:src="require('@/assets/img/estimate/ico_estimate_tootip.svg')"
									@click="tooltipPop"
								/>
								<span
									v-if="tooltip"
									class="ballon"
								>
									<span>
										고객님의 접속 위치를 기반하여 가장 가까운 인테리어점 3곳을 추천드립니다.
										<em>
											<v-img
												class="ml-1 tooltipOrigin"
												max-width="10"
												height="10"
												eager
												:src="require('@/assets/img/estimate/ico_tooltip_close.svg')"
												@click="closetooltipPop" /></em
									></span>
								</span>
							</p>
						</v-col>
						<v-col
							ref="elementWithClass"
							cols="12"
							class="pt-2 px-0 getLocations w-full"
						>
							<CommonButtonsButton07
								name="위치 기반 추천"
								:color="getLocationAuth === true && nexttointerior.length !== 0 ? `${'#00CE7D'}` : `${'#777777'}`"
								class-name="edge_4 fw_bold font_16 btn_upload02 pa-0 w-full d-block"
								height="36"
								radius="4"
								:ico="'estimate/ico_find_store'"
								@click="findInterior"
							/>
						</v-col>
						<v-col
							class="pa-0 td nearArea"
							cols="12"
							sm="12"
							lg="12"
							align="start"
							align-self="start"
						>
							<v-expansion-panels
								v-model="panel"
								flat
								multiple
								class="panel"
							>
								<v-expansion-panel v-model="panel">
									<v-expansion-panel-header>
										<span class="innerTitle"> 추천 인테리어점 </span>
									</v-expansion-panel-header>
									<v-expansion-panel-content class=""
										><div>
											<div
												v-if="nexttointerior.length !== 0 && getLocationAuth === true"
												class="exist"
											>
												<span v-if="nearinterior.length === 0">
													<span style="color: #777">위치 기반 추천 버튼을 눌러주세요. </span>
												</span>
												<span
													v-for="(item, idx) in nearinterior"
													:key="idx"
													class=""
													style="width: 100%"
												>
													<span
														v-if="askThatInterior !== true"
														class="nearItem"
													>
														<span class="">
															{{ item.business_name }}
															<span class="nearAddr"> {{ `${item.addr_cut}` }}</span>
														</span>
														<v-img
															class="ml-1 cancelButton"
															max-width="16"
															height="16"
															eager
															:src="
																require('@/assets/img/home/interior/ico_interiorstor_close.svg')
															"
															@click="selectCancel(item, idx)"
														/>
													</span>
												</span>
											</div>
											<div
												v-else-if="getLocationAuth === false"
												class="disexist"
											>
												<span style="color: #777"
													>위치 기반 추천 서비스 이용을 위해 <br />브라우저 위치 정보 접근 권한 허용 후
													새로고침 해주세요.</span
												>
											</div>
											<div
												v-else
												class="disexist"
											>
												<span>주변에 인테리어점이 없습니다.</span>
											</div>
										</div></v-expansion-panel-content
									>
								</v-expansion-panel>
							</v-expansion-panels>
						</v-col>
					</v-row>

					<v-row class="ma-0 mb-6">
						<v-col
							class="pa-0 mb-3 mb-lg-0 th"
							cols="12"
							lg="12"
							align="start"
						>
							<p>문의 사항</p>
						</v-col>
						<v-col
							class="pa-0 td"
							cols="12"
							lg="12"
							align="start"
						>
							<v-col class="pa-0 questCont">
								<v-textarea
									v-model="askContents"
									outlined
									hide-details
									height="90"
									:placeholder="askContents ? askContents : '문의사항을 입력해주세요.'"
									:maxlength="maxLength"
									@input="checkLength"
								/>
							</v-col>
						</v-col>
					</v-row>

					<!-- <v-row class="ma-0">
						<v-col
							class="pa-0 mb-1 th"
							cols="12"
							lg="12"
							align="start"
							align-self="center"
						>
							<p class="required">개인정보 처리약관</p>
						</v-col>
						<v-col
							class="pa-0 td"
							cols="12"
							lg="12"
							align="start"
							align-self="center"
						>
							<div class="btn_privacy">
								<div class="pa-0 pb-2">
									<CommonCheckboxsCheckbox02
										v-model="privateTermsCheck"
										sub-text="상담 예약 및"
										allowlink="개인정보 수집"
										href="/site/userInfo"
										text="이용 동의"
										class="pa-0 ma-0 pr-5"
									/>
								</div>
							</div>
						</v-col>
					</v-row> -->
				</div>
			</v-col>
		</div>
		<!-- 로그인 되어 있지 않은경우 본인인증 -->
		<!-- <v-row
			v-if="AUTH_GET_USER_AUTH === 'GUEST'"
			class="ma-0 mt-2"
		> -->
		<!-- <v-row class="ma-0 mt-2">
			<v-col
				class="pa-0 pb-3 pb-md-0 th"
				cols="12"
				md="12"
				align="start"
			>
				<p class="required">본인인증</p>
			</v-col>

			<v-col
				class="pa-0 td"
				cols="12"
				md="12"
				align="start"
			>
				<v-col
					cols="12"
					sm="12"
					md="12"
					class="pa-0 selfAuth"
				>
					<v-col class="pa-0 mb-2">
						<CommonInputsInput04
							v-model="name"
							placeholder="이름"
						/>
					</v-col>
					<v-row class="ma-0 mb-2">
						<v-col
							class="pa-0"
							cols="7"
							sm="8"
							md="7"
						>
							<div class="pr-1">
								<CommonInputsInput04
									v-model="hand"
									rule-name="tel"
									placeholder="휴대폰 번호(- 없이 입력)"
								/>
							</div>
						</v-col>
						<v-col
							class="pa-0"
							cols="5"
							sm="4"
							md="5"
						>
							<div class="pl-1">
								<CommonButtonsButton01
									:disabled="hand === null"
									name="인증번호 발송"
									:color="smsNumberCheck ? 'primary' : '#5f6062'"
									class-name="regular font_12 edge5 px-0"
									height="40"
									@click="checkSms"
								/>
							</div>
						</v-col>
						<v-col
							cols="12"
							class="pa-0 pt-2 pr-1"
							align="end"
						>
							<p v-if="smsNumberCheck">인증되었습니다.</p>
						</v-col>
						<CommonLoaderLine01 v-if="telLoading" />
					</v-row>

					<div v-if="smsNumberModel">
						<v-row class="ma-0 pt-3">
							<v-col
								class="pa-0"
								cols="7"
								sm="8"
								md="7"
							>
								<div class="pr-1">
									<CommonInputsInput02
										v-model="smsNum"
										type="number"
										placeholder="인증번호를 입력해주세요."
									/>
								</div>
							</v-col>
							<v-col
								class="pa-0"
								cols="5"
								sm="4"
								md="5"
							>
								<div class="pl-1">
									<CommonButtonsButton01
										name="인증 확인"
										color="#42883d"
										class-name="regular font_12 edge5 px-0"
										height="40"
										@click="confirmSmsNumber"
									/>
								</div>
								<v-col
									cols="12"
									class="pa-0 pt-2 pr-1 pb-3"
									align="end"
								>
									{{ sms_check_timer }}
								</v-col>
							</v-col>
						</v-row>
					</div>
				</v-col>
			</v-col>
		</v-row> -->
		<!-- 로그인 되어 있지 않은경우 본인인증 -->
		<v-col
			class="pa-0 mb-3 mb-lg-0 th"
			cols="12"
			lg="12"
			align="start"
		>
			<p class="color_gray_8 required">{{ AUTH_GET_USER_AUTH === 'GUEST' ? '본인 인증' : '신청자 정보' }}</p>
		</v-col>
		<div v-if="AUTH_GET_USER_AUTH === 'GUEST'">
			<button
				v-if="!selfAuthToggle"
				class="selfAuthBt px-0 mb-4"
				@click="selfAuthTogglebt"
			>
				본인인증하기
			</button>
			<v-row
				v-if="selfAuthToggle"
				class="ma-0 mt-2"
			>
				<!-- :style="
					smsNumberModel
						? 'height: 275px; display: flex; justify-content: start; align-items: start'
						: smsNumberModel
						? 'height: 205px; display: flex; justify-content: start; align-items: start'
						: 'height: 185px; display: flex; justify-content: start; align-items: start'
				" -->
				<v-col
					class="pa-0 td"
					cols="12"
					md="12"
					align="start"
				>
					<v-col
						cols="12"
						sm="12"
						md="12"
						class="pa-0 selfAuth"
						><span class="color_gray_8">이름</span>
						<v-col class="pa-0 mb-2">
							<CommonInputsInput04
								ref="inputName"
								v-model="name"
								rule-name="name"
								placeholder="이름"
								:readonly="smsNumberCheck"
								:clearable="!selfAuthToggle"
								@checkInvalid="checkName"
							/>
						</v-col>
						<span class="color_gray_8">휴대폰 번호</span>
						<v-row class="ma-0 mb-2">
							<v-col
								class="pa-0"
								cols="7"
								sm="8"
								md="7"
							>
								<div class="pr-1">
									<CommonInputsInput04
										ref="inputHand"
										v-model="hand"
										rule-name="tel"
										placeholder="휴대폰 번호(- 없이 입력)"
										:readonly="smsNumberCheck"
										:clearable="!selfAuthToggle"
										@checkInvalid="checkNum"
									/>
								</div>
							</v-col>
							<v-col
								class="pa-0"
								cols="5"
								sm="4"
								md="5"
							>
								<div class="pl-1">
									<CommonButtonsButton01
										:disabled="nameInvalid === false || numberInvalid === false"
										name="인증번호 발송"
										:color="smsNumberCheck ? 'primary' : '#262626'"
										class-name="regular font_12 edge5 pa-0"
										height="40"
										@click="checkSms"
									/>
								</div>
							</v-col>
							<v-row class="ma-0">
								<v-col
									cols="8"
									class="pa-0 pt-2"
									align-self="center"
									align="start"
								>
									<p
										v-if="smsNumberCheck"
										style="color: #00ce7d"
									>
										인증이 완료되었습니다.
									</p>
								</v-col>
								<v-col
									class="pa-0 pt-1"
									cols="4"
									align-self="center"
									align="end"
								>
									<v-btn
										v-if="smsNumberCheck"
										class="pa-0 reset_btn"
										text
										@click="resetSms"
									>
										<v-icon
											color="#9e9e9e"
											small
											>mdi-refresh</v-icon
										>
										초기화
									</v-btn>
								</v-col>
							</v-row>
							<CommonLoaderLine01 v-if="telLoading" />
						</v-row>

						<div v-if="smsNumberModel">
							<v-row class="ma-0 pt-3">
								<v-col
									class="pa-0"
									cols="7"
									sm="8"
									md="7"
								>
									<div class="pr-1">
										<CommonInputsInput02
											ref="inputSmsNum"
											v-model="smsNum"
											type="number"
											placeholder="인증번호를 입력해주세요."
										/>
									</div>
								</v-col>
								<v-col
									class="pa-0"
									cols="5"
									sm="4"
									md="5"
								>
									<div class="pl-1">
										<CommonButtonsButton01
											name="인증 확인"
											color="#262626"
											class-name="regular font_12 edge5 pa-0"
											height="40"
											@click="confirmSmsNumber"
										/>
									</div>
									<v-col
										cols="12"
										class="pa-0 pt-2 pr-1 pb-3"
										align="end"
									>
										{{ sms_check_timer }}
									</v-col>
								</v-col>
							</v-row>
						</div>
					</v-col>
				</v-col>
				<v-row class="ma-0">
					<v-col
						class="pa-0 td"
						cols="12"
						lg="12"
						align="start"
						align-self="center"
					>
						<div class="btn_privacy">
							<div class="pa-0 pb-2">
								<CommonCheckboxsCheckbox02
									v-model="privateTermsCheck"
									sub-text="상담 예약 및"
									allowlink="개인정보 수집"
									href="/site/userInfo"
									text="이용 동의"
									class="pa-0 ma-0 pr-5"
								/>
							</div>
							<!-- <div>
						<v-btn
							text
							@click="common_openPrivate"
						>
							개인정보처리방침확인
						</v-btn>
					</div> -->
						</div>
					</v-col>
					<!-- <v-col
						class="pa-0"
						cols="12"
					>
						<CommonButtonsButton02
							:disabled="!registerCheck"
							name="완료"
							color="#00582c"
							class-name="btn_full"
							@click="apiCallRegister"
						/>
					</v-col> -->
				</v-row>
			</v-row>
		</div>
		<div v-else>
			<v-row class="ma-0 mt-0">
				<!-- 로그인 되어 있는 경우 본인인증 -->
				<v-col
					class="pa-0 td"
					cols="12"
					md="12"
					align="start"
				>
					<HomeCustomItemRegisterBasicInfo
						v-if="AUTH_GET_USER.name"
						title="이름"
						:submit="2"
						:text="AUTH_GET_USER.name"
					/>
					<HomeCustomItemRegisterBasicInfo
						v-if="AUTH_GET_USER.hand"
						title="휴대폰"
						:submit="1"
						:text="common_number_filter(AUTH_GET_USER.hand)"
					/>
					<HomeCustomItemRegisterBasicInfo
						v-if="AUTH_GET_USER.name"
						title="이메일"
						:submit="2"
						:text="AUTH_GET_USER.emailaddr"
					/>
				</v-col>
				<v-row class="ma-0 mt-2">
					<v-col
						class="pa-0 td"
						cols="12"
						lg="12"
						align="start"
						align-self="center"
					>
						<div class="btn_privacy">
							<div class="pa-0 pb-2">
								<CommonCheckboxsCheckbox02
									v-model="privateTermsCheck"
									sub-text="상담 예약 및"
									allowlink="개인정보 수집"
									href="/site/userInfo"
									text="이용 동의"
									class="pa-0 ma-0 pr-5"
								/>
							</div>
							<!-- <div>
						<v-btn
							text
							@click="common_openPrivate"
						>
							개인정보처리방침확인
						</v-btn>
					</div> -->
						</div>
					</v-col>
					<!-- <v-col
						class="pa-0"
						cols="12"
					>
						<CommonButtonsButton02
							:disabled="!registerCheck"
							name="완료"
							color="#00582c"
							height="42"
							class-name="btn_full"
							@click="apiCallRegister"
						/>
					</v-col> -->
				</v-row>
			</v-row>
		</div>

		<!-- <input type="radio" v-model="model.radioValues" value="아파트">
        <input type="radio" v-model="model.radioValues" value="빌라">
        <input type="radio" v-model="model.radioValues" value="주택"> -->
	</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import kakao_post from '@/mixins/kakao_post'
import ad_kakao from '@/mixins/ad/kakao'
import ad_naver from '@/mixins/ad/naver'
import ad_mobion from '@/mixins/ad/mobion'
//import localStorage from '@/utils/localStorage'
import common from '@/mixins/common'
import sms from '@/mixins/sms'
//import kakao_map from '@/mixins/kakao_map'
import geolocation from '@/mixins/geolocation'
import geolocation_current from '@/mixins/geolocation_current'

export default {
	name: 'GreenDongwhaClientRepo04Form',
	mixins: [kakao_post, ad_kakao, ad_naver, ad_mobion, common, sms, geolocation, geolocation_current],

	props: {
		currentpage: {
			Type: Number,
			require: true,
		},
		finaldata: {
			Type: Object,
			default: '',
		},
		homeTypeItems1: {
			Type: Array,
			default: '',
		},
		homeTypeItems2: {
			Type: Array,
			default: '',
		},
		homeTypeItems3: {
			Type: Array,
			default: '',
		},
		homeTypeItems4: {
			Type: Array,
			default: '',
		},
		reset: {
			Type: Boolean,
			default: '',
		},
	},

	data() {
		return {
			newmodel: [],
			askContents: '',
			privateTermsCheck: false,
			confirm: false,
			//finalconfirm: false,
			addQuestionModel: [],
			addQuestionItems: [],
			// sms check
			name: null,
			hand: null,
			smsNum: null,
			// sms
			smsNumberCheck: null,
			smsNumberModel: false,
			smsCheckId: null,
			telLoading: false,
			// temp
			nearinterior: [],
			nexttointerior: [],
			limit: 3,
			nexttointeriorCheck: 0,
			searchNextto: false,
			panel: [0],
			askThatInterior: false,
			//tooltip
			tooltip: false,
			// selfauth
			selfAuthToggle: false,
			// pickinterior
			pickThisInterior: [],
			allow: {
				content: null,
				est_cost: null,
				est_cost_min: null,
				est_cost_max: null,
				interiorStores: [],
			},
			// 최대 글자 수
			maxLength: 200,
			// 위치 허용 여부
			getLocationAuth: null,
			// 이름 유효성
			nameInvalid: false,
			numberInvalid: false,
			// width 가변값
			customWidth: null,
		}
	},

	created() {
		//this.askContents = `${this.homeTypeItems1[this.finaldata.type_1].title} / ${this.finaldata.widthModel.text} / ${this.finaldata.roomModel.text} / ${this.homeTypeItems2[this.finaldata.type_2].title} / ${this.homeTypeItems3[this.finaldata.type_3].title} / ${this.finaldata.productModel.text} / ${this.homeTypeItems4[this.finaldata.type_4].title}`
		this.allow.est_cost = this.HOME_GET_INTERIOR_ESTIMATE_SIMPLE_PRICE?.est_cost || null
		this.allow.est_cost_min = this.HOME_GET_INTERIOR_ESTIMATE_SIMPLE_PRICE?.est_cost_min || null
		this.allow.est_cost_max = this.HOME_GET_INTERIOR_ESTIMATE_SIMPLE_PRICE?.est_cost_max || null
		this.allow.interiorStores = this.HOME_GET_INTERIOR_GET_INTERIOR_ITEMS || null

		//this.updateWidth()
	},

	computed: {
		...mapGetters([
			'AUTH_GET_USER',
			'AUTH_GET_USER_AUTH',
			'ADMIN_GET_PRODUCT_CATEGORY_TYPE_ITEMS',
			'HOME_GET_INTERIOR_SELECT_INTERIOR',
			'HOME_GET_INTERIOR_AUTO_SELECT_INTERIOR',
			'HOME_GET_INTERIOR_SELECT_PRICE',
			'HOME_GET_INTERIOR_SELECT_PRODUCT',
			'HOME_GET_INTERIOR_GET_INTERIOR_ITEMS',
			'HOME_GET_GET_PRODUCT_ITEMS',
			'ADMIN_GET_PRODUCT_CATEGORY_BRAND_ITEMS',
			'HOME_GET_INTERIOR_ESTIMATE_SIMPLE_PRICE',
		]),
		interiorItems() {
			return this.HOME_GET_INTERIOR_GET_INTERIOR_ITEMS || []
		},
		filterInteriorItems() {
			return this.interiorItems
		},
		nearinteriors() {
			// 동화본사 제거 후 3업체 추천
			return this.nexttointerior.filter(obj => obj.corporate_no !== '1378102333').slice(0, this.limit)
		},
		registerCheck() {
			// 인테리어 점 선택
			//if (this.HOME_GET_INTERIOR_SELECT_INTERIOR === null) return false

			const checkList = ['privateTermsCheck', 'nexttointeriorCheck', 'smsNumberCheck', 'nearinterior']
			// GUEST일 경우 본인인증 추가체크
			//if (this.AUTH_GET_USER_AUTH === 'GUEST') checkList.push('smsNumberCheck')

			for (let i of checkList) {
				if (this[i] === '' || this[i] === null || this[i] === undefined || this[i] === false || this[i].length === 0)
					return false
			}
			return true
		},
		registerChecked() {
			// if (this.currentpage === 4) {
			// 	return (this.askContents = `${this.homeTypeItems1[this.finaldata.type_1].title} / ${
			// 		this.finaldata.widthModel.text
			// 	} / ${this.finaldata.roomModel.text} / ${this.homeTypeItems2[this.finaldata.type_2].title} / ${
			// 		this.homeTypeItems3[this.finaldata.type_3].title
			// 	} / ${this.finaldata.productModel.text} / ${this.homeTypeItems4[this.finaldata.type_4].title}`)
			// } else {
			// 	return (this.askContents = '')
			// }
		},
		homeTypeItems_4_update() {
			return {
				content: this.askContents || null,
				est_cost: this.HOME_GET_INTERIOR_ESTIMATE_SIMPLE_PRICE?.est_cost || null,
				est_cost_min: this.HOME_GET_INTERIOR_ESTIMATE_SIMPLE_PRICE?.est_cost_min || null,
				est_cost_max: this.HOME_GET_INTERIOR_ESTIMATE_SIMPLE_PRICE?.est_cost_max || null,
				interiorStores: this.HOME_GET_INTERIOR_GET_INTERIOR_ITEMS || null,
			}
		},
		checkPrivate() {
			return this.privateTermsCheck
		},
		remainingChars() {
			return this.maxLength - this.askContents.length // 남은 글자 수를 계산
		},
	},
	watch: {
		checkPrivate(newval) {
			this.$emit('checkPrivate', newval)
		},
		askContents(newVal, oldVal) {
			this.allow.content = newVal
		},
		updatedata(newval, oldval) {
			if (newval) {
				this.$emit('formevent', newval, 'form4')
			}
		},
		homeTypeItems_4_update(newval, oldval) {
			if (newval) {
				if (Object.keys(newval)[0] === 'content') {
					this.$emit('hometype1', newval, 'form4')
				}
				if (Object.keys(newval)[0] === 'est_cost') {
					this.$emit('hometype1', newval, 'form4')
				}
				if (Object.keys(newval)[0] === 'est_cost_min') {
					this.$emit('hometype1', newval, 'form4')
				}
				if (Object.keys(newval)[0] === 'est_cost_max') {
					this.$emit('hometype1', newval, 'form4')
				}
				if (Object.keys(newval)[0] === 'interiorStores') {
					this.$emit('hometype1', newval, 'form4')
				}
			}
		},
		// kakao_map_status(val) {
		// 	// kakao map start
		// 	if (val) this.startLocation()
		// },
		// registerChecked(val) {
		// 	if (val) {
		// 		console.log('z')
		// 	}
		// },
		// registerCheck(val) {
		// 	alert(val)
		// 	if (val) {
		// 		this.$emit('customquote', val)
		// 	}
		// },
		reset(newvalue, oldvalue) {
			//alert('zz')
			if (newvalue) {
				this.privateTermsCheck = false
				this.smsCheckId = null
				this.smsNum = null
				this.smsNumberCheck = false
				this.smsNumberModel = false
				this.sms_id = null
				this.telLoading = false
				this.timer = 180
				this.timer_model = false

				this.tooltip = false
				this.askContents = ''
				this.selfAuthToggle = false
				this.name = null
				this.hand = null
			}
			// else {
			// 	this.privateTermsCheck = false
			// 	this.smsCheckId = null
			// 	this.smsNum = null
			// 	this.smsNumberCheck = null
			// 	this.smsNumberModel = false
			// 	;(this.name = null), (this.hand = null)
			// 	this.sms_id = null
			// 	this.telLoading = false
			// 	this.timer = 180
			// 	this.timer_model = false
			// }
		},
		nearinterior(newval, oldval) {
			this.allow.interiorStores = this.nearinterior
			this.$emit('interiorItems', this.nearinterior)
			this.HOME_MU_INTERIOR_GET_INTERIOR_ITEMS(this.nearinterior) || null
			this.HOME_MU_CURRENT_ESTIMATE_CONTENT_INTERIORSELECTAUTO(newval) || null
		},
		nearinteriors(newval, oldval) {
			return (this.nearinterior = newval)
		},
		// askThatInterior(newval, oldval) {
		// 	if (newval === true) {
		// 		//this.nearinterior = []
		// 		this.HOME_MU_INTERIOR_SELECT_INTERIOR(this.pickThisInterior[0])
		// 		this.HOME_MU_CURRENT_ESTIMATE_CONTENT_INTERIORSELECTAUTO(this.pickThisInterior)
		// 		// this.HOME_MU_INTERIOR_SELECT_INTERIOR(newval) || null
		// 	}
		// },
		smsNumberCheck(newval) {
			if (newval) {
				const param = {
					name: this.name,
					hand: this.hand,
				}
				this.$emit('userInfo', param)
			}
		},
	},

	mounted() {
		////this.startLocation()
		//this.baseInfo()
		//this.findInterior()
		// if (navigator.permissions) {
		// 	navigator.permissions.query({ name: 'geolocation' }).then(permissionStatus => {
		// 		if (permissionStatus.state === 'granted') {
		// 			this.getLocationAuth = true
		// 			this.baseInfo()
		// 			this.findInterior()
		// 		} else {
		// 			this.getLocationAuth = false
		// 		}
		// 	})
		// } else {
		// 	console.error('Permissions API가 지원되지 않습니다.')
		// 	this.errorMessage = '브라우저가 위치 접근 권한을 확인할 수 없습니다.'
		// }

		this.checkLocationPermission()
		// 컴포넌트가 마운트된 후에 초기 계산을 수행합니다.
		//this.updateWidth()

		// 창 크기가 변경될 때마다 Computed 속성을 다시 계산합니다.
		//window.addEventListener('resize', this.updateWidth)
	},
	destroyed() {
		// 컴포넌트가 파괴되기 전에 이벤트 리스너를 제거합니다.
		//window.removeEventListener('resize', this.updateWidth)
	},

	methods: {
		...mapMutations([
			'HOME_MU_CURRENT_ESTIMATE_CONTENT_ITEM',
			'HOME_MU_CURRENT_ESTIMATE_CONTENT_PAGETYPE',
			'HOME_MU_CURRENT_ESTIMATE_CONTENT_INTERIORSELECTAUTO',
			'HOME_MU_CURRENT_ESTIMATE_CONTENT_ASKCONTENT',
			'HOME_MU_INTERIOR_SELECT_PRODUCT',
			'HOME_MU_INTERIOR_SELECT_INTERIOR',
			'HOME_MU_INTERIOR_AUTO_SELECT_INTERIOR',
			'HOME_MU_CURRENT_ESTIMATE_CONTENT_USERINFO',
			'HOME_MU_INTERIOR_GET_INTERIOR_ITEMS',
			'HOME_MU_CURRENT_ESTIMATE_CONTENT_PRICE',
		]),
		...mapActions([
			'HOME_ACT_INTERIOR_GET_INTERIOR_ITEMS',
			'HOME_ACT_INTERIOR_GET_EXPERT_ITEMS',
			'ADMIN_ACT_PRODUCT_GET_PUBLIC_CODE_ITEMS',
			'HOME_ACT_AUTO_INTERIOR_SELECT',
			'HOME_ACT_INTERIOR_SELECT',
			'HOME_ACT_INTERIOR_ESTIMATE_SIMPLE_PRICE',
		]),
		// updateWidth() {
		// 	this.customWidth = String(this.$refs.elementWithClass.offsetWidth)
		// },
		checkName(val) {
			if (val === true) {
				this.nameInvalid = true
			} else {
				this.nameInvalid = false
			}
		},
		checkNum(val) {
			if (val === true) {
				this.numberInvalid = true
			} else {
				this.numberInvalid = false
			}
		},

		checkLocationPermission() {
			if ('permissions' in navigator) {
				navigator.permissions
					.query({ name: 'geolocation' })
					.then(permissionStatus => {
						if (permissionStatus.state === 'granted') {
							this.getLocationAuth = true
							this.baseInfo()
							this.findInterior()
						} else {
							this.getLocationAuth = false
							console.log('Location permission denied')
							// 위치 권한을 요청하는 UI를 표시하는 등의 작업 수행
							this.NavgetLocation()
						}
					})
					.catch(err => {
						console.error('Error in querying permissions:', err)
						alert('에러')
						this.getLocationAuth = false
					})
			} else if ('geolocation' in navigator) {
				this.NavgetLocation()
			} else {
				console.error('Geolocation API not supported')
				// 브라우저가 Geolocation API를 지원하지 않는 경우 처리
			}
		},
		NavgetLocation() {
			navigator.geolocation.getCurrentPosition(
				position => {
					console.log('Latitude:', position.coords.latitude, 'Longitude:', position.coords.longitude)
					// 위치 정보를 가져오는 데 성공한 경우 처리
					this.getLocationAuth = true
					this.baseInfo()
					this.findInterior()
				},
				error => {
					console.error('Error getting location:', error)
					// 위치 정보를 가져오는 데 실패한 경우 처리
					this.getLocationAuth = false
					// this.baseInfo()
					// this.findInterior()
				},
			)
		},
		// checkNum(val) {
		// 	this.numberInvalid = val
		// },
		baseInfo() {
			this.HOME_MU_CURRENT_ESTIMATE_CONTENT_ITEM(this.items)
			if (this.nearinterior.length > 0) {
				this.HOME_MU_INTERIOR_GET_INTERIOR_ITEMS(this.nearinterior)
			}
		},
		async checkSms() {
			if (this.checkName && this.checkNum) {
				this.telLoading = true
				let params = {
					type: this.sms_check_type[7],
					hand: this.hand,
					name: this.name,
					login_id: this.email,
				}
				const check = await this.sms_call(params)
				if (check) {
					this.telLoading = false
					this.smsNumberModel = true
				}
			} else {
				alert('이름 또는 휴대폰번호를 잘못 입력했습니다. 입력하신 내용을 다시 확인해주세요.')
			}
		},
		async confirmSmsNumber() {
			const check = await this.sms_call_check(this.hand, this.smsNum)
			if (check) {
				this.smsNumberCheck = true
				this.smsNumberModel = false
			}
		},
		checkLength() {
			if (this.askContents.length > this.maxLength) {
				this.askContents = this.askContents.substring(0, this.maxLength) // 글자 수가 최대 길이를 초과하는 경우 자르기
				return this.$toastr.error('최대 글자수는 200자입니다', { timeOut: 500 })
			}
		},
		confirmSubmit() {
			this.confirm = false
			this.$router.go(-1)
		},
		startLocation() {
			// this.kakao_map_pan_to(this.geolocation_position);
			// this.kakao_map_create_my_location_marker(
			// 	this.kakao_map_code_transform(this.geolocation_position),
			// 	{
			// 	place_name: "내위치",
			// 	lat: this.geolocation_position.lat,
			// 	lng: this.geolocation_position.lng,
			// 	}
			// );
			//this.getApiExpertItems(this.geolocation_position);
			this.getCurrentLocation()
			this.HOME_MU_INTERIOR_AUTO_SELECT_INTERIOR(this.nearinteriors)
		},
		getCurrentLocation() {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(
					position => {
						const latitude = position.coords.latitude
						const longitude = position.coords.longitude
						console.log(`현재 위치 - 위도: ${latitude}, 경도: ${longitude}`)
						this.currentmap.lat = latitude
						this.currentmap.lng = longitude
						// 여기에서 위도와 경도 값을 활용할 수 있습니다.
						this.getApiInteriorItems(this.currentmap)
					},
					error => {
						console.error(`위치 정보를 가져오는 중 오류가 발생했습니다: ${error.message}`)
					},
				)
			} else {
				console.error('브라우저가 Geolocation API를 지원하지 않습니다.')
			}
		},
		// getCurrentLocation() {
		// 	if (navigator.geolocation) {
		// 		navigator.geolocation.getCurrentPosition(
		// 			position => {
		// 				const latitude = position.coords.latitude
		// 				const longitude = position.coords.longitude
		// 				console.log(`현재 위치 - 위도: ${latitude}, 경도: ${longitude}`)
		// 				this.currentmap.lat = latitude
		// 				this.currentmap.lng = longitude
		// 				// 여기에서 위도와 경도 값을 활용할 수 있습니다.
		// 				this.getApiInteriorItems(this.currentmap)
		// 			},
		// 			error => {
		// 				console.error(`위치 정보를 가져오는 중 오류가 발생했습니다: ${error.message}`)
		// 			},
		// 		)
		// 	} else {
		// 		console.error('브라우저가 Geolocation API를 지원하지 않습니다.')
		// 	}
		// },
		// async getApiInteriorItems(center) {
		// 	console.log(center)
		// 	const params = {
		// 		//latitude_x: 37.5980032,
		// 		//longitude_y: 126.6876416,
		// 		latitude_x: center.lat,
		// 		longitude_y: center.lng,
		// 		build_range: '',
		// 		meter_range: 6000,
		// 	}

		// 	console.log('params', params)

		// 	await this.HOME_ACT_INTERIOR_GET_INTERIOR_ITEMS(params).then(res => {
		// 		// this.interiorItems = res
		// 		// if (res.length)
		// 		// this.createMarker(this.filterInteriorItems);
		// 		console.log('이건가/', res)
		// 		if (res.length !== 0) {
		// 			this.nexttointeriorCheck = 1
		// 			this.nexttointerior = res
		// 		} else {
		// 			this.nexttointeriorCheck = 0
		// 		}
		// 	})
		// },
		// 객체를 문자열로 변환하는 함수
		// stringifyData(data) {
		// 	return JSON.stringify(data, null, 2);
		// }

		// 위에서 정의한 함수를 사용하여 문자열로 변환
		//const resultString = stringifyData(finalData);
		findInterior() {
			this.askThatInterior = false
			this.searchNextto = true
			this.startLocation()
		},
		selectCancel(item, idx) {
			// if(this.nexttointerior.includes(item.corporate_no)){
			// 	this.nexttointerior.slice(0, item.corporate_no)
			// }
			// this.nexttointerior.slice(0, this.limit).filter((_, i) => i !== idx)
			this.nearinterior = this.nearinterior.filter(v => v.corporate_no !== item.corporate_no)
		},
		tooltipPop() {
			this.tooltip ? (this.tooltip = false) : (this.tooltip = true)
		},
		closetooltipPop() {
			this.tooltip = false
		},
		// 본인인증영역 오픈
		selfAuthTogglebt() {
			this.$emit('checkSize', true)
			this.selfAuthToggle = !this.selfAuthToggle
		},
		resetSms() {
			this.smsNumberCheck = false
			this.name = null
			this.hand = null
			this.smsNum = null
			this.selfAuthToggle = false
			if (this.$refs.inputName) this.$refs.inputName.inputModel = ''
			if (this.$refs.inputHand) this.$refs.inputHand.inputModel = ''
			if (this.$refs.inputSmsNum) this.$refs.inputSmsNum.inputModel = ''
		},
	},
}
</script>

<style lang="scss" scoped>
.panel {
	.innerTitle {
		font-size: $font_normal;
		color: $color_font;
	}
	:deep(.v-expansion-panel-header) {
		padding: 0;
		background-color: transparent;
		min-height: 42px;
	}
	:deep(.v-expansion-panel-content) {
		padding: 0;
	}
	:deep(.v-expansion-panel-content__wrap) {
		padding: 0;
	}
	:deep(.v-expansion-panel) {
		background-color: transparent;
	}
}
.tit04 {
	font-size: $font_xl;
}
.input_area {
	.tit {
		font-size: $font_xl;
	}
	.interior_area {
		background: #f8f8f8;
		border-radius: 8px;
		display: flex;
		flex-direction: column;
		.nearArea {
			.exist {
				display: flex;
				flex-wrap: wrap;
				gap: 12px;
				.nearItem {
					background-color: #fff;
					border-radius: 4px;
					font-size: $font_normal;
					padding: 6px 12px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					.nearAddr {
						font-size: $font_xs;
						display: block;
						align-self: end;
						color: #9e9e9e;
					}
					.cancelButton {
						background-color: #e6e6e6;
						border-radius: 50%;
						display: flex;
						width: 16px;
						height: 16px;
						justify-content: center;
						align-items: center;
						font-style: normal;
						margin-left: 5px;
						font-size: 10px;
						cursor: pointer;
					}
				}
			}
		}

		.tit {
			font-size: $font_xl;
			letter-spacing: -0.7px;
			color: $color_font;
			font-weight: $fw_regular;
		}
	}
}

.stepprogreess {
	display: flex;
	li {
		width: 25%;
		list-style: none;
		height: 10px;
		background-color: #c0c0c0;
	}
	.active {
		background-color: #00582c;
	}
}

.questCont {
	:deep(.v-input__slot) {
		padding: 0 !important;
	}

	:deep(.v-text-field__slot) {
		margin-right: 0 !important;
		border: 1px solid #cccccc;
		border-radius: 4px;
	}

	:deep(.v-text-field__slot) {
		overflow: hidden;
		textarea {
			padding: 5px 10px !important;
			margin-top: 0px !important;
			resize: none;
		}
		/* 스크롤 바 스타일링 */
		textarea::-webkit-scrollbar {
			width: 12px;
			border-radius: 4px;
		}

		/* 스크롤 바 트랙 */
		textarea::-webkit-scrollbar-track {
			background: transparent;
		}

		/* 스크롤 바 핸들 */
		textarea::-webkit-scrollbar-thumb {
			background: #888;
		}

		/* 활성화된 스크롤 바 핸들 */
		textarea::-webkit-scrollbar-thumb:hover {
			background: #555;
		}
	}
}
.selfAuth {
	:deep(.v-input__slot) {
		padding: 0 !important;
		input {
			padding: 0 10px !important;
		}
		button {
			padding: 0px !important;
		}
	}
}

.selfAuthBt {
	background-color: #00592d;
	width: 100%;
	padding: 10px;
	border-radius: 4px;
	color: #fff;
	font-weight: 100;
	margin: 0px auto 0;
	display: block;
}

.searchWarning {
	font-size: $font_sm;
	font-weight: $fw_normal;
	color: $color_gray_5;
}

.info_tootip {
	&:hover {
		cursor: pointer;
	}
}

.required {
	font-weight: $fw_regular;
	font-size: $font_lg;
}
.tooltipOrigin {
	&:hover {
		cursor: pointer;
	}
}

.ballon {
	position: absolute;
	width: auto;
	left: auto;
	top: 30px;
	z-index: 99;
	background: #262626;
	opacity: 0.8;
	color: white;
	border-radius: 5px;
	padding: 12px 12.8px;
	font-size: $font_normal;
	font-weight: $fw_normal;
	span {
		//padding-left: 10px;
		word-wrap: break-word;
		display: block;
		position: relative;
		em {
			position: absolute;
			right: 5px;
			bottom: 0px;
		}
		// &::before {
		// 	content: '-';
		// 	width: 2px;
		// 	height: 2px;
		// 	position: absolute;
		// 	left: 0;
		// }
	}
}
.ballon:after {
	border-bottom: 10px solid #262626;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-top: 0px solid transparent;
	content: '';
	position: absolute;
	top: -9px;
	left: 118px;
}
:deep(.v-expansion-panel) {
	background: transparent !important;
}

.btn_upload02 {
	min-width: 100% !important;
}

.getLocations {
	:deep(.v-btn__content) {
		border-color: #00ce7d;
		text-align: center;
		width: 100%;
		height: 100%;
		display: flex;
		max-width: 100% !important;
		min-width: 100% !important;
	}
}

// .getLocations {
// 	:deep(.v-btn__content) {
// 		border-color: #00ce7d;
// 		text-align: center;
// 		width: 100%;
// 		height: 100%;
// 		display: block;
// 		> span {
// 			display: inline-block !important;
// 			top: 9px;
// 			position: relative;
// 			> div {
// 				display: inline-block;
// 				top: 2px;
// 			}
// 		}
// 	}
// }
</style>
