export default {
	state: {
		dialog: null,
	},
	mutations: {
		APP_MU_LOGINDIALOG(state, dialog) {
			console.log('loginState', state)
			state.dialog = dialog
			console.log('loginStateText', dialog.text)
			console.log('loginStateStatusCode', dialog.title)
			console.log('loginStatecomponentName', dialog.componentName)
			console.log('inactivedate', dialog.inactivedate)
			console.log('visitdate', dialog.visitdate)
		},
	},
	action: {},
	getters: { APP_GET_LOGINDIALOG: state => (state.dialog == null ? false : state.dialog) },
	modules: {},
}
