<template>
	<v-dialog
		v-model="dialog"
		persistent
		width="650"
	>
		<v-card class="pa-0">
			<AdminItemsTitle
				:title="mode === 'create' ? `${title} 등록` : `${title} 수정`"
				@close="$emit('close')"
			/>
			<v-col class="pa-3 pa-md-5">
				<v-container class="admin_table">
					<v-row class="ma-0 admin_table_area">
						<v-col
							cols="12"
							class="pa-0"
						>
							<AdminItemsTableItem
								v-model="text"
								:fill="true"
								title="태그명*"
								input
							/>
						</v-col>
						<v-col
							cols="12"
							class="pa-0"
						>
							<AdminItemsTableItem
								v-if="!models.image"
								v-model="image"
								placeholder="jpg / png / gif 이미지"
								:fill="true"
								title="태그 이미지*"
								file
								:usable-svg="true"
							/>
							<AdminItemsTableItem
								v-else
								title="태그 이미지*"
								:text-button="imageDeleteButton"
								:fill="true"
								@click="
									models.image = null
									image = null
								"
							/>
						</v-col>
					</v-row>
				</v-container>

				<v-row
					class="ma-0"
					align="center"
					justify="center"
				>
					<v-col
						cols="12"
						md="6"
						class="pl-0"
					>
						<v-row class="ma-0">
							<v-col cols="6">
								<v-btn
									color="error"
									block
									tile
									elevation="0"
									@click="$emit('close')"
								>
									<v-icon>clear</v-icon>
									<span class="ml-2">취소</span>
								</v-btn>
							</v-col>
							<v-col
								v-if="mode === 'create'"
								cols="6"
							>
								<v-btn
									color="primary"
									block
									tile
									elevation="0"
									:disabled="allowValue"
									@click="openConfirmDialog"
								>
									<v-icon>add</v-icon>
									<span class="ml-2">등록</span>
								</v-btn>
							</v-col>
							<v-col
								v-if="mode === 'edit'"
								cols="6"
							>
								<v-btn
									color="secondary"
									block
									tile
									elevation="0"
									:disabled="allowValue"
									@click="openConfirmDialog"
								>
									<v-icon>edit</v-icon>
									<span class="ml-2">수정</span>
								</v-btn>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-col>
		</v-card>
		<CommonConfirm
			:dialog="confirmDialog"
			:title="confirmTitle"
			:text="`${confirmTitle}하시겠습니까?`"
			@close="confirmDialog = false"
			@submit="onApiCallItem()"
		/>
		<CommonLoading v-if="loading" />
	</v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import model from '@/mixins/model'
import common from '@/mixins/common'
import admin from '@/mixins/admin'

export default {
	name: 'AdminProductTagCreate',
	mixins: [model, common, admin],
	props: ['title', 'dialog', 'mode', 'editItem'],
	data: () => ({
		models: {
			tag_value: null,
			image: null,
		},
		origin: {},
		loading: false,
		image: null,
		imageDeleteButton: {},
		text: null,
		confirmDialog: false,
		confirmTitle: null,
	}),
	computed: {
		...mapGetters(['AUTH_GET_ADMIN_USER']),
		allowValue() {
			return !this.text
		},
	},
	watch: {
		text(newV, oldV) {
			if (newV && 7 < newV.trim().length) {
				this.$nextTick(() => {
					this.text = oldV
				})
			}
		},
	},
	created() {
		if (this.editItem && this.mode === 'edit') this.startDataMapping()
	},
	mounted() {},
	destroyed() {
		this.resetData()
	},
	methods: {
		...mapActions(['ADMIN_ACT_PRODUCT_TAG_ITEMS']),
		startDataMapping() {
			this.text = this.editItem.tag_value
			this.models = {
				tag_value: this.editItem.tag_value,
				image: this.editItem.image,
			}
			if (this.editItem.image) {
				this.image = this.editItem.image
				this.imageDeleteButton = {
					text: this.editItem.image,
					button: {
						text: '이미지 삭제',
						color: 'primary',
					},
				}
			} else {
				this.image = null
				this.models.image = null
			}
			this.origin = JSON.parse(JSON.stringify(this.models))
		},
		resetData() {
			this.models = {
				tag_value: null,
				image: null,
			}
			this.image = null
			this.origin = {}
			this.imageDeleteButton = {}
			this.text = null
			this.loading = false
		},
		openConfirmDialog() {
			this.confirmTitle = this.mode === 'edit' ? '수정' : '등록'
			this.confirmDialog = true
		},
		async onApiCallItem() {
			this.loading = true

			const params = {
				tag_value: this.text,
			}
			if (this.image && this.image !== this.editItem.image) params.tagimage = this.image

			// 수정일 경우
			if (this.mode === 'edit') {
				params.tag_id = this.editItem.id
				if (this.image !== this.editItem.image && this.editItem.image) params.tagimage_url = this.editItem.image
			}

			console.log('parmas', params)

			try {
				const items = { params: params, method: this.mode }
				await this.ADMIN_ACT_PRODUCT_TAG_ITEMS(items)
				this.loading = false
				this.$emit('close')
			} catch (e) {
				console.log(e)
				this.loading = false
			}
		},
	},
}
</script>

<style scoped lang="scss"></style>
