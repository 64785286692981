var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","width":"1000"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pa-0"},[_c('AdminItemsTitle',{attrs:{"title":_vm.mode === 'create' ? '팝업 등록' : '팝업 수정'},on:{"close":function($event){return _vm.$emit('close')}}}),_c('v-col',{staticClass:"pa-3 pa-md-5"},[_c('v-container',{staticClass:"admin_table"},[_c('v-row',{staticClass:"ma-0 admin_table_area"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","md":"6"}},[_c('AdminItemsTableItem',{attrs:{"title":"*제목","input":""},model:{value:(_vm.models.title),callback:function ($$v) {_vm.$set(_vm.models, "title", $$v)},expression:"models.title"}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","md":"6"}},[_c('AdminItemsTableItem',{attrs:{"title":"*사용여부","radio":""},model:{value:(_vm.models.use_yn),callback:function ($$v) {_vm.$set(_vm.models, "use_yn", $$v)},expression:"models.use_yn"}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('AdminItemsTableItem',{attrs:{"title":"*노출기간","date-picker":"","fill":true},model:{value:(_vm.showDate),callback:function ($$v) {_vm.showDate=$$v},expression:"showDate"}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('AdminItemsTableItem',{attrs:{"title":"*노출위치","input":"","fill":true},model:{value:(_vm.models.location),callback:function ($$v) {_vm.$set(_vm.models, "location", $$v)},expression:"models.location"}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('AdminItemsTableItem',{attrs:{"title":"*팝업창 종류","radio-group":[
								{ text: '이미지형', value: 'IMAGE' },
								{ text: '에디터형', value: 'EDITOR' },
								{ text: '영상', value: 'VIDEO' },
							],"fill":true},model:{value:(_vm.models.popup_kind),callback:function ($$v) {_vm.$set(_vm.models, "popup_kind", $$v)},expression:"models.popup_kind"}})],1),(_vm.models.popup_kind === 'IMAGE')?_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[(_vm.models.popup_url === null)?_c('AdminItemsTableItem',{attrs:{"title":"*이미지 등록","file":"","fill":true,"placeholder":"jpg / png / gif (사이즈 가이드 : 600*600)"},model:{value:(_vm.image_url),callback:function ($$v) {_vm.image_url=$$v},expression:"image_url"}}):_c('AdminItemsTableItem',{attrs:{"title":"이미지","text-button":{
								text: _vm.image_url,
								button: {
									text: '이미지 삭제',
									color: 'primary',
								},
							},"fill":true},on:{"click":function($event){_vm.models.popup_url = null
								_vm.image_url = null}}})],1):_vm._e(),(_vm.models.popup_kind === 'EDITOR')?_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('AdminItemsTableItem',{attrs:{"title":"*내용","text-button":{
								text: _vm.models.content,
								button: {
									text: 'Editor 열기',
									color: 'primary',
								},
							},"fill":true},on:{"click":function($event){_vm.onEditDialog = true}}})],1):_vm._e(),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[(_vm.models.popup_kind === 'IMAGE')?_c('AdminItemsTableItem',{attrs:{"title":"랜딩링크","input":"","fill":true},model:{value:(_vm.models.popuplink_url),callback:function ($$v) {_vm.$set(_vm.models, "popuplink_url", $$v)},expression:"models.popuplink_url"}}):_vm._e(),(_vm.models.popup_kind === 'VIDEO')?_c('AdminItemsTableItem',{attrs:{"title":"*영상링크","input":"","fill":true},model:{value:(_vm.models.popup_url),callback:function ($$v) {_vm.$set(_vm.models, "popup_url", $$v)},expression:"models.popup_url"}}):_vm._e()],1)],1)],1),_c('v-row',{staticClass:"ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"pl-0",attrs:{"cols":"12","md":"6"}},[_c('v-row',{staticClass:"ma-0"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"error","block":"","tile":"","elevation":"0"},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("clear")]),_c('span',{staticClass:"ml-2"},[_vm._v("취소")])],1)],1),(_vm.mode === 'create')?_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"primary","block":"","tile":"","elevation":"0","disabled":_vm.allowValue},on:{"click":_vm.onApiCallItem}},[_c('v-icon',[_vm._v("add")]),_c('span',{staticClass:"ml-2"},[_vm._v("등록")])],1)],1):_vm._e(),(_vm.mode === 'edit')?_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"secondary","block":"","tile":"","elevation":"0","disabled":_vm.allowValue},on:{"click":_vm.onApiCallItem}},[_c('v-icon',[_vm._v("edit")]),_c('span',{staticClass:"ml-2"},[_vm._v("수정")])],1)],1):_vm._e()],1)],1)],1)],1)],1),(_vm.onEditDialog)?_c('CommonEditorQuillEditorDialog',{attrs:{"dialog":_vm.onEditDialog,"edit-contents":_vm.models.content},on:{"save":_vm.contentSave,"close":function($event){_vm.onEditDialog = false}}}):_vm._e(),(_vm.loading)?_c('CommonLoading'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }