<!-- eslint-disable vue/this-in-template -->
<template>
	<div class="mb-5 mx-0 item">
		<v-col class="pa-0 bg_knowhow">
			<div class="category">
				<router-link
					tag="div"
					to="/community/knowhow"
					class="mb-3 tit d-flex align-center clickCursor"
				>
					<p class="mr-1">노하우</p>
					<v-img
						:src="require('@/assets/img/community/arrow_white.svg')"
						max-width="22"
						height="100%"
					/>
				</router-link>

				<span>
					인테리어 Tip & 생활 Tip까지!<br />
					동화만의 다양한 노하우를 지금 확인하세요!
				</span>
			</div>
		</v-col>

		<v-col class="px-4 py-5 px-lg-5 py-lg-7 cont">
			<div class="sub_youtube">
				<div class="mt-2 mb-6 chip">
					<HomeCommunityHomeMaruTvToggleItem
						v-model="themeCode"
						:items="themeCodeItems"
					/>
				</div>

				<div v-if="this.themeCode === 'CC_401_001'">
					<HomeCommunityHomeNewTipitem
						v-for="(item, i) in filterInteriors"
						:key="i"
						:item="item"
						title="인테리어 Tip"
						@click="$emit('click', item)"
					/>
				</div>
				<div v-else-if="this.themeCode === 'CC_401_002'">
					<HomeCommunityHomeNewTipitem
						v-for="(item, i) in filterLifes"
						:key="i"
						:item="item"
						title="마루 Tip"
						@click="$emit('click', item)"
					/>
				</div>
				<div v-else-if="this.themeCode === 'CC_401_003'">
					<HomeCommunityHomeNewTipitem
						v-for="(item, i) in filterMarus"
						:key="i"
						:item="item"
						title="생활 Tip"
						@click="$emit('click', item)"
					/>
				</div>
			</div>
		</v-col>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
	name: 'CommunityHomeNewKnowhow',
	components: {},
	data: () => ({
		themeCode: null,
		themeCodeItems: [],
	}),
	computed: {
		...mapGetters(['HOME_GET_COMMUNITY_KNOW_HOW']),
		filterInteriors() {
			return this.HOME_GET_COMMUNITY_KNOW_HOW.filter(item => item.code === 'CC_401_001').slice(0, 3)
		},
		filterMarus() {
			return this.HOME_GET_COMMUNITY_KNOW_HOW.filter(item => item.code === 'CC_401_003').slice(0, 3)
		},
		filterLifes() {
			return this.HOME_GET_COMMUNITY_KNOW_HOW.filter(item => item.code === 'CC_401_002').slice(0, 3)
		},
	},
	watch: {},
	async created() {
		this.themeCodeItems = await this.ADMIN_ACT_PRODUCT_GET_PUBLIC_CODE_ITEMS({ group_code: '401' })
	},
	methods: {
		...mapActions(['ADMIN_ACT_PRODUCT_GET_PUBLIC_CODE_ITEMS']),
	},
}
</script>

<style scoped lang="scss">
.item {
	background-color: $color_white;
	border-radius: 16px;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
	overflow: hidden;

	.category {
		color: $color_white;
		p {
			font-weight: $fw_bold;
			line-height: 1 !important;
			font-family: 'NotoSansKR-Bold' !important;
			font-size: $font_tit !important;
		}
		span {
			word-break: keep-all;
			font-size: $font_normal;
			font-weight: $fw_normal;
		}
	}

	.cont {
		position: relative;
		margin-top: -20px;
		border-radius: 16px !important;
		background-color: $color_white;
		h3 {
			color: $color_font;
			font-size: $font_xl;
			font-weight: $fw_bold;
		}
	}

	// .bg_maru_tv {
	// 	padding: 30px 20px 40px !important;
	// 	background-color: $color_green_5;
	// }
	.bg_knowhow {
		position: relative;
		width: 100%;
		height: 312px;
		background: url('../../../../../assets/img/community/bg_knowhow.jpg') no-repeat;
		background-size: cover;
		background-position: center;
		.category {
			position: absolute;
			bottom: 15%;
			left: 20px;
		}		
		&::after {
			content:'';
			background: linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
			width:100%;
			height:100%;
			display: block;
		}
	}
}

@media all and (max-width: 1264px) {
	.item {
		.cont {
			margin-top: 0 !important;
			border-radius: 0 !important;
		}
		.bg_maru_tv {
			padding: 20px 16px !important;
		}
	}
}

@media all and (max-width: 1264px) {
	.item {
		border-radius: 0 !important;		
	}
}

@media all and (max-width: 960px) {
	.item {
		.bg_knowhow {
			height:260px !important;
		}
	}
}

@media all and (max-width: 600px) {
	.item {
		.bg_knowhow {
			height: 170px  !important;
			background-position: 0px -25px;
		}
	}
}

@media all and (max-width: 380px) {
	.item {
		.category {
			p {
				font-size: $font_xl !important;
				line-height: 22px !important;
			}
			span {
				font-size: $font_sm !important;
			}
		}
		.cont {
			h3 {
				font-size: $font_lg !important;
			}
		}

		.main_youtube {
			.desc {
				font-size: $font_normal !important;
			}
		}
	}
}
</style>
