<template>
	<v-col
		cols="12"
		align="center"
	>
		<v-card class="feature_tit02 pt-5 pb-5">
			<p>제품구조</p>
		</v-card>
		<v-row class="ma-0 feature_table02">
			<v-col
				v-if="mainImage"
				cols="12"
				class="pa-0 pb-10 item"
			>
				<v-col
					cols="10"
					sm="7"
					md="6"
					lg="9"
				>
					<v-img
						eager
						width="100%"
						height="100%"
						:src="mainImage"
					/>
				</v-col>
			</v-col>
			<v-col
				v-for="(item, i) in items"
				:key="i"
				cols="12"
				md="6"
				class="pa-0 pt-5 pb-5 pt-md-10 pb-md-10 item"
				:class="
					items.length === 6 && mainImage === '' ? 'itemSIx' : mainImage !== '' ? 'itemFourNew' : 'itemFour'
				"
			>
				<v-row class="ma-0">
					<!-- <v-col
						v-if="item.title !== null"
						cols="3"
						class="pa-0"
					>
						<p class="structure_num pa-1 pa-sm-2">{{ i + 1 }}</p>
					</v-col> -->
					<v-col
						cols="9"
						class="pa-0"
						align="start"
					>
						<p class="tit mb-1">
							<span class="structure_num_count">{{ item.title ? `0${i + 1}` : '' }}</span>
							{{ item.title }}
						</p>
						<span class="text">{{ item.text }}</span>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</v-col>
</template>

<script>
export default {
	name: 'HomeProductDetailStructure',
	components: {},
	props: ['mainImage', 'items'],
	data: () => ({}),
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
}
</script>
<style scoped lang="scss">
.structure_num {
	width: 60px !important;
	height: 60px !important;
	font-size: 26px !important;
	vertical-align: middle !important;
	color: #42883d !important;
	border: 1px solid #42883d !important;
}
.item {
	//border-bottom: 1px solid #e6e6e6;
	.tit {
		display: flex;
		align-items: center;
		line-height: 1.2;

		.structure_num_count {
			padding-right: 3px;
			font-weight: bold;
			font-size: 18px !important;
			vertical-align: middle !important;
			color: #00ce7d !important;
		}
	}
	// &:first-child {
	// 	border-bottom: 0;
	// }
	// &:last-child {
	// 	border-bottom: 0;
	// }
}
.itemSIx {
	border-bottom: 1px solid #e6e6e6;
	&:first-child {
		//border-bottom: 0;
	}
	&:nth-of-type(5) {
		border-bottom: 0;
	}
	&:last-child {
		border-bottom: 0;
	}
}
.itemFourNew {
	border-bottom: 1px solid #e6e6e6;

	&:nth-last-of-type(2) {
		border-bottom: 0;
	}
	&:last-child {
		border-bottom: 0;
	}
}
.itemFour {
	&:nth-of-type(1) {
		border-bottom: 1px solid #e6e6e6;
	}
	&:nth-of-type(2) {
		border-bottom: 1px solid #e6e6e6;
	}
	&:nth-of-type(3) {
		border-bottom: 0;
	}
	&:last-child {
		border-bottom: 0;
	}
}
// .first-item {
// 	border-bottom: 1px solid #e6e6e6 !important;
// }
// .last-item {
// 	border-bottom: 0 !important;
// }

.text {
	color: #777777;
	font-weight: 400;
	font-size: 14px;
}

@media all and (max-width: 960px) {
	.feature_table02 {
		.itemFour {
			border-bottom: 1px solid #e6e6e6;
			&:nth-of-type(3) {
				border-bottom: 1px solid #e6e6e6;
			}
			&:last-child {
				border-bottom: 0;
			}
		}
	}
}

@media all and (max-width: 380px) {
	.structure_num {
		width: 40px !important;
		height: 40px !important;
		font-size: 17px !important;
	}
}
</style>
