<template>
	<div>
		<!-- 모바일 버전 -->
		<v-row class="ma-0 mb-4 intro_mo hidden-md-and-up">
			<v-col
				class="pa-0 img"
				cols="12"
			>
				<v-img
					max-height="160"
					eager
					:src="require('@/assets/img/careflo/img_careflo_banner.png')"
				/>
				<p>
					<strong>현장점검</strong>부터 <strong>사후관리</strong>까지<br />
					마루 전문 기업이 만든 확실한<br />
					<strong>Care-Flo</strong>를 경험하세요.
				</p>
			</v-col>
			<v-col
				class="pa-4 txt"
				cols="12"
			>
				<v-col
					class="pa-0 mb-4 d-flex"
					align-item="center"
				>
					<v-img
						class="mr-2"
						max-width="30"
						height="30"
						eager
						:src="require('@/assets/img/careflo/icon_care_flo.svg')"
					/>
					<strong class="tit">Care-Flo 란?</strong>
				</v-col>
				<v-col class="pa-0 desc">
					진/듀오 제품군을 구매한 고객에게 <br class="hidden-sm-and-up" />
					제공하는 마루 전문 기업
					<strong>[동화자연마루만의 마루케어 서비스]</strong>입니다.
					<p class="mt-4">동화자연마루 본사 및 공식 대리점에서 케어플로 서비스를 실시합니다.</p>
				</v-col>
			</v-col>
		</v-row>

		<!-- 웹버전 -->
		<v-row class="px-4 px-lg-0 pt-8 ma-0 mb-10 intro_pc hidden-sm-and-down">
			<v-col
				class="pa-0 left"
				cols="8"
				lg="9"
			>
				<v-col class="pa-0 mb-6 top">
					<p class="mb-6 txt1">케어서비스가 다르면 인테리어도 다릅니다.</p>
					<p class="mb-7 txt2">
						<strong>현장점검</strong>부터 <strong>사후관리</strong>까지 마루 전문 기업이 만든 확실한
						<br class="hidden-sm-and-down" />
						<strong>Care-Flo</strong>를 경험하세요.
					</p>
				</v-col>
				<v-col class="pa-0 bottom">
					<v-row class="ma-0">
						<v-col
							class="pa-0 d-flex"
							cols="auto"
							align-item="center"
						>
							<v-img
								class="mr-2"
								max-width="32"
								height="32"
								eager
								:src="require('@/assets/img/careflo/icon_care_flo.svg')"
							/>
							<strong class="tit">Care-Flo 란?</strong>
						</v-col>
						<v-col class="pa-0">
							<div class="ml-6 desc">
								진/듀오 제품군을 구매한 고객에게 <br />
								제공하는 마루 전문 기업 <strong>[동화자연마루만의 마루케어 서비스]</strong>입니다.
								<p class="mt-3">동화자연마루 본사 및 공식 대리점에서 케어플로 서비스를 실시합니다.</p>
							</div>
						</v-col>
					</v-row>
				</v-col>
			</v-col>
			<v-col
				class="pa-0 right"
				cols="4"
				lg="3"
			>
				<v-img
					eager
					height="100%"
					:src="require('@/assets/img/careflo/img_careflo_banner.png')"
				/>
			</v-col>
		</v-row>

		<v-row class="px-4 pb-7 pb-md-10 ma-0 talk_box">
			<v-col
				class="pa-0 mb-5 mb-md-0"
				md="6"
				cols="12"
				align-self="center"
			>
				<v-img
					width="100%"
					max-width="600"
					height="100%"
					eager
					:src="require('@/assets/img/careflo/img_talk.svg')"
				/>
			</v-col>
			<v-col
				class="pa-0"
				md="6"
				cols="12"
				align-self="center"
			>
				<v-row class="ma-0 justify-end">
					<v-col
						class="pa-0"
						lg="8"
						md="9"
						cols="12"
					>
						<p class="mb-4 mb-md-7 slogan">
							동화자연마루의 숙련된 <br class="hidden-md-and-down" />
							<strong>마루 전문가</strong>가 문제를 <strong>해결</strong>해드립니다.
						</p>
						<p class="notice">일부 도서/산간 지역은 서비스가 어려울 수 있습니다.</p>
						<p class="notice">거리에 따라 별도 출장비가 발생할 수 있습니다.</p>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</div>
</template>

<script>
export default {
	name: 'HomeCustomCareFloVisual',
	components: {},
	props: [],
	data: () => ({}),
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
}
</script>

<style scoped lang="scss">
.intro_mo {
	word-break: keep-all;
	.img {
		position: relative;
		p {
			position: absolute;
			left: 20px;
			bottom: 20px;
			font-size: $font_lg;
		}
	}
	:deep(.v-image__image) {
		background-position: top right !important;
	}
	.txt {
		background-color: $color_white;
		.tit {
			color: $color_font;
			font-size: $font_lg;
		}
		.desc {
			font-size: $font_normal;
			color: $color_gray_7;
			p {
				color: $color_gray_5;
			}
		}
	}
}
.intro_pc {
	word-break: keep-all;
	.left {
		padding: 38px 30px 26px 40px !important;
		background-color: $color_white;
		border-radius: 16px 0px 0px 16px;
		.top {
			border-bottom: 1px solid $color_gray_2;
			.txt1 {
				color: $color_font;
				font-size: 2.2rem;
				font-weight: $fw_bold;
				line-height: 1;
			}
			.txt2 {
				color: $color_gray_8;
				font-size: $font_tit;
				line-height: 1.3;
			}
		}
		.bottom {
			.tit {
				font-size: $font_xl;
				color: $color_gray_8;
			}
			.desc {
				font-size: $font_lg;
				color: $color_gray_7;
				p {
					color: $color_gray_5;
				}
			}
		}
	}
	.right {
		.v-image {
			border-radius: 0 16px 16px 0;
			aspect-ratio: 1/1;
		}
	}
}
.slogan {
	font-size: 1.8rem;
}
.notice {
	position: relative;
	padding-left: 10px;
	font-size: $font_normal;
	color: $color_gray_5;
	&::before {
		display: block;
		position: absolute;
		top: 3px;
		left: 0;
		content: '*';
	}
}

@media all and (max-width: 959px) {
	.talk_box {
		.v-image {
			max-width: 450px !important;
			margin: 0 auto;
		}
	}
	.slogan {
		font-size: $font_xl !important;
	}
	.notice {
		font-size: $font_sm !important;
	}
}
</style>
