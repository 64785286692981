<template>
	<v-row class="ma-0 one benefit_wrap">
		<v-col
			class="pa-0 mb-5"
			cols="12"
			md="6"
		>
			<div class="pa-4 pa-md-7 mr-0 mr-md-3 benefit">
				<v-col
					class="pa-0 mb-4 mb-md-8 d-flex justify-center align-center"
					align="center"
					cols="auto"
				>
					<v-img
						class="mr-2 hidden-md-and-down"
						max-width="32"
						height="32"
						eager
						:src="require('@/assets/img/careflo/icon_care_flo.svg')"
					/>
					<strong class="tit">진/듀오 제품군 시공 고객에게 제공</strong>
				</v-col>
				<p class="desc tac">
					별도의 신청 없이 진/듀오 제품군을
					<br class="hidden-sm-and-down" />
					시공한 고객이라면 누구나 받을 수 있는 혜택입니다.
				</p>
			</div>
		</v-col>
		<v-col
			class="pa-0 mb-5"
			cols="12"
			md="6"
		>
			<div class="pa-4 pa-md-7 ml-0 ml-md-3 benefit">
				<v-col
					class="pa-0 mb-4 mb-md-8 d-flex justify-center align-center"
					align="center"
					cols="auto"
				>
					<v-img
						class="mr-2 hidden-md-and-down"
						max-width="32"
						height="32"
						eager
						:src="require('@/assets/img/careflo/icon_1year.svg')"
					/>
					<strong class="tit">1년간 1회 무상 A/S 제공</strong>
				</v-col>
				<p class="desc tac">시공 후 1년 이내 1회 무상 A/S를 제공합니다.</p>
				<div class="mt-5 free_list">
					<v-row class="ma-0 pa-2">
						<v-col
							class="pa-0 mb-2 mb-md-0 th"
							cols="12"
							md="auto"
						>
							<div class="mr-3">무상 A/S 기준</div>
						</v-col>
						<v-col
							class="pa-0 td"
							cols="12"
							lg="10"
							md="9"
						>
							찍힘/긁힘 메꿈처리, 표면 이물질/오염제거, 걸레받이 재결합, 실리콘 탈락 시 코킹처리
						</v-col>
					</v-row>
				</div>
			</div>
		</v-col>
		<v-col
			class="pa-0 mb-5 mb-md-0"
			cols="12"
			md="6"
		>
			<div class="pa-4 pa-md-7 mr-0 mr-md-3 benefit">
				<v-col
					class="pa-0 mb-4 mb-md-8 d-flex justify-center align-center"
					align="center"
					cols="auto"
				>
					<v-img
						class="mr-2 hidden-md-and-down"
						max-width="32"
						height="32"
						eager
						:src="require('@/assets/img/careflo/icon_home.svg')"
					/>
					<strong class="tit">현장점검</strong>
				</v-col>
				<p class="desc tac">
					마루 시공품질과 시공 후 고객 만족도를 높이기 위해,
					<br class="hidden-sm-and-down" />
					현장을 방문하여 바닥 상태를 점검합니다.
					<!-- <span class="mt-4 notice">
						<span class="txt">본사 직시공에 한정적으로 적용되며, 추후 확대 예정입니다.</span>
					</span> -->
				</p>
			</div>
		</v-col>
		<v-col
			class="pa-0"
			cols="12"
			md="6"
		>
			<div class="pa-4 pa-md-7 ml-0 ml-md-3 benefit">
				<v-col
					class="pa-0 mb-4 mb-md-8 d-flex justify-center align-center"
					align="center"
					cols="auto"
				>
					<v-img
						class="mr-2 hidden-md-and-down"
						max-width="32"
						height="32"
						eager
						:src="require('@/assets/img/careflo/icon_10year.svg')"
					/>
					<strong class="tit">품질보증 10년</strong>
				</v-col>
				<p class="desc tac">제품 품질을 10년간 보증합니다.</p>
			</div>
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: 'HomeCustomCareFloOneBenefit',
	components: {},
	props: [],
	data: () => ({}),
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
}
</script>

<style scoped lang="scss">
.benefit_wrap {
	.benefit {
		height: 100%;
		border-radius: 16px;
		word-break: keep-all;
		.tit {
			font-size: $font_sub_tit;
		}
		.desc {
			color: $color_gray_8;
			font-size: $font_xl;
			.notice {
				display: block;
				.txt {
					position: relative;
					padding-left: 10px;
					color: $color_gray_5;
					font-size: $font_normal;
					&::before {
						display: block;
						position: absolute;
						left: 0;
						top: 3px;
						content: '*';
					}
				}
			}
		}
		.free_list {
			.row {
				font-size: $font_normal;
				background-color: $color_white;
				border-radius: 8px;
				.th {
					color: $color_gray_5;
					font-weight: $fw_bold;
				}
				.td {
					word-break: keep-all;
					color: $color_gray_8;
				}
			}
		}
	}
	&.one {
		.benefit {
			background: $color_gray_1;
		}
	}
}

@media all and (max-width: 959px) {
	.benefit_wrap {
		.benefit {
			.tit {
				font-size: $font_lg !important;
			}
			.desc {
				font-size: $font_normal !important ;
				.notice {
					font-size: $font_sm !important;
				}
			}
			.free_list {
				.row {
					font-size: $font_sm !important;
				}
			}
		}
	}
}
</style>
