<template>
	<v-carousel
		ref="carousel"
		v-model="currentIndex"
		:show-arrows="false"
		delimiter-icon="mdi-color-helper"
		hide-delimiters
		hide-delimiter-background
		class="slide_type01"
		:style="`height:${height} !important`"
		:interval="5000"
		:cycle="cycle"
	>
		<v-carousel-item
			v-for="(item, i) in carouselItems"
			:key="i"
			eager
		>
			<v-img
				:src="item.mainimage_url"
				contain
				eager
				:class="item.link_url ? 'clickCursor' : ''"
				@click="onClickPushLink(item)"
			>
				<v-col
					v-if="item.title"
					class="pa-7 text_area"
				>
					<v-row align="start">
						<v-col cols="auto">
							<v-img
								:src="item.thumimage_url"
								contain
								eager
							/>
						</v-col>
					</v-row>
					<p
						v-if="item.title"
						class="text_area_title"
					>
						{{ item.title }}
					</p>
					<p
						v-if="item.contents"
						class="text_area_text"
					>
						{{ item.contents }}
					</p>
				</v-col>
			</v-img>
		</v-carousel-item>
		<div class="carousel-nav">
			<button @click="prev(currentIndex)">
				<v-img
					:src="require('@/assets/img/home/main/img_slide_left_arrow.svg')"
					eager
				/>
			</button>
			<span class="page_count">
				<em>{{ currentIndex + 1 }}</em> / {{ carouselItems.length }}
			</span>
			<button @click="next(currentIndex)">
				<v-img
					:src="require('@/assets/img/home/main/img_slide_right_arrow.svg')"
					eager
				/>
			</button>

			<div class="operatedWrap">
				<button
					v-if="cycle"
					type="button"
					class="pause operation_button"
					@click="clickPlay"
				>
					<v-img
						:src="require('@/assets/img/home/main/slide_pause.png')"
						eager
					/>
				</button>
				<button
					v-else
					type="button"
					class="play operation_button"
					@click="clickPlay"
				>
					<v-img
						:src="require('@/assets/img/home/main/slide_play.png')"
						eager
					/>
				</button>
			</div>
		</div>
	</v-carousel>
</template>

<script>
import model from '@/mixins/model'
import common from '@/mixins/common'
import google_analytic from '@/mixins/google_analytic'

export default {
	name: 'HomeMainCarousels',
	mixins: [model, common, google_analytic],
	props: ['carouselItems', 'height'],
	data: () => ({
		currentIndex: 0,
		cycle: true,
	}),
	computed: {
		currentTitle() {
			return this.carouselItems[this.currentIndex]
		},
	},
	watch: {},
	created() {},
	mounted() {
		// console.log('Carousels', this.carouselItems)
	},
	methods: {
		onClickPushLink(item) {
			const devMode = process.env.NODE_ENV === 'development'
			const productMode = process.env.NODE_ENV === 'production'
			console.log('링크url', item)
			// google analytic
			if (productMode) {
				this.google_analytic_push_event('click_main_banner', { button_location: item.title })
				this.common_onLink(item.link_url)
			} else {
				this.common_onLink(item.link_url)
			}
			// this.google_analytic_push_event('click_main_banner', { button_location: item.title })
			// this.common_onLink(item.link_url)
		},
		next() {
			this.currentIndex += 1
		},
		prev() {
			this.currentIndex -= 1
		},
		clickPlay() {
			this.cycle = !this.cycle
		},
	},
}
</script>

<style scoped lang="scss">
.v-window.slide_type01 {
	border-radius: 10px;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
	overflow: hidden;
	:deep(.v-image) {
		width: 100% !important;
		height: 100% !important;
		max-height: 200px !important;
	}
	:deep(.v-image__image--contain) {
		background-size: cover !important;
	}
	:deep(.v-item-group) {
		button::before {
			display: none;
		}
		.v-btn--icon {
			width: 10px;
			height: 10px;
		}
		.v-item--active.v-btn--active .v-icon.notranslate {
			background-color: #42883d;
		}
		.v-icon.notranslate {
			width: 10px;
			height: 10px;
			justify-content: space-between;
			background-color: #fff;
			opacity: 1;
			border-radius: 4px;
		}
		.v-icon.notranslate::before {
			display: none !important;
		}
	}

	.carousel-nav {
		position: absolute;
		bottom: 15px;
		//max-width: 100px;
		max-width: auto;
		height: 26px;
		left: 15px;
		padding: 8px 10px;
		border-radius: 220px;
		gap: 10px;
		background-color: #00000033;
		display: flex;
		justify-content: center;
		align-items: center;
		.page_count {
			color: #fff;
			font-weight: $fw_normal;
			font-size: $font_sm;
			em {
				font-style: normal;
				font-weight: $fw_semibold;
			}
		}
		.operatedWrap {
			display: flex;
			width: 9px;
		}
	}

	.text_area {
		max-width: 360px;
		position: absolute;
		bottom: 50%;
		transform: translate(0, 50%);
		right: 20px;
		background-color: rgba(1, 1, 1, 0.5);
		visibility: hidden;
		:deep(.v-image) {
			height: 36px !important;
			margin-bottom: 5px;
		}
		:deep(.v-image__image--contain) {
			background-size: inherit !important;
		}
		.text_area_title {
			padding-bottom: 10px;
			font-family: 'NotoSansKR-Regular';
			font-size: 22px;
			color: #fff;
			visibility: hidden;
		}
		.text_area_text {
			font-family: 'NotoSansKR-Regular';
			font-size: 14px;
			color: #fff;
			visibility: hidden;
		}
	}
}

.detail {
	height: 620px !important;
	border-radius: 0 !important;
	:deep(.v-image) {
		width: 100% !important;
		height: 100% !important;
	}
}
@media all and (min-width: 960px) and (max-width: 1263px) {
}
@media all and (min-width: 600px) and (max-width: 959px) {
}
@media all and (max-width: 600px) {
	.v-window.slide_type01 {
		border-radius: 0 !important;
	}
	.text_area {
		padding-bottom: 50px !important;
		max-width: 100% !important;
		position: absolute;
		bottom: 0 !important;
		right: 0 !important;
		transform: translate(0, 0) !important;
		&_title {
			font-size: 22px;
		}
		&_text {
			font-size: 14px;
		}
	}
}
</style>
