<template>
	<div class="mx-0 my-2">
		<!-- <ul class="stepprogreess my-2">
			<li class="active"></li>
			<li></li>
			<li></li>
			<li></li>
		</ul>-->
		<!-- 집종류 -->
		<!-- <CommonCardMenuTypeBtn07
			v-model="allow.type_1"
			:type-items="homeTypeItems1"
		/> -->

		<v-col
			cols="12"
			class="py-0 px-0 pb-4 question"
			align="start"
		>
			<span>01</span> 어떤 유형의 집을 시공할건가요?
		</v-col>
		<!-- 집종류 -->
		<v-col
			cols="12"
			class="py-0 px-0 pb-6"
		>
			<div align="start">
				<p class="mb-1 color_gray_7">주거형태</p>
				<CommonCardMenuTypeBtn06
					v-model="allow.type_1"
					class="fast"
					:type-items="homeTypeItems1"
					:cols="4"
					:ico="icons"
					:height="76"
					:type="'three'"
				/>
			</div>
		</v-col>

		<v-col
			cols="12"
			class="py-0 px-0 pb-4 question"
			align="start"
		>
			<span>02</span> 시공 장소의 전용 면적과 방개수를 알려주세요
		</v-col>
		<!-- 평수 -->
		<v-col
			cols="12"
			class="py-0 px-0 pb-6"
		>
			<div align="start">
				<p class="mb-1 color_gray_7">전용면적</p>
				<CommonSelectsSelect06
					v-if="widthCodeItems"
					v-model="allow.widthModel"
					:items="widthCodeItems"
					:type="'two'"
					label="평(㎡)"
					class-name="option priceSelec"
				/>
			</div>
		</v-col>

		<!-- 방개수 -->
		<v-col
			cols="12"
			class="py-0 px-0 pb-6"
		>
			<div align="start">
				<p class="mb-1 color_gray_7">방개수</p>
				<CommonSelectsSelect06
					v-if="roomItems"
					v-model="allow.roomModel"
					:items="roomItems"
					:disabled="allow.widthModel === null"
					label="방 선택"
					class-name="option priceSelec"
				/>
			</div>
		</v-col>

		<div style="top: 100px"></div>
	</div>
</template>

<script>
export default {
	name: 'GreenDongwhaClientRepo01Form',

	props: {
		currentpage: {
			Type: Number,
			require: true,
		},
		homeTypeItems1: {
			type: Array,
		},
		widthCodeItems: {
			type: Array,
		},
		roomItems: {
			type: Array,
		},
		reset: {
			type: Boolean,
		},
	},

	data() {
		return {
			allow: {
				type_1: null,
				widthModel: null,
				roomModel: null,
			},
			icons: ['ico_apartment.svg', 'ico_officetel.svg', 'ico_vila.svg'],
		}
	},

	computed: {
		homeTypeItems1_update() {
			return {
				type_1: this.allow.type_1,
				widthModel: this.allow.widthModel,
				roomModel: this.allow.roomModel,
			}
		},
	},
	watch: {
		homeTypeItems1_update(newval, oldval) {
			if (newval) {
				if (Object.keys(newval)[0] === 'type_1') {
					this.$emit('hometype1', newval, 'form1')
				}
				if (Object.keys(newval)[0] === 'widthModel') {
					this.$emit('hometype1', newval, 'form1')
				}
				if (Object.keys(newval)[0] === 'roomModel') {
					this.$emit('hometype1', newval, 'form1')
				}
			}
		},
		reset(newvalue, oldvalue) {
			if (newvalue) {
				this.allow.type_1 = null
				this.allow.widthModel = null
				this.allow.roomModel = null
			} else {
				this.allow.type_1 = null
				this.allow.widthModel = null
				this.allow.roomModel = null
			}
		},
	},

	mounted() {},

	methods: {},
}
</script>

<style lang="scss" scoped>
.stepprogreess {
	display: flex;
	li {
		width: 25%;
		list-style: none;
		height: 10px;
		background-color: #c0c0c0;
	}
	.active {
		background-color: #00582c;
	}
}

.question {
	color: $color_font;
	font-size: $font_xl;
	letter-spacing: -0.7px;
	span {
		font-weight: bold;
		font-size: $font_sub_tit;
	}
}
</style>
