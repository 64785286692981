<template>
	<div class="wrapper">
		<div class="custom_quote">
			<v-col
				cols="12"
				class="pa-0 register_history inner"
				align="center"
			>
				<div class="">
					<v-col
						class="pa-0"
						cols="12"
						align="center"
					>
						<v-col
							cols="12"
							class="pa-0 table"
						>
							<div class="mb-5">
								<p
									class="tit"
									align="start"
								>
									간편상담 신청내역 확인
								</p>
								<v-row class="finalCont mb-4">
									<p
										class="tit pb-1"
										align="start"
									>
										신청자 정보
									</p>

									<v-col
										v-if="Object.keys(estimateItems).length"
										cols="12"
										class="innerBox02 pa-0"
									>
										<div class="d-flex align-items-center py-1">
											<div class="listItem">
												<!-- <p class="font_sm color_gray_6">{{ selectedItem.item[0].kind_name }}</p> -->
												<p class="font_sm color_gray_6">신청번호</p>
												<p v-if="estimateItems.consult_id">{{ estimateItems.consult_id }}</p>
											</div>
										</div>
										<div class="d-flex align-items-center py-1">
											<div class="listItem">
												<!-- <p class="font_sm color_gray_6">{{ selectedItem.item[0].kind_name }}</p> -->
												<p class="font_sm color_gray_6">이름</p>
												<p v-if="estimateItems.name">
													{{
														estimateItems.name === 'aa'
															? estimateItems.regi_userid
															: estimateItems.name
													}}
												</p>
											</div>
										</div>
										<div class="d-flex align-items-center py-1">
											<div class="listItem">
												<!-- <p class="font_sm color_gray_6">{{ selectedItem.item[0].kind_name }}</p> -->
												<p class="font_sm color_gray_6">휴대폰 번호</p>
												<p v-if="estimateItems.hand">{{ common_number_filter(estimateItems.hand) }}</p>
											</div>
										</div>
										<!-- <div
											v-if="estimateItems.emailaddr"
											class="d-flex align-items-center"
										>
											<div class="listItem">
												<p class="font_sm color_gray_6">이메일</p>
												<p>{{ estimateItems.emailaddr }}</p>
											</div>
										</div> -->
										<div
											v-if="estimateItems.content"
											class="d-flex align-items-center py-1"
										>
											<div class="listItem">
												<p class="font_sm color_gray_6">문의사항</p>
												<p>{{ estimateItems.content }}</p>
											</div>
										</div>
									</v-col>

									<v-col
										v-if="
											estimateItems.product_kind === '견적내기' || estimateItems.product_kind === '빠른견적'
										"
										class="pa-0 td nearArea"
										cols="12"
										sm="12"
										lg="12"
										align="start"
										align-self="start"
									>
										<v-expansion-panels
											v-model="panel"
											flat
											multiple
											class="panel"
										>
											<v-expansion-panel v-model="panel">
												<v-expansion-panel-header>
													<span class="innerTitle"> 맞춤 견적 사항 </span>
												</v-expansion-panel-header>
												<v-expansion-panel-content class=""
													><div>
														<div
															v-if="
																estimateItems !== null ||
																estimateItems.exclusive_area !== null ||
																estimateItems.goods_name
															"
															class="detailWrap"
														>
															<div
																v-if="estimateItems?.housetype"
																class="d-flex"
															>
																<span class="detailTit">주거형태</span>
																<span>
																	{{ estimateItems.housetype }}
																</span>
															</div>
															<div
																v-if="estimateItems.exclusive_area"
																class="d-flex"
															>
																<span class="detailTit">전용면적</span>
																<span>
																	{{ exclusive_area[0].text }}
																</span>
															</div>
															<div
																v-if="estimateItems?.roomcount"
																class="d-flex"
															>
																<span class="detailTit">방개수</span>
																<span> {{ estimateItems.roomcount }}개 </span>
															</div>
															<!-- <div
														v-if="selectedItem?.roomcount"
														class="d-flex"
													>
														<span class="detailTit">방개수</span>
														<span> {{ selectedItem.roomcount }}개 </span>
													</div> -->
															<div
																v-if="estimateItems?.veranda_yn"
																class="d-flex"
															>
																<span class="detailTit">베란다 확장 여부</span>
																<span>
																	{{ estimateItems.veranda_yn }}
																</span>
															</div>
															<div
																v-if="estimateItems?.buildscope"
																class="d-flex"
															>
																<span class="detailTit">시공 범위</span>
																<span>
																	{{ estimateItems.buildscope }}
																</span>
															</div>
															<div
																v-if="estimateItems?.goods_name"
																class="d-flex"
															>
																<span class="detailTit">시공 제품</span>
																<span>
																	{{
																		estimateItems.goods_name === '그란데'
																			? good_name_list.gc_01
																			: estimateItems.goods_name === '베이직'
																			? good_name_list.gc_02
																			: estimateItems.goods_name === '125'
																			? good_name_list.gc_03
																			: estimateItems.goods_name
																	}}
																</span>
															</div>
															<div
																v-if="estimateItems?.removal_yn"
																class="d-flex"
															>
																<span class="detailTit">철거유무</span>
																<span>
																	{{ estimateItems.removal_yn }}
																</span>
															</div>
															<div
																v-if="estimateItems?.est_cost"
																class="d-flex"
															>
																<span class="detailTit">예상견적</span>
																<span> {{ estimateItems.est_cost }}만원 </span>
															</div>
														</div>
														<!-- <div
													v-if="nexttointerior.length !== 0"
													class="exist"
												>
													<span
														v-for="(item, idx) in nearinterior"
														:key="idx"
														class=""
														style="width: 100%"
													>
														<span
															v-if="askThatInterior !== true"
															class="nearItem"
														>
															<span class="">
																{{ item.business_name }}
																<span class="nearAddr"> {{ `${item.addr_cut}` }}</span>
															</span>
															<v-img
																class="ml-1 cancelButton"
																max-width="16"
																height="16"
																eager
																:src="
																	require('@/assets/img/home/interior/ico_interiorstor_close.svg')
																"
																@click="selectCancel(item, idx)"
															/>
														</span>
													</span>
												</div> -->
													</div></v-expansion-panel-content
												>
											</v-expansion-panel>
										</v-expansion-panels>
									</v-col>

									<div v-if="estimateItems.card_list">
										<div v-if="estimateItems.product_kind">
											<p class="titType02 font_sm pt-1">문의콘텐츠</p>
											<div class="innerBox02 contentArea">
												<div class="d-flex align-items-center">
													<!-- <v-img
											class="hidden-md-and-down mr-2"
											width="56"
											height="56"
											style="flex: none"
											:src="require(`${selectedItem.item[0].image_url}`)"
										/> -->
													<!-- <img
											:src="`${selectedItem.item.image_url} ? ${selectedItem.item.image_url} : ${selectedItem.item.image}`"
											class="hidden-md-and-down mr-2 askcontImg"
										/> -->
													<router-link
														:to="
															estimateItems.product_kind === '전문가집들이관리'
																? `/housewarming/specialist/detail/${estimateItems.card_list.item_id}`
																: estimateItems.product_kind === '3D집들이관리'
																? `/housewarming/3d/detail/${estimateItems.card_list.item_id}`
																: estimateItems.product_kind === '상품마스터'
																? `/product/detail/${estimateItems.card_list.item_id}`
																: ''
														"
													>
														<v-img
															eager
															class="mr-2 askcontImg"
															:src="
																estimateItems.card_list?.image_url
																	? estimateItems.card_list.image_url
																	: estimateItems.image
															"
														/>
													</router-link>
													<div class="d-flex flex-column justify-center">
														<!-- <p class="font_sm color_gray_6">{{ selectedItem.item[0].kind_name }}</p> -->
														<p class="font_sm color_gray_6">
															{{ estimateItems.card_list.top_title }}
														</p>
														<p>{{ estimateItems.card_list?.title }}</p>
													</div>
												</div>
											</div>
										</div>
										<div v-else>
											<p class="titType02 font_sm pt-1">제품상세</p>
											<div class="innerBox02">
												<div class="">
													<p class="font_sm color_gray_6">{{ estimateItems.card_list.product_kind }}</p>
													<p>{{ estimateItems.card_list.sub_line }}</p>
												</div>
											</div>
										</div>
									</div>
								</v-row>
								<v-row class="ma-0">
									<v-col
										class="pa-0 mt-3"
										cols="12"
										align="start"
									>
										상담 현황
									</v-col>
								</v-row>
								<v-row
									class="ma-0"
									align="center"
								>
									<v-col
										class="pa-0 notice mt-0"
										cols="12"
										md="8"
										sm="12"
									>
										<ul class="pa-0">
											<li>고객 상담을 위해 고객님께 전화를 <br />걸어상담을 진행해주세요.</li>
										</ul>
									</v-col>
									<v-col
										class="pa-0 py-2 td"
										cols="12"
										md="4"
										sm="12"
										align="start"
										align-self="center"
									>
										<v-col
											class="pa-0"
											cols="12"
										>
											<CommonSelectsSelect08
												v-model="estimateModel"
												:items="estimate_consultStatusItems"
												:label="'상담현황 선택'"
											/>
										</v-col>
									</v-col>
								</v-row>
							</div>
						</v-col>
					</v-col>
				</div>
			</v-col>
		</div>
		<div
			class="mt-10 mb-10"
			align="center"
		>
			<CommonButtonsButton02
				name="목록"
				class-name="btn_large"
				color="#262626"
				class="ml-1"
				outlined
				@click="$router.go(-1)"
			/>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import common from '@/mixins/common'
import admin from '@/mixins/admin'

export default {
	name: 'UserAgencyEstimateDetail',
	mixins: [common, admin],
	data: () => ({
		estimateItems: {},
		estimateModel: null,
		panel: [0],
		originEstimateModel: null,
		estimate_consultStatusItems: [
			{ text: '상담대기중', value: '2' },
			{ text: '상담완료', value: '3' },
		],
		exclusive_area: null,
		homeTypeItems1: [
			{ title: '아파트', value: '아파트' },
			{ title: '오피스텔', value: '오피스텔' },
			{ title: '빌라/주택', value: '빌라/주택' },
		],
		homeTypeItems2: [
			{ title: '강마루', value: 'KC_00001' },
			{ title: '강화마루', value: 'KC_00002' },
			{ title: '원목마루', value: 'KC_00003' },
		],
		good_name_list: {
			gc_01: '강마루',
			gc_02: '강화마루',
			gc_03: '원목마루',
		},
	}),
	computed: {
		...mapGetters([
			'AGENCY_GET_ESTIMATE_SELECT',
			'HOME_GET_INTERIOR_ESTIMATE_EXCLUSIVE_AREA',
			'HOME_GET_INTERIOR_ESTIMATE_PRODUCT',
		]),
	},
	watch: {
		estimateModel(val) {
			console.log('estimateModel', val, this.originEstimateModel)
			if (this.originEstimateModel.value !== val.value && this.originEstimateModel !== null) {
				this.modifyData()
			}
		},
	},
	async created() {
		if (Object.keys(this.AGENCY_GET_ESTIMATE_SELECT).length === 0) this.$router.go(-1)
		this.getList()
		await this.getData()
	},
	mounted() {},
	methods: {
		...mapMutations(['APP_MU_DIALOG']),
		...mapActions(['AGENCY_ACT_ESTIMATE', 'HOME_ACT_INTERIOR_ESTIMATE_EXCLUSIVE_AREA', 'HOME_ACT_INTERIOR_ESTIMATE_PRODUCT']),
		// 견적상담 상세정보 가져오기
		async getList() {
			const items = {
				method: 'detail',
				query: this.AGENCY_GET_ESTIMATE_SELECT.consult_id,
			}
			await this.AGENCY_ACT_ESTIMATE(items).then(res => {
				console.log('AGENCY_ACT_ESTIMATE', res, this.AGENCY_GET_ESTIMATE_SELECT)
				this.estimateItems = res[0]

				// state value추출
				const stateItem = this.common_forValueFind(this.estimateItems, 'corp_card_list', 3)

				this.estimateModel = this.admin_estimate_consult_statusItems.find(item => item.value === stateItem.consult_state)
				this.originEstimateModel = this.estimateModel
			})
		},
		async getData() {
			// 전용면적, 방 데이터 가져오기
			if (this.exclusive_area === null) {
				await this.HOME_ACT_INTERIOR_ESTIMATE_EXCLUSIVE_AREA() // 평수
				await this.HOME_ACT_INTERIOR_ESTIMATE_PRODUCT() //제품목록
			}
			if (this.HOME_GET_INTERIOR_ESTIMATE_EXCLUSIVE_AREA.length !== 0) {
				this.exclusive_area = this.HOME_GET_INTERIOR_ESTIMATE_EXCLUSIVE_AREA.filter(
					v => v.exclusive_area === this.estimateItems.exclusive_area,
				)
			}
		},
		async modifyData() {
			await this.AGENCY_ACT_ESTIMATE({
				method: 'put',
				params: {
					consult_id: this.AGENCY_GET_ESTIMATE_SELECT.consult_id,
					consult_state: this.estimateModel.value,
				},
			})
		},
	},
}
</script>

<style scoped lang="scss">
// 제목
h3 {
	font-family: 'NotoSansKR-Bold' !important;
	font-size: 20px !important;
}
.sub_tit {
	padding: 12px 0 !important;
	border-bottom: 1px solid #262626;
	h4 {
		font-family: 'NotoSansKR-Bold' !important;
		font-size: 18px !important;
		color: #262626;
	}
}

// 컨텐츠
.bg_grey {
	padding: 20px !important;
	background-color: #f1f2f3;
}
.register .body {
	background-color: #fff;
}
.register .body .basic_info {
	padding: 20px 28px;
	border-bottom: none !important;
}

.notice {
	li {
		position: relative;
		text-align: left;
		font-family: 'NotoSansKR-Regular';
		font-size: 13px !important;
		list-style: none;
		color: #5f6062;
		// &::before {
		// 	display: block;
		// 	content: '*';
		// 	position: absolute;
		// 	top: 3px;
		// 	left: 0;
		// }
	}
}

.estimate_paper {
	padding: 30px 110px;
	background-color: #fff;
	.logo {
		:deep(.v-image) {
			width: 96px;
		}
	}
	.tit {
		padding: 27px 0;
		font-family: 'NotoSansKR-Bold' !important;
		font-size: 28px !important;
		color: #262626;
		border-bottom: 1px solid $color_gray_4;
	}
	.table {
		.th {
			font-family: 'NotoSansKR-Bold' !important;
			font-size: 16px !important;
			color: #262626;
		}
		.td {
			font-family: 'NotoSansKR-Regular';
			font-size: 16px !important;
			color: #262626;
		}
		.price {
			border-top: 1px solid $color_gray_4;
			strong {
				font-size: 26px !important;
			}
		}
	}

	.notice {
		ul {
			padding: 20px !important;
			background-color: #f1f2f3;
		}
		li {
			position: relative;
			padding-left: 10px;
			text-align: left;
			font-family: 'NotoSansKR-Regular';
			font-size: 13px !important;
			list-style: none;
			color: #5f6062;
			&::before {
				display: block;
				content: '*';
				position: absolute;
				top: 3px;
				left: 0;
			}
		}
	}
}

.product {
	background-color: #fff;
}

.tit {
	font-weight: $fw_bold;
	color: #262626;
	font-size: $font_xl;
	padding-bottom: 20px;
	letter-spacing: -0.7px;
}
.finalCont {
	border: 1px solid #cccccc;
	border-radius: 8px;
	margin: 0;
	padding: 16px;
	flex-direction: column;
	flex-wrap: initial;

	.tit {
		font-weight: $fw_bold;
		color: #262626;
		font-size: $font_lg;
		letter-spacing: -0.7px;
		padding-bottom: 0;
	}

	.innerBox {
		background-color: $color_gray_1;
		padding: 0 10px;
		margin: 10px 0;
		border-radius: 4px;
		li {
			list-style: none;
			text-align: left;
		}
	}

	.innerBox02 {
		//background-color: $color_gray_1;
		text-align: left;
		img {
		}

		.listItem {
			width: 100%;
			display: flex;
			align-items: center;
			p {
				&:nth-of-type(1) {
					width: 30%;
					min-width: 80px;
				}
				&:nth-of-type(2) {
				}
			}
		}
	}

	.contentArea {
		background-color: $color_gray_1;
		padding: 8px 14px;
		margin-top: 6px;
		border-radius: 4px;
	}
}

.titType01 {
	color: $color_font;
	font-size: $font_normal;
	text-align: left;
	font-weight: $fw_bold;
}
.titType02 {
	color: $color_gray_6;
	font-size: $font_normal;
	text-align: left;
}
.titType03 {
	color: $color_font;
	font-size: $font_lg;
	text-align: left;
	font-weight: $fw_regular;
}

.askcontImg {
	flex: none;
	max-width: 56px;
	max-height: 56px;
	height: 56px;
	border-radius: 4px;
}

.custom_quote {
	.title {
		max-width: 622px;
		margin: 0 auto;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.inner {
		max-width: 622px;
		padding: 30px 30px 30px !important;
		//margin: 30px auto;
		background-color: $color_white;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
		border-radius: 16px;
		position: relative;
		z-index: 3;
		top: 30px;
		h3 {
			font-size: $font_xl;
		}
		.confirmMember {
			p {
				font-size: $font_lg;
				color: #262626;
				font-weight: $fw_regular;
			}
			a {
				font-size: $font_normal;
				color: #666666;
				text-decoration: underline;
				font-weight: $fw_normal;
			}
		}
		.nonmemberConfirm {
			max-width: 840px;
		}
	}
}

.panel {
	border: 1px solid #cccccc;
	border-radius: 8px;
	background-color: #f8f8f8;
	margin-top: 16px;
	.innerTitle {
		font-size: $font_normal;
		color: #555555;
	}
	.detailWrap {
		> div {
			padding: 2px 0;
		}
	}
	.detailTit {
		color: #777777;
		font-size: $font_normal;
		min-width: 150px;
	}
	:deep(.v-expansion-panel-header) {
		padding: 16px;
		background-color: #f8f8f8;
		min-height: 42px;
		border-radius: 8px;
	}
	:deep(.v-expansion-panel-content) {
		background-color: #f8f8f8;
		padding: 0 16px 16px !important;
	}
	:deep(.v-expansion-panel-header--active) {
		background-color: #f8f8f8;
	}
	:deep(.v-expansion-panel-content) {
		padding: 0;
	}
	:deep(.v-expansion-panel-content__wrap) {
		padding: 0;
	}
	:deep(.v-expansion-panel) {
		background-color: transparent;
	}
}

@media all and (min-width: 960px) and (max-width: 1263px) {
}
@media all and (min-width: 601px) and (max-width: 959px) {
	.estimate_paper {
		padding: 30px !important;
		.tit {
			padding: 26px 0;
			font-size: 26px !important;
		}
		.table {
			.th {
				font-size: 14px !important;
			}
			.td {
				font-size: 14px !important;
			}
			.price {
				strong {
					font-size: 24px !important;
				}
			}
		}
		.notice {
			ul {
				padding: 14px !important;
			}
			li {
				font-size: 13px !important;
			}
		}
	}
}
@media all and (max-width: 600px) {
	.estimate_paper {
		padding: 15px !important;
		.tit {
			padding: 24px 0;
			font-size: 18px !important;
		}
		.table {
			.th {
				font-size: 13px !important;
			}
			.td {
				font-size: 13px !important;
			}
			.price {
				strong {
					font-size: 22px !important;
				}
			}
		}
		.notice {
			ul {
				padding: 14px !important;
			}
			li {
				font-size: 12px !important;
			}
		}
	}
	.custom_quote {
		.inner {
			border-radius: 0;
			box-shadow: none;
			padding: 15px !important;
		}
	}
}
</style>
