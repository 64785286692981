<template>
	<v-btn
		block
		text
		tile
		class="justify-start pa-0"
		:class="className"
		@click="$emit('click')"
	>
		<v-icon
			v-if="model"
			size="22"
			color="primary"
		>
			check_circle
		</v-icon>
		<v-icon
			v-else
			size="22"
			color="grey"
		>
			check_circle_outline
		</v-icon>
		<span
			v-if="text"
			class="checkbox_text pl-1"
			>{{ text }}</span
		>
	</v-btn>
</template>

<script>
import model from '@/mixins/model'

export default {
	name: 'Checkbox02',
	components: {},
	mixins: [model],
	props: {
		text: {
			type: String,
			default: '',
		},
		className: {
			type: String,
			default: '',
		},
	},
	data: () => ({}),
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
}
</script>
<style scoped lang="scss">
.checkbox_text {
	font-size: $font_normal;
	font-weight: $fw_bold;
	color: $color_gray_8;
}
</style>
