var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","width":"1000"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pa-0"},[_c('AdminItemsTitle',{attrs:{"title":"팝업 내용 확인"},on:{"close":function($event){return _vm.$emit('close')}}}),_c('v-col',{staticClass:"pa-0 pa-md-3"},[_c('v-container',{staticClass:"admin_table"},[_c('v-row',{staticClass:"ma-0 admin_table_area"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","md":"6"}},[_c('AdminItemsTableItem',{attrs:{"title":"제목","text":_vm.readItem.title}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","md":"6"}},[_c('AdminItemsTableItem',{attrs:{"title":"사용여부","text":_vm.readItem.use_yn}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('AdminItemsTableItem',{attrs:{"title":"노출기간","text":_vm.readItem.showDate.join(' ~ '),"fill":true}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('AdminItemsTableItem',{attrs:{"title":"노출위치","text":_vm.readItem.location,"fill":true}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('AdminItemsTableItem',{attrs:{"title":"팝업창 종류","text":_vm.readItem.popup_kind,"fill":true}})],1),(_vm.readItem.popup_kind === 'IMAGE')?_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('AdminItemsTableItem',{attrs:{"title":"이미지","text-button":{
								text: _vm.readItem.popup_url,
								button: {
									text: '이미지',
									color: 'primary',
								},
							},"fill":true},on:{"click":function($event){return _vm.common_onLink(_vm.readItem.popup_url)}}})],1):_vm._e(),(_vm.readItem.popup_kind === 'IMAGE' && _vm.readItem.popuplink_url)?_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('AdminItemsTableItem',{attrs:{"title":"랜딩링크","text-button":{
								text: _vm.readItem.popuplink_url,
								button: {
									text: '링크보기',
									color: 'primary',
									link: _vm.readItem.popuplink_url,
								},
							},"fill":true}})],1):_vm._e(),(_vm.readItem.popup_kind === 'VIDEO')?_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('AdminItemsTableItem',{attrs:{"title":"영상링크","text":_vm.readItem.popup_url,"fill":true}})],1):_vm._e(),(_vm.readItem.popup_kind === 'EDITOR')?_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('AdminItemsTableItem',{attrs:{"title":"내용","text-button":{
								text: _vm.readItem.content,
								button: {
									text: 'Viewer 열기',
									color: 'primary',
								},
							},"fill":true},on:{"click":function($event){_vm.onViewDialog = true}}})],1):_vm._e(),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","md":"6"}},[_c('AdminItemsTableItem',{attrs:{"title":"등록자","text":_vm.readItem.regi_userid}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","md":"6"}},[_c('AdminItemsTableItem',{attrs:{"title":"등록일","text":_vm.readItem.regi_date}})],1)],1)],1),_c('v-row',{staticClass:"ma-0",attrs:{"align":"center","justify":"end"}},[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3","lg":"2"}},[_c('v-btn',{attrs:{"color":"error","block":"","tile":"","elevation":"0"},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("clear")]),_c('span',{staticClass:"ml-2"},[_vm._v("닫기")])],1)],1)],1)],1)],1),_c('CommonEditorQuillViewerDialog',{attrs:{"dialog":_vm.onViewDialog,"html":_vm.readItem.content},on:{"close":function($event){_vm.onViewDialog = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }