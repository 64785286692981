import { render, staticRenderFns } from "./confirm_03.vue?vue&type=template&id=dd12ef16&scoped=true&"
import script from "./confirm_03.vue?vue&type=script&lang=js&"
export * from "./confirm_03.vue?vue&type=script&lang=js&"
import style0 from "./confirm_03.vue?vue&type=style&index=0&id=dd12ef16&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd12ef16",
  null
  
)

export default component.exports