<template>
	<div
		class="list_area"
		:class="fiterClass"
	>
		<v-col
			cols="12"
			class="pa-0 interior_list_area"
			:style="selectInterior ? 'margin-bottom:125px' : ''"
			align="start"
		>
			<v-col
				v-for="(items, i) in filterShowItems"
				:key="i"
				class="pa-0"
			>
				<!-- 인테리어점 리스트 -->
				<CommonCardMenuCardInteriorSelect03
					v-if="tab === 0"
					:item="items"
				/>
				<!-- 전문가 집들이 리스트 -->
				<CommonCardMenuCardHouse
					v-else
					:item="items"
				/>
			</v-col>

			<div
				v-if="filterItems.length > showItemsCount"
				class="btn_bar pt-5"
				align="center"
			>
				<CommonButtonsButton02
					:name="`더보기 (${showItemsCount}/${filterItems.length})`"
					class-name="btn_more"
					color="#70b341"
					outlined
					@click="$emit('showCount')"
				/>
			</div>

			<!-- 내용없음 -->
			<div
				v-if="filterItems.length === 0"
				class="pa-5 no_filter"
				align="center"
			>
				<v-icon
					x-large
					class="mb-3"
				>
					error_outline
				</v-icon>
				<p>검색하신 조건에 대한 검색결과가 없습니다.</p>
			</div>
		</v-col>
	</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
	name: 'HomeInteriorStoreListItemsList',
	props: ['tab', 'filterItems', 'showItemsCount', 'selectInterior'],
	data: () => ({
		fiterClass: '',
	}),
	computed: {
		...mapGetters(['HOME_GET_TAB_SELECT']),
		filterShowItems() {
			return this.filterItems.slice(0, this.showItemsCount) || []
		},
		checkTab() {
			return this.tab
		},
	},
	watch: {
		checkTab(val) {
			this.HOME_MU_TAB_SELECT(val)
		},
	},
	async created() {},
	mounted() {
		if (this.$route.path === '/interior/store') {
			this.fiterClass = 'filterClass'
		}
	},
	destroyed() {},
	updated() {},
	methods: {
		...mapMutations(['HOME_MU_TAB_SELECT']),
	},
}
</script>

<style scoped lang="scss">
// .list_area {
// 	margin-bottom: 50%;
// }
// .list_area.filterClass {
// 	margin-bottom: 117px;
// }
.no_filter {
	.v-icon {
		color: #a0a1a5;
	}
	p {
		font-size: $font_lg;
		color: $color_gray_6;
		word-break: keep-all;
	}
}

@media all and (max-width: 959px) {
	.no_filter {
		p {
			font-size: $font_sm;
		}
	}
	.filterClass {
		margin-bottom: 180px;
	}
}
</style>
