<template>
	<v-row
		justify="space-around"
		class="ma-0"
	>
		<v-tabs
			background-color="deep-purple"
			center-active
		>
			<v-tab
				v-for="(item, i) in parentsItems"
				:key="i"
				@click="selectParents(item)"
			>
				{{ item.text }}
			</v-tab>
		</v-tabs>
		<v-row class="ma-0">
			<v-col
				cols="12"
				class="pa-0 select_background"
			>
				<v-list
					dense
					cols="6"
					class="select_box_07 pa-0 scrollable-div"
					:max-height="areaHeight"
				>
					<div class="sel_wrap">
						<v-list-item
							v-for="(item, i) in childItems"
							:key="i"
							@click="selectChild(item)"
						>
							<v-col
								class="items"
								:class="{ activeClass: i === activeIndex }"
								@click="selectCheck2(item, i)"
							>
								<CommonTooltipType03
									:title="item.text"
									:tooltip="item.tooltip"
									:is-tooltip="item.tooltip != null"
								/>
							</v-col>
							<!-- <v-col
								v-if="selectCheck(item)"
								cols="4"
								class="pa-0"
							>
								<v-icon color="#42883d">mdi-check</v-icon>
							</v-col> -->
						</v-list-item>
					</div>
				</v-list>
			</v-col>
		</v-row></v-row
	>
</template>

<script>
import model from '@/mixins/model'
import { mapGetters } from 'vuex'

export default {
	name: 'Select07',
	mixins: [model],
	props: {
		parentsItems: {
			type: Array,
			default: () => [],
		},
		childItems: {
			type: Array,
			default: () => [],
		},
		label: {
			type: String,
			default: '',
		},
		className: {
			type: String,
			default: '',
		},
		selectText: {
			type: String,
			default: '',
		},
		selectId: {
			type: String,
			default: '',
		},
		height: {
			type: Number,
			default: 50,
		},
		areaHeight: {
			type: Number,
			default: 150,
		},
		disabled: {},
		readonly: {},
	},
	data: () => ({
		menuSelectModel: false,
		activeIndex: null,
	}),
	computed: {
		...mapGetters(['APP_GET_APP_STATE']),
		selectTitle() {
			if (this.model) return this.selectText
			return this.label
		},
	},
	watch: {
		model(val) {
			console.log('model', val)
		},
	},
	methods: {
		selectParents(item) {
			console.log(item)
			this.$emit('select-parents', item)
		},
		selectChild(item) {
			this.menuSelectModel = false
			this.$emit('select-child', item)
		},
		selectCheck(item) {
			if (this.model && this.selectId) {
				if (this.model[this.selectId] === item[this.selectId]) {
					return true
				}
			}
			return false
		},
		selectCheck2(item, idx) {
			this.activeIndex = idx
		},
	},
}
</script>
<style scoped lang="scss">
:deep(.v-slide-group__content) {
	align-items: center;
}
:deep(.v-tabs-bar) {
	background: transparent !important;
}
:deep(.v-tab) {
	border-radius: 50px;
	border: 1px solid #cccccc;
	margin: 0 4px;
	padding: 7px 13px !important;
	max-width: inherit;
	min-width: inherit;
	color: #666666;
	font-size: $font_normal;
}
:deep(.v-tab--active) {
	border: 1px solid #00ce7d !important;
	box-shadow: 0px 2px 8px 0px #00000026;
	color: #00ce7d !important;
	font-weight: bold !important;
}
:deep(.v-slide-group__next) {
	position: absolute;
	right: 0;
	top: 12px;
}
:deep(.v-slide-group__prev) {
	display: none !important;
}
:deep(.v-tabs-slider-wrapper) {
	display: none;
}

:deep(.v-slide-group__next) {
	background: url('../../../assets/sliderArrowRight.png') no-repeat !important;
	width: 31px;
	height: 31px;
	right: -27px;
	> i {
		display: none;
	}
}
.select_box_07 {
	font-size: 1rem;
	display: flex;
	flex-wrap: wrap;
	height: 100%;
	overflow-y: scroll;
	background: #f8f8f8;
	.sel_wrap {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		padding: 10px;
		:deep(.v-list-item) {
			width: 50%;
			padding: 0;
			min-height: 45px;
			.items {
				color: #666666;
			}
			&:hover {
				background: transparent !important;
				//border-radius: 20px;
				&::before {
					opacity: 0;
				}
				.items {
					background: #eee;
					border-radius: 20px;
					padding: 6px 12px;
				}
			}
		}
	}
	:deep(.v-list-item) {
		flex: inherit;
	}
	&_area {
		background-color: $color_gray_1;
	}
	&_active {
		background-color: #e6ece9;
	}
}
.select_btn_text {
	font-size: 1rem;
	font-weight: 500;
	&_dark {
		color: #fff;
	}
	&_light {
		color: #7a9d67;
	}
}
.select_background {
	background-color: #f8f8f8 !important;
	border-right: 1px solid $color_gray_1 !important;
	&_area {
		background-color: $color_gray_1;
	}
}

.activeClass {
	background: #e6e6e6;
	border-radius: 20px;
	padding: 6px 12px;
	font-weight: bold;
	color: #000 !important;
}

/* 사용자 지정 스크롤 스타일 */
.scrollable-div::-webkit-scrollbar {
	width: 4px; /* 스크롤 바의 너비 */
}

.scrollable-div::-webkit-scrollbar-track {
	background-color: #f8f8f8; /* 스크롤 바 트랙의 배경색 */
}

.scrollable-div::-webkit-scrollbar-thumb {
	background-color: #d1d1d1; /* 스크롤 바의 색상 */
	border-radius: 4px; /* 스크롤 바의 모서리 둥글게 */
}

.scrollable-div::-webkit-scrollbar-thumb:hover {
	background-color: #555; /* 마우스를 가져다 댔을 때 스크롤 바의 색상 */
}
</style>
