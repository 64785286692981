<template>
    <v-row class="ma-0">
        <v-col class="tit pa-0" cols="12" md="2">기본 회원 정보</v-col>
        <v-col class="tip pa-0 pt-1 pt-md-0" cols="12" md="9" align-self="center">기본 회원정보가 일치하지 않으면, 마이페이지 > 회원정보 수정을 통해 변경해주세요.</v-col>
    </v-row>
</template>

<script>
export default {
    name: 'CustomRegisterBasicTitle',
	data: ()=>({
	}),
    components: {
    },
	computed: {
	},
	watch: {
	},
    created(){
    },
    mounted(){
    },
    methods:{
    }
}
</script>
<style scoped lang="scss">
</style>

