<template>
	<v-row
		class="ma-0"
		align="center"
	>
		<!-- 파일선택버튼 -->
		<v-col
			cols="4"
			md="3"
			lg="2"
			class="pa-0"
		>
			<CommonButtonsButton01
				name="파일선택"
				color="#78C046"
				class="btn_file"
				height="40"
				:disabled="readonly"
				@click="$refs.fileUpload.$refs.input.click()"
			/>
		</v-col>

		<!-- 인풋 -->
		<v-col
			cols="8"
			md="9"
			lg="10"
			class="pa-0"
		>
			<div class="ml-1">
				<v-file-input
					ref="fileUpload"
					v-model="model"
					:multiple="multiple"
					:placeholder="placeholder"
					:disabled="disabled"
					:readonly="readonly"
					outlined
					hide-details
					dense
					height="40"
					:show-size="1000"
					@change="fileChange"
				>
					<template v-slot:selection="{ index, text, file }">
						<span
							v-if="index < 2 && Object.keys(file).length === 0"
							class="file_name"
							@click="preventClick(false, text)"
						>
							{{ text }}
						</span>
						<a
							v-else-if="index < 2 && text"
							href="javascript:void(0)"
							class="file_name"
							@click="preventClick(true, file)"
						>
							파일 확인하기
						</a>
						<!-- <v-chip
							v-if="index < 2"
							color="primary accent-4"
							dark
							label
							small
						>
							{{ text }}
						</v-chip> -->

						<span
							v-else-if="index === 2"
							class="text-overline grey--text text--darken-3 mx-2"
							>+{{ model.length - 2 }} File(s)</span
						>
					</template>
				</v-file-input>
			</div>
		</v-col>
	</v-row>
</template>

<script>
import model from '@/mixins/model'

export default {
	name: 'InputFile01',
	components: {},
	mixins: [model],
	props: {
		label: {
			type: String,
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
		multiple: {
			type: Boolean,
			default: false,
		},
		disabled: {},
		readonly: {},
		removeIcon: {
			type: Boolean,
			default: false,
		},
		hideIcon: {},
	},
	data: () => ({
		fileModel: null,
	}),
	computed: {},
	watch: {
		model(val) {
			console.log('first file', val)
		},
	},
	created() {},
	mounted() {
		if (this.hideIcon !== undefined) {
			let select = document.getElementsByClassName('v-input__icon--prepend')
			for (let element of select) {
				element.innerHTML = ''
			}
		}
	},
	methods: {
		fileChange(event) {
			console.log(event)
			if (event === null) return
			if (event.name.length > 50) {
				this.$toastr.error('파일이름을 50자 이내로 지정해주세요.', '파일이름 오류', { timeOut: 2500 })
				return this.$refs.fileUpload.reset()
			}
			if (event.size > 200000000) {
				this.$toastr.error('전송할 수 있는 크기 범위를 넘었습니다.', '파일 크기 오류', { timeOut: 2500 })
				return this.$refs.fileUpload.reset()
			}
			if (this.imageTypeCheck(event.type)) {
				this.$toastr.error('첨부할 수 없는 파일형식 입니다.', '파일 오류', { timeOut: 2500 })
				return this.$refs.fileUpload.reset()
			}
		},
		imageTypeCheck(type) {
			let check = true
			switch (type) {
				case 'image/png':
					check = false
					break
				case 'image/jpg':
					check = false
					break
				case 'image/jpeg':
					check = false
					break
				case 'image/webp':
					check = false
					break
				case 'image/gif':
					check = false
					break
				case 'application/pdf':
					check = false
					break
			}
			return check
		},
		preventClick(isOpen, url, event) {
			console.log('isOpen', isOpen)
			if (isOpen) window.open(url, '_blank')
			event.stopPropagation()
			event.preventDefault()
		},
	},
}
</script>

<style scoped lang="scss">
@mixin ellipsis {
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.btn_file {
	:deep(.v-btn__content) {
		span {
			font-size: $font_normal;
			color: $color_white;
		}
	}
}

:deep(.v-input__prepend-outer) {
	display: none;
}
:deep(.v-file-input__text--placeholder) {
	font-size: $font_normal;
	color: $color_gray_11;
}
:deep(.v-input__slot) {
	fieldset {
		border: 1px solid #ddd !important;
	}
}

:deep(.v-text-field__slot) {
	width: 85%;
	.file_name {
		@include ellipsis;
		font-size: $font_sm;
		border-bottom: 1px solid $color_primary_3;
		color: $color_primary_3;
	}
}
:deep(.v-input__append-inner) {
	width: 15%;
	.v-icon {
		&.mdi-close {
			&::before {
				content: '\F015A';
				color: $color_gray_7;
				font-size: 20px;
			}
		}
	}
	.v-input__icon {
		justify-content: end;
	}
}
</style>
