export default {
    data:() => ({
        kakao_post_post:'',
        kakao_post_addressBasic:'',
    }),
    watch:{},
    computed:{
    },
    created(){
        const scriptElem = document.createElement('script')

        // script download
        // scriptElem.onload = () => this.kakao_post_init()
        scriptElem.src = `https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js`

        document.head.appendChild(scriptElem)
    },
    mounted(){
    },
    methods:{
        async kakao_post_init() {
            new window.daum.Postcode({
                oncomplete: function(data) {
                    console.log(data)
                    // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분입니다.
                    // 예제를 참고하여 다양한 활용법을 확인해 보세요.
                }
            }).open()
        },
        async kakao_post_api_call(success, method){
            await new window.daum.Postcode({
                oncomplete: async (data) => {
                    console.log(data)
                    // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
                    let fullRoadAddr = data.roadAddress; // 도로명 주소 변수
                    let extraRoadAddr = ''; // 도로명 조합형 주소 변수

                    // 법정동명이 있을 경우 추가한다. (법정리는 제외)
                    // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
                    if(data.bname !== '' && /[동|로|가]$/g.test(data.bname)){
                        extraRoadAddr += data.bname;
                    }

                    // 건물명이 있고, 공동주택일 경우 추가한다.
                    if(data.buildingName !== '' && data.apartment === 'Y'){
                        extraRoadAddr += (extraRoadAddr !== '' ? ', ' + data.buildingName : data.buildingName);
                    }

                    // 도로명, 지번 조합형 주소가 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
                    if(extraRoadAddr !== ''){
                        extraRoadAddr = ' (' + extraRoadAddr + ')';
                    }

                    // 도로명, 지번 주소의 유무에 따라 해당 조합형 주소를 추가한다.
                    if(fullRoadAddr !== ''){
                        fullRoadAddr += extraRoadAddr;
                    }

                    // 우편번호와 주소 정보를 해당 필드에 넣는다.
                    this.kakao_post_post = data.zonecode;

                    //5자리 새우편번호 사용
                    this.kakao_post_addressBasic = fullRoadAddr;

                    success({
                        post: this.kakao_post_post,
                        address: this.kakao_post_addressBasic
                    })
                }
            }).open()
        }
    }
}
