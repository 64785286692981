<template>
	<v-col
		cols="12"
		class="px-0"
		align="center"
	>
		<v-card class="feature_tit02 pt-5 pb-5">
			<p>패턴정보</p>
			<span>다양한 재질을 비교해보고 취향에 맞는 선택하세요.</span>
		</v-card>
		<v-row class="ma-0 pb-3">
			<v-col
				v-for="(item, i) in items"
				:key="i"
				cols="6"
				sm="4"
				md="3"
				class="pa-0 px-0 pt-3"
			>
				<div class="ma-2 ma-md-4">
					<v-col
						cols="12"
						class="pa-0 hoverAction"
					>
						<v-img
							width="100%"
							height="116"
							:src="item.image"
							eager
							@click="moveToDetail(item)"
						/>
					</v-col>
					<p class="pattern_name mt-sm-3 mt-1">{{ item.text }}</p>
				</div>
			</v-col>
		</v-row>
	</v-col>
</template>

<script>
export default {
	name: 'HomeProductDetailPattern',
	components: {},
	props: ['items'],
	data: () => ({}),
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		moveToDetail(item) {
			//const res = this.keepOnlyNumbers(item.text)
			//this.$router.push(`/product/detail/${item.id}`)
			window.open(`/product/detail/${item.id}`, '_blank')
		},
		// keepOnlyNumbers(value) {
		// 	return value.replace(/\D/g, '')
		// },
	},
}
</script>
<style scoped lang="scss">
.pattern_name {
	font-family: 'NotoSansKR-Bold' !important;
	font-size: 15px !important;
}
.v-image {
	height: 168px;
}
.hoverAction {
	&:hover {
		cursor: pointer;
	}
}
@media all and (min-width: 981px) and (max-width: 1280px) {
}
@media all and (min-width: 769px) and (max-width: 980px) {
	.v-image {
		height: 133px !important;
	}
}
@media all and (min-width: 380px) and (max-width: 768px) {
	.v-image {
		height: 133px !important;
	}
}
@media all and (max-width: 380px) {
	.v-image {
		height: 133px !important;
	}
	.pattern_name {
		font-size: 12px !important;
	}
}
</style>
