<template>
	<v-menu
		offset-y
		open-on-click
		open-on-hover
		z-index="10000"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-col
				class="pa-0 pl-2"
				v-bind="attrs"
				v-on="on"
			>
				<div class="d-flex">
					<v-icon
						class="mr-1 hidden-md-and-down"
						color="#B4B4B4"
						width="16"
						>mdi-account-circle-outline</v-icon
					>
					<span class="txt"> MY동화 </span>
				</div>
			</v-col>
		</template>
		<v-list>
			<div class="pa-2">
				<v-col
					class="pa-0"
					align="center"
				>
					<p
						v-if="user.name"
						class="user_name mb-3"
					>
						{{ user.name }}님
					</p>
					<p
						v-if="user.manager_name"
						class="user_name mb-3"
					>
						{{ user.manager_name }}님
					</p>
				</v-col>
				<v-divider class="mb-3" />
				<v-col class="pa-0 px-2">
					<v-col
						v-for="menu in userMenu"
						:key="menu.title"
						align="center"
						class="list clickCursor pa-0 mb-2"
						@click="pushLink(menu)"
						>{{ menu.title }}</v-col
					>
				</v-col>
			</div>
		</v-list>
	</v-menu>
</template>

<script>
import { mapActions } from 'vuex'

export default {
	name: 'Mypage',
	components: {},
	props: {
		user: {
			type: Object,
			default: () => {},
		},
		isLogin: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({
		userMenu: [
			{
				title: '마이페이지',
				to: '/user',
			},
			{
				title: '스크랩',
				to: '/user?tab=1',
			},
			{
				title: '로그아웃',
				to: '/login',
			},
		],
	}),
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		...mapActions(['AUTH_ACT_LOGOUT']),
		pushLink(menu) {
			if (menu.title === '로그아웃') this.AUTH_ACT_LOGOUT()
			this.$router.push(menu.to).catch(() => {})
		},
	},
}
</script>

<style scoped lang="scss">
.v-list {
	background: $color_white;
	border: 1px solid $color_gray_4;
	border-radius: 8px;
	overflow: hidden !important;
}
.txt {
	font-size: $font_normal;
	color: $color_gray_7;
}

.user_name {
	font-size: $font_normal;
	font-weight: $fw_bold;
}
.list {
	font-size: $font_normal;
}
</style>
