<template>
	<div class="wrapper">
		<v-container
			class="pa-0 px-0 px-md-3"
			fluid
		>
			<div class="custom_quote">
				<v-col
					cols="12"
					class="pa-0 register_history inner"
					align="center"
				>
					<!-- <v-col
						cols="12"
						class="pa-0"
						align="center"
					>
						<div class="content_title">
							간편상담 신청내역 학인
						</div>
					</v-col>

					<p class="txt">
						회원으로 간편 상담을 신청하신 고객님께서는 <br />
						로그인 후 마이페이지 > 간편상담 메뉴 메뉴를 통해서 신청 내역을 확인해주세요.
					</p> -->
					<div class="">
						<v-col
							class="pa-0"
							cols="12"
							align="center"
						>
							<v-row
								v-if="GUEST_GET_ESTIMATE.length !== 0"
								class="ma-0 justify-content-center nonmemberConfirm"
							>
								<v-col class="pa-0">
									<HomeInteriorPriceDetailinquireCommonFormNonmember
										:selected-item="GUEST_GET_ESTIMATE_DETAIL"
									/>
								</v-col>
							</v-row>
						</v-col>
					</div>
				</v-col>
			</div>
		</v-container>
		<v-col cols="12">
			<div
				class="mt-5 mb-10 d-flex justify-center"
				align="center"
			>
				<CommonButtonsButton02
					name="목록"
					class-name="btn_large ml-1"
					color="#262626"
					outlined
					@click="backList"
				/>
			</div>
		</v-col>
	</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import common from '@/mixins/common'
import sms from '@/mixins/sms'

export default {
	name: 'HomeInteriorMypageConfirm03',
	mixins: [common, sms],
	props: ['userdata'],

	data: () => ({}),
	computed: {
		...mapGetters(['GUEST_GET_ESTIMATE', 'GUEST_GET_ESTIMATE_DETAIL', 'GUEST_GET_MYPAGE_INFO']),
		checkGuestRoute() {
			return this.$route.path.includes('/guest')
		},
	},
	watch: {},

	created() {
		if (this.GUEST_GET_MYPAGE_INFO === 2) {
			window.history.pushState(null, null, window.location.href)
			window.onpopstate = this.handlePopState
		}
	},
	mounted() {},
	destroyed() {
		window.onpopstate = null
	},
	methods: {
		...mapMutations(['GUEST_MU_ESTIMATE_SELECT', 'GUEST_MU_MYPAGE_INFO']),
		backList() {
			this.GUEST_MU_MYPAGE_INFO(1)
		},
		handlePopState(event) {
			if (this.checkGuestRoute && this.GUEST_GET_MYPAGE_INFO === 2) {
				this.GUEST_MU_MYPAGE_INFO(1)
			}
		},
	},
}
</script>

<style scoped lang="scss">
.custom_quote {
	.title {
		max-width: 622px;
		margin: 0 auto;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.inner {
		max-width: 622px;
		padding: 30px 30px 30px !important;
		margin: 30px auto;
		background-color: $color_white;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
		border-radius: 16px;
		position: relative;
		z-index: 3;
		h3 {
			font-size: $font_xl;
		}
		.confirmMember {
			p {
				font-size: $font_lg;
				color: #262626;
				font-weight: $fw_regular;
			}
			a {
				font-size: $font_normal;
				color: #666666;
				text-decoration: underline;
				font-weight: $fw_normal;
			}
		}
		.nonmemberConfirm {
			max-width: 840px;
		}
	}
}
.txt {
	font-weight: $fw_bold;
	font-size: $font_mid;
}
@media all and (max-width: 960px) {
	.custom_quote {
		.inner {
			box-shadow: none;
			border-radius: 0;
			padding: 15px !important;
		}
	}
}
</style>
