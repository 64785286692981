<template>
	<div class="mx-0 my-2">
		<v-col
			class="pa-0"
			cols="4"
			align-self="center"
		>
			<p class="tit">상세견적</p>
		</v-col>
		<div>
			<ul>
				{{
					this.newmodel[0].radioValues
				}}
				/
				{{
					this.newmodel[1].radioValues
				}}
				/
				{{
					this.newmodel[2].radioValues
				}}
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	name: 'GreenDongwhaClientRepo05Form',

	props: {
		formdata: {
			Type: Object,
			default: '',
		},
		currentpage: {
			Type: Number,
			require: true,
		},
		activereset: {
			Type: Boolean,
			default: false,
		},
		finaldata: {
			Type: Object,
			default: '',
		},
	},

	data() {
		return {
			model: {
				radioValues: '',
			},
			newmodel: [],
		}
	},

	computed: {
		updatedata() {
			return {
				radioValues: this.model.radioValues,
				netarea: this.model.netarea,
				roomcount: this.model.roomcount,
			}
		},
	},
	watch: {
		updatedata(newval, oldval) {
			if (newval) {
				this.$emit('formevent', newval, 'form4')
			}
		},
		activereset(newval, oldval) {
			if (newval) {
				return (this.model.radioValues = '')
			}
		},
	},

	created() {
		for (let item of Object.values(this.finaldata)) {
			this.newmodel.push(item)
		}
	},

	mounted() {},

	methods: {},
}
</script>

<style lang="scss" scoped></style>
