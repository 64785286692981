<template>
	<div class="pl-0 ma-md-0 click_price_button_home click_price_button_fb">
		<v-col
			v-if="items.length"
			class="py-3 estimate"
		>
			<v-carousel
				ref="carousel"
				v-model="currentIndex"
				hide-delimiters
				hide-delimiter-background
				:show-arrows="false"
				class="slide_type03"
				:interval="interval"
				height="auto"
			>
				<v-carousel-item
					v-for="(slide, i) in filterItems"
					:key="i"
				>
					<div
						cols="12"
						class="pb-4"
					>
						<v-fade-transition mode="out-in">
							<v-row>
								<v-col
									v-for="(item, j) in slide"
									:key="j"
									cols="12"
									class="ma-0 pb-2 content"
									align="center"
									:class="{ 'content-last': j === slide.length - 1 }"
									@click="pushLink(item)"
								>
									<!-- @click="pushLink(item)" -->
									<!-- <CommonCardMenuCardDefaultBest new-tab-link :item="item" /> -->
									<div class="bestThumb clickCursor">
										<div>
											<v-img
												:src="item.image"
												eager
												cover
												width="62"
												height="62"
												class="bg-grey-lighten-2 thumb"
											>
												<!-- <v-chip class="ma-0 flag" color="primary" label>
                            BEST
                          </v-chip> -->
											</v-img>
										</div>
										<div class="cont">
											<div
												v-if="item?.kind_name"
												class="theme mt-2 px-2"
											>
												<span class="brand_info">
													{{ item.kind_name }}
												</span>
											</div>
											<div
												v-if="item?.title"
												class="item_category mb-2 title px-2"
											>
												{{ item.title }}
											</div>
											<div
												v-if="!item?.title"
												class="item_category mb-2 title px-2"
											>
												{{ item.brand_name + item.pattern_name }}
											</div>
										</div>
									</div>
								</v-col>
							</v-row>
						</v-fade-transition>
					</div>
				</v-carousel-item>
			</v-carousel>
			<div class="carousel-nav">
				<button @click="prev(currentIndex)">
					<v-img
						:src="require('@/assets/img/home/main/img_slide_left_arrow_gray.svg')"
						eager
					/>
				</button>
				<span class="page_count">
					<em>{{ currentIndex + 1 }}</em> / {{ Math.ceil(items.length / 3) }}
				</span>
				<button @click="next(currentIndex)">
					<v-img
						:src="require('@/assets/img/home/main/img_slide_right_arrow_gray.svg')"
						eager
					/>
				</button>
			</div>

			<!-- <div
				v-if="recentList.length"
				class="box"
			>
				<v-col
					v-for="(item, i) in recentList"
					:key="i"
					class="pa-5 borderTop"
				>
					<LayoutFloatMenuMenu
						:menu="item"
						class="clickCursor"
						@submit="selectItem"
					/>
				</v-col>
			</div>
			<div class="control d-flex">
				<v-btn
					class="mr-2"
					@click="prevItem"
				>
					<v-icon>chevron_left</v-icon>
				</v-btn>
				<p>
					<span class="done">{{ checkIndex + 1 }}</span>
					<span> / </span>
					<span class="all">{{ recentList.length }}</span>
				</p>
				<v-btn
					class="ml-2"
					@click="nextItem"
				>
					<v-icon>chevron_right</v-icon>
				</v-btn>
			</div> -->
		</v-col>
		<v-col
			v-else
			class="py-3 estimate"
		>
			<p>최근 본 제품이 존재하지 않습니다.</p>
		</v-col>
	</div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
	name: 'HomeMainNewRecent',
	props: ['items'],
	data: () => ({
		alert: false,
		checkIndex: 0,
		currentIndex: 0,
		interval: 5000,
	}),
	computed: {
		filterItems() {
			let items = []
			let item = []
			this.items.forEach((element, i) => {
				if (i % 3 === 0) {
					item = []
					items.push(item)
				}
				item.push(element)
			})
			return items
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		selectItem(item) {
			this.alert = false
			this.common_link_pusher(item)
		},

		pushLink(item) {
			if (item.kind_name === '3D 집들이') {
				this.$router.push(`/housewarming/3d/detail/${item.id}`)
			} else if (item.kind_name === '전문가 집들이') {
				this.$router.push(`/housewarming/specialist/detail/${item.id}`)
			} else {
				this.$router.push(`/product/detail/${item.product_id}`)
			}
		},
		next() {
			this.currentIndex += 1
		},
		prev() {
			this.currentIndex -= 1
		},
	},
}
</script>

<style lang="scss" scoped>
.estimate {
	width: 100%;
	//height: 470px;
	background-color: #fff;
	border-radius: 10px;
	.tit {
		font-family: 'NotoSansKR-Bold' !important;
		font-size: $font_tit !important;
		color: #262626;
		padding: 0 12px;
		.tac {
			text-align: left;
		}
	}
	.tac {
		text-align: left;
	}
	.cal {
		background-color: #555555;
		border-radius: 10px;
		color: #262626;
		.tit {
			font-family: 'NotoSansKR-Light' !important;
			font-size: 14px !important;
			padding-left: 0;
			color: #fff;
		}
		strong {
			font-size: 18px !important;
			color: #fff;
			.constvalue {
				font-family: 'NotoSansKR-Light' !important;
				font-weight: 400;
				font-size: 14px !important;
				padding-left: 5px;
			}
		}
		.notice {
			font-family: 'NotoSansKR-Light' !important;
			font-size: 12px !important;
			color: #fff;
		}
		:deep(.v-image__image) {
			background-size: auto;
		}
	}
}

.btn_upload02 {
	font-size: $font_lg !important;
	font-weight: $fw_bold;
	color: $color_white;
}

.resetcalc {
	cursor: pointer;
}

.all_view {
	text-decoration: underline;
	color: #555555;
	&:hover {
		cursor: pointer;
	}
}

// 두줄이상 (...)처리
@mixin ellipsis_2 {
	display: -webkit-box !important;
	word-wrap: break-word !important;
	-webkit-line-clamp: 2 !important;
	-webkit-box-orient: vertical !important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
}

@mixin ellipsis {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	word-break: keep-all;
	overflow: hidden;
	text-overflow: ellipsis;
}

.flag {
	padding: 6px 10px;
	border-radius: 99px;
	color: $color_white;
	line-height: 1 !important;
	&.best {
		padding: 4px 6px 4px 6px;
		font-size: $font_normal;
		font-weight: $fw_normal;
		background-color: $color_acc_1;
		margin: 15px 0 0 15px;
	}
}

.v-window.slide_type03 {
	overflow: inherit;
	border-radius: 10px;
	height: auto !important;
	.content {
		border-bottom: 1px solid #e6e6e6;
		&:nth-child(3n + 0) {
			border-bottom: 1px solid transparent;
		}
	}
	.content-last {
		border-bottom: 1px solid transparent !important;
	}
	.bestThumb {
		overflow: hidden;
		display: flex;
		justify-content: start;
		align-items: center;
		.cont {
			display: flex;
			flex-direction: column;
			align-items: start;
			justify-content: center;
		}
		:deep(.v-image) {
			max-height: 170px;
			object-fit: cover;
			aspect-ratio: 1/1;
		}
		.theme {
			display: flex;
			align-items: center;
			span {
				@include ellipsis_2;
			}
		}
		.item_category {
			font-size: $font_normal !important;
			font-weight: $fw_regular;
			color: #262626;
			letter-spacing: 0 !important;
			text-align: left;
			line-height: normal !important;
			@include ellipsis_2;
		}
		.thumb {
			border-radius: 4px;
		}
		.brand_info {
			//font-weight: $fw_semibold;
			font-size: $font_sm;
			color: #666666;
		}
	}
	:deep(.v-carousel__controls) {
		bottom: -33px;
	}
	:deep(.v-image__image--contain) {
		background-size: cover !important;
	}
	:deep(.v-item-group) {
		button::before {
			display: none;
		}
		.v-btn--icon {
			width: 10px;
			height: 10px;
		}
		.v-item--active.v-btn--active .v-icon.notranslate {
			background-color: #42883d;
		}
		.v-icon.notranslate {
			width: 10px;
			height: 10px;
			justify-content: space-between;
			background-color: #ccc;
			opacity: 1;
			border-radius: 4px;
		}
		.v-icon.notranslate::before {
			display: none !important;
		}
		.theme {
			text-align: left;
			padding: 0 15px;
			min-height: 42px;
			display: flex;
			align-items: center;
		}
	}

	:deep(.theme) {
		text-align: left;
	}

	.text_area {
		max-width: 360px;
		position: absolute;
		bottom: 50%;
		transform: translate(0, 50%);
		right: 20px;
		background-color: rgba(1, 1, 1, 0.5);

		:deep(.v-image) {
			height: 36px !important;
			margin-bottom: 5px;
		}
		:deep(.v-image__image--contain) {
			background-size: inherit !important;
		}
	}
}

.item {
	background-color: $color_white;
	border-radius: 16px;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
	overflow: hidden;

	.category {
		color: $color_white;
		a {
			color: $color_white;
			text-decoration: none;
			p {
				font-size: $font_tit;
				font-weight: $fw_bold;
				line-height: 1 !important;
			}
			span {
				word-break: keep-all;
				font-size: $font_normal;
				font-weight: $fw_normal;
			}
		}
	}

	.cont {
		position: relative;
		margin-top: -20px;
		border-radius: 16px !important;
		background-color: $color_white;
		h3 {
			color: $color_font;
			font-size: $font_xl;
			font-weight: $fw_bold;
		}
	}

	.bg_knowhow {
		position: relative;
		width: 100%;
		height: 205px;
		background: url('../../../../assets/img/home/main/bg_best.png') no-repeat;
		background-size: cover;
		background-position: top;
		.category {
			position: absolute;
			bottom: 15%;
			left: 20px;
		}
		&::after {
			content: '';
			background: linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
			width: 100%;
			height: 100%;
			display: block;
		}
	}
}

.carousel-nav {
	width: 78px;
	height: 26px;
	left: 15px;
	padding: 10px;
	border: 1px solid #e6e6e6;
	border-radius: 220px;
	gap: 10px;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;
	.page_count {
		color: #777777;
		font-weight: $fw_normal;
		font-size: $font_sm;
		em {
			font-style: normal;
			font-weight: $fw_semibold;
		}
	}
}

@media all and (max-width: 1264px) {
	.item {
		.cont {
			margin-top: 0 !important;
			border-radius: 0 !important;
		}
		.bg_maru_tv {
			padding: 20px 16px !important;
		}
		.bg_knowhow {
		}
	}
	.v-window.slide_type03 {
		.bestThumb {
			:deep(.v-image) {
				max-height: 144px;
				width: 100%;
			}
		}
	}
}

@media all and (max-width: 600px) {
	.item {
		border-radius: 0 !important;
		.bg_knowhow {
			height: 205px;
			background-size: cover;
			background-position: 0px 0;
			.category {
				line-height: 32px;
				bottom: 10%;
			}
		}
	}
	.v-window.slide_type03 {
		.bestThumb {
			:deep(.v-image) {
				max-height: 144px;
			}
		}
	}
}

@media all and (max-width: 380px) {
	.item {
		.bg_knowhow {
			height: 130px;
			max-height: 130px;
			.category {
				p {
					font-size: $font_xl !important;
					line-height: 22px !important;
				}
				span {
					font-size: $font_sm !important;
				}
			}
		}
		.cont {
			h3 {
				font-size: $font_lg !important;
			}
		}

		.main_youtube {
			.desc {
				font-size: $font_normal !important;
			}
		}
	}
}

@media all and (max-width: 600px) {
	.click_price_button_home {
		margin: 0 !important;
	}
}
</style>
