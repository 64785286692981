<template>
	<v-btn
		block
		text
		tile
		class="pa-0"
		:block="block"
		:class="className"
		@click="model = !model"
	>
		<v-icon
			v-if="model"
			size="22"
			color="primary"
		>
			check_circle
		</v-icon>
		<v-icon
			v-else
			size="22"
			color="#d8d8d8"
		>
			check_circle_outline
		</v-icon>
		<div class="pl-1">
			<span
				v-if="subText"
				class="checkbox_text"
			>
				{{ subText }}
			</span>
			<a
				v-if="link"
				class="link ml-1 mr-0 pr-0"
				@click="common_newLink(href)"
			>
				{{ link }}
			</a>
			<a
				v-if="allowlink"
				class="link mr-0 pr-0 text-decoration-underline"
				@click="common_newLink(href)"
			>
				{{ allowlink }}
			</a>
			<span
				v-if="text"
				class="checkbox_text"
				:class="activeEvent && model ? 'activeColor' : ''"
			>
				{{ text }}
			</span>
		</div>
	</v-btn>
</template>

<script>
import model from '@/mixins/model'
import common from '@/mixins/common'

export default {
	name: 'Checkbox02',
	components: {},
	mixins: [model, common],
	props: {
		subText: {
			type: String,
			default: '',
		},
		link: {
			type: String,
			default: '',
		},
		href: {
			type: String,
			default: '',
		},
		text: {
			type: String,
			default: '',
		},
		className: {
			type: String,
			default: '',
		},
		block: {
			type: Boolean,
			default: true,
		},
		allowlink: {
			type: String,
			default: '',
		},
		activeEvent: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({}),
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
}
</script>
<style scoped lang="scss">
.checkbox_text {
	display: inline-block;
	font-size: $font_sm;
	color: $color_gray_6;
	line-height: 20px;
}
.link {
	color: $color_font;
	font-weight: $fw_regular;
}
.v-btn {
	height: 30px !important;
	:deep(.v-btn__content) {
		display: inline-block;
		text-align: left;
		.v-icon {
			float: left;
		}
	}
}

.careflo {
	.checkbox_text {
		display: inline-block;
		padding-top: 4px;
		font-size: $font_mid;
		font-weight: $fw_regular;
		color: $color_font;
		line-height: 1;
	}
}

.activeColor {
	color: $color_font;
}
</style>
