<template>
	<v-dialog
		v-model="dialog"
		persistent
		width="1000"
	>
		<v-card class="pa-0">
			<AdminItemsTitle
				:title="mode === 'create' ? `${title} 등록` : `${title} 수정`"
				@close="$emit('close')"
			/>
			<v-col class="pa-3 pa-md-5">
				<v-container class="admin_table">
					<h3 class="py-3 pl-2">NFC Code 정보</h3>
					<v-row class="ma-0 admin_table_area">
						<v-col
							cols="12"
							md="6"
							class="pa-0"
						>
							<AdminItemsTableItem
								v-model="models.nfc_code"
								title="NFC Code"
								input
							/>
						</v-col>
						<v-col
							cols="12"
							md="6"
							class="pa-0"
						>
							<AdminItemsTableItem
								title="NFC URL"
								:text-button="nfc_url"
							/>
						</v-col>
					</v-row>
					<h3 class="py-3 pl-2">시공정보</h3>
					<v-row class="ma-0 admin_table_area">
						<v-col
							cols="12"
							md="6"
							class="pa-0"
						>
							<AdminItemsTableItem
								title="시공일자"
								:text="models.operator_date"
							/>
						</v-col>
						<v-col
							cols="12"
							md="6"
							class="pa-0"
						>
							<AdminItemsTableItem
								title="시공자 연락처"
								:text="models.operator_hand"
							/>
						</v-col>
						<v-col
							v-if="mode === 'edit'"
							cols="12"
							md="12"
							class="pa-0"
						>
							<AdminItemsTableItem
								v-model="models.care_type"
								title="서비스 구분"
								:radio-group="admin_as_care_statusItems.slice(1)"
								:fill="true"
							/>
						</v-col>
						<v-col
							cols="12"
							md="12"
							class="pa-0"
						>
							<AdminItemsTableItem
								title="바코드 정보"
								:text-button="barcode"
								:fill="true"
								@click="confirmBarcode = true"
							/>
						</v-col>
						<v-col
							cols="12"
							md="12"
							class="pa-0"
						>
							<AdminItemsTableItem
								title="이미지"
								:text="models.img"
								:fill="true"
							/>
						</v-col>
					</v-row>
					<h3 class="py-3 pl-2">고객정보</h3>
					<v-row class="ma-0 admin_table_area">
						<v-col
							cols="12"
							md="12"
							class="pa-0"
						>
							<AdminItemsTableItem
								title="고객명"
								:text-button="customer"
								:fill="true"
								@click="confirmCustomer = true"
							/>
						</v-col>
						<v-col
							cols="12"
							md="12"
							class="pa-0"
						>
							<AdminItemsTableItem
								title="고객연락처"
								:text="models.cust_hand"
								:fill="true"
							/>
						</v-col>
						<v-col
							cols="12"
							md="12"
							class="pa-0"
						>
							<AdminItemsTableItem
								title="고객주소"
								:text="models.address"
								:fill="true"
							/>
						</v-col>
						<v-col
							cols="12"
							md="12"
							class="pa-0"
						>
							<AdminItemsTableItem
								title="고객 정보 등록일"
								:text="models.regi_date"
								:fill="true"
							/>
						</v-col>
						<v-col
							cols="12"
							md="12"
							class="pa-0"
						>
							<AdminItemsTableItem
								title="A/S 신청 횟수"
								:text="models.as_count?.toString()"
								:fill="true"
							/>
						</v-col>
					</v-row>
				</v-container>
				<v-row
					class="ma-0"
					align="center"
					justify="center"
				>
					<v-col
						cols="12"
						md="6"
						class="pl-0"
					>
						<v-row class="ma-0">
							<v-col cols="6">
								<v-btn
									color="error"
									block
									tile
									elevation="0"
									@click="$emit('close')"
								>
									<v-icon>clear</v-icon>
									<span class="ml-2">취소</span>
								</v-btn>
							</v-col>
							<v-col
								v-if="mode === 'create'"
								cols="6"
							>
								<v-btn
									color="primary"
									block
									tile
									elevation="0"
									:disabled="allowValue"
									@click="onApiCallItem"
								>
									<v-icon>add</v-icon>
									<span class="ml-2">등록</span>
								</v-btn>
							</v-col>
							<v-col
								v-if="mode === 'edit'"
								cols="6"
							>
								<v-btn
									color="secondary"
									block
									tile
									elevation="0"
									:disabled="allowValue"
									@click="onApiCallItem"
								>
									<v-icon>edit</v-icon>
									<span class="ml-2">수정</span>
								</v-btn>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-col>
		</v-card>
		<CommonLoading v-if="loading" />
		<CommonConfirm
			:dialog="confirmBarcode"
			:title="``"
			text="바코드 정보를 초기화하겠습니까?"
			mobile
			@close="confirmBarcode = false"
			@submit="reset('deleteOperatorNfc')"
		/>
		<CommonConfirm
			:dialog="confirmCustomer"
			:title="``"
			text="고객 정보를 초기화하겠습니까?"
			mobile
			@close="confirmCustomer = false"
			@submit="reset('deleteCustomerNfc')"
		/>
	</v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import model from '@/mixins/model'
import common from '@/mixins/common'
import admin from '@/mixins/admin'

export default {
	name: 'AdminUserCareFloCreateNfc',
	mixins: [model, common, admin],
	props: ['title', 'dialog', 'mode', 'editItem', 'agentCorno'],
	data: () => ({
		models: {
			agent_corno: null,
			nfc_code: null,
			care_type: null,
			barcode_url: null,
			operator_date: null,
			operator_hand: null,
			barcode: null,
			img: null,
			cust_name: null,
			cust_hand: null,
			address: null,
			regi_date: null,
			as_count: null,
		},
		nfc_url: null,
		barcode: null,
		customer: null,
		origin: {},
		loading: false,
		confirmBarcode: false,
		confirmCustomer: false,
	}),
	computed: {
		...mapGetters(['AUTH_GET_ADMIN_USER']),
		allowValue() {
			// const checkValue = ['agent_corno', 'agent_name', 'agent_tel', 'agent_bossname', 'agent_bosshand']
			// let check = this.common_allowValueCheck(this.models, checkValue)
			// if (check) return true

			const checkText = ['nfc_code']
			let check = this.common_allowTextCheck(this.models, checkText)
			if (check) return true

			return check
		},
	},
	watch: {
		models(val) {
			console.log('models', val)
		},
	},
	async created() {
		if (this.editItem && this.mode === 'edit') this.startDataMapping()
		console.log('editItem', this.editItem)
		this.nfc_url = {
			text: this.editItem.nfc_code,
			button: {
				text: 'NFC 링크',
				color: 'primary',
				link: window.location.origin + '/careflo/' + this.editItem.nfc_code,
			},
		}
		this.barcode = {
			text: this.editItem.barcode,
			button: {
				text: '바코드 정보 초기화',
				color: 'primary',
				link: '',
			},
		}
		this.customer = {
			text: this.editItem.cust_name,
			button: {
				text: '고객 정보 초기화',
				color: 'primary',
				link: '',
			},
		}
	},
	async mounted() {},
	destroyed() {
		this.resetData()
	},
	methods: {
		...mapActions(['ADMIN_ACT_USER_CARE_FLO']),
		startDataMapping() {
			this.models = {
				agent_corno: this.editItem.agent_corno,
				nfc_code: this.editItem.nfc_code,
				care_type: this.editItem.care_type === '원케어' ? 'one' : this.editItem.care_type === '풀케어' ? 'full' : '',
				barcode_url: this.editItem.barcode_url,
				operator_date: this.editItem.operator_date,
				operator_hand: this.editItem.operator_hand,
				barcode: this.editItem.barcode,
				img: this.editItem.barcode_url,
				cust_name: this.editItem.cust_name,
				cust_hand: this.editItem.cust_hand,
				address: this.editItem.address,
				regi_date: this.editItem.regi_date,
				as_count: this.editItem.as_count,
			}
			// if (this.editItem.company_picurl) {
			// 	this.company_picurl = this.editItem.company_picurl
			// } else {
			// 	this.company_picurl = null
			// 	this.models.company_picurl = null
			// }

			// if (this.editItem.company_logourl) {
			// 	this.company_logourl = this.editItem.company_logourl
			// } else {
			// 	this.company_logourl = null
			// 	this.models.company_logourl = null
			// }

			this.origin = JSON.parse(JSON.stringify(this.models))
		},
		resetData() {
			this.models = {
				agent_corno: null,
				nfc_code: null,
				care_type: null,
				barcode_url: null,
				operator_date: null,
				operator_hand: null,
				barcode: null,
				img: null,
				cust_name: null,
				cust_hand: null,
				address: null,
				regi_date: null,
				as_count: null,
			}
			this.origin = {}
		},
		async onApiCallItem() {
			this.loading = true

			const params = {
				agent_corno: this.mode === 'create' ? this.agentCorno : this.models.agent_corno,
				nfc_code: this.models.nfc_code,
				care_type: this.models.care_type,
				barcode_url: this.models.barcode_url,
				operator_date: this.models.operator_date,
				operator_hand: this.models.operator_hand,
				barcode: this.models.barcode,
				img: this.models.img,
				cust_name: this.models.cust_name,
				cust_hand: this.models.cust_hand,
				address: this.models.address,
				regi_date: this.models.regi_date,
				as_count: this.models.as_count,
			}

			const items = { method: this.mode === 'create' ? 'createNfc' : 'editNfc', params: params }

			console.log('items', items)

			try {
				await this.ADMIN_ACT_USER_CARE_FLO(items)
				this.loading = false
				this.$emit('close')
			} catch (e) {
				console.log('ADMIN_ACT_USER_CARE_FLO', e)
				this.loading = false
			}
		},
		async reset(method) {
			const items = { method: method, params: { nfc_code: this.editItem.nfc_code } }
			await this.ADMIN_ACT_USER_CARE_FLO(items).then(res => {
				console.log('ADMIN_ACT_USER_CARE_FLO', res)
				this.$emit('close')
			})
		},
	},
}
</script>

<style scoped lang="scss"></style>
