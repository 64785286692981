<script>
import { Line } from 'vue-chartjs'

export default {
	name: 'LineChart',
	extends: Line,
	props: {
		chartData: {
			type: Array,
			default: () => [
				// {
				// 	name: '일반회원 신규',
				// 	data: [
				// 		{ label: '202301', value: 332 },
				// 		{ label: '202302', value: 946 },
				// 		{ label: '202303', value: 54 },
				// 		{ label: '202304', value: 8 },
				// 	],
				// },
				// {
				// 	name: '인테리어점 신규',
				// 	data: [
				// 		{ label: '202301', value: 935 },
				// 		{ label: '202302', value: 2725 },
				// 		{ label: '202303', value: 536 },
				// 		{ label: '202304', value: 163 },
				// 	],
				// },
			],
		},
		height: {
			type: String,
			default: '100%',
		},
	},
	data() {
		return {
			options: {
				responsive: true,
				responsiveAnimationDuration: 1000,
				maintainAspectRatio: false,
				// devicePixelRatio: 1,
				elements: {
					line: {
						tension: 0.1,
					},
				},
				legend: {
					display: true,
					position: 'bottom',
				},
				scales: {
					xAxes: [
						{
							ticks: {
								maxRotation: 0,
								minRotation: 0,
							},
							barPercentage: 0.3,
							borderRadius: 0.2,
							gridLines: {
								display: false,
								offsetGridLines: false,
							},
						},
					],
					yAxes: [
						{
							ticks: {
								padding: 15,
								beginAtZero: true,
							},
							display: true,
							scaleLabel: {
								display: false,
							},
							gridLines: {
								drawBorder: false,
								display: true,
							},
						},
					],
				},
				plugins: {
					datalabels: {
						display: false,
					},
				},
				layout: {},
			},
			color: ['#57a0e5', '#ed6d85', '#6dbebf', '#f2a354', '#9268f6', '#f7cf6b', '#c9cbcf'],
		}
	},
	watch: {
		chartData() {
			this.draw()
		},
	},
	mounted() {
		this.draw()
	},
	methods: {
		makeData() {
			const { chartData } = this
			const labels = chartData[0]?.data.map(item => item.label) // x축 label 가져오기
			let datasets = []
			chartData?.map((item, i) => {
				let label = item.name
				let data = item.data.map(val => val.value)
				datasets.push({
					label: label,
					data: [...data],
					fill: false,
					borderColor: this.color[i],
				})
			})

			return {
				labels,
				datasets,
			}
		},
		draw() {
			const { options } = this
			const data = this.makeData()

			this.renderChart(data, options)
		},
	},
}
</script>

<style scoped></style>
