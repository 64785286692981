<template>
	<div class="py-4 px-3 pa-md-4 mx-md-7 house_list">
		<v-row class="ma-0">
			<v-col
				class="pa-0"
				cols="3"
				md="3"
			>
				<div class="mr-1">
					<v-img
						:src="item.image_url"
						class="store_view"
						width="100"
						height="100"
						contain
						eager
					/>
				</div>
			</v-col>
			<v-col
				class="pa-0"
				cols="9"
				md="9"
			>
				<div class="ml-1 mt-2">
					<!-- <p class="type">전문가집들이</p> -->
					<p class="tit">{{ item.title }}</p>
					<div class="keyword">
						<span v-if="item.addr_cut">{{ item.addr_cut }}</span>
						<span v-if="item.spacious_name">{{ item.spacious_name }}</span>
						<span v-if="item.style_name">{{ item.style_name }}</span>
					</div>
					<v-col
						class="pa-0 place"
						cols="12"
						align-self="center"
					>
						<!-- <v-icon>mdi-map-marker-outline</v-icon> -->
						{{ item.business_name }}
					</v-col>
				</div>
				<v-row
					v-if="!checkSize"
					class="ma-0"
				>
					<v-col
						class="pa-0 pt-2 pt-md-0"
						cols="5"
						md="5"
					>
					</v-col>
					<v-col cols="7">
						<v-row>
							<v-col
								class="pa-0"
								cols="5"
							>
								<CommonButtonsButton04
									name="상세보기"
									rounded
									outlined
									height="30"
									color="#ccc"
									class="specialDetail"
									:to="`/housewarming/specialist/detail/${item.exphw_id}`"
								/>
							</v-col>
							<v-col
								class="pa-0"
								cols="7"
							>
								<div class="ml-2">
									<v-btn
										rounded
										:color="checkInterior(item).newcolor"
										height="30"
										width="100%"
										class="btHover"
										:outlined="!checkInterior(item).fill"
										:style="`border:${checkInterior(item).border}; background:${checkInterior(item).bg}`"
										@click="goToInquire(item)"
									>
										<v-icon style="top: 0; position: relative">mdi-check</v-icon>
										<span>
											{{ checkInterior(item).name }}
										</span>
									</v-btn>
								</div>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
		<v-row
			v-if="checkSize"
			class="mx-0 pb-3"
		>
			<v-col cols="12">
				<v-row>
					<v-col
						class="pa-0"
						cols="6"
					>
						<CommonButtonsButton04
							name="상세보기"
							rounded
							outlined
							height="30"
							color="#ccc"
							class="specialDetail"
							:to="`/housewarming/specialist/detail/${item.exphw_id}`"
						/>
					</v-col>
					<v-col
						class="pa-0"
						cols="6"
					>
						<div class="ml-2">
							<v-btn
								rounded
								:color="checkInterior(item).newcolor"
								height="30"
								width="100%"
								class="btHover"
								:outlined="!checkInterior(item).fill"
								:style="`border:${checkInterior(item).border}; background:${checkInterior(item).bg}`"
								@click="goToInquire(item)"
							>
								<v-icon style="top: 0; position: relative">mdi-check</v-icon>
								<span>
									{{ checkInterior(item).name }}
								</span>
							</v-btn>
						</div>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import common from '@/mixins/common'

export default {
	name: 'CommonCardMenuHouse',
	mixins: [common],
	props: ['item'],
	data: () => ({
		detailView: false,
	}),
	computed: {
		...mapGetters([
			'HOME_GET_INTERIOR_SELECT_INTERIOR',
			'APP_GET_APP_STATE',
			'APP_GET_APP_WIDTH',
			'APPBAR_GET_INTERIORSTORE_DRAWER',
		]),
		currentPath() {
			return this.common_recentlyRouterPath === '/interior/price/inquire' ? 'components' : 'page'
		},
		checkSize() {
			return this.APP_GET_APP_WIDTH < 960
		},
		checkDrawer() {
			return this.APPBAR_GET_INTERIORSTORE_DRAWER
		},
	},
	watch: {
		checkDrawer(newVal, oldVal) {
			if (newVal === false) {
				this.HOME_MU_INTERIOR_SELECT_INTERIOR(null)
			}
		},
	},
	mounted() {
		console.log('CommonCardMenuHouse', this.item)
	},
	destroyed() {},
	methods: {
		...mapMutations([
			'HOME_MU_INTERIOR_SELECT_INTERIOR',
			'HOME_MU_PICKSTORE_DETAIL',
			'APPBAR_MU_INTERIORSTORE_DRAWER',
			'HOME_MU_CURRENT_ESTIMATE_CONTENT_INTERIORSELECT',
		]),
		originDongWhaCheck(number) {
			return number === '1378102333' ? false : true
		},
		checkInterior(item) {
			const check = this.HOME_GET_INTERIOR_SELECT_INTERIOR.find(x => x.corporate_no === item.corporate_no)
			if (check)
				return {
					name: '간편상담',
					color: '#0dd083',
					fill: false,
					border: '1px solid #0dd083',
					bg: '#0dd083',
					newcolor: '#fff !important',
				}
			// if (this.inquireCheck) return { name: '인테리어점 선택', color: '#fff', fill: false }
			// return { name: '맞춤견적 신청', color: '#fff', fill: false }
			return {
				name: '간편상담',
				color: '#777777',
				fill: false,
				border: '1px solid #D8D8D8',
				bg: 'transparent',
				newcolor: '#777777',
			}
		},
		goToInquire(val) {
			this.HOME_MU_INTERIOR_SELECT_INTERIOR(null)
			this.HOME_MU_INTERIOR_SELECT_INTERIOR(this.item)

			if (val !== null || val !== '') {
				this.APPBAR_MU_INTERIORSTORE_DRAWER(true)
				this.HOME_MU_CURRENT_ESTIMATE_CONTENT_INTERIORSELECT(val)
			}

			//if (this.currentPath === 'page') this.$router.push('/interior/price/inquire').catch(() => {})
		},
		pushLink(corporate_no) {
			//if (this.APP_GET_APP_STATE === 'web') this.common_newLink(`/interior/store/detail/${corporate_no}`)
			//else this.$router.push(`/interior/store/detail/${corporate_no}`)
			this.detailView = true
			this.HOME_MU_PICKSTORE_DETAIL(corporate_no)
		},
	},
}
</script>

<style scoped lang="scss">
// 한줄이상 (...)처리
@mixin ellipsis {
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

// 두줄이상 (...)처리
@mixin ellipsis_2 {
	display: -webkit-box !important;
	word-wrap: break-word !important;
	-webkit-line-clamp: 2 !important;
	-webkit-box-orient: vertical !important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
}
.specialDetail {
	border: 1px solid #d8d8d8 !important;
	min-width: auto !important;
	&:hover {
		background: transparent;
		&:before {
			opacity: 0;
		}
	}
}
.btHover {
	&:hover {
		border: 1px solid #0dd083 !important;
		color: #0dd083 !important;
		&:before {
			opacity: 0;
		}
	}
}

.house_list {
	// box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
	// border-radius: 16px;
	background-color: $color_white;
	border-bottom: 1px solid #e6e6e6;
	.store_view {
		// border-top-right-radius: 8px;
		// border-end-start-radius: 8px;
		border-radius: 4px;
		:deep(.v-image__image--contain) {
			background-size: cover;
		}
	}
	.type {
		font-size: $font_mid;
		font-weight: $fw_regular;
		color: $color_font;
	}
	.tit {
		@include ellipsis;
		font-size: $font_lg;
		font-weight: $fw_regular;
		color: $color_font;
		word-break: keep-all;
	}
	.keyword {
		font-weight: $fw_regular;
		font-size: $font_mid;
		color: $color_gray_6;
		span {
			display: inline-block;
			position: relative;
			padding-right: 10px;
			&:after {
				display: block;
				position: absolute;
				top: 5px;
				right: 4px;
				width: 1px;
				height: 10px;
				background-color: $color_gray_4;
				content: '';
			}
			&:last-child:after {
				display: none;
			}
		}
	}
	.place {
		.v-icon {
			width: 20px;
			font-size: $font_sub_tit;
			color: $color_font;
		}
		font-weight: $fw_regular;
		font-size: $font_mid;
		color: $color_font;
	}
	:deep(.v-btn__content) {
		font-size: $font_normal;
		font-weight: $fw_regular;
		// span {
		// 	color: $color_gray_6 !important;
		// }
	}

	&:hover {
		background: #f8f8f8;
	}
}

.no_filter {
	.v-icon {
		color: #a0a1a5;
	}
	p {
		font-family: 'NotoSansKR-Regular';
		font-size: 16px;
		color: $color_gray_6;
	}
}
.house_list {
	.specialDetail {
		:deep(.v-btn__content) {
			span {
				color: #777777 !important;
			}
		}
	}
}
@media all and (max-width: 600px) {
	.store_view {
		width: 80px !important;
		height: 80px !important;
	}
	.house_list {
		.tit {
			font-size: 14px;
		}
	}
	.no_filter {
		p {
			font-size: 12px;
		}
	}
}
</style>
