<template>
	<v-card
		class="pa-0 card_type"
		elevation="0"
		@click="onClick"
	>
		<v-col class="pa-0 pt-4 px-3 content">
			<div class="pt-5 pb-3">
				<p class="product">{{ menu.kind_name }}</p>
				<p class="date">{{ menu.date }}</p>
			</div>

			<v-divider />

			<div class="py-2 state">
				<p class="state">{{ menu.progress_state_name }}</p>
			</div>
		</v-col>
	</v-card>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
	name: 'UserUserASCard',
	props: ['menu'],
	data: () => ({}),
	computed: {},
	created() {},
	mounted() {},
	methods: {
		...mapMutations(['USER_MU_AS_SELECT']),
		onClick() {
			// this.$emit('submit', this.menu)
			this.USER_MU_AS_SELECT(this.menu)
			this.$router.push(`/user/listDetail/2`)
		},
	},
}
</script>

<style scoped lang="scss">
// .card_type {
// 	width: 190px;
// 	height: 120px;
// 	border-radius: 10px;
// 	box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 5%), 0px 2px 2px 0px rgb(0 0 0 / 5%), 0px 1px 5px 0px rgb(0 0 0 / 10%) !important;
// }
// .product {
// 	font-family: 'NotoSansKR-Bold' !important;
// 	font-size: 15px !important;
// 	color: #262626;
// }
// .date {
// 	font-family: 'NotoSansKR-Regular' !important;
// 	font-size: 14px !important;
// 	color: #262626;
// }
// .state {
// 	font-family: 'NotoSansKR-Regular' !important;
// 	font-size: 14px !important;
// 	color: #f97729;
// }
// 한줄이상 (...)처리

@mixin ellipsis {
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

// 두줄이상 (...)처리
@mixin ellipsis_2 {
	display: -webkit-box !important;
	word-wrap: break-word !important;
	-webkit-line-clamp: 2 !important;
	-webkit-box-orient: vertical !important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
}
.card_type {
	width: 190px;
	//height: 308px;
	height: 208px;
	border-radius: 10px;
	box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 5%), 0px 2px 2px 0px rgb(0 0 0 / 5%), 0px 1px 5px 0px rgb(0 0 0 / 10%) !important;
	.content {
		.tit {
			width: 40px !important;
			height: 20px !important;
			font-family: 'NotoSansKR-Regular' !important;
			font-size: 13px !important;
			color: #262626;
			background-color: #f1f2f3;
			border-radius: 4px;
		}
		.tit02 {
			height: 20px !important;
			font-family: 'NotoSansKR-Regular' !important;
			font-size: 13px !important;
			color: #262626;
			background-color: #f1f2f3;
			border-radius: 4px;
		}
		.date {
			font-family: 'NotoSansKR-Medium';
			font-size: 14px !important;
			color: #262626;
		}
		.cont_tit {
			@include ellipsis;
			font-family: 'NotoSansKR-Bold' !important;
			font-size: 14px !important;
			font-weight: bold;
			color: #262626;
			line-height: 1.2;
		}
		.cont {
			@include ellipsis;
			//height: 35px;
			height: 25px;
			font-family: 'NotoSansKR-Regular' !important;
			font-size: 13px !important;
			color: #262626;
			line-height: 1.2;
		}
	}
	.state {
		font-family: 'NotoSansKR-Regular' !important;
		font-size: 14px !important;
		color: #f97729;
		&.clear {
			color: #fff;
			background-color: #42883d;
		}
		&.wait {
			color: $color_gray_9;
			background-color: #e7e7e7;
		}
	}
}
@media all and (max-width: 600px) {
	.card_type {
		width: 100%;
		height: auto;
		padding-bottom: 10px !important;
	}
}
@media all and (max-width: 440px) {
}
@media all and (max-width: 350px) {
}
</style>
