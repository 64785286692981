<template>
	<v-select
		v-model="model"
		:items="items"
		:class="className"
		:height="height"
		:disabled="disabled"
		:readonly="readonly"
		:item-value="
			item => {
				return item
			}
		"
		append-icon="mdi-chevron-down "
		dense
		outlined
		single-line
		hide-details
		@change="handleChange"
	>
	</v-select>
</template>

<script>
import model from '@/mixins/model'

export default {
	name: 'Select08',
	components: {},
	mixins: [model],
	props: {
		items: {
			type: Array,
			default: () => [],
		},
		label: {
			type: String,
			default: '',
		},
		className: {
			type: String,
			default: '',
		},
		height: {
			type: Number,
			default: 40,
		},
		disabled: {},
		readonly: {},
		// placeholder: {
		// 	type: String,
		// 	default: '선택',
		// },
	},
	data: () => ({
		previousModel: null, // 이전 선택 값을 저장할 변수 추가
	}),
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		// handleChange(newValue) {
		// 	if (confirm('선택하시겠습니까?')) {
		// 		this.model = newValue
		// 		this.previousModel = newValue // 변경된 값으로 이전 선택 값을 업데이트
		// 		this.$emit('change', model)
		// 	} else {
		// 		// 취소한 경우 이전 선택 상태로 되돌리거나 원하는 동작 수행
		// 		//this.model = null
		// 		if (this.previousModel !== null) {
		// 			// 이전 선택 값이 있는 경우에만 이전 값으로 롤백
		// 			this.model = this.previousModel
		// 			this.$emit('change', model)
		// 		}
		// 	}
		// },
		handleChange(newValue, oldValue) {
			if (confirm('선택하시겠습니까?')) {
				this.model = newValue
				this.$emit('change', model)
			} else {
				const other = this.items.findIndex(v => v.value === this.model.value)
				console.log('?????', other)
				this.previousModel = this.items[other]
				this.model = this.previousModel
				this.$emit('change', this.model)
			}
		},
	},
}
</script>

<style scoped lang="scss">
:deep(.v-input) {
	font-size: 15px;
}
:deep(.v-input__slot) {
	fieldset {
		border: 1px solid #ddd !important;
	}
}
:deep(.v-input__control) {
	border-radius: 5px;
	background-color: #fff;
}
:deep(.v-select__selection) {
	font-size: 15px;
	color: #262626;
}
:deep(.v-select-list) {
	margin-top: 40px !important;
}
:deep(.v-label) {
	font-size: 15px !important;
}
:deep(.v-input__append-inner) {
	.v-input__icon {
		min-width: 0 !important;
		width: 10px !important;
	}
	.v-icon {
		color: #262626;
	}
}
:deep(.v-label--active) {
	transform: translateY(-20px) scale(0.7) !important;
}

// 인테리어하기 > 견적내기
.option.v-text-field--outlined {
	border-radius: 0 !important;
	border: 1px solid $color_gray_4 !important;
	height: 50px !important;
	:deep(.v-input__slot) {
		height: 50px !important;
	}
	:deep(.v-input__control) {
		background-color: transparent !important;
	}
	:deep(fieldset) {
		border-width: 0 !important;
	}
	:deep(.v-label) {
		font-size: 16px !important;
		top: 14px !important;
	}
	:deep(.v-input__append-inner) {
		margin-top: 14px !important;
	}
}

// 빠른견적내기
.v-input.fast {
	height: 36px !important;
	:deep(.v-input__control) {
		min-height: 36px !important;
		height: 36px !important;
	}
	:deep(.v-input__slot) {
		min-height: 36px !important;
		height: 36px !important;
	}
	:deep(.v-label) {
		top: 8px;
		font-size: 13px !important;
	}
}

@media all and (min-width: 960px) and (max-width: 1263px) {
}
@media all and (min-width: 600px) and (max-width: 959px) {
}
@media all and (max-width: 600px) {
	:deep(.v-input) {
		font-size: 13px;
	}
	:deep(.v-select__selection) {
		font-size: 13px;
	}

	// 인테리어하기 > 견적내기
	.option.v-text-field--outlined {
		:deep(.v-label) {
			font-size: 13px !important;
		}
	}
}
</style>
