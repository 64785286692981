<template>
	<v-navigation-drawer
		id="inquireDrawer"
		v-model="drawer"
		absolute
		temporary
		right
		width="412"
		class="inquireDrawer"
	>
		<v-list-item class="px-0">
			<v-list-item-content>
				<v-row class="px-4">
					<v-col class="contAsk">간편상담 신청하기</v-col>
					<v-col
						class="pa-0 pr-0"
						align-self="center"
						style="text-align: right"
					>
						<v-btn
							icon
							text
							@click="closeDrawer"
						>
							<v-icon color="#666">mdi-window-close</v-icon>
						</v-btn>
					</v-col>
				</v-row>
			</v-list-item-content>
		</v-list-item>

		<v-divider></v-divider>

		<v-list
			class="pa-0 ma-0"
			dense
		>
			<v-col
				md="12"
				sm="12"
				class="pa-0 contents_table layout_align"
			>
				<div class="fixed-tabs-bar">
					<v-row
						v-if="!checkresult"
						class="ma-0"
					>
						<v-col
							col="12"
							class="confirmMySubmitWrap py-2 px-0"
						>
							<div class="mx-5 confirmMySubmit">
								<span class="txt"> 이미 신청하셨나요? </span>
								<span
									class="confirm"
									@click="confirmMySubmit"
								>
									신청내역 확인
								</span>
							</div>
						</v-col>
					</v-row>
					<HomeInteriorPriceDetailinquireCommonFormMain
						v-if="!checkresult"
						@showResult="showResult"
					/>
					<v-col
						v-else
						cols="12"
						align="center"
						class="pa-0 pt-6"
					>
						<div class="guide_txt">
							<div class="icon">
								<div class="pb-2">
									<v-img
										max-width="30"
										max-height="30"
										:src="require('@/assets/img/estimate/ico_checkDone.svg')"
									/>
								</div>
							</div>
							<p class="tit fw_bold">견적 신청 완료!</p>
						</div>
						<div class="estimateFinal mx-0">
							<v-col class="pa-0 sns item">
								<div class="px-4 ma-0">
									<HomeInteriorPriceDetailinquireCommonFinal
										:selected-item="HOME_GET_CURRENT_ESTIMATE_CONTENT"
										:selected-userinfo="HOME_GET_CURRENT_ESTIMATE_USERINFO"
										@closePop="closePop"
									/>
								</div>
							</v-col>
						</div>
					</v-col>
				</div>
			</v-col>
		</v-list>
	</v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
	name: 'InquireDrawer',
	data() {
		return {
			items: [
				{ title: 'Home', icon: 'mdi-view-dashboard' },
				{ title: 'About', icon: 'mdi-forum' },
			],
			showresult: false,
		}
	},
	computed: {
		...mapGetters([
			'APPBAR_GET_INQUIRE_DRAWER',
			'HOME_GET_CURRENT_ESTIMATE_CONTENT',
			'HOME_GET_CURRENT_ESTIMATE_USERINFO',
			'AUTH_GET_USER_AUTH',
		]),
		drawer: {
			get() {
				return this.APPBAR_GET_INQUIRE_DRAWER
			},
			set(value) {
				// 다른곳클릭시 닫기
				if (value === false) {
					this.closeDrawer()
				}
			},
		},
		checkresult() {
			return this.showresult
		},
	},
	watch: {},
	mounted() {},
	methods: {
		...mapMutations(['APPBAR_MU_INQUIRE_DRAWER']),
		closeDrawer() {
			if (this.drawer) {
				this.APPBAR_MU_INQUIRE_DRAWER(false)
				this.showresult = false
			}
		},
		closePop(val) {
			if (val) {
				if (this.drawer) {
					this.APPBAR_MU_INQUIRE_DRAWER(false)
					this.showresult = false
				}
			}
		},
		confirmMySubmit() {
			if (this.AUTH_GET_USER_AUTH === 'USER') return this.$router.push('/user?tab=2')
			else if (this.AUTH_GET_USER_AUTH === 'GUEST') return this.$router.push('/interior/mypage/guest')
		},
		showResult(val) {
			if (val === true) this.showresult = true
		},
	},
}
</script>

<style lang="scss" scoped>
.confirmMySubmitWrap {
	background-color: #f8f8f8;
	.confirmMySubmit {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.txt {
			color: #666666;
			font-size: $font_sm;
		}
		.confirm {
			border: 1px solid #9e9e9e;
			background-color: #fff;
			border-radius: 4px;
			padding: 4px 6px;
			font-size: $font_sm;
			&:hover {
				cursor: pointer;
			}
		}
	}
}
.contAsk {
	font-weight: $fw_bold;
	font-size: $font_xl;
	color: $color_font;
}
.inquireDrawer {
	z-index: 100;
	//height: 100vh !important;
	position: fixed;
	overflow: auto;
}

// @media all and (max-width: 959px) {
// 	.estimateFinal {
// 		border-radius: 0;
// 		box-shadow: none;
// 		max-width: 100%;
// 	}
// }

@media all and (max-width: 600px) {
	.inquireDrawer {
		width: 100% !important;
	}
}
</style>
