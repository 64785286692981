<template>
	<div>
		<v-col
			cols="12"
			class="pa-0 pb-2"
		>
			<v-row
				class="ma-0"
				align="center"
			>
				<v-col
					cols="6"
					class="pa-0"
				>
					전체: {{ desserts.length }}건
				</v-col>
				<v-spacer />
				<v-col
					cols="6"
					md="3"
					class="table-search pa-0"
				>
					<v-text-field
						v-model="search"
						append-icon="mdi-magnify"
						single-line
						hide-details
					></v-text-field>
				</v-col>
			</v-row>
		</v-col>
		<v-data-table
			id="virtual-scroll-table"
			v-model="model"
			:headers="headers"
			:page.sync="page"
			:items-per-page="itemsPerPage"
			hide-default-footer
			:items="desserts"
			:search="search"
			:show-select="showSelect"
			:class="className"
			:loading="loading"
			item-key="id"
			elevation="0"
			:style="`max-height:${height};`"
			:expanded.sync="expanded"
			:show-expand="showExpand"
			single-expand
			@page-count="pageCount = $event"
		>
			<template v-slot:item.state="{ item, header }">
				<td
					class="admin_grid_text"
					:style="`max-width:${header.width}`"
				>
					<p v-if="item.state">ON</p>
					<p v-else>OFF</p>
				</td>
			</template>
			<template v-slot:item.use_yn="{ item, header }">
				<td
					class="admin_grid_text"
					:style="`max-width:${header.width}`"
				>
					<p v-if="item.use_yn === 'Y'">ON</p>
					<p v-else>OFF</p>
				</td>
			</template>
			<template v-slot:item.title="{ item, header }">
				<td
					class="admin_grid_text"
					:style="`max-width:${header.width}`"
				>
					{{ item.title }}
				</td>
			</template>
			<template v-slot:item.contents="{ item, header }">
				<td @click="expanded = [item]">
					<p
						class="admin_grid_contents"
						:style="`max-width:${header.width};`"
						v-html="item.contents"
					/>
				</td>
			</template>
			<template v-slot:item.recomm_prod="{ item }">
				<a
					:href="`/product/detail/${item.recomm_prod}`"
					target="_blank"
					>{{ item.recomm_prod }}</a
				>
			</template>
			<template v-slot:item.thumbnail="{ item }">
				<v-btn
					v-if="item.thumbnail"
					outlined
					@click.stop="common_onLink(item.thumbnail)"
				>
					<span class="admin_grid_text">썸네일 확인</span>
				</v-btn>
			</template>
			<template v-slot:item.image="{ item }">
				<v-btn
					v-if="item.image"
					outlined
					@click.stop="common_onLink(item.image)"
				>
					<span class="admin_grid_text">이미지 확인</span>
				</v-btn>
			</template>
			<template v-slot:item.link="{ item }">
				<v-btn
					v-if="item.link"
					outlined
					@click.stop="common_onLink(item.link)"
				>
					<span class="admin_grid_text">링크 확인</span>
				</v-btn>
			</template>
			<template v-slot:item.file="{ item }">
				<v-btn
					v-if="item.file"
					outlined
					@click.stop="common_onLink(item.file)"
				>
					<span class="admin_grid_text">파일 확인</span>
				</v-btn>
			</template>
			<template v-slot:item.data-read="{ item }">
				<v-btn
					icon
					@click.stop="$emit('open', item)"
				>
					<v-icon>mdi-eye-settings</v-icon>
				</v-btn>
			</template>
			<template v-slot:item.data-edit="{ item }">
				<v-btn
					icon
					@click.stop="$emit('edit', item)"
				>
					<v-icon>mdi-pencil</v-icon>
				</v-btn>
			</template>
			<template v-slot:item.data-etc="{ item }">
				<v-btn
					icon
					@click.stop="$emit('etc', item)"
				>
					<v-icon>mdi-wrench</v-icon>
				</v-btn>
			</template>
			<template v-slot:item.data-link="{ item }">
				<v-btn
					icon
					@click.stop="$emit('link', item)"
				>
					<v-icon>mdi-link</v-icon>
				</v-btn>
			</template>

			<template v-slot:no-data> NO DATA HERE! </template>
		</v-data-table>
		<div class="table-pagination text-center pt-2">
			<v-pagination
				v-model="page"
				:length="pageCount"
				:total-visible="totalVisible"
			></v-pagination>
			<v-select
				v-model="itemsPerPage"
				:items="[5, 10, 15, 20]"
				label="개수"
			></v-select>
			<!-- <v-text-field
				:value="itemsPerPage"
				label="Items per page"
				type="number"
				min="-1"
				max="100"
				@input="itemsPerPage = parseInt($event, 10)"
			></v-text-field> -->
		</div>
	</div>
</template>

<script>
import model from '@/mixins/model'
import common from '@/mixins/common'
import admin from '@/mixins/admin'

export default {
	// 페이지네이션 추가버전
	name: 'GridType03',
	mixins: [model, common, admin],
	props: ['headers', 'desserts', 'height', 'readonly', 'className', 'loading', 'showExpand', 'numbersPerPage'],
	data: () => ({
		page: 1,
		pageCount: 0,
		totalVisible: 7,
		itemsPerPage: 11,
		search: '',
		expanded: [],
	}),
	computed: {
		showSelect() {
			return this.readonly === undefined
		},
	},
	watch: {
		loading(val) {
			if (!val) {
				// scroll Top push
				this.common_scrollTop('#virtual-scroll-table')
				this.start = 0
			}
		},
	},
	created() {
		this.itemsPerPage = this.numbersPerPage || 11
	},
	mounted() {},
	methods: {
		changeStatus(item) {
			this.$emit('status', item)
		},
	},
}
</script>
<style scoped lang="scss">
:deep(.v-data-table-header) {
	background: #ededed !important;
}
:deep(.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th) {
	color: #000 !important;
	font-size: 1rem;
	background: #ededed !important;
}
#virtual-scroll-table {
	overflow: auto;
}
.v-data-table {
	border-radius: 0 !important;
	border: 1px solid $color_gray_4;
}
:deep(.v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content) {
	box-shadow: none;
	background-color: #f1f8f3;
}
.table-pagination {
	position: relative;
	> .v-input {
		position: absolute;
		top: 0;
		width: 60px;
	}
}
</style>
