<template>
	<div>
		<v-img
			:src="require('@/assets/img/careflo/careflo_event_detail.png')"
			eager
		/>
		<p
			class="closeBtn"
			@click="event => $emit('close', event)"
		>
			시공 인증 하러가기
		</p>
	</div>
</template>

<script>
export default {
	name: 'CareFloEventPop',
	props: {},
	data: () => ({}),
	computed: {},
	watch: {},
	mounted() {},
	methods: {},
}
</script>

<style scoped lang="scss">
.closeBtn {
	position: fixed;
	bottom: 0;
	width: 100%;
	height: 42px;
	padding: 8px;
	font-size: $font_lg;
	color: white;
	font-weight: 700;
	text-align: center;
	background: black;
}
</style>
