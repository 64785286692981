<template>
	<div class="color_analysis">
		<v-row class="ma-0 mb-3 mb-md-5">
			<v-col
				class="pa-0"
				cols="6"
				align-self="center"
			>
				<div class="subject">색상 분석 결과</div>
			</v-col>

			<v-col
				class="pa-0"
				cols="6"
				align="end"
				align-self="center"
			>
				<span
					class="btn_color"
					@click="confirmReset = true"
				>
					<v-icon
						color="#262626"
						small
					>
						mdi-autorenew
					</v-icon>
					색상 변경
				</span>
			</v-col>
		</v-row>

		<v-row class="ma-0">
			<v-col
				class="pa-0"
				md="4"
				cols="12"
			>
				<div class="pa-4 mr-0 mr-md-2 left">
					<v-row class="ma-0 mb-5 head">
						<v-col
							class="pa-0 left"
							cols="12"
						>
							<span>선택된 색상</span>
						</v-col>
					</v-row>

					<v-row
						v-if="items.objectColor"
						class="ma-0 justify-space-between"
					>
						<v-col
							class="pa-0"
							cols="2"
							align="center"
							align-self="center"
						>
							<v-img
								width="100%"
								max-width="34"
								:src="require('@/assets/img/search/icon_sofa2.svg')"
							/>
							가구
						</v-col>
						<v-col
							class="pa-0 analysis"
							cols="9"
						>
							<div class="drop">
								<svg
									width="28"
									height="32"
									viewBox="0 0 28 32"
									fill="none"
									class="mr-2"
								>
									<path
										d="M4.44076 27.6519C7.03798 30.1728 10.1838 31.4332 13.8783 31.4332C17.5727 31.4332 20.7185 30.1728 23.3158 27.6519C25.913 25.1311 27.2116 22.0856 27.2116 18.5155C27.2116 16.7168 26.8644 15.0544 26.1699 13.5282C25.4755 12.0021 24.5171 10.6395 23.2949 9.44036L13.8783 0.365234L4.46159 9.44036C3.23937 10.6395 2.28103 12.0021 1.58659 13.5282C0.892144 15.0544 0.544922 16.7168 0.544922 18.5155C0.544922 22.0856 1.84353 25.1311 4.44076 27.6519Z"
										:fill="items.objectColor"
									/>
									<path
										d="M13.8783 30.9332C10.3108 30.9332 7.29078 29.7214 4.789 27.2931C2.28727 24.865 1.04492 21.9483 1.04492 18.5155C1.04492 16.783 1.37895 15.1918 2.04169 13.7353C2.70982 12.267 3.63163 10.9554 4.81014 9.79885L13.8783 1.05964L22.9448 9.79727C22.9453 9.7978 22.9458 9.79832 22.9464 9.79885C24.1249 10.9554 25.0467 12.267 25.7148 13.7353C26.3776 15.1918 26.7116 16.783 26.7116 18.5155C26.7116 21.9483 25.4692 24.865 22.9675 27.2931C20.4657 29.7214 17.4457 30.9332 13.8783 30.9332Z"
										stroke="black"
										stroke-opacity="0.1"
									/>
								</svg>
								<span class="color_code">{{ items.objectColorCategory }}</span>
							</div>
						</v-col>
					</v-row>

					<v-row
						v-if="items.wallColor"
						class="ma-0 mt-4 justify-space-between"
					>
						<v-col
							class="pa-0"
							cols="2"
							align="center"
							align-self="center"
						>
							<v-img
								width="100%"
								max-width="34"
								:src="require('@/assets/img/search/icon_wall.svg')"
							/>
							벽
						</v-col>
						<v-col
							class="pa-0 analysis"
							cols="9"
						>
							<div class="drop">
								<svg
									width="28"
									height="32"
									viewBox="0 0 28 32"
									fill="none"
									class="mr-2"
								>
									<path
										d="M4.44076 27.6519C7.03798 30.1728 10.1838 31.4332 13.8783 31.4332C17.5727 31.4332 20.7185 30.1728 23.3158 27.6519C25.913 25.1311 27.2116 22.0856 27.2116 18.5155C27.2116 16.7168 26.8644 15.0544 26.1699 13.5282C25.4755 12.0021 24.5171 10.6395 23.2949 9.44036L13.8783 0.365234L4.46159 9.44036C3.23937 10.6395 2.28103 12.0021 1.58659 13.5282C0.892144 15.0544 0.544922 16.7168 0.544922 18.5155C0.544922 22.0856 1.84353 25.1311 4.44076 27.6519Z"
										:fill="items.wallColor"
									/>
									<path
										d="M13.8783 30.9332C10.3108 30.9332 7.29078 29.7214 4.789 27.2931C2.28727 24.865 1.04492 21.9483 1.04492 18.5155C1.04492 16.783 1.37895 15.1918 2.04169 13.7353C2.70982 12.267 3.63163 10.9554 4.81014 9.79885L13.8783 1.05964L22.9448 9.79727C22.9453 9.7978 22.9458 9.79832 22.9464 9.79885C24.1249 10.9554 25.0467 12.267 25.7148 13.7353C26.3776 15.1918 26.7116 16.783 26.7116 18.5155C26.7116 21.9483 25.4692 24.865 22.9675 27.2931C20.4657 29.7214 17.4457 30.9332 13.8783 30.9332Z"
										stroke="black"
										stroke-opacity="0.1"
									/>
								</svg>
								<span class="color_code">{{ items.wallColorCategory }}</span>
							</div>
						</v-col>
					</v-row>

					<p class="mt-4 tac notice hidden-md-and-up">선택하신 색상과 조화롭게 어울리는 추천 제품을 확인해보세요!</p>
				</div>
			</v-col>

			<v-col
				class="pa-0 hidden-sm-and-down"
				md="8"
			>
				<div class="ml-0 ml-md-2 right">
					<div class="mb-0 mb-md-4 tac wrap">
						<div class="result_txt">
							<span class="color_circle_txt">{{ `${selected_color_text} 색상으로` }}</span>
							<span
								v-if="items.objectColor"
								class="tac mx-3 color_circle"
							>
								<span class="circle">
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
									>
										<path
											d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z"
											:fill="items.objectColor"
										/>
										<path
											d="M23.5 12C23.5 18.3513 18.3513 23.5 12 23.5C5.64873 23.5 0.5 18.3513 0.5 12C0.5 5.64873 5.64873 0.5 12 0.5C18.3513 0.5 23.5 5.64873 23.5 12Z"
											stroke="black"
											stroke-opacity="0.1"
										/>
									</svg>
								</span>
								<p>{{ items.objectColorCategory }}</p>
							</span>
							<span
								v-if="items.wallColor"
								class="tac mx-3 color_circle"
							>
								<span class="circle">
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
									>
										<path
											d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z"
											:fill="items.wallColor"
										/>
										<path
											d="M23.5 12C23.5 18.3513 18.3513 23.5 12 23.5C5.64873 23.5 0.5 18.3513 0.5 12C0.5 5.64873 5.64873 0.5 12 0.5C18.3513 0.5 23.5 5.64873 23.5 12Z"
											stroke="black"
											stroke-opacity="0.1"
										/>
									</svg>
								</span>
								<p>{{ items.wallColorCategory }}</p>
							</span>
							<span class="color_circle_txt"> 을 선택하셨군요! </span>
						</div>
						<div class="mt-5 result_txt">
							<span class="color_circle_txt">선택하신 색상과 조화롭게 어울리는 추천 제품을 확인해보세요!</span>
						</div>
					</div>
				</div>
			</v-col>
		</v-row>

		<CommonConfirm
			:dialog="confirmReset"
			title="색상 초기화"
			text="색상을 변경하시겠습니까?"
			@close="confirmReset = false"
			@submit="initColor"
		/>
	</div>
</template>

<script>
import { mapMutations } from 'vuex'
import common from '@/mixins/common'
export default {
	name: 'ColorAnalysis',
	mixins: [common],
	props: ['items'],
	data: () => ({
		confirmReset: false,
	}),
	computed: {
		selected_color_text() {
			if (this.items.objectColor && !this.items.wallColor) return '가구'
			else if (!this.items.objectColor && this.items.wallColor) return '벽'
			else return '가구와 벽'
		},
	},
	watch: {},
	mounted() {},
	methods: {
		...mapMutations(['HOME_MU_SEARCH_SELECTED_COLORS']),
		initColor() {
			this.HOME_MU_SEARCH_SELECTED_COLORS({})
			this.$emit('input', null)
		},
	},
}
</script>

<style scoped lang="scss">
.color_analysis {
	.subject {
		font-size: $font_sub_tit;
		font-weight: 500;
		letter-spacing: -2px;
		color: $color_font;
	}
	.btn_color {
		padding: 6px 24px;
		border: 1px solid $color_gray_4;
		border-radius: 4px;
		box-sizing: border-box;
		vertical-align: middle;
		font-size: $font_normal;
		cursor: pointer;
	}
	.left {
		height: 100%;
		border: 1px solid $color_gray_2;
		border-radius: 8px;
		.head {
			.left {
				border: none;
				span {
					padding: 4px 16px;
					background-color: $color_gray_1;
					border-radius: 99px;
					color: $color_font;
					font-size: $font_lg;
				}
			}
		}
		.analysis {
			position: relative;
			background-color: $color_gray_1;
			border-radius: 8px;
			.drop {
				min-width: 190px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				svg {
					vertical-align: middle;
				}
				text-align: center;
				.color_code {
					padding: 2px 10px !important;
					background-color: $color_white;
					border-radius: 99px;
					font-size: $font_normal;
					color: $color_font;
					vertical-align: middle;
				}
			}
		}
		.notice {
			font-size: $font_normal;
			color: $color_gray_8;
			word-break: keep-all;
		}
	}

	.right {
		position: relative;
		height: 100%;
		background-color: $color_gray_1;
		border-radius: 8px;
		align-content: center;
		.wrap {
			min-width: 570px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
		.result_txt {
			.color_circle {
				display: inline-block;
				vertical-align: middle;
				.circle {
					width: 24px;
					height: 24px;
				}
			}
			.color_circle_txt {
				display: inline-block;
				font-size: $font_lg;
				color: $color_font;
				word-break: keep-all;
			}
		}
	}
}

@media all and (max-width: 959px) {
	.color_analysis {
		.subject {
			font-size: $font_lg !important;
		}
		.btn_color {
			padding: 3px 8px !important;
			font-size: $font_sm !important;
		}
	}
}
</style>
