<template>
	<div class="mx-0 my-2">
		<v-col
			class="pa-0"
			cols="4"
			align-self="center"
		>
			<p class="tit">상세견적</p>
		</v-col>
		<div>결과</div>
	</div>
</template>

<script>
export default {
	name: 'GreenDongwhaClientRepo06Form',
}
</script>

<style lang="scss" scoped></style>
