import { mapMutations } from 'vuex'
export default {
	data: () => ({
		// map
		currentmap: {
			// lat: 37.5230525,
			// lng: 126.9247649,
			// lat: null,
			// lng: null,
			lat: null,
			lng: null,
		},
	}),
	watch: {},
	computed: {},
	created() {},
	mounted() {},
	beforeDestroy() {
		this.geolocation_status = 1
		if (this.geolocation_watch_id !== null) this.geolocation_cancel()
	},
	methods: {
		...mapMutations([
			'HOME_MU_INTERIOR_SELECT_INTERIOR',
			'HOME_MU_CURRENT_ESTIMATE_CONTENT_INTERIORSELECT',
			'HOME_MU_CURRENT_ESTIMATE_CONTENT_INTERIORSELECTAUTO',
		]),
		// getCurrentLocation() {
		// 	if (navigator.geolocation) {
		// 		navigator.geolocation.getCurrentPosition(
		// 			position => {
		// 				const latitude = position.coords.latitude
		// 				const longitude = position.coords.longitude
		// 				console.log(`현재 위치 - 위도: ${latitude}, 경도: ${longitude}`)
		// 				this.currentmap.lat = latitude
		// 				this.currentmap.lng = longitude
		// 				// 여기에서 위도와 경도 값을 활용할 수 있습니다.
		// 				this.getApiInteriorItems(this.currentmap)
		// 			},
		// 			error => {
		// 				console.error(`위치 정보를 가져오는 중 오류가 발생했습니다: ${error.message}`)
		// 			},
		// 		)
		// 	} else {
		// 		console.error('브라우저가 Geolocation API를 지원하지 않습니다.')
		// 	}
		// },
		async getApiInteriorItems(center) {
			console.log(center)
			const params = {
				//latitude_x: 37.5230525,
				//longitude_y: 126.9247649,
				latitude_x: center.lat,
				longitude_y: center.lng,
				build_range: '',
				//meter_range: 3000,
				meter_range: 3000,
			}

			console.log('params', params)

			await this.HOME_ACT_INTERIOR_GET_INTERIOR_ITEMS(params).then(res => {
				// this.interiorItems = res
				// if (res.length)
				// this.createMarker(this.filterInteriorItems);
				this.$store.dispatch('HOME_ACT_AUTO_INTERIOR_SELECT', res.slice(0, this.limit))
				//this.HOME_MU_INTERIOR_SELECT_INTERIOR(res)
				if (res.length !== 0) {
					this.nexttointeriorCheck = 1
					this.nexttointerior = res
					this.HOME_MU_CURRENT_ESTIMATE_CONTENT_INTERIORSELECTAUTO(this.nexttointerior)
				} else {
					this.nexttointeriorCheck = 0
				}
			})
		},
	},
}
