<template>
	<div class="detailWrapper">
		<v-list-item
			v-if="APP_GET_APP_WIDTH < 960"
			class="px-0 mo_close"
		>
			<!-- <v-list-item-avatar>
					<v-img src="https://randomuser.me/api/portraits/men/78.jpg"></v-img>
				</v-list-item-avatar> -->

			<v-list-item-content class="py-2">
				<v-row class="px-4">
					<!-- <v-list-item-title>간편상담 신청하기</v-list-item-title> -->
					<v-col class="contAsk">인테리어점 상세</v-col>
					<v-col
						class="pa-0 pr-0"
						align-self="center"
						style="text-align: right"
					>
						<CommonMenuMenu01
							:icon="'mdi-share-variant-outline'"
							:items="share_shareItems"
							class="link"
							:zindex="13"
							@submit="onShare"
						/>
						<v-btn
							icon
							text
							@click="closePickStore"
						>
							<v-icon color="#666">mdi-window-close</v-icon>
						</v-btn>
					</v-col>
				</v-row>
			</v-list-item-content>
		</v-list-item>
		<v-container
			class="pa-6 px-md-6 px-4 py-md-4 mo_container"
			fluid
		>
			<v-row
				v-if="APP_GET_APP_WIDTH > 960"
				class="ma-0 popTop"
			>
				<div
					class="pl-1"
					@click="closePickStore"
				>
					<v-img
						:src="require('@/assets/img/home/interior/ico_detailInfo_arrow.svg')"
						eager
						width="8"
						height="16"
						class="backArrow"
					/>
				</div>
				<CommonMenuMenu01
					:icon="'mdi-share-variant-outline'"
					:items="share_shareItems"
					class="link"
					:zindex="13"
					@submit="onShare"
				/>
			</v-row>

			<!-- 회사 이미지 -->
			<v-row class="ma-0">
				<v-col
					cols="12"
					md="12"
					align="start"
					class="pa-0"
				>
					<v-img
						:src="storeItems.company_picurl"
						eager
						class="interior_img"
					>
						<v-row
							v-if="!isImage"
							class="ma-0 text_area"
							alig="center"
							justify="center"
						>
							<v-col
								align="center"
								justify="center"
							>
								<p
									v-if="storeItems.business_name"
									class="text_area_title"
								>
									{{ storeItems.business_name }}
								</p>
							</v-col>
						</v-row>
					</v-img>
				</v-col>
			</v-row>
			<!-- 단순 회사정보 -->
			<!-- 단순 회사정보 -->
			<HomeInteriorStoreSimpleInfo :items="storeItems" />

			<!-- 상세 회사정보 & 이미지 -->
			<!-- 상세 회사정보 & 이미지 -->
			<v-row class="ma-0 mt-0">
				<v-col
					cols="12"
					md="12"
					class="px-0 px-md-0 py-0 py-md-2 pr-md-5"
				>
					<HomeInteriorStoreDetailInfo :items="storeItems" />
				</v-col>
			</v-row>

			<v-row class="ma-0 mt-0 mx-0">
				<v-col
					cols="6"
					md="6"
					class="pl-0"
				>
					<CommonButtonsButton05
						v-if="AUTH_GET_USER_AUTH !== 'AGENCY'"
						name="상세보기"
						:border="'#D8D8D8'"
						:color="'#777777'"
						:radius="4"
						:height="30"
						class="mr-1 font_normal"
						@click="pushLink02(storeItems.corporate_no)"
					/>
				</v-col>
				<v-col
					cols="6"
					md="6"
					class="pr-0"
				>
					<CommonButtonsButton05
						v-if="getQuery !== '1378102333'"
						name="인테리어점 선택"
						:border="'#00CE7D'"
						:color="'#00CE7D'"
						:weight="400"
						:radius="4"
						:height="30"
						class="mr-1 font_normal"
						@click="goToInquire"
					/>
				</v-col>
			</v-row>

			<!-- <v-col
				v-if="filterProductItems.length"
				class="mt-10 pa-0"
			>
				<CommonTabsTab02
					v-model="tab"
					:tab-items="tabItems"
					:cols="6"
				/>
			</v-col>

			<v-col
				v-if="filterProductItems.length"
				cols="11"
				md="12"
				align="center"
				class="pa-0 mt-5 layout_align"
			>
				<div class="card_align">
					<CommonCardMenuGroup02
						:menu-type="'product'"
						:card-menu="filterProductItems"
						@submit="pushLink"
					/>
				</div>
			</v-col>

			<div
				v-if="filterProductItems.length > showItemsCount"
				class="btn_bar"
				align="center"
			>
				<CommonButtonsButton02
					:name="`더보기 (${showItemsCount}/${filterProductItems.length})`"
					class-name="btn_more"
					color="#70b341"
					outlined
					@click="showItemsCount += 16"
				/>
			</div> -->
		</v-container>
	</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import common from '@/mixins/common'
import scrap from '@/mixins/scrap'
import ad_kakao from '@/mixins/ad/kakao'
import ad_naver from '@/mixins/ad/naver'
import ad_mobion from '@/mixins/ad/mobion'
import share from '@/mixins/share'

export default {
	name: 'HomeInteriorStoreDetail',

	mixins: [common, scrap, ad_kakao, ad_naver, ad_mobion, share],
	props: ['pickstoreDetail'],
	data: () => ({
		showItemsCount: 16,
		tab: 0,
		tabItems: [],
		titleGroup: [],
		storeItems: [],
		expertItems: [],
		productItems: [],
		isImage: false,
	}),
	computed: {
		...mapGetters(['AUTH_GET_USER_AUTH', 'HOME_GET_PICKSTORE_DETAIL', 'APP_GET_APP_WIDTH']),
		getQuery() {
			return this.pickstoreDetail || null
		},
		filterProductItems() {
			return this.expertItems.filter(item => item.form === this.tabItems[this.tab].value) || []
		},
		appSizeCheck() {
			return this.APP_GET_APP_WIDTH > 960
		},
	},
	watch: {
		filterProductItems(val) {
			console.log('filterProductItems', val)
		},
		getQuery(newval, oldval) {
			if (newval) {
				this.getNewData()
			}
		},
	},
	created() {
		this.getNewData()
	},
	mounted() {
		// ad_kakao page
		this.ad_kakao_pageScript('store_detail')
		// ad_naver
		this.ad_naver_changeScript(5, 1)
		// ad_mobion page
		this.ad_mobion_page_convertion('page_view_store_detail')
	},
	destroyed() {},
	methods: {
		...mapMutations(['HOME_MU_INTERIOR_SELECT_INTERIOR', 'HOME_MU_PICKSTORE_DETAIL']),
		...mapActions(['HOME_ACT_INTERIOR_GET_INTERIOR_DETAIL_ITEM', 'HOME_ACT_INTERIOR_GET_INTERIOR_DETAIL_EXPERT_ITEMS']),
		parseData(data) {
			// console.log('HOME_ACT_GET_PRODUCT_DETAIL_ITEMS', data[0])
			if (!data) return
			let getData = data[0]
			console.log('getData', getData)
			getData.buildItems = getData.buildtype.map(x => x.code_name)
			this.isImage = getData.company_picurl?.length
			getData.company_picurl = getData.company_picurl?.length
				? getData.company_picurl
				: require('@/assets/img/home/interior/img_store_empty1.png')
			this.storeItems = getData
		},
		parseExpertData(data) {
			console.log('HOME_ACT_INTERIOR_GET_EXPERT_ITEMS', data)
			if (!data) return
			let dataForm = []
			this.expertItems = data

			data.forEach(element => {
				// 전문가 집들이 data parse
				element.image = element.image_url
				element.kind_name = `${element.addr_cut} | ${element.spacious_name}`

				// tab 추출
				if (dataForm.includes(element.form) === false) {
					dataForm.push(element.form)
					this.tabItems.push({
						title: element.form_name,
						value: element.form,
					})
				}

				// tag filter
				element.tags = element.tags.map(x => x.tag_value)
			})
		},
		goToInquire() {
			this.HOME_MU_INTERIOR_SELECT_INTERIOR(this.storeItems)
			this.HOME_MU_PICKSTORE_DETAIL(null)
			//this.$router.push('/interior/price/inquire').catch(() => {})
		},
		pushLink(data) {
			let link

			if (data?.hw_id) link = `/housewarming/3d/detail/${data.hw_id}`
			if (data?.exphw_id) link = `/housewarming/specialist/detail/${data.exphw_id}`

			this.$router.push(link).catch(() => {})
		},
		pushLink02(corporate_no) {
			if (this.APP_GET_APP_STATE === 'web') this.common_newLink(`/interior/store/detail/${corporate_no}`)
			else this.$router.push(`/interior/store/detail/${corporate_no}`)
			// this.detailView = true
			// this.HOME_MU_PICKSTORE_DETAIL(corporate_no)
		},

		async getNewData() {
			// 인테리어점 상세 정보
			await this.HOME_ACT_INTERIOR_GET_INTERIOR_DETAIL_ITEM(this.getQuery).then(res => {
				this.parseData(res)
			})

			// 인테리어점 전문가 집들이
			const params = {
				corporate_no: this.getQuery,
				area_type: '',
				final_appstate: '2',
			}
			await this.HOME_ACT_INTERIOR_GET_INTERIOR_DETAIL_EXPERT_ITEMS(params).then(res => {
				this.parseExpertData(res)
			})
		},
		closePickStore() {
			//this.$emit('closePickStore', true)
			this.HOME_MU_PICKSTORE_DETAIL(null)
		},
		onShare(item) {
			const productItem = {
				title: this.items.business_name,
				description: '인테리어점',
				image: this.items.company_picurl,
			}
			this.share_operator(item, productItem)
		},
	},
}
</script>

<style scoped lang="scss">
.detailWrapper {
	position: absolute;
	z-index: 10;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #fff;
}
.backArrow {
	&:hover {
		cursor: pointer;
	}
}
.interior_img {
	max-height: 200px;
	border-radius: 8px;
	object-fit: cover;
}
.interior_bg {
	background: #ededed;
	height: 200px;
	width: 100%;
	opacity: 0.1;
}
.text_area {
	width: 100%;
	height: 200px;
	background-color: rgba(1, 1, 1, 0.4);
	.text_area_title {
		margin-top: 75px;
		font-family: 'NotoSansKR-Bold';
		font-size: 22px;
		color: #fff;
	}
}
.card_align {
	margin: 0 -1% !important;
}

@media all and (max-width: 960px) {
	:deep(.v-menu__content) {
		z-index: 13 !important;
	}
	.detailWrapper {
		z-index: 100;
		top: 0;
		position: absolute;
		overflow-y: auto;
		height: 100vh;
		.interior_img {
			max-height: 140px !important;
			display: flex;
			align-items: center;
		}
		.mo_close {
			width: 100%;
			z-index: 1;
			top: 0;
			border-bottom: 1px solid #d8d8d8;
			background: #fff;
			.popup_title_area {
				font-size: 1.2rem;
				font-weight: bold;

				.close_area {
					color: #555555;
				}
			}
		}
		.mo_container {
			position: relative;
			top: 0;
			height: 100vh;
		}
		.contAsk {
			color: #555555;
			font-size: 1.2rem;
			font-weight: bold;
		}
	}
}
.popTop {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
@media all and (min-width: 981px) and (max-width: 1280px) {
}
@media all and (min-width: 769px) and (max-width: 980px) {
}
@media all and (min-width: 380px) and (max-width: 768px) {
	.interior_img {
		max-height: 400px !important;
	}
	.text_area {
		height: 400px;
		background-color: rgba(1, 1, 1, 0.4);
		.text_area_title {
			margin-top: 180px;
			font-family: 'NotoSansKR-Bold';
			font-size: 20px;
			color: #fff;
		}
	}
}
@media all and (max-width: 380px) {
	.interior_img {
		max-height: 400px !important;
	}
	.text_area {
		height: 400px;
		background-color: rgba(1, 1, 1, 0.4);
		.text_area_title {
			margin-top: 180px;
			font-family: 'NotoSansKR-Bold';
			font-size: 18px;
			color: #fff;
		}
	}
}
</style>
