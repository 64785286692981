<template>
	<v-tabs
		v-model="tab"
		grow
		class="tab_count"
	>
		<v-tab
			v-for="(item, i) in tabItems"
			:key="i"
		>
			{{ item }}
			<span class="count">999</span>
		</v-tab>
	</v-tabs>
</template>

<script>
import model from '@/mixins/model'

export default {
	name: 'TabCount',
	components: {},
	mixins: [model],
	props: ['tabItems'],
	data: () => ({}),
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
}
</script>
<style scoped lang="scss">
.tab_count {
	height: 48px !important;
	border-left: 1px solid #ccc;
	:deep(.v-tabs-slider-wrapper) {
		top: 0 !important;
		color: #78c046;
	}

	:deep(.v-slide-group__prev),
	:deep(.v-slide-group__next) {
		display: none !important;
	}

	.v-tab {
		font-size: $font_lg;
		color: $color_gray_7;
		background-color: #f1f1f1;
		border-top: 1px solid $color_gray_4;
		border-right: 1px solid $color_gray_4;
		border-bottom: 1px solid $color_gray_4;
		.count {
			display: inline-block;
			margin-left: 4px;
			padding: 4px 8px;
			font-size: $font_sm;
			color: $color_gray_7;
			background-color: $color_white;
			border-radius: 99px;
			line-height: 1;
		}
		&.v-tab--active {
			font-weight: $fw_bold;
			color: #78c046;
			background-color: $color_white;
			border-bottom: transparent !important;
			.count {
				background-color: #f2f9ed !important;
				color: #44883d !important;
			}
		}
	}
}

@media all and (max-width: 600px) {
	.tab_count {
		height: 34px !important;
		.v-tab {
			height: 34px !important;
			font-size: $font_normal !important;
			.count {
				display: none !important;
			}
			&.v-tab--active {
				font-weight: $fw_bold;
				color: #78c046;
				background-color: $color_white;
				border-bottom: transparent !important;
				.count {
					background-color: #f2f9ed !important;
					color: #44883d !important;
				}
			}
		}
	}
}
</style>
