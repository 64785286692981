<template>
	<div class="wrapper">
		<v-container
			class="pa-0 px-0 px-md-3"
			fluid
		>
			<div class="custom_quote pt-12">
				<v-col
					v-show="true"
					cols="12"
					align="left"
					class="pa-0 inner"
				>
					<v-row class="mx-0">
						<v-col class="pa-0 sns item py-2">
							<h3 class="ma-0">간편상담 신청서 확인</h3>
							<p style="color: #666666">
								신청 내역을 확인하기 위해<br />간편상담 신청 시 입력한 개인 정보를 입력해주세요.
							</p>
						</v-col>
					</v-row>
					<v-row>
						<v-col class="d-flex pb-4 justify-space-between confirmMember">
							<p>비회원 확인</p>
							<a href="/login">회원이신가요?</a>
						</v-col>
					</v-row>
					<v-row class="ma-0 mt-2">
						<v-col
							class="pa-0 td"
							cols="12"
							md="12"
							align="start"
						>
							<v-col
								cols="12"
								sm="12"
								md="12"
								class="pa-0 selfAuth"
								><span class="color_gray_8">이름</span>
								<v-col class="pa-0 mb-2">
									<CommonInputsInput04
										ref="inputName"
										v-model="name"
										rule-name="name"
										placeholder="이름"
										:readonly="smsNumberCheck"
										@checkInvalid="checkName"
									/>
								</v-col>
								<span class="color_gray_8">휴대폰 번호</span>
								<v-row class="ma-0 mb-2">
									<v-col
										class="pa-0"
										cols="7"
										sm="8"
										md="7"
									>
										<div class="pr-1">
											<CommonInputsInput04
												ref="inputHand"
												v-model="hand"
												rule-name="tel"
												placeholder="휴대폰 번호(- 없이 입력)"
												:readonly="smsNumberCheck"
												@checkInvalid="checkNum"
											/>
										</div>
									</v-col>
									<v-col
										class="pa-0"
										cols="5"
										sm="4"
										md="5"
									>
										<div class="pl-1">
											<CommonButtonsButton01
												:disabled="nameInvalid === false || numberInvalid === false"
												name="인증번호 발송"
												:color="smsNumberCheck ? 'primary' : '#262626'"
												class-name="regular font_12 edge5"
												height="40"
												@click="checkSms"
											/>
										</div>
									</v-col>
									<v-row class="ma-0">
										<v-col
											cols="8"
											class="pa-0 pt-2 pr-1"
											align-self="center"
											align="start"
										>
											<p
												v-if="smsNumberCheck"
												style="color: #00ce7d"
											>
												인증이 완료되었습니다.
											</p>
										</v-col>
										<v-col
											class="pa-0 pt-1"
											cols="4"
											align-self="center"
											align="end"
										>
											<v-btn
												v-if="smsNumberCheck"
												class="pa-0 reset_btn"
												text
												@click="resetSms"
											>
												<v-icon
													color="#9e9e9e"
													small
													>mdi-refresh</v-icon
												>
												초기화
											</v-btn>
										</v-col>
									</v-row>
									<CommonLoaderLine01 v-if="telLoading" />
								</v-row>

								<div v-if="smsNumberModel">
									<v-row class="ma-0 pt-3">
										<v-col
											class="pa-0"
											cols="7"
											sm="8"
											md="7"
										>
											<div class="pr-1">
												<CommonInputsInput02
													ref="inputSmsNum"
													v-model="smsNum"
													placeholder="인증번호를 입력해주세요."
												/>
											</div>
										</v-col>
										<v-col
											class="pa-0"
											cols="5"
											sm="4"
											md="5"
										>
											<div class="pl-1">
												<CommonButtonsButton01
													name="인증 확인"
													color="#262626"
													class-name="regular font_12 edge5"
													height="40"
													@click="confirmSmsNumber"
												/>
											</div>
											<v-col
												cols="12"
												class="pa-0 pt-2 pr-1 pb-3"
												align="end"
												style="color: #fb4f4f"
											>
												{{ sms_check_timer }}
											</v-col>
										</v-col>
									</v-row>
								</div>
							</v-col>
						</v-col>
						<v-row class="ma-0">
							<v-col
								class="pa-0 td"
								cols="12"
								lg="12"
								align="start"
								align-self="center"
							>
								<div class="btn_privacy">
									<div class="pa-0 pb-2">
										<CommonCheckboxsCheckbox02
											v-model="privateTermsCheck"
											sub-text="상담 예약 및"
											allowlink="개인정보 수집"
											href="/site/userInfo"
											text="이용 동의"
											class="pa-0 ma-0 pr-5"
										/>
									</div>
								</div>
							</v-col>
							<v-col
								class="pa-0"
								cols="12"
							>
								<CommonButtonsButton06
									:disabled="!registerCheck"
									name="간편상담 신청서 확인"
									color="#00582c"
									class-name="btn_new_basic_nonmember"
									@click="checkNoUserData"
								/>
							</v-col>
						</v-row>
					</v-row>
				</v-col>
			</div>
		</v-container>
	</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import common from '@/mixins/common'
import sms from '@/mixins/sms'

export default {
	name: 'HomeInteriorMypageConfirm01',
	mixins: [common, sms],

	data() {
		return {
			privateTermsCheck: false,
			// sms check
			name: null,
			hand: null,
			smsNum: null,
			// sms
			smsNumberCheck: null,
			smsNumberModel: false,
			smsCheckId: null,
			telLoading: false,
			// ssession
			nonmemberData: null,
			// 이름 유효성
			nameInvalid: false,
			numberInvalid: false,
		}
	},
	computed: {
		...mapGetters([
			'AUTH_GET_USER',
			'AUTH_GET_USER_AUTH',
			'APP_GET_APP_STATE',
			'GUEST_GET_MYPAGE_INFO',
			'USER_GET_ESTIMATE',
			'GUEST_GET_ESTIMATE',
			'GUEST_GET_ESTIMATE_DETAIL',
		]),

		registerCheck() {
			// 인테리어 점 선택
			//if (this.HOME_GET_INTERIOR_SELECT_INTERIOR === null) return false

			const checkList = ['privateTermsCheck', 'smsNumberCheck']
			// GUEST일 경우 본인인증 추가체크
			//if (this.AUTH_GET_USER_AUTH === 'GUEST') checkList.push('smsNumberCheck')

			for (let i of checkList) {
				if (this[i] === '' || this[i] === null || this[i] === undefined || this[i] === false) return false
			}
			return true
		},

		getDataFromSessionStorage() {
			// 세션 스토리지에서 데이터 가져오기
			return sessionStorage.getItem('user_data')
		},
	},
	mounted() {
		if (this.getDataFromSessionStorage === 'guest') {
			this.checkNoUserData()
		}
	},
	methods: {
		...mapMutations(['GUEST_MU_MYPAGE_INFO', 'GUEST_MU_ESTIMATE', 'GUEST_MU_ESTIMATE_DETAIL', 'APP_MU_DIALOG']),
		...mapActions(['AUTH_ACT_SIGN_UP_CHECK', 'GUEST_ACT_ESTIMATE']),
		checkName(val) {
			if (val === true) {
				this.nameInvalid = true
			} else {
				this.nameInvalid = false
			}
		},
		checkNum(val) {
			if (val === true) {
				this.numberInvalid = true
			} else {
				this.numberInvalid = false
			}
		},
		async checkSms() {
			this.telLoading = true
			let params = {
				type: this.sms_check_type[7],
				hand: this.hand,
				name: this.name,
				login_id: this.email,
			}
			const check = await this.sms_call(params)
			if (check) {
				this.telLoading = false
				this.smsNumberModel = true
			}
		},
		async confirmSmsNumber() {
			const check = await this.sms_call_check(this.hand, this.smsNum)
			if (check) {
				this.smsNumberCheck = true
				this.smsNumberModel = false
			}
		},
		async checkNoUserData() {
			await this.GUEST_ACT_ESTIMATE({
				method: 'get',
				params: {
					login_name: this.name,
					login_id: this.hand,
				},
			})
				.then(async res => {
					console.log('통신결과값', res)
					if (res) {
						if (res.length !== 0 || res !== null || res !== undefined) {
							this.saveToSessionStorage()
							this.$emit('nextLevel', 1)
						}
					} else {
						await this.APP_MU_DIALOG({
							dialog: true,
							title: '간편상담 신청서 확인',
							text: '간편상담 신청내역이 없습니다.<br/>신청내역을 확인 하기 위해<br/>간편상담을 신청해보세요!',
						})
						await this.$router.push('/')
					}
				})
				.catch(e => {
					console.log('checkNoUserData', e)
				})
			// this.$emit('nextLevel', 1)
			// let items = {
			// 	method: 'post',
			// 	params: {
			// 		memberYN: this.AUTH_GET_USER.login_id ? 'Y' : 'N',
			// 		login_id: this.AUTH_GET_USER.login_id ? this.AUTH_GET_USER.login_id : this.hand,
			// 		login_name: this.AUTH_GET_USER.name ? this.AUTH_GET_USER.name : this.name,
			// 		regi_userid: this.AUTH_GET_USER.login_id ? this.AUTH_GET_USER.login_id : this.name,
			// 	},
			// }
		},
		saveToSessionStorage() {
			// 세션 스토리지에 데이터 저장
			if (this.GUEST_GET_ESTIMATE) {
				this.nonmemberData = 'guest'
			}
			const items = {
				login_name: this.name,
				login_id: this.hand,
				nonmemberData: this.nonmemberData,
			}

			sessionStorage.setItem('user_data', JSON.stringify(items))
		},
		resetSms() {
			this.smsNumberCheck = false
			this.name = null
			this.hand = null
			this.smsNum = null
			if (this.$refs.inputName) this.$refs.inputName.inputModel = ''
			if (this.$refs.inputHand) this.$refs.inputHand.inputModel = ''
			if (this.$refs.inputSmsNum) this.$refs.inputSmsNum.inputModel = ''
		},
	},
}
</script>

<style scoped lang="scss">
.custom_quote {
	.title {
		max-width: 468px;
		margin: 0 auto;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.inner {
		max-width: 468px;
		padding: 30px !important;
		margin: 0 auto;
		background-color: $color_white;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
		border-radius: 16px;
		position: relative;
		z-index: 3;
		h3 {
			font-size: $font_xl;
		}
		.confirmMember {
			p {
				font-size: $font_lg;
				color: #262626;
				font-weight: $fw_regular;
			}
			a {
				font-size: $font_normal;
				color: #666666;
				text-decoration: underline;
				font-weight: $fw_normal;
			}
		}
	}
}
@media all and (max-width: 960px) {
	.custom_quote {
		.inner {
			box-shadow: none;
			border-radius: 0;
			padding: 0 15px 15px !important;
		}
	}
}
</style>
