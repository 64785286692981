<template>
	<div>
		<v-row
			class="ma-0 pt-2 pt-md-0"
			align="center"
		>
			<v-col
				v-if="currentPath === 'page'"
				cols="12"
				lg="6"
				md="6"
				align="left"
				class="pa-0"
			>
				<CommonTabsTab03
					v-model="model"
					:tab-items="mapTabItems"
				/>
			</v-col>
			<v-col
				v-if="currentPath === 'page'"
				class="pa-0 hidden-sm-and-down"
				:lg="currentPath !== 'page' ? 12 : 12"
				:md="currentPath !== 'page' ? 12 : 12"
				align="end"
			>
				<div class="mx-7 setStore">
					조건에맞는 시공업체 <em>{{ totalCount }}</em
					>개
				</div>
			</v-col>
		</v-row>

		<v-col
			class="pa-0"
			:class="`${currentPath === 'page' ? '' : 'pt-2 pt-md-0'}`"
		>
			<v-tabs-items v-model="model">
				<v-tab-item
					class="list_board"
					:style="`height:${listHeight}`"
				>
					<!-- 인테리어점 리스트 -->
					<HomeInteriorStoreListItemsList
						:tab="model"
						:filter-items="filterInteriorItems"
						:show-items-count="showItemsCount"
						:select-interior="selectInterior"
						@showCount="$emit('showCount')"
					/>
				</v-tab-item>

				<v-tab-item
					class="list_board"
					:style="`height:${listHeight}`"
				>
					<!-- 전문가 집들이 리스트 -->
					<HomeInteriorStoreListItemsList
						:tab="model"
						:filter-items="filterExpertItems"
						:show-items-count="showItemsCount"
						:select-interior="selectInterior"
						@showCount="$emit('showCount')"
					/>
				</v-tab-item>
			</v-tabs-items>
		</v-col>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import model from '@/mixins/model'

export default {
	name: 'HomeInteriorStoreListItemsTabGroup',
	mixins: [model],
	props: {
		currentPath: {
			type: String,
			default: 'page',
		},
		totalCount: {
			type: Number,
			default: 0,
		},
		filterInteriorItems: {
			type: Array,
			default: () => [],
		},
		filterExpertItems: {
			type: Array,
			default: () => [],
		},
		showItemsCount: {
			type: Number,
			default: 16,
		},
		selectInterior: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({
		mapTabItems: ['인테리어점', '전문가 집들이'],
	}),
	computed: {
		...mapGetters(['APP_GET_APP_STATE']),
		listHeight() {
			//return this.APP_GET_APP_STATE !== 'web' ? '100vh' : this.currentPath === 'page' ? '500px' : '610px'
			return this.APP_GET_APP_STATE !== 'web' ? '100vh' : this.currentPath === 'page' ? '500px' : '610px'
		},
	},
	watch: {},
	async created() {},
	mounted() {},
	destroyed() {},
	updated() {},
	methods: {},
}
</script>

<style scoped lang="scss">
.no_filter {
	.v-icon {
		color: #a0a1a5;
	}
	p {
		font-size: $font_lg;
		color: $color_gray_6;
		word-break: keep-all;
	}
}
.list_board {
	overflow-y: scroll;
	-ms-overflow-style: none !important;
	&::-webkit-scrollbar {
		display: none;
	}
}

.setStore {
	background-color: #f8f8f8;
	padding: 9px 14px;
	font-size: $font_sm;
	border-bottom: 1px solid #d8d8d8;
	em {
		font-style: normal;
		font-weight: bold;
	}
}

@media all and (max-width: 959px) {
	.no_filter {
		p {
			font-size: $font_sm;
		}
	}
}
</style>
