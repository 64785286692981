<template>
	<div class="btWrap">
		<CommonButtonsButton01
			v-if="!stepdowncalc"
			name="뒤로"
			color="#00CE7D"
			:outlined="outlined ? '#00CE7D' : ''"
			class="prevButton"
			class-name="edge_4 fw_bold font_16 btn_upload02"
			height="43px"
			radius="8"
			:disabled="stepdowncalc"
			@click="prevstep"
		/>
		<!-- <v-btn
			v-if="count === 3"
			@click="resetcalc"
		>
			초기화
		</v-btn> -->
		<CommonButtonsButton01
			v-if="count === 3"
			name="초기화"
			color="#00CE7D"
			:outlined="outlined ? '#00CE7D' : ''"
			class="prevButton"
			class-name="edge_4 fw_bold font_16 btn_upload02"
			height="43px"
			radius="8"
			@click="resetcalc"
		/>
		<!-- <v-btn
			v-if="count === 3"
			:disabled="!quote"
			@click="customquote"
		>
			맞춤견적신청
		</v-btn> -->
		<CommonButtonsButton01
			v-if="count === 3"
			name="간편상담 신청하기"
			color="#00CE7D"
			class="nextButton"
			class-name="edge_4 fw_bold font_16 btn_upload02"
			height="46"
			radius="8"
			:disabled="!quote"
			@click="customquote"
		/>
		<!-- 작업후 지우기 -->
		<!-- <v-btn
			v-if="count === 3"
			@click="customquote"
		>
			맞춤견적신청(테스트용)
		</v-btn> -->
		<!-- //작업후 지우기 -->
		<v-btn
			v-else-if="count === 4"
			class="lastbt"
			@click="showsummit"
		>
			신청내역보기
		</v-btn>
		<CommonButtonsButton01
			v-else
			name="다음"
			color="#00CE7D"
			class="nextButton"
			class-name="edge_4 fw_bold font_16 btn_upload02"
			height="46"
			radius="8"
			:disabled="!stepcalc"
			@click="nextpage"
		/>
		<v-btn
			v-if="count === 4"
			class="lastbt"
			@click="resetcalc"
		>
			이전으로 갈까요?
		</v-btn>
	</div>
</template>

<script>
export default {
	props: {
		newforData: {
			type: Object,
		},
		count: {
			type: Number,
		},
		stepdowncalc: {
			type: Boolean,
		},
		stepcalc: {
			type: Boolean,
		},
		kindbutton: {
			type: String,
		},
		checkcountall: {
			type: Boolean,
		},
		finalcheck: {
			type: Boolean,
		},
		quote: {
			type: Boolean,
		},
		outlined: {
			type: Boolean,
		},
	},
	data() {
		return {
			newcount: 0,
		}
	},
	computed: {
		newcounting() {
			return (this.newcount = this.count)
		},
	},
	// created() {
	// 	this.newcounting
	// },
	methods: {
		// nextpage(){
		//     this.newcount = this.count
		//     this.newcount
		// }.
		nextpage() {
			if (this.newcounting + 1 <= 4) {
				this.newcount++
			}
			this.$emit('next', this.newcount)
		},
		prevstep() {
			if (this.newcounting + 1 >= 0) {
				this.newcount--
			}
			this.$emit('prev', this.newcount)
		},
		submitcalc() {
			this.newcount = 4
			this.$emit('nextcalc', this.newcount)
		},
		customquote() {
			this.newcount = 4
			this.$emit('customquoto', this.newcount)
		},
		resetcalc() {
			this.newcount = 0
			this.$emit('resetAll', this.newcount)
		},
		showsummit() {
			const test = 'test'
			this.$emit('submit', test)
		},
	},
}
</script>

<style lang="scss" scoped>
.btWrap {
	display: flex;
	justify-content: space-between;
	.prevButton {
		min-width: auto !important;
		flex: none;
		width: 25%;
		margin-right: 8px;
	}
	.nextButton {
		min-width: auto !important;
		flex-grow: 1;
	}
}
</style>
