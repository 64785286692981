<template>
	<div>
		<!-- 관련상품 필터 -->
		<v-row class="ma-0 mb-5 pl-4 pl-sm-0 justify-center">
			<v-chip-group
				v-model="filterChip"
				class="filter_group"
			>
				<v-chip
					v-for="chip in items.other"
					:key="chip.goods_code"
					filter
					class="filter"
					:value="chip.goods_code"
					@click="onLinkChip(chip)"
				>
					{{ chip.name }}
				</v-chip>
			</v-chip-group>
		</v-row>

		<!-- 베스트제품 -->
		<div class="px-4 px-sm-0 mb-4 mb-md-10 best_search">
			<v-row class="ma-0">
				<v-col
					class="pa-0 left"
					cols="12"
					md="8"
					align-self="center"
					:style="{ background: `url('${items.image_url}') no-repeat center` }"
				>
					<div class="img">
						<div class="text">
							<p class="level_01">{{ `${items.brand_engname} ${items.goods_engname}` }}</p>
							<div class="d-flex">
								<p class="mr-2 level_02">{{ `${items.brand_name} ${items.goods_name}` }}</p>
								<div class="d-flex level_03">
									<p class="pr-2">종류</p>
									<p class="pl-2">{{ items.kind_name }}</p>
								</div>
							</div>

							<div
								v-if="items.description.length > 0"
								class="box"
							>
								<p
									ref="desc"
									class="desc"
								>
									{{
										isShort && items.description.length > 100
											? items.description.substring(0, 100) + '...'
											: items.description
									}}
								</p>
								<a
									v-if="items.description.length > 100"
									href="javascript:void(0)"
									class="btn_more"
									@click="isShort = !isShort"
								>
									{{ isShort ? '더보기' : '접기' }}
								</a>
							</div>
						</div>
					</div>
				</v-col>

				<v-col
					class="pa-0 right"
					cols="12"
					md="4"
					align-self="center"
				>
					<!-- pc일때 -->
					<div class="ml-8 ml-lg-15 best hidden-sm-and-down">
						<p class="tit">Best 제품</p>
						<p class="sub_tit">베스트 제품 3가지를 만나보세요.</p>

						<ul class="mt-7 pa-0">
							<template v-for="(bestItem, i) in items.best">
								<li
									v-if="i < 3"
									:key="bestItem.id"
									class="mb-4"
									@click="onLink(`/product/detail/${bestItem.product_id}`)"
								>
									<v-row class="ma-0">
										<v-col
											class="pa-0"
											cols="auto"
											align-self="center"
										>
											<v-img
												width="100%"
												height="100%"
												max-width="80"
												max-height="80"
												:src="bestItem.rep_image_url"
												eager
											/>
										</v-col>
										<v-col
											class="pl-4 pa-0 name"
											align-self="center"
										>
											{{ bestItem.name }}
										</v-col>
									</v-row>
								</li>
							</template>
						</ul>
					</div>

					<!-- mo일때 -->
					<div class="mt-4 best hidden-md-and-up">
						<v-expansion-panels
							v-model="panel"
							accordion
							elevation="0"
							class="panel"
						>
							<v-expansion-panel>
								<v-expansion-panel-header> Best 제품 </v-expansion-panel-header>
								<v-expansion-panel-content class="pa-0">
									<ul class="pa-0">
										<template v-for="(bestItem, i) in items.best">
											<li
												v-if="i < 3"
												:key="bestItem.id"
												class="mb-4"
												@click="onLink(`/product/detail/${bestItem.product_id}`)"
											>
												<v-row class="ma-0">
													<v-col
														class="pa-0"
														cols="auto"
														align-self="center"
													>
														<v-img
															width="100%"
															max-width="62"
															max-height="62"
															:src="bestItem.rep_image_url"
															eager
														/>
													</v-col>
													<v-col
														class="pl-4 pa-0 name"
														align-self="center"
													>
														{{ bestItem.name }}
													</v-col>
												</v-row>
											</li>
										</template>
									</ul>
								</v-expansion-panel-content>
							</v-expansion-panel>
						</v-expansion-panels>
					</div>
				</v-col>
			</v-row>
		</div>
	</div>
</template>

<script>
import common from '@/mixins/common'

export default {
	name: 'BestSearch',
	mixins: [common],
	props: ['items'],
	data: () => ({
		panel: null,
		filterChip: null,
		selectedChip: null,
		isShort: true,
		boxes: [],
	}),
	computed: {
		filterSearchItems() {
			if (this.tabItems[this.tab].value === 'all') return this.searchResults.result || []
			else return this.searchResults.result?.filter(item => item.type === this.tabItems[this.tab].value)
		},
	},
	watch: {
		'items.goods_code'() {
			this.filterChip = this.items.goods_code || null
			// this.filterChip = this.items.other.find(x => x.name.trim() === this.get_keywords)?.goods_code || null
		},
	},
	created() {
		this.filterChip = this.items.goods_code || null
	},
	mounted() {},
	destroyed() {},
	methods: {
		onLink(link) {
			this.common_onLink(link)
		},
		onLinkChip(chip) {
			if (this.selectedChip === chip.goods_code) {
				return
			} else {
				this.selectedChip = chip.goods_code
				this.filterChip = chip.goods_code
				this.common_onLink(`/search/result?keyword=${chip.name.trim()}`)
			}
		},
	},
}
</script>

<style scoped lang="scss">
@mixin ellipsis_2 {
	display: -webkit-box !important;
	word-wrap: break-word !important;
	-webkit-line-clamp: 2 !important;
	-webkit-box-orient: vertical !important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
}

.filter_group {
	:deep(.v-slide-group__content) {
		padding: 0 !important;
	}
	.filter.v-chip {
		padding: 16px;
		border: 1px solid $color_gray_3;
		background-color: $color_white;
		:deep(.v-chip__content) {
			font-size: $font_lg;
			color: $color_gray_7;
		}
		&::before {
			display: none !important;
		}
		&:hover {
			border: 1px solid $color_state_posi !important;
			background-color: $color_white !important;
			:deep(.v-chip__content) {
				color: $color_state_posi !important;
			}
		}
		&.v-chip--active {
			border: 1px solid $color_state_posi !important;
			background-color: $color_state_posi !important;
			:deep(.v-chip__content) {
				font-weight: $fw_bold !important;
				color: $color_white !important;
			}
		}
	}
}

.best_search {
	.left {
		height: 100vh;
		max-height: 360px;
		// background: url('../../../../src/assets/img/search/img_etc_bg.png') no-repeat center;
		background-size: cover !important;
		border-radius: 16px;
		overflow: hidden;
		content: '';

		.img {
			position: relative;
			height: 100vh;
			max-height: 360px;
			&::after {
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100vh;
				max-height: 360px;
				background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
				content: '';
				z-index: 1;
			}
			.text {
				position: absolute;
				left: 30px;
				bottom: 30px;
				color: $color_white;
				z-index: 2;
				.d-flex {
					align-items: baseline !important ;
				}
				.level_01 {
					font-size: $font_lg;
				}
				.level_02 {
					font-size: 2.16rem;
					font-weight: $fw_bold;
				}
				.level_03 {
					font-size: $font_normal;
					p {
						position: relative;
						&:first-child {
							font-weight: $fw_bold;
							&::after {
								display: block;
								width: 1px;
								height: 12px;
								position: absolute;
								top: 5px;
								right: -2px;
								background-color: $color_gray_3;
								content: '';
							}
						}
					}
				}
				.desc {
					width: 100%;
					min-width: 60%;
					max-width: 520px;
					font-size: $font_lg;
					word-break: keep-all;
					font-weight: 100 !important;
				}
				.btn_more {
					font-size: $font_sm;
					color: $color_white !important;
				}
			}
		}
	}
	.right {
		.best {
			.tit {
				font-size: $font_sub_tit;
				font-weight: $fw_bold;
				counter-reset: $color_font;
			}
			.sub_tit {
				font-size: $font_normal;
				color: $color_gray_7;
			}
			ul {
				li {
					list-style: none;
					cursor: pointer;
					.v-image {
						aspect-ratio: 1/1;
						border-radius: 8px;
					}
					.name {
						@include ellipsis_2;
						font-size: $font_normal;
						color: $color_gray_8;
					}
				}
			}
		}
		.panel {
			border: 1px solid $color_gray_2;
			background: $color_white;
			border-radius: 4px;
			:deep(.v-expansion-panel::before) {
				box-shadow: none !important;
			}
			:deep(.v-expansion-panel-header) {
				min-height: 0 !important;
				padding: 10px 12px !important;
				font-size: $font_normal;
				color: $color_font;
				box-shadow: none !important;
			}
			:deep(.v-expansion-panel-header__icon) {
				max-height: 20px !important;
				i {
					font-size: 24px;
					color: $color_gray_4 !important;
				}
			}
			:deep(.v-expansion-panel-content) {
				.v-expansion-panel-content__wrap {
					padding: 12px;
				}
			}
		}
	}
}

@media all and (max-width: 959px) {
	.best_search {
		.left {
			max-height: 240px !important;
			border-radius: 8px !important;
			.img {
				max-height: 240px !important;
				&::after {
					max-height: 240px !important;
				}
				.text {
					left: 16px !important;
					bottom: 20px !important;
					.level_01 {
						font-size: $font_sm !important;
					}
					.level_02 {
						font-size: $font_sub_tit !important;
					}
					.level_03 {
					}
					.desc {
						width: 95% !important;
						font-size: $font_normal !important;
					}
				}
			}
		}
	}
}

@media all and (max-width: 600px) {
	.filter_group {
		.filter.v-chip {
			padding: 8px 16px !important;
			:deep(.v-chip__content) {
				font-size: $font_normal !important;
			}
		}
	}
	.best_search {
		.left {
			.v-image {
				aspect-ratio: 16/13 !important;
			}
		}
	}
}
</style>
