<template>
	<v-dialog
		v-model="dialog"
		fullscreen
		hide-overlay
		persistent
		no-click-animation
		transition="dialog-top-transition"
	>
		<v-sheet
			class="pa-0 ma-0"
			color="white"
		>
			<v-row
				class="ma-0 popup_title_area"
				justify="center"
				align="center"
			>
				<v-col
					cols="10"
					class="popup_title pl-6"
				>
					인테리어점 찾기
				</v-col>
				<v-col
					cols="2"
					align="center"
					class="pr-6 close_area"
				>
					<v-btn
						class="pr-6"
						icon
						@click="mobileClose"
					>
						<v-icon>arrow_close_ios</v-icon>
					</v-btn>
				</v-col>
			</v-row>
			<v-col class="pa-0 popup_contents">
				<HomeInteriorStoreListItemsTabGroup
					v-model="model"
					:current-path="currentPath"
					:total-count="totalCount"
					:filter-interior-items="filterInteriorItems"
					:filter-expert-items="filterExpertItems"
					:show-items-count="showItemsCount"
					@showCount="$emit('showCount')"
				/>
			</v-col>
		</v-sheet>
	</v-dialog>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import model from '@/mixins/model'
import common from '@/mixins/common'

export default {
	name: 'HomeInteriorStoreListItemsListDialog',
	mixins: [common, model],
	props: {
		dialog: {
			type: Boolean,
			default: false,
		},
		currentPath: {
			type: String,
			default: '',
		},
		totalCount: {
			type: Number,
			default: 0,
		},
		filterInteriorItems: {
			type: Array,
			default: () => [],
		},
		filterExpertItems: {
			type: Array,
			default: () => [],
		},
		showItemsCount: {
			type: Number,
			default: 0,
		},
	},
	data: () => ({}),
	computed: {},
	watch: {
		dialog(val) {
			console.log('dialog', val)
			// if (val) this.common_scrollTop('.interior_list_area')
		},
	},
	async created() {},
	mounted() {},
	destroyed() {},
	updated() {},
	methods: {
		...mapMutations(['HOME_MU_INTERIOR_SELECT_INTERIOR']),
		mobileClose() {
			this.$emit('close')
			//this.HOME_MU_INTERIOR_SELECT_INTERIOR(null)
		},
	},
}
</script>

<style scoped lang="scss">
.v-dialog__content {
	z-index: 21 !important;
}
:deep(.v-btn--icon) {
	&:hover {
		opacity: 0;
		&:before {
			opacity: 0;
		}
	}
	&:focus {
		&:after {
			opacity: 0;
		}
	}
}
.popup_title_area {
	background: #fff !important;
	border-bottom: 1px solid #d8d8d8;
	.close_area {
		color: #555555;
	}
	div {
		color: #555555;
	}
}
.popup {
	&_title {
		font-size: 1.2rem;
		font-weight: bold;
		color: white;

		&_area {
			width: 100%;
			z-index: 1;
			top: 0;
			position: fixed;
			background-color: $color_green_5;
		}
	}
	&_contents {
		margin-top: 50px;
	}
}
:deep(.v-slide-group__content) {
	background: #f8f8f8 !important;
}
</style>
