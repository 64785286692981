import apiAdmin from '@/api/admin'
import apiHome from '@/api/home'
import adminParse from '@/utils/parse/admin'
import mainParse from '@/utils/parse/home'
import router from '@/router'

export default {
	state: {
		// 현재 상담받을 내용
		current_estimate_contents: {
			item: null,
			value: null,
			interiorSelect: null,
			interiorSelectauto: null,
			askContents: null,
			pageType: null,
			price: {
				exclusive_area: null, //전용면적
				housetype: null, //집유형
				roomcount: null, //방개수
				veranda_yn: null, //베란다확장여부
				buildscope: null, //시공범위
				removal_yn: null, //철거여부
				est_cost: null, //예상견적비용(평균)
				est_cost_min: null, //예상견적비용(최소)
				est_cost_max: null, //예상견적비용(최대)
			},
			product: null,
			// product: {
			// 	login_id: null,
			// 	login_name: null,
			// 	regi_userid: null,
			// 	content: null,
			// 	goods_code: null,
			// 	product_kind: null,
			// 	exclusive_area: null, //전용면적
			// 	housetype: null, //집유형
			// 	roomcount: null, //방개수
			// 	veranda_yn: null, //베란다확장여부
			// 	buildscope: null, //시공범위
			// 	removal_yn: null, //철거여부
			// 	est_cost: null, //예상견적비용(평균)
			// 	est_cost_min: null, //예상견적비용(최소)
			// 	est_cost_max: null, //예상견적비용(최대)
			// },
		},
		current_estimate_userinfo: {
			name: null,
			hand: null,
		},
	},
	mutations: {
		// 기본데이터
		HOME_MU_CURRENT_ESTIMATE_CONTENT_ITEM(state, item) {
			state.current_estimate_contents.item = item
		},
		// 빠른견적금액
		HOME_MU_CURRENT_ESTIMATE_CONTENT_VALUE(state, value) {
			state.current_estimate_contents.value = value
		},
		// 인테리어선택한지점
		HOME_MU_CURRENT_ESTIMATE_CONTENT_INTERIORSELECT(state, interiorselect) {
			state.current_estimate_contents.interiorSelect = interiorselect
		},
		// 인테리어자동선택한지점
		HOME_MU_CURRENT_ESTIMATE_CONTENT_INTERIORSELECTAUTO(state, interiorselectauto) {
			state.current_estimate_contents.interiorSelectauto = interiorselectauto
		},
		// 문의사항
		HOME_MU_CURRENT_ESTIMATE_CONTENT_ASKCONTENT(state, askcontents) {
			state.current_estimate_contents.askContents = askcontents
		},
		// 페이지타입
		HOME_MU_CURRENT_ESTIMATE_CONTENT_PAGETYPE(state, pagetype) {
			state.current_estimate_contents.pageType = pagetype
		},
		// 인테리어자동선택한지점
		HOME_MU_CURRENT_ESTIMATE_CONTENT_PRICE(state, priceItem) {
			state.current_estimate_contents.price[priceItem] = priceItem
		},
		// 인테리어종합데이터
		HOME_MU_CURRENT_ESTIMATE_CONTENT_PRODUCT(state, productItem) {
			state.current_estimate_contents.product = productItem
		},
		// 유저 정보
		HOME_MU_CURRENT_ESTIMATE_CONTENT_USERINFO(state, userinfo) {
			state.current_estimate_userinfo.name = userinfo.name
			state.current_estimate_userinfo.hand = userinfo.hand
		},
	},
	actions: {
		// 인테리어 상담받을 내용
		// async HOME_ACT_UPDATE_CURRENT_ESTIMATE_CONTENT({ commit }, items) {
		// 	console.log('인테리어 상담받을 내용', items)
		// 	this.commit('HOME_MU_CURRENT_ESTIMATE_CONTENT', items)
		// },
	},
	getters: {
		// 현재 상담 인테리어 지점 요청사항들
		HOME_GET_CURRENT_ESTIMATE_CONTENT: state => state.current_estimate_contents,
		HOME_GET_CURRENT_ESTIMATE_USERINFO: state => state.current_estimate_userinfo,
		HOME_GET_CURRENT_ESTIMATE_PRODUCT: state => state.current_estimate_userinfo.product,
	},
	modules: {},
}
