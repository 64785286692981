<template>
	<div>
		<!-- <svg
			width="40"
			height="40"
			viewBox="0 0 40 40"
			:fill="color"
			xmlns="http://www.w3.org/2000/svg"
		>
			<mask
				id="mask0_384_714"
				style="mask-type: alpha"
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="40"
				height="40"
			>
				<rect
					width="40"
					height="40"
					fill="#D9D9D9"
				/>
			</mask>
			<g mask="url(#mask0_384_714)">
				<path
					d="M21.6667 38.3333C21.1944 38.3333 20.7989 38.1733 20.48 37.8533C20.16 37.5344 20 37.1388 20 36.6666C20 36.1944 20.16 35.7988 20.48 35.4799C20.7989 35.1599 21.1944 34.9999 21.6667 34.9999H31.6667V33.3333H28.3333C27.4167 33.3333 26.6322 33.0071 25.98 32.3549C25.3267 31.7016 25 30.9166 25 29.9999V23.3333C25 22.4166 25.3267 21.6316 25.98 20.9783C26.6322 20.326 27.4167 19.9999 28.3333 19.9999H31.6667V18.3333C31.6667 15.111 30.5278 12.361 28.25 10.0833C25.9722 7.80547 23.2222 6.66659 20 6.66659C16.7778 6.66659 14.0278 7.80547 11.75 10.0833C9.47222 12.361 8.33333 15.111 8.33333 18.3333V19.9999H11.6667C12.5833 19.9999 13.3678 20.326 14.02 20.9783C14.6733 21.6316 15 22.4166 15 23.3333V29.9999C15 30.9166 14.6733 31.7016 14.02 32.3549C13.3678 33.0071 12.5833 33.3333 11.6667 33.3333H8.33333C7.41667 33.3333 6.63167 33.0071 5.97833 32.3549C5.32611 31.7016 5 30.9166 5 29.9999V18.3333C5 16.2777 5.39556 14.3399 6.18667 12.5199C6.97889 10.701 8.05556 9.11103 9.41667 7.74992C10.7778 6.38881 12.3678 5.31214 14.1867 4.51992C16.0067 3.72881 17.9444 3.33325 20 3.33325C22.0556 3.33325 23.9933 3.72881 25.8133 4.51992C27.6322 5.31214 29.2222 6.38881 30.5833 7.74992C31.9444 9.11103 33.0211 10.701 33.8133 12.5199C34.6044 14.3399 35 16.2777 35 18.3333V34.9999C35 35.9166 34.6739 36.7016 34.0217 37.3549C33.3683 38.0071 32.5833 38.3333 31.6667 38.3333H21.6667ZM8.33333 29.9999H11.6667V23.3333H8.33333V29.9999ZM28.3333 29.9999H31.6667V23.3333H28.3333V29.9999Z"
					:fill="color"
				/>
			</g>
		</svg> -->		
		
        <svg width="24" height="30" viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.25 0.9375H4.125C3.08107 0.938671 2.08023 1.35389 1.34206 2.09206C0.603889 2.83023 0.188671 3.83107 0.1875 4.875V28.5C0.187507 28.6492 0.246773 28.7923 0.352261 28.8977C0.457748 29.0032 0.600818 29.0625 0.75 29.0625H21C21.1492 29.0625 21.2923 29.0032 21.3977 28.8977C21.5032 28.7923 21.5625 28.6492 21.5625 28.5C21.5625 28.3508 21.5032 28.2077 21.3977 28.1023C21.2923 27.9968 21.1492 27.9375 21 27.9375H1.3125V27.375C1.31333 26.6293 1.60991 25.9144 2.13718 25.3872C2.66444 24.8599 3.37933 24.5633 4.125 24.5625H23.25C23.3992 24.5625 23.5423 24.5032 23.6477 24.3977C23.7532 24.2923 23.8125 24.1492 23.8125 24V1.5C23.8125 1.35082 23.7532 1.20775 23.6477 1.10226C23.5423 0.996773 23.3992 0.937507 23.25 0.9375ZM22.6875 23.4375H4.125C3.60087 23.4372 3.08198 23.5418 2.59894 23.7452C2.1159 23.9487 1.67848 24.2468 1.3125 24.622V4.875C1.31333 4.12933 1.60991 3.41444 2.13718 2.88718C2.66444 2.35991 3.37933 2.06333 4.125 2.0625H22.6875V23.4375Z" :fill="color"/>
        </svg>

	</div>
</template>

<script>
export default {
	name: 'CommonIconsCatalogIcon',
	props: ['color'],
}
</script>

<style lang="scss" scoped></style>
