<template>
	<div class="mb-9">
		<v-col
			cols="12"
			class="table_type01 px-4 py-0"
		>
			<v-row
				v-for="(item, i) in items"
				:key="i"
				class="ma-0"
			>
				<v-col
					cols="5"
					sm="3"
					class="th bc_gr th_bt fw_normal fl_half"
					>{{ item.title }}</v-col
				>
				<v-col
					cols="7"
					sm="9"
					class="td color_font"
					align="start"
				>
					{{ item.text }}
					<CommonTooltipType01
						v-if="item.tooltip"
						:tooltip="item.tooltip"
					/>
				</v-col>
			</v-row>
			<v-row>
				<v-col
					cols="12 pa-0 ma-0"
					align="right"
				>
					<p class="notice fl_half px-5 px-md-0 mt-2">* 실제 제품과 다소 차이가 있을 수 있습니다.</p>
				</v-col>
			</v-row>
		</v-col>
	</div>
</template>

<script>
export default {
	name: 'HomeProductDetailInfo',
	components: {},
	props: ['items'],
	data: () => ({}),
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
}
</script>
<style scoped lang="scss">
.table_type01 {
	border-top: 0 !important;

	.bc_gr {
		border-bottom: 1px solid #e6e6e6;
		color: #777777;
	}
	.th {
		font-family: 'NotoSansKR-Regular' !important;
	}
	.td {
		border-bottom: 1px solid #e6e6e6;
		font-family: 'NotoSansKR-Medium' !important;
	}
	.row {
		&:last-child {
			.bc_gr {
				border-bottom: 0 !important;
			}
			.td {
				border-bottom: 0 !important;
			}
		}
	}
	.notice {
		color: #9e9e9e;
		font-size: $font_sm;
		font-weight: $fw_normal;
	}
}
</style>
