export default {
  computed: {
    model02: {
      get() {
        return this.newmodel;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
