export const careflo = [
	{
		path: '/careflo',
		name: 'CareFlo',
		component: () => import('@/layout/careflo.vue'),
		meta: { type: 'layout' },
		children: [
			{
				path: ':param',
				name: 'Care_Flo_Home',
				component: () => import(/* webpackChunkName: "Care_Flo_Home" */ '@/views/home/careflo/home.vue'),
				meta: { title: '안심케어 게이트' },
			},
			{
				path: 'customer/verification',
				name: 'Care_Flo_Customer_Verification',
				component: () =>
					import(
						/* webpackChunkName: "Care_Flo_Customer_Verification" */ '@/views/home/careflo/customer/verification.vue'
					),
				meta: { allow: 'NFC', title: '안심케어 구매자 정보확인' },
			},
			{
				path: 'customer/clear',
				name: 'Care_Flo_Customer_Clear',
				component: () =>
					import(/* webpackChunkName: "Care_Flo_Customer_Clear" */ '@/views/home/careflo/customer/clear.vue'),
				meta: { allow: 'NFC', title: '안심케어 구매자정보 입력완료' },
			},
			{
				path: 'customer/login',
				name: 'Care_Flo_Customer_Login',
				component: () =>
					import(/* webpackChunkName: "Care_Flo_Customer_Login" */ '@/views/home/careflo/customer/login.vue'),
				meta: { allow: 'NFC', title: '안심케어 구매자 로그인' },
			},
			{
				path: 'customer/asRegister',
				name: 'Care_Flo_Customer_AS_Register',
				component: () =>
					import(
						/* webpackChunkName: "Care_Flo_Customer_AS_Register" */ '@/views/home/careflo/customer/asRegister.vue'
					),
				meta: { allow: 'NFC', title: '안심케어 구매자 AS 신청하기' },
			},
			{
				path: 'customer/asInfo',
				name: 'Care_Flo_Customer_AS_Info',
				component: () =>
					import(/* webpackChunkName: "Care_Flo_Customer_AS_Info" */ '@/views/home/careflo/customer/asInfo.vue'),
				meta: { allow: 'NFC', title: '안심케어 구매자 AS 신청내역' },
			},
			{
				path: 'store/verification',
				name: 'Care_Flo_Store_Verification',
				component: () =>
					import(/* webpackChunkName: "Care_Flo_Store_Verification" */ '@/views/home/careflo/store/verification.vue'),
				meta: { allow: 'NFC', title: '안심케어 시공자 정보확인' },
			},
			{
				path: 'store/login',
				name: 'Care_Flo_Store_Login',
				component: () => import(/* webpackChunkName: "Care_Flo_Store_Login" */ '@/views/home/careflo/store/login.vue'),
				meta: { allow: 'NFC', title: '안심케어 시공자 로그인' },
			},
			{
				path: 'store/scan',
				name: 'Care_Flo_Store_Scan',
				component: () => import(/* webpackChunkName: "Care_Flo_Store_Scan" */ '@/views/home/careflo/store/scan.vue'),
				meta: { allow: 'NFC', title: '안심케어 시공자 스캔' },
			},
			{
				path: 'store/upload',
				name: 'Care_Flo_Store_Upload',
				component: () => import(/* webpackChunkName: "Care_Flo_Store_Scan" */ '@/views/home/careflo/store/upload.vue'),
				meta: { allow: 'NFC', title: '안심케어 바코드촬영 완료' },
			},
			{
				path: 'scan',
				name: 'Scan',
				component: () => import(/* webpackChunkName: "Scan" */ '@/views/home/careflo/store/scan.vue'),
				meta: { allow: 'NFC', title: '스캔' },
			},
		],
	},
]
