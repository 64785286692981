<template>
	<v-col
		class="ma-0 pa-0 card_type"
		elevation="0"
		@click="onClick"
	>
		<v-col class="pa-0">
			<v-img
				v-if="menu.image"
				:src="menu.image"
				width="100%"
				height="160px"
				class="card_type1_img"
			/>
		</v-col>
		<v-col
			align="center"
			justify="center"
			class="pa-0 py-3 card_content_text fill-height"
		>
			<p
				v-if="menu.title"
				class="title"
			>
				{{ menu.title }}
			</p>
			<p
				v-if="menu.subText"
				class="sub_title"
			>
				{{ menu.subText }}
			</p>
		</v-col>
	</v-col>
</template>

<script>
export default {
	name: 'UserUserScrapCard',
	props: ['menu'],
	data: () => ({}),
	computed: {},
	created() {},
	mounted() {},
	methods: {
		onClick() {
			this.$emit('submit', this.menu)
		},
	},
}
</script>

<style scoped lang="scss">
// 한줄이상 (...)처리
@mixin ellipsis {
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

// 두줄이상 (...)처리
@mixin ellipsis_2 {
	display: -webkit-box !important;
	word-wrap: break-word !important;
	-webkit-line-clamp: 2 !important;
	-webkit-box-orient: vertical !important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
}

.card_type {
	width: 190px;
	min-height: auto;
	border-radius: 0;

	.card_type1_img {
		height: 200px;
	}
	.card_content_text {
		color: #262626;
		word-break: keep-all;

		.title {
			@include ellipsis_2;
			max-width: 95%;
			font-family: 'NotoSansKR-Bold' !important;
			font-size: 14px !important;
			line-height: 1.2;
		}
		.sub_title {
			@include ellipsis;
			max-width: 95%;
			font-family: 'NotoSansKR-Light' !important;
			font-size: 14px !important;
		}
	}
}

// @media all and (max-width: 440px) {
// 	.card_type {
// 		.card_type1_img {
// 			height: 130px;
// 		}
// 		width: 135px;
// 		height: 200px;
// 	}
// }
// @media all and (max-width: 350px) {
// 	.card_type {
// 		.card_type1_img {
// 			height: 200px;
// 		}
// 		width: 200px;
// 		height: 280px;
// 	}
// }

@media all and (max-width: 450px) {
	.card_type {
		min-height: auto;
	}
	.card_grid {
		grid-template-columns: repeat(1, 3fr);
		grid-row-gap: 20px;
		justify-items: center !important;
		align-items: start !important;
		.clickCursor {
			width: 100%;
			overflow: hidden;
			//border-bottom: 1px solid #e6e6e6;
			.card_type {
				max-width: 100%;
				min-height: auto;
				width: calc(100vw - 22px) !important;
				display: flex;
				overflow: hidden;
				height: 100%;
				align-items: center;
				border-bottom: 1px solid #e6e6e6;
				padding-bottom: 25px !important; //margin-bottom: 12px !important;
				.card_type1_img {
					height: 100px;
				}
				> div {
					width: 100%;
					align-items: center;
					display: block;
					//display: flex;
					padding: 0 0 0 10px !important;
					text-align: left !important;
					.card_type1_img {
						height: auto !important;
						:deep(.v-image__image) {
							border-radius: 4px;
						}
					}
					&:nth-of-type(1) {
						text-align: center !important;
						justify-content: center;
						display: flex;
						width: 100px;
						height: 100px;
						overflow: hidden;
						border-radius: 4px;
						padding: 0 !important;
						flex: unset;
					}
				}
			}
			&:last-child {
				.card_type {
					border-bottom: 0;
					//margin-bottom: 0 !important;
				}
			}
		}
	}
}
// @media all and (max-width: 350px) {
// 	.card_grid {
// 		grid-template-columns: repeat(1, 1fr);
// 	}
// }
</style>
