<template>
	<div class="befit">
		<p class="mb-4 mb-md-10 tit">추천제품</p>

		<v-row class="ma-0 pa-0 wrap">
			<template v-for="(item, i) in items">
				<v-col
					v-if="i < 8"
					:key="i"
					class="pa-0 px-1 px-sm-2 pb-4 pb-md-10 item"
				>
					<v-row class="ma-0 rank">
						<v-col
							v-if="i === 0"
							class="pa-0 mr-3"
							cols="auto"
							align-self="center"
						>
							<v-img
								class="crown"
								width="40"
								:src="require('@/assets/img/search/icon_crown.svg')"
							/>
						</v-col>
						<v-col
							v-else
							class="pa-0 mr-2"
							cols="auto"
							align-self="center"
						>
							<span class="num">{{ i + 1 }}</span>
						</v-col>

						<v-col
							class="pa-0 star"
							cols="auto"
							align-self="center"
						>
							<div class="d-flex">
								<div
									v-for="(star, j) in item.stars"
									:key="j"
								>
									<svg
										width="17"
										height="16"
										viewBox="0 0 17 16"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<defs>
											<linearGradient
												:id="`star-gradient-${i}${j}`"
												x1="0%"
												y1="0%"
												x2="100%"
												y2="0%"
											>
												<template v-if="star !== 0">
													<stop
														:offset="`${star}%`"
														stop-color="#FFD233"
													/>
													<stop
														:offset="`${star + 0.1 > 100 ? 100 : star + 0.1}%`"
														stop-color="#FFD233"
													/>
													<stop
														:offset="`${star + 0.1 > 100 ? 100 : star + 0.1}%`"
														stop-color="#bdbdbd"
													/>
													<stop
														:offset="`100%`"
														stop-color="#bdbdbd"
													/>
												</template>
												<template v-else>
													<stop
														:offset="`0%`"
														stop-color="#bdbdbd"
													/>
													<stop
														:offset="`100%`"
														stop-color="#bdbdbd"
													/>
												</template>
											</linearGradient>
										</defs>
										<path
											d="M1.01183 7.41667L4.01183 10.1667L3.17849 14C3.09516 14.5 3.26183 15 3.67849 15.25C3.84516 15.4167 4.09516 15.5 4.34516 15.5C4.51183 15.5 4.67849 15.5 4.84516 15.4167C4.84516 15.4167 4.92849 15.4167 4.92849 15.3333L8.34516 13.4167L11.7618 15.3333C11.7618 15.3333 11.8452 15.3333 11.8452 15.4167C12.2618 15.5833 12.7618 15.5833 13.0952 15.3333C13.5118 15.0833 13.6785 14.5833 13.5952 14.0833L12.7618 10.25C13.0952 10 13.5952 9.5 14.0952 9L15.6785 7.58333L15.7618 7.5C16.0952 7.16667 16.1785 6.66667 16.0118 6.25C15.8452 5.83333 15.5118 5.5 15.0118 5.41667H14.9285L11.0118 5L9.42849 1.41667C9.42849 1.41667 9.42849 1.33333 9.34516 1.33333C9.26183 0.75 8.84516 0.5 8.42849 0.5C8.01183 0.5 7.59516 0.75 7.34516 1.16667C7.34516 1.16667 7.34516 1.25 7.26183 1.25L5.67849 4.83333L1.76183 5.25H1.67849C1.26183 5.33333 0.845161 5.66667 0.678494 6.08333C0.595161 6.58333 0.678494 7.08333 1.01183 7.41667Z"
											:fill="`url(#star-gradient-${i}${j})`"
										/>
									</svg>
								</div>
							</div>
						</v-col>
					</v-row>

					<CommonCardMenuCardDefault
						maru-recommend
						new-tab-link
						:item="item"
					/>
				</v-col>
			</template>
		</v-row>
	</div>
</template>

<script>
import common from '@/mixins/common'
export default {
	name: 'Befit',
	mixins: [common],
	props: ['items'],
	data: () => ({}),
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
}
</script>

<style scoped lang="scss">
.befit {
	.tit {
		text-align: center;
		color: $color_font;
		font-weight: $fw_regular;
		font-size: $font_tit;
	}

	.rank {
		margin-bottom: 20px !important;
		.num {
			display: inline-block;
			width: 36px;
			height: 36px;
			font-weight: $fw_bold;
			font-size: $font_xl;
			border-radius: 50%;
			color: $color_white;
			background-color: $color_gray_8;
			text-align: center;
			vertical-align: middle;
			line-height: 36px;
		}
		.star {
			p {
				font-size: $font_sm;
				color: $color_gray_7;
			}
		}
	}

	.wrap {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(25%, 25%));
		grid-template-rows: repeat(auo-fit, minmax(400px, 4fr));
	}
}
@media all and (min-width: 600px) and (max-width: 1264px) {
	.wrap {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(33.3%, 33.3%)) !important;
		// gap: 16px !important;
	}
}
@media all and (max-width: 600px) {
	.wrap {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(50%, 50%)) !important;
		// gap: 12px !important;
	}
}

@media all and (max-width: 959px) {
	.befit {
		.tit {
			font-size: $font_lg !important;
		}
		.rank {
			margin-bottom: 8px !important;
			.crown {
				width: 27px !important;
			}
			.num {
				width: 24px !important;
				height: 24px !important;
				font-size: $font_normal !important;
				line-height: 24px !important;
			}
		}
	}
}
</style>
