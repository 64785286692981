<template>
	<v-dialog
		v-model="dialog"
		persistent
		width="320"
	>
		<v-card class="pa-0">
			<!-- 아이콘이 있을경우 -->
			<v-col
				class="pa-0 mb-2"
				align="center"
			>
				<v-img
					v-if="icon"
					class="mb-2"
					max-width="30"
					height="30"
					eager
					:src="require(`@/assets/img/${icon}`)"
				/>
				<strong class="state_tit">{{ title }}</strong>
			</v-col>

			<!-- 컨텐츠 -->
			<v-card-title class="pa-0">
				<v-row class="ma-0 text_wrapper">
					<v-col
						class="pa-0"
						align="center"
						align-self="center"
					>
						<p
							class="dialog_text"
							v-html="text"
						/>
					</v-col>
				</v-row>
			</v-card-title>

			<!-- 확인버튼 -->
			<v-card-actions class="pa-0">
				<v-col
					align="center"
					justify="center"
					class="pa-0"
				>
					<CommonButtonsButton01
						class="dialog_btn"
						name="확인"
						color="#00592D"
						height="46"
						@click="$emit('close')"
					/>
				</v-col>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: 'Dialog',
	props: ['icon', 'title', 'text', 'dialog'],
	data: () => ({}),
	created() {},
	mounted() {},
	methods: {
		closePopup(e) {
			this.$emit('close', e)
		},
	},
}
</script>

<style scoped lang="scss">
:deep(.v-dialog) {
	border: 1px solid $color_gray_8;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
	border-radius: 8px;
	padding: 30px 16px 16px;
	background-color: #fff;

	.v-card {
		border-radius: 0;
		box-shadow: none;
		// 컨텐츠
		.text_wrapper {
			min-height: 70px;
		}
		.dialog_text {
			font-size: $font_normal !important;
			color: $color_gray_8;
			word-break: keep-all;
			white-space: pre-wrap;
		}
	}
}

.dialog_btn {
	border-radius: 5px;
	margin-top: 20px;
	:deep(.v-btn__content) {
		span {
			font-weight: $fw_bold !important;
			font-size: $font_lg !important;
		}
	}
}

@media all and (max-width: 600px) {
	:deep(.v-dialog) {
		padding: 16px 10px 10px !important;
	}
	.dialog_btn {
		margin-top: 14px !important;
		max-height: 36px !important;
		:deep(.v-btn__content) {
			span {
				font-size: $font_normal !important;
			}
		}
	}
}
</style>
