import { render, staticRenderFns } from "./other_rect.vue?vue&type=template&id=1b1f5f60&scoped=true&"
import script from "./other_rect.vue?vue&type=script&lang=js&"
export * from "./other_rect.vue?vue&type=script&lang=js&"
import style0 from "./other_rect.vue?vue&type=style&index=0&id=1b1f5f60&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b1f5f60",
  null
  
)

export default component.exports