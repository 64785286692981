var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","width":"1000"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pa-0"},[_c('AdminItemsTitle',{attrs:{"title":_vm.mode === 'create' ? `${_vm.title} 등록` : `${_vm.title} 수정`},on:{"close":function($event){return _vm.$emit('close')}}}),_c('v-col',{staticClass:"pa-3 pa-md-5"},[_c('v-container',{staticClass:"admin_table"},[_c('v-row',{staticClass:"ma-0 admin_table_area"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","md":"6"}},[_c('AdminItemsTableItem',{attrs:{"title":"*사용여부","radio":""},model:{value:(_vm.models.use_yn),callback:function ($$v) {_vm.$set(_vm.models, "use_yn", $$v)},expression:"models.use_yn"}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","md":"6"}},[_c('AdminItemsTableItem',{attrs:{"title":"조회수","text":_vm.editItem ? String(_vm.editItem.hits) : ''}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[(_vm.themeCodeItems)?_c('AdminItemsTableItem',{attrs:{"title":"*테마","select":_vm.themeCodeItems,"fill":true},model:{value:(_vm.models.theme_code),callback:function ($$v) {_vm.$set(_vm.models, "theme_code", $$v)},expression:"models.theme_code"}}):_vm._e()],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[(_vm.models.image_url === null)?_c('AdminItemsTableItem',{attrs:{"title":"*대표이미지","file":"","fill":true},model:{value:(_vm.image_url),callback:function ($$v) {_vm.image_url=$$v},expression:"image_url"}}):_c('AdminItemsTableItem',{attrs:{"title":"대표이미지","text-button":{
								text: _vm.image_url,
								button: {
									text: '대표이미지 삭제',
									color: 'primary',
								},
							},"fill":true},on:{"click":_vm.deleteImageUrl}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('AdminItemsTableItem',{attrs:{"title":"*제목","input":"","fill":true},model:{value:(_vm.models.title),callback:function ($$v) {_vm.$set(_vm.models, "title", $$v)},expression:"models.title"}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('AdminItemsTableItem',{attrs:{"title":"*내용","text-button":{
								text: _vm.models.description,
								button: {
									text: 'Editor 편집',
									color: 'primary',
								},
							},"fill":true},on:{"click":function($event){_vm.onEditDialog = true}}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[(_vm.models.attach_url === null)?_c('AdminItemsTableItem',{attrs:{"title":"첨부파일","file":"","fill":true},model:{value:(_vm.attach_url),callback:function ($$v) {_vm.attach_url=$$v},expression:"attach_url"}}):_c('AdminItemsTableItem',{attrs:{"title":"첨부파일","text-button":{
								text: _vm.attach_url,
								button: {
									text: '첨부파일 삭제',
									color: 'primary',
								},
							},"fill":true},on:{"click":function($event){_vm.models.attach_url = null
								_vm.attach_url = null}}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","md":"6"}},[(_vm.editItem)?_c('AdminItemsTableItem',{attrs:{"title":"등록자","text":_vm.editItem.regi_userid}}):_vm._e()],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","md":"6"}},[(_vm.editItem)?_c('AdminItemsTableItem',{attrs:{"title":"등록일","text":_vm.editItem.regi_date}}):_vm._e()],1)],1)],1),_c('v-row',{staticClass:"ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"pl-0",attrs:{"cols":"12","md":"6"}},[_c('v-row',{staticClass:"ma-0"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"error","block":"","tile":"","elevation":"0"},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("clear")]),_c('span',{staticClass:"ml-2"},[_vm._v("취소")])],1)],1),(_vm.mode === 'create')?_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"primary","block":"","tile":"","elevation":"0","disabled":_vm.allowValue},on:{"click":_vm.onApiCallItem}},[_c('v-icon',[_vm._v("add")]),_c('span',{staticClass:"ml-2"},[_vm._v("등록")])],1)],1):_vm._e(),(_vm.mode === 'edit')?_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"secondary","block":"","tile":"","elevation":"0","disabled":_vm.allowValue},on:{"click":_vm.onApiCallItem}},[_c('v-icon',[_vm._v("edit")]),_c('span',{staticClass:"ml-2"},[_vm._v("수정")])],1)],1):_vm._e()],1)],1)],1)],1)],1),(_vm.onEditDialog)?_c('CommonEditorQuillEditorDialog',{attrs:{"dialog":_vm.onEditDialog,"edit-contents":_vm.models.description},on:{"save":_vm.contentSave,"close":function($event){_vm.onEditDialog = false}}}):_vm._e(),(_vm.loading)?_c('CommonLoading'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }