<template>
	<v-col
		class="pa-0 layout_align"
		lg="9"
		md="11"
		cols="12"
	>
		<v-row class="ma-0">
			<v-col
				class="pa-0 mb-2 mb-md-0"
				cols="12"
				md="4"
				align="center"
			>
				<div class="mr-0 mr-md-3 consulting_block">
					<v-img
						class="mb-2 mb-md-4"
						max-width="72"
						height="72"
						eager
						:src="require('@/assets/img/careflo/icon_consulting.svg')"
					/>
					<h3 class="mb-5">1:1문의</h3>
					<p class="desc">
						1:1 문의를 남겨주시면 <br />
						빠른시일이내 답변을 드립니다!
					</p>
					<v-col
						class="pa-0 btn_bar"
						cols="6"
					>
						<CommonButtonsButton01
							height="36"
							name="바로가기"
							color="#00CE7D"
							@click="$router.push('/custom/question')"
						/>
					</v-col>
				</div>
			</v-col>
			<v-col
				class="pa-0 mb-2 mb-md-0"
				cols="12"
				md="4"
				align="center"
			>
				<div class="mx-0 mx-md-3 consulting_block">
					<v-img
						class="mb-2 mb-md-4"
						max-width="72"
						height="72"
						eager
						:src="require('@/assets/img/careflo/icon_toktok.svg')"
					/>
					<h3 class="mb-5">동화톡톡</h3>
					<p class="desc">
						상담 직원과 대화를 통해<br />
						궁금증을 해결해보세요!
					</p>
					<v-col
						class="pa-0 btn_bar"
						cols="6"
					>
						<CommonButtonsButton01
							height="36"
							name="통화 톡톡 상담"
							color="#00CE7D"
							@click="onClick('동화 톡톡')"
						/>
					</v-col>
				</div>
			</v-col>
			<v-col
				class="pa-0 mb-0"
				cols="12"
				md="4"
				align="center"
			>
				<div class="ml-0 ml-md-3 consulting_block">
					<v-img
						class="mb-2 mb-md-4"
						max-width="72"
						height="72"
						eager
						:src="require('@/assets/img/careflo/icon_custom.svg')"
					/>
					<h3 class="mb-5">고객센터</h3>
					<p class="desc">
						전화 상담원과의 통화하여<br />
						불편사항을 빠르게 해결해보세요!
					</p>
					<div class="time mt-2"><strong>운영시간</strong> 평일 09:00 ~ 17:00</div>
					<v-col
						class="pa-0 btn_bar"
						cols="6"
					>
						<a
							ref="phoneCall"
							href="tel:1899-2837"
							style="display: none"
						></a>
						<CommonButtonsButton01
							height="36"
							name="1899-2837"
							color="#00CE7D"
							@click="onClick('동화 콜센터')"
						/>
					</v-col>
				</div>
			</v-col>
		</v-row>
	</v-col>
</template>

<script>
import common from '@/mixins/common'
import naver_chatbot from '@/mixins/naver_chatbot'

export default {
	name: 'HomeCustomCareFloLinkConsulting',
	components: {},
	mixins: [common, naver_chatbot],
	props: [],
	data: () => ({}),
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		onClick(link) {
			switch (link) {
				case '동화 톡톡':
					this.naver_chatbot_on_click()
					break
				case '동화 콜센터':
					this.common_phoneCall('18992837')
					break
			}
		},
	},
}
</script>

<style scoped lang="scss">
.consulting_block {
	transform: rotate(0);
	height: 100%;
	padding: 20px 20px 100px !important;
	background: #ffffff;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
	border-radius: 20px;
	h3 {
		font-size: $font_sub_tit;
	}
	.desc {
		color: #717171;
		font-size: $font_lg;
	}
	.time {
		color: $color_gray_7;
		font-size: $font_sm;
	}
	.btn_bar {
		position: fixed;
		bottom: 20px;
		left: 50%;
		transform: translateX(-50%);
		:deep(.v-btn__content) {
			font-size: $font_lg;
			font-weight: $fw_regular;
		}
	}
}
@media all and (max-width: 959px) {
	.consulting_block {
		padding: 20px 20px 80px !important;
		.v-image {
			max-width: 60px !important;
			height: 60px !important;
		}
		h3 {
			font-size: $font_lg !important;
		}
		.desc {
			font-size: $font_normal !important;
		}
	}
}
</style>
