var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","width":"1000"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pa-0"},[_c('AdminItemsTitle',{attrs:{"title":_vm.mode === 'create' ? `${_vm.title} 등록` : `${_vm.title} 수정`},on:{"close":function($event){return _vm.$emit('close')}}}),_c('v-col',{staticClass:"pa-3 pa-md-5"},[_c('v-container',{staticClass:"admin_table"},[_c('v-row',{staticClass:"ma-0 admin_table_area"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","md":"6"}},[_c('AdminItemsTableItem',{attrs:{"title":"*구분","select":_vm.admin_notice_divisionItems},model:{value:(_vm.models.division),callback:function ($$v) {_vm.$set(_vm.models, "division", $$v)},expression:"models.division"}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","md":"6"}},[_c('AdminItemsTableItem',{attrs:{"title":"*중요여부","select":_vm.admin_notice_importantItems},model:{value:(_vm.models.important),callback:function ($$v) {_vm.$set(_vm.models, "important", $$v)},expression:"models.important"}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('AdminItemsTableItem',{attrs:{"title":"*제목","input":"","fill":true},model:{value:(_vm.models.title),callback:function ($$v) {_vm.$set(_vm.models, "title", $$v)},expression:"models.title"}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('AdminItemsTableItem',{attrs:{"title":"*내용","text-button":{
								text: _vm.models.contents,
								button: {
									text: 'Editor 열기',
									color: 'primary',
								},
							},"fill":true},on:{"click":function($event){_vm.onEditDialog = true}}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[(_vm.models.file1 === null)?_c('AdminItemsTableItem',{attrs:{"title":"첨부파일1","file":"","fill":true},model:{value:(_vm.file1),callback:function ($$v) {_vm.file1=$$v},expression:"file1"}}):_c('AdminItemsTableItem',{attrs:{"title":"첨부파일1","text-button":_vm.file1DeleteButton,"fill":true},on:{"click":function($event){_vm.models.file1 = null
								_vm.file1 = null}}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[(_vm.models.file2 === null)?_c('AdminItemsTableItem',{attrs:{"title":"첨부파일2","file":"","fill":true},model:{value:(_vm.file2),callback:function ($$v) {_vm.file2=$$v},expression:"file2"}}):_c('AdminItemsTableItem',{attrs:{"title":"첨부파일2","text-button":_vm.file2DeleteButton,"fill":true},on:{"click":function($event){_vm.models.file2 = null
								_vm.file2 = null}}})],1)],1)],1),_c('v-row',{staticClass:"ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"pl-0",attrs:{"cols":"12","md":"6"}},[_c('v-row',{staticClass:"ma-0"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"error","block":"","tile":"","elevation":"0"},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("clear")]),_c('span',{staticClass:"ml-2"},[_vm._v("취소")])],1)],1),(_vm.mode === 'create')?_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"primary","block":"","tile":"","elevation":"0","disabled":_vm.allowValue},on:{"click":_vm.onApiCallItem}},[_c('v-icon',[_vm._v("add")]),_c('span',{staticClass:"ml-2"},[_vm._v("등록")])],1)],1):_vm._e(),(_vm.mode === 'edit')?_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"secondary","block":"","tile":"","elevation":"0","disabled":_vm.allowValue},on:{"click":_vm.onApiCallItem}},[_c('v-icon',[_vm._v("edit")]),_c('span',{staticClass:"ml-2"},[_vm._v("수정")])],1)],1):_vm._e()],1)],1)],1)],1)],1),(_vm.onEditDialog)?_c('CommonEditorQuillEditorDialog',{attrs:{"dialog":_vm.onEditDialog,"edit-contents":_vm.models.contents},on:{"save":_vm.contentSave,"close":function($event){_vm.onEditDialog = false}}}):_vm._e(),(_vm.loading)?_c('CommonLoading'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }