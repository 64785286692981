<template>
	<div>
		<v-col class="section pa-4 mb-5">
			<v-col
				class="pa-0 mb-5"
				align="center"
			>
				<v-img
					class="mb-2"
					width="30"
					eager
					:src="require('@/assets/img/careflo/icon_clear.svg')"
				/>
				<h3 class="mb-5">A/S 신청 완료!</h3>
				<p class="desc">
					A/S를 신청이 완료되었습니다. <br />
					담당자가 확인 후 신속하게 연락 드리겠습니다.
				</p>
			</v-col>

			<v-col class="pa-0 px-3 list_table">
				<v-row class="ma-0 py-3">
					<v-col
						class="pa-0 th"
						cols="6"
					>
						상태
					</v-col>
					<v-col
						class="pa-0 td"
						cols="6"
						align="end"
					>
						접수중
					</v-col>
				</v-row>
				<v-row class="ma-0 py-3">
					<v-col
						class="pa-0 th"
						cols="6"
					>
						A/S 신청 시간
					</v-col>
					<v-col
						class="pa-0 td"
						cols="6"
						align="end"
					>
						{{ date }}
					</v-col>
				</v-row>
				<!-- <v-row class="ma-0 py-3">
					<v-col
						class="pa-0 th"
						cols="6"
					>
						A/S 구분
					</v-col>
					<v-col
						class="pa-0 td"
						cols="6"
						align="end"
					>
						무상
					</v-col>
				</v-row> -->
			</v-col>
		</v-col>

		<v-col
			class="pa-0 layout_align"
			cols="4"
		>
			<v-btn
				block
				height="42"
				color="#ccc"
				outlined
				class="btn_list"
				@click="$emit('close')"
			>
				신청목록
			</v-btn>
		</v-col>
	</div>
</template>

<script>
import common from '@/mixins/common'

export default {
	name: 'Clear',
	mixins: [common],
	props: {},
	data: () => ({
		date: '',
	}),
	created() {
		const today = new Date()
		const year = today.getFullYear()
		const month = String(today.getMonth() + 1).padStart(2, '0')
		const day = String(today.getDate()).padStart(2, '0')
		this.date = `${year}-${month}-${day}`
	},
	methods: {},
}
</script>

<style scoped lang="scss">
.section {
	background-color: $color_white;
	h3 {
		font-size: $font_lg;
	}
	.desc {
		font-size: $font_normal;
		color: $color_gray_8;
	}
}

.list_table {
	border: 1px solid $color_gray_2;
	border-radius: 4px;
	.row {
		border-bottom: 1px solid $color_gray_2;
		&:last-child {
			border: none !important;
		}
		.th {
			font-size: $font_normal;
			color: $color_gray_5;
		}
		.td {
			font-size: $font_normal;
			color: $color_font;
		}
	}
}

.btn_list {
	:deep(.v-btn__content) {
		color: $color_gray_6;
		font-size: $font_normal;
	}
}
</style>
