import apiAdmin from '@/api/admin'
import adminParse from '@/utils/parse/admin'

export default {
	state: {
		// 통계 홈
		home_summary: [],
	},
	mutations: {
		STATISTICS_MU_HOME_SUMMARY(state, summary) {
			state.home_summary = summary
		},
	},
	actions: {
		// 통계
		// STATISTICS

		// 대시보드
		async ADMIN_ACT_STATISTICS_DASHBOARD() {
			try {
				const res = await apiAdmin.statistics.DashboardGet()
				const result = res == undefined || res == null ? {} : adminParse.statistics.dashboard.parser(res)
				console.log('admin.statistics.DashabordGet', result)
				return result
			} catch (e) {
				console.log('admin.statistics.DashabordGet', e)
			}
		},
		// 통계관리 - 통계 홈
		async ADMIN_ACT_STATISTICS_HOME_SUMMARY({ commit }, query) {
			try {
				let res = await apiAdmin.statistics.StatisticsHomeGet(query)
				console.log('ADMIN_ACT_STATISTICS_HOME_SUMMARY', res)
				const result = res == undefined || res == null ? [] : adminParse.statistics.home.parser(res)
				return result
			} catch (e) {
				console.log('admin.statistics.HomeSummaryGet', e)
			}
		},
		// 통계관리 방문자 통계 - 회원 통계
		async ADMIN_ACT_STATISTICS_MEMBER({ commit }, query) {
			try {
				let res = await apiAdmin.statistics.VisitMemberGet(query)
				console.log('ADMIN_ACT_STATISTICS_MEMBER', res)
				const result = res == undefined || res == null ? [] : adminParse.statistics.member.parser(res)
				// const result = res == undefined || res == null ? [] : res
				return result
			} catch (e) {
				console.log('admin.statistics.VisitMemberGet', e)
			}
		},
		// 통계관리 방문자 통계 - 방문자 통계
		async ADMIN_ACT_STATISTICS_VISITOR({ commit }, query) {
			try {
				let res = await apiAdmin.statistics.VisitVisitorGet(query)
				console.log('ADMIN_ACT_STATISTICS_VISITOR', res)
				const result = res == undefined || res == null ? [] : adminParse.statistics.visitor.parser(res)
				// const result = res == undefined || res == null ? [] : res
				return result
			} catch (e) {
				console.log('admin.statistics.VisitVisitorGet', e)
			}
		},
		// 통계관리 - 견적&상담
		async ADMIN_ACT_STATISTICS_CONSULT({ commit }, query) {
			try {
				let res = await apiAdmin.statistics.ServiceConsultGet(query)
				console.log('ADMIN_ACT_STATISTICS_CONSULT', res)
				const result = res == undefined || res == null ? [] : adminParse.statistics.consult.parser(res)
				return result
			} catch (e) {
				console.log('admin.statistics.ServiceConsultGet', e)
			}
		},
		// 통계관리 - 메인콘텐츠
		async ADMIN_ACT_STATISTICS_MAIN_CONTENT({ commit }, query) {
			let res
			let result
			try {
				switch (query.method) {
					case 'banner':
						res = await apiAdmin.statistics.ServiceMainBannerGet(query)
						result = res == undefined || res == null ? [] : adminParse.statistics.mainContent.parser(res)
						console.log('ADMIN_ACT_STATISTICS_MAIN_CONTENT_BANNER', res)
						break
					case 'tag':
						res = await apiAdmin.statistics.ServiceMainTagtGet(query)
						result = res == undefined || res == null ? [] : adminParse.statistics.mainContent.parser(res)
						console.log('ADMIN_ACT_STATISTICS_MAIN_CONTENT_TAG', res)
						break
				}
				return result
			} catch (e) {
				console.log('admin.statistics.VisitVisitorGet', e)
			}
		},
		// 통계관리 - 집들이콘텐츠
		async ADMIN_ACT_STATISTICS_HOUSEWARMING({ commit }, query) {
			try {
				let res = await apiAdmin.statistics.ServiceMainHousewarmingGet(query)
				console.log('ADMIN_ACT_STATISTICS_HOUSEWARMING', res)
				const result = res == undefined || res == null ? [] : adminParse.statistics.housewarming.parser(res)
				// const result = res == undefined || res == null ? [] : res
				return result
			} catch (e) {
				console.log('admin.statistics.ServiceMainHousewarmingGet', e)
			}
		},

		// 검색
		async ADMIN_ACT_STATISTICS_SEARCH_KEYWORD({ commit }, query) {
			try {
				let res = await apiAdmin.statistics.SearchKeywordGet(query)
				console.log('ADMIN_ACT_STATISTICS_SEARCH_KEYWORD', res)
				// const checkList = getters.ADMIN_GET_HOME_SUMMARY
				const result = res == undefined || res == null ? [] : adminParse.statistics.keyword.parser(res)
				// const result = res == undefined || res == null ? [] : res
				// commit('STATISTICS_MU_HOME_SUMMARY', result)
				return result
			} catch (e) {
				console.log('admin.statistics.SearchKeywordGet', e)
			}
		},
		async ADMIN_ACT_STATISTICS_SEARCH_PICTURE({ commit }, query) {
			try {
				let res = await apiAdmin.statistics.SearchPictureGet(query)
				console.log('ADMIN_ACT_STATISTICS_SEARCH_PICTURE', res)
				// const checkList = getters.ADMIN_GET_HOME_SUMMARY
				const result = res == undefined || res == null ? [] : adminParse.statistics.picture.parser(res)
				// const result = res == undefined || res == null ? [] : res
				// commit('STATISTICS_MU_HOME_SUMMARY', result)
				return result
			} catch (e) {
				console.log('admin.statistics.SearchPictureGet', e)
			}
		},
		async ADMIN_ACT_STATISTICS_SEARCH_COLOR({ commit }, query) {
			try {
				let res = await apiAdmin.statistics.SearchColorGet(query)
				console.log('ADMIN_ACT_STATISTICS_SEARCH_COLOR', res)
				// const checkList = getters.ADMIN_GET_HOME_SUMMARY
				const result = res == undefined || res == null ? [] : adminParse.statistics.color.parser(res)
				// const result = res == undefined || res == null ? [] : res
				// commit('STATISTICS_MU_HOME_SUMMARY', result)
				return result
			} catch (e) {
				console.log('admin.statistics.SearchColorGet', e)
			}
		},
		// 통계관리 - 메뉴사용
		async ADMIN_ACT_STATISTICS_MENU({ commit }, query) {
			try {
				let res = await apiAdmin.statistics.MenuGet(query)
				console.log('ADMIN_ACT_STATISTICS_MENU', res)
				const result = res == undefined || res == null ? [] : adminParse.statistics.menu.parser(res)
				// const result = res == undefined || res == null ? [] : res
				return result
			} catch (e) {
				console.log('admin.statistics.MenuGet', e)
			}
		},
	},
	getters: {
		// home
		ADMIN_GET_HOME_SUMMARY: state => state.home_summary,
	},
	modules: {},
}
