<template>
	<div class="mb-5 mx-0 cafeflowrap">
		<v-col
			class="warranty item clickCursor pa-0"
			@click="$router.push('/custom/careFlo')"
		>
			<v-row class="ma-0 tit_wrap">
				<v-col
					class="pa-0"
					align-self="center"
				>
					<p class="tit">Care-Flo</p>
					<p class="sub_txt">품질에 자신있으니까! <br/>동화자연마루의 무상 A/S 혜택</p>
				</v-col>				
			</v-row>
		</v-col>
	</div>
</template>

<script>
export default {
	name: 'CommunityHomeNewCareflo',
	components: {},
	data: () => ({}),
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
}
</script>

<style scoped lang="scss">
.warranty.item {
	position: relative;
	border-radius: 16px;
	background:url('../../../../../assets/img/careflo/nfc_sticker.png');
	background-size: cover;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
	overflow: hidden;
	height:200px;
	justify-content: end;
    align-items: center;
    display: flex;
	.tit {
		font-size: $font_3xl;
		font-weight: $fw_bold;
		font-family: 'GmarketSanse-Bold' !important;
		color:#fff;
	}
	.sub_txt {
		color:#fff;
		font-family: 'Pretendard-Light' !important;
	}
	.tit_wrap {
		background-color:$color_primary_3;
		padding:10px;
		text-align: right;
		display: flex;
		justify-content: end;    
		width: calc(100% - 142px);
		bottom: 9%;
    	position: absolute;
	}
}

// @media all and (max-width: 1264px) {
// 	.warranty.item {
// 		border-radius: 0 !important;
// 	}
// }

@media all and (max-width: 600px) {
	.warranty.item {		
			border-radius: 0 !important;	
			background-position: center;
	}
}

@media all and (max-width: 380px) {
	.warranty.item {
		.tit {
			font-size: $font_normal !important;
		}
	}
}
</style>
