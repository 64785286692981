<template>
	<div class="custom_input">
		<v-text-field
			v-model="model"
			outlined
			dense
			:type="type"
			:inputmode="type === 'number' ? 'numeric' : ''"
			:label="label"
			:placeholder="placeholder"
			:append-icon="check ? 'mdi-check' : ''"
			:rules="ruleCheck"
			:height="height"
			validate-on-blur
		/>
	</div>
</template>

<script>
import model from '@/mixins/model'

export default {
	name: 'Input02',
	components: {},
	mixins: [model],
	props: {
		label: {
			type: String,
			default: '',
		},
		type: {
			type: String,
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
		ruleName: {
			type: String,
			default: '',
		},
		passwordValue: {},
		height: {
			type: Number,
			default: 40,
		},
	},
	data: () => ({
		rules: {
			required: v => !!v || '최소 2자 이상을 입력해주세요.',
			tel: v => !!v?.match(/^.*(?=^.{10,11}$)(?=.*\d).*$/) || '전화번호를 정확히 입력해 주세요.',
			password: v =>
				!!v?.match(/^.*(?=^.{8,15}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&+=()]).*$/) ||
				'8자리 이상 15자리 이하 특수문자(!@#$%^&+=()) 와 숫자를 반드시 포함해서 만들어 주세요.',
			space: v => !v?.match(/\s/g) || '공백은 허용하지 않습니다.',
			email: v =>
				!!v?.match(
					/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
				) || 'e-mail형식에 맞게 작성해주세요.(ooo@ooo.ooo)',
		},
	}),
	computed: {
		ruleCheck() {
			if (this.passwordValue !== undefined)
				return [this.rules.password, this.passwordValue || '입력한 비밀번호와 동일하지 않습니다.']
			if (this.ruleName === 'password') return [this.rules.required, this.rules.space, this.rules.password]
			if (this.ruleName === 'tel') return [this.rules.required, this.rules.space, this.rules.tel]
			if (this.ruleName === 'email') return [this.rules.required, this.rules.space, this.rules.email]
			return [this.rules.required, this.rules.space]
		},
		check() {
			if (this.passwordValue !== undefined) return this.passwordValue
			if (this.ruleName) return this.rules[this.ruleName](this.model) === true
			return this.rules.required(this.model) === true
		},
	},
	watch: {
		check(val) {
			if (val === false) this.model = null
			console.log('check', val)
		},
		inputModel(val) {
			if (this.check) this.model = val
		},
	},
	created() {},
	mounted() {},
	methods: {},
}
</script>
<style scoped lang="scss">
.custom_input {
	:deep(.v-input--is-focused .v-input__slot) {
		fieldset {
			border: 1px solid $color_primary_1 !important;
		}
	}
	:deep(.v-input__slot) {
		fieldset {
			border: 1px solid $color_gray_4 !important;
		}
	}
	:deep(.v-text-field__slot input::placeholder) {
		font-size: $font_lg !important;
	}

	:deep(.mdi-check::before) {
		color: #00ce7d !important;
	}

	:deep(.v-label) {
		font-size: 14px !important;
	}
	:deep(.v-text-field) {
		input {
			line-height: 10px;
		}
	}
}
</style>
