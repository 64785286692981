<template>
	<div class="color_analysis">
		<v-row class="ma-0 mb-3 mb-md-5">
			<v-col
				class="pa-0"
				cols="6"
				align-self="center"
			>
				<div class="subject">색상 분석 결과</div>
			</v-col>

			<v-col
				class="pa-0"
				cols="6"
				align="end"
				align-self="center"
			>
				<span
					class="btn_color"
					@click="confirmReset = true"
				>
					<v-icon
						color="#262626"
						small
					>
						mdi-autorenew
					</v-icon>
					색상 변경
				</span>
			</v-col>
		</v-row>

		<v-row class="ma-0">
			<v-col
				class="pa-0"
				md="4"
				cols="12"
			>
				<div class="pa-4 mr-0 mr-md-2 left">
					<v-row class="ma-0 mb-5 head">
						<span>선택된 색상</span>
					</v-row>

					<!-- 가구 or 벽 색상 -->
					<v-row
						v-if="selected_color_text !== '가구와 벽'"
						class="ma-0 justify-center"
					>
						<v-col
							class="pa-0"
							cols="10"
							md="8"
						>
							<v-row class="ma-0 justify-space-between">
								<v-col
									class="pa-0"
									cols="2"
									align="center"
									align-self="center"
								>
									<v-img
										width="100%"
										max-width="34"
										:src="
											require(`@/assets/img/search/${items.objectColor ? 'icon_sofa2' : 'icon_wall'}.svg`)
										"
									/>
									{{ selected_color_text }}
								</v-col>
								<v-col
									class="pa-0 analysis"
									cols="9"
								>
									<div class="drop">
										<svg
											width="41"
											height="47"
											viewBox="0 0 41 47"
											fill="none"
										>
											<path
												d="M6.66016 40.9808C10.556 44.7621 15.2747 46.6528 20.8164 46.6528C26.3581 46.6528 31.0768 44.7621 34.9727 40.9808C38.8685 37.1995 40.8164 32.6313 40.8164 27.2761C40.8164 24.5781 40.2956 22.0845 39.2539 19.7953C38.2122 17.5061 36.7747 15.4621 34.9414 13.6635L20.8164 0.0507812L6.69141 13.6635C4.85807 15.4621 3.42057 17.5061 2.37891 19.7953C1.33724 22.0845 0.816406 24.5781 0.816406 27.2761C0.816406 32.6313 2.76432 37.1995 6.66016 40.9808Z"
												:fill="items.objectColor ? items.objectColor : items.wallColor"
											/>
											<path
												d="M20.8164 46.1528C15.4018 46.1528 10.8088 44.3107 7.0084 40.622C3.20806 36.9334 1.31641 32.494 1.31641 27.2761C1.31641 24.6443 1.82404 22.2219 2.83401 20.0024C3.84935 17.771 5.25034 15.7781 7.03995 14.022C7.04049 14.0214 7.04103 14.0209 7.04157 14.0204L20.8164 0.745183L34.5912 14.0204C34.5918 14.021 34.5924 14.0215 34.593 14.0221C36.3826 15.7782 37.7835 17.7711 38.7988 20.0024C39.8088 22.2219 40.3164 24.6443 40.3164 27.2761C40.3164 32.494 38.4247 36.9334 34.6244 40.622C30.824 44.3107 26.2311 46.1528 20.8164 46.1528Z"
												stroke="black"
												stroke-opacity="0.1"
											/>
										</svg>
									</div>
								</v-col>
							</v-row>
						</v-col>
					</v-row>

					<!-- 벽 색상 -->
					<!-- <v-row class="ma-0 justify-center">
						<v-col
							class="pa-0"
							cols="10"
							md="8"
						>
							<v-row
								v-if="items.wallColor"
								class="ma-0 mt-4 justify-space-between"
							>
								<v-col
									class="pa-0"
									cols="2"
									align="center"
									align-self="center"
								>
									<v-img
										width="100%"
										max-width="34"
										:src="require('@/assets/img/search/icon_wall.svg')"
									/>
									벽
								</v-col>
								<v-col
									class="pa-0 analysis"
									cols="9"
								>
									<div class="drop">
										<svg
											width="41"
											height="47"
											viewBox="0 0 41 47"
											fill="none"
										>
											<path
												d="M6.66016 40.9808C10.556 44.7621 15.2747 46.6528 20.8164 46.6528C26.3581 46.6528 31.0768 44.7621 34.9727 40.9808C38.8685 37.1995 40.8164 32.6313 40.8164 27.2761C40.8164 24.5781 40.2956 22.0845 39.2539 19.7953C38.2122 17.5061 36.7747 15.4621 34.9414 13.6635L20.8164 0.0507812L6.69141 13.6635C4.85807 15.4621 3.42057 17.5061 2.37891 19.7953C1.33724 22.0845 0.816406 24.5781 0.816406 27.2761C0.816406 32.6313 2.76432 37.1995 6.66016 40.9808Z"
												:fill="items.wallColor"
											/>
											<path
												d="M20.8164 46.1528C15.4018 46.1528 10.8088 44.3107 7.0084 40.622C3.20806 36.9334 1.31641 32.494 1.31641 27.2761C1.31641 24.6443 1.82404 22.2219 2.83401 20.0024C3.84935 17.771 5.25034 15.7781 7.03995 14.022C7.04049 14.0214 7.04103 14.0209 7.04157 14.0204L20.8164 0.745183L34.5912 14.0204C34.5918 14.021 34.5924 14.0215 34.593 14.0221C36.3826 15.7782 37.7835 17.7711 38.7988 20.0024C39.8088 22.2219 40.3164 24.6443 40.3164 27.2761C40.3164 32.494 38.4247 36.9334 34.6244 40.622C30.824 44.3107 26.2311 46.1528 20.8164 46.1528Z"
												stroke="black"
												stroke-opacity="0.1"
											/>
										</svg>
									</div>
								</v-col>
							</v-row>
						</v-col>
					</v-row> -->

					<!-- 가구 + 벽 -->
					<v-row
						v-else
						class="ma-0 justify-center"
					>
						<v-col
							class="pa-0"
							cols="10"
							md="12"
						>
							<v-row class="ma-0 justify-center">
								<!-- 가구 -->
								<v-col
									class="pa-0"
									cols="12"
									md="6"
								>
									<div class="mr-0 mr-md-1">
										<v-row class="ma-0 justify-center">
											<v-col
												class="pa-0 mr-4"
												cols="3"
												align="center"
												align-self="center"
											>
												<v-img
													width="100%"
													max-width="34"
													:src="require('@/assets/img/search/icon_sofa2.svg')"
												/>
												가구
											</v-col>
											<v-col
												class="pa-0 analysis"
												cols="8"
												md="6"
											>
												<div class="drop">
													<svg
														width="41"
														height="47"
														viewBox="0 0 41 47"
														fill="none"
													>
														<path
															d="M6.66016 40.9808C10.556 44.7621 15.2747 46.6528 20.8164 46.6528C26.3581 46.6528 31.0768 44.7621 34.9727 40.9808C38.8685 37.1995 40.8164 32.6313 40.8164 27.2761C40.8164 24.5781 40.2956 22.0845 39.2539 19.7953C38.2122 17.5061 36.7747 15.4621 34.9414 13.6635L20.8164 0.0507812L6.69141 13.6635C4.85807 15.4621 3.42057 17.5061 2.37891 19.7953C1.33724 22.0845 0.816406 24.5781 0.816406 27.2761C0.816406 32.6313 2.76432 37.1995 6.66016 40.9808Z"
															:fill="items.objectColor"
														/>
														<path
															d="M20.8164 46.1528C15.4018 46.1528 10.8088 44.3107 7.0084 40.622C3.20806 36.9334 1.31641 32.494 1.31641 27.2761C1.31641 24.6443 1.82404 22.2219 2.83401 20.0024C3.84935 17.771 5.25034 15.7781 7.03995 14.022C7.04049 14.0214 7.04103 14.0209 7.04157 14.0204L20.8164 0.745183L34.5912 14.0204C34.5918 14.021 34.5924 14.0215 34.593 14.0221C36.3826 15.7782 37.7835 17.7711 38.7988 20.0024C39.8088 22.2219 40.3164 24.6443 40.3164 27.2761C40.3164 32.494 38.4247 36.9334 34.6244 40.622C30.824 44.3107 26.2311 46.1528 20.8164 46.1528Z"
															stroke="black"
															stroke-opacity="0.1"
														/>
													</svg>
												</div>
											</v-col>
										</v-row>
									</div>
								</v-col>

								<!-- 벽 -->
								<v-col
									class="pa-0"
									cols="12"
									md="6"
								>
									<div class="ml-0 ml-md-1">
										<v-row class="ma-0 mt-4 mt-md-0 justify-center">
											<v-col
												class="pa-0 mr-4"
												cols="3"
												align="center"
												align-self="center"
											>
												<v-img
													width="100%"
													max-width="34"
													:src="require('@/assets/img/search/icon_wall.svg')"
												/>
												벽
											</v-col>
											<v-col
												class="pa-0 analysis"
												cols="8"
												md="6"
											>
												<div class="drop">
													<svg
														width="41"
														height="47"
														viewBox="0 0 41 47"
														fill="none"
													>
														<path
															d="M6.66016 40.9808C10.556 44.7621 15.2747 46.6528 20.8164 46.6528C26.3581 46.6528 31.0768 44.7621 34.9727 40.9808C38.8685 37.1995 40.8164 32.6313 40.8164 27.2761C40.8164 24.5781 40.2956 22.0845 39.2539 19.7953C38.2122 17.5061 36.7747 15.4621 34.9414 13.6635L20.8164 0.0507812L6.69141 13.6635C4.85807 15.4621 3.42057 17.5061 2.37891 19.7953C1.33724 22.0845 0.816406 24.5781 0.816406 27.2761C0.816406 32.6313 2.76432 37.1995 6.66016 40.9808Z"
															:fill="items.wallColor"
														/>
														<path
															d="M20.8164 46.1528C15.4018 46.1528 10.8088 44.3107 7.0084 40.622C3.20806 36.9334 1.31641 32.494 1.31641 27.2761C1.31641 24.6443 1.82404 22.2219 2.83401 20.0024C3.84935 17.771 5.25034 15.7781 7.03995 14.022C7.04049 14.0214 7.04103 14.0209 7.04157 14.0204L20.8164 0.745183L34.5912 14.0204C34.5918 14.021 34.5924 14.0215 34.593 14.0221C36.3826 15.7782 37.7835 17.7711 38.7988 20.0024C39.8088 22.2219 40.3164 24.6443 40.3164 27.2761C40.3164 32.494 38.4247 36.9334 34.6244 40.622C30.824 44.3107 26.2311 46.1528 20.8164 46.1528Z"
															stroke="black"
															stroke-opacity="0.1"
														/>
													</svg>
												</div>
											</v-col>
										</v-row>
									</div>
								</v-col>
							</v-row>
						</v-col>
					</v-row>

					<p class="mt-4 tac notice hidden-md-and-up">선택하신 색상과 조화롭게 어울리는 추천 제품을 확인해보세요!</p>
				</div>
			</v-col>

			<v-col
				class="pa-0 hidden-sm-and-down"
				md="8"
			>
				<div class="ml-0 ml-md-2 right">
					<div class="mb-0 mb-md-4 tac wrap">
						<div class="result_txt">
							<span class="color_circle_txt">{{ `${selected_color_text} 색상으로` }}</span>
							<span
								v-if="items.objectColor"
								class="tac mx-3 color_circle"
							>
								<span class="circle">
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
									>
										<path
											d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z"
											:fill="items.objectColor"
										/>
										<path
											d="M23.5 12C23.5 18.3513 18.3513 23.5 12 23.5C5.64873 23.5 0.5 18.3513 0.5 12C0.5 5.64873 5.64873 0.5 12 0.5C18.3513 0.5 23.5 5.64873 23.5 12Z"
											stroke="black"
											stroke-opacity="0.1"
										/>
									</svg>
								</span>
								<p>{{ items.objectColorCategory }}</p>
							</span>
							<span
								v-if="items.wallColor"
								class="tac mx-3 color_circle"
							>
								<span class="circle">
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
									>
										<path
											d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z"
											:fill="items.wallColor"
										/>
										<path
											d="M23.5 12C23.5 18.3513 18.3513 23.5 12 23.5C5.64873 23.5 0.5 18.3513 0.5 12C0.5 5.64873 5.64873 0.5 12 0.5C18.3513 0.5 23.5 5.64873 23.5 12Z"
											stroke="black"
											stroke-opacity="0.1"
										/>
									</svg>
								</span>
								<p>{{ items.wallColorCategory }}</p>
							</span>
							<span class="color_circle_txt"> 을 선택하셨군요! </span>
						</div>
						<div class="mt-5 result_txt">
							<span class="color_circle_txt">선택하신 색상과 조화롭게 어울리는 추천 제품을 확인해보세요!</span>
						</div>
					</div>
				</div>
			</v-col>
		</v-row>

		<CommonConfirm
			:dialog="confirmReset"
			title="색상 초기화"
			text="색상을 변경하시겠습니까?"
			@close="confirmReset = false"
			@submit="initColor"
		/>
	</div>
</template>

<script>
import { mapMutations } from 'vuex'
import common from '@/mixins/common'
export default {
	name: 'ColorAnalysis',
	mixins: [common],
	props: ['items'],
	data: () => ({
		confirmReset: false,
	}),
	computed: {
		selected_color_text() {
			if (this.items.objectColor && !this.items.wallColor) return '가구'
			else if (!this.items.objectColor && this.items.wallColor) return '벽'
			else return '가구와 벽'
		},
	},
	watch: {},
	mounted() {},
	methods: {
		...mapMutations(['HOME_MU_SEARCH_SELECTED_COLORS']),
		initColor() {
			this.HOME_MU_SEARCH_SELECTED_COLORS({})
		},
	},
}
</script>

<style scoped lang="scss">
.color_analysis {
	.subject {
		font-size: $font_sub_tit;
		font-weight: 500;
		letter-spacing: -2px;
		color: $color_font;
	}
	.btn_color {
		padding: 6px 24px;
		border: 1px solid $color_gray_4;
		border-radius: 4px;
		box-sizing: border-box;
		vertical-align: middle;
		font-size: $font_normal;
		cursor: pointer;
	}
	.left {
		height: 100%;
		border: 1px solid $color_gray_2;
		border-radius: 8px;
		.head {
			justify-content: center;
			span {
				display: inline-block;
				padding: 4px 16px;
				background-color: $color_gray_8;
				border-radius: 99px;
				color: $color_white;
				font-size: $font_lg;
			}
		}
		.analysis {
			position: relative;
			height: 84px;
			background-color: $color_gray_1;
			border: 1px solid $color_gray_2;
			border-radius: 8px;
			.drop {
				min-width: 190px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				svg {
					vertical-align: middle;
				}
				text-align: center;
				.color_code {
					padding: 2px 10px !important;
					background-color: $color_white;
					border-radius: 99px;
					font-size: $font_normal;
					color: $color_font;
					vertical-align: middle;
				}
			}
		}
		.notice {
			font-size: $font_normal;
			color: $color_gray_8;
			word-break: keep-all;
		}
	}

	.right {
		position: relative;
		height: 100%;
		background-color: $color_gray_1;
		border-radius: 8px;
		align-content: center;
		.wrap {
			min-width: 570px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
		.result_txt {
			.color_circle {
				display: inline-block;
				vertical-align: middle;
				.circle {
					width: 24px;
					height: 24px;
				}
			}
			.color_circle_txt {
				display: inline-block;
				font-size: $font_lg;
				color: $color_font;
				word-break: keep-all;
			}
		}
	}
}

@media all and (max-width: 959px) {
	.color_analysis {
		.subject {
			font-size: $font_lg !important;
		}
		.btn_color {
			padding: 3px 8px !important;
			font-size: $font_sm !important;
		}
	}
}
</style>
