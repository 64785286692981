<template>
	<!-- 
        class name 에 따라서 디자인이 변경 , 추후 추가되는 카드또한 이곳에 추가할 예정
        product : 제품
        housewarming : 3D집들이
        expert : 전문가집들이 
        knowhow : 제품
        maru_tv : 마루티비
     -->

	<div
		class="cardtype content"
		:class="[item.class]"
		@click="onClickCard(item)"
	>
		<div class="thumbnail">
			<v-img
				:src="item.type === 'video' ? thumbnail : item.image ? item.image : require('@/assets/img/etc/no_image.jpg')"
				:aspect-ratio="thumbnail_ratio"
				eager
			>
				<v-row class="ma-0 pa-2 pa-sm-3 pa-md-4 top_element">
					<v-col
						class="pa-0"
						align="start"
						align-self="center"
						cols="6"
					>
						<span
							v-if="item.PopItem_YN === 'Y'"
							class="flag best"
							>Best</span
						>
					</v-col>
					<v-col
						class="pa-0"
						align="end"
						align-self="center"
						cols="6"
					>
						<span
							v-if="AUTH_GET_USER_AUTH !== 'AGENCY'"
							class="bookmark"
							@click="event => onScrap(item, event)"
						>
							<v-icon
								v-if="scrapStatus(item)"
								color="#00CE7D"
								>mdi-bookmark-outline</v-icon
							>
							<v-icon
								v-else
								color="#00CE7D"
								>mdi-bookmark</v-icon
							>
						</span>
					</v-col>
				</v-row>
			</v-img>
			<div class="dim">
				<v-img
					:src="require('@/assets/img/search/icon_thum_play.svg')"
					width="53"
					height="53"
					class="icon_play"
				/>
			</div>
		</div>

		<div class="py-4 px-2 py-md-6 px-md-4 card_txt">
			<span
				v-if="!maruRecommend"
				class="d-flex flag category"
			>
				<v-img
					class="icon_marutv"
					max-width="14"
					height="100%"
					:src="require('@/assets/img/search/icon_play.svg')"
				/>
				<span>{{ item.typeKr }}</span>
			</span>
			<p class="name">
				{{ item.type === 'master' ? `${item.brand_name} ${item.goods_name} ${item.pattern_name}` : item.title }}
			</p>
			<p
				v-if="item.type === 'master'"
				class="mt-2 sub_txt"
			>
				{{ item.kind_name }}
			</p>
			<p
				v-else-if="item.type === '3dhw' || item.type === 'exphw'"
				class="mt-2 sub_txt"
			>
				{{ generateSubText }}
				<!-- {{ item.tags.map(tag => tag.tag_value).join(' | ') }} -->
			</p>
			<p
				v-else-if="item.type === 'knowhow'"
				class="mt-2 sub_txt"
			>
				{{ item.code_name }}
			</p>
			<p
				v-if="item.business_name"
				class="mt-1 store_name"
			>
				{{ item.business_name }}
			</p>
		</div>
		<HomeCommunityItemsVideoPlayer
			v-if="videoDialog"
			v-model="videoDialog"
			:url="videoUrl"
			@close="videoDialog = false"
		/>
	</div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import common from '@/mixins/common'
import scrap from '@/mixins/scrap'

export default {
	name: 'CardMenuCardDefault',
	mixins: [common, scrap],
	props: {
		item: {
			type: Object,
			default: () => {},
		},
		maruRecommend: {
			// 마루추천 화면에선 제품 flag 숨김
			type: Boolean,
			default: false,
		},
		newTabLink: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({
		videoUrl: '',
		videoDialog: false,
	}),
	computed: {
		...mapGetters(['AUTH_GET_USER_AUTH']),
		// homePath() {
		// 	return this.common_recentlyRouterPath === '/home'
		// },
		// productSearchPath() {
		// 	return this.common_recentlyRouterPath === '/search/result'
		// },
		thumbnail() {
			const videoId = this.item.video_url?.split('/').pop().split('=').pop()
			return `https://i3.ytimg.com/vi/${videoId}/hqdefault.jpg`
		},
		thumbnail_ratio() {
			let ratio = 1 / 1
			if (this.item.type === 'master') ratio = 1 / 1
			if (this.item.type === '3dhw') ratio = 16 / 10
			if (this.item.type === 'exphw') ratio = 1 / 1
			if (this.item.type === 'knowhow') ratio = 1 / 1
			if (this.item.type === 'video') ratio = 16 / 8

			return ratio
		},
		generateSubText() {
			let text = ''
			if (this.item.familytype_name) {
				text += `${this.item.familytype_name} | `
			}
			if (this.item.spacious_name) {
				text += `${this.item.spacious_name} | `
			}
			if (this.item.style_name) {
				text += this.item.style_name
			}
			return text
		},
	},
	created() {},
	mounted() {},
	methods: {
		...mapMutations(['APP_MU_RECENT_PRODUCT', 'APP_MU_SNACKBAR']),
		scrapStatus(item) {
			// let scrapCheck
			// if (this.homePath) scrapCheck = this.scrap_check_list(item.id, item.scrap_code)
			// else if (this.productSearchPath) scrapCheck = this.scrap_check_list(item.id, item.con_gubun)
			// else scrapCheck = this.scrap_check_list(item.id)
			let scrapCheck = this.scrap_check_list(item.id, item.scrap_code)
			if (item.id == 17) console.log('scrapCheck', scrapCheck === 'post')
			return scrapCheck === 'post'
		},
		onClickCard(val) {
			console.log('onClickCard', val)
			let link = ''
			switch (val.type) {
				case 'master':
					link = `/product/detail/${val.id}`
					break
				case '3dhw':
					link = `/housewarming/3d/detail/${val.id}`
					break
				case 'exphw':
					link = `/housewarming/specialist/detail/${val.id}`
					break
				case 'knowhow':
					link = `/community/knowHow/detail/${val.id}`
					break
				case 'video':
					this.videoUrl = val.video_url
					this.videoDialog = true
					break
			}
			if (val.type !== 'video') {
				if (this.newTabLink) window.open(link, '_blank')
				else this.$router.push(link)
			}
		},
		onScrap(item, e) {
			e.stopPropagation()
			// if (this.homePath) this.scrap_call(item.id, 'list', item.scrap_code)
			// else if (this.productSearchPath) this.scrap_call(item.id, 'list', item.con_gubun)
			// else this.scrap_call(item.id, 'list')
			this.scrap_call(item.id, 'list', item.scrap_code)
		},
	},
}
</script>

<style scoped lang="scss">
// 한줄이상 (...)처리
@mixin ellipsis_1 {
	display: -webkit-box !important;
	word-wrap: break-word !important;
	-webkit-line-clamp: 1 !important;
	-webkit-box-orient: vertical !important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
}

// 두줄이상 (...)처리
@mixin ellipsis_2 {
	display: -webkit-box !important;
	word-wrap: break-word !important;
	-webkit-line-clamp: 2 !important;
	-webkit-box-orient: vertical !important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
}

.cardtype {
	width: 100%;
	height: 100%;
	cursor: pointer;
	.thumbnail {
		position: relative !important;
		border-top-left-radius: 16px;
		border-top-right-radius: 16px;
		overflow: hidden;
		:deep(.v-image) {
			border-top-left-radius: 16px;
			border-top-right-radius: 16px;
		}
		.dim {
			display: none;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: rgba(0, 0, 0, 0.3);
			.icon_play {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%) !important;
			}
		}
	}
	.top_element {
		.bookmark {
			display: inline-block;
			width: 30px !important;
			height: 30px !important;
			position: relative;
			border-radius: 50%;
			background-color: $color_white;
			opacity: 0.8;
			box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
			text-align: center;
			cursor: pointer;
			:deep(.v-icon) {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				font-size: 22px;
			}
		}
	}
	.card_txt {
		position: relative;
		background-color: $color_white;
		border-bottom-left-radius: 16px;
		border-bottom-right-radius: 16px;
		box-shadow: (0px 2px 8px rgba(0, 0, 0, 0.15));
		.name {
			@include ellipsis_2;
			font-size: $font_lg !important;
			font-weight: $fw_regular !important;
			color: $color_font;
			line-height: 1.4;
			height: 42px;
		}
		.sub_txt {
			@include ellipsis_1;
			font-size: $font_sm;
			color: #9e9e9e;
		}
		.store_name {
			@include ellipsis_1;
			font-size: $font_sm;
			color: $color_primary_2;
		}
	}
	.flag {
		padding: 6px 10px;
		border-radius: 99px;
		color: $color_white;
		line-height: 1 !important;
		&.best {
			padding: 2px 10px;
			font-size: $font_normal;
			background-color: $color_acc_1;
		}
		&.category {
			position: absolute;
			top: -12px;
			background-color: $color_font;
			font-size: $font_sm;
			align-items: center;
			.v-image {
				display: none;
				aspect-ratio: auto !important;
				:deep(.v-image__image) {
					max-width: 14px;
					height: 10px;
				}
				margin-right: 4px;
				border-radius: 0;
			}
		}
	}
	// 카드(제품)

	&.maru_tv {
		.top_element {
			display: none;
		}
		.thumbnail {
			.dim {
				display: block !important;
			}
		}
		.flag {
			&.category {
				.v-image {
					display: block !important;
				}
			}
		}
	}
}

@media all and (max-width: 600px) {
	.cardtype {
		.thumbnail {
			.dim {
				.icon_play {
					width: 26px !important;
					height: 26px !important;
				}
			}
		}
		.top_element {
			.bookmark {
				width: 20px !important;
				height: 20px !important;
				:deep(.v-icon) {
					font-size: 18px !important;
				}
			}
		}
		.card_txt {
			.name {
				font-size: $font_normal !important;
				height: 36px !important;
			}
		}
		.flag {
			font-size: $font_sm !important;
			padding: 4px 10px !important;
			&.best {
				padding: 2px 10px;
			}
		}
	}
}
</style>
