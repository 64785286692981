import { render, staticRenderFns } from "./inputsnew.vue?vue&type=template&id=8b1ed8cc&scoped=true&"
import script from "./inputsnew.vue?vue&type=script&lang=js&"
export * from "./inputsnew.vue?vue&type=script&lang=js&"
import style0 from "./inputsnew.vue?vue&type=style&index=0&id=8b1ed8cc&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8b1ed8cc",
  null
  
)

export default component.exports