<template>
	<v-dialog
		v-model="dialog"
		persistent
		width="auto"
		:class="className"
	>
		<v-color-picker
			v-model="color"
			width="240"
			:swatches="swatches"
			show-swatches
		>
		</v-color-picker>
		<v-row>
			<v-col col="6">
				<v-btn
					width="100%"
					text
					color="primary"
					@click="cancel"
				>
					취소
				</v-btn>
			</v-col>
			<v-col col="6">
				<v-btn
					width="100%"
					text
					color="primary"
					@click="confirm"
				>
					확인
				</v-btn>
			</v-col>
		</v-row>
	</v-dialog>
</template>

<script>
export default {
	name: 'InputColor01',
	components: {},
	props: {
		className: {
			type: String,
			default: '',
		},
		dialog: {
			type: Boolean,
			default: false,
		},
		swatches: {
			type: Array,
			default: () => [],
		},
	},
	data: () => ({
		type: 'hex',
		hex: null,
	}),
	computed: {
		color: {
			get() {
				return this[this.type]
			},
			set(val) {
				this[this.type] = val
			},
		},
	},
	watch: {},
	created() {},
	mounted() {
		this.color = '#ffffff'
	},
	methods: {
		confirm() {
			this.$emit('update:color', this.color)
			this.$emit('close')
		},
		cancel() {
			this.$emit('update:color', null)
			this.$emit('close')
		},
	},
}
</script>
<style scoped lang="scss">
:deep(.v-color-picker) {
	border-bottom-right-radius: 0px;
	border-bottom-left-radius: 0px;
	.v-color-picker__swatch {
		.v-color-picker__color {
			border-radius: 2px;
		}
		&:first-child .v-color-picker__color:first-child {
			border: 1px solid #ececec;
		}
	}
}
.row {
	margin: 0px;
	background-color: $color_white;
	border-top: 1px solid $color_gray_2;
	.col {
		padding: 0px;
		.v-btn {
			padding: 24px;
			border-radius: 0px;
			&:first-child {
				border-right: 1px solid $color_gray_2;
			}
		}
	}
}
</style>
