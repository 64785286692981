<template>
	<div class="px-2 px-sm-0 mb-7 show_card_default">
		<p class="tit">이런 제품은 어떠세요?</p>
		<v-row class="ma-0 wrap">
			<v-col
				v-for="(item, i) in items"
				:key="i"
				class="pa-0 px-1 px-sm-2 pb-4 pb-md-10 item"
			>
				<CommonCardMenuCardDefault
					:item="item"
					new-tab-link
				/>
			</v-col>
		</v-row>
	</div>
</template>

<script>
export default {
	name: 'CommonShowCardDefault',
	props: ['items'],
	data: () => ({}),
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	destroyed() {},
	methods: {},
}
</script>

<style scoped lang="scss">
.show_card_default {
	.tit {
		margin-bottom: 30px !important;
		font-size: 2rem;
		font-weight: $fw_regular;
		color: $color_font;
		text-align: center;
	}
	.wrap {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(25%, 25%));
		grid-template-rows: repeat(auo-fit, minmax(400px, 4fr));
		// gap: 20px;
	}
}
@media all and (min-width: 600px) and (max-width: 1264px) {
	.show_card_default {
		.wrap {
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(33.3%, 33.3%)) !important;
			// gap: 16px !important;
		}
	}
}
@media all and (max-width: 600px) {
	.show_card_default {
		.wrap {
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(50%, 50%)) !important;
			// gap: 12px !important;
		}
	}
}
</style>
