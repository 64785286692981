<template>
	<div
		class="d-flex pa-5 store_list"
		@click="goToStore(item.corporate_no)"
	>
		<div class="d-flex align-center storeListWrap">
			<!-- <span v-if="item.company_picurl !== '' && item.company_picurl !== null">
				<v-img
					:src="item.company_picurl"
					class="store_view mr-3"
					eager
					contain
				>
					<v-row
						v-if="!item.is_img"
						class="ma-0 pl-3 pl-md-4 fill-height"
						align="start"
						justify="center"
					>
						<p class="store_title">
							{{ item.business_name }}
						</p>
					</v-row>
				</v-img>
			</span>
			<span v-if="item.company_picurl === '' || item.company_picurl === null">
				<v-img
					:src="require('@/assets/img/home/interior/img_store_empty1.png')"
					class="store_view empty_storeView mr-3"
					eager
					contain
				>
				</v-img>
			</span> -->
			<div>
				<div class="">
					<!--                <span v-if="item.company_logourl">
                    <v-img
                        :src="item.company_logourl"
                        class="store_logo"
                        eager
						contain
                    />
                </span>-->
					<p
						v-if="item.business_name"
						class="store_name"
					>
						{{ item.business_name }}
					</p>
				</div>
				<div
					v-if="item.company_addr1"
					class="address"
				>
					{{ item.company_addr1 }} {{ item.company_addr2 }}
				</div>
			</div>
		</div>
		<div class="ml-2 ml-md-5 d-flex align-center personalInfo">
			<div class="explain">
				<!-- <table v-if="!housewarmingCheck">
					<tr v-if="item.boss_name">
						<th>대표자</th>
						<td>{{ item.boss_name }}</td>
					</tr>
					<tr v-if="item.company_tel">
						<th>연락처</th>
						<td>{{ common_number_filter(item.company_tel) }}</td>
					</tr>
				</table>
				<table v-else>
					<tr v-if="item.cnt_construct">
						<th>시공사례</th>
						<td>{{ item.cnt_construct }}건</td>
					</tr>
					<tr v-if="item.buildItems">
						<th>시공범위</th>
						<td>
							<span
								v-for="(build, i) in item.buildItems"
								:key="i"
								class="text_array"
							>
								{{ build }}</span
							>
						</td>
					</tr>
				</table> -->
				<div
					v-if="!housewarmingCheck"
					class="table"
				>
					<span
						v-if="item.boss_name"
						class="tr"
					>
						<span class="th">대표자</span>
						<span class="td">{{ item.boss_name }}</span>
					</span>
					<span
						v-if="item.company_tel"
						class="tr"
					>
						<span class="th">연락처</span>
						<span class="td">{{ common_number_filter(item.company_tel) }}</span>
					</span>
				</div>
				<div v-else>
					<span
						v-if="item.cnt_construct"
						class="tr"
					>
						<span class="th">시공사례</span>
						<span class="td">{{ item.cnt_construct }}건</span>
					</span>
					<span
						v-if="item.buildItems"
						class="tr"
					>
						<span class="th">시공범위</span>
						<span class="td">
							<span
								v-for="(build, i) in item.buildItems"
								:key="i"
								class="text_array"
							>
								{{ build }}</span
							>
						</span>
					</span>
				</div>
				<div
					v-if="housewarmingCheck"
					class="d-flex mt-3"
					align="center"
				>
					<CommonButtonsButton02
						name="상세보기"
						outlined
						class-name="btn_small"
						color="#262626"
						class="mr-1"
						:to="`/interior/store/detail/${item.corporate_no}`"
					/>
					<CommonButtonsButton02
						v-if="item.corporate_no !== '1378102333'"
						name="견적상담 의뢰"
						class-name="btn_small"
						color="#42883d"
						class="mr-1"
						@click="goToInquire"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapMutations } from 'vuex'
import common from '@/mixins/common'

export default {
	name: 'CommonCardMenuStore',
	mixins: [common],
	props: ['item'],
	data: () => ({}),
	computed: {
		housewarmingCheck() {
			let check = true
			if (this.common_recentlyRouterPath.includes('housewarming')) {
				check = false
			}
			return check
		},
	},
	watch: {},
	mounted() {
		console.log('check', this.item)
	},
	destroyed() {},
	methods: {
		...mapMutations(['HOME_MU_INTERIOR_SELECT_INTERIOR']),
		goToInquire() {
			this.HOME_MU_INTERIOR_SELECT_INTERIOR(this.item)
			this.$router.push('/interior/price/inquire').catch(() => {})
		},
		goToStore(company) {
			//this.$router.push(`/interior/store/detail/${company}`)
			window.open(this.$router.resolve(`/interior/store/detail/${company}`).href, '_blank')
		},
	},
}
</script>

<style scoped lang="scss">
.list_board {
	border: 1px solid $color_gray_4;
	height: 642px;
	overflow-y: scroll;
}

.store_title {
	font-family: 'NotoSansKR-bold';
	font-size: 1rem;
	color: white;
	width: 80%;

	// ...처리
	overflow: hidden;
	text-overflow: ellipsis;

	/* 여러 줄 자르기 추가 스타일 */
	white-space: normal;
	line-height: 1.2;
	height: 2.3em;
	word-break: keep-all;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
.store_list {
	border-top: 1px solid $color_gray_4;
	justify-content: space-between;
	.v-btn {
		width: 90px !important;
	}

	.store_view {
		width: 64px !important;
		height: 64px !important;
		border: 1px solid #e6e6e6;
		border-radius: 4px;
		:deep(.v-image__image) {
			background-size: cover;
		}
	}
	.empty_storeView {
		:deep(.v-image__image) {
			background-size: cover;
		}
	}
	.store_logo {
		width: 60px !important;
		height: 24px !important;
	}
	.store_name {
		font-family: 'NotoSansKR-Medium';
		font-size: 16px;
		text-align: left;
		font-weight: 500;
		color: #262626;
		letter-spacing: -0.7px;
	}
	.address {
		text-align: left !important;
		font-family: 'NotoSansKR-Regular';
		font-size: 13px;
		color: #5f6062;
	}

	&:hover {
		cursor: pointer;
	}
	.explain {
		.table {
			width: 100% !important;
			.tr {
				.th {
					width: 50px;
					text-align: left;
					position: relative;
					padding-right: 5px;
					font-family: 'NotoSansKR-Regular';
					font-size: 12px;
					font-weight: 600;
					color: #777777;
					// &:after {
					// 	display: block;
					// 	position: absolute;
					// 	top: 5px;
					// 	right: 0px;
					// 	width: 1px;
					// 	height: 12px;
					// 	background-color: $color_gray_4;
					// 	content: '';
					// }
					// &:nth-of-type(2) {
					// 	margin-left: ;
					// }
				}
				.td {
					font-family: 'NotoSansKR-Regular';
					font-size: 12px;
					color: #777777;
					word-break: keep-all;
				}
			}
			.tr:nth-of-type(1) {
				.td {
					&:after {
						display: inline-block;
						position: relative;
						top: 5px;
						right: 0px;
						width: 1px;
						height: 12px;
						background-color: $color_gray_4;
						content: '';
						margin: 0 9px;
						vertical-align: super;
					}
				}
			}
		}
	}
}

@media all and (min-width: 960px) and (max-width: 1263px) {
}
@media all and (min-width: 600px) and (max-width: 959px) {
}
@media all and (max-width: 600px) {
	.personalInfo {
		display: none !important;
	}
	.store_list {
		.store_view {
			width: 80px !important;
			height: 80px !important;
		}
		.store_name {
			font-family: 'NotoSansKR-Regular';
			font-size: 14px;
		}
	}
	.store_title {
		font-size: 0.8rem;
	}
}
</style>
