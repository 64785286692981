<template>
	<div class="mb-10 mb-md-12 py-8 px-4 pa-sm-10 search_confirm">
		<v-col
			class="pa-0 confirm"
			align="center"
		>
			<v-img
				class="mb-3 mb-sm-5"
				width="100%"
				max-width="46"
				max-height="46"
				:src="require('@/assets/img/icon/icon_error_circle.svg')"
				eager
			/>
			<p class="txt_1 mb-3">검색결과가 없습니다.</p>
			<p class="txt_2">
				다른 검색어로 다시 검색해 보세요.<br />
				한글을 영어로, 혹은 영어를 한글로 입력했는지 확인해 보세요.
			</p>
		</v-col>

		<p class="txt_3 mb-6 mb-sm-12 tac">이런 검색어는 어떠세요?</p>
		<v-row
			class="ma-0"
			justify="center"
		>
			<v-col
				class="pa-0 mb-8 mb-md-0"
				cols="12"
				md="5"
			>
				<v-row class="ma-0">
					<v-col
						class="pa-0"
						sm="3"
						cols="12"
						align="start"
					>
						<p class="mb-2 tit">추천 검색</p>
					</v-col>
					<v-col
						class="pa-0"
						sm="9"
						cols="12"
					>
						<ul class="pa-0">
							<li
								v-for="(item, i) in APPBAR_GET_SEARCH_KEYWORDS"
								:key="i"
								@click="onLink(`/search/result?keyword=${item.search_name.trim()}`)"
							>
								# {{ item.search_name }}
							</li>
						</ul>
					</v-col>
				</v-row>
			</v-col>
			<v-col
				class="pa-0"
				cols="12"
				md="5"
			>
				<v-row class="ma-0">
					<v-col
						class="pa-0"
						sm="3"
						cols="12"
						align="start"
					>
						<p class="mb-2 tit">추천 태그</p>
					</v-col>
					<v-col
						class="pa-0"
						sm="9"
						cols="12"
					>
						<v-row class="ma-0 tag_wrap">
							<template v-for="(item, i) in HOME_GET_MAIN_SEARCH_TAGS">
								<v-col
									v-if="i < 7"
									:key="item.tag_id"
									class="py-1 px-4 tag"
									cols="auto"
									@click="onLink(`/search/result?keyword=${item.tag_value.trim()}`)"
								>
									{{ item.tag_value }}
								</v-col>
							</template>
						</v-row>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import common from '@/mixins/common'

export default {
	name: 'SearchConfirm',
	mixins: [common],
	data: () => ({}),
	computed: {
		...mapGetters(['APPBAR_GET_SEARCH_KEYWORDS', 'HOME_GET_MAIN_SEARCH_TAGS', 'HOME_GET_MAIN_BEST_PRODUCT']),
	},
	watch: {},
	async created() {
		if (this.APPBAR_GET_SEARCH_KEYWORDS.length === 0) await this.getSearchList()
		if (this.HOME_GET_MAIN_SEARCH_TAGS?.length === 0) await this.getSearchProductList()
		if (this.HOME_GET_MAIN_BEST_PRODUCT.length === 0) await this.getBestProductList()
	},
	mounted() {},
	destroyed() {},
	methods: {
		...mapActions(['APPBAR_ACT_SEARCH_KEYWORDS', 'HOME_ACT_MAIN_SEARCH_TAGS', 'HOME_ACT_MAIN_BEST_PRODUCT']),
		async getSearchList() {
			// 추천검색어
			await this.APPBAR_ACT_SEARCH_KEYWORDS().then(res => {
				console.log('APPBAR_GET_SEARCH_KEYWORDS', res)
			})
		},
		async getSearchProductList() {
			// 추천태그
			await this.HOME_ACT_MAIN_SEARCH_TAGS().then(res => {
				console.log('HOME_ACT_MAIN_SEARCH_TAGS', res)
			})
		},
		async getBestProductList() {
			// 베스트 제품
			await this.HOME_ACT_MAIN_BEST_PRODUCT().then(res => {
				console.log('HOME_ACT_MAIN_BEST_PRODUCT', res)
			})
		},
		onLink(link) {
			console.log(link)
			this.common_onLink(link)
		},
	},
}
</script>

<style scoped lang="scss">
@mixin ellipsis_1 {
	display: -webkit-box !important;
	word-wrap: break-word !important;
	-webkit-line-clamp: 1 !important;
	-webkit-box-orient: vertical !important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
}

.search_confirm {
	background: #fff;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
	border-radius: 16px;
	.confirm {
		margin-bottom: 50px;
		.txt_1 {
			color: $color_gray_7;
			font-size: $font_xl;
		}
		.txt_2 {
			color: $color_gray_11;
			font-size: $font_normal;
		}
	}
	.txt_3 {
		color: $color_black;
		font-size: $font_tit;
		font-weight: $fw_regular;
	}
	.tit {
		font-weight: $fw_bold;
	}
	ul {
		li {
			@include ellipsis_1;
			margin-bottom: 8px;
			color: $color_gray_7;
			font-weight: $fw_normal;
			text-align: left;
			list-style: none;
			cursor: pointer;
		}
	}
	.tag_wrap {
		.tag {
			color: $color_primary_1;
			background-color: $color_white;
			border-radius: 20px;
			margin: 4px 8px 4px 0;
			outline: 1px solid $color_primary_1;
			cursor: pointer;
		}
	}
}

@media all and (max-width: 600px) {
	.search_confirm {
		border-radius: 0 !important;
		.confirm {
			margin-bottom: 40px !important;
			.v-image {
				max-width: 33px !important;
				max-height: 33px !important;
			}
			.txt_1 {
				font-size: $font_lg !important;
			}
			.txt_2 {
				font-size: $font_sm !important;
			}
		}
		.txt_3 {
			font-size: $font_xl !important;
		}
		ul {
			li {
				font-weight: $fw_normal;
			}
		}
		.tag_wrap {
			.tag {
				margin: 4px 8px 4px 0;
				outline: 1px solid $color_primary_1;
			}
		}
	}
}
</style>
