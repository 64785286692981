<template>
	<v-dialog
		v-model="internalDialog"
		width="700"
	>
		<v-card class="pa-7 recoguideWrap">
			<div class="photo_tip">
                <div class="inner">                    
                    <v-row class="ma-0 tipWrap pt-4 pb-7 pl-7 pr-7">
                        <v-col class="mb-4 tac" cols="12" >
                            <span class="tit">사진 촬영 Tip</span>
                        </v-col>

                        <v-col
                            class="pa-0 mb-5 mb-md-0 align-center align-md-start"
                            cols="12"
                            md="7"
                            align="start"
                            align-md="start"
                        >
                            <v-img
                                width="100%"
                                max-width="320"
                                alt="마루추천 서비스 사진 촬영 팁"
                                :src="require('@/assets/img/search/img_search_guide.png')"
                            />
                        </v-col>

                        <v-col
                            class="pa-0 tal right"
                            cols="12"
                            md="5"
                        >
                            <div class="mb-5 item">
                                <p class="mb-1 tip">Tip 1</p>
                                <p class="txt">
                                    <strong>[바닥, 가구, 벽]</strong>
                                    세개의 피사체가 <br class="hidden-md-and-down" />
                                    모두 보이도록 촬영해주세요!
                                </p>
                            </div>
                            <div class="mb-5 item">
                                <p class="mb-1 tip">Tip 2</p>
                                <p class="txt">
                                    적당한 거리를 유지하여<br class="hidden-md-and-down" />
                                    <strong>전체적인 인테리어</strong>가 보이도록 촬영해주세요!
                                </p>
                            </div>
                            <div class="item">
                                <p class="mb-1 tip">Tip 3</p>
                                <p class="txt">
                                    사진 권장 비율은 <strong>4:3 가로형</strong>입니다.<br class="hidden-md-and-down" />
                                    사진을 촬영할 때 가로로 촬영해주세요!
                                </p>
                            </div>
                        </v-col>
                    </v-row>
                    <v-col
                        cols="12"
                        class="pa-0 mt-4 closebt"
                        @click="close"
                    >
                        <v-btn
                            block
                            color="#00592D"                  
                        >
                            확인
                        </v-btn>
                    </v-col>
                </div>
            </div>			
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: 'CommonDialogRecoguideDialog',
	props: ['dialog', 'items'],
	data: () => ({
		internalDialog: false,
		current: 0,
	}),
	computed: {},
	watch: {
		dialog(newValue) {
			this.internalDialog = newValue
		},
		internalDialog(newValue) {
			this.$emit('update:dialog', newValue)
		},
		current(cur) {
			if (this.player) this.stop()
			if (this.items[cur].popup_kind === 'VIDEO') {
				this.player = document.getElementById(`youtube_player${cur}`)
			}
		},
	},
	created() {
		this.internalDialog = this.dialog
	},
	async mounted() {},
	destroyed() {},
	methods: {
		noPopupOneDay() {
			if (this.items[0].popuplink_url === 'popup') this.$cookies.set('careflo_event_popup', 'false', '1d')
			else this.$cookies.set(this.items[0].location, 'false', '1d')
			this.close()
		},
		onClickPushLink(link) {
			if (link === 'popup') this.$emit('openPopup')
			else if (link.length) location.href = link
		},
		close() {
			if (this.player) this.stop()
			this.$emit('close')
		},
		play() {
			this.isPlay = true
			this.player.contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*')
		},
		stop() {
			this.player.contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*')
			this.player = null
		},
	},
}
</script>

<style scoped lang="scss">
// 팝업리셋
:deep(.v-dialog) {
	border-radius: 0 !important;
}
.v-sheet.v-card {
	border-radius: 16px !important;
}

// 컨텐츠 공통
.popup_item {
	display: flex;
	align-items: center;
	width: 100%;
	max-height: 600px;
	aspect-ratio: 1/1;
	overflow-y: auto;
	overflow-x: hidden;
	word-break: keep-all;
	border-radius: 0 !important;
	// &::-webkit-scrollbar {
	// 	width: 5px;
	// }
	// &::-webkit-scrollbar-thumb {
	// 	background-color: $color_gray_6;
	// 	border-radius: 25px;
	// }
	.v-image {
		width: 100%;
		height: auto;
	}
}

// 슬라이드
:deep(.v-carousel__controls) {
	height: 30px !important;
	.v-item-group {
		.v-btn {
			.v-btn__content {
				i {
					width: 10px;
					height: 10px;
					border-radius: 50%;
					background-color: #d9d9d9;
					opacity: 1;
					&::before {
						display: none;
					}
					&::after {
						display: none;
					}
				}
			}
			&.v-btn--active {
				.v-btn__content {
					i {
						background-color: $color_green_5;
					}
				}
			}
		}
		.v-btn--icon {
			width: 5px;
			height: 0;
		}
	}
}

// 컨텐츠 : 에디터
:deep(.popup_item_editor) {
	width: 100%;
	height: 100%;
	background-color: $color_gray_1 !important;
	.inner {
		height: 100%;
		max-height: 540px;
		overflow-y: auto;
		background-color: $color_white !important;
		border-radius: 16px;
	}
	.ql-align-left {
		text-align: left;
	}
	.ql-align-right {
		text-align: right;
	}
	.ql-align-center {
		text-align: center;
	}
	.ql-align-justify {
		text-align: justify;
	}
}

// 컨텐츠 : 유튜브
:deep(.popup_item_youtube) {
	position: relative;
	width: 100%;
	height: 100%;
	background-color: #333 !important;
	.youtube_container {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		aspect-ratio: 16/9;
		&::before {
			display: block;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.3);
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
		&.play {
			&::before {
				display: none;
			}
		}
	}
}

.recoguideWrap {
    .tipWrap {
        border-radius: 16px;
        background-color:#F8F8F8;
        .tac {
            .tit {
                background-color:#FFFFFF;
                padding:8px 20px;
                border-radius:16px;
            }
        }
    }
    .closebt {
        max-width:288px;
        margin: 0 auto;
        button {
            color:#fff;
            border-radius: 4px;
			box-shadow: none;
            span {
                padding:10px;
                font-size:$font_lg;
            }
        }
    }
}

// 팝업하단버튼
.bottom_btn {
	.v-btn {
		height: 50px;
		box-shadow: none !important;
		:deep(.v-btn__content) {
			font-size: $font_normal;
			font-weight: $fw_bold;
			color: $color_white;
		}
		&.one_day {
			:deep(.v-btn__content) {
				display: inline !important;
				text-align: left !important;
				font-weight: $fw_normal !important;
				color: $color_gray_5 !important;
			}
		}
	}
}

@media all and (max-width: 600px) {
	:deep(.popup_item_editor) {
		.inner {
			.v-image {
				width: 47px !important;
			}
		}
	}
	:deep(.v-carousel__controls) {
		height: 22px !important;
	}
}
</style>
