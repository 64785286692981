<template>
	<v-row class="ma-0 full benefit_wrap">
		<v-col
			class="pa-0 mb-5"
			cols="12"
			md="6"
		>
			<div class="pa-4 pa-md-7 mr-0 mr-md-3 benefit">
				<v-col
					class="pa-0 mb-4 mb-md-8 d-flex justify-center align-center"
					align="center"
					cols="auto"
				>
					<v-img
						class="mr-2 hidden-md-and-down"
						max-width="32"
						height="32"
						eager
						:src="require('@/assets/img/careflo/icon_care_flo.svg')"
					/>
					<strong class="tit">'풀케어' 서비스 별도 구매 고객에게 제공</strong>
				</v-col>
				<p class="desc tac">
					보다 확실한 케어서비스를 원하신다면,
					<br class="hidden-sm-and-down" />
					풀케어 서비스를 구매할 수 있습니다.
					<span class="ml-2 flag_pay">유료</span>
					<!-- <span class="mt-4 notice">
						<span class="txt">나투스진/나투스듀오 제품 시공 고객에 한하여 구매할 수 있습니다.</span>
					</span> -->
				</p>
			</div>
		</v-col>
		<v-col
			class="pa-0 mb-5"
			cols="12"
			md="6"
		>
			<div class="pa-4 pa-md-7 ml-0 ml-md-3 benefit">
				<v-col
					class="pa-0 mb-4 mb-md-8 d-flex justify-center align-center"
					align="center"
					cols="auto"
				>
					<v-img
						class="mr-2 hidden-md-and-down"
						max-width="32"
						height="32"
						eager
						:src="require('@/assets/img/careflo/icon_count3.svg')"
					/>
					<strong class="tit">3회 무상 A/S 제공</strong>
				</v-col>
				<p class="desc tac">
					시공 후 3년 이내, 연 1회씩 총 3회 무상 A/S를 제공합니다.
					<span class="mt-4 notice">
						<span class="txt">무상 A/S기준 참고 (원케어 서비스 무상 A/S 기준과 동일)</span>
					</span>
				</p>
			</div>
		</v-col>
		<v-col
			class="pa-0 mb-5"
			cols="12"
			md="6"
		>
			<div class="pa-4 pa-md-7 mr-0 mr-md-3 benefit">
				<v-col
					class="pa-0 mb-4 mb-md-8 d-flex justify-center align-center"
					align="center"
					cols="auto"
				>
					<v-img
						class="mr-2 hidden-md-and-down"
						max-width="32"
						height="32"
						eager
						:src="require('@/assets/img/careflo/icon_home.svg')"
					/>
					<strong class="tit">현장점검</strong>
				</v-col>
				<p class="desc tac">
					마루 시공품질과 시공 후 고객 만족도를 높이기 위해,
					<br class="hidden-sm-and-down" />
					현장을 방문하여 바닥 상태를 점검합니다.
					<span class="mt-4 notice">
						<span class="txt">본사 직시공에 한정적으로 적용되며, 추후 확대 예정입니다.</span>
					</span>
				</p>
			</div>
		</v-col>
		<v-col
			class="pa-0 mb-5"
			cols="12"
			md="6"
		>
			<div class="pa-4 pa-md-7 ml-0 ml-md-3 benefit">
				<v-col
					class="pa-0 mb-4 mb-md-8 d-flex justify-center align-center"
					align="center"
					cols="auto"
				>
					<v-img
						class="mr-2 hidden-md-and-down"
						max-width="32"
						height="32"
						eager
						:src="require('@/assets/img/careflo/icon_pretreatment.svg')"
					/>
					<strong class="tit">바닥 전처리 (Pretreatment)</strong>
				</v-col>
				<p class="desc tac">
					시공 전 바닥 상태를 최상으로 전처리하여 청결한 현장에서
					<br class="hidden-sm-and-down" />
					시공될 수 있게 작업합니다.
				</p>
			</div>
		</v-col>
		<v-col
			class="pa-0 mb-5 mb-md-0"
			cols="12"
			md="6"
		>
			<div class="pa-4 pa-md-7 mr-0 mr-md-3 benefit">
				<v-col
					class="pa-0 mb-4 mb-md-8 d-flex justify-center align-center"
					align="center"
					cols="auto"
				>
					<v-img
						class="mr-2 hidden-md-and-down"
						max-width="32"
						height="32"
						eager
						:src="require('@/assets/img/careflo/icon_build.svg')"
					/>
					<strong class="tit">본사 책임 시공</strong>
				</v-col>
				<p class="desc tac">
					본사가 직접 관리하고 교육하는 숙련된 시공자를 보내드립니다.
					<br class="hidden-sm-and-down" />
					집진설비 설치, 동화 정품 본드 사용, 깔끔한 마감, 폐기물 수거 등
					<br class="hidden-sm-and-down" />
					높은 품질의 시공 서비스를 제공해 드립니다.
				</p>
			</div>
		</v-col>
		<v-col
			class="pa-0"
			cols="12"
			md="6"
		>
			<div class="pa-4 pa-md-7 ml-0 ml-md-3 benefit">
				<v-col
					class="pa-0 mb-4 mb-md-8 d-flex justify-center align-center"
					align="center"
					cols="auto"
				>
					<v-img
						class="mr-2 hidden-md-and-down"
						max-width="32"
						height="32"
						eager
						:src="require('@/assets/img/careflo/icon_inclusive.svg')"
					/>
					<strong class="tit">평생 품질보증</strong>
				</v-col>
				<p class="desc tac">제품 품질을 평생동안 보증합니다.</p>
			</div>
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: 'HomeCustomCareFloFullBenefit',
	components: {},
	props: [],
	data: () => ({}),
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
}
</script>

<style scoped lang="scss">
.benefit_wrap {
	.benefit {
		height: 100%;
		border-radius: 16px;
		box-shadow: 0px 2px 10px rgba(10, 91, 59, 0.15);
		word-break: keep-all;
		.tit {
			font-size: $font_sub_tit;
		}
		.desc {
			color: $color_gray_8;
			font-size: $font_xl;
			.flag_pay {
				padding: 1px 8px;
				color: #006ff1;
				background: #ecf6ff;
				border-radius: 4px;
				font-size: $font_normal;
			}
			.notice {
				display: block;
				.txt {
					position: relative;
					padding-left: 10px;
					color: $color_gray_5;
					font-size: $font_normal;
					&::before {
						display: block;
						position: absolute;
						left: 0;
						top: 3px;
						content: '*';
					}
				}
			}
		}
	}
	&.full {
		.benefit {
			background: #fff;
		}
	}
}

@media all and (max-width: 959px) {
	.benefit_wrap {
		.benefit {
			.tit {
				font-size: $font_lg !important;
			}
			.desc {
				font-size: $font_normal !important ;
				.flag_pay {
					font-size: $font_sm !important;
					padding: 2px 6px !important;
				}
				.notice {
					font-size: $font_sm !important;
				}
			}
		}
	}
}
</style>
