<template>
	<v-col
		cols="12"
		class="pa-0 px-0 px-md-0 layout_align"
		:class="checkDetail === 'checkdetail' ? 'px-md-0' : 'px-0'"
		:style="checkDetail === 'checkdetail' ? 'padding: 0 !important;' : 'padding:0'"
	>
		<v-row
			class="store_info ma-0"
			:class="checkDetail === 'checkdetail' ? 'checkdetailBg mt-10' : 'mt-0'"
			:style="checkDetail === 'checkdetail' ? 'padding:15px; padding-bottom:15px' : ''"
		>
			<v-col
				cols="8"
				class="pa-0"
				align-self="center"
			>
				<v-row class="ma-0">
					<v-col
						v-if="items.company_logourl"
						cols="4"
						md="2"
						class="pa-0"
					>
						<v-img
							:src="items.company_logourl"
							class="store_logo"
							width="100%"
							contain
						/>
					</v-col>
					<v-col
						cols="7"
						md="9"
						class="pa-0 pl-0"
						align-self="center"
					>
						<p class="store_name">{{ items.business_name }}</p>
						<p class="ceo_name">
							<span class="ceo">대표자</span>
							<span class="name">{{ items.boss_name }}</span>
						</p>
					</v-col>
				</v-row>
			</v-col>
			<v-col
				v-if="checkStore !== 'checkStore'"
				cols="4"
				class="pa-0"
				align-self="center"
			>
				<v-col
					cols="12"
					class="pa-0 btn_area"
					align="end"
				>
					<CommonMenuMenu01
						:icon="'mdi-share-variant-outline'"
						:items="share_shareItems"
						class="link"
						@submit="onShare"
					/>
					<v-btn
						v-if="AUTH_GET_USER_AUTH !== 'AGENCY'"
						class="pa-0"
						@click="scrap_call"
					>
						<v-icon>mdi-bookmark-outline</v-icon>
					</v-btn>
				</v-col>
			</v-col>
		</v-row>
	</v-col>
</template>

<script>
import { mapGetters } from 'vuex'
import share from '@/mixins/share'
import scrap from '@/mixins/scrap'

export default {
	mixins: [share, scrap],
	props: ['items'],
	data: () => ({
		checkDetail: '',
		checkStore: '',
	}),
	computed: {
		...mapGetters(['AUTH_GET_USER_AUTH']),
		checkRouter() {
			return this.$route.path.includes('/detail')
		},
		checkRouterStore() {
			return this.$route.path.includes('/store')
		},
	},
	watch: {
		checkRouter(val) {
			if (val) {
				this.checkDetail = 'checkdetail'
			} else {
				this.checkDetail = ''
			}
		},
		checkRouterStore(val) {
			if (val) {
				alert('?')
				this.checkStore = 'checkStore'
			} else {
				this.checkStore = ''
			}
		},
	},
	created() {},
	mounted() {
		if (this.checkRouter) {
			this.checkDetail = 'checkdetail'
		} else this.checkDetail = ''
		if (this.checkRouterStore) {
			this.checkStore = 'checkStore'
		} else this.checkStore = ''
	},
	destroyed() {},
	methods: {
		onShare(item) {
			const productItem = {
				title: this.items.business_name,
				description: '인테리어점',
				image: this.items.company_picurl,
			}
			this.share_operator(item, productItem)
		},
	},
}
</script>

<style scoped lang="scss">
.checkdetailBg {
	background-color: #eee;
	margin-top: 40px;
}
.store_info {
	width: 100%;
	//height: 100px;
	border-radius: 8px;
	padding-top: 15px;
	//background-color: #eee;

	.store_logo {
		height: 64px;
	}

	.store_name {
		font-family: 'NotoSansKR-Regular';
		font-size: $font_lg;
		line-height: 1.3;
		font-weight: bold;
	}
	.ceo_name {
		font-family: 'NotoSansKR-Regular';
		font-size: 14px;
		color: #5f6062;
		.ceo {
			position: relative;
			padding-right: 5px;
			color: #777777;
			&:after {
				display: block;
				width: 1px;
				height: 13px;
				background-color: #5f6062;
				position: absolute;
				top: 5px;
				right: 0;
				content: '';
			}
		}
		.name {
			color: #000 !important;
			padding-left: 5px;
		}
	}

	.btn_area {
		position: relative;
		.v-btn {
			box-shadow: none !important;
			min-width: 40px !important;
			height: 40px !important;
			padding: 0 !important;
			background-color: #eee !important;
			.v-icon {
				color: #414141 !important;
			}
			&:hover {
				color: #fff !important;
				background-color: #42883d !important;
				.v-icon {
					color: #fff !important;
				}
			}
		}
	}
}
@media all and (min-width: 981px) and (max-width: 1280px) {
}
@media all and (min-width: 769px) and (max-width: 980px) {
}
@media all and (min-width: 380px) and (max-width: 768px) {
	.store_info {
		//height: 90px !important;
		padding-bottom: 5px;
		.store_logo {
			height: 50px;
		}
		.store_name {
			font-size: 14px;
		}
		.ceo_name {
			font-size: 12px;
		}
	}
}
@media all and (max-width: 380px) {
	.store_info {
		height: 90px !important;
		.store_logo {
			height: 40px;
		}
		.store_name {
			font-size: 14px;
		}
		.ceo_name {
			font-size: 12px;
		}
	}
}
</style>
