<template>
	<v-dialog
		v-model="model"
		class="pop_wrapper"
		width="100%"
		max-width="320px"
		persistent
		content-class="elevation-0"
	>
		<v-col class="pa-0">
			<!-- 컨텐츠 -->
			<v-card-title class="pa-0">
				<v-row class="ma-0 text_wrapper">
					<v-col
						class="pa-0"
						align="center"
						align-self="center"
					>
						<!-- <p
							class="dialog_text"
							v-html="memberkind"
						/> -->
					</v-col>
				</v-row>
			</v-card-title>

			<!-- <LoginPasswordCount /> -->
			<!-- <LoginPasswordLock /> -->
			<component
				:is="title === '401' ? 'LoginPasswordCount' : 'LoginPasswordLock'"
				:text="text"
			/>

			<!-- 컨텐츠 -->
			<v-card-title class="pa-0">
				<v-row class="ma-0 text_wrapper">
					<v-col
						class="pa-0"
						align="center"
						align-self="center"
					>
						<v-btn
							class="mb-3 pa-0 btn_find"
							text
							align="end"
							@click="linkTo(memberkind)"
						>
							비밀번호 찾기 〉
						</v-btn>
					</v-col>
				</v-row>
			</v-card-title>

			<!-- 확인버튼 -->
			<v-card-actions class="pa-0">
				<v-row class="ma-0 justify-center">
					<CommonButtonsButton01
						class="dialog_btn"
						name="확인"
						color="#00592D"
						height="46"
						@click="$emit('close')"
					/>
				</v-row>
			</v-card-actions>
		</v-col>
	</v-dialog>
</template>

<script>
import model from '@/mixins/model'
import { mapGetters, mapMutations } from 'vuex'

// import LoginPasswordCount from '@/components/login/PasswordCount'
// import LoginPasswordLock from '@/components/login/PasswordCount'
export default {
	name: 'LoginDialogLogin',
	components: {
		LoginPasswordCount: () => import('@/components/login/PasswordCount'),
		LoginPasswordLock: () => import('@/components/login/PasswordLock'),
	},
	//상단에 직접 import하는 경우
	// components: {
	// 	LoginPasswordCount,
	// 	LoginPasswordLock,
	// },
	mixins: [model],
	props: ['title', 'text', 'dialog', 'memberkind'],
	data: () => ({
		//tab: 0,
	}),
	computed: {
		...mapGetters(['APP_GET_DIALOG', 'APP_GET_LOADING']),
		loading() {
			return this.APP_GET_LOADING
		},
		// loginDialog() {
		// 	return !this.APP_GET_LOGINDIALOG ? { dialog: false, title: '', text: '' } : this.APP_GET_LOGINDIALOG
		// },
	},
	methods: {
		...mapMutations(['APP_MU_LOGINDIALOG']),
		closeDialog(e) {
			//this.$emit('close')
			this.$emit('close', e)
		},
		linkTo(e) {
			//this.$emit('close')
			//console.log(e)
			this.$emit('close', e)
			this.$router.push(`/login/find_info/${e}?tab=1`)
		},
	},
}
</script>

<style lang="scss" scoped>
:deep(.v-dialog) {
	border: 1px solid $color_gray_8;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
	border-radius: 8px;
	padding: 30px 16px 16px;
	background-color: #fff;
}

// 제목
.dialog_title {
	font-family: 'NotoSansKR-Bold';
	font-size: 1.2rem;
	word-break: keep-all;
	color: $color_green_6;
}
.title_bottom {
	border-bottom: 1px solid $color_gray_4;
}

// 컨텐츠
.text_wrapper {
	min-height: 0px;
}
.dialog_text {
	font-size: $font_normal !important;
	color: $color_font;
	word-break: keep-all;
}

// 확인버튼
.dialog_btn {
	border-radius: 5px;
	:deep(.v-btn__content) {
		span {
			font-weight: $fw_bold !important;
			font-size: $font_lg !important;
		}
	}
}

.btn_find {
	min-height: 0;
	:deep(.v-btn__content) {
		color: $color_gray_7;
		border-bottom: 1px solid $color_gray_7;
		font-weight: $fw_regular !important;
		font-size: $font_normal !important;
	}
}
</style>
