<template>
	<div class="pagination">
		<button
			class="pagination-button"
			:class="{ 'pagination-button-disabled': currentPage === 1 }"
			@click="prevPage"
		>
			<v-img
				:src="require('@/assets/img/icon/ico_prev_paging.svg')"
				class="icon_play"
			/>
		</button>
		<!-- <button
			v-if="startPage > 1"
			class="pagination-button"
			@click="changePage(1)"
		>
			1
		</button> -->
		<span v-if="startPage > 2">...</span>
		<button
			v-for="pageNumber in displayedPages"
			:key="pageNumber"
			class="pagination-button"
			:class="{ 'pagination-button-active': currentPage === pageNumber }"
			@click="changePage(pageNumber)"
		>
			{{ pageNumber }}
		</button>
		<span v-if="endPage < totalPages - 1">...</span>
		<button
			v-if="endPage < totalPages"
			class="pagination-button"
			@click="changePage(totalPages)"
		>
			{{ totalPages }}
		</button>
		<button
			class="pagination-button"
			:class="{ 'pagination-button-disabled': currentPage === totalPages }"
			@click="nextPage"
		>
			<v-img
				:src="require('@/assets/img/icon/ico_next_paging.svg')"
				class="icon_play"
			/>
		</button>
	</div>
</template>

<script>
export default {
	props: {
		currentPage: {
			type: Number,
			required: true,
		},
		totalPages: {
			type: Number,
			required: true,
		},
		itemsPerPage: {
			type: Number,
			required: true,
		},
		maxDisplayedPages: {
			type: Number,
			default: 5, // 표시할 최대 페이지 수 (1, 2, 3, ..., 99 형태)
		},
	},
	data: () => ({
		startPage: null,
		endPage: null,
	}),
	computed: {
		displayedPages() {
			const pages = []
			this.startPage = Math.max(1, this.currentPage - Math.floor(this.maxDisplayedPages / 2))
			this.endPage = Math.min(this.totalPages, this.startPage + this.maxDisplayedPages - 1)
			if (this.endPage < this.totalPages) {
				this.endPage-- // 맨 끝 페이지를 하나 줄입니다.
			}
			if (this.endPage - this.startPage < this.maxDisplayedPages - 1) {
				this.startPage = Math.max(1, this.endPage - this.maxDisplayedPages + 1)
			}
			for (let i = this.startPage; i <= this.endPage; i++) {
				pages.push(i)
			}
			return pages
		},
	},
	methods: {
		changePage(pageNumber) {
			this.$emit('changePage', pageNumber)
		},
		prevPage() {
			if (this.currentPage > 1) {
				this.$emit('changePage', this.currentPage - 1)
			}
		},
		nextPage() {
			if (this.currentPage < this.totalPages) {
				this.$emit('changePage', this.currentPage + 1)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.pagination {
	display: flex;
	justify-content: center;
	margin-top: 20px;
}

.pagination-button {
	//border: 1px solid #ccc;
	background-color: #fff;
	color: #333;
	padding: 5px 10px;
	cursor: pointer;
	margin: 0 4px;
	&:hover {
		background-color: #00ce7d;
		color: #fff;
		border-radius: 4px;
	}
	&:first-child {
		border: 1px solid #d8d8d8;
		border-radius: 4px;
		&:hover {
			background-color: transparent;
		}
	}
	&:last-child {
		border: 1px solid #d8d8d8;
		border-radius: 4px;
		&:hover {
			background-color: transparent;
		}
	}
}

.pagination-button-active {
	background-color: #00ce7d;
	color: #fff;
	border-radius: 4px;
}

.pagination-button-disabled {
	cursor: not-allowed;
	opacity: 0.5;
}
</style>
