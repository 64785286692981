import apiHome from '@/api/home'
import mainParse from '@/utils/parse/home'

export default {
	state: {
		home_stopSearchDialogs: false,
		home_autoCompleteKeywords: [],
		home_pictureUploadFile: {},
		home_selectedColors: {},
	},
	mutations: {
		HOME_MU_SEARCH_STOP_DIALOGS(state, items) {
			state.home_stopSearchDialogs = items
		},
		HOME_MU_SEARCH_AUTO_COMPLETE_KEYWORDS(state, items) {
			state.home_autoCompleteKeywords = items
		},
		HOME_MU_SEARCH_PICTURE_UPLOAD_FILE(state, items) {
			state.home_pictureUploadFile = items
		},
		HOME_MU_SEARCH_SELECTED_COLORS(state, items) {
			state.home_selectedColors = items
		},
	},
	actions: {
		// 통합 검색 결과
		async HOME_ACT_SEARCH_KEYWORD_UNIVERSAL({ commit }, query) {
			let result
			try {
				await apiHome.search.UniversalKeywordSearchGet(query).then(res => {
					console.log('HOME_ACT_SEARCH_KEYWORD_UNIVERSAL', res)
					result = res == undefined || res == null ? {} : mainParse.search.universal.parser(res)
				})
			} catch (e) {
				console.log('home.search.UniversalKeywordSearchGet', e)
			}
			return result
		},
		// 검색 자동완성 리스트
		async HOME_ACT_SEARCH_KEYWORD_LIST({ commit }) {
			let result
			try {
				await apiHome.search.SearchKeywordListGet().then(res => {
					console.log('HOME_ACT_SEARCH_KEYWORD_LIST', res)
					result = res == undefined || res == null ? {} : mainParse.search.keywordList.parser(res)
				})
				commit('HOME_MU_SEARCH_AUTO_COMPLETE_KEYWORDS', result)
			} catch (e) {
				console.log('home.search.SearchKeywordListGet', e)
			}
			return result
		},
		// 사진검색
		async HOME_ACT_SEARCH_RECOMMEND_LIST({ commit }, params) {
			let result
			try {
				await apiHome.search.SearchRecommendListGet(params).then(res => {
					console.log('HOME_ACT_SEARCH_RECOMMEND_LIST', res)
					result = res == undefined || res == null ? {} : mainParse.search.recommendList.parser(res)
				})
			} catch (e) {
				console.log('home.search.SearchRecommendListGet', e)
			}
			return result
		},
	},
	getters: {
		HOME_GET_SEARCH_STOP_DIALOGS: state => state.home_stopSearchDialogs,
		HOME_GET_SEARCH_AUTO_COMPLETE_KEYWORDS: state => state.home_autoCompleteKeywords,
		HOME_GET_SEARCH_PICTURE_UPLOAD_FILE: state => state.home_pictureUploadFile,
		HOME_GET_SEARCH_SELECTED_COLORS: state => state.home_selectedColors,
	},
	modules: {},
}
