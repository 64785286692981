import * as adminMixins from '@/mixins/admin'
import moment from 'moment'

function moment_filter(date) {
	return moment(date).format('YYYY-MM-DD')
}

const Calculater = {
	basic_array: {
		parser(res) {
			let data = [...res]
			data.forEach((x, i) => {
				x.id = i
			})
			return data
		},
	},
	// 메인 관리
	main: {
		// 메인 베너
		banner: {
			parser(res) {
				let data = [...res]
				data.forEach((x, i) => {
					x.showDate = [moment_filter(x.start_date), moment_filter(x.end_date)]
					x.thumbnail = x.thumimage_url
					x.image = x.mainimage_url
					x.link = x.link_url
					x.regi_userId = x.regi_userid
				})
				return data.sort((a, b) => (a.priority > b.priority ? 1 : -1))
			},
		},
		// 베스트제품
		bestProduct: {
			best: {
				parser(res) {
					let data = [...res]
					data.forEach((x, i) => {
						x.id = i
						x.product_name = `${x.subject_name} > ${x.kind_name} > ${x.brand_name} > ${x.goods_name} > ${x.pattern_name}`
					})
					return data
				},
			},
			hit: {
				parser(res) {
					let data = [...res]
					data.forEach((x, i) => {
						x.id = i
					})
					return data
				},
			},
		},
		// 추천 제품
		recommendProduct: {
			parser(res) {
				let data = [...res]
				data.forEach((x, i) => {
					x.id = i
					x.show = x.recommend_priority === 1 ? '노출' : ''
				})
				return data.sort((a, b) => (a.priority > b.priority ? 1 : -1))
			},
		},
		// 추천 검색어
		recommendSearch: {
			parser(res) {
				let data = [...res]
				data.forEach((x, i) => {
					x.id = i
					x.priority = x.listorder
					x.link = x.link_url
				})
				return data.sort((a, b) => (a.regi_date > b.regi_date ? -1 : 1))
			},
		},
		// 팝업
		popup: {
			parser(res) {
				let data = [...res]
				const concatPeriod = (start, end) => {
					return `${moment_filter(start)} ~ ${moment_filter(end)}`
				}
				data.forEach(x => {
					x.id = x.popup_id
					x['data-period'] = concatPeriod(x.start_date, x.end_date)
					x.showDate = [moment(x.start_date).format('YYYY-MM-DD'), moment(x.end_date).format('YYYY-MM-DD')]
					x.popup_image = x.popup_image ?? null
				})
				return data.sort((a, b) => (a.regi_date > b.regi_date ? -1 : 1))
			},
		},
	},
	// 제품 관리
	product: {
		category_data: {
			parser(query, res) {
				let data = [...res]
				switch (query.substring(0, 1)) {
					// 품목
					case '1':
						data.forEach(x => {
							x.category_id = 'subject'
							x.title = x.subject_name
							x.text = `${x.use_kind === '1' ? '주거용' : '상업용'} ${x.subject_name}`
							x.partCode = x.subject_code
						})
						data = data.sort((a, b) => (a.priority > b.priority ? 1 : -1))
						break
					// 종류
					case '2':
						data.forEach(x => {
							x.category_id = 'kind'
							x.text = x.kind_name
							x.typeCode = x.kind_code
						})
						break
					// 브랜드
					case '3':
						data.forEach(x => {
							x.category_id = 'brand'
							x.text = x.brand_name
							x.brandCode = x.brand_code
						})
						data.sort((a, b) => (a.priority > b.priority ? 1 : -1))
						data.sort((a, b) => (a.brandCode > b.brandCode ? 1 : -1))
						break
					// 제품
					case '4':
						data.forEach(x => {
							x.category_id = 'goods'
							x.text = x.goods_name
							x.productCode = x.goods_code
						})
						break
					// 패턴
					case '5':
						data.forEach(x => {
							x.category_id = 'pattern'
							x.text = x.pattern_name
							x.patternCode = x.pattern_code
						})
						break
				}
				return data
			},
			product_parser(method, res) {
				let data = [...res]
				switch (method) {
					// 특장점
					case 'CC_101_001':
						data.forEach(x => {
							x.id = x.detail_id
							x.thumbnail = x.thumbnail_url
							x.image = x.image_url
						})
						break
					// 구조
					case 'CC_101_002':
						data.forEach(x => {
							x.id = x.detail_id
							x.thumbnail = x.thumbnail_url
							x.image = x.image_url
						})
						break
					// 그 외
					default:
						data.forEach(x => {
							x.id = x.detail_id
							x.thumbnail = x.thumbnail_url
						})
						break
				}
				return data
			},
		},
		public_code_data: {
			parser(res) {
				let data = [...res]
				// const kindItems = store.getters.ADMIN_GET_PRODUCT_PUBLIC_CODE_GROUP_ITEMS
				data.forEach((x, i) => {
					x.id = i + 1
					x.text = x.code_name
					// x.code_kind_name = kindItems.find(item => item.value === x.code_kind).text
				})

				// 101 수정되지 않는 코드 filter
				const filter = data.filter(item => item.group_code.substring(0, 1) !== '1')
				return filter
			},
			group_parser(res) {
				let data = [...res]
				data.forEach((x, i) => {
					x.id = i + 1
					x.text = x.group_name
				})
				// 101 수정되지 않는 코드 filter
				const filter = data.filter(item => item.group_code.substring(0, 1) !== '1')
				return filter
			},
		},
		tag_data: {
			parser(res) {
				let data = [...res]
				data.forEach((x, i) => {
					x.id = x.tag_id !== undefined ? x.tag_id : i + 1
				})
				return data
			},
			tag_parser(res) {
				let data = [...res]
				data.forEach(x => {
					x.id = x.tag_id
					x.image = x.tagimage_url
				})
				return data.sort((a, b) => (a.regi_date > b.regi_date ? -1 : 1))
			},
		},
		master_data: {
			parser(res) {
				let data = [...res]
				data.forEach((x, i) => {
					x.number = i + 1
					x.id = x.product_id
					x.release_date = x.release_date !== '' && x.release_date != null ? moment_filter(x.release_date) : null
				})
				return data.sort((a, b) => (a.regi_date > b.regi_date ? -1 : 1))
			},
		},
	},
	// 자료 관리
	reference: {
		// 인증서 / 시험성적서
		certification: {
			parser(res) {
				let data = [...res]
				data.forEach((x, i) => {
					x.id = x.docu_id
					x.docu_kind = x.docu_kind
						? adminMixins.export_admin_certification_divisionItems.find(item => item.value === x.docu_kind)
						: ''
					x.showDate = [moment(x.auth_from).format('YYYY-MM-DD'), moment(x.auth_to).format('YYYY-MM-DD')]
				})
				return data.sort((a, b) => (a.regi_date > b.regi_date ? -1 : 1))
			},
		},
		// 카탈로그
		catalog: {
			parser(res) {
				let data = [...res]
				data.forEach((x, i) => {
					x.id = x.catalogue_id
					if (x.catalogue_mapping.length) {
						x.subject_code = x.catalogue_mapping[0].subject_code
						x.subject_name = x.catalogue_mapping[0].subject_name
						x.kind_code = x.catalogue_mapping[0].subject_code
						x.kind_name = x.catalogue_mapping[0].kind_name
					}
				})
				return data.sort((a, b) => (a.regi_date > b.regi_date ? -1 : 1))
			},
		},
	},
	// 고객 센터
	custom: {
		special: {
			parser(res) {
				let data = [...res]
				data.forEach((x, i) => {
					x.id = x.special_id
				})
				return data.sort((a, b) => (a.regi_date > b.regi_date ? -1 : 1))
			},
		},
		quality: {
			parser(res) {
				let data = [...res]
				data.forEach((x, i) => {
					x.id = x.guarantee_id
					x.purchase_date = x.purchase_date.replace(/(\d{4})(\d{2})/, `$1년 $2월`)
					x.data_status_quality = x.conf_state
						? adminMixins.export_admin_quality_statusItems.find(item => item.value === x.conf_state)
						: ''
				})
				return data.sort((a, b) => (a.regi_date > b.regi_date ? -1 : 1))
			},
		},
		as: {
			parser(res) {
				let data = [...res]
				data.forEach((x, i) => {
					x.id = i
					x.data_status_as = x.progress_state
						? adminMixins.export_admin_as_statusItems.find(item => item.value === x.progress_state)
						: ''
					x.link = x.file_url
				})
				return data.sort((a, b) => (a.regi_date > b.regi_date ? -1 : 1))
			},
		},
		asCareFlo: {
			parser(res) {
				let data = [...res]
				data.forEach((x, i) => {
					x.id = i
					x.data_status_as = x.progress_state
						? adminMixins.export_admin_as_statusItems.find(item => item.value === x.progress_state)
						: ''
					x.link = x.file_url
				})
				return data.sort((a, b) => (a.receipt_date > b.receipt_date ? -1 : 1))
			},
		},
		notice: {
			parser(res) {
				let data = [...res]
				data.forEach((x, i) => {
					x.id = x.notice_id
					x.file = x.file_url1
					x.link = x.file_url2
					x.important = x.important_yn
						? adminMixins.export_admin_notice_importantItems.find(item => item.value === x.important_yn).text
						: ''
				})
				return data.sort((a, b) => (a.regi_date > b.regi_date ? -1 : 1))
			},
		},
		interiorQuestion: {
			parser(res) {
				let data = [...res]
				data.forEach((x, i) => {
					x.id = x.service_id
				})
				return data.reverse()
			},
		},
	},
	// 전시 관리
	display: {
		// 3D집들이
		house_3D: {
			parser(res) {
				let data = [...res]
				data.forEach((x, i) => {
					x.id = x.hw_id
					x.status = x.use_yn ? adminMixins.export_admin_booleanItems.find(item => item.value === x.use_yn).text : ''
				})
				return data.sort((a, b) => (a.regi_date > b.regi_date ? -1 : 1))
			},
		},
		// 전문가 집들이
		house_special: {
			parser(res) {
				let data = [...res]
				data.forEach((x, i) => {
					x.id = x.exphw_id
					x.status = x.app_state
						? adminMixins.export_admin_quality_statusItems.find(item => item.value === x.app_state).text
						: ''
					x.product_path = `${x.use_kind === '1' ? '주거용' : '상업용'} ${x.subject_name} > ${x.kind_name} > ${
						x.brand_name
					} > ${x.goods_name} > ${x.pattern_name}`
				})
				return data.sort((a, b) => (a.regi_date > b.regi_date ? -1 : 1))
			},
		},
		// 노하우
		konw_how: {
			parser(res) {
				let data = [...res]
				data.forEach((x, i) => {
					x.id = x.knowhow_id
				})
				return data.sort((a, b) => (a.regi_date > b.regi_date ? -1 : 1))
			},
		},
		// 동화 쇼룸
		show_room: {
			parser(res) {
				let data = [...res]
				data.forEach((x, i) => {
					x.id = x.showroom_id
					x.status = x.showroom_yn
						? adminMixins.export_admin_booleanItems.find(item => item.value === x.showroom_yn).text
						: ''
				})
				return data.sort((a, b) => (a.regi_date > b.regi_date ? -1 : 1))
			},
		},
		// 노하우
		maru_tv: {
			parser(res) {
				if (!res) return []
				let data = [...res]
				data.forEach(x => {
					x.id = x.knowhow_id
					x.link = x.video_url
				})
				return data.sort((a, b) => (a.regi_date > b.regi_date ? -1 : 1))
			},
		},
	},
	// 견적 관리
	estimate: {
		exclusive_area: {
			parser(res) {
				let data = [...res]
				data.forEach((x, i) => {
					x.text = `${x.py_area}평 ( ${x.exclusive_area}㎡ )`
				})
				return data
			},
		},
		room_count: {
			parser(res) {
				let data = [...res]
				data.forEach((x, i) => {
					x.text = `${x.roomcount} 개`
				})
				return data
			},
		},
		brand: {
			parser(res) {
				let data = [...res]
				data.forEach((x, i) => {
					x.text = x.brand_name
				})
				return data
			},
		},
		product: {
			parser(res, checkList) {
				let data = []
				res.forEach((x, i) => {
					const check = checkList.find(item => item.goods_code === x.goods_code)
					if (check && check.use_yn === 'Y') {
						x.text = x.goods_name
						data.push(x)
					}
				})
				return data
			},
		},
		// 견적관리리스트
		estimate_list: {
			parser(res) {
				let data = [...res]
				data.forEach((x, i) => {
					x.id = x.est_id
				})
				return data.sort((a, b) => (a.regi_date > b.regi_date ? -1 : 1))
			},
		},
		// 견적상담리스트
		estimate_consult_list: {
			parser(res) {
				let data = [...res]
				console.log('값 받아오는값111', data)
				data.forEach((x, i) => {
					console.log('값 받아오는값222', x)
					x.id = x.consult_id
					// x.consult_state = x.consult_state
					// 	? adminMixins.export_admin_estimate_consult_statusItems.find(item => item.value === x.consult_state).text
					// 	: ''

					x.consult_state = x.consult_state
						? adminMixins.export_admin_estimate_consult_statusItems.find(item => item.text === x.consult_state)
						: ''
					x.address = `${x.zipcode}) ${x.address1} ${x.address2}`
				})
				return data.sort((a, b) => (a.regi_date > b.regi_date ? -1 : 1))
			},
		},
	},
	// 마케팅
	marketing: {
		// 이벤트
		event: {
			parser(res) {
				let data = [...res]
				data.forEach((x, i) => {
					x.id = x.event_id
					x.event_date = `${moment(x.start_date).format('YYYY-MM-DD')} ~ ${moment(x.end_date).format('YYYY-MM-DD')}`

					if (x.participating_user.length) {
						x.participating_user.forEach(user => {
							user.status = user.win_yn
								? adminMixins.export_admin_event_winStatusItems.find(item => item.value === user.win_yn)
								: ''
							user.data_status_event = user.win_yn
						})
					}
				})
				return data.sort((a, b) => (a.regi_date > b.regi_date ? -1 : 1))
			},
		},
	},
	// 통계
	statistics: {
		// 대시보드
		dashboard: {
			parser(res) {
				let data = {}
				Object.entries(res).forEach(([x, i]) => {
					if (x === 'count') data[x] = i
					if (x === 'guarantee') {
						data[x] = []
						i.map(item => {
							let temp = {}
							Object.entries(item).forEach(([y, j]) => {
								if (y === 'state') Object.assign(temp, { first: j })
								if (y === 'guarantee_no') Object.assign(temp, { second: j })
								if (y === 'regi_date') Object.assign(temp, { third: j })
							})
							data[x].push(temp)
							data[x].sort((a, b) => (a.third > b.third ? -1 : 1))
						})
					}
					if (x === 'serviceas') {
						data[x] = []
						i.map(item => {
							let temp = {}
							Object.entries(item).forEach(([y, j]) => {
								if (y === 'state') Object.assign(temp, { first: j })
								if (y === 'kind_name') Object.assign(temp, { second: j })
								if (y === 'regi_date') Object.assign(temp, { third: j })
							})
							data[x].push(temp)
							data[x].sort((a, b) => (a.third > b.third ? -1 : 1))
						})
					}
					if (x === 'serviceask') {
						data[x] = []
						i.map(item => {
							let temp = {}
							Object.entries(item).forEach(([y, j]) => {
								if (y === 'state') Object.assign(temp, { first: j })
								if (y === 'service_kind') Object.assign(temp, { second: j })
								if (y === 'regi_date') Object.assign(temp, { third: j })
							})
							data[x].push(temp)
							data[x].sort((a, b) => (a.third > b.third ? -1 : 1))
						})
					}
					if (x === 'corporation' || x === 'exphousewarming' || x === 'interservice') {
						data[x] = []
						i.map(item => {
							let temp = {}
							Object.entries(item).forEach(([y, j]) => {
								if (y === 'state') Object.assign(temp, { first: j })
								if (y === 'business_name') Object.assign(temp, { second: j })
								if (y === 'regi_date') Object.assign(temp, { third: j })
							})
							data[x].push(temp)
							data[x].sort((a, b) => (a.third > b.third ? -1 : 1))
						})
					}
					if (x === 'statistics') {
						data[x] = []
						i.map(item => {
							let temp = {}
							Object.entries(item).forEach(([y, j]) => {
								if (y === 'getDate') Object.assign(temp, { date: j })
								if (y === 'totalUser') Object.assign(temp, { value: j })
								if (y === 'type') Object.assign(temp, { type: j })
							})
							data[x].push(temp)
							data[x].sort((a, b) => (a.date > b.date ? 1 : -1))
						})
					}
				})
				return data
			},
		},
		// 통계 홈
		home: {
			parser(res) {
				let data = [...res]
				let newData = []
				const kpi = [
					{ active_user: '방문자수' },
					{ page_view: '페이지뷰수' },
					{ active_ratio: '방문자활동수' },
					{ est_count: '견적이용수' },
					{ consult_count: '맞춤견적 상담신청수' },
					{ safetynumber_count: '전화통화수' },
					{ cbi: 'CBI' },
					{ contentuse_ratio: '정보컨텐츠 이용률' },
				]
				const measure_cycle = ['Month', 'Day', 'Day', 'Day', 'Day', 'Day', 'Day', 'Day']

				for (let i = 0; i < kpi.length; i++) {
					let obj = {}
					let sum = 0

					const key = Object.keys(kpi[i])[0]
					const value = Object.values(kpi[i])[0]

					obj['kpi'] = value
					obj['measure_cycle'] = measure_cycle[i]
					data.forEach(x => {
						obj[`date_${x['get_date']}`] = x[key] === 0 ? '-' : (Math.round(x[key] * 100) / 100).toLocaleString()
						sum += x[key]
					})
					obj['sum'] = sum === 0 ? '-' : (Math.round(sum * 100) / 100).toLocaleString()
					obj['avg'] = sum / data.length === 0 ? '-' : (Math.round((sum / data.length) * 100) / 100).toLocaleString()

					newData.push(obj)
				}

				return newData
			},
		},
		// 방문자
		member: {
			parser(res) {
				let data = [...res]
				data = data.map(item => {
					const formattedItem = {}
					for (const key in item) {
						if (key !== 'get_date' && typeof item[key] === 'number') {
							formattedItem[key] = item[key].toLocaleString() // 천단위 콤마 추가
						} else {
							formattedItem[key] = item[key]
						}
					}
					return formattedItem
				})
				data.sort((a, b) => (a.get_date > b.get_date ? -1 : 1))

				const totalDataIndex = data.findIndex(item => item.get_date === '총계')
				if (totalDataIndex !== -1) {
					const totalData = data[totalDataIndex]
					data.splice(totalDataIndex, 1)
					data.unshift(totalData)
				}
				return data
			},
		},
		visitor: {
			parser(res) {
				let data = [...res]
				data = data.map(item => {
					const formattedItem = {}
					for (const key in item) {
						if (key !== 'get_date' && typeof item[key] === 'number') {
							formattedItem[key] = item[key].toLocaleString() // 천단위 콤마 추가
						} else {
							formattedItem[key] = item[key]
						}
					}
					return formattedItem
				})
				data.sort((a, b) => (a.get_date > b.get_date ? -1 : 1))

				const totalDataIndex = data.findIndex(item => item.get_date === '총계')
				if (totalDataIndex !== -1) {
					const totalData = data[totalDataIndex]
					data.splice(totalDataIndex, 1)
					data.unshift(totalData)
				}
				return data
			},
		},
		consult: {
			parser(res) {
				let data = { ...res }
				data.estConsult = data.estConsult.map(item => {
					const formattedItem = {}
					for (const key in item) {
						if (key === 'estConsult_ratio') formattedItem[key] = Math.round(item[key] * 100) / 100
						else if (key !== 'get_date' && typeof item[key] === 'number') {
							formattedItem[key] = item[key].toLocaleString() // 천단위 콤마 추가
						} else {
							formattedItem[key] = item[key]
						}
					}
					return formattedItem
				})
				data.estConsult.sort((a, b) => (a.get_date > b.get_date ? -1 : 1))
				data.callConsult = data.callConsult.map(item => {
					const formattedItem = {}
					for (const key in item) {
						if (key === 'estimate_ratio' || key === 'saleConsult_ratio')
							formattedItem[key] = Math.round(item[key] * 100) / 100
						else if (key !== 'get_date' && typeof item[key] === 'number') {
							formattedItem[key] = item[key].toLocaleString() // 천단위 콤마 추가
						} else {
							formattedItem[key] = item[key]
						}
					}
					return formattedItem
				})
				data.callConsult.sort((a, b) => (a.get_date > b.get_date ? -1 : 1))

				Object.keys(data).forEach(x => {
					const totalDataIndex = data[x].findIndex(item => item.get_date === '총계')
					if (totalDataIndex !== -1) {
						const totalData = data[x][totalDataIndex]
						data[x].splice(totalDataIndex, 1)
						data[x].unshift(totalData)
					}
				})

				return data
			},
		},
		mainContent: {
			parser(res) {
				let data = [...res]
				data = data.map(item => {
					const formattedItem = {}
					for (const key in item) {
						if (key === 'cnt' && typeof item[key] === 'number') {
							formattedItem[key] = item[key].toLocaleString() // 천단위 콤마 추가
						} else {
							formattedItem[key] = item[key]
						}
					}
					return formattedItem
				})
				return data
			},
		},
		housewarming: {
			parser(res) {
				let data = [...res]
				data = data.map(item => {
					const formattedItem = {}
					for (const key in item) {
						if (key !== 'get_date' && typeof item[key] === 'number') {
							formattedItem[key] = item[key].toLocaleString() // 천단위 콤마 추가
						} else {
							formattedItem[key] = item[key]
						}
					}
					return formattedItem
				})
				data.sort((a, b) => (a.get_date > b.get_date ? -1 : 1))

				const totalDataIndex = data.findIndex(item => item.get_date === '총계')
				if (totalDataIndex !== -1) {
					const totalData = data[totalDataIndex]
					data.splice(totalDataIndex, 1)
					data.unshift(totalData)
				}
				return data
			},
		},
		// 검색
		keyword: {
			parser(res) {
				let data = { ...res }
				data.totalCount = data.totalCount.map(item => {
					const formattedItem = {}
					for (const key in item) {
						if (key !== 'get_date' && typeof item[key] === 'number') {
							formattedItem[key] = item[key].toLocaleString() // 천단위 콤마 추가
						} else {
							formattedItem[key] = item[key]
						}
					}
					return formattedItem
				})
				data.totalCount.sort((a, b) => (a.get_date < b.get_date ? -1 : 1))
				return data
			},
		},
		picture: {
			parser(res) {
				let data = { ...res }
				data.totalCount = data.totalCount.map(item => {
					const formattedItem = {}
					for (const key in item) {
						if (key !== 'get_date' && typeof item[key] === 'number') {
							formattedItem[key] = item[key].toLocaleString() // 천단위 콤마 추가
						} else {
							formattedItem[key] = item[key]
						}
					}
					return formattedItem
				})
				data.searchLogList.forEach(x => {
					x.image = x.picture_url
				})
				data.totalCount.sort((a, b) => (a.get_date > b.get_date ? -1 : 1))
				data.searchLogList.sort((a, b) => (a.regi_date > b.regi_date ? -1 : 1))
				return data
			},
		},
		color: {
			parser(res) {
				let data = { ...res }
				data.totalCount = data.totalCount.map(item => {
					const formattedItem = {}
					for (const key in item) {
						if (key !== 'get_date' && typeof item[key] === 'number') {
							formattedItem[key] = item[key].toLocaleString() // 천단위 콤마 추가
						} else {
							formattedItem[key] = item[key]
						}
					}
					return formattedItem
				})
				data.totalCount.sort((a, b) => (a.get_date > b.get_date ? -1 : 1))
				data.searchLogList.sort((a, b) => (a.regi_date > b.regi_date ? -1 : 1))
				return data
			},
		},
		menu: {
			parser(res) {
				let data = [...res]
				data = data.map(item => {
					const formattedItem = {}
					for (const key in item) {
						if (key !== 'get_date' && typeof item[key] === 'number') {
							formattedItem[key] = item[key].toLocaleString() // 천단위 콤마 추가
						} else {
							formattedItem[key] = item[key]
						}
					}
					return formattedItem
				})
				data.sort((a, b) => (a.get_date > b.get_date ? -1 : 1))

				const totalIndex = data.findIndex(item => item.get_date === '총계')
				if (totalIndex !== -1) {
					const totalData = data[totalIndex]
					data.splice(totalIndex, 1)
					data.unshift(totalData)
				}
				const grandTotalIndex = data.findIndex(item => item.get_date === '누계')
				if (grandTotalIndex !== -1) {
					const grandTotalData = data[grandTotalIndex]
					data.splice(grandTotalIndex, 1)
					data.unshift(grandTotalData)
				}

				let result = {
					admin: [],
					user: [],
				}

				data.forEach(x => {
					result.admin.push({
						get_date: x.get_date,
						housewarming_count: x.housewarming_count,
						exphousewarming_count: x.exphousewarming_count,
						special_count: x.special_count,
						catalogue_count: x.catalogue_count,
						knowhow_count: x.knowhow_count,
						video_count: x.video_count,
						instamedia_count: x.instamedia_count,
						event_count: x.event_count,
						showroom_count: x.showroom_count,
						notice_count: x.notice_count,
					})
					result.user.push({
						get_date: x.get_date,
						picturesearch_count: x.picturesearch_count,
						colorsearch_count: x.colorsearch_count,
						fastestimate_count: x.fastestimate_count,
						estimate_count: x.estimate_count,
						estconsult_count: x.estconsult_count,
						guarantee_count: x.guarantee_count,
						asservice_count: x.asservice_count,
						askservice_count: x.askservice_count,
					})
				})

				return result
			},
		},
	},
	// 회원관리
	user: {
		// 회원관리
		user: {
			parser(res) {
				let data = [...res]
				data.forEach((x, i) => {
					x.id = i
					x.status = x.inactive_date ? '휴면' : x.leave_date ? '탈퇴' : '정상'
				})
				return data.sort((a, b) => (a.regi_date > b.regi_date ? -1 : 1))
			},
		},
		// 인테리어 회원관리
		interior: {
			parser(res) {
				let data = [...res]
				data.forEach((x, i) => {
					x.id = i
					x.join_state = x.app_state
						? adminMixins.export_admin_user_interior_statusItems.find(item => item.value == x.app_state).text
						: ''
				})
				return data.sort((a, b) => (a.regi_date > b.regi_date ? -1 : 1))
			},
		},
		// 스크랩 리스트
		scrap: {
			parser(res) {
				let data = [...res]
				data.forEach((x, i) => {
					x.id = x.scrap_id
				})
				return data.sort((a, b) => (a.regi_date > b.regi_date ? -1 : 1))
			},
		},
		// 케어플로 리스트
		careFlo: {
			parser(res) {
				let data = []
				let interior = [...res]

				// 대리점과 NFC 데이터 따로 분리해서 저장

				// 대리점
				interior.forEach((x, i) => {
					// 대리점 클릭시 해당 대리점에 등록된 NFC데이터를 필터링하기 위한 조건으로
					// careflo_nfc안에 key(agent_corno)값 넣기
					x.id = x.agent_corno
					x.careflo_nfc.forEach((y, j) => {
						y.id = x.agent_corno + j
						y.agent_corno = x.agent_corno
					})
					x.careflo_nfc.sort((a, b) => (a.operator_date > b.operator_date ? -1 : 1))
				})
				interior.sort((a, b) => (a.regi_date > b.regi_date ? -1 : 1))

				// NFC
				let nfc = interior.reduce((acc, x) => {
					acc.push(...x.careflo_nfc)
					return acc
				}, [])

				data.push(interior)
				data.push(nfc)
				return data
			},
		},
	},
	// 관리자 관리
	admin: {
		parser(res) {
			let data = [...res]
			data.forEach((x, i) => {
				x.id = i
			})
			return data.sort((a, b) => (a.regi_date > b.regi_date ? -1 : 1))
		},
	},
}

export default Calculater
