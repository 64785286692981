<template>
	<v-dialog
		v-model="dialog"
		persistent
		width="1000"
	>
		<v-card class="pa-0">
			<AdminItemsTitle
				:title="mode === 'create' ? `${title} 등록` : `${title} 수정`"
				@close="$emit('close')"
			/>
			<v-col class="pa-3 pa-md-5">
				<v-container class="admin_table">
					<v-row class="ma-0 admin_table_area">
						<v-col
							cols="12"
							md="6"
							class="pa-0"
						>
							<AdminItemsTableItem
								v-model="models.agent_name"
								title="*대리점명"
								input
							/>
						</v-col>
						<v-col
							cols="12"
							md="6"
							class="pa-0"
						>
							<AdminItemsTableItem
								v-if="mode === 'create'"
								v-model="models.agent_corno"
								title="*사업자번호"
								input
							/>
							<AdminItemsTableItem
								v-else
								title="*사업자번호"
								:text="models.agent_corno"
							/>
						</v-col>
						<v-col
							cols="12"
							md="12"
							class="pa-0"
						>
							<AdminItemsTableItem
								v-model="models.agent_bossname"
								title="대표자명"
								input
								:fill="true"
							/>
						</v-col>
						<v-col
							cols="12"
							md="6"
							class="pa-0"
						>
							<AdminItemsTableItem
								v-model="models.agent_bosshand"
								title="대표 연락처"
								input
							/>
						</v-col>
						<v-col
							cols="12"
							md="6"
							class="pa-0"
						>
							<AdminItemsTableItem
								v-model="models.agent_tel"
								title="대리점 연락처"
								input
							/>
						</v-col>
					</v-row>
				</v-container>
				<v-row
					class="ma-0"
					align="center"
					justify="center"
				>
					<v-col
						cols="12"
						md="6"
						class="pl-0"
					>
						<v-row class="ma-0">
							<v-col cols="6">
								<v-btn
									color="error"
									block
									tile
									elevation="0"
									@click="$emit('close')"
								>
									<v-icon>clear</v-icon>
									<span class="ml-2">취소</span>
								</v-btn>
							</v-col>
							<v-col
								v-if="mode === 'create'"
								cols="6"
							>
								<v-btn
									color="primary"
									block
									tile
									elevation="0"
									:disabled="allowValue"
									@click="onApiCallItem"
								>
									<v-icon>add</v-icon>
									<span class="ml-2">등록</span>
								</v-btn>
							</v-col>
							<v-col
								v-if="mode === 'edit'"
								cols="6"
							>
								<v-btn
									color="secondary"
									block
									tile
									elevation="0"
									:disabled="allowValue"
									@click="onApiCallItem"
								>
									<v-icon>edit</v-icon>
									<span class="ml-2">수정</span>
								</v-btn>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-col>
		</v-card>
		<CommonLoading v-if="loading" />
	</v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import model from '@/mixins/model'
import common from '@/mixins/common'
import admin from '@/mixins/admin'

export default {
	name: 'AdminUserCareFloCreateInterior',
	mixins: [model, common, admin],
	props: ['title', 'dialog', 'mode', 'editItem'],
	data: () => ({
		models: {
			agent_corno: null,
			agent_name: null,
			agent_tel: null,
			agent_bossname: null,
			agent_bosshand: null,
		},
		origin: {},
		loading: false,
	}),
	computed: {
		...mapGetters(['AUTH_GET_ADMIN_USER']),
		allowValue() {
			// const checkValue = ['agent_corno', 'agent_name', 'agent_tel', 'agent_bossname', 'agent_bosshand']
			// let check = this.common_allowValueCheck(this.models, checkValue)
			// if (check) return true

			const checkText = ['agent_corno', 'agent_name', 'agent_tel', 'agent_bossname', 'agent_bosshand']
			let check = this.common_allowTextCheck(this.models, checkText)
			if (check) return true

			return check
		},
	},
	watch: {
		models(val) {
			console.log('models', val)
		},
	},
	async created() {
		if (this.editItem && this.mode === 'edit') this.startDataMapping()
		// console.log('editItem', this.editItem, this.models)
	},
	async mounted() {},
	destroyed() {
		this.resetData()
	},
	methods: {
		...mapActions(['ADMIN_ACT_USER_CARE_FLO']),
		startDataMapping() {
			this.models = {
				agent_corno: this.editItem.agent_corno,
				agent_name: this.editItem.agent_name,
				agent_tel: this.editItem.agent_tel,
				agent_bossname: this.editItem.agent_bossname,
				agent_bosshand: this.editItem.agent_bosshand,
			}

			if (this.editItem.company_picurl) {
				this.company_picurl = this.editItem.company_picurl
			} else {
				this.company_picurl = null
				this.models.company_picurl = null
			}

			if (this.editItem.company_logourl) {
				this.company_logourl = this.editItem.company_logourl
			} else {
				this.company_logourl = null
				this.models.company_logourl = null
			}

			this.origin = JSON.parse(JSON.stringify(this.models))
		},
		resetData() {
			this.models = {
				agent_corno: null,
				agent_name: null,
				agent_tel: null,
				agent_bossname: null,
				agent_bosshand: null,
			}
			this.origin = {}
		},
		async onApiCallItem() {
			this.loading = true

			const params = {
				agent_corno: this.models.agent_corno, // 상태
				agent_name: this.models.agent_name, // 대리점 이름
				agent_tel: this.models.agent_tel, // 대리점 전화
				agent_bossname: this.models.agent_bossname, // 대표 이름
				agent_bosshand: this.models.agent_bosshand, // 대표 전화
			}

			const items = { method: this.mode === 'create' ? 'createInterior' : 'editInterior', params: params }

			console.log('items', items)

			try {
				await this.ADMIN_ACT_USER_CARE_FLO(items)
				this.loading = false
				this.$emit('close')
			} catch (e) {
				console.log('ADMIN_ACT_USER_CARE_FLO', e)
				this.loading = false
			}
		},
	},
}
</script>

<style scoped lang="scss"></style>
