<template>
	<div class="mx-0 my-2">
		<v-col
			class="pa-0"
			cols="4"
			align-self="center"
		>
			<p class="tit">상세견적</p>
		</v-col>
		<div>
			<HomeInteriorPriceConsult />
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'GreenDongwhaClientRepo06Form',
	computed: {
		...mapGetters(['AUTH_GET_USER_AUTH']),
	},
}
</script>

<style lang="scss" scoped></style>
