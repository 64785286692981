<template>
	<div class="photo_analysis">
		<v-row class="ma-0 mb-3 mb-md-5 head">
			<v-col
				class="pa-0 subject"
				cols="6"
				align-self="center"
			>
				<div class="d-flex">
					<span>사진 분석 결과</span>
					<span class="desc hidden-sm-and-down">
						<span class="eff"></span>
						<v-icon
							color="#006FF1"
							@click="showTooltip = !showTooltip"
							>mdi-information-outline</v-icon
						>

						<div
							v-show="showTooltip"
							class="txt"
						>
							<p>* 카메라 촬영 환경으로 분석 결과 값이 실제와 다를 수 있습니다.</p>
							<p>* 비율에 따라 사진이 일부 잘려보일 수 있습니다.</p>
							<div
								class="btn_close tar"
								@click="showTooltip = !showTooltip"
							>
								<v-icon
									small
									color="white"
									>mdi-window-close</v-icon
								>
							</div>
						</div>
					</span>
				</div>
			</v-col>

			<v-col
				class="pa-0 hidden-md-and-up"
				cols="6"
				align-self="center"
				align="end"
			>
				<div
					class="btn_pic_chage"
					@click="triggerFileInput"
				>
					<span class="d-flex justify-center">
						<v-img
							width="100%"
							max-width="16"
							height="17"
							:src="require('@/assets/img/search/icon_pic_change2.svg')"
						/>
						<span class="pl-1">사진변경</span>
					</span>

					<input
						ref="fileInput"
						type="file"
						accept="image/*"
						capture="camera"
						hidden
						@change="handleFileChange"
					/>
				</div>
			</v-col>
		</v-row>

		<v-row class="ma-0">
			<v-col
				class="pa-0"
				cols="12"
				md="5"
			>
				<div class="mr-0 mr-md-15 left">
					<div class="mb-2 pic_change">
						<v-img
							width="100%"
							height="100%"
							:src="uploadedImage"
						/>
					</div>
					<div
						class="d-flex justify-center btn_pic_chage"
						@click="triggerFileInput"
					>
						<v-img
							width="100%"
							max-width="18"
							height="19"
							:src="require('@/assets/img/search/icon_pic_change.svg')"
						/>
						<span class="pl-1">사진변경</span>
						<input
							ref="fileInput"
							type="file"
							accept=".jpeg, .jpg, .png"
							hidden
							@change="handleFileChange"
						/>
					</div>
				</div>
			</v-col>

			<v-col
				class="pa-0"
				md="7"
			>
				<div class="px-2 px-md-4 px-lg-10 py-4 right">
					<div class="tac">
						<span class="color_spectrum mb-4">색상분포도</span>
					</div>

					<div class="mb-4 analysis_rank">
						<v-row class="ma-0">
							<v-col
								class="pa-0 item"
								cols="3"
								align="center"
								align-self="center"
							>
								<div class="mx-1 mx-md-2 bg">
									<div class="drop">
										<svg
											width="47"
											height="55"
											viewBox="0 0 47 55"
											fill="none"
										>
											<path
												d="M6.98372 47.7517C11.5289 52.1632 17.0341 54.369 23.4994 54.369C29.9646 54.369 35.4698 52.1632 40.015 47.7517C44.5601 43.3402 46.8327 38.0106 46.8327 31.7629C46.8327 28.6153 46.225 25.706 45.0098 23.0353C43.7945 20.3645 42.1174 17.9799 39.9785 15.8815L23.4994 0L7.02018 15.8815C4.88129 17.9799 3.20421 20.3645 1.98893 23.0353C0.773655 25.706 0.166016 28.6153 0.166016 31.7629C0.166016 38.0106 2.43859 43.3402 6.98372 47.7517Z"
												:fill="items?.colorInfo?.color1"
											/>
											<path
												d="M23.4994 53.869C17.1611 53.869 11.7817 51.7118 7.33196 47.3929C2.88233 43.0741 0.666016 37.8733 0.666016 31.7629C0.666016 28.6814 1.26046 25.8435 2.44403 23.2424C3.633 20.6294 5.27358 18.2959 7.36878 16.2399C7.3693 16.2394 7.36982 16.2389 7.37035 16.2384L23.4994 0.694402L39.6284 16.2384C39.6289 16.2389 39.6295 16.2395 39.6301 16.2401C41.7252 18.296 43.3657 20.6295 44.5547 23.2424C45.7382 25.8435 46.3327 28.6814 46.3327 31.7629C46.3327 37.8733 44.1164 43.0741 39.6667 47.3929C35.217 51.7118 29.8376 53.869 23.4994 53.869Z"
												stroke="black"
												stroke-opacity="0.1"
											/>
										</svg>
									</div>
								</div>
							</v-col>

							<v-col
								class="pa-0 item"
								cols="3"
								align="center"
							>
								<div class="mx-1 mx-md-2 bg">
									<div class="drop">
										<svg
											width="47"
											height="55"
											viewBox="0 0 47 55"
											fill="none"
										>
											<path
												d="M6.98372 47.7517C11.5289 52.1632 17.0341 54.369 23.4994 54.369C29.9646 54.369 35.4698 52.1632 40.015 47.7517C44.5601 43.3402 46.8327 38.0106 46.8327 31.7629C46.8327 28.6153 46.225 25.706 45.0098 23.0353C43.7945 20.3645 42.1174 17.9799 39.9785 15.8815L23.4994 0L7.02018 15.8815C4.88129 17.9799 3.20421 20.3645 1.98893 23.0353C0.773655 25.706 0.166016 28.6153 0.166016 31.7629C0.166016 38.0106 2.43859 43.3402 6.98372 47.7517Z"
												:fill="items?.colorInfo?.color2"
											/>
											<path
												d="M23.4994 53.869C17.1611 53.869 11.7817 51.7118 7.33196 47.3929C2.88233 43.0741 0.666016 37.8733 0.666016 31.7629C0.666016 28.6814 1.26046 25.8435 2.44403 23.2424C3.633 20.6294 5.27358 18.2959 7.36878 16.2399C7.3693 16.2394 7.36982 16.2389 7.37035 16.2384L23.4994 0.694402L39.6284 16.2384C39.6289 16.2389 39.6295 16.2395 39.6301 16.2401C41.7252 18.296 43.3657 20.6295 44.5547 23.2424C45.7382 25.8435 46.3327 28.6814 46.3327 31.7629C46.3327 37.8733 44.1164 43.0741 39.6667 47.3929C35.217 51.7118 29.8376 53.869 23.4994 53.869Z"
												stroke="black"
												stroke-opacity="0.1"
											/>
										</svg>
									</div>
								</div>
							</v-col>

							<v-col
								class="pa-0 item"
								cols="3"
								align="center"
							>
								<div class="mx-1 mx-md-2 bg">
									<div class="drop">
										<svg
											width="47"
											height="55"
											viewBox="0 0 47 55"
											fill="none"
										>
											<path
												d="M6.98372 47.7517C11.5289 52.1632 17.0341 54.369 23.4994 54.369C29.9646 54.369 35.4698 52.1632 40.015 47.7517C44.5601 43.3402 46.8327 38.0106 46.8327 31.7629C46.8327 28.6153 46.225 25.706 45.0098 23.0353C43.7945 20.3645 42.1174 17.9799 39.9785 15.8815L23.4994 0L7.02018 15.8815C4.88129 17.9799 3.20421 20.3645 1.98893 23.0353C0.773655 25.706 0.166016 28.6153 0.166016 31.7629C0.166016 38.0106 2.43859 43.3402 6.98372 47.7517Z"
												:fill="items?.colorInfo?.color3"
											/>
											<path
												d="M23.4994 53.869C17.1611 53.869 11.7817 51.7118 7.33196 47.3929C2.88233 43.0741 0.666016 37.8733 0.666016 31.7629C0.666016 28.6814 1.26046 25.8435 2.44403 23.2424C3.633 20.6294 5.27358 18.2959 7.36878 16.2399C7.3693 16.2394 7.36982 16.2389 7.37035 16.2384L23.4994 0.694402L39.6284 16.2384C39.6289 16.2389 39.6295 16.2395 39.6301 16.2401C41.7252 18.296 43.3657 20.6295 44.5547 23.2424C45.7382 25.8435 46.3327 28.6814 46.3327 31.7629C46.3327 37.8733 44.1164 43.0741 39.6667 47.3929C35.217 51.7118 29.8376 53.869 23.4994 53.869Z"
												stroke="black"
												stroke-opacity="0.1"
											/>
										</svg>
									</div>
								</div>
							</v-col>

							<v-col
								class="pa-0 item"
								cols="3"
								align="center"
							>
								<div class="mx-1 mx-md-2 bg">
									<div class="drop">
										<svg
											width="47"
											height="55"
											viewBox="0 0 47 55"
											fill="none"
										>
											<path
												d="M6.98372 47.7517C11.5289 52.1632 17.0341 54.369 23.4994 54.369C29.9646 54.369 35.4698 52.1632 40.015 47.7517C44.5601 43.3402 46.8327 38.0106 46.8327 31.7629C46.8327 28.6153 46.225 25.706 45.0098 23.0353C43.7945 20.3645 42.1174 17.9799 39.9785 15.8815L23.4994 0L7.02018 15.8815C4.88129 17.9799 3.20421 20.3645 1.98893 23.0353C0.773655 25.706 0.166016 28.6153 0.166016 31.7629C0.166016 38.0106 2.43859 43.3402 6.98372 47.7517Z"
												:fill="items?.colorInfo?.color4"
											/>
											<path
												d="M23.4994 53.869C17.1611 53.869 11.7817 51.7118 7.33196 47.3929C2.88233 43.0741 0.666016 37.8733 0.666016 31.7629C0.666016 28.6814 1.26046 25.8435 2.44403 23.2424C3.633 20.6294 5.27358 18.2959 7.36878 16.2399C7.3693 16.2394 7.36982 16.2389 7.37035 16.2384L23.4994 0.694402L39.6284 16.2384C39.6289 16.2389 39.6295 16.2395 39.6301 16.2401C41.7252 18.296 43.3657 20.6295 44.5547 23.2424C45.7382 25.8435 46.3327 28.6814 46.3327 31.7629C46.3327 37.8733 44.1164 43.0741 39.6667 47.3929C35.217 51.7118 29.8376 53.869 23.4994 53.869Z"
												stroke="black"
												stroke-opacity="0.1"
											/>
										</svg>
									</div>
								</div>
							</v-col>
						</v-row>
					</div>

					<div class="mx-1 mx-md-2 mb-4 mb-md-5 furniture">
						<v-row class="ma-0">
							<v-col
								class="pa-0"
								cols="3"
								md="3"
								lg="4"
								align="center"
								align-self="center"
							>
								<span class="tit">가구</span>
							</v-col>

							<v-col
								class="pa-0"
								cols="9"
								md="9"
								lg="8"
							>
								<div class="item_group">
									<div
										v-for="(item, i) in furniture"
										:key="i"
										:class="[items.objectList?.some(x => x.objectName === item.titleEng) ? 'active' : '']"
										class="px-4 item"
									>
										<svg
											width="34"
											height="34"
											viewBox="0 0 34 34"
											fill="#fff"
											class="mr-0 mr-md-2"
										>
											<rect
												x="0.5"
												y="0.5"
												width="34"
												height="34"
												:rx="item.title === '의자' ? 17 : 16.5"
												fill="#fff"
											/>
											<path
												v-if="item.title === '소파'"
												d="M10 26C9.71667 26 9.47917 25.9042 9.2875 25.7125C9.09583 25.5208 9 25.2833 9 25V24C8.16667 24 7.45833 23.7083 6.875 23.125C6.29167 22.5417 6 21.8333 6 21V16C6 15.1667 6.29167 14.4583 6.875 13.875C7.45833 13.2917 8.16667 13 9 13V11C9 10.1667 9.29167 9.45833 9.875 8.875C10.4583 8.29167 11.1667 8 12 8H22C22.8333 8 23.5417 8.29167 24.125 8.875C24.7083 9.45833 25 10.1667 25 11V13C25.8333 13 26.5417 13.2917 27.125 13.875C27.7083 14.4583 28 15.1667 28 16V21C28 21.8333 27.7083 22.5417 27.125 23.125C26.5417 23.7083 25.8333 24 25 24V25C25 25.2833 24.9042 25.5208 24.7125 25.7125C24.5208 25.9042 24.2833 26 24 26C23.7167 26 23.4792 25.9042 23.2875 25.7125C23.0958 25.5208 23 25.2833 23 25V24H11V25C11 25.2833 10.9042 25.5208 10.7125 25.7125C10.5208 25.9042 10.2833 26 10 26ZM9 22H25C25.2833 22 25.5208 21.9042 25.7125 21.7125C25.9042 21.5208 26 21.2833 26 21V16C26 15.7167 25.9042 15.4792 25.7125 15.2875C25.5208 15.0958 25.2833 15 25 15C24.7167 15 24.4792 15.0958 24.2875 15.2875C24.0958 15.4792 24 15.7167 24 16V20H10V16C10 15.7167 9.90417 15.4792 9.7125 15.2875C9.52083 15.0958 9.28333 15 9 15C8.71667 15 8.47917 15.0958 8.2875 15.2875C8.09583 15.4792 8 15.7167 8 16V21C8 21.2833 8.09583 21.5208 8.2875 21.7125C8.47917 21.9042 8.71667 22 9 22ZM12 18H22V16C22 15.55 22.0917 15.1417 22.275 14.775C22.4583 14.4083 22.7 14.0833 23 13.8V11C23 10.7167 22.9042 10.4792 22.7125 10.2875C22.5208 10.0958 22.2833 10 22 10H12C11.7167 10 11.4792 10.0958 11.2875 10.2875C11.0958 10.4792 11 10.7167 11 11V13.8C11.3 14.0833 11.5417 14.4083 11.725 14.775C11.9083 15.1417 12 15.55 12 16V18Z"
												fill="#D9D9D9"
											/>
											<path
												v-if="item.title === '의자'"
												d="M10 26V19C10 18.45 10.1958 17.9792 10.5875 17.5875C10.9792 17.1958 11.45 17 12 17H13V15H12C11.45 15 10.9792 14.8042 10.5875 14.4125C10.1958 14.0208 10 13.55 10 13V10C10 9.45 10.1958 8.97917 10.5875 8.5875C10.9792 8.19583 11.45 8 12 8H22C22.55 8 23.0208 8.19583 23.4125 8.5875C23.8042 8.97917 24 9.45 24 10V13C24 13.55 23.8042 14.0208 23.4125 14.4125C23.0208 14.8042 22.55 15 22 15H21V17H22C22.55 17 23.0208 17.1958 23.4125 17.5875C23.8042 17.9792 24 18.45 24 19V26H22V23H12V26H10ZM12 13H22V10H12V13ZM15 17H19V15H15V17ZM12 21H22V19H12V21Z"
												fill="#D9D9D9"
											/>
											<path
												v-if="item.title === '테이블'"
												class="table"
												d="M9.3246 14H24.6746L23.8246 11H10.1996L9.3246 14ZM21.7996 16H12.2246L11.9496 18H22.0496L21.7996 16ZM8.9996 25L10.2246 16H7.9996C7.66626 16 7.40376 15.8667 7.2121 15.6C7.02043 15.3333 6.96626 15.0417 7.0496 14.725L8.4746 9.725C8.54126 9.50833 8.65793 9.33333 8.8246 9.2C8.99126 9.06667 9.19126 9 9.4246 9H24.5746C24.8079 9 25.0079 9.06667 25.1746 9.2C25.3413 9.33333 25.4579 9.50833 25.5246 9.725L26.9496 14.725C27.0329 15.0417 26.9788 15.3333 26.7871 15.6C26.5954 15.8667 26.3329 16 25.9996 16H23.7996L24.9996 25H22.9996L22.3246 20H11.6746L10.9996 25H8.9996Z"
												fill="#D9D9D9"
											/>
										</svg>
										<span class="item_title">{{ item.title }}</span>
									</div>
								</div>
							</v-col>
						</v-row>
					</div>

					<div class="result_txt">
						<!-- 결과값이 있을때 -->
						<div v-if="items.objectList?.length > 0">
							사진 분석 결과값을 바탕으로 <br />
							어울리는 제품을 추천드릴게요!
						</div>

						<!-- 결과값이 없을때 -->
						<div v-else>
							<p class="fw_bold color_state_posi">사진 분석 AI가 가구 정보를 감지하지 못했네요!</p>
							<p>사진 분석 결과값을 바탕으로 어울리는 제품을 추천드릴게요!</p>
						</div>
					</div>
				</div>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import common from '@/mixins/common'
export default {
	name: 'PhotoAnalysis',
	mixins: [common],
	props: ['items'],
	data: () => ({
		showTooltip: false,
		uploadedImage: null,
		furniture: [
			{
				title: '소파',
				titleEng: 'sofa',
			},
			{
				title: '의자',
				titleEng: 'chair',
			},
			{
				title: '테이블',
				titleEng: 'diningtable',
			},
		],
	}),
	computed: {
		...mapGetters(['HOME_GET_SEARCH_PICTURE_UPLOAD_FILE']),
	},
	watch: {
		HOME_GET_SEARCH_PICTURE_UPLOAD_FILE() {
			this.uploadedImage = this.HOME_GET_SEARCH_PICTURE_UPLOAD_FILE?.image
		},
		// items() {
		// 	this.updateColorSpectrum()
		// },
	},
	created() {
		this.uploadedImage = this.HOME_GET_SEARCH_PICTURE_UPLOAD_FILE?.image
	},
	mounted() {
		// this.updateColorSpectrum()
	},
	methods: {
		...mapMutations(['HOME_MU_SEARCH_PICTURE_UPLOAD_FILE']),
		triggerFileInput() {
			this.$refs.fileInput.click()
		},
		async handleFileChange(e) {
			const selectedFile = e.target.files[0]
			if (selectedFile) {
				let data = {}
				const reader = new FileReader()
				reader.onload = e => {
					data.file = selectedFile
					data.image = e.target.result
					this.HOME_MU_SEARCH_PICTURE_UPLOAD_FILE(data)
				}
				reader.readAsDataURL(selectedFile)
			}
		},
		updateColorSpectrum() {
			const colorBoxes = Array.from(document.getElementsByClassName('colorBoxes'))
			const colors = this.items.colorInfo

			colorBoxes.forEach((box, i) => {
				let colorBox = document.getElementById(`colorBox${i + 1}`)
				if (colorBox) box.removeChild(colorBox)

				const newColorBox = document.createElement('div')
				newColorBox.id = `colorBox${i + 1}`
				newColorBox.classList.add('colorBox')
				box.appendChild(newColorBox)

				for (let j = 0; j <= 100; j++) {
					const innerDiv = document.createElement('div')
					//바닥컬러
					if (i === 0) {
						innerDiv.style.background = `hsl(${colors.floorColorHsl[0]}, ${colors.floorColorHsl[1]}%, ${100 - j}%)`
						if (j === 100 - colors.floorColorHsl[2]) innerDiv.classList.add('here')
					}
					// 벽컬러
					if (i === 1) {
						innerDiv.style.background = `hsl(${colors.wallColorHsl[0]}, ${colors.wallColorHsl[1]}%, ${100 - j}%)`
						if (j === 100 - colors.wallColorHsl[2]) innerDiv.classList.add('here')
					}
					newColorBox.appendChild(innerDiv)
				}
			})
		},
	},
}
</script>

<style scoped lang="scss">
.photo_analysis {
	.head {
		.subject {
			font-size: $font_sub_tit;
			font-weight: 500;
			letter-spacing: -2px;
			color: $color_font;
			.d-flex {
				align-items: center !important;
			}
		}
		.btn_pic_chage {
			display: inline-block;
			width: 100px;
			padding: 4px 13px;
			border: 1px solid $color_gray_3;
			border-radius: 4px;
			box-sizing: border-box;
			font-size: $font_sm;
		}
		.desc {
			display: inline-block;
			position: relative;
			cursor: pointer;
			padding-left: 4px;
			line-height: 20px;
			> .v-icon.v-icon {
				&::after {
					display: none;
				}
			}
			.eff {
				display: none;
			}
			&:hover {
				.eff {
					display: inline-block;
					width: 30px;
					height: 30px;
					position: absolute;
					left: 1px;
					top: -2px;
					background-color: rgba(71, 170, 252, 0.3);

					border-radius: 100%;
				}
			}
			.txt {
				width: 400px;
				position: absolute;
				bottom: -90px;
				left: -30px;
				padding: 16px;
				background: rgba(38, 38, 38, 0.9);
				border-radius: 4px;
				color: $color_white;
				font-size: $font_normal;
				z-index: 2;
				.btn_close {
					height: 10px;
					.v-icon.v-icon {
						vertical-align: top !important;
					}
				}
				&:after {
					border-top: 0 solid transparent;
					border-left: 8px solid transparent;
					border-right: 8px solid transparent;
					border-bottom: 8px solid rgba(38, 38, 38, 0.9);
					content: '';
					position: absolute;
					top: -8px;
					left: 38px;
				}
			}
		}
	}

	.left {
		height: 100%;
		.pic_change {
			width: 100%;
			max-height: 290px;
			margin: 0 auto;
			border-radius: 10px;
			border: 1px solid rgba(0, 0, 0, 0.1);
			overflow: hidden;
			aspect-ratio: 16/12 !important;
		}
		.btn_pic_chage {
			width: 100%;
			padding: 10px 0;
			color: $color_white;
			background-color: rgba(0, 0, 0, 0.6) !important;
			font-size: $font_normal;
			border-radius: 8px;
			cursor: pointer;
		}
		.tone {
			padding: 5px 16px;
			color: $color_font;
			font-size: $font_lg;
			line-height: 1;
			border-radius: 99px;
			background-color: $color_gray_1;
		}
		::v-deep(.colorBox) {
			display: flex;
			width: 100%;
			height: 12px;
			> div {
				position: relative;
				flex: 1;
				border-top: 1px solid $color_gray_2;
				border-bottom: 1px solid $color_gray_2;
				&:first-child {
					border-start-start-radius: 160px;
					border-end-start-radius: 160px;
					border-left: 1px solid $color_gray_2;
				}
				&:last-child {
					border-start-end-radius: 160px;
					border-end-end-radius: 160px;
					border-right: 1px solid $color_gray_2;
				}
				&.here::before {
					display: block;
					width: 11px;
					height: 13px;
					background: url('../../../assets/img/search/color_drop.svg') no-repeat;
					background-size: contain;
					content: '';
					position: absolute;
					top: -18px;
					left: -5.5px;
				}
			}
		}
	}

	.right {
		height: 100%;
		border: 1px solid $color_gray_2;
		border-radius: 8px;
		.color_spectrum {
			display: inline-block;
			padding: 7px 32px;
			font-size: $font_lg;
			color: $color_white;
			border-radius: 99px;
			background-color: $color_gray_8;
		}
		.analysis_rank {
			.item {
				.bg {
					position: relative;
					height: 100px;
					background-color: $color_gray_1;
					border: 1px solid $color_gray_2;
					border-radius: 16px;
					.drop {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
					}
				}
			}
		}

		.furniture {
			padding: 7px 18px;
			background-color: $color_gray_1;
			border-radius: 4px;
			.tit {
				padding: 5px 10px;
				font-size: $font_lg;
				color: $color_font;
				background-color: $color_white;
				border-radius: 99px;
			}
			.item_group {
				display: flex;
				justify-content: space-between;
				.item {
					display: flex;
					position: relative;
					align-items: center;
					text-align: center;
					font-size: $font_normal;
					color: $color_gray_4;
					line-height: 1.2 !important;
					&::after {
						display: block;
						position: absolute;
						top: 10px;
						right: -20px;
						width: 1px;
						height: 16px;
						background-color: #d9d9d9;
						content: '';
					}
					&.active {
						color: $color_font !important;
						svg {
							rect {
								fill: $color_state_posi !important;
							}
							path {
								fill: $color_white !important;
							}
						}
					}
					&:last-child {
						&::after {
							display: none !important;
						}
					}
				}
			}
		}

		.result_txt {
			text-align: center;
			color: $color_font;
			font-size: $font_lg;
			word-break: keep-all;
		}
	}
}

@media all and (max-width: 959px) {
	.photo_analysis {
		.head {
			.subject {
				font-size: $font_lg !important;
			}
		}

		.left {
			.pic_change {
				max-width: 100% !important;
			}
			.btn_pic_chage {
				display: none !important;
			}
			.tone {
				font-size: $font_normal !important;
			}
			::v-deep(.colorBox) {
				height: 10px !important;
				> div {
					&.here::before {
						top: -15px !important;
					}
				}
			}
		}

		.right {
			.color_spectrum {
				padding: 6px 16px !important;
				font-size: $font_normal !important;
			}
			.analysis_rank {
				.item {
					.bg {
						height: 56px !important;
						.drop {
							svg {
								width: 29px !important;
								height: 34px !important;
							}
						}
					}
				}
			}
			.furniture {
				padding: 12px 18px !important;
				.tit {
					padding: 5px 12px !important;
					font-size: $font_normal !important;
				}
				.item_group {
					.item {
						display: grid;
						font-size: $font_sm !important;
						&::after {
							display: none !important;
						}
					}
				}
			}
			.result_txt {
				font-size: $font_normal !important;
			}
		}

		// .furniture {
		// 	padding: 7px 18px;
		// 	background-color: $color_gray_1;
		// 	border-radius: 4px;
		// 	.tit {
		// 		padding: 5px 10px;
		// 		font-size: $font_lg;
		// 		color: $color_font;
		// 		background-color: $color_white;
		// 		border-radius: 99px;
		// 	}
		// 	.item {
		// 		text-align: center;
		// 		font-size: $font_normal;
		// 		color: $color_font;
		// 		line-height: 1.2 !important;
		// 		&.active {
		// 			svg {
		// 				rect {
		// 					fill: $color_state_posi !important;
		// 				}
		// 				path {
		// 					fill: $color_white !important;
		// 				}
		// 			}
		// 		}
		// 	}
		// }
	}
}
</style>
