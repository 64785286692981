<template>
	<div>
		<v-row class="ma-0 py-3 mb-5 d-flex history_bar">
			<v-col
				class="pa-0 tit"
				align="start"
				align-self="center"
			>
				간편상담 신청 내역
			</v-col>
			<v-col
				class="pa-0"
				align="end"
			>
				<CommonButtonsButton03
					to="/interior/price/inquire"
					title="상담 신청"
					add-icon
				/>
			</v-col>
		</v-row>
		<v-row
			v-if="USER_GET_ESTIMATE.length"
			class="ma-0"
			style="margin-bottom: 70px !important"
		>
			<v-col class="pa-0">
				<p class="txt">
					비회원으로 맞춤견적을 신청하신 고객님께서는 <br />로그아웃 후 간편상담 메뉴를 통해서 신청 내역을 확인해주세요.
				</p>
				<CommonCardMenuGroup02
					:menu-type="'estimate'"
					:card-menu="paginatedItems"
				/>
			</v-col>
		</v-row>
		<v-row
			v-else
			justify="center"
			class="ma-0"
		>
			<UserItemEmpty :text="`집 근처 인테리어점을 찾아<br>첫 번째 <strong>견적상담 의뢰</strong>를 진행해보세요.`" />
		</v-row>
		<div>
			<v-col
				cols="12"
				class="pa-0 register_history inner"
				align="center"
			>
				<div>
					<CommonPaginationListPagination03
						:total-pages="totalPages"
						:items-per-page="itemsPerPage"
						:current-page="currentPage"
						@changePage="handlePageChange"
					/>
				</div>
			</v-col>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
	name: 'UserMyPageEstimateList',
	props: ['items'],
	data: () => ({
		//페이징
		currentPage: 1,
		itemsPerPage: 7,
		pageitems: [], // 실제 데이터가 들어가는 곳으로 가정
		previousPage: null, // 이전 페이지를 저장하기 위한 변수
	}),
	computed: {
		...mapGetters(['USER_GET_ESTIMATE', 'GUEST_GET_MYPAGE_INFO_PREVIOUSPAGE']),
		changedItems() {
			return this.pageitems
		},
		totalItems() {
			return this.paginatedItems.length
		},
		totalPages() {
			return Math.ceil(this.changedItems.length / this.itemsPerPage)
		},
		paginatedItems() {
			const startIndex = (this.currentPage - 1) * this.itemsPerPage
			const endIndex = startIndex + (this.itemsPerPage + 1)
			return this.changedItems.slice(startIndex, endIndex)
		},
	},
	watch: {
		previousPage(val) {
			if (val) {
				this.GUEST_MU_MYPAGE_INFO_PREVIOUSPAGE(val)
			}
		},
		USER_GET_ESTIMATE(val) {
			console.log('업데이트 내용', val)
		},
	},
	created() {
		if (this.USER_GET_ESTIMATE.length === 0) return this.$router.push('/user?tab=0')
		this.pageitems = this.USER_GET_ESTIMATE
		if (this.GUEST_GET_MYPAGE_INFO_PREVIOUSPAGE !== null) {
			this.previousPage = this.GUEST_GET_MYPAGE_INFO_PREVIOUSPAGE
			this.currentPage = this.previousPage
		}
	},
	methods: {
		...mapMutations(['USER_MU_ESTIMATE_SELECT', 'GUEST_MU_MYPAGE_INFO_PREVIOUSPAGE']),
		handlePageChange(pageNumber) {
			this.currentPage = pageNumber
			this.previousPage = this.currentPage // 이전 페이지를 저장
		},
	},
}
</script>

<style scoped lang="scss">
.txt {
	padding: 20px 0 20px 0;
	font-weight: $fw_bold;
	font-size: $font_mid;
}
</style>
