<template>
	<div class="px-3 px-sm-0 mb-7 by_category">
		<template v-for="(tab, i) in tabs">
			<div
				v-if="i !== 0"
				:key="tab.value"
				:class="[value === 0 ? 'mb-5' : '']"
			>
				<p
					v-if="value === 0 || value === i"
					class="tit"
				>
					{{ tab.title }}
				</p>

				<v-row class="ma-0 wrap">
					<v-col
						v-if="value === 0 && filterItemsNumber(i) > 4"
						class="pa-0 pc_btn_more"
						@click="$emit('input', i)"
					>
						<div class="tac txt">
							<v-icon>mdi-arrow-right</v-icon>
							<p>더보기</p>
						</div>
					</v-col>
					<template v-for="(item, j) in filterItems(i)">
						<!-- 전체 탭일경우 카드 4개만 노출, 외에 전부 출력 -->
						<v-col
							v-if="value === 0 ? j < 4 : true"
							:key="item.type + item.id"
							class="pa-0 px-1 px-sm-2 pb-4 pb-md-10 item"
						>
							<CommonCardMenuCardDefault
								new-tab-link
								:item="item"
							/>
						</v-col>
					</template>
				</v-row>

				<CommonButtonsButton01
					v-if="value === 0 && filterItemsNumber(i) > 4"
					class="mo_btn_more"
					outlined
					name="더보기"
					color="#d8d8d8"
					height="36"
					@click="$emit('input', i)"
				/>
			</div>
		</template>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'HomeByCategory',
	props: ['items', 'tabs', 'value'], // value는 현재 tab
	data: () => ({
		showItemsCount: 20,
		increaseItemsCount: 12,
		scrollThrottle: null,
	}),
	computed: {
		...mapGetters(['APP_GET_SCROLL_BOTTOM']),
	},
	watch: {
		value() {
			setTimeout(() => {
				if (this.value !== 0) this.filterItems(this.value)
			}, 100)
		},
		APP_GET_SCROLL_BOTTOM() {
			if (this.value === 0) return

			if (!this.scrollThrottle) {
				if (this.showItemsCount < this.items?.length) this.showItemsCount += this.increaseItemsCount
				this.scrollThrottle = setTimeout(() => {
					this.scrollThrottle = null
					console.log('this.showItemsCount', this.showItemsCount)
				}, 500)
			}
		},
	},
	created() {
		console.log('this.items', this.items)
	},
	mounted() {},
	destroyed() {},
	methods: {
		filterItems(tab) {
			const type = this.tabs[tab].value
			return this.items.filter(item => item.type === type).slice(0, this.value === 0 ? 4 : this.showItemsCount)
		},
		filterItemsNumber(tab) {
			const type = this.tabs[tab].value
			return this.items.filter(item => item.type === type).length
		},
	},
}
</script>

<style scoped lang="scss">
.by_category {
	.tit {
		margin-bottom: 30px !important;
		font-size: 2rem;
		font-weight: $fw_regular;
		color: $color_font;
		text-align: center;
	}
	.wrap {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(25%, 25%));
		grid-template-rows: repeat(auo-fit, minmax(400px, 4fr));
		// gap: 20px;
		position: relative;
		.pc_btn_more {
			display: none;
			width: 70px;
			height: 70px;
			background: rgba(255, 255, 255, 0.9);
			position: absolute;
			top: 50%;
			right: -35px;
			transform: translateY(-50%);
			z-index: 2;
			border-radius: 50%;
			cursor: pointer;
			.txt {
				height: 100%;
				padding: 12px 0;
				font-size: $font_normal;
				color: $color_gray_7;
			}
		}
		&:hover {
			.pc_btn_more {
				display: block;
			}
		}
	}
	.mo_btn_more {
		display: none;
		margin-top: 16px;
		background-color: $color_white !important;
		:deep(.v-btn__content) {
			span {
				font-size: $font_normal;
				color: $color_font !important;
			}
		}
	}
}

@media all and (min-width: 600px) and (max-width: 1264px) {
	.by_category {
		.tit {
			margin-bottom: 16px !important;
			font-size: $font_sub_tit !important;
		}
		.wrap {
			grid-template-columns: repeat(auto-fit, minmax(33.3%, 33.3%)) !important;
			// gap: 12px !important;
			&:hover {
				.pc_btn_more {
					display: none !important;
				}
			}
		}
		.mo_btn_more {
			display: block !important;
		}
	}
}

@media all and (max-width: 768px) {
	.by_category {
		.tit {
			margin-bottom: 16px !important;
			font-size: $font_sub_tit !important;
		}
		.wrap {
			grid-template-columns: repeat(auto-fit, minmax(50%, 50%)) !important;
			// gap: 12px !important;
			&:hover {
				.pc_btn_more {
					display: none !important;
				}
			}
		}
		.mo_btn_more {
			display: block !important;
		}
	}
}
</style>
