<template>
	<div class="mx-0 my-2">
		<v-col
			cols="12"
			class="py-0 px-0 pb-4 question"
			align="start"
		>
			<span>05</span> 원하는 바닥재 종류를 선택해주세요.
			<p class="selectedItem">
				<span
					>제품선택 : {{ kindModel ? kindModel.kind_name : '강마루' }} >
					{{ productModel ? productModel.text : '' }}</span
				>
			</p>
		</v-col>
		<!-- 바닥재종류 -->
		<v-col
			cols="12"
			class="py-0 px-0 pb-6"
		>
			<div align="start">
				<!-- <p class="mb-1 color_gray_7">확장여부</p> -->
				<CommonSelectsSelect07
					v-model="productModel"
					:parents-items="kindItemsFilter"
					:child-items="productItemsFilter"
					:select-text="selectProductModel"
					select-id="goods_code"
					label="제품 선택"
					@select-parents="selectKind"
					@select-child="selectProduct"
				/>
			</div>
		</v-col>

		<v-col
			cols="12"
			class="py-0 px-0 pb-4 question"
			align="start"
		>
			<span>06</span> 기존 제품의 철거 유무를 선택해주세요.
		</v-col>
		<!-- 철거유무 -->
		<v-col
			cols="12"
			class="py-0 px-0 pb-6"
		>
			<div align="start">
				<p class="mb-1 color_gray_7">철거 유무</p>
				<CommonCardMenuTypeBtn06
					v-model="allow.type_4"
					:type="'two'"
					:type-items="homeTypeItems4"
					:cols="6"
					:height="56"
				/>
			</div>
		</v-col>
	</div>
</template>

<script>
export default {
	name: 'GreenDongwhaClientRepo03Form',

	props: {
		currentpage: {
			Type: Number,
			require: true,
		},
		homeTypeItems4: {
			type: Array,
		},
		productModel: {
			type: Object,
		},
		kindModel: {
			type: Object,
		},
		kindItemsFilter: {
			type: Array,
		},
		productItemsFilter: {
			type: Array,
		},
		selectProductModel: {
			type: String,
		},
		reset: {
			Type: Boolean,
			default: '',
		},
	},

	data() {
		return {
			allow: {
				productModel: null,
				type_4: null,
			},
		}
	},

	computed: {
		homeTypeItems_1_update() {
			return {
				productModel: this.allow.productModel,
				type_4: this.allow.type_4,
			}
		},
	},
	watch: {
		updatedata(newval, oldval) {
			if (newval) {
				this.$emit('formevent', newval, 'form3')
			}
		},
		homeTypeItems_1_update(newval, oldval) {
			if (newval) {
				if (Object.keys(newval)[0] === 'productModel') {
					this.$emit('hometype1', newval, 'form3')
				}
				if (Object.keys(newval)[0] === 'type_4') {
					this.$emit('hometype1', newval, 'form3')
				}
			}
		},
		reset(newvalue, oldvalue) {
			if (newvalue) {
				this.allow.productModel = null
				this.allow.type_4 = null
			} else {
				this.allow.productModel = null
				this.allow.type_4 = null
			}
		},
	},

	mounted() {},

	methods: {
		selectKind(item) {
			this.$emit('selectkind', item)
		},
		selectProduct(item) {
			this.$emit('selectproduct', item)
		},
	},
}
</script>

<style lang="scss" scoped>
.selectedItem {
	left: 25px;
	position: relative;
	span {
		background-color: #f8f8f8;
		padding: 4px 12px;
		font-size: $font_sm !important;
		color: #777777;
		display: inline-block;
		border-radius: 99px;
		font-weight: 100 !important;
	}
}
.stepprogreess {
	display: flex;
	li {
		width: 25%;
		list-style: none;
		height: 10px;
		background-color: #c0c0c0;
	}
	.active {
		background-color: #00582c;
	}
}

.question {
	color: $color_font;
	font-size: $font_xl;
	letter-spacing: -0.7px;
	span {
		font-weight: bold;
		font-size: $font_sub_tit;
	}
}
</style>
