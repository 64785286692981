<template>
	<v-app-bar
		app
		:clipped-right="true"
		color="white"
		class="header"
		:class="scrollUp ? 'scrollUp' : ''"
		flat
	>
		<div class="wrapper">
			<v-container
				class="pa-0 px-4 px-lg-0"
				fluid
			>
				<!-- 1단 -->
				<v-row class="ma-0 pb-3 depth_one">
					<!-- 로고:pc -->
					<v-col
						class="pa-0 hidden-sm-and-down"
						cols="2"
						sm="2"
						md="4"
						align="start"
						align-self="center"
					>
						<v-img
							alt="Logo"
							class="logo shrink"
							contain
							:src="logoImage"
							:width="logoWidth"
							eager
							@click="goHome"
						/>
					</v-col>

					<!-- 로고:mo -->
					<v-col
						class="pa-0 hidden-md-and-up"
						cols="2"
						sm="3"
						md="4"
						align="start"
						align-self="center"
					>
						<v-img
							alt="Logo"
							class="mo_logo shrink"
							contain
							:src="moLogoImage"
							:width="logoWidth"
							eager
							max-width="25"
							@click="goHome"
						/>
					</v-col>

					<!-- 검색창 & 연관검색창 -->
					<v-col
						class="pa-0"
						cols="8"
						sm="6"
						md="4"
						align="center"
						align-self="center"
					>
						<LayoutAppbarSearchBar />
					</v-col>

					<!-- 유틸리티 -->
					<v-col
						class="pa-0 hidden-sm-and-down util"
						cols="2"
						sm="5"
						md="4"
						align="end"
						align-self="center"
					>
						<v-row class="ma-0 justify-end align-center">
							<v-col
								v-for="menu in userMenu[0]"
								:key="menu.title"
								class="pa-0 px-2 item clickCursor"
								cols="auto"
								@click="pushLink(menu)"
							>
								<div
									v-if="menu.title === '마루추천'"
									class="point"
								>
									<v-img
										width="20"
										class="mr-1"
										:src="require('@/assets/ico_photo_camera.svg')"
									/>
									{{ menu.title }}
								</div>
								<template v-else>{{ menu.title }}</template>
							</v-col>

							<!-- <v-col
								v-for="menu in userMenu[0]"
								:key="menu.title"
								class="pa-0 px-2 item clickCursor"
								cols="auto"
								@click="pushLink(menu)"
							>
								<div class="d-flex point">
									<v-img
										class="hidden-md-and-down"
										width="18"
										height="100%"
										style="margin-right: 3px"
										:src="require(`@/assets/img/icon/${menu.image}.svg`)"
									/>
									{{ menu.title }}
								</div>
							</v-col> -->

							<!-- <v-col
								class="pa-0 px-2 item clickCursor"
								cols="auto"
							>
								<div class="d-flex point">
									<v-img
										class="hidden-md-and-down"
										width="20"
										:src="require('@/assets/img/icon/icon_appbar_estimate.svg')"
									/>
									맞춤견적
								</div>
							</v-col> -->
							<template v-if="!isLogin">
								<v-col
									v-for="menu in userMenu[1]"
									:key="menu.title"
									class="pa-0 px-2 item clickCursor"
									cols="auto"
									@click="pushLink(menu)"
								>
									{{ menu.title }}
								</v-col>
							</template>

							<!-- 로그인시 -->
							<template v-else>
								<v-col
									class="pa-0 pl-2 item clickCursor"
									cols="auto"
								>
									<LayoutAppbarMypage
										:user="AUTH_GET_USER"
										:is-login="isLogin"
									/>
								</v-col>
							</template>
						</v-row>

						<!-- <LayoutAppbarUser
							:user="AUTH_GET_USER"
							:user-menu="userMenu"
							:is-login="isLogin"
						/>
						<LayoutAppbarScrap v-if="AUTH_GET_USER_AUTH === 'USER'" /> -->
					</v-col>

					<v-col
						class="pa-0 hidden-md-and-up"
						cols="2"
						sm="3"
						md="4"
						align="end"
						align-self="center"
					>
						<!-- 햄버거버튼 -->
						<v-btn
							class="btn_menu"
							icon
							width="30"
							@click="model = !model"
						>
							<v-img
								width="30"
								:src="require('@/assets/img/icon/icon_menu.svg')"
							/>
						</v-btn>
					</v-col>
				</v-row>

				<!-- 2단 -->
				<div class="hidden-sm-and-down">
					<v-row class="ma-0 d-flex justify-center depth_two">
						<!-- 전체 -->
						<div class="overlay_menu_btn">
							<LayoutAppbarOverlayMenu />
						</div>

						<!-- 나투스진 -->
						<div
							class="mt-2 logo px-2"
							@click="goNatus"
						>
							<v-img
								alt="Logo"
								class="shrink mt-1"
								contain
								:src="natusImage"
								:width="logoWidth"
								eager
								max-width="75"
							/>
						</div>

						<!-- 메뉴 -->
						<div>
							<v-row class="ma-0">
								<v-col
									v-for="(menu, i) in common_menus"
									:key="i"
									class="pa-0"
								>
									<LayoutAppbarMenu :menu="menu" />
								</v-col>
							</v-row>
						</div>
					</v-row>
				</div>
			</v-container>
		</div>
	</v-app-bar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import model from '@/mixins/model'
import common from '@/mixins/common'

export default {
	name: 'Appbar',
	mixins: [model, common],
	data: () => ({
		topMenu: null,
		logoWidth: 92,
		topbarHeight: 125,
		lastScrollTop: 0,
		scrollUp: true,
		logoImage: require('@/assets/logo_color.svg'),
		moLogoImage: require('@/assets/logo_tree.svg'),
		//natusImage: require('@/assets/img/natus/logo_natusjin.svg'),
		natusImage: require('@/assets/img/natus/logo_brand_jin.svg'),
		userMenu: [
			[
				{
					title: '마루추천',
					image: 'icon_appbar_camera',
					to: '/search/maruRecommend',
				},
				// {
				// 	title: '맞춤견적',
				// 	image: 'icon_appbar_estimate',
				// 	to: '/interior/price/inquire',
				// },
			],
			[
				{
					title: '로그인',
					to: '/login',
				},
				{
					title: '회원가입',
					to: '/login/signup',
				},
			],
		],
		items: [
			{ text: 'Real-Time', icon: 'mdi-clock' },
			{ text: 'Audience', icon: 'mdi-account' },
			{ text: 'Conversions', icon: 'mdi-flag' },
		],
	}),
	computed: {
		...mapGetters(['APP_GET_APP_STATE', 'AUTH_GET_USER', 'AUTH_GET_USER_AUTH']),
		isLogin() {
			let login = false
			if (this.AUTH_GET_USER_AUTH === 'USER' || this.AUTH_GET_USER_AUTH === 'AGENCY') return (login = true)
			return login
		},
		routerName() {
			return this.$route.name
		},
		recentlyRouterPath() {
			return this.$route.path
		},
	},
	watch: {
		APP_GET_APP_STATE(val) {
			// this.handleResize(val)
		},
	},
	created() {
		window.addEventListener('scroll', this.handleScroll)
	},
	mounted() {
		// this.handleResize(this.APP_GET_APP_STATE)
	},
	destroyed() {
		window.removeEventListener('scroll', this.handleScroll)
	},
	methods: {
		...mapActions(['AUTH_ACT_LOGOUT']),
		handleScroll() {
			const scrollTop = window.scrollY || window.pageYOffset
			if (scrollTop > this.lastScrollTop) {
				this.scrollUp = false
			} else if (scrollTop < this.lastScrollTop) {
				this.scrollUp = true
			}
			this.lastScrollTop = scrollTop
		},
		// handleScroll(e) {
		// 	if (this.APP_GET_APP_STATE === 'web') {
		// 		const scrollTop = e.target.scrollingElement.scrollTop
		// 		this.topbarHeight = scrollTop > 10 ? 76 : 125
		// 		this.logoWidth = scrollTop > 10 ? 92 : 92
		// 	}
		// },
		// handleResize(val) {
		// 	this.topbarHeight = val !== 'web' ? 50 : 125
		// 	this.logoHeight = val !== 'web' ? 56 : 37
		// 	this.logoWidth = val !== 'web' ? 70 : 92
		// },
		goHome() {
			if (this.routerName === 'Home') return this.$router.go(this.$router.currentRoute)
			this.$router.push('/')
		},
		goNatus() {
			if (this.routerName === 'Product_Brand_NatusJin') return // this.$router.go(this.$router.currentRoute)
			this.$router.push('/product/brand/jin')
		},
		pushLink(menu) {
			console.log('menu.to', menu.to, this.recentlyRouterPath)
			if (menu.title === '로그아웃') this.AUTH_ACT_LOGOUT()
			if (menu.title === '마루추천') {
				if (this.recentlyRouterPath === menu.to || this.recentlyRouterPath === '/search/maruRecommend/')
					window.location.reload()
			}
			this.$router.push(menu.to).catch(() => {})
		},
	},
}
</script>

<style scoped lang="scss">
.v-sheet.header {
	min-height: 0 !important;
	height: unset !important;
	border-bottom: 1px solid $color_gray_3 !important;
	box-sizing: border-box;
}
:deep(.v-toolbar__content) {
	padding: 0 !important;
}
.header {
	border-bottom: 1px solid $color_gray_4 !important;
	z-index: 12;
	:deep(.v-toolbar__content) {
		padding: 16px 0 0 !important;
		height: unset !important;
	}
	.wrapper {
		width: 100%;
	}
	.logo {
		cursor: pointer !important;
	}
	&.v-app-bar--is-scrolled {
		transition: all 0.3s;
		:deep(.v-toolbar__content) {
			box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
			.depth_two {
				display: none !important;
			}
		}
	}
	&.scrollUp {
		:deep(.v-toolbar__content) {
			.depth_two {
				display: flex !important;
			}
		}
	}
}

.depth_one {
	.util {
		.item {
			position: relative;
			font-size: $font_normal;
			color: $color_gray_7;
			&:nth-child(2) {
				color: $color_font !important;
				font-weight: $fw_regular !important;
			}
			.point {
				//padding: 2px 10px !important;
				font-weight: $fw_bold;
				color: #00ce7d;
				display: flex;
				align-items: center;
				// background: linear-gradient(92.38deg, #00ce7d -23.88%, #14aaff 105.96%);
				border-radius: 99px;
			}
			&:last-child {
				&::after {
					display: none;
				}
			}
			&::after {
				display: block;
				position: absolute;
				top: 5px;
				right: -2px;
				width: 2px;
				height: 12px;
				background-color: $color_gray_2;
				content: '';
			}
		}
	}
}

// 전체메뉴
.overlay_menu_btn {
	display: inline-block;
}

@media all and (max-width: 1280px) {
	.overlay_menu_btn {
		display: none;
	}
}

@media all and (max-width: 959px) {
	.v-sheet.header {
		:deep(.v-toolbar__content) {
			padding: 0 !important;
			// height: 50px !important;
		}
	}
	.depth_one {
		padding: 0 !important;
		.search_bar.v-text-field {
			:deep(.v-input__slot) {
				min-height: 30px !important;
			}
		}
	}
	.btn_menu {
		:deep(.v-image) {
			max-width: 24px !important;
		}
	}
}
</style>
