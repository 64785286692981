<template>
  <!-- <v-card
		height="470"
		class="best_product pa-2 pa-sm-3 pa-md-8"
	>
		<div class="pt-6 pt-sm-5 pt-md-0 head">
			<p class="mb-2 title tac">베스트제품</p>
			<p class="mb-5 subtitle tac">동화자연마루 베스트제품</p>
		</div>
		<v-container class="pa-0">
			<v-carousel
				v-if="items.length"
				height="340"
				hide-delimiter-background
				:show-arrows="false"
			>
				<v-carousel-item
					v-for="(slide, i) in filterItems"
					:key="i"
				>
					<v-col
						v-for="(item, i) in slide"
						:key="i"
						class="ma-0 mb-2 content clickCursor"
						fill-height
						align="start"
						cols="4"
					>
						<v-chip-group v-model="model">
							<v-chip
								color="white"
								class="hash"
								@click="onClickPushLink(item)"
							>
								{{ item.tag_value }}
							</v-chip>
						</v-chip-group>
					</v-col>
				</v-carousel-item>
			</v-carousel>
		</v-container>
	</v-card> -->
  <div class="mb-5 mx-0 item">
    <v-col class="pa-0 bg_maru_tv">
      <!-- <div class="category">
        <div class="tit">
          <router-link
            tag="div"
            to="/community/maruTv"
            class="mb-3 d-flex clickCursor"
          >
            <v-img
              :src="require('@/assets/img/home/main/img_trend_hash.png')"
              max-width="29"
              height="100%"
            />
            <p class="mx-1">주목받는 트렌드</p>           
          </router-link>
          <span>
            원하는 테마의 추천 태그를 선택해 제품을 찾아보세요!<br
              class="hidden-sm-and-down"
            />
          </span>
        </div>
      </div> -->
    </v-col>

    <v-col class="px-4 px-lg-5 pt-lg-7 cont">
      <div class="category">
        <div class="tit">
          <a
            href="javascript:;"
            class="mb-3 d-flex align-center clickCursor"
          >
            <v-img
              :src="require('@/assets/img/home/main/img_trend_hash.svg')"
              max-width="18"
              height="100%"
            />
            <p class="mx-1">주목받는 트렌드</p>           
          </a>
          <span class="color_gray_6">
            요즘 가장 많이 찾는 마루를 찾아보세요!<br
              class="hidden-sm-and-down"
            />
          </span>
        </div>
      </div>
      <v-row class="categoryList ma-0">
        <v-col
          v-for="(item, i) in items"
          :key="i"
          cols="4"
          md="4"
          class="listItem"
          @click="onClickPushLink(item)"
        >
          <div class="pa-2 categoryItem">
            <a
              class="trendlink"
            >
              <v-img 
                :src="item.tagimage_url"
                width="56"
                height="56" 
              ></v-img>
              <p class="pt-2">{{ item.tag_value }}</p>
            </a>
          </div>
        </v-col>
      </v-row>
      <!-- <v-container class="pa-0">
				<v-carousel
					v-if="items.length"
					height="340"
					hide-delimiter-background
					:show-arrows="false"
				>
					<v-carousel-item
						v-for="(slide, i) in filterItems"
						:key="i"
					>
						<v-col
							v-for="(item, i) in slide"
							:key="i"
							class="ma-0 mb-2 content clickCursor"
							fill-height
							align="start"
							cols="4"
						>
							<v-chip-group v-model="model">
								<v-chip
									color="white"
									class="hash"
									@click="onClickPushLink(item)"
								>
									{{ item.tag_value }}
								</v-chip>
							</v-chip-group>
						</v-col>
					</v-carousel-item>
				</v-carousel>
			</v-container> -->
      <!-- <v-carousel height="200" hide-delimiter-background show-arrows-on-hover>
        <v-carousel-item v-for="(slide, i) in filterItems" :key="i">          
          <div style="height: 100%">
            <div
              class="d-flex align-content-center flex-wrap"
              min-height="200"
              align="center"
              justify="center"
              style="
                margin: 0 auto;
                justify-content: flex-start;
                width: 80%;
                height: 100%;
              "
            >
              <div
                v-for="(item, i) in slide"
                :key="i"
                class="py-2"
                style="width: 33.3%"
              >
                <v-col
                  cols="12"
                  class="pa-0 clickCursor w-auto"
                  @click="onClickPushLink(item)"
                >
                  <v-img
                    eager
                    :src="`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZto9TAbU-xWv0A9nnd0vSWmvsn5ROf069VQ&usqp=CAU`"
                    width="50"
                    height="50"
                    class="card_type1_img"
                  />
                  <span>
                    {{ item.tag_value }}
                  </span>
                </v-col>               
              </div>
            </div>
          </div>
        </v-carousel-item>
      </v-carousel> -->

    </v-col>
  </div>
</template>

<script>
import common from "@/mixins/common";
import google_analytic from "@/mixins/google_analytic";

export default {
  name: "HomeMainNewCategory",
  props: ["items"],
  mixins: [common, google_analytic],
  data: () => ({
    model: null,
  }),
  computed: {
    filterItems() {
      let items = [];
      let item = [];
      this.items.forEach((element, i) => {
        if (i % 6 === 0) {
          item = [];
          items.push(item);
        }
        item.push(element);
      });
      return items;
    },
  },
  created() {},
  mounted() {},
  methods: {
    // pushLink(item) {
    // 	this.$router.push(`/product/detail/${item.product_id}`)
    // },
    onClickPushLink(item) {
      this.google_analytic_push_event("click_main_tag_button", {
        button_location: item.tag_value,
      });
      this.common_searchTag(item);
    },
  },
};
</script>

<style scoped lang="scss">
.item {
  border-radius: 16px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  overflow: hidden;

  .category {
    color: $color_font;
    a {
      color: $color_font;
      text-decoration: none;
      p {
        font-weight: $fw_bold;
        line-height: 1 !important;
        font-family: 'NotoSansKR-Bold' !important;
        font-size: $font_tit !important;
      }
      span {
        word-break: keep-all;
        font-size: $font_normal;
        font-weight: $fw_normal;
      }   
    } 
  }
  .categoryList {
    padding:20px 0 20px 0;
    .trendlink {
      text-decoration: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding:0 !important;
      p {
        color:$color_gray_6;
      }
    }
    .listItem {
      // &:nth-of-type(3n+1){
      //   display:flex;
      //   justify-content: start;
      // }
      // &:nth-of-type(3n+3){
      //   display:flex;
      //   justify-content: end;
      // }
      // &:nth-of-type(n+4){
      //   display:flex;
      //   justify-content: start;
      // }
      padding:0;
      .categoryItem {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        p {
          text-decoration: none;
          text-align: center;
        }
      }
    }    
  }

  .cont {
    position: relative;
    margin: 0 auto;
    margin-top: -20px;
    border-radius: 16px !important;
    background-color: $color_white;
    h3 {
      color: $color_font;
      font-size: $font_xl;
      font-weight: $fw_bold;
    }
  }

  .bg_maru_tv {
    background-size: auto;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 50px;
    background: linear-gradient(43.15deg, #00CE7D 41.47%, #14AAFF 109.23%)
  }
}

@media all and (max-width: 1264px) {
  .item {
    .cont {
      margin-top: 0 !important;
      border-radius: 0 !important;
    }
    .bg_maru_tv {
      padding: 20px 16px !important;
    }

    .categoryList {     
      .listItem {       
        .categoryItem {
          padding: 0 !important;
        }
      }    
    }
  }
}
@media all and (max-width: 600px) {
	.item{
		border-radius: 0 !important;	
	}
}
@media all and (max-width: 380px) {
  .item {
    .category {
      p {
        font-size: $font_xl !important;
        line-height: 22px !important;
      }
      span {
        font-size: $font_sm !important;
      }
    }
    .cont {
      h3 {
        font-size: $font_lg !important;
      }
    }

    .main_youtube {
      .desc {
        font-size: $font_normal !important;
      }
    }
  }
}
</style>
