<template>
	<v-alert
		v-model="internalDialog"
		width="600"
		class="mainPopup"
	>
		<v-card class="pa-0 mainPopupCont">
			<v-col
				cols="3"
				class="pa-0 closebt"
				@click="close"
			>
				<v-img
					:src="require('@/assets/img/home/main/main_pop_closed.svg')"
					width="26"
					eager
				/>
			</v-col>
			<v-carousel
				ref="carousel"
				v-model="currentIndex"
				cycle
          		:show-arrows="false"
				:interval="5000"
				:continuous="true"
				delimiter-icon="mdi-color-helper"
				hide-delimiters
				hide-delimiter-background
				class="slide_type01"
				height="100%"
			>
				<v-carousel-item
					v-for="(item, i) in items"
					:key="i"
					:class="item.popuplink_url ? 'clickCursor' : ''"
					eager
					:ripple="!!item.popuplink_url"
					@click="onClickPushLink(item.popuplink_url)"
				>
					<v-row class="ma-0">
						<!-- 컨텐츠 : 이미지 -->
						<v-col
							v-if="item.popup_kind === 'IMAGE'"
							class="pa-0 popup_item"
						>
							<v-img
								cover
								:src="item.popup_url"
							/>
						</v-col>

						<!-- 컨텐츠 : 에디터내용 -->
						<v-col
							v-if="item.popup_kind === 'EDITOR'"
							class="pa-0 popup_item"
						>
							<v-col class="pa-5 pa-sm-8 popup_item_editor">
								<v-col class="pa-3 pt-sm-5 px-sm-7 inner">
									<v-img
										class="mb-7"
										width="88"
										:src="require('@/assets/logo_color.svg')"
										eager
									/>
									<p v-html="item.description"></p>
								</v-col>
							</v-col>
						</v-col>

						<!-- 컨텐츠 : 유튜브 -->
						<v-col
							v-if="item.popup_kind === 'VIDEO'"
							class="pa-0 popup_item"
						>
							<v-col class="pa-0 popup_item_youtube">
								<div
									class="youtube_container"
									:class="isPlay ? 'play' : 'stop'"
									style="width: 100%"
									@click="play"
								>
									<iframe
										:id="`youtube_player${i}`"
										:src="`${item.popup_url}?enablejsapi=1&mute=1`"
										frameborder="0"
										width="100%"
										height="100%"
									/>
								</div>
							</v-col>

							<!-- <HomeCommunityItemsYoutubeIframe :video-id="item.popup_url.split('/').pop()" /> -->
						</v-col>
					</v-row>
				</v-carousel-item>
			</v-carousel>
			<div class="carousel-nav">
				<button
					@click="prev(currentIndex)"
				>
					<v-img
						:src="require('@/assets/img/home/main/img_slide_left_arrow.svg')"
						eager
					/>
				</button>
				<span class="page_count">
					<em>{{ currentIndex + 1 }}</em> / {{ items.length }}
				</span>
				<button
					@click="next(currentIndex)"
				>
					<v-img
						:src="require('@/assets/img/home/main/img_slide_right_arrow.svg')"
						eager
					/>
				</button>
			</div>

			<!-- 하단버튼 -->
			<v-row
				class="ma-0 bottom_btn"
				align="center"
			>
				<v-col
					cols="12"
					class="pa-0"
					@click="noPopupOneDay"
				>
					<v-btn
						class="one_day"
						block
						tile
						color="#fff"
						@click="noPopupOneDay"
					>
						오늘 하루 그만 보기
					</v-btn>
				</v-col>				
			</v-row>
		</v-card>
	</v-alert>
</template>

<script>
export default {
	name: 'CommonDialogAppDialogNew',
	props: ['dialog', 'items'],
	data: () => ({
		internalDialog: false,
		current: 0,
		isPlay: false,
		player: null,
		currentIndex: 0,
	}),
	computed: {		
		currentTitle() {
         return this.items[this.currentIndex]
     	},
	},
	watch: {
		dialog(newValue) {
			this.internalDialog = newValue
		},
		internalDialog(newValue) {
			this.$emit('update:dialog', newValue)
		},
		current(cur) {
			if (this.player) this.stop()
			if (this.items[cur].popup_kind === 'VIDEO') {
				this.player = document.getElementById(`youtube_player${cur}`)
			}
		},
	},
	created() {
		this.internalDialog = this.dialog
	},
	async mounted() {},
	destroyed() {},
	methods: {
		noPopupOneDay() {
			if (this.items[0].popuplink_url === 'popup') this.$cookies.set('careflo_event_popup', 'false', '1d')
			else this.$cookies.set(this.items[0].location, 'false', '1d')
			this.close()
		},
		onClickPushLink(link) {
			if (link === 'popup') this.$emit('openPopup')
			else if (link.length) location.href = link
		},
		close() {
			if (this.player) this.stop()
			this.$emit('close')
		},
		play() {
			this.isPlay = true
			this.player.contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*')
		},
		stop() {
			this.player.contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*')
			this.player = null
		},
		next() {
			this.currentIndex += 1
		},
		prev() {
			this.currentIndex -= 1
		},
	},
}
</script>

<style scoped lang="scss">
// 팝업리셋
:deep(.v-dialog) {
	border-radius: 0 !important;
}
.v-sheet.v-card {
	border-radius: 0 !important;
}

// 컨텐츠 공통
.closebt {
	position:absolute;
	z-index: 24;

}
.popup_item {
	display: flex;
	align-items: center;
	width: 100%;
	max-height: 600px;
	aspect-ratio: 1/1;
	overflow-y: auto;
	overflow-x: hidden;
	word-break: keep-all;
	border-radius: 0 !important;
	// &::-webkit-scrollbar {
	// 	width: 5px;
	// }
	// &::-webkit-scrollbar-thumb {
	// 	background-color: $color_gray_6;
	// 	border-radius: 25px;
	// }
	.v-image {
		width: 100%;
		height: auto;
	}
}


// 슬라이드
:deep(.v-carousel__controls) {
	height: 30px !important;
	.v-item-group {
		.v-btn {
			.v-btn__content {
				i {
					width: 10px;
					height: 10px;
					border-radius: 50%;
					background-color: #d9d9d9;
					opacity: 1;
					&::before {
						display: none;
					}
					&::after {
						display: none;
					}
				}
			}
			&.v-btn--active {
				.v-btn__content {
					i {
						background-color: $color_green_5;
					}
				}
			}
		}
		.v-btn--icon {
			width: 5px;
			height: 0;
		}
	}
}

// 컨텐츠 : 에디터
:deep(.popup_item_editor) {
	width: 100%;
	height: 100%;
	background-color: $color_gray_1 !important;
	.inner {
		height: 100%;
		max-height: 540px;
		overflow-y: auto;
		background-color: $color_white !important;
		border-radius: 16px;
	}
	.ql-align-left {
		text-align: left;
	}
	.ql-align-right {
		text-align: right;
	}
	.ql-align-center {
		text-align: center;
	}
	.ql-align-justify {
		text-align: justify;
	}
}

// 컨텐츠 : 유튜브
:deep(.popup_item_youtube) {
	position: relative;
	width: 100%;
	height: 100%;
	background-color: #333 !important;
	.youtube_container {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		aspect-ratio: 16/9;
		&::before {
			display: block;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.3);
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
		&.play {
			&::before {
				display: none;
			}
		}
	}
}

.mainPopup {
	width:auto !important;
	position: fixed;
	z-index: 999;
	bottom: 0;
	left: 0;    
	margin-bottom:0;
	padding: 0;
	box-shadow: 0px 0px 8px 0px #00000066;
	background:none;
	.mainPopupCont {
		border-radius: 16px !important;
		overflow: hidden;	
		position: relative;
		.closebt {
			width: auto;    
			right: 10px;
			top: 10px;	
			&:hover {
				cursor:pointer;
			}
		}
		.carousel-nav {
			z-index: 999;
			width: 78px;
			height: 26px;
			right: 15px;
			bottom:65px;
			background: #00000033;
			border-radius: 220px;
			gap: 10px;
			margin:0 auto;
			display:flex;
			justify-content: center;
			align-items: center;    
			position: absolute;    
			//left: 50%;
			//transform: translateX(-50%);
			.page_count {
				color:#ffffff;
				font-weight:$fw_normal;
				font-size: $font_sm;
				em {
					font-style:normal;
					font-weight:$fw_semibold;
				}
			}
		}
	}
	.popup_item {
		max-height:320px;
		height:320px;
		width:320px;
	}
}

// 팝업하단버튼
.bottom_btn {
	.v-btn {
		height: 50px;
		box-shadow: none !important;
		:deep(.v-btn__content) {
			font-size: $font_normal;
			font-weight: $fw_bold;
			color: $color_white;
		}
		&.one_day {
			:deep(.v-btn__content) {
				display: inline !important;
				text-align: center !important;
				font-weight: $fw_normal !important;
				color: $color_gray_5 !important;
			}
		}
	}
}

@media all and (min-width: 2026px) {
	.mainPopup {
		transform: translatex(-310%);
		left: 50%;	
		margin-bottom:16px;    
	}
}

@media all and (max-width: 600px) {
	.mainPopup {
		:deep(.v-alert__content){
			width:100%;
		}
		.popup_item {
			aspect-ratio: 1/1;
			max-height: max-content;
    		height: auto;
		}
	}
	:deep(.popup_item_editor) {
		.inner {
			.v-image {
				width: 47px !important;
			}
		}
	}
	:deep(.v-carousel__controls) {
		height: 22px !important;
	}

	.mainPopup {
		width: 100% !important;
		left: 0;
		bottom: 0;
		margin-bottom: 0;
		.mainPopupCont {
			border-radius: 16px 16px 0 0 !important;
			z-index: 33;
		}
		// &::after {
		// 	content:'';
		// 	width:100%;
		// 	height:100%;
		// 	position:absolute;
		// 	z-index: 22;
		// 	background:red;
		// 	top:0;
		// 	left:0;
		// }
	}
}
</style>
