<template>
	<div>
		<v-col
			cols="12"
			class="pa-0"
		>
			<div class="form">
				<p
					class="mb-1"
					align="start"
				>
					이름
				</p>
				<CommonInputsInput02
					v-model="name"
					placeholder="이름"
				/>

				<div v-if="!isSns">
					<p
						class="mb-1"
						align="start"
					>
						이메일 주소(회원 ID로 사용)
					</p>
					<v-row class="ma-0 mb-5">
						<v-col
							class="pa-0"
							cols="7"
						>
							<div class="pr-1">
								<CommonInputsInput04
									v-model="email"
									rule-name="email"
									height="40"
									placeholder="이메일 주소"
								/>
							</div>
						</v-col>
						<v-col
							class="pa-0"
							cols="5"
						>
							<div>
								<CommonButtonsButton01
									:disabled="email === null"
									name="중복체크"
									:color="idCheck ? 'primary' : '#262626'"
									class-name="font_normal"
									height="40"
									@click="checkId"
								/>
							</div>
						</v-col>
						<v-col
							cols="12"
							class="pa-0 mt-1 font_sm"
							align="start"
						>
							<p
								v-if="idCheck"
								style="color: #00ce7d"
							>
								사용가능한 아이디 입니다.
							</p>
						</v-col>
					</v-row>
				</div>

				<p
					class="mb-1"
					align="start"
				>
					휴대폰 번호
				</p>
				<v-row class="ma-0 mb-5">
					<v-col
						class="pa-0"
						cols="7"
					>
						<div class="pr-1">
							<CommonInputsInput04
								v-model="hand"
								rule-name="tel"
								height="40"
								placeholder="‘-’ 없이 숫자만 입력"
							/>
						</div>
					</v-col>
					<v-col
						class="pa-0"
						cols="5"
					>
						<div>
							<CommonButtonsButton01
								:disabled="hand === null"
								name="인증번호 발송"
								:color="smsNumberCheck ? 'primary' : '#262626'"
								class-name="font_normal"
								height="40"
								@click="checkSms"
							/>
						</div>
					</v-col>
					<v-col
						cols="12"
						class="pa-0 mt-1 font_sm"
						align="start"
					>
						<p
							v-if="smsNumberCheck"
							style="color: #00ce7d"
						>
							인증되었습니다.
						</p>
					</v-col>
					<CommonLoaderLine01 v-if="telLoading" />
				</v-row>

				<div v-if="smsNumberModel">
					<p
						class="mb-1"
						align="start"
					>
						인증번호
					</p>
					<v-row class="ma-0">
						<v-col
							class="pa-0"
							cols="7"
						>
							<div class="pr-1">
								<CommonInputsInput02
									v-model="smsNum"
									height="40"
									type="number"
									placeholder="인증번호를 입력"
								/>
							</div>
						</v-col>
						<v-col
							class="pa-0"
							cols="5"
						>
							<div>
								<CommonButtonsButton01
									name="인증 확인"
									color="#42883d"
									class-name="font_normal"
									height="40"
									@click="confirmSmsNumber"
								/>
							</div>
							<v-col
								cols="12"
								class="pa-0 pt-1 font_sm"
								align="end"
								style="color: #fa2a6f"
							>
								{{ sms_check_timer }}
							</v-col>
						</v-col>
					</v-row>
				</div>

				<div v-if="!isSns">
					<p
						class="mb-1"
						align="start"
					>
						비밀번호
					</p>
					<CommonInputsInput02
						v-model="password"
						type="password"
						rule-name="password"
						placeholder="8~12(영문,숫자,특수문자 조합)"
					/>

					<p
						class="mb-1"
						align="start"
					>
						비밀번호 확인
					</p>
					<CommonInputsInput02
						v-model="passwordConfirm"
						type="password"
						placeholder="비밀번호를 다시 입력해 주세요."
						:password-value="passwordCheck"
					/>
				</div>
			</div>
		</v-col>

		<v-divider class="my-2" />

		<v-col class="pa-0">
			<p class="notice mt-5 mb-10">
				입력된 휴대폰번호는 비밀번호찾기, A/S신청 등<br />
				서비스 이용 시 안내하는 용도로 사용되며,<br />
				자세한 사항은 <span>개인정보처리방침</span> 내용을 확인 부탁 드립니다.
			</p>
		</v-col>

		<v-col class="pa-0">
			<CommonButtonsButton01
				name="가입하기"
				color="#00582c"
				height="50"
				class-name="font_lg fw_bold"
				:disabled="allowValue"
				rounded
				@click="saveUserInfo"
			/>
			<CommonButtonsButton02
				name="로그인 페이지로 이동 >"
				height="50"
				class-name="btn_more mt-3"
				color="#222"
				text
				@click="$router.push('/login')"
			/>
		</v-col>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import model from '@/mixins/model'
import sms from '@/mixins/sms'

export default {
	name: 'LoginSignupUserUserInfo03',
	mixins: [model, sms],
	data: () => ({
		name: null,
		email: null,
		hand: null,
		smsNum: null,
		password: null,
		idCheck: null,
		passwordConfirm: null,
		// sms
		smsNumberCheck: null,
		smsNumberModel: false,
		smsCheckId: null,
		telLoading: false,
	}),
	computed: {
		...mapGetters(['AUTH_GET_SIGNUP_SNS_INFO', 'AUTH_GET_EVENT_INFO']),
		allowValue() {
			let check = false
			let checkValue
			if (this.isSns) {
				checkValue = ['name', 'hand', 'smsNumberCheck']
			} else {
				checkValue = ['name', 'email', 'hand', 'password', 'idCheck', 'smsNumberCheck', 'passwordCheck']
			}

			for (let i of checkValue) {
				if (this[i] === null || this[i] === false) check = true
			}

			return check
		},
		passwordCheck() {
			let val = false
			if ((this.password?.length >= 8 && this.password?.length <= 12) || this.passwordConfirm?.length >= 8) {
				val = this.password === this.passwordConfirm ? true : false
			}
			return val
		},
		isSns() {
			return Object.keys(this.AUTH_GET_SIGNUP_SNS_INFO).length
		},
	},
	watch: {
		email(val) {
			if (val === null) this.idCheck = null
		},
		sms_check_timer(val) {
			if (val === '00:00') {
				this.smsNumberCheck = null
				this.smsNumberModel = false
			}
		},
	},
	mounted() {},
	methods: {
		...mapActions(['AUTH_ACT_SIGN_UP', 'AUTH_ACT_SIGN_UP_CHECK']),
		async checkId() {
			this.idCheck = false
			const params = {
				login_id: this.email,
			}

			const items = { method: 'user_id', params: params }
			await this.AUTH_ACT_SIGN_UP_CHECK(items).then(res => {
				if (res) this.idCheck = true
				if (res == undefined || res == null) {
					this.$toastr.error('이미 사용중인 ID입니다.', 'ID 중복 오류', { timeOut: 2500 })
				}
			})
		},
		async checkSms() {
			this.telLoading = true
			let params = {
				type: this.sms_check_type[5],
				hand: this.hand,
				name: this.name,
				login_id: this.email,
			}
			const check = await this.sms_call(params)
			if (check) {
				this.telLoading = false
				this.smsNumberModel = true
			}
		},
		async confirmSmsNumber() {
			const check = await this.sms_call_check(this.hand, this.smsNum)
			if (check) {
				this.smsNumberCheck = true
				this.smsNumberModel = false
			}
		},
		async saveUserInfo() {
			const params = {
				name: this.name,
				hand: this.hand,
				email_yn: this.AUTH_GET_EVENT_INFO.email ? 'Y' : 'N',
				sms_yn: this.AUTH_GET_EVENT_INFO.sms ? 'Y' : 'N',
			}

			let mode
			if (this.isSns) {
				// sns 가입
				params.sns_kind = this.AUTH_GET_SIGNUP_SNS_INFO.code
				params.sns_token = this.AUTH_GET_SIGNUP_SNS_INFO.token

				mode = 'sns'
			} else {
				// 일반 가입
				params.login_id = this.email
				params.password = this.password

				mode = 'user'
			}

			const items = { method: mode, params: params }
			console.log('params', items)

			await this.AUTH_ACT_SIGN_UP(items).then(res => {
				console.log('AUTH_ACT_SIGN_UP', res)
				if (res) this.model = 3
			})
		},
	},
}
</script>

<style scoped lang="scss">
.notice {
	font-size: $font_mid;
	color: #5f6062;
	word-break: keep-all;
	span {
		font-weight: $fw_regular;
		color: $color_font;
		border-bottom: 1px solid $color_font;
	}
}
</style>
