<template>
	<v-dialog
		v-model="dialog"
		persistent
		width="1000"
	>
		<v-card class="pa-0">
			<AdminItemsTitle
				:title="`${title} NFC Code 보기`"
				@close="$emit('close')"
			/>
			<v-col class="pa-3 pa-md-5">
				<v-container class="admin_table">
					<h3 class="py-3 pl-2">NFC Code 정보</h3>
					<v-row class="ma-0 admin_table_area">
						<v-col
							cols="12"
							md="6"
							class="pa-0"
						>
							<AdminItemsTableItem
								:text="readItem.nfc_code"
								title="NFC Code"
							/>
						</v-col>
						<v-col
							cols="12"
							md="6"
							class="pa-0"
						>
							<AdminItemsTableItem
								title="NFC URL"
								:text-button="nfc_url"
							/>
						</v-col>
					</v-row>
					<h3 class="py-3 pl-2">시공정보</h3>
					<v-row class="ma-0 admin_table_area">
						<v-col
							cols="12"
							md="6"
							class="pa-0"
						>
							<AdminItemsTableItem
								title="시공일자"
								:text="readItem.operator_date"
							/>
						</v-col>
						<v-col
							cols="12"
							md="6"
							class="pa-0"
						>
							<AdminItemsTableItem
								title="시공자 연락처"
								:text="readItem.operator_hand"
							/>
						</v-col>
						<v-col
							cols="12"
							md="12"
							class="pa-0"
						>
							<AdminItemsTableItem
								title="서비스 구분"
								:text="readItem.care_type"
								:fill="true"
							/>
						</v-col>
						<v-col
							cols="12"
							md="12"
							class="pa-0"
						>
							<AdminItemsTableItem
								title="바코드 정보"
								:text="readItem.barcode"
								:fill="true"
							/>
						</v-col>
						<v-col
							cols="12"
							md="12"
							class="pa-0"
						>
							<AdminItemsTableItem
								title="이미지"
								:text-button="image"
								:fill="true"
								@click="showImage"
							/>
						</v-col>
					</v-row>
					<h3 class="py-3 pl-2">고객정보</h3>
					<v-row class="ma-0 admin_table_area">
						<v-col
							cols="12"
							md="12"
							class="pa-0"
						>
							<AdminItemsTableItem
								title="고객명"
								:text="readItem.cust_name"
								:fill="true"
							/>
						</v-col>
						<v-col
							cols="12"
							md="12"
							class="pa-0"
						>
							<AdminItemsTableItem
								title="고객연락처"
								:text="readItem.cust_hand"
								:fill="true"
							/>
						</v-col>
						<v-col
							cols="12"
							md="12"
							class="pa-0"
						>
							<AdminItemsTableItem
								title="고객주소"
								:text="readItem.address"
								:fill="true"
							/>
						</v-col>
						<v-col
							cols="12"
							md="12"
							class="pa-0"
						>
							<AdminItemsTableItem
								title="고객 정보 등록일"
								:text="readItem.regi_date"
								:fill="true"
							/>
						</v-col>
						<v-col
							cols="12"
							md="12"
							class="pa-0"
						>
							<AdminItemsTableItem
								title="A/S 신청 횟수"
								:text="readItem.as_count?.toString()"
								:fill="true"
							/>
						</v-col>
					</v-row>
				</v-container>
				<v-row
					class="ma-0"
					align="center"
					justify="end"
				>
					<v-col
						cols="12"
						sm="4"
						md="3"
						lg="2"
					>
						<v-btn
							color="error"
							block
							tile
							elevation="0"
							@click="$emit('close')"
						>
							<v-icon>clear</v-icon>
							<span class="ml-2">닫기</span>
						</v-btn>
					</v-col>
				</v-row>
			</v-col>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import model from '@/mixins/model'
import common from '@/mixins/common'
import admin from '@/mixins/admin'

export default {
	name: 'AdminUserCareFloReadNfc',
	mixins: [model, common, admin],
	props: ['title', 'dialog', 'readItem'],
	data: () => ({
		nfc_url: null,
		image: null,
	}),
	computed: {},
	watch: {},
	created() {
		console.log('this.readItem', this.readItem)
		this.nfc_url = {
			text: this.readItem.nfc_code,
			button: {
				text: 'NFC 링크',
				color: 'primary',
				link: window.location.origin + '/careflo/' + this.readItem.nfc_code,
			},
		}
		this.image = {
			text: this.readItem.barcode_url,
			button: {
				text: '이미지 보기',
				color: 'primary',
				link: this.readItem.barcode_url,
			},
		}
	},
	mounted() {},
	destroyed() {},
	methods: {
		showImage() {
			if (this.readItem.barcode_url.length > 0) {
				const base64Image = this.readItem.barcode_url
				const newTab = window.open()
				newTab.document.write(`<img src="${base64Image}" alt="Barcode Scan Image">`)
			}
		},
	},
}
</script>

<style scoped lang="scss"></style>
