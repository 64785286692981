<template>
	<div class="text-center pagination">
		<v-pagination
			v-model="currentNumber"
			:length="totalPages"
			:total-visible="itemsPerPage"
			@input="changePage"
		></v-pagination>
	</div>
</template>

<script>
export default {
	props: {
		currentPage: {
			type: Number,
			required: true,
		},
		totalPages: {
			type: Number,
			required: true,
		},
		itemsPerPage: {
			type: Number,
			required: true,
		},
	},
	data: () => ({
		currentNumber: 1,
	}),
	methods: {
		changePage(pageNumber) {
			this.$emit('changePage', this.currentNumber)
		},
	},
}
</script>

<style lang="scss" scoped>
.pagination {
	display: flex;
	justify-content: center;
	margin-top: 20px;
	:deep(.v-pagination) {
		.v-pagination__item {
			background: #fff;
			box-shadow: none !important;
			color: #333;
			padding: 5px 10px;
			cursor: pointer;
			margin: 0 4px;
			width: 32px;
			height: 32px;
			&:hover {
				background-color: #00ce7d;
				color: #fff;
				border-radius: 4px;
			}
		}

		.v-pagination__item--active {
			background-color: #00ce7d !important;
			color: #fff !important;
		}
		.v-pagination__navigation {
			border: 1px solid #d8d8d8;
			border-radius: 4px;
			box-shadow: none !important;
		}
	}
}

.pagination-button {
	//border: 1px solid #ccc;
	background-color: #fff;
	color: #333;
	padding: 5px 10px;
	cursor: pointer;
	margin: 0 4px;
	&:hover {
		background-color: #00ce7d;
		color: #fff;
		border-radius: 4px;
	}
	&:first-child {
		border: 1px solid #d8d8d8;
		border-radius: 4px;
		&:hover {
			background-color: transparent;
		}
	}
	&:last-child {
		border: 1px solid #d8d8d8;
		border-radius: 4px;
		&:hover {
			background-color: transparent;
		}
	}
}

.pagination-button-active {
	background-color: #00ce7d;
	color: #fff;
	border-radius: 4px;
}

.pagination-button-disabled {
	cursor: not-allowed;
	opacity: 0.5;
}
</style>
