<template>
	<v-dialog
		:value="dialog"
		persistent
		:width="width"
		height="100%"
		scrollable
		fullscreen
	>
		<v-card class="pa-0">
			<!-- Dialog header -->
			<v-row
				class="ma-0 px-4 py-2 dialog_dynamic_header"
				align="center"
			>
				<v-col
					class="pa-0"
					cols="10"
					align="start"
				>
					<v-col
						v-if="title"
						class="pa-0 dialog_dynamic_title"
					>
						{{ title }}
					</v-col>
				</v-col>
				<v-col
					class="pa-0"
					cols="2"
					align="end"
				>
					<v-btn
						icon
						class="pa-0"
						@click="closeDialog"
					>
						<v-icon
							color="#666"
							medium
						>
							mdi-window-close
						</v-icon>
					</v-btn>
				</v-col>
			</v-row>

			<!-- 컴포넌트 -->
			<Component
				:is="componentInstance"
				ref="child"
				v-model="model"
				:type="type"
				:item="item"
				class="component"
				@modify="modify"
				@close="closeDialog"
			></Component>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import model from '@/mixins/model'
export default {
	name: 'CommonDialogDialogDynamicBasic',
	mixins: [model],
	props: {
		title: {
			type: String,
			default: '',
		},
		width: {
			type: Number,
			default: 400,
		},
		componentsPath: {
			type: String,
			default: '',
		},
		type: {
			type: String,
			default: 'read',
		},
		item: {
			type: [Array, Object],
			default: () => [],
		},
		dialog: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapGetters({
			GetAppState: '@/app/appState',
		}),
		componentInstance() {
			return () => import(`@/components/${this.componentsPath}`)
		},
		appMobileState() {
			return this.GetAppState === 'mobile'
		},
	},
	methods: {
		modify(item) {
			this.$emit('modify', item)
		},
		closeDialog(e) {
			this.$emit('close', e)
		},
	},
}
</script>

<style scoped lang="scss">
:deep(.v-dialog) {
	.v-card {
		background-color: $color_gray_1;
	}
}
.dialog_dynamic_header {
	max-height: 50px;
	background-color: $color_white;
	border-bottom: 1px solid $color_gray_3;
	.dialog_dynamic_title {
		font-size: $font_lg;
		font-weight: $fw_bold;
		color: $color_font;
	}
}
.component {
	position: relative;
	height: 100%;
}
</style>
