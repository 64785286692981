<template>
	<v-btn-toggle
		v-model="model"
		mandatory
		class="d-inline flex"
	>
		<v-row
			class="ma-0"
			:class="type === 'two' ? 'type_btn_05_wrap' : 'type_btn_06_wrap'"
			justify="center"
		>
			<v-col
				v-for="(item, n) in typeItems"
				:key="n"
				:cols="cols !== undefined ? cols : 4"
				align="center"
				class="pa-0 type_btn_03 type_btn_05"
			>
				<v-btn
					:color="model === n ? 'primary rapidcalcbt rapidnum$' : 'white'"
					:outlined="model === n ? false : true"
					elevation="0"
					:class="`btn_toggle pa-0 rapidnum${n}`"
					:style="`height:${height}px !important`"
				>
					<v-col
						align="center"
						class="pa-0 px-5"
					>
						<v-img
							v-if="ico.length !== 0"
							eager
							width="18px"
							class="mx-auto mb-1"
							max-width="50"
							:src="require(`@/assets/img/estimate/${ico[n]}`)"
							alt=""
						/>
						<span
							class="type_btn_03_text font_lg"
							:class="model === n ? 'type_btn_03_text_select' : ''"
						>
							{{ item.title }}
						</span>
					</v-col>
				</v-btn>
			</v-col>
		</v-row>
	</v-btn-toggle>
</template>

<script>
import model from '@/mixins/model'

export default {
	name: 'TypeBtn06',
	mixins: [model],
	props: {
		typeItems: {
			Type: Number,
			require: true,
		},
		cols: {
			Type: Number,
			require: true,
		},
		ico: {
			Type: Array,
			default: '',
		},
		height: {
			Type: Number,
			default: '36',
		},
		type: {
			Type: String,
			default: '',
		},
	},
	data: () => ({}),
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
}
</script>

<style scoped lang="scss">
.type_btn_03 {
	.v-btn {
		border-radius: 0 !important;
		width: 100%;
		height: 50px !important;
		//background-color: $color_gray_1 !important;
		border: $color_gray_2 !important;
	}
	.v-btn.primary.rapidcalcbt {
		background-color: #fff !important;
		border: 1px solid $color_font !important;
	}

	.type_btn_03_text {
		color: $color_green_6 !important;
		font-family: 'NotoSansKR-Regular' !important;
		font-size: 16px !important;
		color: #666666 !important;
		&_select {
			font-family: 'NotoSansKR-Bold' !important;
			color: #000 !important;
		}
	}
}
.type_btn_05_wrap {
	border: 1px solid $color_gray_2;
	border-radius: 8px;
	background-color: #f8f8f8 !important;
	box-sizing: border-box;
	.rapidnum0.v-item--active {
		border-radius: 4px 0px 0px 4px !important;
		box-shadow: 0px 2px 8px 0px #00000026 !important;
	}
	.rapidnum1.v-item--active {
		border-radius: 0 4px 4px 0 !important;
		box-shadow: 0px 2px 8px 0px #00000026 !important;
	}
	.rapidnum2.v-item--active {
		border-radius: 0 4px 4px 0 !important;
		box-shadow: 0px 2px 8px 0px #00000026 !important;
	}
}
.type_btn_06_wrap {
	border: 1px solid $color_gray_2;
	border-radius: 4px;
	background-color: #f8f8f8 !important;
	box-sizing: border-box;
	.rapidnum0.v-item--active {
		border-radius: 4px 0px 0px 4px !important;
		box-shadow: 0px 2px 8px 0px #00000026 !important;
	}
	.rapidnum1.v-item--active {
		border-radius: 0 !important;
		box-shadow: 0px 2px 8px 0px #00000026 !important;
	}
	.rapidnum2.v-item--active {
		border-radius: 0 4px 4px 0 !important;
		box-shadow: 0px 2px 8px 0px #00000026 !important;
	}
}

// 빠른견적내기
.fast {
	.v-btn {
		height: 36px !important;
	}
	:deep(.v-icon) {
		display: none !important;
	}
	.type_btn_03_text {
		font-size: 14px !important;
	}
	.v-btn__content > div {
		text-align: center !important;
	}
}

@media all and (min-width: 960px) and (max-width: 1263px) {
}
@media all and (min-width: 600px) and (max-width: 959px) {
}
@media all and (max-width: 600px) {
	.type_btn_03 {
		.type_btn_03_text {
			font-size: 13px !important;
		}
	}
}
</style>
