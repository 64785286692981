<template>
	<div class="mb-5 mx-0 item">
		<!-- <v-col class="pa-0 bg_event">
			<div class="category">
				<div class="tit">
					<router-link
						tag="div"
						to="/search/maruRecommend"
						class="mb-3 d-flex clickCursor"
					>
						<v-img
							:src="require('@/assets/img/community/icon_event.svg')"
							max-width="27"
							height="100%"
						/>
						<p class="mr-1">마루추천서비스</p>
						<v-img
							:src="require('@/assets/img/community/arrow_white.svg')"
							max-width="22"
							height="100%"
						/>
					</router-link>

					<span> 내게 딱 맞는 마루 제품 추천 받기! </span>
				</div>
			</div>
		</v-col> -->
		<v-col class="cont">
			<div>
				<v-col class="py-3 recommend">
					<p class="tit tac">마루 추천 서비스</p>
					<v-col
						cols="12"
						class="pa-0"
					>
						<p class="tac color_gray_6">내게 딱 맞는 마루 제품 추천 받기!</p>
					</v-col>
				</v-col>
				<!-- 추천검색 필터 -->
				<v-row class="ma-0 mb-4 my-md-2 justify-center">
					<v-btn-toggle
						v-model="recommend_group"
						mandatory
						class="btn_recommend_group"
					>
						<v-btn
							checked
							class="recommendToggle"
						>
							<svg
								width="18"
								height="18"
								viewBox="0 0 31 30"
							>
								<path
									d="M6.75 26.25C6.0625 26.25 5.47396 26.0052 4.98438 25.5156C4.49479 25.026 4.25 24.4375 4.25 23.75V6.25C4.25 5.5625 4.49479 4.97396 4.98438 4.48438C5.47396 3.99479 6.0625 3.75 6.75 3.75H24.25C24.9375 3.75 25.526 3.99479 26.0156 4.48438C26.5052 4.97396 26.75 5.5625 26.75 6.25V23.75C26.75 24.4375 26.5052 25.026 26.0156 25.5156C25.526 26.0052 24.9375 26.25 24.25 26.25H6.75ZM6.75 23.75H24.25V6.25H6.75V23.75ZM8 21.25H23L18.3125 15L14.5625 20L11.75 16.25L8 21.25ZM11.125 12.5C11.6458 12.5 12.0885 12.3177 12.4531 11.9531C12.8177 11.5885 13 11.1458 13 10.625C13 10.1042 12.8177 9.66146 12.4531 9.29688C12.0885 8.93229 11.6458 8.75 11.125 8.75C10.6042 8.75 10.1615 8.93229 9.79688 9.29688C9.43229 9.66146 9.25 10.1042 9.25 10.625C9.25 11.1458 9.43229 11.5885 9.79688 11.9531C10.1615 12.3177 10.6042 12.5 11.125 12.5Z"
								/>
							</svg>
							<span class="prevent_break">사진으로 추천</span>
						</v-btn>

						<v-btn class="recommendToggle">
							<svg
								width="18"
								height="18"
								viewBox="0 0 31 30"
							>
								<path
									d="M15.5 26.25C12.7292 26.25 10.3698 25.2865 8.42188 23.3594C6.47396 21.4323 5.5 19.1042 5.5 16.375C5.5 15 5.76042 13.7292 6.28125 12.5625C6.80208 11.3958 7.52083 10.3542 8.4375 9.4375L15.5 2.5L22.5625 9.4375C23.4792 10.3542 24.1979 11.3958 24.7188 12.5625C25.2396 13.7292 25.5 15 25.5 16.375C25.5 19.1042 24.526 21.4323 22.5781 23.3594C20.6302 25.2865 18.2708 26.25 15.5 26.25ZM15.5 23.75V6L10.1875 11.25C9.45833 11.9375 8.91146 12.7135 8.54688 13.5781C8.18229 14.4427 8 15.375 8 16.375C8 18.3958 8.72917 20.1302 10.1875 21.5781C11.6458 23.026 13.4167 23.75 15.5 23.75Z"
								/>
							</svg>
							<span class="prevent_break">색상으로 추천</span>
						</v-btn>
					</v-btn-toggle>
				</v-row>

				<template v-if="recommend_group === 0">
					<!-- 사진으로 추천내용 -->
					<section class="py-0 px-0 pa-md-2 cont_recommend_picture">
						<HomeSearchPhotoReco :frommain="from_main" />
						<div class="text-center mt-4">
							<span
								class="all_view my-2"
								@click="openguide"
							>
								사진 촬영 가이드
							</span>

							<!-- <v-alert
								v-model="alert"
								close-text="Close Alert"
								class="all_view_list pa-6"
								elevation="7"
							>
								<div class="header d-flex justify-space-between mb-3">
									
									<v-btn @click="alert = false">
										<v-icon>close</v-icon>
									</v-btn>
								</div>
							</v-alert> -->
							<CommonDialogRecoguideDialog
								:dialog="popupDialog"
								@openPopup="eventDetailPop = true"
								@close="popupDialog = false"
							/>
						</div>
					</section>
				</template>

				<template v-else>
					<!-- 색상으로 추천내용 -->
					<section class="py-0 px-0 pa-md-2 cont_recommend_color">
						<HomeSearchColorReco :frommain="from_main" />
					</section>
				</template>		
			</div>
		</v-col>
	</div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
	name: 'CommunityHomeNewRecommend',
	components: {},
	data: () => ({
		recommend_group: 0,
		results_picture: null,
		results_color: null,
		from_main: true,
		popupDialog: false,
		eventDetailPop: false,
	}),
	computed: {
		...mapGetters(['HOME_GET_SEARCH_PICTURE_UPLOAD_FILE', 'HOME_GET_SEARCH_SELECTED_COLORS']),
	},
	watch: {},
	async created() {
		const data = this.HOME_GET_SEARCH_PICTURE_UPLOAD_FILE
		if (data) {
			if (data?.file) {
				this.callSearchItems({ picture: data?.file }, 'picture')
				this.recommend_group = 0
			} else this.results_picture = null
		}
	},
	mounted() {
	},
	methods: {
		...mapMutations(['HOME_MU_SEARCH_PICTURE_UPLOAD_FILE', 'HOME_MU_SEARCH_SELECTED_COLORS']),
		...mapActions(['HOME_ACT_SEARCH_RECOMMEND_LIST']),
		async callSearchItems(params, type) {
			await this.HOME_ACT_SEARCH_RECOMMEND_LIST(params).then(res => {
				if (res.result?.length > 0) {
					if (type === 'picture') this.results_picture = res
					else this.results_color = res
				} else {
					if (type === 'picture') {
						this.HOME_MU_SEARCH_PICTURE_UPLOAD_FILE({})
						this.results_picture = null
					}
					// this.APP_MU_DIALOG({
					// 	dialog: true,
					// 	text: '사진 업로드 실패하였습니다. 잠시 후 시도해주세요.',
					// })
				}
			})
		},
		openguide() {
			this.popupDialog = true
			this.eventDetailPop = true
		},
		close(e) {
			this.eventDetailPop = false
			if (e.target.className === 'closeBtn') this.popupDialog = false
		},
	},
}
</script>

<style scoped lang="scss">
.recommend {
	.tit {
		font-family: 'NotoSansKR-Bold' !important;
		font-size: $font_tit !important;
		color: #262626;
		.tac {text-align: left;}
	}
	.tac {text-align: left;}	
}

.recommendToggle {height:40px !important}	

.item {
	background-color: $color_white;
	border-radius: 16px;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
	overflow: hidden;

	.category {
		color: $color_white;
		p {
			font-size: $font_sub_tit;
			font-weight: $fw_bold;
			line-height: 1 !important;
		}
		span {
			word-break: keep-all;
			font-size: $font_normal;
			font-weight: $fw_normal;
		}
	}

	.cont {
		position: relative;
		border-radius: 16px !important;
		background-color: $color_white;
		h3 {
			color: $color_font;
			font-size: $font_xl;
			font-weight: $fw_bold;
		}
	}

	.bg_event {
		padding: 30px 20px 40px !important;
		background-color: $color_primary_1;
	}

	.event_list {
		.v-image {
			border-radius: 4px;
		}
		.tit {
			color: $color_font;
			font-size: $font_lg;
			font-weight: $fw_normal;
		}
		.text {
			color: $color_gray_5;
			font-size: $font_normal;
			font-weight: $fw_normal;
		}
	}
}
:deep(.v-window__prev) {
	position: absolute;
	top: 40%;
	left: -30px;
	background: rgba(0, 0, 0, 0.3);
	z-index: 99;
}
:deep(.v-window__next) {
	position: absolute;
	top: 40%;
	right: -30px;
	background: rgba(0, 0, 0, 0.3);
}
.v-carousel {
	overflow: inherit;
}

.btn_recommend_group {
	width: 100%;
	display: flex;
	justify-content: space-around;
  &.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
	border:1px solid $color_gray_2;
    border-color: $color_gray_2 !important;
	width: 46%;
  }
  &.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn--active {
    border-color: $color_state_posi !important;
  }
  :deep(.v-btn) {
    border-radius: 99px !important;
    background-color: $color_white;
    padding: 0 26px !important;
    svg {
      fill: $color_gray_4 !important;
    }
    .v-btn__content {
      font-size: $font_lg;
      color: $color_gray_6;
    }
    &.v-btn--active {
      &::before {
        display: none !important;
      }
      svg {
        fill: $color_state_posi !important;
      }
      .v-btn__content {
        font-weight: $fw_bold !important;
        color: $color_state_posi !important;
      }
    }
  }
}

.cont_recommend_picture {
	.all_view {
		text-decoration: underline;
		&:hover {
			cursor: pointer;
		}
	}
}

@media all and (max-width: 1264px) {
	.item {
		.cont {
			margin-top: 0 !important;
			border-radius: 0 !important;
		}
		.bg_event {
			padding: 20px 16px !important;
		}
	}
}

@media all and (min-width: 960px) and (max-width: 1200px) {
	.prevent_break {display:none;}
}

@media all and (min-width: 600px) and (max-width: 770px) {
	.prevent_break {display:none;}
}

@media all and (max-width: 600px) {
	.item{
		border-radius: 0 !important;	
	}
}


@media all and (max-width: 380px) {
	.item {
		.category {
			p {
				font-size: $font_xl !important;
				line-height: 22px !important;
			}
			span {
				font-size: $font_sm !important;
			}
		}
		.cont {
			margin-top: 0px !important;
			h3 {
				font-size: $font_lg !important;
			}
		}
		.event_list {
			.tit {
				font-size: $font_normal !important;
			}
		}
	}
}
</style>
