<template>
	<div>
		<v-row
			v-if="AGENCY_GET_ESTIMATE"
			class="ma-0"
			style="margin-bottom: 70px !important"
		>
			<v-col class="pa-0">
				<p class="txt">간편상담 신청내역을 확인하여 고객님과 상담을 진행해주세요!</p>
				<CommonCardMenuGroup02
					:menu-type="'agency_estimate'"
					:card-menu="paginatedItems"
					@submit="onClick"
				/>
			</v-col>
		</v-row>
		<v-row
			v-else
			justify="center"
			class="ma-0"
		>
			<UserItemEmpty
				text="'견적상담 내역이 없습니다."
				sub-text="'견적상담 요청 내역이 없습니다.'"
			/>
		</v-row>
		<div>
			<v-col
				cols="12"
				class="pa-0 register_history inner"
				align="center"
			>
				<div>
					<CommonPaginationListPagination03
						:total-pages="totalPages"
						:items-per-page="itemsPerPage"
						:current-page="currentPage"
						@changePage="handlePageChange"
					/>
				</div>
			</v-col>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
	name: 'UserAgencyEstimateList',
	props: ['items'],
	data: () => ({
		currentPage: 1,
		itemsPerPage: 7,
		pageitems: [], // 실제 데이터가 들어가는 곳으로 가정
		previousPage: null, // 이전 페이지를 저장하기 위한 변수
	}),
	computed: {
		...mapGetters(['AGENCY_GET_ESTIMATE', 'GUEST_GET_MYPAGE_INFO_PREVIOUSPAGE']),
		changedItems() {
			return this.pageitems
		},
		totalItems() {
			return this.paginatedItems.length
		},
		totalPages() {
			return Math.ceil(this.changedItems.length / this.itemsPerPage)
		},
		paginatedItems() {
			const startIndex = (this.currentPage - 1) * this.itemsPerPage
			const endIndex = startIndex + (this.itemsPerPage + 1)
			return this.changedItems.slice(startIndex, endIndex)
		},
	},
	watch: {
		previousPage(val) {
			if (val) {
				this.GUEST_MU_MYPAGE_INFO_PREVIOUSPAGE(val)
			}
		},
	},
	created() {
		if (this.AGENCY_GET_ESTIMATE.length === 0) return this.$router.push('/user?tab=0')
		this.pageitems = this.AGENCY_GET_ESTIMATE
		if (this.GUEST_GET_MYPAGE_INFO_PREVIOUSPAGE !== null) {
			this.previousPage = this.GUEST_GET_MYPAGE_INFO_PREVIOUSPAGE
			this.currentPage = this.previousPage
		}
	},
	methods: {
		...mapMutations(['AGENCY_MU_ESTIMATE_SELECT', 'GUEST_MU_MYPAGE_INFO_PREVIOUSPAGE']),
		onClick(val) {
			console.log('onClick', val)
			this.AGENCY_MU_ESTIMATE_SELECT(val)
			this.$router.push(`/user/listDetail/5`)
		},
		handlePageChange(pageNumber) {
			this.currentPage = pageNumber
			this.previousPage = this.currentPage // 이전 페이지를 저장
		},
	},
}
</script>

<style scoped lang="scss">
.txt {
	padding: 20px 0 20px 0;
	font-weight: $fw_bold;
	font-size: $font_mid;
}
</style>
