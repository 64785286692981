<script>
import { Bar, Line } from 'vue-chartjs'

export default {
	name: 'LineChart',
	extends: Bar,
	Line,
	props: {
		barChartData: {
			type: Array,
			default: () => [],
		},
		lineChartData: {
			type: Array,
			default: () => [],
		},
		height: {
			type: String,
			default: '100%',
		},
	},
	data() {
		return {
			options: {
				responsive: true,
				responsiveAnimationDuration: 1000,
				maintainAspectRatio: false,
				// devicePixelRatio: 1,
				elements: {
					line: {
						tension: 0,
					},
				},
				legend: {
					display: true,
					position: 'bottom',
				},
				scales: {
					xAxes: [
						{
							ticks: {
								maxRotation: 0,
								minRotation: 0,
							},
							categoryPercentage: 0.5,
							barPercentage: 0.9,
							// maxBarThickness: 35,
							borderRadius: 1,
							gridLines: {
								display: false,
								offsetGridLines: false,
							},
						},
					],
					yAxes: [
						{
							ticks: {
								padding: 15,
								beginAtZero: true,
							},
							display: true,
							scaleLabel: {
								display: false,
							},
							gridLines: {
								drawBorder: false,
								display: true,
							},
						},
					],
				},
				plugins: {
					datalabels: {
						display: false,
					},
				},
				layout: {},
			},
			color: ['#57a0e5', '#ed6d85', '#6dbebf', '#f2a354', '#9268f6', '#f7cf6b', '#c9cbcf'],
		}
	},
	watch: {
		barChartData() {
			this.draw()
		},
		lineChartData() {
			this.draw()
		},
	},
	mounted() {
		this.draw()
	},
	methods: {
		makeData() {
			const charts = [this.barChartData, this.lineChartData]
			const labels = charts[0][0]?.data.map(item => item.label) // x축 label 가져오기
			let datasets = []
			charts?.map((chartData, type) => {
				// type 0 : bar, type 1 : line
				chartData?.map((item, i) => {
					let label = item.name
					let data = item.data.map(val => val.value)
					if (type === 0) {
						// bar
						datasets.push({
							type: 'bar',
							label: label,
							data: [...data],
							// borderColor: '#000000',
							// borderWidth: 5,
							// borderRadius: 1,
							borderSkipped: false,
							backgroundColor: this.color[i],
							order: charts.length - type,
						})
					} else if (type === 1) {
						// line
						datasets.push({
							type: 'line',
							label: label,
							data: [...data],
							fill: false,
							backgroundColor: this.color[type + i + 1],
							borderColor: this.color[type + i + 1],
							order: charts.length - type,
						})
					}
				})
			})

			return {
				labels,
				datasets,
			}
		},
		draw() {
			const { options } = this
			const data = this.makeData()

			this.renderChart(data, options)
		},
	},
}
</script>

<style scoped></style>
