<template>
	<v-dialog
		v-model="model"
		class="pop_wrapper"
		width="100%"
		max-width="500px"
		persistent
		content-class="elevation-0"
	>
		<v-col
			class="pa-0 player_header"
			align="end"
		>
			<v-btn
				icon
				class="close_button"
				color="white"
				@click="closeDialog"
				><v-icon size="30">mdi-close</v-icon></v-btn
			>
		</v-col>

		<v-col
			class="pa-0"
			style="background-color: white"
		>
			<div>
				<v-carousel
					hide-delimiters
					height="auto"
				>
					<v-carousel-item
						v-for="(image, i) in item.children"
						:key="i"
					>
						<v-img
							class="img_insta"
							:src="image"
							eager
						></v-img>
					</v-carousel-item>
				</v-carousel>
			</div>

			<div class="pa-4 pop_content">
				<v-btn
					color="#00CE7D"
					text
					class="pa-0 mb-2 author"
					@click="handleLinkClick"
				>
					@green_dongwha
				</v-btn>
				<p v-html="item.caption" />
			</div>
		</v-col>
	</v-dialog>
</template>

<script>
import model from '@/mixins/model'
export default {
	name: 'HomeCommunityInstagramDetailItem',
	mixins: [model],
	props: ['item'],
	methods: {
		closeDialog() {
			this.$emit('close')
		},
		handleLinkClick() {
			window.open('https://www.instagram.com/green_dongwha/', '_blank')
		},
	},
}
</script>

<style lang="scss" scoped>
// 팝업 스크롤 바 삭제
:deep(.v-dialog) {
	-ms-overflow-style: none; /* 인터넷 익스플로러 */
	scrollbar-width: none; /* 파이어폭스 */
	&::-webkit-scrollbar {
		display: none !important;
	}
}

.img_insta {
	&.v-image {
		width: 100%;
		height: auto;
		aspect-ratio: 1/1;
	}
}
.pop_content {
	max-height: 350px;
	overflow-y: auto;
}
.author {
	text-transform: lowercase;
	font-size: 1.375rem;
}

::-webkit-scrollbar {
	z-index: 10000;
	-webkit-appearance: none;
}
::-webkit-scrollbar:vertical {
	width: 10px;
}
::-webkit-scrollbar:horizontal {
	height: 10px;
}
::-webkit-scrollbar-track {
	background: #ededed;
	border-radius: 0;
}
::-webkit-scrollbar-thumb {
	background: #92a99b;
	border-radius: 5px;
}
</style>
